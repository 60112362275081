"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddProductModal = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var lodash_1 = require("lodash");
var React = require("react");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var modal_1 = require("../../../components/modal/modal");
var AddProductModal = (function (_super) {
    __extends(AddProductModal, _super);
    function AddProductModal() {
        var _this = this;
        var _a;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                product: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'product',
                    optionsMap: (function (response) {
                        var _a;
                        return (_a = response.items) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            var _a, _b, _c;
                            return ({
                                name: "".concat((_a = i === null || i === void 0 ? void 0 : i.title) === null || _a === void 0 ? void 0 : _a.hu, " ").concat((_b = i === null || i === void 0 ? void 0 : i.subTitle) === null || _b === void 0 ? void 0 : _b.hu, " (").concat(i === null || i === void 0 ? void 0 : i.sku, " - ").concat((_c = i === null || i === void 0 ? void 0 : i.b2cCategoryName) === null || _c === void 0 ? void 0 : _c.hu, ")"),
                                key: i._id,
                                value: i
                            });
                        });
                    }),
                    optionFilters: {
                        categories: (_a = _this.props.categories) === null || _a === void 0 ? void 0 : _a.map(function (c) { return ((c === null || c === void 0 ? void 0 : c._id) || c); }),
                        onlyBundles: 1
                    },
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('bundle-product.form.field.product.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            loading: false
        };
        return _this;
    }
    AddProductModal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (!(0, lodash_1.isEqual)(prevProps.categories, this.props.categories)) {
            this.form.fields.product.setOptionSettings({
                optionFilters: {
                    categories: (_a = this.props.categories) === null || _a === void 0 ? void 0 : _a.map(function (c) { return ((c === null || c === void 0 ? void 0 : c._id) || c); }),
                    onlyBundles: 1
                }
            });
        }
    };
    AddProductModal.prototype.render = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { renderModalHeader: function () {
                return React.createElement("h3", null, 'Termék hozzáadása');
            }, renderModalContent: function (modalContext) {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("div", null,
                            React.createElement("div", { className: "col-18" },
                                React.createElement(form_control_1.FormControl, { field: _this.form.fields.product, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: false, className: 'select-input-basic' }))))),
                    React.createElement("div", { className: 'display-flex flex-row' },
                        React.createElement("div", null,
                            React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                            React.createElement(button_1.Button, { disabled: _this.state.loading, className: 'button-primary-outline button-size-normal-success', title: 'Hozzáadás', onClick: function () { return _this.submit(modalContext); } }))));
            }, renderTrigger: function () {
                return React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: 'Termék hozzáadása', iconRight: 'fas fa-plus' });
            } });
    };
    AddProductModal.prototype.submit = function (modalContext) {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, product, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        isValid = this.form.validate();
                        if (!isValid) {
                            return [2];
                        }
                        modalContext.setLoading(true);
                        product = this.form.toJSON().product;
                        return [4, this.repository.put("admin/bundle/".concat(this.props.forYouId, "/addProduct"), { data: { product: product } })];
                    case 1:
                        _a.sent();
                        modalContext.setLoading(false);
                        modalContext.close();
                        handle_toasts_1.HandleToasts.success('Sikeresen hozzáadtad a terméket');
                        if (this.props.onSubmitSuccess) {
                            this.props.onSubmitSuccess();
                        }
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error(e_1, 'Hiba történt');
                        modalContext.setLoading(false);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return AddProductModal;
}(abstract_component_1.AbstractComponent));
exports.AddProductModal = AddProductModal;
