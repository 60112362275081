"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bOrderListScreen = exports.b2bOrderStatus = void 0;
var random_id_1 = require("@codebuild/glaze/libs/libs/random-id");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var operators_1 = require("rxjs/operators");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var state_filter_component_1 = require("../../common/components/list-search/state-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var permissions_1 = require("../../common/libs/permissions/permissions");
var clickable_row_1 = require("../../components/clickable-row");
var form_1 = require("../../components/form/form");
var permission_wrapper_1 = require("../../components/permission-wrapper");
require("./b2b-order.scss");
var sales_managers_selector_1 = require("../sales-dashboard/components/sales-managers.selector");
var order_badge_1 = require("./components/badge/order-badge");
exports.b2bOrderStatus = {
    draft: { status: 'draft', url: 'draft' },
    financeApprovalNeeded: { status: 'financeApprovalNeeded', url: 'finance-approval-needed' },
    financeApproved: { status: 'financeApproved', url: 'finance-approved' },
    stockLocked: { status: 'stockLocked', url: 'stock-locked' },
    casePicked: { status: 'casePicked', url: 'case-picked' },
    deliveryNoteIssued: { status: 'deliveryNoteIssued', url: 'delivery-note-issued' },
    factDeliveryDetermined: { status: 'factDeliveryDetermined', url: 'fact-delivery-determined' },
    paid: { status: 'paid', url: 'paid' },
    canceled: { status: 'canceled', url: 'cancel' },
};
var B2bOrderListScreen = (function (_super) {
    __extends(B2bOrderListScreen, _super);
    function B2bOrderListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                customerPartner: new field_1.Field({
                    name: 'customerPartner',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.customer-partner'),
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.customerPartner) || null,
                    optionsEndpoint: '/b2b/customer-partner?sort[name]=1',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.name,
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                }),
                from: new field_1.Field({
                    placeholder: '',
                    value: ((_m = (_l = _this.props.control$) === null || _l === void 0 ? void 0 : _l.getQuery()) === null || _m === void 0 ? void 0 : _m.from) || null,
                    name: 'from',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.from'),
                    validators: []
                }),
                to: new field_1.Field({
                    placeholder: '',
                    value: ((_p = (_o = _this.props.control$) === null || _o === void 0 ? void 0 : _o.getQuery()) === null || _p === void 0 ? void 0 : _p.to) || null,
                    name: 'to',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.to'),
                    validators: []
                }),
                salesManager: new field_1.Field({
                    placeholder: '',
                    value: ((_r = (_q = _this.props.control$) === null || _q === void 0 ? void 0 : _q.getQuery()) === null || _r === void 0 ? void 0 : _r.salesManager) || null,
                    name: 'to',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.to'),
                    validators: []
                }),
                isPlanUpdateNeeded: new field_1.Field({
                    placeholder: '',
                    value: ((_t = (_s = _this.props.control$) === null || _s === void 0 ? void 0 : _s.getQuery()) === null || _t === void 0 ? void 0 : _t.isPlanUpdateNeeded) || null,
                    name: 'isPlanUpdateNeeded',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.is-plan-update-needed'),
                    validators: []
                }),
                isInvoiceIssued: new field_1.Field({
                    placeholder: '',
                    value: ((_v = (_u = _this.props.control$) === null || _u === void 0 ? void 0 : _u.getQuery()) === null || _v === void 0 ? void 0 : _v.isInvoiceIssued) || null,
                    name: 'isInvoiceIssued',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.is-invoice-issued'),
                    validators: []
                }),
            }
        });
        _this.state = {
            isFilterOpen: true,
        };
        return _this;
    }
    B2bOrderListScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.filterForm.fields.customerPartner.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('customerPartner', v); });
                this.filterForm.fields.from.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('from', v); });
                this.filterForm.fields.to.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('to', v); });
                this.filterForm.fields.isPlanUpdateNeeded.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('isPlanUpdateNeeded', v); });
                this.filterForm.fields.isInvoiceIssued.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('isInvoiceIssued', v); });
                this.filterForm.fields.salesManager.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('salesManager', v); });
                return [2];
            });
        });
    };
    B2bOrderListScreen.prototype.handleChange = function (property, value) {
        if (!value) {
            this.control.set(property, '');
            this.control.page(0);
            return;
        }
        this.control.set(property, value);
        this.control.page(0);
    };
    B2bOrderListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'B2bOrderListScreen' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-15' },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('stock-order.list.main.title')),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.ORDERS.b2bOrders.read },
                                React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/b2b-order/create'); }, title: (0, trans_1.trans)('b2b-order.list.create.button'), iconLeft: 'fal fa-plus', className: 'button-primary-outline button-size-normal' }))))),
                React.createElement("div", { className: 'col-9' },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row mt-4 mb-7' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filter-wrapper' },
                        React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                            React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                            React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                                React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                        this.state.isFilterOpen && React.createElement(form_1.Form, null,
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: false, tabIndex: 1, className: 'select-input-basic col-8 pl-0' })),
                                React.createElement(state_filter_component_1.StateFilterComponent, { control: this.control, className: 'col-8' }),
                                React.createElement("div", { className: 'col-8' },
                                    React.createElement(sales_managers_selector_1.SalesManagersSelector, { onUserChange: function (_a) {
                                            var customerPartners = _a.customerPartners, user = _a.user;
                                            return _this.filterForm.fields.salesManager.setValue(user._id);
                                        } })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.from, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                    React.createElement(datepicker_1.Datepicker, { type: 'date', locale: 'hu', className: 'datepicker-basic col-8', tabIndex: 3 })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.to, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                    React.createElement(datepicker_1.Datepicker, { type: 'date', locale: 'hu', className: 'datepicker-basic col-8', tabIndex: 4 }))),
                            React.createElement("div", { className: "row" },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.isPlanUpdateNeeded, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6', tabIndex: 5 })),
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.isInvoiceIssued, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6', tabIndex: 6 }))))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/order/quick', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    B2bOrderListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    B2bOrderListScreen.prototype.getTotal = function (plan) {
        var total = plan === null || plan === void 0 ? void 0 : plan.reduce(function (acc, item) {
            var _a;
            var price = (_a = item.exactPrice) !== null && _a !== void 0 ? _a : item.calculatedPrice;
            var value = item.amount * price;
            return acc + value;
        }, 0);
        return total !== null && total !== void 0 ? total : 0;
    };
    B2bOrderListScreen.prototype.renderItem = function (ctx, item) {
        var _a, _b, _c;
        var total = this.getTotal((_a = item === null || item === void 0 ? void 0 : item.order) === null || _a === void 0 ? void 0 : _a.plan);
        return React.createElement(clickable_row_1.ClickableRow, { key: (0, random_id_1.randomId)(), href: "/b2b-order/".concat(item === null || item === void 0 ? void 0 : item._id) },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("p", null, item.identifier)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("p", null, (_b = item.customerPartner) === null || _b === void 0 ? void 0 : _b.name)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement(order_badge_1.OrderBadge, { status: item === null || item === void 0 ? void 0 : item.status })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, ((_c = item.shippingData) === null || _c === void 0 ? void 0 : _c.city) || '')),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, item.plannedDeliveryDate ? moment(item.plannedDeliveryDate).format('YYYY.MM.DD') : 'n/a')),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, moment(item.createdAt).format('YYYY.MM.DD hh:mm'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, (0, currency_parser_1.moneyFormat)(total))));
    };
    B2bOrderListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('identifier'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('customerPartner'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('city'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('plannedDeliveryDate'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('createdAt'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('total'))));
    };
    B2bOrderListScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-order.table.header.".concat(p)),
            property: p
        };
    };
    B2bOrderListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bOrderListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bOrderListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total, openedRows: [] });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    B2bOrderListScreen.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    B2bOrderListScreen.prototype.fetchOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.get('/b2b/sales-dashboard/customer-partners-by-sales-manager')];
                    case 1:
                        response = _a.sent();
                        this.setState({ customerPartners: response });
                        return [3, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.log({ DashboardTotalBoxes: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return B2bOrderListScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bOrderListScreen = B2bOrderListScreen;
