"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryStorageTreeSelectFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/sprinkles/libs/form/form-control/types/abstract.form-control-type");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var lodash_1 = require("lodash");
var React = require("react");
var HistoryStorageTreeSelectFormControlType = (function (_super) {
    __extends(HistoryStorageTreeSelectFormControlType, _super);
    function HistoryStorageTreeSelectFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HistoryStorageTreeSelectFormControlType.prototype.getProps = function () {
        var _this = this;
        return {
            onChange: function (value) { return _this.handleChange(value); },
            value: this.getValue(),
            label: this.formControl.props.field.getLabel(),
            placeholder: this.formControl.props.field.getPlaceholder(),
            required: this.formControl.props.field.getRequired(),
            errors: this.errors,
            loading: this.formControl.props.field.loading,
            renderTrigger: function (ctx) { return _this.renderTrigger(ctx); }
        };
    };
    HistoryStorageTreeSelectFormControlType.prototype.handleChange = function (value) {
        var uniqValues = (0, lodash_1.uniq)(value || []);
        var values = uniqValues.map(function (v) { var _a; return (_a = v === null || v === void 0 ? void 0 : v.document) === null || _a === void 0 ? void 0 : _a._id; });
        return this.formControl.props.field.setValue(values);
    };
    HistoryStorageTreeSelectFormControlType.prototype.getValue = function () {
        var _a;
        return (_a = (this.formControl.props.field.getValue() || [])) === null || _a === void 0 ? void 0 : _a.map(function (v) { return ({ document: { _id: v } }); });
    };
    HistoryStorageTreeSelectFormControlType.prototype.renderTrigger = function (ctx) {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement("div", { className: 'display-flex align-items-center' },
            React.createElement(button_1.Button, { iconLeft: !!((_a = this.getValue()) === null || _a === void 0 ? void 0 : _a.length) ? 'far fa-pencil mr-2' : 'far fa-plus mr-2', className: "button-size-medium ".concat(((_b = this.errors) === null || _b === void 0 ? void 0 : _b.length) ? 'button-error-outline' : 'button-neutral-outline', " "), title: !!((_c = this.getValue()) === null || _c === void 0 ? void 0 : _c.length) ? (ctx.getPaths(this.getValue()).map(function (p) { return React.createElement("div", { className: 'w-100 display-flex justify-content-start', key: p }, p); })) : 'Tárhely választása' }),
            ctx.props.clearable && !!((_d = this.getValue()) === null || _d === void 0 ? void 0 : _d.length) && React.createElement("div", { className: 'ml-2' },
                React.createElement(button_1.Button, { className: 'button-size-medium button-neutral-link p-0', iconLeft: 'fa fa-eraser', onClick: function (event) { return _this.clear(event, ctx); } })));
    };
    HistoryStorageTreeSelectFormControlType.prototype.clear = function (e, ctx) {
        e.preventDefault();
        e.stopPropagation();
        ctx.clearSelection();
    };
    return HistoryStorageTreeSelectFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.HistoryStorageTreeSelectFormControlType = HistoryStorageTreeSelectFormControlType;
