"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSku = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var operators_1 = require("rxjs/operators");
var CustomSku = (function (_super) {
    __extends(CustomSku, _super);
    function CustomSku() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                sku: new field_1.Field({
                    placeholder: '',
                    value: (_b = (_a = _this.props.existingPartnerData) === null || _a === void 0 ? void 0 : _a.customSKU) !== null && _b !== void 0 ? _b : '',
                    name: 'sku',
                    label: "".concat(_this.props.customerPartner.name, " ").concat((0, trans_1.trans)('product.update.basic-data.form.field.custom.sku.label')),
                }),
            }
        });
        return _this;
    }
    CustomSku.prototype.componentDidMount = function () {
        var _this = this;
        var _a;
        this.$subscriptions.push(this.form.fields.sku.$value
            .pipe((0, operators_1.debounceTime)(400))
            .subscribe(function (sku) {
            var _a;
            return _this.props.onDataChange((_a = {}, _a[_this.props.customerPartner._id] = sku, _a));
        }));
        if (this.props.existingPartnerData) {
            this.form.fields.sku.setValue((_a = this.props.existingPartnerData) === null || _a === void 0 ? void 0 : _a.customSKU);
        }
    };
    CustomSku.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (prevProps.existingPartnerData !== this.props.existingPartnerData) {
            this.form.fields.sku.setValue((_a = this.props.existingPartnerData) === null || _a === void 0 ? void 0 : _a.customSKU);
        }
    };
    CustomSku.prototype.render = function () {
        return React.createElement(form_control_1.FormControl, { field: this.form.fields.sku, type: text_input_form_control_type_1.TextInputFormControlType },
            React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }));
    };
    return CustomSku;
}(abstract_component_1.AbstractComponent));
exports.CustomSku = CustomSku;
