"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockIncomeCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var file_uploader_1 = require("../../components/file-uplader/file-uploader");
var file_uploader_form_control_type_1 = require("../../components/file-uplader/type/file-uploader-form-control-type");
var form_1 = require("../../components/form/form");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
var add_product_modal_1 = require("./components/add-product.modal");
var delete_product_modal_1 = require("./components/delete-product.modal");
var stock_income_table_row_1 = require("./components/stock-income-table-row");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var B2bStockIncomeCreateScreen = (function (_super) {
    __extends(B2bStockIncomeCreateScreen, _super);
    function B2bStockIncomeCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.productsRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            isSupplierSelected: false,
            isDepotSelected: false,
            isWarehouseSelected: false,
            isReturn: false,
            isTransaction: false,
            partner: {},
            products: [],
            error: '',
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                supplier: new field_1.Field({
                    name: 'supplier',
                    id: 'supplier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.supplier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: null,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                supplierShippingData: new field_1.Field({
                    name: 'supplierShippingData',
                    id: 'supplierShippingData',
                    value: null,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.supplier-depot.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: [],
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                bko: new field_1.Field({
                    name: 'bko',
                    id: 'bko',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.bko.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                pretenceCode: new field_1.Field({
                    name: 'pretenceCode',
                    id: 'pretenceCode',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.pretence-code.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/stock-miscellaneous/pretence-codes',
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) { return response === null || response === void 0 ? void 0 : response.map(function (c) { return ({ name: "".concat(c.code, " - ").concat(c.description), value: c.code, key: c.code }); }); },
                    validators: []
                }),
                legalCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.legalCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: issuing_legal_codes_1.INCOME_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                depot: new field_1.Field({
                    name: 'depot',
                    id: 'depot',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.depot.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/depot?hasStorage=true',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: o.title, value: o._id, key: o._id }); }); },
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    id: 'warehouse',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.warehouse.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: [],
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    name: 'comment',
                    id: 'comment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-income.create.form.comment.label')),
                    validators: []
                }),
                isForeign: new field_1.Field({
                    name: 'isForeign',
                    id: 'isForeign',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-income.create.form.isForeign.label')),
                    validators: []
                }),
                attachments: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'attachments',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.attachments.label'),
                        " "),
                }),
                isReturn: new field_1.Field({
                    name: 'isReturn',
                    id: 'isReturn',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-income.create.form.isReturn.label')),
                    validators: []
                }),
                isTransaction: new field_1.Field({
                    name: 'isTransaction',
                    id: 'isTransaction',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-income.create.form.isTransaction.label')),
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bStockIncomeCreateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var endpoint;
            var _this = this;
            return __generator(this, function (_a) {
                endpoint = this.getPartnerOptions();
                this.form.fields.supplier.setOptionSettings({
                    optionsEndpoint: endpoint,
                    optionsMap: (function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                            name: o.name,
                            value: o._id,
                            key: o._id,
                            raw: o
                        }); });
                    })
                });
                this.$subscriptions.push(this.form.fields.supplier.$value.subscribe(function (value) { return _this.onSupplierChange(value); }), this.form.fields.depot.$value.subscribe(function (value) { return _this.onDepotChange(value); }), this.form.fields.warehouse.$value.subscribe(function (value) { return _this.onWarehouseChange(value); }), this.form.fields.isReturn.$value.subscribe(function (value) { return _this.onReturnChange(value); }), this.form.fields.isTransaction.$value.subscribe(function (value) { return _this.onTransactionChange(value); }));
                return [2];
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.getPartnerOptions = function () {
        return this.state.isReturn
            ? '/b2b/customer-partner?sort[name]=1&_sort[name]=1&limit=5000&isActive=true'
            : '/b2b/supplier-partner?sort[name]=1&_sort[name]=1&limit=5000';
    };
    B2bStockIncomeCreateScreen.prototype.onReturnChange = function (value) {
        if (value === void 0) { value = false; }
        return __awaiter(this, void 0, void 0, function () {
            var endpoint;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isReturn: value, products: [], partner: {} });
                        return [4, this.form.fields.supplier.setValue(null)];
                    case 1:
                        _a.sent();
                        endpoint = this.getPartnerOptions();
                        this.form.fields.supplier.setOptionSettings({
                            optionsEndpoint: endpoint,
                            optionsMap: (function (response) {
                                var _a;
                                return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                                    name: o.name,
                                    value: o._id,
                                    key: o._id,
                                    raw: o
                                }); });
                            })
                        });
                        return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.onTransactionChange = function (value) {
        if (value === void 0) { value = false; }
        return __awaiter(this, void 0, void 0, function () {
            var endpoint;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isTransaction: value, products: [], partner: {} });
                        return [4, this.form.fields.supplier.setValue(null)];
                    case 1:
                        _a.sent();
                        endpoint = this.getPartnerOptions();
                        this.form.fields.supplier.setOptionSettings({
                            optionsEndpoint: endpoint,
                            optionsMap: (function (response) {
                                var _a;
                                return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                                    name: o.name,
                                    value: o._id,
                                    key: o._id,
                                    raw: o
                                }); });
                            })
                        });
                        return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.onSupplierChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchSupplier()];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.supplierShippingData.setValue(null)];
                    case 2:
                        _a.sent();
                        this.setState({
                            isSupplierSelected: !!value,
                            products: [],
                        });
                        return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.onDepotChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchWarehouses()];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.warehouse.setValue(null)];
                    case 2:
                        _a.sent();
                        this.setState({ isDepotSelected: !!value, products: [] });
                        return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.onWarehouseChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    isWarehouseSelected: !!value,
                    products: [],
                    showTaxDependentFields: ((value === null || value === void 0 ? void 0 : value.type) === 'tax')
                });
                if (!!(value === null || value === void 0 ? void 0 : value.isTaxArea)) {
                    this.form.fields.legalCode.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                }
                else {
                    this.form.fields.legalCode.removeValidators();
                }
                return [2];
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.fetchSupplier = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var supplierId, response, _b, list, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        return [4, this.form.fields.supplier.getValue()];
                    case 1:
                        supplierId = _c.sent();
                        if (!supplierId) {
                            this.setState({ partner: null });
                            return [2, this.form.fields.supplierShippingData.setOptions([])];
                        }
                        if (!this.state.isReturn) return [3, 3];
                        return [4, this.repository.get("/b2b/customer-partner/".concat(supplierId, "?isActive=true"))];
                    case 2:
                        _b = _c.sent();
                        return [3, 5];
                    case 3: return [4, this.repository.get("/b2b/supplier-partner/".concat(supplierId))];
                    case 4:
                        _b = _c.sent();
                        _c.label = 5;
                    case 5:
                        response = _b;
                        list = ((_a = response === null || response === void 0 ? void 0 : response.shippingDatas) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: "".concat(item.zipCode, " ").concat(item.city, ", ").concat(item.address, " (Eng.sz.: ").concat(item.operatingPermitNumber || '', ")"),
                            value: item,
                            key: item.name
                        }); })) || [];
                        return [4, this.form.fields.supplierShippingData.setOptions(list)];
                    case 6:
                        _c.sent();
                        return [3, 8];
                    case 7:
                        e_1 = _c.sent();
                        return [3, 8];
                    case 8: return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.fetchWarehouses = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var depotId_1, response, list, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        return [4, this.form.fields.depot.getValue()];
                    case 1:
                        depotId_1 = _c.sent();
                        if (!depotId_1) {
                            return [2, this.form.fields.warehouse.setOptions([])];
                        }
                        return [4, this.repository.get('/b2b/warehouse?hasStorage=true')];
                    case 2:
                        response = _c.sent();
                        list = ((_b = (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.depot === depotId_1; })) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
                            name: item.title,
                            value: item,
                            key: item._id
                        }); })) || [];
                        return [4, this.form.fields.warehouse.setOptions(list)];
                    case 3:
                        _c.sent();
                        return [3, 5];
                    case 4:
                        e_2 = _c.sent();
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    B2bStockIncomeCreateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'StockIncomeCreate row w-100' },
            this.renderModals(),
            React.createElement("div", { className: 'col content-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-income'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h3", { className: 'fw-600' }, (0, trans_1.trans)('stock-income.create.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement(form_1.Form, { className: 'w-100' },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isReturn, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: this.props.disabled || this.state.loading || this.state.isTransaction, className: 'checkbox-input-basic' }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isTransaction, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: this.props.disabled || this.state.loading || this.state.isReturn, className: 'checkbox-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.supplier, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading, unclearable: false, multiple: false, searchable: true, className: 'select-input-basic' }))),
                            React.createElement("div", { className: 'col' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.supplierShippingData, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading || !this.state.isSupplierSelected, unclearable: false, multiple: false, className: 'select-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.depot, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading, unclearable: false, multiple: false, className: 'select-input-basic' }))),
                            React.createElement("div", { className: 'col' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading || !this.state.isDepotSelected, unclearable: false, multiple: false, className: 'select-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-12' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.bko, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { disabled: this.props.disabled || this.state.loading, className: 'text-input-basic' }))),
                            this.state.showTaxDependentFields && React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading, unclearable: true, multiple: false, searchable: true, className: 'select-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-12' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled || this.state.loading, rows: 6, className: 'textarea-input-basic' }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.attachments, type: file_uploader_form_control_type_1.FileUploaderFormControlType },
                                    React.createElement(file_uploader_1.FileUploader, { multiple: true, disabled: this.props.disabled || this.state.loading, title: React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                                            React.createElement("div", null,
                                                React.createElement("span", { className: 'color--primary-6 fw-700 cursor-pointer' }, "V\u00E1lassz f\u00E1jlt"),
                                                React.createElement("span", { className: 'fw-700' }, " vagy csak dobd ide")),
                                            React.createElement("div", null,
                                                React.createElement("span", { className: 'color--neutral-5' }, "PNG, JPG, JPEG - max 5MB"))), endpoint: '/b2b/upload' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-14' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isForeign, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: this.props.disabled || this.state.loading, className: 'checkbox-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col' }, this.renderStockIncomeTable()))))),
            React.createElement("div", { className: 'col stock-income-save-box-wrapper' },
                this.renderSaveBox(),
                this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex align-items-center' },
                        React.createElement("i", { className: 'far fa-exclamation-circle mr-2' }),
                        React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)(this.state.error))) })));
    };
    B2bStockIncomeCreateScreen.prototype.renderStockIncomeTable = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'col mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('stock-income.create.product-list.title')),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('stock-income.create.product-list.add.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.$showAddProductModal.open(); }, className: 'button-primary-inverze button-size-normal', disabled: !this.state.isSupplierSelected
                        || !this.state.isDepotSelected
                        || !this.state.isWarehouseSelected })),
            React.createElement(table_1.Table, { className: 'table-basic StockIncomeTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.products); } }));
    };
    B2bStockIncomeCreateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 40 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('amount'), { className: 'justify-content-center' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    B2bStockIncomeCreateScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-income.create.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockIncomeCreateScreen.prototype.renderItems = function (items) {
        var _this = this;
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var _a, _b;
            return React.createElement(stock_income_table_row_1.StockIncomeTableRow, { key: item._id, ref: function (ref) { return _this.productsRefs[item.productId] = ref; }, product: item, values: (_b = (_a = _this.productsRefs[item.productId]) === null || _a === void 0 ? void 0 : _a.form) === null || _b === void 0 ? void 0 : _b.fields, showDeleteProductModal: function () { return _this.$showDeleteProductModal.open({ product: item.productId }); }, warehouse: _this.form.fields.warehouse.getValue() });
        });
    };
    B2bStockIncomeCreateScreen.prototype.handleRemoveProduct = function (productId) {
        var _a;
        var newProducts = (_a = (0, lodash_1.clone)(this.state.products)) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
        this.setState({ products: newProducts });
        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-income.create.delete.product.success.toast'));
        this.$showDeleteProductModal.close();
    };
    B2bStockIncomeCreateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAddProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('stock-income.add-product.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$showAddProductModal.close(); }, onAddProduct: function (product) { return _this.setState({
                        products: __spreadArray(__spreadArray([], __read(_this.state.products), false), [product], false),
                    }); }, filter: (!_this.state.isReturn && !_this.state.isTransaction) ? "supplier=".concat(_this.form.fields.supplier.getValue()) : '' }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$showDeleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.product });
                } }));
    };
    B2bStockIncomeCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'stock-income-save-box mb-6' },
                React.createElement("h5", { className: 'mb-6' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Rögzítés', iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-primary-normal button-size-normal', disabled: false, onClick: function () { return _this.submitIncomeData(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockIncomeCreateScreen.prototype.submitIncomeData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var valid, items, _b, _c, product, item, e_3_1, formData, incomeType, data, result, e_4;
            var e_3, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 11, , 12]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _e.sent();
                        items = [];
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 7, 8, 9]);
                        _b = __values(this.state.products), _c = _b.next();
                        _e.label = 3;
                    case 3:
                        if (!!_c.done) return [3, 6];
                        product = _c.value;
                        return [4, this.productsRefs[product.productId].getData()];
                    case 4:
                        item = _e.sent();
                        if (item === null || item === void 0 ? void 0 : item.error) {
                            valid = false;
                            return [3, 5];
                        }
                        items.push(item);
                        _e.label = 5;
                    case 5:
                        _c = _b.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_3_1 = _e.sent();
                        e_3 = { error: e_3_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('stock-income.form.missing.required'));
                        }
                        formData = this.form.toJSON();
                        incomeType = formData.isReturn
                            ? 'return'
                            : formData.isTransaction
                                ? 'transaction'
                                : 'income';
                        data = {
                            incomeType: incomeType,
                            attachments: formData.attachments,
                            supplier: formData === null || formData === void 0 ? void 0 : formData.supplier,
                            supplierShippingData: formData === null || formData === void 0 ? void 0 : formData.supplierShippingData,
                            bko: formData === null || formData === void 0 ? void 0 : formData.bko,
                            pretenceCode: parseInt(formData === null || formData === void 0 ? void 0 : formData.pretenceCode, 10),
                            depot: formData === null || formData === void 0 ? void 0 : formData.depot,
                            legalCode: formData === null || formData === void 0 ? void 0 : formData.legalCode,
                            warehouse: (_a = formData === null || formData === void 0 ? void 0 : formData.warehouse) === null || _a === void 0 ? void 0 : _a._id,
                            comment: formData === null || formData === void 0 ? void 0 : formData.comment,
                            isForeign: formData === null || formData === void 0 ? void 0 : formData.isForeign,
                            plan: items === null || items === void 0 ? void 0 : items.map(function (item) { return ({
                                storageId: item.storage,
                                productId: item.product,
                                lot: item.lot,
                                amount: item.amount,
                            }); }),
                        };
                        return [4, this.repository.post('/b2b/stock-income', { data: data })];
                    case 10:
                        result = _e.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-income.create.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo("/stock-income/".concat(result._id))];
                    case 11:
                        e_4 = _e.sent();
                        this.setState({ loading: false, error: (0, trans_1.trans)("stock-income.create.error.".concat(e_4.message)) });
                        return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    return B2bStockIncomeCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockIncomeCreateScreen = B2bStockIncomeCreateScreen;
