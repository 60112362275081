"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderListScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var badge_1 = require("../../components/libs/badge/badge");
var SliderListScreen = (function (_super) {
    __extends(SliderListScreen, _super);
    function SliderListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 10,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : ''
            },
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    SliderListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('slider.list.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/slider/create'); }, title: (0, trans_1.trans)('slider.list.button.create.title'), iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' })))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: 'admin/slider', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    SliderListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    SliderListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('title.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('title.en'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('subTitle.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('subTitle.en'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('published'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15, isDefaultSort: true }, this.setHeaderCell('order'))));
    };
    SliderListScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("slider.table.header.".concat(p)),
            property: p
        };
    };
    SliderListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    SliderListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    SliderListScreen.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d;
        return React.createElement("a", { className: 'list-table-row-link', href: "/slider/".concat(item === null || item === void 0 ? void 0 : item._id), key: item._id },
            React.createElement(table_body_row_1.TableBodyRow, null,
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.title) === null || _a === void 0 ? void 0 : _a.hu)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, (_b = item === null || item === void 0 ? void 0 : item.title) === null || _b === void 0 ? void 0 : _b.en)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, (_c = item === null || item === void 0 ? void 0 : item.subTitle) === null || _c === void 0 ? void 0 : _c.hu)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, (_d = item === null || item === void 0 ? void 0 : item.subTitle) === null || _d === void 0 ? void 0 : _d.en)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement(badge_1.Badge, { title: (item === null || item === void 0 ? void 0 : item.published) ? (0, trans_1.trans)('slider.status.published') : (0, trans_1.trans)('slider.status.unpublished'), type: (item === null || item === void 0 ? void 0 : item.published) ? 'success' : 'error' })),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item === null || item === void 0 ? void 0 : item.order))));
    };
    SliderListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return SliderListScreen;
}(abstract_component_1.AbstractComponent));
exports.SliderListScreen = SliderListScreen;
