"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var SliderCreateScreen = (function (_super) {
    __extends(SliderCreateScreen, _super);
    function SliderCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                subTitle_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'subTitle_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.subTitle_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                subTitle_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'subTitle_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.subTitle_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('slider.form.description_hu'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('slider.form.description_en'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonTitle_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonTitle_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonTitle_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonTitle_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonTitle_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonTitle_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonLink_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonLink_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonLink_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonLink_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonLink_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonLink_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                order: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'order',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.order'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                color: new field_1.Field({
                    placeholder: '',
                    value: 'black',
                    name: 'color',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.color'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))],
                    options: [
                        {
                            value: 'black',
                            name: 'fekete',
                            key: 1,
                            disabled: false,
                        },
                        {
                            value: 'white',
                            name: 'fehér',
                            key: 2,
                            disabled: false
                        },
                        {
                            value: 'coral',
                            name: 'piros',
                            key: 3,
                            disabled: false
                        },
                    ],
                })
            }
        });
        _this.state = {
            desktopImage: null,
            mobileImage: null,
            error: null
        };
        return _this;
    }
    SliderCreateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.createSlide(); });
    };
    SliderCreateScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'SlideCreateScreen row w-100' },
            React.createElement("div", { className: "col-24" },
                React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('slider.create.screen.title'))),
            React.createElement("div", { className: "col-md-12 col-24" }, this.renderAttributeForm()),
            React.createElement("div", { className: "col-md-12 col-24" }, this.renderImageUploader()),
            React.createElement("div", { className: "col-24" },
                React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelCreate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('slider.create.button.title'), onClick: function () { return _this.form.submit(); } })),
                React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                    React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("slider.create.".concat((0, trans_1.trans)((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message))) }))));
    };
    SliderCreateScreen.prototype.renderAttributeForm = function () {
        return React.createElement("div", { className: 'SlideCreateForm' },
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.subTitle_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.subTitle_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTitle_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTitle_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonLink_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonLink_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.color, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { unclearable: true, tabIndex: 1, iconRight: 'winehub-icon arrow-small-down', className: 'select-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.order, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))));
    };
    SliderCreateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 mb-4' },
                React.createElement("h4", null, "Desktop k\u00E9p"),
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.desktopImageUploaderRef$ = ref; }, value: this.state.desktopImage, targetVariant: "fullpage", width: 1600, heigth: 333 })),
            React.createElement("div", { className: 'col-12 mb-4' },
                React.createElement("h4", null, "Mobil k\u00E9p"),
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.mobileImageUploaderRef$ = ref; }, value: this.state.mobileImage, width: 375, heigth: 400, targetVariant: "fullpage" })));
    };
    SliderCreateScreen.prototype.cancelCreate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/slider');
                        return [2];
                }
            });
        });
    };
    SliderCreateScreen.prototype.createSlide = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var data, desktopImage, mobileImage, parsedData, response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        data = this.form.toJSON();
                        return [4, ((_a = this.desktopImageUploaderRef$) === null || _a === void 0 ? void 0 : _a.getValue())];
                    case 1:
                        desktopImage = _c.sent();
                        return [4, ((_b = this.mobileImageUploaderRef$) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 2:
                        mobileImage = _c.sent();
                        if (!desktopImage || !mobileImage) {
                            handle_toasts_1.HandleToasts.error(null, 'Képek feltöltése kötelező');
                            return [2];
                        }
                        parsedData = __assign(__assign({}, data), { options: [], title: { hu: data === null || data === void 0 ? void 0 : data.title_hu, en: data.title_en }, subTitle: { hu: data === null || data === void 0 ? void 0 : data.subTitle_hu, en: data.subTitle_en }, buttonTitle: { hu: data === null || data === void 0 ? void 0 : data.buttonTitle_hu, en: data.buttonTitle_en }, buttonLink: { hu: data === null || data === void 0 ? void 0 : data.buttonLink_hu, en: data.buttonLink_en }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en }, desktopImage: desktopImage, mobileImage: mobileImage, color: data.color });
                        return [4, this.repository.post('/admin/slider', { data: parsedData })];
                    case 3:
                        response = _c.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('slider.create.success.toast'));
                        router_provider_1.RouterProvider.goTo('/slider');
                        return [3, 5];
                    case 4:
                        e_1 = _c.sent();
                        this.setState({ error: e_1 });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    return SliderCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.SliderCreateScreen = SliderCreateScreen;
