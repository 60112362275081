"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bSupplierPricingListScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var datepicker_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/datepicker/datepicker");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var list_search_component_1 = require("../../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var list_state_handler_1 = require("../../../common/libs/list-state-handler");
var user_permission_1 = require("../../../common/libs/user-permission");
var form_1 = require("../../../components/form/form");
var modal_1 = require("../../../components/modal/modal");
require("./b2b-supplier-pricing.scss");
var supplier_pricing_show_modal_1 = require("./components/supplier-pricing-show.modal");
var supplier_pricing_table_row_1 = require("./components/supplier-pricing-table-row");
var statusOptions = [
    { key: 0, name: 'Összes', value: 'all' },
    { key: 1, name: 'Aktív', value: 'active' },
    { key: 2, name: 'Inaktív', value: 'inactive' },
];
var B2bSupplierPricingListScreen = (function (_super) {
    __extends(B2bSupplierPricingListScreen, _super);
    function B2bSupplierPricingListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.state = {
            products: [],
            selectedProduct: {},
            total: 0,
            isSupplierFilterDisabled: false,
            activeDate: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.date) || moment().toDate(),
        };
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                supplier: new field_1.Field({
                    name: 'supplier',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.supplier'),
                    value: '',
                    optionsEndpoint: '/b2b/supplier-partner/list-by-owner?sort[name]=1&limit=5000',
                    loadOptionsAfterMount: true,
                    optionsMap: (function (response) { return response.items
                        .map(function (i) { return ({
                        name: "".concat(i.name, " ").concat((i === null || i === void 0 ? void 0 : i.brandName) ? "(".concat(i.brandName, ")") : ''),
                        key: i._id,
                        value: i._id
                    }); }); }),
                }),
                date: new field_1.Field({
                    placeholder: '',
                    value: ((_m = (_l = _this.control) === null || _l === void 0 ? void 0 : _l.getQuery()) === null || _m === void 0 ? void 0 : _m.date) || moment().toDate(),
                    name: 'date',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.date'),
                    validators: []
                }),
                category: new field_1.Field({
                    placeholder: '',
                    value: ((_p = (_o = _this.control) === null || _o === void 0 ? void 0 : _o.getQuery()) === null || _p === void 0 ? void 0 : _p.category) || '',
                    name: 'category',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.category'),
                    optionsEndpoint: '/admin/b2c/category',
                    loadOptionsAfterMount: true,
                    optionsMap: (function (response) { return response.items
                        .map(function (i) {
                        var _a;
                        return ({
                            name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.hu,
                            key: i._id,
                            value: i._id
                        });
                    }); }),
                    validators: []
                }),
                manufacturer: new field_1.Field({
                    placeholder: '',
                    value: ((_r = (_q = _this.control) === null || _q === void 0 ? void 0 : _q.getQuery()) === null || _r === void 0 ? void 0 : _r.manufacturer) || '',
                    name: 'manufacturer',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.manufacturer'),
                    optionsEndpoint: '/admin/manufacturer',
                    loadOptionsAfterMount: true,
                    optionsMap: (function (response) { return response.items
                        .map(function (i) {
                        var _a;
                        return ({
                            name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.hu,
                            key: i._id,
                            value: i._id
                        });
                    }); }),
                    validators: []
                }),
                distributed: new field_1.Field({
                    placeholder: '',
                    value: ((_t = (_s = _this.control) === null || _s === void 0 ? void 0 : _s.getQuery()) === null || _t === void 0 ? void 0 : _t.distributed) || '',
                    name: 'distributed',
                    label: (0, trans_1.trans)('b2b-supplier-pricing.filter.form.label.distributed'),
                    options: statusOptions,
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bSupplierPricingListScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.filterForm.fields.supplier.$value.subscribe(function (v) { return _this.handleChange('supplier', v); });
                this.filterForm.fields.category.$value.subscribe(function (v) { return _this.handleChange('category', v); });
                this.filterForm.fields.distributed.$value.subscribe(function (v) { return _this.handleChange('distributed', v); });
                this.filterForm.fields.manufacturer.$value.subscribe(function (v) { return _this.handleChange('manufacturer', v); });
                this.filterForm.fields.date.$value.subscribe(function (v) {
                    (0, list_state_handler_1.saveListState)('b2b-supplier-pricing', { date: v });
                    _this.setState({ activeDate: v });
                });
                this.filterForm.fields.supplier.$options.subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                    var user, supplierOptions, repositorySupplier, formSupplier, values;
                    var _a, _b, _c, _d, _e, _f, _g;
                    return __generator(this, function (_h) {
                        switch (_h.label) {
                            case 0: return [4, local_store_1.LocalStore.getItem('User')];
                            case 1:
                                user = _h.sent();
                                supplierOptions = this.filterForm.fields.supplier.getOptions();
                                repositorySupplier = (_b = (_a = this.control) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.supplier;
                                formSupplier = this.filterForm.fields.supplier.getValue();
                                if (((_c = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _c === void 0 ? void 0 : _c.role) === user_permission_1.Role.admin || ((_d = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _d === void 0 ? void 0 : _d.role) === user_permission_1.Role.superadmin) {
                                    this.setState({ isSupplierFilterDisabled: false });
                                    if (!formSupplier) {
                                        this.filterForm.fields.supplier.setValue(repositorySupplier || ((_e = supplierOptions === null || supplierOptions === void 0 ? void 0 : supplierOptions[0]) === null || _e === void 0 ? void 0 : _e.value) || '');
                                    }
                                }
                                if (((_f = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _f === void 0 ? void 0 : _f.role) === user_permission_1.Role.b2bSupplier) {
                                    this.setState({ isSupplierFilterDisabled: true });
                                    values = supplierOptions.map(function (i) { return i.value; });
                                    (!!formSupplier && !values.includes(formSupplier)) && (!!repositorySupplier && values.includes(repositorySupplier))
                                        ? this.filterForm.fields.supplier.setValue(repositorySupplier)
                                        : this.filterForm.fields.supplier.setValue(((_g = supplierOptions === null || supplierOptions === void 0 ? void 0 : supplierOptions[0]) === null || _g === void 0 ? void 0 : _g.value) || '');
                                }
                                return [2];
                        }
                    });
                }); });
                return [2];
            });
        });
    };
    B2bSupplierPricingListScreen.prototype.handleChange = function (property, value) {
        this.control.set(property, value);
        this.control.page(0);
    };
    B2bSupplierPricingListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            this.renderModals(),
            React.createElement("div", { className: 'SupplierPricingListScreen PricingListScreen' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-15' },
                        React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                            React.createElement("h2", null, (0, trans_1.trans)('b2b-supplier-pricing.list.main.title')))),
                    React.createElement("div", { className: 'col-9' },
                        React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
                React.createElement("div", { className: 'row mt-2' },
                    React.createElement("div", { className: 'col' },
                        React.createElement("div", { className: 'filter-wrapper' },
                            React.createElement(form_1.Form, null,
                                React.createElement("div", { className: 'row mx-0' },
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.supplier, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { disabled: this.state.isSupplierFilterDisabled, searchable: !this.state.isSupplierFilterDisabled, unclearable: true, multiple: false, tabIndex: 1, className: 'select-input-basic col pl-0' })),
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.date, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                        React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic col', disabled: false, type: 'date', locale: 'hu' })),
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.category, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { searchable: false, unclearable: false, multiple: true, tabIndex: 3, className: 'select-input-basic col pl-0' })),
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.manufacturer, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, multiple: true, tabIndex: 4, className: 'select-input-basic col pl-0' })),
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.distributed, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { tabIndex: 5, className: 'select-input-basic col pl-0' }))))))),
                React.createElement("div", { className: 'border-1 p-2' },
                    React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/supplier-pricing/price-list-by-supplier', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
                    React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                            { name: 20, value: 20 },
                            { name: 50, value: 50 },
                            { name: 100, value: 100 },
                            { name: 500, value: 500 },
                        ] }))));
    };
    B2bSupplierPricingListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    B2bSupplierPricingListScreen.prototype.renderItem = function (ctx, item) {
        var _this = this;
        return React.createElement(supplier_pricing_table_row_1.SupplierPricingTableRow, { key: item._id, item: item, date: this.state.activeDate, openModal: function (product) {
                _this.setState({ selectedProduct: product });
                _this.$supplierPricingModal.open();
            } });
    };
    B2bSupplierPricingListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('category'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 50 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('price'), { className: 'justify-content-end' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('edit'))));
    };
    B2bSupplierPricingListScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-supplier-pricing.table.header.".concat(p)),
            property: p
        };
    };
    B2bSupplierPricingListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total, products: ctx.state.items });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    B2bSupplierPricingListScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { col: { md: 20, lg: 16 }, ref: function (ref) { return (_this.$supplierPricingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.show.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(supplier_pricing_show_modal_1.SupplierPricingShowModal, { onClose: function () { return _this.$supplierPricingModal.close(); }, onRefreshRequest: function () { return _this.control.reload(); }, product: _this.state.products.find(function (p) { return p._id === _this.state.selectedProduct; }) }); } }));
    };
    return B2bSupplierPricingListScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bSupplierPricingListScreen = B2bSupplierPricingListScreen;
