"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommissionChart = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var recharts_1 = require("recharts");
var currency_parser_1 = require("../../common/libs/currency-parser");
var CommissionChart = (function (_super) {
    __extends(CommissionChart, _super);
    function CommissionChart() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            data: [],
        };
        return _this;
    }
    CommissionChart.prototype.componentDidMount = function () {
        this.countData();
    };
    CommissionChart.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.data, prevProps.data)) {
            this.countData();
        }
    };
    CommissionChart.prototype.countData = function () {
        var _this = this;
        var months = this.getMonths();
        var data = months.map(function (month) {
            var _a, _b, _c;
            var value = (_c = (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[month]) !== null && _c !== void 0 ? _c : 0;
            return {
                name: month,
                commission: value,
                y: Math.max(value, value) * 1.5
            };
        });
        this.setState({ data: data });
    };
    CommissionChart.prototype.getMonths = function () {
        var months = [];
        var currentDate = moment();
        for (var i = 0; i < 12; i++) {
            var date = currentDate.clone().subtract(i, 'months');
            var monthName = date.locale('hu').format('MMMM');
            months.unshift(monthName);
        }
        return months;
    };
    CommissionChart.prototype.customLabel = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height, value = props.value;
        var val = typeof value === 'string'
            ? parseInt(value, 10)
            : value;
        var format = (0, currency_parser_1.moneyFormat)(val);
        return React.createElement("g", null,
            React.createElement("text", { x: x + width / 2, y: y, dy: -4, textAnchor: 'middle', className: 'fw-600', style: { fontSize: 10 } }, format));
    };
    CommissionChart.prototype.renderCustomTooltip = function (data) {
        var _a, _b, _c, _d;
        if (data.active && ((_a = data.payload) === null || _a === void 0 ? void 0 : _a.length)) {
            return (React.createElement("div", { className: "custom-tooltip" }, ((_b = data.payload) === null || _b === void 0 ? void 0 : _b[1])
                ? React.createElement("p", { className: "label" }, "J\u00E1rul\u00E9k : ".concat((0, currency_parser_1.moneyFormat)((_d = (_c = data.payload) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.value, data.unit)))
                : React.createElement("p", { className: "label" }, 'Járulék : 0 Ft')));
        }
        return null;
    };
    CommissionChart.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'chart-wrapper', style: { height: 300, padding: 8 } },
            React.createElement(recharts_1.ResponsiveContainer, null,
                React.createElement(recharts_1.BarChart, { width: 800, height: 260, data: this.state.data, barGap: 2 },
                    React.createElement(recharts_1.CartesianGrid, null),
                    React.createElement(recharts_1.Tooltip, { content: function (props) { return _this.renderCustomTooltip(props); } }),
                    React.createElement(recharts_1.Legend, { dy: 24, verticalAlign: "bottom", height: 36, iconType: 'circle', formatter: function (value, entry) {
                            return React.createElement("span", { style: { color: '#000' } }, (0, trans_1.trans)("chart.legend.".concat(value)));
                        } }),
                    React.createElement(recharts_1.XAxis, { dataKey: 'name', angle: -45, dy: 20, dx: -5, height: 60, interval: 0, tick: { fill: '#A9A9A9' }, tickLine: false, axisLine: { stroke: '#A9A9A9' } }),
                    React.createElement(recharts_1.YAxis, { dataKey: 'y', width: 100, interval: 0, tick: { fill: '#A9A9A9' }, tickLine: false, tickFormatter: function (value) { return (0, currency_parser_1.moneyFormat)(value); }, axisLine: { stroke: '#A9A9A9' } }),
                    React.createElement(recharts_1.Bar, { dataKey: 'commission', fill: "#D6493D", radius: [2, 2, 2, 2], label: function (props) { return _this.customLabel(props); } }))));
    };
    return CommissionChart;
}(abstract_component_1.AbstractComponent));
exports.CommissionChart = CommissionChart;
