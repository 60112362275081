"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductLocksModal = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var ProductLocksModal = (function (_super) {
    __extends(ProductLocksModal, _super);
    function ProductLocksModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            order: _this.props.order || {},
            product: _this.props.product || {},
            items: _this.props.items || [],
            sumAmount: 0,
            sumTotalAmount: 0,
        };
        return _this;
    }
    ProductLocksModal.prototype.componentDidMount = function () {
        this.setAmountAndTotal();
    };
    ProductLocksModal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!(0, lodash_1.isEqual)(this.props.order, prevProps.order)) {
                    this.setState({ order: this.props.order });
                }
                if (!(0, lodash_1.isEqual)(this.props.product, prevProps.product)) {
                    this.setState({ product: this.props.product });
                }
                if (!(0, lodash_1.isEqual)(this.props.items, prevProps.items)) {
                    this.setState({ items: this.props.items });
                    this.setAmountAndTotal();
                }
                return [2];
            });
        });
    };
    ProductLocksModal.prototype.setAmountAndTotal = function () {
        var _a, _b;
        var sumAmount = (_a = this.state.items) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, i) { return acc + i.amount; }, 0);
        var sumTotalAmount = (_b = this.state.items) === null || _b === void 0 ? void 0 : _b.reduce(function (acc, i) { return acc + i.totalAmount; }, 0);
        this.setState({ sumAmount: sumAmount, sumTotalAmount: sumTotalAmount });
    };
    ProductLocksModal.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var ean = ((_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = this.state.product.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = this.state.product.product) === null || _c === void 0 ? void 0 : _c.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu) || '-';
        var title = ((_f = (_e = this.state.product.product) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu) || '-';
        var vintage = ((_g = this.state.product.product) === null || _g === void 0 ? void 0 : _g.vintage) || '-';
        var capacity = ((_k = (_j = (_h = this.state.product.product) === null || _h === void 0 ? void 0 : _h.b2cAttributeSearchValues) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k[1]) || '-';
        return React.createElement("div", { className: 'mt-6' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col" },
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                            React.createElement("span", null, ean)),
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                            React.createElement("span", null, sku)),
                        React.createElement("p", null,
                            manufacturer,
                            " ",
                            title,
                            " ",
                            vintage,
                            " (",
                            capacity,
                            ")")),
                    React.createElement("span", { className: 'typo-p mb-4' },
                        "K\u00E9rt mennyis\u00E9g: ",
                        React.createElement("h5", { className: 'display-inline' }, this.state.product.amount)))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col" },
                    React.createElement(table_1.Table, { className: 'table-basic B2bOrderFifoPlanTable mb-6', renderHeader: function () { return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
                            React.createElement(table_header_cell_1.TableHeaderCell, { width: 40, title: "T\u00C1RHELY", property: "" }),
                            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: "LOT/N\u00C9BIH", property: "" }),
                            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: "T\u00CDPUS", property: "" }),
                            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: "\u00D6SSZMENNYIS\u00C9G (".concat(_this.state.sumTotalAmount, " db)"), property: "" }),
                            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: "V\u00C1LASZTOTT MENNY. (".concat(_this.state.sumAmount, " db)"), property: "" })); }, renderBody: function () {
                            var _a;
                            return React.createElement(React.Fragment, null, (_a = _this.state.items) === null || _a === void 0 ? void 0 : _a.map(function (iItem, iIndex) { return _this.renderPlanProductItem(iItem, iIndex); }));
                        } }))),
            React.createElement("div", { className: 'row justify-content-center' },
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.close.button'), onClick: function () { return _this.props.onClose(); }, className: 'col button-neutral-inverze button-size-normal' }),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.save.button'), onClick: function () { return _this.props.onSave(); }, className: 'col button-primary-normal button-size-normal ml-4' })));
    };
    ProductLocksModal.prototype.renderPlanProductItem = function (item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        var totalAmount = (item === null || item === void 0 ? void 0 : item.totalAmount) || 0;
        var storage = (_e = (_d = (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.storage) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.map(function (i) { return i.title; })) === null || _d === void 0 ? void 0 : _d.join) === null || _e === void 0 ? void 0 : _e.call(_d, '/');
        return React.createElement(table_body_row_1.TableBodyRow, { key: index },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 40 }, storage),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, item.lot),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, (0, trans_1.trans)("b2b-order.stock-product-locks.modal.type.".concat(item.type))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, (totalAmount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(text_input_1.TextInput, { className: 'text-input-basic w-100', min: 0, max: item.totalAmount, type: "number", value: (item.amount), onChange: function (e) { return _this.props.setValue(item, parseInt((e.target.value || 0), 10)); } })));
    };
    return ProductLocksModal;
}(abstract_component_1.AbstractComponent));
exports.ProductLocksModal = ProductLocksModal;
