"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryAttributeUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var category_attribute_row_1 = require("./category-attribute.row");
var CategoryAttributeUpdateScreen = (function (_super) {
    __extends(CategoryAttributeUpdateScreen, _super);
    function CategoryAttributeUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.addAttributeForm = new form_builder_1.FormBuilder({
            fields: {
                attribute: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'attribute',
                    optionsEndpoint: '/admin/b2c/category-attribute',
                    loadOptionsAfterMount: true,
                    label: 'Jellemző',
                    optionsMap: (function (response) { return response.items.map(function (i) {
                        var _a;
                        return ({
                            name: "".concat((_a = i.title) === null || _a === void 0 ? void 0 : _a.hu, " (").concat(i.adminTitle, ")"),
                            key: i._id,
                            value: i._id
                        });
                    }); }),
                    validators: []
                }),
            }
        });
        _this.state = {
            category: null,
            error: null,
            selectedIsInList: false
        };
        return _this;
    }
    CategoryAttributeUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.fetchCategory();
        this.addAttributeForm.$value.subscribe(function () {
            _this.setState({ selectedIsInList: _this.alreadyAdded(_this.addAttributeForm.fields.attribute.getValue()) });
        });
    };
    CategoryAttributeUpdateScreen.prototype.fetchCategory = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/b2c/category/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.categoryId))];
                    case 1:
                        response = _c.sent();
                        this.setState({ category: response });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CategoryAttributeUpdateScreen.prototype.render = function () {
        var _a, _b;
        return React.createElement("div", { className: 'CategoryAttributeUpdateScreen display-flex flex-column align-items-center w-100' },
            React.createElement("div", { className: 'w-100 display-flex flex-row mb-6' },
                React.createElement("h2", { className: 'color--secondary-dark-3' },
                    (0, trans_1.trans)('category-attribute.update.screen.title'),
                    " / ", (_b = (_a = this.state.category) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 :
                    _b.hu)),
            this.renderAddAttributeForm(),
            this.renderTable());
    };
    CategoryAttributeUpdateScreen.prototype.renderAddAttributeForm = function () {
        var _this = this;
        return React.createElement("div", { className: 'row w-100' },
            React.createElement("div", { className: "col-12" },
                React.createElement(form_control_1.FormControl, { field: this.addAttributeForm.fields.attribute, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { renderOption: function (o) { return (_this.alreadyAdded(o === null || o === void 0 ? void 0 : o.value) ? React.createElement("span", { className: 'color--secondary-dark-3' }, o.name) : o.name); }, searchable: true, multiple: false, className: 'select-input-basic' })),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal mr-2', title: 'Jellemző hozzáadása', disabled: this.state.selectedIsInList, onClick: function () { return _this.addAttribute(); } }),
                !!this.state.selectedIsInList && React.createElement("span", { className: 'color--status-error-1' }, "A jellemz\u0151 m\u00E1r szerepel a list\u00E1ban!")),
            React.createElement("div", { className: "col-12" },
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: 'Mentés', onClick: function () { return _this.submitAttributes(); } })));
    };
    CategoryAttributeUpdateScreen.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(); } });
    };
    CategoryAttributeUpdateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('attribute.title.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('attribute.adminTitle'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('showList'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('showProduct'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('listOrder'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('productOrder'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('adminOrder'))));
    };
    CategoryAttributeUpdateScreen.prototype.setHeaderCell = function (p) {
        return {
            enableSort: false,
            title: (0, trans_1.trans)("category-attribute.table.header.".concat(p)),
            property: p
        };
    };
    CategoryAttributeUpdateScreen.prototype.alreadyAdded = function (attributeId) {
        var _a;
        return (_a = this.state.category) === null || _a === void 0 ? void 0 : _a.b2cAttributeValues.map(function (v) { var _a; return (_a = v === null || v === void 0 ? void 0 : v.attribute) === null || _a === void 0 ? void 0 : _a._id; }).includes(attributeId);
    };
    CategoryAttributeUpdateScreen.prototype.renderItems = function () {
        var _this = this;
        var _a;
        return (_a = this.state.category) === null || _a === void 0 ? void 0 : _a.b2cAttributeValues.map(function (item) {
            var _a;
            return (React.createElement(category_attribute_row_1.CategoryAttributeRow, { key: (_a = item === null || item === void 0 ? void 0 : item.attribute) === null || _a === void 0 ? void 0 : _a._id, onChange: function (attributeId, value) { return _this.updateAttributeValue(attributeId, value); }, onRemoveAttribute: function (attributeId) { return _this.removeAttribute(attributeId); }, categoryAttribute: item }));
        });
    };
    CategoryAttributeUpdateScreen.prototype.updateAttributeValue = function (attributeId, value) {
        var updatedAttributeValues = this.state.category.b2cAttributeValues.map(function (v) {
            var _a;
            if (((_a = v === null || v === void 0 ? void 0 : v.attribute) === null || _a === void 0 ? void 0 : _a._id) === attributeId) {
                return value;
            }
            return v;
        });
        var category = this.state.category;
        category.b2cAttributeValues = updatedAttributeValues;
        this.setState({ category: category });
    };
    CategoryAttributeUpdateScreen.prototype.addAttribute = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var attributeId, attribute, category, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        attributeId = (_a = this.addAttributeForm.toJSON()) === null || _a === void 0 ? void 0 : _a.attribute;
                        if (!attributeId) {
                            return [2];
                        }
                        return [4, this.repository.get("/admin/b2c/category-attribute/".concat(attributeId))];
                    case 1:
                        attribute = _b.sent();
                        category = this.state.category;
                        category.b2cAttributeValues.push({
                            attribute: attribute,
                            listOrder: category.b2cAttributeValues.length,
                            adminOrder: category.b2cAttributeValues.length,
                            productOrder: category.b2cAttributeValues.length,
                            showList: false,
                            showProduct: false
                        });
                        this.setState({ category: category });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('Jellemző hozzáadva listához'));
                        this.addAttributeForm.clear();
                        this.addAttributeForm.updateFields({});
                        return [3, 3];
                    case 2:
                        err_1 = _b.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CategoryAttributeUpdateScreen.prototype.removeAttribute = function (attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var category, categoryAttributes;
            return __generator(this, function (_a) {
                category = this.state.category;
                categoryAttributes = category.b2cAttributeValues.filter(function (v) { var _a; return ((_a = v.attribute) === null || _a === void 0 ? void 0 : _a._id) !== attributeId; });
                category.b2cAttributeValues = categoryAttributes;
                this.addAttributeForm.clear();
                this.addAttributeForm.updateFields({});
                this.setState({ category: category });
                return [2];
            });
        });
    };
    CategoryAttributeUpdateScreen.prototype.submitAttributes = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/b2c/category/".concat((_a = this.state.category) === null || _a === void 0 ? void 0 : _a._id), {
                                data: { b2cAttributeValues: this.state.category.b2cAttributeValues }
                            })];
                    case 1:
                        response = _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('category-attribute.update-success'));
                        return [3, 3];
                    case 2:
                        err_2 = _b.sent();
                        handle_toasts_1.HandleToasts.error(err_2, (0, trans_1.trans)('category-attribute.update-error'));
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return CategoryAttributeUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.CategoryAttributeUpdateScreen = CategoryAttributeUpdateScreen;
