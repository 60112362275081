"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moneyFormat = exports.hufFormat = exports.formatNumber = exports.splitToMoney = void 0;
var lodash_1 = require("lodash");
var splitToMoney = function (amount) {
    if (amount !== 0 && !amount) {
        return;
    }
    var decimalPart = amount.toString().split('.')[0];
    var partialPart = amount.toString().split('.')[1];
    if (decimalPart.toString().length <= 3) {
        return decimalPart.toString();
    }
    var end = decimalPart.toString().slice(decimalPart.toString().length - 3, decimalPart.toString().length);
    var carry = decimalPart.toString().slice(0, decimalPart.toString().length - 3);
    if (carry.length > 3) {
        return "".concat((0, exports.splitToMoney)(carry), " ").concat(end);
    }
    if (partialPart) {
        return "".concat(carry, " ").concat(end, ".").concat(partialPart);
    }
    return "".concat(carry, " ").concat(end);
};
exports.splitToMoney = splitToMoney;
var formatNumber = function (amount, isFractional) {
    if (isFractional === void 0) { isFractional = false; }
    var decimal = Math.floor(amount);
    var decimalString = decimal.toString();
    var decimalStringArray = decimalString.split('');
    var decimalChunk = (0, lodash_1.chunk)(decimalStringArray.reverse(), 3);
    var decimalJoined = decimalChunk
        .reverse()
        .map(function (item) { return item.reverse().join(''); })
        .join(' ');
    if (isFractional) {
        var fractionalPart = Math.floor((parseFloat((amount - decimal).toPrecision(3)) * 100)).toString();
        return "".concat(decimalJoined, ",").concat(fractionalPart);
    }
    return decimalJoined;
};
exports.formatNumber = formatNumber;
var hufFormat = function (amount) {
    if ((parseInt("".concat(amount), 10) !== 0 && !amount) || !!Number.isNaN(Number(amount))) {
        return '-';
    }
    var roundAmount = Math.round(+amount);
    var number = (0, exports.splitToMoney)(roundAmount);
    return "".concat(number, " Ft");
};
exports.hufFormat = hufFormat;
var moneyFormat = function (amount, currency, defaultReturnVal) {
    if (currency === void 0) { currency = 'HUF'; }
    if (defaultReturnVal === void 0) { defaultReturnVal = '-'; }
    var isFractional = currency.toUpperCase() !== 'HUF';
    if (+amount !== 0 && !amount) {
        return defaultReturnVal;
    }
    var number = (0, exports.formatNumber)(+amount, isFractional);
    return "".concat(number, " ").concat(getCurrency(currency));
};
exports.moneyFormat = moneyFormat;
var getCurrency = function (currency) {
    switch (currency.toUpperCase()) {
        case 'HUF':
            return 'Ft';
        case 'EUR':
            return '€';
    }
};
