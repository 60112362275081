"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockTransactionCreateTableStockProductRow = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var React = require("react");
var StockTransactionCreateTableStockProductRow = (function (_super) {
    __extends(StockTransactionCreateTableStockProductRow, _super);
    function StockTransactionCreateTableStockProductRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            opened: true,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                ref: new field_1.Field({
                    name: 'ref',
                    value: _this.props.item._id || null,
                }),
                productLot: new field_1.Field({
                    name: 'productLot',
                    value: _this.props.item.productLot || null,
                }),
                type: new field_1.Field({
                    name: 'type',
                    value: _this.props.item.type || '',
                }),
                amount: new field_1.Field({
                    name: 'amount',
                    value: '',
                    label: 'db',
                }),
            }
        });
        return _this;
    }
    StockTransactionCreateTableStockProductRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var owners = this.getOwners();
        if ((this.props.item.type === 'cp-reservation' || this.props.item.type === 'ch-reservation') && ((_a = this.props.item.locks) === null || _a === void 0 ? void 0 : _a.length)) {
            return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: "".concat(this.props.opened || this.props.item.type === 'available' ? 'opened' : 'closed') },
                React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'flex-column wrapper' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                        React.createElement("div", { style: { width: 96 } }),
                        React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'align-items-center content', onClick: function () { return _this.setState({ opened: !_this.state.opened }); } },
                            React.createElement("div", { className: 'chevron-container' },
                                React.createElement("i", { className: "fal ".concat(this.state.opened ? 'fa-chevron-up' : 'fa-chevron-down') })),
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 66 },
                                React.createElement("p", null, owners)),
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end pr-3' },
                                React.createElement("p", null, (_b = this.props.item) === null || _b === void 0 ? void 0 :
                                    _b.amount,
                                    " db")),
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 17 },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.amount, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { max: (_c = this.props.item) === null || _c === void 0 ? void 0 : _c.amount, type: 'number', className: 'text-input-basic' }))))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: this.props.item.type, className: "".concat(this.state.opened ? 'opened' : 'closed') },
                        React.createElement("div", { style: { width: 128 } }), (_d = this.props.item.locks) === null || _d === void 0 ? void 0 :
                        _d.map(function (lock, index) {
                            return React.createElement(table_body_row_1.TableBodyRow, { key: index },
                                React.createElement(table_body_cell_1.TableBodyCell, { width: 66 },
                                    React.createElement("p", null, _this.getTypeTitle(lock.type))),
                                React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end pr-3' },
                                    React.createElement("p", null,
                                        "\u2211 ",
                                        lock.amount,
                                        " db")),
                                React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end' },
                                    React.createElement("p", null,
                                        "\u2211 ",
                                        lock.amount,
                                        " db")));
                        }))));
        }
        return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: "".concat(this.props.opened || this.props.item.type === 'available' ? 'opened' : 'closed') },
            React.createElement("div", { style: { width: this.props.item.type === 'available' ? 64 : 96 } }),
            React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: "".concat(this.props.item.type === 'available' && 'available', " content") },
                React.createElement("div", { style: { width: 32 } }),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 66 },
                    React.createElement("p", null, owners)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end pr-3' },
                    React.createElement("p", null, (_e = this.props.item) === null || _e === void 0 ? void 0 :
                        _e.amount,
                        " db")),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 17 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.amount, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { max: (_f = this.props.item) === null || _f === void 0 ? void 0 : _f.amount, type: 'number', className: 'text-input-basic' })))));
    };
    StockTransactionCreateTableStockProductRow.prototype.getOwners = function () {
        var _a, _b, _c;
        var item = this.props.item;
        if (item.type === 'cp-reservation') {
            return (_b = (_a = item.__meta) === null || _a === void 0 ? void 0 : _a.owners) === null || _b === void 0 ? void 0 : _b.map(function (owner) { return owner.name; }).join(', ');
        }
        if (item.type === 'ch-reservation') {
            return (_c = item.channels) === null || _c === void 0 ? void 0 : _c.join(', ');
        }
        if (item.type === 'order-lock') {
            return 'Megrendelés zárolás';
        }
        if (item.type === 'issue-lock') {
            return 'Kitárolás zárolás';
        }
        return 'Szabad készlet';
    };
    StockTransactionCreateTableStockProductRow.prototype.getTypeTitle = function (type) {
        switch (type) {
            case 'cp-reservation': return 'Vevő cég foglalás';
            case 'ch-reservation': return 'Csatorna foglalás';
            case 'order-lock': return 'Megrendelés zárolás';
            case 'issue-lock': return 'Kitárolás zárolás';
            default: return 'Szabad készlet';
        }
    };
    return StockTransactionCreateTableStockProductRow;
}(abstract_component_1.AbstractComponent));
exports.StockTransactionCreateTableStockProductRow = StockTransactionCreateTableStockProductRow;
