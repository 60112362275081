"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockReservationCustomerPartnerRelease = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
require("../b2b-stock-reservation.scss");
var B2bStockReservationCustomerPartnerRelease = (function (_super) {
    __extends(B2bStockReservationCustomerPartnerRelease, _super);
    function B2bStockReservationCustomerPartnerRelease() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                comment: new field_1.Field({
                    name: 'comment',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-reservation.release.cp.form.comment.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                amount: new field_1.Field({
                    name: 'amount',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-reservation.release.cp.form.amount.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            reservation: null,
            loading: false,
            error: '',
        };
        return _this;
    }
    B2bStockReservationCustomerPartnerRelease.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockReservationCustomerPartnerRelease.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/stock-reservation/stock-product-customer-partner-reservation/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        response = _c.sent();
                        this.setState({ reservation: response, loading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ error: e_1.message, loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bStockReservationCustomerPartnerRelease.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var owners = (_c = (_b = (_a = this.state.reservation) === null || _a === void 0 ? void 0 : _a.__meta) === null || _b === void 0 ? void 0 : _b.owners) === null || _c === void 0 ? void 0 : _c.join(' | ');
        return React.createElement("div", { className: 'B2bStockReservationReleaseScreen CustomerPartnerScreen row w-100' },
            React.createElement("div", { className: 'col reservation-release-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/customer-partner-stock-reservation'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('stock-reservation.release.cp.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col mb-4' },
                        React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('stock-reservation.release.cp.data.owners')),
                        React.createElement("p", { className: 'fw-700 ml-2' }, owners)),
                    React.createElement(form_1.Form, { className: 'w-100' },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled, rows: 6, className: 'textarea-input-basic col-14 mb-7' })),
                        React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('stock-reservation.release.cp.data.products')),
                        React.createElement(table_1.Table, { className: 'table-basic StockReservationReleaseTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.reservation); } })))),
            React.createElement("div", { className: 'col stock-release-save-box-wrapper' }, this.renderSaveBox()));
    };
    B2bStockReservationCustomerPartnerRelease.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 36 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('current-amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('amount-to-release'))));
    };
    B2bStockReservationCustomerPartnerRelease.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-reservation.release.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockReservationCustomerPartnerRelease.prototype.renderItems = function (item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        var ean = (item === null || item === void 0 ? void 0 : item.ean) || '-';
        var sku = ((_b = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = item === null || item === void 0 ? void 0 : item.__meta) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.manufacturer) || '-';
        var title = ((_f = (_e = item === null || item === void 0 ? void 0 : item.__meta) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.title) || '-';
        var vintage = ((_h = (_g = item === null || item === void 0 ? void 0 : item.__meta) === null || _g === void 0 ? void 0 : _g.product) === null || _h === void 0 ? void 0 : _h.vintage) || '-';
        var capacity = ((_k = (_j = item === null || item === void 0 ? void 0 : item.__meta) === null || _j === void 0 ? void 0 : _j.product) === null || _k === void 0 ? void 0 : _k.capacity) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, { key: item === null || item === void 0 ? void 0 : item.ean },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title,
                        " ",
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 36 },
                React.createElement("p", null, (_m = (_l = item === null || item === void 0 ? void 0 : item.__meta) === null || _l === void 0 ? void 0 : _l.storagePath) === null || _m === void 0 ? void 0 : _m.map(function (s) { return s.title; }).join('/'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                React.createElement("p", null, item === null || item === void 0 ? void 0 : item.lot)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                React.createElement("p", null, item === null || item === void 0 ? void 0 :
                    item.amount,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.amount, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.props.disabled, className: 'text-input-basic w-100', type: 'number', min: 0, max: (_o = this.state.reservation) === null || _o === void 0 ? void 0 : _o.amount }))));
    };
    B2bStockReservationCustomerPartnerRelease.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'stock-release-save-box mb-6' },
                React.createElement("h5", { className: 'mb-6' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Indítás', iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-primary-normal button-size-normal', disabled: false, onClick: function () { return _this.submitRelease(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockReservationCustomerPartnerRelease.prototype.submitRelease = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var valid, formData, data, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _c.sent();
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('stock-release.form.missing.required'));
                        }
                        formData = this.form.toJSON();
                        if (formData.amount < 1 || formData.amount > ((_a = this.state.reservation) === null || _a === void 0 ? void 0 : _a.amount)) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('stock-release.form.wrong.amount'));
                        }
                        data = {
                            comment: formData === null || formData === void 0 ? void 0 : formData.comment,
                            plan: [
                                {
                                    reservationId: (_b = this.state.reservation) === null || _b === void 0 ? void 0 : _b._id,
                                    amount: parseInt(formData === null || formData === void 0 ? void 0 : formData.amount, 10),
                                }
                            ]
                        };
                        return [4, this.repository.post('/b2b/stock-reservation/customer-partner-reservation-release', { data: data })];
                    case 2:
                        _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-reservation.release.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo('/customer-partner-stock-reservation')];
                    case 3:
                        e_2 = _c.sent();
                        this.setState({ loading: false, error: e_2.message });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return B2bStockReservationCustomerPartnerRelease;
}(abstract_component_1.AbstractComponent));
exports.B2bStockReservationCustomerPartnerRelease = B2bStockReservationCustomerPartnerRelease;
