"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var permissions_1 = require("../../common/libs/permissions/permissions");
var badge_1 = require("../../components/libs/badge/badge");
var modal_1 = require("../../components/modal/modal");
var modify_price_batch_1 = require("./modals/modify-price-batch");
var price_show_modal_1 = require("./modals/price-show.modal");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var PricingScreen = (function (_super) {
    __extends(PricingScreen, _super);
    function PricingScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : ''
            },
        });
        _this.state = {
            total: 0,
            skus: []
        };
        return _this;
    }
    PricingScreen.prototype.toggleAllSkus = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var skus;
            return __generator(this, function (_f) {
                skus = (_d = (_c = (_b = (_a = this.control) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return !!(item === null || item === void 0 ? void 0 : item.price); })) === null || _d === void 0 ? void 0 : _d.map(function (item) { return item.sku; });
                if ((_e = this.state.skus) === null || _e === void 0 ? void 0 : _e.length) {
                    this.setState({ skus: [] });
                }
                else {
                    this.setState({ skus: skus });
                }
                return [2];
            });
        });
    };
    PricingScreen.prototype.toggleSku = function (sku) {
        return __awaiter(this, void 0, void 0, function () {
            var exists;
            return __generator(this, function (_a) {
                exists = this.state.skus.includes(sku);
                if (exists) {
                    this.setState({
                        skus: this.state.skus.filter(function (item) { return item !== sku; })
                    });
                }
                else {
                    this.setState({
                        skus: __spreadArray(__spreadArray([], __read(this.state.skus), false), [sku], false)
                    });
                }
                return [2];
            });
        });
    };
    PricingScreen.prototype.isSkuSelected = function (sku) {
        return this.state.skus.includes(sku);
    };
    PricingScreen.prototype.isSkuSelectorDisabled = function (item) {
        return !(item === null || item === void 0 ? void 0 : item.price);
    };
    PricingScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", null,
            this.renderModals(),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('pricing.list.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.PRICING.channelPricing.update },
                                React.createElement(button_1.Button, { disabled: !((_a = this.state.skus) === null || _a === void 0 ? void 0 : _a.length), onClick: function () { return _this.$createBatch.open(_this.state.skus); }, title: (0, trans_1.trans)('pricing.list.modify-batch.title'), iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' }))))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return (_this.$list = ref); }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/price', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    PricingScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    PricingScreen.prototype.renderTableHeader = function () {
        var _this = this;
        var _a;
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 5, title: React.createElement("input", { type: "checkbox", checked: (_a = this.state.skus) === null || _a === void 0 ? void 0 : _a.length, onChange: function () { return _this.toggleAllSkus(); } }), property: "" }),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('manufacturer'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 40 }, this.setHeaderCell('amount'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('isProductActive'))));
    };
    PricingScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("pricing.table.header.".concat(p)),
            property: p
        };
    };
    PricingScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    PricingScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    PricingScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: "cursor-pointer" },
                React.createElement("input", { type: "checkbox", checked: this.isSkuSelected(item.sku), onChange: function () { return _this.toggleSku(item.sku); }, disabled: this.isSkuSelectorDisabled(item) })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: "cursor-pointer", onChange: function () { return _this.toggleSku(item.sku); }, onClick: function () { return _this.$showModal.open(item.sku); } },
                React.createElement("span", null, (_b = (_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: "cursor-pointer", onClick: function () { return _this.$showModal.open(item.sku); } },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: "cursor-pointer", onChange: function () { return _this.toggleSku(item.sku); }, onClick: function () { return _this.$showModal.open(item.sku); } },
                React.createElement("span", null, (_e = (_d = (_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.manufacturer) === null || _d === void 0 ? void 0 : _d.name) === null || _e === void 0 ? void 0 : _e.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 40, onClick: function () { return _this.$showModal.open(item.sku); } },
                !!(item === null || item === void 0 ? void 0 : item.price) && React.createElement("div", null,
                    ((_f = item === null || item === void 0 ? void 0 : item.price) === null || _f === void 0 ? void 0 : _f.type) === 'sale' && React.createElement("div", null,
                        React.createElement("span", null,
                            React.createElement("b", null, "Eredeti Brutt\u00F3"),
                            ": ",
                            (0, currency_parser_1.hufFormat)((_g = item === null || item === void 0 ? void 0 : item.price) === null || _g === void 0 ? void 0 : _g.grossOriginal)),
                        React.createElement("br", null),
                        React.createElement("span", null,
                            React.createElement("b", null, "Eredeti Nett\u00F3"),
                            ": ",
                            (0, currency_parser_1.hufFormat)((_h = item === null || item === void 0 ? void 0 : item.price) === null || _h === void 0 ? void 0 : _h.netOriginal))),
                    React.createElement("div", null,
                        React.createElement("span", null,
                            React.createElement("b", null, "Brutt\u00F3"),
                            ": ",
                            (0, currency_parser_1.hufFormat)((_j = item === null || item === void 0 ? void 0 : item.price) === null || _j === void 0 ? void 0 : _j.gross)),
                        React.createElement("br", null),
                        React.createElement("span", null,
                            React.createElement("b", null, "Nett\u00F3"),
                            ": ",
                            (0, currency_parser_1.hufFormat)((_k = item === null || item === void 0 ? void 0 : item.price) === null || _k === void 0 ? void 0 : _k.net)))),
                !(item === null || item === void 0 ? void 0 : item.price) && React.createElement("div", null,
                    React.createElement("span", null, "Ebben a pillanatban nincs \u00E9rv\u00E9nyes \u00E1r"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20, onClick: function () { return _this.$showModal.open(item.sku); } },
                (item === null || item === void 0 ? void 0 : item.isProductActive) && React.createElement(badge_1.Badge, { title: (0, trans_1.trans)('pricing.product-status.exist'), type: 'success' }),
                !(item === null || item === void 0 ? void 0 : item.isProductActive) && React.createElement(badge_1.Badge, { title: (0, trans_1.trans)('pricing.product-status.deleted'), type: 'warning' })));
    };
    PricingScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    PricingScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(price_show_modal_1.PriceShowModal, { sku: ctx.state.args }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$createBatch = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(modify_price_batch_1.ModifyPriceBatch, { skus: ctx.state.args }); } }));
    };
    return PricingScreen;
}(abstract_component_1.AbstractComponent));
exports.PricingScreen = PricingScreen;
