"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getListState = exports.saveListState = void 0;
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var saveListState = function (page, pageState) {
    var state = local_store_1.LocalStore.getItem('LIST_PAGE_STATES') || {};
    var currentState = !!state ? state[page] : {};
    state[page] = __assign(__assign({}, currentState), pageState);
    local_store_1.LocalStore.setItem('LIST_PAGE_STATES', state);
};
exports.saveListState = saveListState;
var getListState = function (page) {
    var _a;
    return ((_a = local_store_1.LocalStore.getItem('LIST_PAGE_STATES')) === null || _a === void 0 ? void 0 : _a[page]) || {};
};
exports.getListState = getListState;
