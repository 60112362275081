module.exports = {
    'sales-targets.screen.title': 'Értékesítők',
    'sales-targets.update.year': 'Év választó',
    'sales-targets.form.january': 'Január',
    'sales-targets.form.february': 'Február',
    'sales-targets.form.march': 'Március',
    'sales-targets.form.april': 'Április',
    'sales-targets.form.may': 'Május',
    'sales-targets.form.june': 'Június',
    'sales-targets.form.july': 'Július',
    'sales-targets.form.august': 'Augusztus',
    'sales-targets.form.september': 'Szeptember',
    'sales-targets.form.october': 'Október',
    'sales-targets.form.november': 'November',
    'sales-targets.form.december': 'December',
    'sales-targets.form.submission.success': 'Sikeres mentés',
    'sales-targets.form.submit.button': 'Mentés',
};
