"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationControl = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var admin_pagination_1 = require("../../admin-pagination/admin-pagination");
var PaginationControl = (function (_super) {
    __extends(PaginationControl, _super);
    function PaginationControl() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            limit: (_b = (_a = _this.props.control$.getQuery()) === null || _a === void 0 ? void 0 : _a._limit) !== null && _b !== void 0 ? _b : 10,
            page: (_d = (_c = _this.props.control$.getQuery()) === null || _c === void 0 ? void 0 : _c._page) !== null && _d !== void 0 ? _d : 0,
            total: (_f = (_e = _this.props.control$.getQuery()) === null || _e === void 0 ? void 0 : _e._total) !== null && _f !== void 0 ? _f : 0
        };
        return _this;
    }
    PaginationControl.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.props.control$.response$.subscribe(function (response) { return _this.setState({
            limit: response.limit,
            page: response.page,
            total: response.total,
        }, function () { return null; }); }));
    };
    PaginationControl.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'row py-4' },
            React.createElement("div", { className: 'col-4' }, this.renderLimitSelector()),
            React.createElement("div", { className: "col-16 display-flex justify-content-center align-items-center" },
                React.createElement(admin_pagination_1.AdminPagination, { onChange: function (e, data) { return _this.handlePageChange(e, data); }, total: this.state.total, page: this.state.page, limit: this.state.limit })),
            React.createElement("div", { className: "col-4" }));
    };
    PaginationControl.prototype.renderLimitSelector = function () {
        var _this = this;
        var props = {
            placeholder: '',
            value: this.state.limit,
            options: this.props.limitOptions,
            name: '',
            label: (0, trans_1.trans)('pagination.limit.label'),
        };
        return React.createElement("div", null,
            React.createElement(select_input_1.SelectInput, __assign({}, props, { unclearable: true, onChange: function (value) { return _this.handleLimitChange(value); }, disabled: false, className: 'select-input-basic' })));
    };
    PaginationControl.prototype.handlePageChange = function (e, page) {
        this.props.control$.page(page);
    };
    PaginationControl.prototype.handleLimitChange = function (value) {
        this.props.control$.limit(parseInt(value, 10));
    };
    return PaginationControl;
}(abstract_component_1.AbstractComponent));
exports.PaginationControl = PaginationControl;
