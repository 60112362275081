"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementProductsTableRowComponent = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var lodash_1 = require("lodash");
var React = require("react");
var fact_input_1 = require("./fact-input");
var plan_input_1 = require("./plan-input");
var ProcurementProductsTableRowComponent = (function (_super) {
    __extends(ProcurementProductsTableRowComponent, _super);
    function ProcurementProductsTableRowComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            item: _this.props.item || {},
            loadUnderProcurement: true,
            underProcurement: '',
            loadFreeStock: true,
            freeStock: '',
            loadProductRotation: false,
            productRotation: {},
        };
        return _this;
    }
    ProcurementProductsTableRowComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchUnderProcurement()];
                    case 1:
                        _a.sent();
                        return [4, this.fetchFreeStock()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ProcurementProductsTableRowComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.item, prevProps.item)) {
            this.setState({ item: this.props.item });
        }
    };
    ProcurementProductsTableRowComponent.prototype.fetchUnderProcurement = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.setState({ loadUnderProcurement: true });
                        return [4, this.repository.get("/b2b/procurement/".concat(this.state.item.productId, "/underProcurement"), {
                                params: {
                                    procurementId: this.props.procurementId
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        this.setState({ underProcurement: response, loadUnderProcurement: false });
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.setState({ loadUnderProcurement: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProcurementProductsTableRowComponent.prototype.fetchFreeStock = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.setState({ loadFreeStock: true });
                        return [4, this.repository.get("/b2b/procurement/".concat(this.state.item.productId, "/freeStock"))];
                    case 1:
                        response = _a.sent();
                        this.setState({ freeStock: response, loadFreeStock: false });
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.setState({ loadFreeStock: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProcurementProductsTableRowComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
        var ean = ((_b = (_a = this.state.item) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.eanCode) || ((_c = this.state.item) === null || _c === void 0 ? void 0 : _c.ean) || '-';
        var sku = ((_e = (_d = this.state.item) === null || _d === void 0 ? void 0 : _d.product) === null || _e === void 0 ? void 0 : _e.sku) || ((_g = (_f = this.state.item) === null || _f === void 0 ? void 0 : _f.productArtifact) === null || _g === void 0 ? void 0 : _g.sku) || '-';
        var manufacturer = ((_k = (_j = (_h = this.state.item) === null || _h === void 0 ? void 0 : _h.product) === null || _j === void 0 ? void 0 : _j.manufacturerName) === null || _k === void 0 ? void 0 : _k.hu) || ((_m = (_l = this.state.item) === null || _l === void 0 ? void 0 : _l.productArtifact) === null || _m === void 0 ? void 0 : _m.manufacturer) || '-';
        var title = ((_q = (_p = (_o = this.state.item) === null || _o === void 0 ? void 0 : _o.product) === null || _p === void 0 ? void 0 : _p.title) === null || _q === void 0 ? void 0 : _q.hu) || ((_s = (_r = this.state.item) === null || _r === void 0 ? void 0 : _r.productArtifact) === null || _s === void 0 ? void 0 : _s.title) || '-';
        var vintage = ((_u = (_t = this.state.item) === null || _t === void 0 ? void 0 : _t.product) === null || _u === void 0 ? void 0 : _u.vintage) || ((_w = (_v = this.state.item) === null || _v === void 0 ? void 0 : _v.productArtifact) === null || _w === void 0 ? void 0 : _w.vintage) || '-';
        var capacity = ((_0 = (_z = (_y = (_x = this.state.item) === null || _x === void 0 ? void 0 : _x.product) === null || _y === void 0 ? void 0 : _y.b2cAttributeSearchValues) === null || _z === void 0 ? void 0 : _z.capacity) === null || _0 === void 0 ? void 0 : _0[1]) || ((_2 = (_1 = this.state.item) === null || _1 === void 0 ? void 0 : _1.productArtifact) === null || _2 === void 0 ? void 0 : _2.capacity) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, null,
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title,
                        " ",
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 }, this.state.loadProductRotation
                ? this.renderLoader()
                : React.createElement("p", null, (_4 = (_3 = this.state.productRotation) === null || _3 === void 0 ? void 0 : _3.thirty) !== null && _4 !== void 0 ? _4 : '-',
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 }, this.state.loadProductRotation
                ? this.renderLoader()
                : React.createElement("p", null, (_6 = (_5 = this.state.productRotation) === null || _5 === void 0 ? void 0 : _5.sixty) !== null && _6 !== void 0 ? _6 : '-',
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 8 }, this.state.loadProductRotation
                ? this.renderLoader()
                : React.createElement("p", null, (_8 = (_7 = this.state.productRotation) === null || _7 === void 0 ? void 0 : _7.ninety) !== null && _8 !== void 0 ? _8 : '-',
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 }, this.state.loadFreeStock
                ? this.renderLoader()
                : React.createElement("p", null, (_9 = this.state.freeStock) !== null && _9 !== void 0 ? _9 : '-',
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 }, this.state.loadUnderProcurement
                ? this.renderLoader()
                : React.createElement("p", null, (_10 = this.state.underProcurement) !== null && _10 !== void 0 ? _10 : '-',
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                React.createElement(plan_input_1.PlanInput, { amount: (_11 = this.state.item) === null || _11 === void 0 ? void 0 : _11.amount, ref: function (ref) { return _this.props.planInputRefs[_this.state.item.productId] = ref; }, setPlan: function (amount) { return _this.props.handleProductPlanAmountChange(_this.state.item.productId, amount); } })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                React.createElement(fact_input_1.FactInput, { disabled: !(this.props.procurementAction === 'update'), amount: this.props.factAmount, ref: function (ref) { return _this.props.factInputRefs[_this.state.item.productId] = ref; }, setFact: function (amount) { return _this.props.handleProductFactAmountChange(amount); } })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: 'justify-content-center' }, this.deleteButton(this.state.item.productId)));
    };
    ProcurementProductsTableRowComponent.prototype.renderLoader = function () {
        return React.createElement("i", { className: 'far fa-spinner-third fa-spin' });
    };
    ProcurementProductsTableRowComponent.prototype.deleteButton = function (productId) {
        var _this = this;
        return React.createElement(button_1.Button, { className: 'delete-button', onClick: function () { return _this.props.showDeleteProductModal(productId); }, iconLeft: 'fas fa-trash' });
    };
    return ProcurementProductsTableRowComponent;
}(abstract_component_1.AbstractComponent));
exports.ProcurementProductsTableRowComponent = ProcurementProductsTableRowComponent;
