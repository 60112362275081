module.exports = {
    'supplier-partner.list.title': 'Beszállító cégek',
    'supplier-partner.title': 'Beszállító cég',
    'supplier-partner.list.button-create.title': 'Új cég létrehozása',
    'supplier-partner.update.save-box.button.enable-edit': 'Cég szerkesztése',
    'supplier-partner.update.save-box.button.save': 'Mentés',
    'supplier-partner.table.header.name': 'Cégnév',
    'supplier-partner.table.header.lastOrder': 'Utolsó rendelés',
    'supplier-partner.table.header.orderSummary': 'Összes rendelés',
    'supplier-partner.table.header.commitment': 'Forgalmi vállalás',
    'supplier-partner.table.header.status': 'Státusz',
    'supplier-partner.table.header.createdAt': 'Létrehozva',
    'supplier-partner.status.active': 'Aktív',
    'supplier-partner.status.inactive': 'Inaktív',
    'supplier-partner.create.save-box.button.save': 'Létrehozás',
    'supplier-partner.update.subtitle.basic-data.company-data': 'Cégadatok',
    'supplier-partner.update.subtitle.basic-data.address': 'Székhely',
    'supplier-partner.update.subtitle.basic-data.other': 'Egyéb adatok',
    'supplier-partner.update.subtitle.basic-data.other.hint': 'Szállítási idő, minimum rendelési mennyiség, egyéb',
    'supplier-partner.basic-data.form.field.name.label': 'Cégnév',
    'supplier-partner.basic-data.form.field.partnerDiscountNotApplicable.label': 'Vevő cég kedvezménye nem alkalmazható.',
    'supplier-partner.basic-data.form.field.brandName.label': 'Márkanév',
    'supplier-partner.basic-data.form.field.operatingPermitNumber.label': 'Enegedélyszám',
    'supplier-partner.basic-data.form.field.taxNumber.label': 'Adószám',
    'supplier-partner.basic-data.form.field.euTaxNumber.label': 'EU adószám',
    'supplier-partner.basic-data.form.field.businessClerk.label': 'Értékesítő',
    'supplier-partner.basic-data.form.field.zipCode.label': 'Irányítószám',
    'supplier-partner.basic-data.form.field.country.label': 'Ország',
    'supplier-partner.basic-data.form.field.city.label': 'Város',
    'supplier-partner.basic-data.form.field.address.label': 'Cím',
    'supplier-partner.basic-data.form.field.comment.label': 'Megjegyzés',
    'supplier-partner.basic-data.form.field.bankAccountNumber.label': 'Bankszámlaszám',
    'supplier-partner.basic-data.form.field.paymentDueDate.label': 'Fizetési határidő',
    'supplier-partner.basic-data.form.field.billingType.label': 'Számla típusa',
    'supplier-partner.commitment-box.form.field.commitment.label': 'Forgalmi vállalás (Ft)',
    'supplier-partner.credit-box.form.field.credit.label': 'Hitelkeret',
    'supplier-partner.credit-box.form.field.categories.label': 'Partner kategória',
    'supplier-partner.basic-data.form.field.documentOperatingPermit.label': 'Jövedéki engedély',
    'supplier-partner.basic-data.form.field.documentCopyOfSignatureTitle.label': 'Aláírási címpéldány',
    'address.table.header.name': 'Elnevezés',
    'address.table.header.zipCode': 'IRSZ',
    'address.table.header.country': 'Ország',
    'address.table.header.city': 'Város',
    'address.table.header.address': 'Cím',
    'address.table.header.comment': 'Megjegyzés',
    'address.form.field.name.label': 'Elnevezés',
    'address.form.field.country.label': 'Ország',
    'address.form.field.zipCode.label': 'IRSZ',
    'address.form.field.city.label': 'Város',
    'address.form.field.address.label': 'Cím',
    'address.form.field.comment.label': 'Megjegyzés',
    'contact.table.header.name': 'Név',
    'contact.table.header.email': 'E-mail',
    'contact.table.header.phone': 'Telefonszám',
    'contact.table.header.position': 'Pozíció ',
    'contact.table.header.comment': 'Megjegyzés ',
    'contact.form.field.lastName.label': 'Vezetéknév',
    'contact.form.field.firstName.label': 'Keresztnév',
    'contact.form.field.email.label': 'E-mail',
    'contact.form.field.phone.label': 'Telefonszám',
    'contact.form.field.position.label': 'Pozíció ',
    'contact.form.field.comment.label': 'Megjegyzés ',
    'connected-partner.table.header.name': 'Cégnév',
    'connected-partner.table.header.debit': 'Tartozás',
    'connected-partner.table.header.bill': 'Számla',
    'connected-partner.table.header.status': 'Státusz',
    'connected-partner.form.field.name.label': 'Cég',
    'owner.table.header.name': 'Név',
    'owner.table.header.email': 'E-mail',
    'owner.table.header.status': 'Státusz',
    'owner.form.field.user.label': 'Felhasználó',
    'contract.table.header.identifier': 'Azonosító',
    'contract.table.header.type': 'Típus',
    'contract.table.header.date': 'Időtartam',
    'contract.table.header.contact': 'Kapcsolattartó',
    'contract.table.header.files': 'Fájl',
    'contract.table.header.description': 'Leírás',
    'contract.form.field.identifier.label': 'Azonosító',
    'contract.form.field.type.label': 'Típus',
    'contract.form.field.validFrom.label': 'Időtartam',
    'contract.form.field.validTo.label': '',
    'contract.form.field.contact.label': 'Kapcsolattartó',
    'contract.form.field.files.label': 'Fájl',
    'contract.form.field.description.label': 'Leírás',
    'supplier-partner.update.success.toast': 'Sikeresen módosítás',
    'supplier-partner.create.success.toast': 'Sikeresen létrehozás',
    'supplier-partner.status-update.success.toast': 'Sikeresen státusz módosítás',
};
