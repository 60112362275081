"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelPricingShowModal = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var lodash_1 = require("lodash");
var React = require("react");
var handle_toasts_1 = require("../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../components/modal/modal");
var edit_modifier_modal_1 = require("./edit-modifier.modal");
var edit_price_modal_1 = require("./edit-price.modal");
var modifier_table_1 = require("./modifier-table");
var price_table_1 = require("./price-table");
var ChannelPricingShowModal = (function (_super) {
    __extends(ChannelPricingShowModal, _super);
    function ChannelPricingShowModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            product: _this.props.product || {},
            activeTab: 'price',
        };
        return _this;
    }
    ChannelPricingShowModal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!(0, lodash_1.isEqual)(this.props.product, prevProps.product)) {
                    this.setState({ product: this.props.product });
                }
                return [2];
            });
        });
    };
    ChannelPricingShowModal.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var ean = ((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = this.state.product) === null || _c === void 0 ? void 0 : _c.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu) || '-';
        var title = ((_f = (_e = this.state.product) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu) || '-';
        var vintage = ((_g = this.state.product) === null || _g === void 0 ? void 0 : _g.vintage) || '-';
        var capacity = ((_k = (_j = (_h = this.state.product) === null || _h === void 0 ? void 0 : _h.b2cAttributeSearchValues) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k[1]) || '-';
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'channel-pricing-modal mt-4' },
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: 'col-6' },
                        React.createElement("p", null, (0, trans_1.trans)('b2b-channel-pricing.pricing.show.modal.channel')),
                        React.createElement("p", null, this.state.product.channelName || '-')),
                    React.createElement("div", { className: 'col' },
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                            React.createElement("span", null, ean)),
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                            React.createElement("span", null, sku)),
                        React.createElement("p", null,
                            manufacturer,
                            " ",
                            title),
                        React.createElement("p", null,
                            vintage,
                            " (",
                            capacity,
                            ")"))),
                React.createElement("div", { className: 'row' },
                    React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === 'price' ? 'active' : 'inactive'), title: 'LISTAÁR', onClick: function () { return _this.setState({ activeTab: 'price' }); } }),
                    React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === 'modifier' ? 'active' : 'inactive'), title: 'AKCIÓK', onClick: function () { return _this.setState({ activeTab: 'modifier' }); } })),
                React.createElement("div", { className: 'row flex-column border-1 py-2' }, this.state.activeTab === 'price'
                    ? React.createElement(price_table_1.PriceTable, { product: this.state.product, items: (_o = (_m = (_l = this.state.product) === null || _l === void 0 ? void 0 : _l.channelPrice) === null || _m === void 0 ? void 0 : _m.priceData) === null || _o === void 0 ? void 0 : _o.items, openEdit: function (item) { return _this.$priceEditingModal.open(item); }, deletePrice: function (supplierPriceId, priceItemId) { return _this.deletePrice(supplierPriceId, priceItemId); } })
                    : this.state.activeTab === 'modifier'
                        ? React.createElement(modifier_table_1.ModifierTable, { onRefreshRequest: this.props.onRefreshRequest, product: this.state.product, items: (_r = (_q = (_p = this.state.product) === null || _p === void 0 ? void 0 : _p.channelPriceModifier) === null || _q === void 0 ? void 0 : _q.valueData) === null || _r === void 0 ? void 0 : _r.items, openEdit: function (item) { return _this.$modifierEditingModal.open(item); }, deleteModifier: function (channelPriceModifierId, modifierItemId) { return _this.deleteModifier(channelPriceModifierId, modifierItemId); } })
                        : null),
                React.createElement("div", { className: 'row justify-content-end mt-6' },
                    React.createElement(button_1.Button, { className: 'button-neutral-inverze button-size-normal', title: 'Bezárás', onClick: function () { return _this.props.onClose(); } }))),
            this.renderModals());
    };
    ChannelPricingShowModal.prototype.deletePrice = function (channelPriceId, priceItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/channel-pricing/price/".concat(channelPriceId, "/").concat(priceItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. Az ár adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ChannelPricingShowModal.prototype.deleteModifier = function (channelPriceModifierId, modifierItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/channel-pricing/modifier/".concat(channelPriceModifierId, "/").concat(modifierItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. A módosító adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ChannelPricingShowModal.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$priceEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(edit_price_modal_1.EditPriceModal, { onClose: function () { return _this.$priceEditingModal.close(); }, product: _this.state.product, price: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); } }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$modifierEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(edit_modifier_modal_1.EditModifierModal, { onClose: function () { return _this.$modifierEditingModal.close(); }, product: _this.state.product, modifier: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); } }); } }));
    };
    return ChannelPricingShowModal;
}(abstract_component_1.AbstractComponent));
exports.ChannelPricingShowModal = ChannelPricingShowModal;
