"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoryList = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_1 = require("@codebuild/glaze/libs/router/router");
var lodash_1 = require("lodash");
var React = require("react");
var list_loader_1 = require("./loader/list-loader");
var RepositoryList = (function (_super) {
    __extends(RepositoryList, _super);
    function RepositoryList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cancelToken = repository_1.Repository.createCancelToken();
        _this.state = {
            items: [],
            response: null,
            loading: false,
            error: null,
            total: null,
        };
        return _this;
    }
    RepositoryList.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b;
        if (!(0, lodash_1.isEqual)(prevState.loading, this.state.loading)) {
            (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.onLoadingChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.state.loading);
        }
    };
    RepositoryList.prototype.fetch = function (query, body) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (query === void 0) { query = {}; }
        if (body === void 0) { body = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var sort, appliedSort, parsedQuery, response, e_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 4, , 6]);
                        return [4, this.setState({ loading: true })];
                    case 1:
                        _j.sent();
                        if (this.cancelToken) {
                            this.cancelToken.cancel('CanceledByUser');
                            this.cancelToken = repository_1.Repository.createCancelToken();
                        }
                        sort = __assign(__assign(__assign({}, (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.listQuery) === null || _b === void 0 ? void 0 : _b.sort), query === null || query === void 0 ? void 0 : query.sort), query === null || query === void 0 ? void 0 : query._sort);
                        appliedSort = !!((_c = sort === null || sort === void 0 ? void 0 : sort.keys) === null || _c === void 0 ? void 0 : _c.length) ? sort : ((_d = this.props.defaultSort) !== null && _d !== void 0 ? _d : { createdAt: -1 });
                        parsedQuery = router_1.Router.encodeQuery(__assign(__assign(__assign({}, query), (_e = this.props) === null || _e === void 0 ? void 0 : _e.listQuery), { _sort: __assign({}, appliedSort) }), true);
                        if (!!((_f = this.props) === null || _f === void 0 ? void 0 : _f.blockUntilInQuery) && !parsedQuery.includes(this.props.blockUntilInQuery)) {
                            return [2];
                        }
                        return [4, this.props.repository[this.props.method || 'get']("".concat(this.props.endpoint).concat(parsedQuery), {
                                cancelToken: this.cancelToken.token
                            })];
                    case 2:
                        response = _j.sent();
                        if (this.props.control) {
                            this.props.control.setResponse(response);
                        }
                        if (this.props.onResponseChange) {
                            (_h = (_g = this.props).onResponseChange) === null || _h === void 0 ? void 0 : _h.call(_g, response);
                        }
                        return [4, this.setState({
                                loading: false,
                                response: response,
                                items: this.extractItems(response),
                                error: false,
                                total: response.total
                            })];
                    case 3:
                        _j.sent();
                        if (this.props.onChange) {
                            this.props.onChange(this);
                        }
                        return [3, 6];
                    case 4:
                        e_1 = _j.sent();
                        if (e_1.message === 'CanceledByUser') {
                            return [2];
                        }
                        return [4, this.setState({
                                loading: false,
                                error: e_1
                            })];
                    case 5:
                        _j.sent();
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    RepositoryList.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (!((_a = this.props) === null || _a === void 0 ? void 0 : _a.mandatoryQueryParam) || ((_b = this.props) === null || _b === void 0 ? void 0 : _b.mandatoryQueryParam) in ((_c = this.props.control) === null || _c === void 0 ? void 0 : _c.data)) {
            void this.fetch((_d = this.props.control) === null || _d === void 0 ? void 0 : _d.data);
        }
        if (this.props.control) {
            this.$subscriptions.push(this.props.control.query$.subscribe(function (query) {
                void _this.fetch(query);
            }));
        }
    };
    RepositoryList.prototype.reload = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.fetch((_a = this.props.control) === null || _a === void 0 ? void 0 : _a.data)];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    RepositoryList.prototype.render = function () {
        return this.renderWrapper(this);
    };
    RepositoryList.prototype.renderWrapper = function (ctx) {
        if (this.props.renderWrapper) {
            return this.props.renderWrapper(ctx);
        }
        return React.createElement("div", null, this.renderItemsWrapper(this));
    };
    RepositoryList.prototype.renderItemsWrapper = function (ctx) {
        if (this.state.error) {
            return this.renderError(this);
        }
        if (this.props.renderItemsWrapper) {
            return this.props.renderItemsWrapper(ctx);
        }
        return this.renderItems(ctx);
    };
    RepositoryList.prototype.renderItems = function (ctx) {
        var _this = this;
        var items = this.getItems();
        return [
            this.props.renderBeforeItems && this.props.renderBeforeItems(ctx),
            this.renderEmpty(items),
            this.renderLoading(this),
            items.map(function (item, index) { return _this.renderItemWrapper(ctx, item, index); }),
            this.props.renderAfterItems && this.props.renderAfterItems(ctx)
        ];
    };
    RepositoryList.prototype.renderEmpty = function (items) {
        if (items.length) {
            return null;
        }
        if (this.state.loading) {
            return null;
        }
        if (this.props.renderEmpty) {
            return this.props.renderEmpty(this);
        }
        return React.createElement("div", null, "Empty list");
    };
    RepositoryList.prototype.renderItemWrapper = function (ctx, item, index) {
        if (this.props.showItem && !(this.props.showItem && this.props.showItem(this, item, index))) {
            return;
        }
        return this.renderItem(ctx, item, index);
    };
    RepositoryList.prototype.renderItem = function (ctx, item, index) {
        if (this.props.renderItem) {
            return this.props.renderItem(ctx, item, index);
        }
        return React.createElement("div", { key: index }, JSON.stringify(item));
    };
    RepositoryList.prototype.renderError = function (ctx) {
        if (!this.props.showError || !(this.props.showError && this.props.showError(this))) {
            return;
        }
        if (!this.state.error) {
            return null;
        }
        if (this.props.hideErrorWhileLoading && this.state.error) {
            return null;
        }
        if (this.props.renderError) {
            return this.props.renderError(ctx);
        }
        return React.createElement("div", null, "Error");
    };
    RepositoryList.prototype.renderLoading = function (ctx) {
        var _a, _b, _c;
        var shouldRenderThis = !(ctx.props.showLoading && ctx.props.showLoading(ctx));
        var shouldShowLoader = !ctx.props.showLoading;
        var isLoading = !ctx.state.loading;
        var hasError = ctx.props.hideLoadingWhileError && ctx.state.error;
        var dontRenderLoading = !ctx.props.renderLoading;
        if (hasError || isLoading || shouldShowLoader || shouldRenderThis || dontRenderLoading) {
            return null;
        }
        return (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.renderLoading) === null || _b === void 0 ? void 0 : _b.call(_a, ctx)) !== null && _c !== void 0 ? _c : React.createElement(list_loader_1.ListLoader, null);
    };
    RepositoryList.prototype.getItems = function () {
        var _a;
        if (this.props.hideItemsWhileError && this.state.error) {
            return [];
        }
        if (this.props.hideItemsWhileLoading && this.state.loading) {
            return [];
        }
        return (_a = this.state.items) !== null && _a !== void 0 ? _a : [];
    };
    RepositoryList.prototype.extractItems = function (response) {
        var _a;
        if (this.props.extractItems) {
            return this.props.extractItems(response);
        }
        var i = (_a = response.items) !== null && _a !== void 0 ? _a : response;
        if (!Array.isArray(i)) {
            return [];
        }
        return i;
    };
    return RepositoryList;
}(abstract_component_1.AbstractComponent));
exports.RepositoryList = RepositoryList;
