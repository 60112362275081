module.exports = {
    'b2b-stock.screen.title': 'Aktuális raktárkészlet',
    'b2b-stock.table.header.sku': 'sku',
    'b2b-stock.table.header.ean': 'ean',
    'b2b-stock.table.header.product': 'termék',
    'b2b-stock.table.header.amount': 'db',
    'b2b-stock.table.header.kn.knCode': 'KN kód',
    'b2b-stock.table.header.kn.amount': 'Mennyiség (db)',
    'b2b-stock.table.header.kn.edt': 'Mennyiség (jövedéki)',
    'b2b-stock.management.type.available': 'Szabad készlet',
    'b2b-stock.management.type.cp-reservation': 'Vevő cég foglalás',
    'b2b-stock.management.type.ch-reservation': 'Csatorna foglalás',
    'b2b-stock.management.type.order-lock': 'Megrendelés lock',
    'b2b-stock.management.type.issue-lock': 'Kitárolás lock',
};
