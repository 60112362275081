"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockReservationChannelListScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var list_search_component_1 = require("../../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var permissions_1 = require("../../../common/libs/permissions/permissions");
require("../b2b-stock-reservation.scss");
var permission_wrapper_1 = require("../../../components/permission-wrapper");
var channel_group_release_table_1 = require("../components/list/channel-group-release.table");
var channel_group_table_1 = require("../components/list/channel-group.table");
var stock_product_channel_group_table_1 = require("../components/list/stock-product-channel-group.table");
var B2bStockReservationChannelListScreen = (function (_super) {
    __extends(B2bStockReservationChannelListScreen, _super);
    function B2bStockReservationChannelListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.state = {
            tab: local_store_1.LocalStore.getItem('CH_STOCK_RESERVATION_TAB') || 'ch-group',
        };
        return _this;
    }
    B2bStockReservationChannelListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'StockReservationListScreen' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('reservation.list.main.title.ch')),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.STORAGE.channelStockReservation.create },
                                React.createElement(React.Fragment, null,
                                    React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/channel-stock-reservation/create'); }, title: (0, trans_1.trans)('reservation.list.create.ch.button'), iconLeft: 'fal fa-plus', className: 'button-primary-outline button-size-normal mr-4' }),
                                    React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/channel-stock-reservation/release'); }, title: (0, trans_1.trans)('reservation.list.release.ch.button'), iconLeft: 'fal fa-plus', className: 'button-primary-outline button-size-normal' })))))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row flex-column' },
                React.createElement("div", { className: 'col px-0' },
                    React.createElement(button_1.Button, { onClick: function () { return _this.onTabChange('ch-group'); }, title: (0, trans_1.trans)('reservation.list.tab.button.ch.group'), className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.tab === 'ch-group' ? 'active' : '') }),
                    React.createElement(button_1.Button, { onClick: function () { return _this.onTabChange('ch-group-release'); }, title: (0, trans_1.trans)('reservation.list.tab.button.ch.release'), className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.tab === 'ch-group-release' ? 'active' : '') }),
                    React.createElement(button_1.Button, { onClick: function () { return _this.onTabChange('ch-reservation'); }, title: (0, trans_1.trans)('reservation.list.tab.button.ch.list'), className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.tab === 'ch-reservation' ? 'active' : '') })),
                React.createElement("div", { className: 'col content-wrapper' },
                    this.state.tab === 'ch-group' && React.createElement(channel_group_table_1.ChannelGroupTable, null),
                    this.state.tab === 'ch-group-release' && React.createElement(channel_group_release_table_1.ChannelGroupReleaseTable, null),
                    this.state.tab === 'ch-reservation' && React.createElement(stock_product_channel_group_table_1.StockProductChannelGroupTable, null))));
    };
    B2bStockReservationChannelListScreen.prototype.onTabChange = function (tab) {
        this.setState({ tab: tab });
        local_store_1.LocalStore.setItem('CH_STOCK_RESERVATION_TAB', tab);
    };
    return B2bStockReservationChannelListScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockReservationChannelListScreen = B2bStockReservationChannelListScreen;
