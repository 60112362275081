"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomShippingCharge = void 0;
var CustomShippingCharge = (function () {
    function CustomShippingCharge(_a) {
        var costHUF = _a.costHUF, costEUR = _a.costEUR, zipCodes = _a.zipCodes, minimumThresholdEUR = _a.minimumThresholdEUR, minimumThresholdHUF = _a.minimumThresholdHUF;
        this.minimumThresholdHUF = minimumThresholdHUF || '';
        this.minimumThresholdEUR = minimumThresholdEUR || '';
        this.costHUF = costHUF || '';
        this.costEUR = costEUR || '';
        this.key = this.generateHash();
        this.zipCodes = zipCodes || {
            from: '',
            to: '',
        };
    }
    CustomShippingCharge.prototype.generateHash = function () {
        return Math.random().toString(36).substring(2, 10);
    };
    return CustomShippingCharge;
}());
exports.CustomShippingCharge = CustomShippingCharge;
