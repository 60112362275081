"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFECT_LEGAL_CODES = exports.INCOME_LEGAL_CODES = exports.ISSUING_LEGAL_CODES = void 0;
exports.ISSUING_LEGAL_CODES = [
    { code: '30100', title: 'Jövedéki termék előállításához felhasznált jövedéki termék*' },
    { code: '30101', title: 'Jövedéki termék előállításához felhasznált a Jöt. 3. § (3) bekezdés 22. pontja szerint sörnek minősülő, erjesztést követően előállított még nem késztermék*' },
    { code: '30200', title: 'Jövedéki termék vagy nem jövedéki termék előállításához alapanyagként felhasznált nem jövedéki termék' },
    { code: '30300', title: 'ETBE előállításához felhasznált jövedéki termék*' },
    { code: '30400', title: 'A Jöt. 9. § (1) bekezdés a) pontja szerint értékesített jövedéki termék*' },
    { code: '30500', title: 'A Jöt. 9. § (1) bekezdés b) pontja szerint átadott jövedéki termék*' },
    { code: '30601', title: 'A Jöt. 9. § (1) bekezdés c) pont ca) alpontja szerinti célra a mintavételi szabályzat szerint vett minta az állami adó- és vámhatóság jelenlétében' },
    { code: '30602', title: 'A Jöt. 9. § (1) bekezdés c) pont ca) alpontja szerinti célra a mintavételi szabályzat szerint vett minta nem az állami adó- és vámhatóság jelenlétében*' },
    { code: '30701', title: 'Az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárból elszállítás révén' },
    { code: '30702', title: 'Az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárban' },
    { code: '30703', title: 'Nem az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárból elszállítás révén*' },
    { code: '30704', title: 'Nem az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárban*' },
    { code: '30800', title: 'Cigarettán kívüli dohánygyártmányok Jöt. 77. § (3) bekezdés b) pontja szerinti zárjegy eltávolítást követő kivezetése az adózott készletből*' },
    {
        code: '30900',
        title: 'A Jöt. 9. § (1) bekezdés e) és f) pontja szerint jövedéki termék olyan károsodása, amelyet az állami adó- és vámhatóság teljes megsemmisülésként vagy helyrehozhatatlan károsodásként elismert, a Jöt. 9. § (4) bekezdés b) pontja szerint elismert mennyiségű hiány kivételével'
    },
    { code: '31000', title: 'A Jöt. 112. § (1) bekezdés a) pontja szerint jövedéki termék kiszolgálása*' },
    { code: '31100', title: 'A Jöt. 112. § (1) bekezdés b) pontja szerint jövedéki termék kiszolgálása*' },
    { code: '31200', title: 'A Jöt. 112. § (1) bekezdés c) pont ca) alpontja szerint felhasznált jövedéki termék*' },
    { code: '31300', title: 'A Jöt. 112. § (1) bekezdés c) pont cb) alpontja szerint felhasznált jövedéki termék*' },
    { code: '31400', title: 'A Jöt. 112. § (1) bekezdés c) pont cc) alpontja szerint felhasznált jövedéki termék*' },
    { code: '31500', title: 'Jöt. 133. § (1) bekezdés e) pontja szerint felhasznált jövedéki termék gyógyszer, gyógyhatású készítmény, gyógyszeranyag, intermedier előállításához*' },
    { code: '31600', title: 'Jöt. 133. § (1) bekezdés f) pontja szerinti felhasznált jövedéki termék ecet előállításához*' },
    { code: '31700', title: 'Jöt. 133. § (1) bekezdés g) pontja szerint felhasznált jövedéki termék aroma előállításához*' },
    { code: '31800', title: 'Jöt. 133. § (1) bekezdés h) pontja szerint felhasznált jövedéki termék csokoládé és egyéb élelmiszer előállításhoz*' },
    { code: '31900', title: 'Jöt. 133. § (1) bekezdés i) pontja szerint felhasznált jövedéki termék vegyipari, kozmetikai és egyéb, nem emberi fogyasztásra szolgáló termék előállításához*' },
    { code: '32000', title: 'Jöt. 133. § (1) bekezdés k) pontja szerint felhasznált jövedéki termék teljesen denaturált alkohol előállításához*' },
    { code: '32100', title: 'Terméktávvezeték adóraktárnak vagy adóraktárnak nem minősülő csővezetékes szállításnál adófelfüggesztési eljárás keretében átadott energiatermék*' },
    { code: '32201', title: 'Szabadforgalomba bocsátott jövedéki termék magánszemély részére**' },
    { code: '32202', title: 'Szabadforgalomba bocsátott jövedéki termék jövedéki engedélyes kereskedő részére**' },
    { code: '32203', title: 'Szabadforgalomba bocsátott jövedéki termék jövedéki kiskereskedő részére**' },
    { code: '32204', title: 'Szabadforgalomba bocsátott jövedéki termék más tagállamba**' },
    { code: '32205', title: 'Szabadforgalomba bocsátott jövedéki termék felhasználói engedélyes részére**' },
    { code: '32206', title: 'Szabadforgalomba bocsátott jövedéki termék nyilvántartásba vett felhasználó részére**' },
    { code: '32207', title: 'Szabadforgalomba bocsátott jövedéki termék dohány-kiskereskedelmi ellátó részére**' },
    { code: '32208', title: 'Szabadforgalomba bocsátott 5 liternél vagy 5 kilogrammnál nagyobb kiszerelésű egyéb ellenőrzött ásványolaj a Jöt. 7. § (1) bekezdés f) pontja szerint**' },
    { code: '32209', title: 'Szabadforgalomba bocsátott jövedéki termék kisüzemi bortermelő részére**' },
    { code: '32210', title: 'Szabadforgalomba bocsátott jövedéki termék légiutas-ellátási tevékenységre**' },
    { code: '32211', title: 'Szabadforgalomba bocsátott 5 liter vagy 5 kilogramm vagy annál kisebb kiszerelésű egyéb ellenőrzött ásványolaj**' },
    { code: '32212', title: 'Szabadforgalomba bocsátott jövedéki termék egyéb személy részére**' },
    { code: '32213', title: 'Szabadforgalomba bocsátás tagállamba, harmadik országba történő kiszállítás céljából, zárjegy nélkül a Jöt. 62. § (13) bekezdése szerinti termék**' },
    { code: '32300', title: 'Szabadforgalomba bocsátott, az adóraktárban adófizetési kötelezettséget eredményező módon elfogyasztott, felhasznált jövedéki termék*' },
    { code: '32401', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék saját adóraktárba' },
    { code: '32402', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék nem saját adóraktárba' },
    { code: '32403', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék tagállamba' },
    { code: '32404', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék harmadik országba' },
    { code: '32405', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék másik tagállamba diplomáciai és konzuli képviselet és annak tagjai részére' },
    { code: '32406', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék másik tagállamba nemzetközi szervezet és annak tagjai részére' },
    { code: '32407', title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék másik tagállamba Észak-atlanti Szerződésben részes állam fegyveres erői, polgári állománya és étterme, kantinja részére' },
    {
        code: '32408',
        title: 'Adófelfüggesztési eljárás keretében feladott jövedéki termék másik tagállamba a tagállam közös biztonság- és védelempolitika keretében folytatott uniós tevékenység végrehajtása céljából végzett védelmi feladat ellátásában részt vevő fegyveres erői, polgári állománya és étterme, kantinja részére'
    },
    { code: '32500', title: 'Termékkészlet átvezetés biztosíték köteles készletből a Jöt. 21. § (4) bekezdés a)-b) pontja szerinti termékkészletbe (csökkenés)*' },
    { code: '32600', title: 'Termékkészlet átvezetés a Jöt. 21. § (4) bekezdés a)-b) pontja szerinti termékkészletből biztosítékköteles készletbe (csökkenés)*' },
    { code: '32701', title: 'Az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított adóköteles hiány' },
    { code: '32702', title: 'Az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított nem adóköteles hiány' },
    { code: '32703', title: 'Nem az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított adóköteles hiány*' },
    { code: '32704', title: 'Nem az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított nem adóköteles hiány*' },
    { code: '32801', title: 'Adófizetési kötelezettség alóli mentesülés kapcsán keletkezett, jövedéki terméknek minősülő melléktermék, hulladék felhasználása termék előállításhoz' },
    { code: '32802', title: 'Adófizetési kötelezettség alóli mentesülés kapcsán keletkezett, jövedéki terméknek minősülő melléktermék, hulladék megsemmisítése' },
    { code: '32803', title: 'Adófizetési kötelezettség alóli mentesülés kapcsán keletkezett, jövedéki terméknek minősülő melléktermék, hulladék feladása, átadása' },
    { code: '32900', title: 'Dohánygyártmány kiskereskedelmi eladási ár változása miatti készletátvezetése (csökkenés)*' },
    { code: '33000', title: 'Adózott termék átadása saját adóraktár részére*' },
    { code: '33100', title: 'Jövedéki termék átvezetése KN-kód vagy fajtakód változása esetén (csökkenés)*' },
    { code: '33200', title: 'A Jöt. 9. § (1) bekezdés n) és o) pontja és a DCA megállapodás XVI. cikke szerint jövedéki termék kiszolgálása**' },
    { code: '33300', title: 'Adóraktárban végzett felhasználói engedélyes tevékenységhez kiadott termék a Jöt. 24. § (11) bekezdése szerint' },
    { code: '33400', title: 'A DCA megállapodás XVI. cikke szerinti jövedéki termék kiszolgálása*' },
    { code: '33500', title: 'A Jöt. 62. § (13) bekezdése szerinti termék feladása saját adóraktár részére*' }
];
exports.INCOME_LEGAL_CODES = [
    { code: '00000', title: 'Nem jövedéki termék betárolása' },
    { code: '10000', title: 'Nyitókészlet (kizárólag a 2017. június 30-át követő első adatszolgáltatás alkalmával beküldendő)' },
    { code: '20101', title: 'Előállított jövedéki termék (nem bioetanol) készletre vétele*' },
    { code: '20102', title: 'Előállított  bioetanol (EU termelésű alapanyagból fenntarthatósági igazolással rendelkezik) készletre vétele*' },
    { code: '20103', title: 'Előállított  bioetanol (EU termelésű alapanyagból fenntarthatósági igazolással nem rendelkezik) készletre vétele*' },
    { code: '20104', title: 'Előállított  bioetanol (harmadik országban termelt alapanyagból fenntarthatósági igazolással rendelkezik) készletre vétele*' },
    { code: '20105', title: 'Előállított  bioetanol (harmadik országban termelt alapanyagból fenntarthatósági igazolással nem rendelkezik) készletre vétele*' },
    { code: '20106', title: 'Előállított a Jöt. 3. § (3) bekezdés 22. pontja szerint sörnek minősülő, erjesztést követően előállított még nem késztermék készletre vétele*' },
    { code: '20200', title: 'Cigarettán kívüli dohánygyártmány Jöt. 77. § (3) bekezdés b) pontja szerinti zárjegy eltávolítás utáni készletre vétele*' },
    { code: '20301', title: 'Előállított Jöt. 133. § (1) bekezdés e) pontja szerinti gyógyszer, gyógyhatású készítmény, gyógyszeranyag, intermedier készletre vétele*' },
    { code: '20302', title: 'Előállított Jöt. 133. § (1) bekezdés f) pontja szerinti ecet készletre vétele*' },
    { code: '20303', title: 'Előállított Jöt. 133. § (1) bekezdés g) pontja szerinti aroma készletre vétele*' },
    { code: '20304', title: 'Előállított Jöt. 133. § (1) bekezdés h) pontja szerinti csokoládé és egyéb élelmiszer készletre vétele*' },
    { code: '20305', title: 'Előállított Jöt. 133. § (1) bekezdés i) pontja szerinti vegyipari, kozmetikai és egyéb, nem emberi fogyasztásra szolgáló termék készletre vétele*' },
    { code: '20306', title: 'Előállított Jöt. 133. § (1) bekezdés k) pontja szerinti teljesen denaturált alkohol készletre vétele*' },
    { code: '20307', title: 'Előállított ETBE készletre vétele*' },
    { code: '20308', title: 'Előállított Jöt. 112. § 1) bekezdés cb) pontja szerinti termék készletre vétele*' },
    { code: '20309', title: 'Jövedéki termék előállításához alapanyagként szolgáló nem jövedéki termék előállítása' },
    { code: '20400', title: 'Adófizetési kötelezettség alóli mentesülés kapcsán keletkezett, jövedéki terméknek minősülő melléktermék, hulladék készletre vétele' },
    { code: '20501', title: 'Adófelfüggesztési eljárás keretében átvett jövedéki termék készletre vétele saját adóraktárból' },
    { code: '20502', title: 'Adófelfüggesztési eljárás keretében átvett jövedéki termék készletre vétele nem saját adóraktárból' },
    { code: '20503', title: 'Adófelfüggesztési eljárás keretében átvett jövedéki termék készletre vétele tagállamból' },
    { code: '20504', title: 'Adófelfüggesztési eljárás keretében átvett jövedéki termék készletre vétele bejegyzett feladótól' },
    { code: '20505', title: 'Adófelfüggesztési eljárás keretében történő szállításból visszaszállított jövedéki termék készletre vétele' },
    { code: '20601', title: 'Adófelfüggesztési eljárás keretében átvett csendes és habzóbor készletre vétele egyszerűsített adóraktártól*' },
    { code: '20602', title: 'Adófelfüggesztési eljárás keretében átvett csendes és habzóbor készletre vétele kisüzemi bortermelőtől*' },
    { code: '20603', title: 'Adófelfüggesztési eljárás keretében átvett csendes és habzóbor készletre vétele másik tagállamból a Jöt. 51. §-a szerint*' },
    { code: '20700', title: 'Adóraktárban importált jövedéki termék készletre vétele' },
    { code: '20800', title: 'Légijárműből lefejtett, adóraktárba szállított repülőgép üzemanyag készletre vétele*' },
    { code: '20900', title: 'Terméktávvezeték adóraktártól vagy adóraktárnak nem minősülő csővezetékes szállításból átvett jövedéki termék készletre vétele*' },
    { code: '21000', title: 'A Jöt. 12. § h) pontja szerinti jövedéki termék visszavétele*' },
    { code: '21100', title: 'Bérfőzetőtől a párlat kiadása nélkül, adózatlanul megvásárolt mennyiség*' },
    { code: '21200', title: 'Beszerzett nem jövedéki termék készletre vétele' },
    { code: '21300', title: 'Mintaként vett, de fel nem használt termék készletre vétele*' },
    { code: '21401', title: 'Az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított készlet többlet készletre vétele' },
    { code: '21402', title: 'Nem az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított készlet többlet készletre vétele*' },
    { code: '21500', title: 'Termékkészlet átvezetése biztosíték köteles készletből a Jöt. 21. § (4) bekezdés a)-b) pontja szerinti termékkészletbe (növekedés)*' },
    { code: '21600', title: 'Termékkészlet átvezetése a Jöt. 21. § (4) bekezdés a)-b) pontja szerinti termékkészletből biztosíték köteles készletbe (növekedés)*' },
    { code: '21700', title: 'Magánfőzőtől átvett párlat készletre vétele*' },
    { code: '21800', title: 'Bérfőzetőtől átvett adózott párlat készletre vétele*' },
    { code: '21900', title: 'Szabadforgalomból visszahozott vagy vásárolt jövedéki termék készletre vétele*' },
    { code: '22000', title: 'Dohánygyártmány kiskereskedelmi eladási ár változása miatti készletátvezetése (növekedés)*' },
    { code: '22100', title: 'Szabadforgalomba bocsátott jövedéki termék kiszállítást követő 72 órán belül részben vagy teljes mennyiségben történő visszaszállítása**' },
    { code: '22200', title: 'Adózott termék átvétele saját adóraktárból*' },
    { code: '22300', title: 'Jövedéki termék átvezetése KN-kód vagy fajtkód változása esetén (növekedés)*' },
    { code: '22400', title: 'A Jöt. 9. § (1) bekezdés a) pontja szerint értékesített jövedéki termék kiszállítást követő 72 órán belül részben vagy teljes mennyiségben történő visszaszállítása*' },
    { code: '22500', title: 'Adóraktárban végzett felhasználói engedélyes tevékenységből átvett termék készletre vétele a Jöt. 24. § (11) bekezdése szerint*' },
    { code: '22600', title: 'A Jöt. 9. § (1) bekezdés n) és o) pontja és a DCA megállapodás XVI. cikke szerint kiszolgált jövedéki termék visszaszállítása*' },
    { code: '22700', title: 'A Jöt. 62. § (13) bekezdése szerinti termék átvétele adófelfüggesztés alatt álló készletbe tagállamból*' },
    { code: '22800', title: 'A Jöt. 62. § (13) bekezdése szerinti termék átvétele saját adóraktárból*' },
    { code: '22900', title: 'Másik tagállamban szabadforgalomba bocsátott jövedéki termék átvétele' }
];
exports.DEFECT_LEGAL_CODES = [
    {
        code: 30701,
        title: 'z állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárból elszállítás révén',
    },
    {
        code: 30702,
        title: 'Az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárban',
    },
    {
        code: 30703,
        title: 'Nem az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárból elszállítás révén*',
    },
    {
        code: 30704,
        title: 'Nem az állami adó- és vámhatóság jelenlétében a Jöt. 9. § (1) bekezdés c) pont cb) alpontja szerint végzett megsemmisítés az adóraktárban*',
    },
    {
        code: 32701,
        title: 'Az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított adóköteles hiány',
    },
    {
        code: 32702,
        title: 'Az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított nem adóköteles hiány',
    },
    {
        code: 32703,
        title: 'Nem az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított adóköteles hiány*',
    },
    {
        code: 32704,
        title: 'Nem az állami adó- és vámhatóság jelenlétében végzett készletfelvétel során megállapított nem adóköteles hiány*',
    }
];
