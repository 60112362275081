"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementStatusBadge = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
var ProcurementStatusBadge = (function (_super) {
    __extends(ProcurementStatusBadge, _super);
    function ProcurementStatusBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProcurementStatusBadge.prototype.render = function () {
        if (!this.props.status) {
            return null;
        }
        return React.createElement("div", { className: "procurement-status-badge ".concat(this.props.status, " ").concat(this.props.className ? this.props.className : '') },
            React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)("procurement.status.badge.title.".concat(this.props.status))));
    };
    return ProcurementStatusBadge;
}(abstract_component_1.AbstractComponent));
exports.ProcurementStatusBadge = ProcurementStatusBadge;
