module.exports = {
    'kn-attributes.list.button.create.title': 'Létrehozás',
    'kn-attribute.table.header.knCode': 'KN azonosító',
    'kn-attribute.table.header.createdAt': 'Létrehozva',
    'kn-attribute.table.header.updatedAt': 'Módosítva',
    'kn-attribute.create.screen.title': 'Új KN jellemző létrehozása',
    'kn-attribute.update.screen.title': 'KN jellemző',
    'kn-attribute.form.knCode': 'KN kód',
    'kn-attribute.form.isExciseDuty': 'Jövedéki',
    'kn-attribute.form.exciseDutyList': 'Jövedéki fajta kódok',
    'kn-attribute.form.exciseDutyMeasurement': 'Jövedéki mennyiség mértékegysége',
    'kn-attribute.form.exciseDutyFormula': 'Jövedéki számítási képlet',
    'kn-attribute.create.success.toast': 'Jellemző sikeresen létrehozva',
    'kn-attribute.update.success.toast': 'Jellemző sikeresen módosítva',
};
