"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleDate = void 0;
var moment = require("moment");
var handleDate = function (date, format) {
    if (format === void 0) { format = 'YYYY.MM.DD.'; }
    if (!date) {
        return '-';
    }
    return moment(date).format(format);
};
exports.handleDate = handleDate;
