"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@codebuild/glaze/libs/environment");
var translation_1 = require("@codebuild/glaze/libs/translation/translation");
require("react-toastify/dist/ReactToastify.min.css");
translation_1.Translation.setLanguage('hu');
translation_1.Translation.addSource('hu', __assign({}, require('./translations/hu/hu')));
environment_1.Environment
    .initialize()
    .then(function () { return require('./application'); });
