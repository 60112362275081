"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiLanguageInput = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var lodash_1 = require("lodash");
var React = require("react");
var MultiLanguageInput = (function (_super) {
    __extends(MultiLanguageInput, _super);
    function MultiLanguageInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            value: _this.props.value || {},
            currentLanguage: _this.props.currentLanguage
        };
        return _this;
    }
    MultiLanguageInput.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.value, prevProps.value)) {
            this.setState({ value: this.props.value });
        }
    };
    MultiLanguageInput.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var currentProps = __assign(__assign({}, this.props), { value: ((_a = this.props.value) === null || _a === void 0 ? void 0 : _a[this.props.currentLanguage]) || '', disabled: this.props.disabled, onChange: function (e) { return _this.onChange(e); } });
        return React.createElement(((_b = this.props) === null || _b === void 0 ? void 0 : _b.input) || text_input_1.TextInput, currentProps);
    };
    MultiLanguageInput.prototype.onChange = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var currentValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentValue = this.state.value;
                        currentValue[this.props.currentLanguage] = e.target.value;
                        return [4, this.setState({ value: currentValue })];
                    case 1:
                        _a.sent();
                        this.props.onChange({ target: { value: this.state.value } });
                        return [2];
                }
            });
        });
    };
    MultiLanguageInput.prototype.isEmpty = function () {
        return __awaiter(this, void 0, void 0, function () {
            var empty, _a, _b, v;
            var e_1, _c;
            return __generator(this, function (_d) {
                if (!this.state.value || this.state.value === {}) {
                    return [2, true];
                }
                empty = true;
                try {
                    for (_a = __values(Object.keys(this.state.value)), _b = _a.next(); !_b.done; _b = _a.next()) {
                        v = _b.value;
                        empty = empty && !this.state.value[v].length;
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return [2, empty];
            });
        });
    };
    return MultiLanguageInput;
}(abstract_component_1.AbstractComponent));
exports.MultiLanguageInput = MultiLanguageInput;
