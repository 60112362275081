"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddProductModal = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var AddProductModal = (function (_super) {
    __extends(AddProductModal, _super);
    function AddProductModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                product: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'product',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.add-product.modal.form.field.product.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: "/admin/product?distributed=true&".concat(_this.props.filter),
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) {
                            var _a, _b, _c;
                            var capacity = (_b = (_a = o.b2cAttributeSearchValues) === null || _a === void 0 ? void 0 : _a.capacity) === null || _b === void 0 ? void 0 : _b[1];
                            var drsContent = o.drs ? '(DRS)' : '';
                            return {
                                value: o,
                                key: o._id,
                                name: React.createElement("span", null,
                                    o.sku,
                                    " - ",
                                    o.manufacturerName.hu,
                                    " ", (_c = o.title) === null || _c === void 0 ? void 0 :
                                    _c.hu,
                                    " ",
                                    o.vintage,
                                    !!capacity ? " - ".concat(capacity) : null,
                                    " ",
                                    drsContent),
                            };
                        });
                    },
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))],
                }),
            }
        });
        return _this;
    }
    AddProductModal.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function (value) { return _this.onProductSelect(value); }));
    };
    AddProductModal.prototype.onProductSelect = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.onAddProduct({ product: value.product, productId: (0, random_id_1.randomId)() });
                this.props.onClose();
                return [2];
            });
        });
    };
    AddProductModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(form_1.Form, { className: 'w-100' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.product, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, unclearable: false, multiple: false, searchable: true, className: 'select-input-basic product-search-input' }))),
            React.createElement("div", { className: 'display-flex justify-content-end' },
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('procurement.add-product.modal.close.button'), onClick: function () { return _this.props.onClose(); }, className: 'button-neutral-inverze button-size-normal' }),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('procurement.add-product.modal.add-product.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.form.submit(); }, className: 'button-primary-normal button-size-normal ml-4' })));
    };
    return AddProductModal;
}(abstract_component_1.AbstractComponent));
exports.AddProductModal = AddProductModal;
