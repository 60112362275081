module.exports = {
    'coupon.create.screen.title': 'Új kupon létrehozása',
    'coupon.update.screen.title': 'Kupon szerkesztése',
    'coupon.form.code': 'Kuponkód',
    'coupon.form.title_hu': 'Kupon neve (HU)',
    'coupon.form.title_en': 'Kupon neve (EN)',
    'coupon.form.description_hu': 'Kupon leírása (HU)',
    'coupon.form.description_en': 'Kupon leírása (EN)',
    'coupon.form.hasDateLimit': 'Időkorlátos',
    'coupon.form.hasRedemptionLimit': 'Beváltási korlátos (Kupononként)',
    'coupon.form.hasUserRedemptionLimit': 'Beváltási korlátos (Felhasználónként)',
    'coupon.form.discountType': 'Típus',
    'coupon.form.amountDiscountHuf': 'Kedvezmény mértéke (HUF)',
    'coupon.form.amountDiscountEur': 'Kedvezmény mértéke (EUR)',
    'coupon.form.percentDiscount': 'Kedvezmény mértéke (%)',
    'coupon.form.minimumValueHuf': 'Minimumösszeg (HUF)',
    'coupon.form.minimumValueEur': 'Minimumösszeg (EUR)',
    'coupon.form.validFrom': 'Érvényesség kezdete',
    'coupon.form.validTo': 'Érvényesség vége',
    'coupon.form.redemptionLimit': 'Beváltási korlát (Kupononként)',
    'coupon.form.userRedemptionLimit': 'Beváltási korlát (Felhasználónként)',
    'coupon.form.allowedProducts': 'Termék',
    'coupon.form.allowedCategories': 'Kategória',
    'coupon.form.allowedGroups': 'CSPID',
    'coupon.form.forbiddenProducts': 'Termék',
    'coupon.form.forbiddenCategories': 'Kategória',
    'coupon.form.forbiddenGroups': 'CSPID',
    'coupon.create.screen.subtitle.allowedProducts': 'Megengedett termékek',
    'coupon.create.screen.subtitle.forbiddenProducts': 'Kizárt termékek',
    'coupon.create.button.title': 'Kupon létrehozása',
    'coupon.create.success.toast': 'A kupon létrehozása sikeresen megtörtént',
    'coupon.create.screen.basic.subtitle': 'Alap adatok',
    'coupon.create.screen.restrictions.subtitle': 'Beváltási szabályok',
    'coupon.create.screen.productRestrictions.subtitle': 'Termék szabályok',
    'coupon.create.CouponCodeAlreadyExist': 'Ezzel az kuponkóddal már létezik kupon',
    'coupon.update.screen.subtitle.allowedProducts': 'Megengedett termékek',
    'coupon.update.screen.subtitle.forbiddenProducts': 'Kizárt termékek',
    'coupon.update.button.title': 'Kupon szerkesztése',
    'coupon.update.success.toast': 'A kupon módosítása sikeresen megtörtént',
    'coupon.update.screen.basic.subtitle': 'Alap adatok',
    'coupon.update.screen.restrictions.subtitle': 'Beváltási szabályok',
    'coupon.update.screen.productRestrictions.subtitle': 'Termék szabályok',
    'coupon.update.CouponCodeAlreadyExist': 'Ezzel az kuponkóddal már létezik kupon',
    'coupons.list.title': 'Kuponok',
    'coupons.list.button.create.title': 'Új kupon létrehozása',
    'coupon.table.header.code': 'Kuponkód',
    'coupon.table.header.creator': 'Létrehozó',
    'coupon.table.header.updatedAt': 'Utolsó módosítás'
};
