module.exports = {
    'main-menu.screen.title': 'Főmenü beállítás',
    'main-menu-category.table.header.title.hu': 'Cím (HU)',
    'main-menu-category.table.header.title.en': 'Cím (EN)',
    'main-menu-category.table.header.link.hu': 'Link (HU)',
    'main-menu-category.table.header.link.en': 'Link (EN)',
    'main-menu-category.table.header.order': 'Sorrend',
    'menu-item.form.field.title_hu.label': 'Cím (HU)',
    'menu-item.form.field.title_en.label': 'Cím (EN)',
    'menu-item.form.field.link_hu.label': 'Link (HU)',
    'menu-item.form.field.link_en.label': 'Link (EN)',
    'menu-item.form.field.order.label': 'Sorrend',
};
