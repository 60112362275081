"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'procurement.list.title': 'Beszerzés',
    'procurement.list.create.button': 'Új beszerzés indítása',
    'procurement.table.header.identifier': 'AZONOSÍTÓ',
    'procurement.table.header.supplierArtifact.name': 'PARTNER NEVE',
    'procurement.table.header.totalPlan': 'TERV DB',
    'procurement.table.header.totalFact': 'TÉNY DB',
    'procurement.table.header.createdAt': 'LÉTREHOZVA',
    'procurement.table.header.status': 'STÁTUSZ',
    'procurement.table.header.updatedAt': 'MÓDOSÍTVA',
    'procurement.table.header.plannedDeliveryDate': 'TERVEZETT SZÁLLÍTÁS',
    'procurement.table.header.confirmedDeliveryDate': 'VISSZAIGAZOLT SZÁLLÍTÁS',
    'procurement.status.draft': 'VÁZLAT',
    'procurement.status.placed': 'RÖGZÍTETT',
    'procurement.status.under-delivery': 'BESZÁLLLÍTÁS ALATT',
    'procurement.status.fulfilled': 'TELJESÍTETT',
    'procurement.status.failed': 'MEGHIÚSULT',
    'procurement.view.table.header.product': 'TERMÉK',
    'procurement.view.table.header.free-stock': 'SZABAD KÉSZLET',
    'procurement.view.table.header.under-procurement': 'BESZERZÉS ALATT',
    'procurement.view.table.header.7-days': 'FORGÁS 7 NAP',
    'procurement.view.table.header.14-days': 'FORGÁS 14 NAP',
    'procurement.view.table.header.30-days': 'FORGÁS 30 NAP',
    'procurement.view.table.header.plan': 'TERV DB',
    'procurement.view.table.header.fact': 'TÉNY DB',
    'procurement.view.title': 'Beszerzés',
    'procurement.view.data.identifier': 'Azonosító',
    'procurement.view.data.supplier': 'Beszállító partner',
    'procurement.view.data.planned-delivery-date': 'Tervezett szállítás',
    'procurement.view.data.confirmed-delivery-date': 'Visszaigazolt szállítás',
    'procurement.view.data.comment': 'Megjegyzés',
    'procurement.view.data.failed-reason': 'Meghiúsulás oka',
    'procurement.view.data.products': 'Termékek',
    'procurement.view.edit.box.button': 'Beszerzés szerkesztése',
    'procurement.view.control.status.title': 'STÁTUSZ',
    'procurement.status.badge.title.draft': 'VÁZLAT',
    'procurement.status.badge.title.placed': 'RÖGZÍTVE',
    'procurement.status.badge.title.underdelivery': 'BESZERZÉS ALATT',
    'procurement.status.badge.title.fulfilled': 'TELJESEÍTVE',
    'procurement.status.badge.title.failed': 'MEGHIÚSULT',
    'procurement.view.edit.under-delivery-status.button': 'Továbbítás a következő státuszba',
    'procurement.view.edit.placed-status.button': 'Beszerzés rögzítése',
    'procurement.view.edit.fulfilled-status.button': 'Beszerzés teljesítve',
    'procurement.view.edit.failed-status.button': 'Beszerzés meghiúsult',
    'procurement.view.status.change.success': React.createElement("div", { className: 'display-flex align-items-start' },
        React.createElement("i", { className: 'fas fa-check-circle mr-2 mt-2' }),
        React.createElement("div", null,
            React.createElement("p", { className: 'fw-600' }, "Beszerz\u00E9st sikeresen \u00E1tker\u00FClt a k\u00F6vetkez\u0151 st\u00E1tuszba"))),
    'procurement.view.status.change.server-error': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Sikertelen st\u00E1tusz \u00E1ll\u00EDt\u00E1s"),
        React.createElement("p", { className: 'fw-400' }, "A beszerz\u00E9s st\u00E1tusz \u00E1t\u00E1ll\u00EDt\u00E1sa sor\u00E1n probl\u00E9ma l\u00E9pett fel. K\u00E9rlek pr\u00F3b\u00E1ld meg k\u00E9s\u0151bb \u00FAjra.")),
    'procurement.view.status.change.missing-fact': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Sikertelen st\u00E1tusz \u00E1ll\u00EDt\u00E1s"),
        React.createElement("p", { className: 'fw-400' }, "Hi\u00E1nyosak a t\u00E9ny adatok")),
    'procurement.view.status.change.confirmed-deliverydate-missing': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Sikertelen st\u00E1tusz \u00E1ll\u00EDt\u00E1s"),
        React.createElement("p", { className: 'fw-400' }, "Hi\u00E1nyz\u00F3 visszaigazolt sz\u00E1ll\u00EDt\u00E1si d\u00E1tum")),
    'procurement.view.failed.modal.form.reason.label': 'Indoklás',
    'procurement.create.title': 'Beszerzés',
    'procurement.create.product-list.title': 'Termékek',
    'procurement.create.product-list.add.button': 'Új termék hozzáadása',
    'procurement.create.form.supplier.label': 'Beszállító partner',
    'procurement.create.form.comment.label': 'Megjegyzés',
    'procurement.create.form.comment.planned-delivery-date': 'Tervezett beszállítási idő',
    'procurement.create.form.comment.confirmed-delivery-date': 'Visszaigazolt beszállítási idő',
    'procurement.create.table.header.product': 'TERMÉK',
    'procurement.create.table.header.free-stock': 'SZABAD KÉSZLET',
    'procurement.create.table.header.under-procurement': 'BESZERZÉS ALATT',
    'procurement.create.table.header.30-days': 'FORGÁS 30 NAP',
    'procurement.create.table.header.60-days': 'FORGÁS 60 NAP',
    'procurement.create.table.header.90-days': 'FORGÁS 90 NAP',
    'procurement.create.table.header.plan': 'TERV DB',
    'procurement.create.table.header.fact': 'TÉNY DB',
    'procurement.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'procurement.create.success.toast': 'Beszerzést sikeresen létrehoztuk',
    'procurement.create.delete.product.success.toast': React.createElement("div", { className: 'display-flex align-items-start' },
        React.createElement("i", { className: 'fas fa-exclamation-circle mr-2 mt-2' }),
        React.createElement("div", null,
            React.createElement("p", { className: 'fw-600' }, "Term\u00E9k elt\u00E1vol\u00EDt\u00E1sa sikeres"),
            React.createElement("p", { className: 'fw-400' }, "Sikeresen elt\u00E1vol\u00EDtottuk a term\u00E9ket az \u00F6ssze\u00E1ll\u00EDt\u00E1sb\u00F3l"))),
    'procurement.update.data.created-at': 'Létrehozva',
    'procurement.update.data.updated-at': 'Utoljára módosítva',
    'procurement.update.title': 'Beszerzés',
    'procurement.update.form.comment.label': 'Megjegyzés',
    'procurement.update.success.toast': 'Beszerzést sikeresen elmentettük',
    'procurement.update.edit.under-delivery-status.button': 'Mentés és továbbítás a következő státuszba',
    'procurement.delete.success.toast': React.createElement("div", { className: 'display-flex align-items-start' },
        React.createElement("i", { className: 'fas fa-exclamation-check mr-2 mt-2' }),
        React.createElement("p", { className: 'fw-400' }, "Beszerz\u00E9s t\u00F6rl\u00E9se sikeres")),
    'procurement.delete.failed.toast': React.createElement("div", { className: 'display-flex align-items-start' },
        React.createElement("i", { className: 'fas fa-exclamation-circle mr-2 mt-2' }),
        React.createElement("div", null,
            React.createElement("p", { className: 'fw-600' }, "Beszerz\u00E9s t\u00F6rl\u00E9se sikertelen"),
            React.createElement("p", { className: 'fw-400' }, "Nem tudtuk t\u00F6r\u00F6lni a beszerz\u00E9st. K\u00E9rlek pr\u00F3b\u00E1ld meg k\u00E9s\u0151bb \u00FAjra."))),
    'procurement.error.form.missing.products': 'Legalább egy termék hozzáadása kötelező',
    'procurement.error.form.not.valid': 'Termék mennyiségek megadása kötelező!',
    'procurement.create.save.error': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Sikertelen l\u00E9trehoz\u00E1s"),
        React.createElement("p", { className: 'fw-400' }, "A beszerz\u00E9s r\u00F6gz\u00EDt\u00E9se sor\u00E1n probl\u00E9ma l\u00E9pett fel. K\u00E9rlek pr\u00F3b\u00E1ld meg k\u00E9s\u0151bb \u00FAjra.")),
    'procurement.create.save.email.error': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Nem siker\u00FClt az \u00E9rtes\u00EDt\u0151 e-mailt kik\u00FCldeni")),
    'procurement.update.save.error': React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'fw-600' }, "Sikertelen ment\u00E9s"),
        React.createElement("p", { className: 'fw-400' }, "A beszerz\u00E9s ment\u00E9se sor\u00E1n probl\u00E9ma l\u00E9pett fel. K\u00E9rlek pr\u00F3b\u00E1ld meg k\u00E9s\u0151bb \u00FAjra.")),
    'procurement.add-product.modal.title': 'Temék hozzáadása',
    'procurement.add-product.modal.form.field.product.label': 'Válassz egy terméket',
    'procurement.add-product.modal.add-product.button': 'Hozzáadás',
    'procurement.add-product.modal.close.button': 'Mégsem',
    'procurement.delete-product.modal.title': 'Termék törlése',
    'procurement.delete-product.modal.text': 'Biztosan törlöd a terméket az összeállításból?',
    'procurement.delete-product.modal.close.button': 'Mégsem',
    'procurement.delete-product.modal.add-product.button': 'Igen',
    'procurement.view.failed.modal.title': 'Beszerzés heghiúsult',
    'procurement.view.failed.modal.text': 'Indokold meg miért hiúsult meg a beszerzés',
    'procurement.view.failed.modal.cancel.button': 'Mégse',
    'procurement.view.failed.modal.submit.button': 'Indoklás küldése',
    'procurement.delete-procurement.modal.title': 'Beszerzés törlése',
    'procurement.delete-procurement.modal.text': 'Biztosan törlöd a beszerzést?',
    'procurement.delete-procurement.modal.close.button': 'Mégsem',
    'procurement.delete-procurement.modal.delete-product.button': 'Igen',
};
