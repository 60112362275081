"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressForm = void 0;
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var file_uploader_1 = require("../../../../../components/file-uplader/file-uploader");
var file_uploader_form_control_type_1 = require("../../../../../components/file-uplader/type/file-uploader-form-control-type");
var extended_required_validator_1 = require("../../../../../components/form/validators/extended-required.validator");
var handle_toasts_1 = require("../../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../../components/modal/modal");
var AddressForm = (function (_super) {
    __extends(AddressForm, _super);
    function AddressForm() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.addressForm = new form_builder_1.FormBuilder({
            fields: {
                country: new field_1.Field({
                    placeholder: '',
                    value: 'Magyarország',
                    name: 'country',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.country.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))],
                    options: [
                        { key: 'Ausztria', name: 'Ausztria', value: 'Ausztria' },
                        { key: 'Belgium', name: 'Belgium', value: 'Belgium' },
                        { key: 'Ciprus', name: 'Ciprus', value: 'Ciprus' },
                        { key: 'Csehország', name: 'Csehország', value: 'Csehország' },
                        { key: 'Dánia', name: 'Dánia', value: 'Dánia' },
                        { key: 'Észtország', name: 'Észtország', value: 'Észtország' },
                        { key: 'Finnország', name: 'Finnország', value: 'Finnország' },
                        { key: 'Franciaország', name: 'Franciaország', value: 'Franciaország' },
                        { key: 'Németország', name: 'Németország', value: 'Németország' },
                        { key: 'Görögország', name: 'Görögország', value: 'Görögország' },
                        { key: 'Magyarország', name: 'Magyarország', value: 'Magyarország' },
                        { key: 'Írország', name: 'Írország', value: 'Írország' },
                        { key: 'Olaszország', name: 'Olaszország', value: 'Olaszország' },
                        { key: 'Lettország', name: 'Lettország', value: 'Lettország' },
                        { key: 'Litvánia', name: 'Litvánia', value: 'Litvánia' },
                        { key: 'Luxemburg', name: 'Luxemburg', value: 'Luxemburg' },
                        { key: 'Málta', name: 'Málta', value: 'Málta' },
                        { key: 'Hollandia', name: 'Hollandia', value: 'Hollandia' },
                        { key: 'Lengyelország', name: 'Lengyelország', value: 'Lengyelország' },
                        { key: 'Portugália', name: 'Portugália', value: 'Portugália' },
                        { key: 'Szlovákia', name: 'Szlovákia', value: 'Szlovákia' },
                        { key: 'Szlovénia', name: 'Szlovénia', value: 'Szlovénia' },
                        { key: 'Spanyolország', name: 'Spanyolország', value: 'Spanyolország' },
                        { key: 'Anglia', name: 'Anglia', value: 'Anglia' },
                        { key: 'Izland', name: 'Izland', value: 'Izland' },
                        { key: 'Lichtenstein', name: 'Lichtenstein', value: 'Lichtenstein' },
                        { key: 'Norvégia', name: 'Norvégia', value: 'Norvégia' },
                        { key: 'Svájc', name: 'Svájc', value: 'Svájc' },
                        { key: 'Amerikai Egyesült Államok', name: 'Amerikai Egyesült Államok', value: 'Amerikai Egyesült Államok' },
                        { key: 'Andorra', name: 'Andorra', value: 'Andorra' },
                        { key: 'Arab Emírségek', name: 'Arab Emírségek', value: 'Arab Emírségek' },
                        { key: 'Argentína', name: 'Argentína', value: 'Argentína' },
                        { key: 'Ausztrália', name: 'Ausztrália', value: 'Ausztrália' },
                        { key: 'Bosznia-Hercegovina', name: 'Bosznia-Hercegovina', value: 'Bosznia-Hercegovina' },
                        { key: 'Brazília', name: 'Brazília', value: 'Brazília' },
                        { key: 'Bulgária', name: 'Bulgária', value: 'Bulgária' },
                        { key: 'Chile', name: 'Chile', value: 'Chile' },
                        { key: 'Dél-Afrikai Köztársaság', name: 'Dél-Afrikai Köztársaság', value: 'Dél-Afrikai Köztársaság' },
                        { key: 'Ecuador', name: 'Ecuador', value: 'Ecuador' },
                        { key: 'Egyiptom', name: 'Egyiptom', value: 'Egyiptom' },
                        { key: 'Fülöp-szigetek', name: 'Fülöp-szigetek', value: 'Fülöp-szigetek' },
                        { key: 'Hongkong', name: 'Hongkong', value: 'Hongkong' },
                        { key: 'Horvátország', name: 'Horvátország', value: 'Horvátország' },
                        { key: 'India', name: 'India', value: 'India' },
                        { key: 'Izrael', name: 'Izrael', value: 'Izrael' },
                        { key: 'Japán', name: 'Japán', value: 'Japán' },
                        { key: 'Kanada', name: 'Kanada', value: 'Kanada' },
                        { key: 'Kazahsztán', name: 'Kazahsztán', value: 'Kazahsztán' },
                        { key: 'Kína', name: 'Kína', value: 'Kína' },
                        { key: 'Kirgizisztán', name: 'Kirgizisztán', value: 'Kirgizisztán' },
                        { key: 'Koreai Köztársaság', name: 'Koreai Köztársaság', value: 'Koreai Köztársaság' },
                        { key: 'Kuba', name: 'Kuba', value: 'Kuba' },
                        { key: 'Macedónia', name: 'Macedónia', value: 'Macedónia' },
                        { key: 'Marokkó', name: 'Marokkó', value: 'Marokkó' },
                        { key: 'Mexikó', name: 'Mexikó', value: 'Mexikó' },
                        { key: 'Moldova', name: 'Moldova', value: 'Moldova' },
                        { key: 'Monaco', name: 'Monaco', value: 'Monaco' },
                        { key: 'Örményország', name: 'Örményország', value: 'Örményország' },
                        { key: 'Peru', name: 'Peru', value: 'Peru' },
                        { key: 'Románia', name: 'Románia', value: 'Románia' },
                        { key: 'San Marino', name: 'San Marino', value: 'San Marino' },
                        { key: 'Szaúd-Arábia', name: 'Szaúd-Arábia', value: 'Szaúd-Arábia' },
                        { key: 'Szerbia és Montenegró', name: 'Szerbia és Montenegró', value: 'Szerbia és Montenegró' },
                        { key: 'Törökország', name: 'Törökország', value: 'Törökország' },
                        { key: 'Tunézia', name: 'Tunézia', value: 'Tunézia' },
                        { key: 'Új-Zéland', name: 'Új-Zéland', value: 'Új-Zéland' },
                        { key: 'Ukrajna', name: 'Ukrajna', value: 'Ukrajna' },
                    ]
                }),
                zipCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'zipCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.zipCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                city: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'city',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.city.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                address: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'address',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.address.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                name: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'name',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.name.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'comment',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('address.form.field.comment.label'),
                        " "),
                    validators: []
                }),
                documentOperatingPermit: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'documentOperatingPermit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('customer-partner.basic-data.form.field.documentOperatingPermit.label'),
                        " "),
                }),
                operatingPermitNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.operatingPermitNumber) || '',
                    name: 'operatingPermitNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('customer-partner.basic-data.form.field.operatingPermitNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                email: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.email) || '',
                    name: 'email',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('customer-partner.basic-data.form.field.name.email'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        return _this;
    }
    AddressForm.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return _this.$contactModal = ref; }, renderModalHeader: function () {
                    return React.createElement("h3", null, !_this.props.isUpdate ? 'Telephely hozzáadása' : 'Telephely szerkesztése');
                }, renderModalContent: function (modalContext) {
                    return React.createElement("div", null,
                        React.createElement("div", { className: 'mb-4' },
                            React.createElement("div", { className: 'row w-100' },
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.name, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                                React.createElement("div", { className: "col-12" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.country, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { multiple: false, className: 'select-input-basic' }))),
                                React.createElement("div", { className: "col-12" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.zipCode, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.city, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.address, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(textarea_input_1.TextareaInput, { tabIndex: 1, className: 'textarea-input-basic' }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.operatingPermitNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { disabled: _this.props.readOnly, className: "text-input-basic ".concat(_this.props.readOnly ? 'readOnly' : '') }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.email, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { disabled: _this.props.readOnly, className: "text-input-basic ".concat(_this.props.readOnly ? 'readOnly' : '') }))),
                                React.createElement("div", { className: "col-24" },
                                    React.createElement(form_control_1.FormControl, { field: _this.addressForm.fields.documentOperatingPermit, type: file_uploader_form_control_type_1.FileUploaderFormControlType },
                                        React.createElement(file_uploader_1.FileUploader, { disabled: _this.props.readOnly, title: React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                                                React.createElement("div", null,
                                                    React.createElement("span", { className: 'color--primary-6 fw-700 cursor-pointer' }, "V\u00E1lassz f\u00E1jlt"),
                                                    React.createElement("span", { className: 'fw-700' }, " vagy csak dobd ide")),
                                                React.createElement("div", null,
                                                    React.createElement("span", { className: 'color--neutral-5' }, "PNG, JPG, JPEG - max 5MB"))), endpoint: '/b2b/upload' }))))),
                        React.createElement("div", { className: 'display-flex flex-row' },
                            React.createElement("div", { className: 'col-24' },
                                React.createElement(button_1.Button, { className: 'button-neutral-inverze button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: _this.props.isUpdate ? 'Mentés' : 'Hozzáadás', onClick: function () { return _this.submitAddress(); } }))));
                }, renderTrigger: function () { return null; } }));
    };
    AddressForm.prototype.open = function (data) {
        if (!!data) {
            this.addressForm.updateFields(data);
            this.setState({ updateIndex: data.index });
        }
        this.$contactModal.open();
    };
    AddressForm.prototype.submitAddress = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.addressForm.validate()];
                    case 1:
                        _c.sent();
                        return [4, ((_a = this.addressForm) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _c.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        if (this.props.onSubmit) {
                            this.props.onSubmit(this.addressForm.toJSON(), (_b = this.state) === null || _b === void 0 ? void 0 : _b.updateIndex);
                        }
                        return [4, this.addressForm.clear()];
                    case 3:
                        _c.sent();
                        this.addressForm.updateFields({ country: 'Magyarország' });
                        this.$contactModal.close();
                        handle_toasts_1.HandleToasts.success(this.props.isUpdate ? 'Sikeres módosítás' : 'Sikeres hozzáadás');
                        return [2];
                }
            });
        });
    };
    return AddressForm;
}(abstract_component_1.AbstractComponent));
exports.AddressForm = AddressForm;
