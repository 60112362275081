"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StickyContainer = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/glaze/libs/libs/random-id");
var jquery = require("jquery");
var React = require("react");
var StickyContainer = (function (_super) {
    __extends(StickyContainer, _super);
    function StickyContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.containerId = (0, random_id_1.randomId)();
        return _this;
    }
    StickyContainer.prototype.componentDidMount = function () {
        if (!this.props.enabled) {
            return;
        }
        this.initStickyContainer();
    };
    StickyContainer.prototype.componentWillUnmount = function () {
        if (!this.props.enabled) {
            return;
        }
        _super.prototype.componentWillUnmount.call(this);
        window.removeEventListener('scroll', this.callback);
    };
    StickyContainer.prototype.render = function () {
        if (!this.props.enabled) {
            return React.createElement(React.Fragment, null, this.props.children);
        }
        return React.createElement("div", { className: this.props.className, style: { position: 'absolute' }, "sticky-container-id": this.containerId },
            React.createElement("div", null, this.props.children));
    };
    StickyContainer.prototype.initStickyContainer = function () {
        var _this = this;
        var _a;
        this.container = jquery("[sticky-container-id=".concat(this.containerId, "]"));
        this.containerOffsetTop = this.container.offset().top - ((_a = this.props) === null || _a === void 0 ? void 0 : _a.topSpacing) || 0;
        this.callback = function () {
            var scroll = jquery(document).scrollTop();
            if (scroll > _this.containerOffsetTop) {
                jquery(_this.container).css({ top: scroll - _this.containerOffsetTop });
            }
            else {
                jquery(_this.container).css({ top: 0 });
            }
        };
        new ResizeObserver(this.callback).observe(document.body);
        window.addEventListener('scroll', this.callback);
    };
    return StickyContainer;
}(abstract_component_1.AbstractComponent));
exports.StickyContainer = StickyContainer;
