"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FifoComponent = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var modal_1 = require("../../../components/modal/modal");
var delete_product_modal_1 = require("../../b2b-stock-reservation/components/create/delete-product.modal");
var add_product_modal_1 = require("./add-product.modal");
var FifoComponent = (function (_super) {
    __extends(FifoComponent, _super);
    function FifoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$download = new rxjs_1.Subject();
        _this.state = {
            extraProducts: [],
            locks: [],
            plan: [],
            values: {}
        };
        return _this;
    }
    FifoComponent.prototype.download = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var businessType, customerPartner, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        businessType = 'b2b';
                        customerPartner = this.props.customerPartner;
                        if (this.props.isB2c) {
                            businessType = 'b2c';
                            customerPartner = 'b2c';
                        }
                        if (this.props.isReturn) {
                            businessType = 'return';
                            customerPartner = this.props.supplierPartner;
                        }
                        if (this.props.isThirdParty) {
                            businessType = 'third-party';
                            customerPartner = this.props.supplierPartner;
                        }
                        if (this.props.isTransaction) {
                            businessType = 'transaction';
                        }
                        return [4, repository_1.Repository.use('default').post('/b2b/stock-issuing/plan', {
                                data: {
                                    businessType: businessType,
                                    customerPartner: customerPartner,
                                    products: this.getAllProductIds(),
                                    issue: ((_a = this.props.issue) === null || _a === void 0 ? void 0 : _a._id) || null,
                                }
                            })];
                    case 1:
                        response = _b.sent();
                        this.setState({
                            plan: response.plan || [],
                            locks: response.locks || []
                        });
                        this.emitOnChange();
                        return [2];
                }
            });
        });
    };
    FifoComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                if (!(0, lodash_1.isEqual)(prevProps.customerPartner, this.props.customerPartner) || !(0, lodash_1.isEqual)(prevProps.supplierPartner, this.props.supplierPartner)) {
                    this.setState({
                        extraProducts: [],
                        locks: [],
                        plan: [],
                    });
                    this.$download.next();
                }
                if (!(0, lodash_1.isEqual)(prevProps.issue, this.props.issue)) {
                    this.$download.next();
                }
                if (!(0, lodash_1.isEqual)(prevState.extraProducts, (_a = this.state) === null || _a === void 0 ? void 0 : _a.extraProducts)) {
                    this.$download.next();
                }
                if (!(0, lodash_1.isEqual)(prevProps.warehouse, this.props.warehouse)) {
                    this.setState({
                        extraProducts: [],
                        locks: [],
                        plan: [],
                    });
                }
                return [2];
            });
        });
    };
    FifoComponent.prototype.getAllProductIds = function () {
        var _a, _b;
        return __spreadArray(__spreadArray([], __read((((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.issue) === null || _b === void 0 ? void 0 : _b.plan) || [])
            .map(function (item) { return item.productId || item.product; })), false), __read(this.state.extraProducts), false).reduce(function (acc, item) { return acc.includes(item) ? acc : __spreadArray(__spreadArray([], __read(acc), false), [item], false); }, [])
            .sort(function (a, b) { return a > b ? 1 : -1; });
    };
    FifoComponent.prototype.getIssueLocks = function () {
        var _a;
        return (((_a = this.state) === null || _a === void 0 ? void 0 : _a.locks) || [])
            .map(function (item) { return ({
            product: item.productId || item.product,
            lot: item.productLot || item.lot,
            storage: item.storage,
            type: item.type,
            typePayload: item.refId || item.typePayload || null,
            amount: item.amount || 0,
            __meta: item.__meta,
            __key: "".concat(item.product, "-").concat(item.lot, "-").concat(item.storage, "-").concat(item.type, "-").concat(item.typePayload || null)
        }); })
            .reduce(function (acc, item) {
            var accIndex = acc.findIndex(function (i) { return i.__key === item.__key; });
            if (accIndex !== -1) {
                acc[accIndex].amount += item.amount;
                return acc;
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }, []);
    };
    FifoComponent.prototype.getArtifactLocks = function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.props.issue) === null || _a === void 0 ? void 0 : _a.artifacts) === null || _b === void 0 ? void 0 : _b.map(function (artifact) { return artifact.lock; })) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({
            product: item.productId || item.product,
            lot: item.productLot || item.lot,
            storage: item.storage,
            type: item.type,
            typePayload: item.refId || item.typePayload || null,
            amount: item.amount || 0,
            __meta: item.__meta,
            __key: "".concat(item.product, "-").concat(item.lot, "-").concat(item.storage, "-").concat(item.type, "-").concat(item.typePayload || null)
        }); }).reduce(function (acc, item) {
            var accIndex = acc.findIndex(function (i) { return i.__key === item.__key; });
            if (accIndex !== -1) {
                acc[accIndex].amount += item.amount;
                return acc;
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }, []);
    };
    FifoComponent.prototype.getIssuePlan = function () {
        var e_1, _a, e_2, _b;
        var _c;
        var plan = ((_c = this.state) === null || _c === void 0 ? void 0 : _c.plan) || [];
        var result = [];
        try {
            for (var plan_1 = __values(plan), plan_1_1 = plan_1.next(); !plan_1_1.done; plan_1_1 = plan_1.next()) {
                var planItem = plan_1_1.value;
                var items = [];
                try {
                    for (var _d = (e_2 = void 0, __values(planItem.items)), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var planItemItem = _e.value;
                        items.push({
                            product: planItem.product || planItem.productId,
                            lot: planItemItem.lot || planItemItem.productLot,
                            storage: planItemItem.storage,
                            type: planItemItem.type,
                            typePayload: planItemItem.typePayload || planItemItem.refId || null,
                            amount: planItemItem.amount || 0,
                            __meta: planItemItem === null || planItemItem === void 0 ? void 0 : planItemItem.__meta,
                            __key: "".concat((planItem.product || planItem.productId), "-").concat((planItemItem.lot || planItemItem.productLot), "-").concat(planItemItem.storage, "-").concat(planItemItem.type, "-").concat((planItemItem.typePayload || planItemItem.refId || null))
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                result.push({
                    items: items,
                    product: planItem.product || planItem.productId,
                    __meta: planItem === null || planItem === void 0 ? void 0 : planItem.__meta
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (plan_1_1 && !plan_1_1.done && (_a = plan_1.return)) _a.call(plan_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    };
    FifoComponent.prototype.getItemsToRender = function () {
        var e_3, _a;
        var _b, _c, _d, _e;
        var allProductIds = this.getAllProductIds();
        var results = [];
        var _loop_1 = function (pid) {
            var e_4, _f;
            var items = [];
            var locksItems = ((_b = this_1.props.issue) === null || _b === void 0 ? void 0 : _b.status) === 'executed'
                ? (_c = this_1.getArtifactLocks()) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return item.product === pid; })
                : (_d = this_1.getIssueLocks()) === null || _d === void 0 ? void 0 : _d.filter(function (item) { return item.product === pid; });
            var planItem = (_e = this_1.getIssuePlan()) === null || _e === void 0 ? void 0 : _e.find(function (item) { return item.product === pid; });
            if (!planItem) {
                return "continue";
            }
            var keys = __spreadArray(__spreadArray([], __read(locksItems), false), __read(planItem.items), false).map(function (item) { return item.__key; })
                .reduce(function (acc, item) { return acc.includes(item) ? acc : __spreadArray(__spreadArray([], __read(acc), false), [item], false); }, []);
            var _loop_2 = function (key) {
                var lockValue = locksItems.find(function (item) { return item.__key === key; });
                var planValue = planItem.items.find(function (item) { return item.__key === key; });
                var value = lockValue || planValue;
                var amount = ((this_1.state.values[key] === undefined)
                    ? ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0)
                    : (this_1.state.values[key]) || 0);
                var totalAmount = planValue
                    ? ((planValue === null || planValue === void 0 ? void 0 : planValue.amount) || 0) + ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0)
                    : ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0);
                items.push(__assign(__assign({}, value), { amount: amount, totalAmount: totalAmount }));
            };
            try {
                for (var keys_1 = (e_4 = void 0, __values(keys)), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                    var key = keys_1_1.value;
                    _loop_2(key);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (keys_1_1 && !keys_1_1.done && (_f = keys_1.return)) _f.call(keys_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
            results.push(__assign(__assign({}, planItem), { items: items }));
        };
        var this_1 = this;
        try {
            for (var allProductIds_1 = __values(allProductIds), allProductIds_1_1 = allProductIds_1.next(); !allProductIds_1_1.done; allProductIds_1_1 = allProductIds_1.next()) {
                var pid = allProductIds_1_1.value;
                _loop_1(pid);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (allProductIds_1_1 && !allProductIds_1_1.done && (_a = allProductIds_1.return)) _a.call(allProductIds_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return results;
    };
    FifoComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.$subscriptions.push(this.$download
                    .pipe((0, operators_1.debounceTime)(100))
                    .subscribe(function () { return _this.download(); }));
                this.$download.next();
                return [2];
            });
        });
    };
    FifoComponent.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var isDraft = ((_a = this.props.issue) === null || _a === void 0 ? void 0 : _a.status) === 'draft';
        return React.createElement("div", { className: 'col' },
            this.renderModals(),
            React.createElement("div", { className: 'mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('stock-transaction.create.product-list.title')),
                ((_b = this.props.issue) === null || _b === void 0 ? void 0 : _b.status) !== 'executed' && React.createElement(button_1.Button, { disabled: !this.props.isWarehouseSelected && !isDraft, title: (0, trans_1.trans)('stock-transaction.create.product-list.add.button'), iconLeft: 'fal fa-plus', className: 'button-primary-inverze button-size-normal', onClick: function () { return _this.$addProductModal.open(); } })),
            React.createElement(table_1.Table, { className: 'table-basic StockTransactionTable mb-6', renderHeader: function () { return null; }, renderBody: function () { return _this.renderItems(); } }));
    };
    FifoComponent.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-transaction.create.table.header.".concat(p)),
            property: p
        };
    };
    FifoComponent.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 95 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    FifoComponent.prototype.renderItems = function () {
        var _this = this;
        var items = this.getItemsToRender();
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return items.map(function (item, index) { return _this.renderProductItem(item, index); });
    };
    FifoComponent.prototype.getData = function (data) {
        if (!data) {
            return '';
        }
        return data;
    };
    FifoComponent.prototype.renderProductItem = function (item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var product = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.product;
        var sumAmount = item.items.reduce(function (acc, i) { return acc + i.amount; }, 0);
        var sumTotalAmount = item.items.reduce(function (acc, i) { return acc + i.totalAmount; }, 0);
        var capacity = (_b = product === null || product === void 0 ? void 0 : product.b2cProductDetailsAttributes) === null || _b === void 0 ? void 0 : _b.find(function (i) { return (i === null || i === void 0 ? void 0 : i.key) === 'capacity'; });
        var alcohol = (_c = product === null || product === void 0 ? void 0 : product.b2cProductDetailsAttributes) === null || _c === void 0 ? void 0 : _c.find(function (i) { return (i === null || i === void 0 ? void 0 : i.key) === 'alcohol'; });
        var title = [
            this.getData((_d = product === null || product === void 0 ? void 0 : product.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu),
            this.getData((_e = product === null || product === void 0 ? void 0 : product.title) === null || _e === void 0 ? void 0 : _e.hu),
            this.getData(product === null || product === void 0 ? void 0 : product.vintage),
            this.getData((_f = capacity === null || capacity === void 0 ? void 0 : capacity.value) === null || _f === void 0 ? void 0 : _f.hu),
            this.getData((_g = alcohol === null || alcohol === void 0 ? void 0 : alcohol.value) === null || _g === void 0 ? void 0 : _g.hu)
        ];
        return React.createElement(table_body_row_1.TableBodyRow, { className: "w-100 flex-column border-0", key: index },
            React.createElement(table_body_cell_1.TableBodyCell, { className: "row" },
                React.createElement(table_body_row_1.TableBodyRow, null,
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 95 },
                        React.createElement("div", { className: "col" },
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                React.createElement("span", null, product === null || product === void 0 ? void 0 : product.eanCode)),
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                React.createElement("span", null, product === null || product === void 0 ? void 0 : product.sku)),
                            React.createElement("p", null, title.join(' ')))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 5 }, ((_h = this.props.issue) === null || _h === void 0 ? void 0 : _h.status) !== 'executed' && this.deleteButton(item.product)))),
            React.createElement(table_body_cell_1.TableBodyCell, { className: "row" },
                React.createElement(table_1.Table, { className: 'table-basic StockTransactionTable mb-6', renderHeader: function () { return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "LOT/N\u00C9BIH", property: "" }),
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "STORAGE", property: "" }),
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "\u00DATVONAL", property: "" }),
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "T\u00CDPUS", property: "" }),
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "\u00D6SSZMENNYIS\u00C9G (".concat(sumTotalAmount, " db)"), property: "" }),
                        React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: "V\u00C1LASZTOTT MENNY. (".concat(sumAmount, " db)"), property: "" })); }, renderBody: function () { return _this.renderProducts(item.items); } })));
    };
    FifoComponent.prototype.renderProducts = function (items) {
        var _this = this;
        var _a, _b;
        if (!(items === null || items === void 0 ? void 0 : items.length)) {
            return null;
        }
        if (!((_a = this.props.warehouse) === null || _a === void 0 ? void 0 : _a.identifier) || ((_b = this.props.warehouse) === null || _b === void 0 ? void 0 : _b.identifier) === 'WH-KISKER' || this.props.isB2c) {
            return items
                .filter(function (s) { return (s === null || s === void 0 ? void 0 : s.amount) || (s === null || s === void 0 ? void 0 : s.totalAmount); })
                .map(function (iItem, iIndex) { return _this.renderPlanProductItem(items, iItem, iIndex); });
        }
        return items
            .filter(function (s) { var _a, _b, _c, _d, _e; return ((_d = (_c = (_b = (_a = s === null || s === void 0 ? void 0 : s.__meta) === null || _a === void 0 ? void 0 : _a.storage) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.find(function (p) { return (p === null || p === void 0 ? void 0 : p.type) === 'warehouse'; })) === null || _d === void 0 ? void 0 : _d.identifier) === ((_e = _this.props.warehouse) === null || _e === void 0 ? void 0 : _e.identifier); })
            .map(function (iItem, iIndex) { return _this.renderPlanProductItem(items, iItem, iIndex); });
    };
    FifoComponent.prototype.renderPlanProductItem = function (parentItem, item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var totalAmount = item.totalAmount || 0;
        var storage = (_b = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.storage) === null || _b === void 0 ? void 0 : _b.title;
        var path = this.getPath((_d = (_c = item === null || item === void 0 ? void 0 : item.__meta) === null || _c === void 0 ? void 0 : _c.storage) === null || _d === void 0 ? void 0 : _d.path);
        return React.createElement(table_body_row_1.TableBodyRow, { key: index },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, item.lot),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, storage),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, path),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, item.type),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, (totalAmount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement(text_input_1.TextInput, { disabled: ((_e = this.props.issue) === null || _e === void 0 ? void 0 : _e.status) === 'executed', readOnly: ((_f = this.props.issue) === null || _f === void 0 ? void 0 : _f.status) === 'executed', className: 'text-input-basic', max: item.amount, type: "number", value: item.amount, onChange: function (e) { return _this.setValue(item, parseInt(e.target.value, 10)); } })));
    };
    FifoComponent.prototype.deleteButton = function (productId) {
        var _this = this;
        return React.createElement(button_1.Button, { className: 'delete-button', onClick: function () { return _this.$deleteProductModal.open({ productId: productId }); }, iconLeft: 'fas fa-trash' });
    };
    FifoComponent.prototype.renderModals = function () {
        var _this = this;
        return [
            React.createElement(modal_1.Modal, { key: 0, ref: function (ref) { return (_this.$addProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('stock-income.add-product.modal.title')); }, renderModalContent: function (ctx) {
                    var _a;
                    return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$addProductModal.close(); }, onAddProduct: function (product) { var _a, _b; return _this.setState({ extraProducts: (0, lodash_1.uniq)(__spreadArray(__spreadArray([], __read((_a = _this.state) === null || _a === void 0 ? void 0 : _a.extraProducts), false), [(_b = product === null || product === void 0 ? void 0 : product.product) === null || _b === void 0 ? void 0 : _b._id], false)) }); }, isReturn: _this.props.isReturn, isThirdParty: _this.props.isThirdParty, isTransaction: _this.props.isTransaction, customer: !!_this.props.customerPartner
                            ? "customerPartner=".concat(_this.props.customerPartner)
                            : 'salesChannels[]=b2c', supplier: !!_this.props.supplierPartner
                            ? "supplier=".concat(_this.props.supplierPartner)
                            : ' ', warehouse: (_a = _this.props.warehouse) === null || _a === void 0 ? void 0 : _a._id });
                } }),
            React.createElement(modal_1.Modal, { key: 1, ref: function (ref) { return (_this.$deleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$deleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.productId });
                } })
        ];
    };
    FifoComponent.prototype.handleRemoveProduct = function (productId) {
        var _a;
        var newProducts = (_a = (0, lodash_1.clone)(this.state.plan)) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
        this.setState({ plan: newProducts });
        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-disposal.create.delete.product.success.toast'));
        this.$deleteProductModal.close();
    };
    FifoComponent.prototype.setValue = function (data, value) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var current;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        current = __assign(__assign({}, ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.values) !== null && _b !== void 0 ? _b : {})), (_c = {}, _c[data.__key] = value, _c));
                        return [4, this.setState({ values: current })];
                    case 1:
                        _d.sent();
                        this.emitOnChange();
                        return [2];
                }
            });
        });
    };
    FifoComponent.prototype.getValue = function (data) {
        var _a, _b;
        var userDefinedValue = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[data.__key];
        if (userDefinedValue === undefined) {
            var lock = this.getIssueLocks()
                .find(function (item) { return item.__key === data.__key; });
            return (lock === null || lock === void 0 ? void 0 : lock.amount) || 0;
        }
        return userDefinedValue || 0;
    };
    FifoComponent.prototype.emitOnChange = function () {
        var _a, _b, _c, _d;
        var result = (_b = (_a = this.getItemsToRender()) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.items; })) === null || _b === void 0 ? void 0 : _b.flat();
        (_d = (_c = this.props).onChange) === null || _d === void 0 ? void 0 : _d.call(_c, result);
    };
    FifoComponent.prototype.getPath = function (item) {
        return item === null || item === void 0 ? void 0 : item.map(function (p, pi) { return "".concat(p === null || p === void 0 ? void 0 : p.title); }).join(' / ');
    };
    return FifoComponent;
}(abstract_component_1.AbstractComponent));
exports.FifoComponent = FifoComponent;
