"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bDepotUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var moment = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../../common/components/sticky-container");
var handle_toasts_1 = require("../../../../components/libs/handle.toasts");
require("./b2b-depot-create-update.screen.scss");
var basic_data_form_1 = require("./libs/basic-data-form");
var B2bDepotUpdateScreen = (function (_super) {
    __extends(B2bDepotUpdateScreen, _super);
    function B2bDepotUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            depot: null,
            editorLanguage: 'hu',
            isBundle: null,
            loading: false,
            readOnly: true
        };
        return _this;
    }
    B2bDepotUpdateScreen.prototype.componentDidMount = function () {
        this.fetch();
    };
    B2bDepotUpdateScreen.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/b2b/depot/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.depotId))];
                    case 1:
                        response = _c.sent();
                        this.setState({ depot: response });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        console.log({ fetch: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bDepotUpdateScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'DepotUpdateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-small', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/b2b-depot'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h3", { className: 'fw-600' }, (0, trans_1.trans)('depot.title'))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(basic_data_form_1.BasicDataForm, { ref: function (ref) { return _this.depotBasicDataFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: false, readOnly: this.state.readOnly, values: this.state.depot })))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    B2bDepotUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            this.state.readOnly && React.createElement("div", { className: 'SaveBox' },
                React.createElement("div", { className: 'w-100 display-flex justify-content-center mb-4' },
                    React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.setState({ readOnly: false }); }, iconLeft: 'far fa-pencil', title: this.state.loading ?
                            React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('depot.update.save-box.button.enable-edit'), className: 'button-info-normal button-size-normal' }))),
            !this.state.readOnly && React.createElement("div", { className: 'SaveBox' },
                React.createElement("h5", { className: 'mb-4 text-center' }, "SZERKESZT\u00C9S"),
                React.createElement("div", { className: 'w-100 display-flex justify-content-start mb-4' },
                    React.createElement("div", { className: 'display-flex flex-column' },
                        React.createElement("small", { className: 'mb-2' }, "L\u00E9trehozva"),
                        React.createElement("span", { className: 'fw-700 ml-4' }, moment((_a = this.state.depot) === null || _a === void 0 ? void 0 : _a.createdAt).format('YYYY.MM.DD.')))),
                React.createElement("div", { className: 'w-100 display-flex justify-content-start mb-4' },
                    React.createElement("div", { className: 'display-flex flex-column' },
                        React.createElement("small", { className: 'mb-2' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                        React.createElement("span", { className: 'fw-700 ml-4' }, moment((_b = this.state.depot) === null || _b === void 0 ? void 0 : _b.updatedAt).format('YYYY.MM.DD.')))),
                React.createElement("div", { className: 'w-100 display-flex justify-content-center' },
                    React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.submitForms(); }, iconLeft: 'far fa-save', title: this.state.loading ?
                            React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('depot.update.save-box.button.save'), className: 'button-info-normal button-size-normal' }))));
    };
    B2bDepotUpdateScreen.prototype.submitForms = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var basicData, response, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, ((_a = this.depotBasicDataFormRef$) === null || _a === void 0 ? void 0 : _a.getData())];
                    case 1:
                        basicData = _c.sent();
                        if (!!basicData.error) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        if (!!basicData.error) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        return [4, this.repository.put("/b2b/depot/".concat((_b = this.state.depot) === null || _b === void 0 ? void 0 : _b._id), { data: basicData })];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, readOnly: true });
                        router_provider_1.RouterProvider.goTo("/b2b-depot/update/".concat(response._id));
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('depot.update.success.toast'));
                        return [3, 4];
                    case 3:
                        e_2 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_2, (0, trans_1.trans)(e_2.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return B2bDepotUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bDepotUpdateScreen = B2bDepotUpdateScreen;
