"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicDataForm = exports.categoriesOptions = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var countries_1 = require("../../../../common/libs/countries");
var file_uploader_1 = require("../../../../components/file-uplader/file-uploader");
var file_uploader_form_control_type_1 = require("../../../../components/file-uplader/type/file-uploader-form-control-type");
var form_1 = require("../../../../components/form/form");
var extended_required_validator_1 = require("../../../../components/form/validators/extended-required.validator");
var billing_type_options_1 = require("./billing-type-options");
var payment_due_date_options_1 = require("./payment-due-date-options");
exports.categoriesOptions = [
    { value: 'wholesale', name: 'Nagykereskedő', key: 'wholesale' },
    { value: 'retail', name: 'Kiskereskedő', key: 'retail' },
    { value: 'horeca', name: 'Horeca', key: 'horeca' },
    { value: 'hiper', name: 'Hiper', key: 'hiper' },
];
var BasicDataForm = (function (_super) {
    __extends(BasicDataForm, _super);
    function BasicDataForm() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.companyForm = new form_builder_1.FormBuilder({
            fields: {
                partnerDiscountNotApplicable: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.partnerDiscountNotApplicable) || '',
                    name: 'partnerDiscountNotApplicable',
                    label: React.createElement("div", null, (0, trans_1.trans)('supplier-partner.basic-data.form.field.partnerDiscountNotApplicable.label')),
                    validators: []
                }),
                name: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.name) || '',
                    name: 'name',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.name.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                brandName: new field_1.Field({
                    placeholder: '',
                    value: ((_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.brandName) || '',
                    name: 'name',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.brandName.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                taxNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_d = _this.props.values) === null || _d === void 0 ? void 0 : _d.taxNumber) || '',
                    name: 'taxNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.taxNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                euTaxNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.euTaxNumber) || '',
                    name: 'euTaxNumber',
                    label: React.createElement("div", null, (0, trans_1.trans)('supplier-partner.basic-data.form.field.euTaxNumber.label')),
                    validators: []
                }),
                operatingPermitNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_f = _this.props.values) === null || _f === void 0 ? void 0 : _f.operatingPermitNumber) || '',
                    name: 'operatingPermitNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.operatingPermitNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                bankAccountNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_g = _this.props.values) === null || _g === void 0 ? void 0 : _g.bankAccountNumber) || '',
                    name: 'bankAccountNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.bankAccountNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: ((_h = _this.props.values) === null || _h === void 0 ? void 0 : _h.comment) || '',
                    name: 'comment',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.comment.label'),
                        " "),
                    validators: []
                }),
                paymentDueDate: new field_1.Field({
                    placeholder: '',
                    value: ((_k = (_j = _this.props.values) === null || _j === void 0 ? void 0 : _j.billingData) === null || _k === void 0 ? void 0 : _k.paymentDueDate) || null,
                    name: 'paymentDueDate',
                    options: payment_due_date_options_1.paymentDueDateOptions,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.paymentDueDate.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                billingType: new field_1.Field({
                    placeholder: '',
                    value: ((_l = _this.props.values) === null || _l === void 0 ? void 0 : _l.billingType) || null,
                    name: 'billingType',
                    options: billing_type_options_1.billingTypeOptions,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.billingType.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                documentOperatingPermit: new field_1.Field({
                    placeholder: '',
                    value: ((_m = _this.props.values) === null || _m === void 0 ? void 0 : _m.documentOperatingPermit) || [],
                    name: 'documentOperatingPermit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.documentOperatingPermit.label'),
                        " "),
                }),
            }
        });
        _this.addressForm = new form_builder_1.FormBuilder({
            fields: {
                zipCode: new field_1.Field({
                    placeholder: '',
                    value: ((_p = (_o = _this.props.values) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 : _p.zipCode) || '',
                    name: 'zipCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.zipCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                country: new field_1.Field({
                    placeholder: '',
                    value: ((_r = (_q = _this.props.values) === null || _q === void 0 ? void 0 : _q.address) === null || _r === void 0 ? void 0 : _r.country) || 'HU',
                    name: 'country',
                    options: countries_1.COUNTRIES,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.country.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                city: new field_1.Field({
                    placeholder: '',
                    value: ((_t = (_s = _this.props.values) === null || _s === void 0 ? void 0 : _s.address) === null || _t === void 0 ? void 0 : _t.city) || '',
                    name: 'city',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.city.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                address: new field_1.Field({
                    placeholder: '',
                    value: ((_v = (_u = _this.props.values) === null || _u === void 0 ? void 0 : _u.address) === null || _v === void 0 ? void 0 : _v.address) || '',
                    name: 'address',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.address.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: ((_x = (_w = _this.props.values) === null || _w === void 0 ? void 0 : _w.address) === null || _x === void 0 ? void 0 : _x.comment) || '',
                    name: 'comment',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('supplier-partner.basic-data.form.field.comment.label'),
                        " "),
                    validators: []
                })
            }
        });
        return _this;
    }
    BasicDataForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.companyForm.updateFields(__assign(__assign({}, this.props.values), { paymentDueDate: (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.billingData) === null || _b === void 0 ? void 0 : _b.paymentDueDate, billingType: (_d = (_c = this.props.values) === null || _c === void 0 ? void 0 : _c.billingData) === null || _d === void 0 ? void 0 : _d.billingType }));
            this.addressForm.updateFields((_e = this.props.values) === null || _e === void 0 ? void 0 : _e.address);
        }
    };
    BasicDataForm.prototype.render = function () {
        return React.createElement("div", null, this.renderBaseDataForm());
    };
    BasicDataForm.prototype.renderBaseDataForm = function () {
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('supplier-partner.update.subtitle.basic-data.company-data'))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.name, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.partnerDiscountNotApplicable, type: checkbox_form_control_type_1.CheckboxFormControlType },
                            React.createElement(checkbox_input_1.CheckboxInput, { disabled: this.props.readOnly, className: "checkbox-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.brandName, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.operatingPermitNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.taxNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.euTaxNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.bankAccountNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.billingType, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { readOnly: this.props.readOnly, unclearable: false, multiple: false, className: 'select-input-basic' }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.paymentDueDate, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { readOnly: this.props.readOnly, unclearable: false, multiple: false, className: 'select-input-basic' })))),
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('supplier-partner.update.subtitle.basic-data.address'))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.addressForm.fields.country, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { readOnly: this.props.readOnly, className: 'select-input-basic', searchable: true }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.addressForm.fields.zipCode, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-8 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.addressForm.fields.city, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.addressForm.fields.address, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.addressForm.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') })))),
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('supplier-partner.update.subtitle.basic-data.other')),
                    React.createElement("h5", { className: 'color--neutral-5' },
                        "(",
                        (0, trans_1.trans)('supplier-partner.update.subtitle.basic-data.other.hint'),
                        ")")),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { tabIndex: 1, readOnly: this.props.readOnly, className: 'textarea-input-basic' })))),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(form_control_1.FormControl, { field: this.companyForm.fields.documentOperatingPermit, type: file_uploader_form_control_type_1.FileUploaderFormControlType },
                            React.createElement(file_uploader_1.FileUploader, { disabled: this.props.readOnly, title: React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                                    React.createElement("div", null,
                                        React.createElement("span", { className: 'color--primary-6 fw-700 cursor-pointer' }, "V\u00E1lassz f\u00E1jlt"),
                                        React.createElement("span", { className: 'fw-700' }, " vagy csak dobd ide")),
                                    React.createElement("div", null,
                                        React.createElement("span", { className: 'color--neutral-5' }, "PNG, JPG, JPEG - max 5MB"))), endpoint: '/b2b/upload' }))))));
    };
    BasicDataForm.prototype.getData = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var companyFormErrors, addressFormErrors, hasError, companyData, address;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.companyForm.validate()];
                    case 1:
                        _c.sent();
                        return [4, this.addressForm.validate()];
                    case 2:
                        _c.sent();
                        return [4, ((_a = this.companyForm) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 3:
                        companyFormErrors = _c.sent();
                        return [4, ((_b = this.addressForm) === null || _b === void 0 ? void 0 : _b.getErrors())];
                    case 4:
                        addressFormErrors = _c.sent();
                        hasError = false;
                        (0, lodash_1.map)(__assign(__assign({}, companyFormErrors), addressFormErrors), function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        companyData = this.companyForm.toJSON();
                        address = this.addressForm.toJSON();
                        return [2, __assign(__assign({}, companyData), { address: address })];
                }
            });
        });
    };
    return BasicDataForm;
}(abstract_component_1.AbstractComponent));
exports.BasicDataForm = BasicDataForm;
