"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleProductForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var form_1 = require("../../../../components/form/form");
var BundleProductForm = (function (_super) {
    __extends(BundleProductForm, _super);
    function BundleProductForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.typeSelectorForm = new form_builder_1.FormBuilder({
            fields: {
                isBundle: new field_1.Field({
                    placeholder: '',
                    value: _this.props.type === 'bundle',
                    name: 'isBundle',
                    label: (0, trans_1.trans)('product.update.bundle.form.field.isBundle.label'),
                }),
            }
        });
        _this.state = {
            isBundle: _this.props.type === 'bundle',
            selectedProducts: []
        };
        _this.bundleProductForm = new form_builder_1.FormBuilder({
            fields: {
                bundleProduct: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'bundleProduct',
                    optionsMap: (function (response) {
                        var _a, _b;
                        return (_b = (_a = response.items) === null || _a === void 0 ? void 0 : _a.filter(function (p) { return p.type !== 'bundle' && p.sku !== _this.props.sku; })) === null || _b === void 0 ? void 0 : _b.map(function (i) {
                            var _a;
                            return ({
                                name: "".concat((_a = i === null || i === void 0 ? void 0 : i.title) === null || _a === void 0 ? void 0 : _a.hu, " (").concat(i === null || i === void 0 ? void 0 : i.sku, ")"),
                                key: i._id,
                                value: i
                            });
                        });
                    }),
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('product.update.bundle.form.field.bundleProduct.label'),
                    validators: []
                }),
            }
        });
        _this.productAmountForm = new form_builder_1.FormBuilder({
            fields: {}
        });
        return _this;
    }
    BundleProductForm.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.typeSelectorForm.fields.isBundle.$value.subscribe(function (v) {
            _this.setState({ isBundle: v });
            if (_this.props.bundleChange) {
                _this.props.bundleChange(v);
            }
        }));
    };
    BundleProductForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedProducts_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.props.type !== prevProps.type) {
                            this.typeSelectorForm.fields.isBundle.setValue(this.props.type === 'bundle');
                        }
                        if (!!(0, lodash_1.isEqual)(this.props.bundleProducts, prevProps.bundleProducts)) return [3, 2];
                        selectedProducts_1 = [];
                        return [4, (this.props.bundleProducts || []).map(function (p) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, selectedProducts_1.push({
                                                productId: p === null || p === void 0 ? void 0 : p.productId,
                                                title: p === null || p === void 0 ? void 0 : p.title,
                                                description: p === null || p === void 0 ? void 0 : p.description,
                                                subTitle: p === null || p === void 0 ? void 0 : p.subTitle,
                                                sku: p === null || p === void 0 ? void 0 : p.sku,
                                                group: p === null || p === void 0 ? void 0 : p.group
                                            })];
                                        case 1:
                                            _a.sent();
                                            return [4, this.productAmountForm.field(p === null || p === void 0 ? void 0 : p.productId, new field_1.Field({
                                                    id: p === null || p === void 0 ? void 0 : p.productId,
                                                    placeholder: '',
                                                    value: p === null || p === void 0 ? void 0 : p.amount,
                                                    name: p === null || p === void 0 ? void 0 : p.productId,
                                                    label: '',
                                                    validators: []
                                                }))];
                                        case 2:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        this.setState({ selectedProducts: selectedProducts_1 });
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    BundleProductForm.prototype.render = function () {
        return React.createElement("div", null,
            this.renderBundleSelectorForm(),
            this.renderBundleProductSelector());
    };
    BundleProductForm.prototype.renderBundleSelectorForm = function () {
        var _a;
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: (_a = this.typeSelectorForm.fields) === null || _a === void 0 ? void 0 : _a.isBundle, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic', disabled: this.props.disabled }))));
    };
    BundleProductForm.prototype.renderBundleProductSelector = function () {
        var _this = this;
        var _a;
        if (!this.state.isBundle) {
            return;
        }
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderProductTableHeader(); }, renderBody: function () { return _this.renderSelectedProducts(); } })),
            React.createElement("div", { className: 'w-100 display-flex justify-content-center align-items-center' },
                React.createElement("div", { className: 'flex-fill mr-4' },
                    React.createElement(form_control_1.FormControl, { field: (_a = this.bundleProductForm.fields) === null || _a === void 0 ? void 0 : _a.bundleProduct, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { searchable: true, disabled: this.props.disabled, multiple: false, className: 'select-input-basic' }))),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: 'Hozzáadás', iconLeft: 'fal fa-plus', onClick: function () { var _a, _b; return _this.addProduct((_b = (_a = _this.bundleProductForm.fields) === null || _a === void 0 ? void 0 : _a.bundleProduct) === null || _b === void 0 ? void 0 : _b.getValue()); } })));
    };
    BundleProductForm.prototype.renderSelectedProducts = function () {
        var _this = this;
        return this.state.selectedProducts.map(function (p) {
            return _this.renderItem(p);
        });
    };
    BundleProductForm.prototype.renderProductTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'SKU', property: 'sku' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Termék címe', property: 'name' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Termék alcíme', property: 'product' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Mennyiség', property: 'amount' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: '', property: '' }));
    };
    BundleProductForm.prototype.renderItem = function (item) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item === null || item === void 0 ? void 0 : item.productId },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.title) === null || _a === void 0 ? void 0 : _a.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_b = item === null || item === void 0 ? void 0 : item.subTitle) === null || _b === void 0 ? void 0 : _b.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(form_control_1.FormControl, { field: (_c = this.productAmountForm.fields) === null || _c === void 0 ? void 0 : _c[item === null || item === void 0 ? void 0 : item.productId], type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: this.props.disabled, min: 1, type: 'number', className: 'text-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("i", { className: 'fal fa-trash cursor-pointer', onClick: function () { return _this.removeProduct(item); } })));
    };
    BundleProductForm.prototype.addProduct = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedProducts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedProducts = this.state.selectedProducts;
                        if (!!(this.state.selectedProducts || []).find(function (p) { return p.productId === product._id; })) {
                            return [2];
                        }
                        if (!product) {
                            return [2];
                        }
                        selectedProducts.push({
                            productId: product === null || product === void 0 ? void 0 : product._id,
                            title: product === null || product === void 0 ? void 0 : product.title,
                            description: product === null || product === void 0 ? void 0 : product.description,
                            subTitle: product === null || product === void 0 ? void 0 : product.subTitle,
                            sku: product === null || product === void 0 ? void 0 : product.sku,
                            group: product === null || product === void 0 ? void 0 : product.group
                        });
                        return [4, this.productAmountForm.field(product === null || product === void 0 ? void 0 : product._id, new field_1.Field({
                                id: product === null || product === void 0 ? void 0 : product._id,
                                placeholder: '',
                                value: 1,
                                name: product === null || product === void 0 ? void 0 : product._id,
                                label: '',
                                validators: []
                            }))];
                    case 1:
                        _a.sent();
                        this.setState({ selectedProducts: selectedProducts });
                        return [2];
                }
            });
        });
    };
    BundleProductForm.prototype.removeProduct = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedProducts;
            return __generator(this, function (_a) {
                selectedProducts = (this.state.selectedProducts || []).filter(function (p) { return (p === null || p === void 0 ? void 0 : p.productId) !== (product === null || product === void 0 ? void 0 : product.productId); });
                this.setState({ selectedProducts: selectedProducts });
                return [2];
            });
        });
    };
    BundleProductForm.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var products;
            var _this = this;
            return __generator(this, function (_a) {
                products = this.state.selectedProducts.map(function (p) {
                    var _a, _b;
                    return {
                        productId: p === null || p === void 0 ? void 0 : p.productId,
                        title: p === null || p === void 0 ? void 0 : p.title,
                        description: p === null || p === void 0 ? void 0 : p.description,
                        subTitle: p === null || p === void 0 ? void 0 : p.subTitle,
                        sku: p === null || p === void 0 ? void 0 : p.sku,
                        group: p === null || p === void 0 ? void 0 : p.group,
                        amount: parseInt((_b = (_a = _this.productAmountForm.fields) === null || _a === void 0 ? void 0 : _a[p === null || p === void 0 ? void 0 : p.productId]) === null || _b === void 0 ? void 0 : _b.getValue(), 10)
                    };
                });
                return [2, {
                        isBundle: this.state.isBundle,
                        bundleProducts: products
                    }];
            });
        });
    };
    return BundleProductForm;
}(abstract_component_1.AbstractComponent));
exports.BundleProductForm = BundleProductForm;
