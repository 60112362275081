module.exports = {
    'attribute.list.title': 'Jellemzők',
    'attribute.list.button.create.title': 'Új jellemző létrehozása',
    'attribute.table.header.adminTitle': 'Admin név',
    'attribute.table.header.title.hu': 'Jellemző neve (HU)',
    'attribute.table.header.title.en': 'Jellemző neve (EN)',
    'attribute.table.header.key': 'Slug',
    'attribute.table.header.values': 'Értékek',
    'attribute.create.screen.title': 'Új jellemző létrehozása',
    'attribute.update.screen.title': 'Jellemző szerkesztése',
    'attribute.create.button.title': 'Új jellemző létrehozása',
    'attribute.update.button.title': 'Jellemző szerkesztése',
    'attribute.form.title_hu': 'Jellemző neve (HU)',
    'attribute.form.title_en': 'Jellemző neve (EN)',
    'attribute.form.adminTitle': 'Admin név',
    'attribute.form.description_hu': 'Jellemző leírása (HU)',
    'attribute.form.description_en': 'Jellemző leírása (EN)',
    'attribute.form.key': 'Slug',
    'attribute.form.isAccordingToOurSpecialists': 'Szakértőnk szerint',
    'attribute.form.productOwnerEnableEdit': 'Borász szerkesztheti',
    'attribute.form.inputFieldType': 'Mező típusa',
    'attribute.update.title': 'Jellemző szerkesztése',
    'attribute.update.success.toast': 'A jellemző módosítása sikeresen megtörtént',
    'attribute.create.success.toast': 'A jellemző létrehozása sikeresen megtörtént',
    'attribute.create.title': 'Jellemző létrehozása',
    'attribute-option.create.success.toast': 'A jellemző érték létrehozása sikeresen megtörtént',
    'attribute-option.update.success.toast': 'A jellemző érték módosítása sikeresen megtörtént',
    'attribute-options.list.button.create.title': 'Új érték hozzáadása',
    'attribute-option.create.screen.title': 'Új érték hozzáadása',
    'attribute-option.create.button.title': 'Új érték hozzáadása',
    'attribute-option.update.screen.title': 'érték szerkesztése',
    'attribute-options.table.header.title_hu': 'Érték neve (HU)',
    'attribute-options.table.header.title_en': 'Érték neve (EN)',
    'attribute-options.table.header.icon': 'Ikon',
    'attribute-options.table.header.description_hu': 'Leírás (HU)',
    'attribute-options.table.header.description_en': 'Leírás (EN)',
    'attribute-option.form.title_hu': 'Érték neve (HU)',
    'attribute-option.form.title_en': 'Érték neve (EN)',
    'attribute-option.form.icon': 'Ikon',
    'attribute-option.form.description_hu': 'Leírás (HU)',
    'attribute-option.form.description_en': 'Leírás (EN)',
};
