"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoveltyOrder = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var badge_1 = require("../../components/libs/badge/badge");
var edit_novelty_order_modal_1 = require("./edit-novelty-order.modal");
var NoveltyOrder = (function (_super) {
    __extends(NoveltyOrder, _super);
    function NoveltyOrder() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 10,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : ''
            },
        });
        _this.productSelector = new form_builder_1.FormBuilder({
            fields: {
                product: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'product',
                    optionsMap: (function (response) { return response.items.map(function (i) {
                        var _a, _b;
                        return ({
                            name: "".concat((_a = i === null || i === void 0 ? void 0 : i.title) === null || _a === void 0 ? void 0 : _a.hu, " ").concat((_b = i === null || i === void 0 ? void 0 : i.subTitle) === null || _b === void 0 ? void 0 : _b.hu, " (").concat(i === null || i === void 0 ? void 0 : i.sku, ")"),
                            key: i._id,
                            value: i
                        });
                    }); }),
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('novelty-order.form.field.product.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                noveltyOrder: new field_1.Field({
                    placeholder: '',
                    value: 1,
                    name: 'noveltyOrder',
                    label: (0, trans_1.trans)('novelty-order.form.field.noveltyOrder.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    NoveltyOrder.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, 'Újdonságok'),
                        React.createElement("span", null,
                            this.state.total,
                            " db"))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-12 col-24" }, this.renderSelector())),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return _this.repositoryListRef = ref; }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/product', listQuery: { novelty: 1 }, renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    NoveltyOrder.prototype.renderSelector = function () {
        var _this = this;
        return React.createElement("div", { className: 'w-100 display-flex justify-content-center align-items-center' },
            React.createElement("div", { className: 'flex-fill row mr-4' },
                React.createElement("div", { className: "col-18" },
                    React.createElement(form_control_1.FormControl, { field: this.productSelector.fields.product, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { searchable: true, multiple: false, className: 'select-input-basic' }))),
                React.createElement("div", { className: "col-6" },
                    React.createElement(form_control_1.FormControl, { field: this.productSelector.fields.noveltyOrder, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' })))),
            React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: 'Hozzáadás', iconLeft: 'fal fa-plus', onClick: function () { return _this.setFeatured(); } }));
    };
    NoveltyOrder.prototype.setFeatured = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isValid, data, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        return [4, this.productSelector.validate()];
                    case 1:
                        isValid = _b.sent();
                        if (!isValid) {
                            return [2];
                        }
                        data = this.productSelector.toJSON();
                        return [4, this.repository.put("/admin/product/".concat((_a = data === null || data === void 0 ? void 0 : data.product) === null || _a === void 0 ? void 0 : _a._id, "/novelty"), {
                                data: {
                                    novelty: true,
                                    noveltyOrder: parseInt(data === null || data === void 0 ? void 0 : data.noveltyOrder, 10)
                                }
                            })];
                    case 2:
                        _b.sent();
                        return [4, this.repositoryListRef.fetch()];
                    case 3:
                        _b.sent();
                        return [3, 5];
                    case 4:
                        err_1 = _b.sent();
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    NoveltyOrder.prototype.updateOrder = function (product, noveltyOrder, modalContext) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.repository.put("/admin/product/".concat(product === null || product === void 0 ? void 0 : product._id, "/novelty"), {
                                data: {
                                    novelty: true,
                                    noveltyOrder: parseInt(noveltyOrder, 10)
                                }
                            })];
                    case 1:
                        _a.sent();
                        modalContext.setLoading(false);
                        modalContext.close();
                        return [4, this.repositoryListRef.fetch()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_2 = _a.sent();
                        modalContext.setLoading(false);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    NoveltyOrder.prototype.deleteOrder = function (product, noveltyOrder, modalContext) {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.repository.put("/admin/product/".concat(product === null || product === void 0 ? void 0 : product._id, "/novelty"), {
                                data: {
                                    novelty: false,
                                    noveltyOrder: null
                                }
                            })];
                    case 1:
                        _a.sent();
                        modalContext.setLoading(false);
                        modalContext.close();
                        return [4, this.repositoryListRef.fetch()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_3 = _a.sent();
                        modalContext.setLoading(false);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    NoveltyOrder.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    NoveltyOrder.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('title.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('subTitle.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15, isDefaultSort: true }, this.setHeaderCell('noveltyOrder'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('stock.amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('valid'))));
    };
    NoveltyOrder.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("novelty-order.table.header.".concat(p)),
            property: p
        };
    };
    NoveltyOrder.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    NoveltyOrder.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    NoveltyOrder.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.title) === null || _a === void 0 ? void 0 : _a.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_b = item === null || item === void 0 ? void 0 : item.subTitle) === null || _b === void 0 ? void 0 : _b.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.noveltyOrder)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_c = item === null || item === void 0 ? void 0 : item.stock) === null || _c === void 0 ? void 0 : _c.amount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(badge_1.Badge, { title: (item === null || item === void 0 ? void 0 : item.valid) ? 'valid' : 'invalid', type: (item === null || item === void 0 ? void 0 : item.valid) ? 'success' : 'error' })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(edit_novelty_order_modal_1.EditNoveltyOrderModal, { product: item, onSubmit: function (noveltyOrder, modalContext) { return _this.updateOrder(item, noveltyOrder, modalContext); }, onDelete: function (noveltyOrder, modalContext) { return _this.deleteOrder(item, noveltyOrder, modalContext); } })));
    };
    NoveltyOrder.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return NoveltyOrder;
}(abstract_component_1.AbstractComponent));
exports.NoveltyOrder = NoveltyOrder;
