module.exports = {
    'product-order.screen.title': 'Termék rendezések',
    'product-order.novelty.tab.title': 'Újdonságok',
    'featured-category.form.field.product.label': 'Termék',
    'featured-category.form.field.featuredOrder.label': 'Sorszám',
    'featured-product.table.header.sku': 'Cikkszám',
    'featured-product.table.header.group': 'CSPID',
    'featured-product.table.header.title.hu': 'Cím (HU)',
    'featured-product.table.header.subTitle.hu': 'Alcím (HU)',
    'featured-product.table.header.featuredOrder': 'Sorszám',
    'featured-product.table.header.stock.amount': 'Készlet',
    'featured-product.table.header.valid': 'Valid',
    'novelty-order.form.field.product.label': 'Termék',
    'novelty-order.form.field.noveltyOrder.label': 'Sorszám',
    'novelty-order.table.header.sku': 'Cikkszám',
    'novelty-order.table.header.group': 'CSPID',
    'novelty-order.table.header.title.hu': 'Cím (HU)',
    'novelty-order.table.header.subTitle.hu': 'Alcím (HU)',
    'novelty-order.table.header.noveltyOrder': 'Sorszám',
    'novelty-order.table.header.stock.amount': 'Készlet',
    'novelty-order.table.header.valid': 'Valid',
};
