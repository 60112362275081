"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logout = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var logout = function () {
    local_store_1.LocalStore.deleteItem('accessToken');
    local_store_1.LocalStore.deleteItem('User');
    router_provider_1.RouterProvider.goTo('/login');
};
exports.logout = logout;
