"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unsynced = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var pagination_list_control_1 = require("../../../../common/components/repository-list/controls/pagination-list-control");
var list_empty_1 = require("../../../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../../../common/components/repository-list/repository-list");
var modal_1 = require("../../../../components/modal/modal");
var sync_modal_1 = require("../modals/sync.modal");
var Unsynced = (function (_super) {
    __extends(Unsynced, _super);
    function Unsynced() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {},
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    Unsynced.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            this.renderModals(),
            React.createElement("h2", null, "Szinkroniz\u00E1land\u00F3 k\u00E9szletmozg\u00E1sok"),
            React.createElement("p", null, "Itt azokat a k\u00E9szletmozg\u00E1sokat l\u00E1thatod, melyek m\u00E9g lettek \u00E1tszinkroniz\u00E1lva a V\u00C1M Informatika rendszer\u00E9be"),
            React.createElement("p", null, "Amennyiben szinkroniz\u00E1lod ezeket a k\u00E9szletmozg\u00E1sokat, azok meg fognak jelenni a j\u00F6ved\u00E9ki rakt\u00E1rkezel\u0151 rendszerben"),
            React.createElement("br", null),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return (_this.$list = ref); }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/vam-informatika/get-unsynced', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    Unsynced.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    Unsynced.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 25, title: "", property: "" }));
    };
    Unsynced.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    Unsynced.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    Unsynced.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a;
        return React.createElement("div", { key: item.batchId || Math.random(), className: "border-1 p-3 mb-4" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-18 p-3" },
                    !!item.order ? React.createElement("h3", null,
                        "MEGRENDEL\u00C9S: ",
                        item.title) : React.createElement("h3", null, item.title),
                    React.createElement("small", null,
                        "L\u00C9TREHOZVA: ",
                        React.createElement("b", null, moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'))),
                    React.createElement("br", null),
                    React.createElement("small", null,
                        "MEGRENDEL\u00C9S ST\u00C1TUSZA: ",
                        React.createElement("b", null, (_a = item === null || item === void 0 ? void 0 : item.order) === null || _a === void 0 ? void 0 : _a.status)),
                    React.createElement("br", null)),
                React.createElement("div", { className: "col-6 display-flex justify-content-end align-items-center" },
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: "\u00D6sszes szinkronz\u00E1l\u00E1sa (".concat(item.items.length, " db)"), onClick: function () { return _this.$syncModal.open(item.items); } }))),
            item.items.map(function (sItem) {
                var _a, _b;
                return React.createElement(table_body_row_1.TableBodyRow, { key: sItem._id, className: !!sItem.syncResponse ? 'sync-error' : '' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, sItem.sku),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, (_b = (_a = sItem === null || sItem === void 0 ? void 0 : sItem.product) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.hu),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, sItem === null || sItem === void 0 ? void 0 : sItem.type),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, sItem === null || sItem === void 0 ? void 0 :
                        sItem.amount,
                        " darab"),
                    !!sItem.syncResponse
                        ? React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, "Hiba a szinkroniz\u00E1ci\u00F3 sor\u00E1n")
                        : null,
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                        React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: 'Szinkronizálás', onClick: function () { return _this.$syncModal.open([sItem]); } })));
            }),
            React.createElement("br", null));
    };
    Unsynced.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    Unsynced.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$syncModal = ref); }, renderModalContent: function (modal) { return React.createElement(sync_modal_1.SyncModal, { key: 0, items: modal.state.args || [], onCreateSuccess: function () {
                        _this.$list.reload();
                        _this.$syncModal.close();
                    } }); } }));
    };
    return Unsynced;
}(abstract_component_1.AbstractComponent));
exports.Unsynced = Unsynced;
