"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSalesReportByOrdersScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var filter_group_1 = require("../../common/components/filter/filter-group");
var customer_partner_select_component_1 = require("../../common/components/list-search/customer-partner-select.component");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var supplier_select_component_1 = require("../../common/components/list-search/supplier-select.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var report_download_1 = require("../../components/report-download");
var interfaces_1 = require("./libs/interfaces");
var ProductSalesReportByOrdersScreen = (function (_super) {
    __extends(ProductSalesReportByOrdersScreen, _super);
    function ProductSalesReportByOrdersScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                query: '',
                sort: ''
            },
        });
        _this.state = {};
        return _this;
    }
    ProductSalesReportByOrdersScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'EnrichedCustomerPartnerReportScreen ListScreen' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('product-sales-by-orders-report.screen.title')))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/sales-by-order-report/download', control: this.control }))),
                React.createElement("div", { className: 'col-15' }),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: "row pb-4" },
                React.createElement(filter_group_1.FilterGroup, { renderFilters: React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-8" },
                            React.createElement(customer_partner_select_component_1.CustomerPartnerSelectComponent, { extraOption: { name: 'B2C vásárlások', key: 'b2c-customer', value: 'b2c-customer' }, label: (0, trans_1.trans)('order-sales-report.filter.customer-partner'), property: 'customerPartner', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(supplier_select_component_1.SupplierSelectComponent, { label: (0, trans_1.trans)('order-sales-report.filter.suppliers'), property: 'supplierPartner', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').toDate(), "control$": this.control }))) })),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: 'admin/sales-by-order-report', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function () { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    ProductSalesReportByOrdersScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    ProductSalesReportByOrdersScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('sku', 'meta.sku', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title', 'meta.title', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('capacity'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('vintage', 'meta.vintage', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 16 }, this.setHeaderCell('supplierPrice', 'supplierPrice.source', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 14 }, this.setHeaderCell('amount', 'itemsSold', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('total', 'total', true))));
    };
    ProductSalesReportByOrdersScreen.prototype.getSupplierPrice = function (item) {
        var _a, _b;
        var price = (0, currency_parser_1.hufFormat)((_a = item.supplierPrice) === null || _a === void 0 ? void 0 : _a.value);
        var source = ((_b = item.supplierPrice) === null || _b === void 0 ? void 0 : _b.source) && item.supplierPrice.source !== interfaces_1.SupplierPriceType.supplierPrice ? "(".concat((0, trans_1.trans)("product-sales-by-orders-report.".concat(item.supplierPrice.source)), ")") : '';
        return React.createElement("div", { className: 'display-flex flex-column' },
            React.createElement("div", null,
                React.createElement("span", null, price)),
            React.createElement("div", null,
                React.createElement("span", { className: 'fs-10 color--error-6' }, source)));
    };
    ProductSalesReportByOrdersScreen.prototype.getAmount = function (item) {
        var _a;
        if (!((_a = this.control.data) === null || _a === void 0 ? void 0 : _a.supplierPartner)) {
            return item.itemsSold;
        }
        return React.createElement("div", { className: 'display-flex flex-column' },
            React.createElement("div", null,
                React.createElement("span", null, item.itemsSold)),
            React.createElement("div", null,
                React.createElement("span", { className: 'fs-10 color--error-6' },
                    "(",
                    item.customerPartner.name,
                    ")")));
    };
    ProductSalesReportByOrdersScreen.prototype.renderItem = function (ctx, item) {
        var _a, _b, _c, _d;
        var id = item._id;
        var title = item.meta.title;
        var sku = item.meta.sku;
        var capacity = (_b = (_a = item.meta) === null || _a === void 0 ? void 0 : _a.capacity) !== null && _b !== void 0 ? _b : '-';
        var vintage = (_d = (_c = item.meta) === null || _c === void 0 ? void 0 : _c.vintage) !== null && _d !== void 0 ? _d : '-';
        var supplierPrice = this.getSupplierPrice(item);
        var amount = this.getAmount(item);
        var total = item.total;
        return React.createElement(table_body_row_1.TableBodyRow, { key: id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, title)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, capacity)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, vintage)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 16 },
                React.createElement("span", null, supplierPrice)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 14 },
                React.createElement("span", null, amount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(total))));
    };
    ProductSalesReportByOrdersScreen.prototype.setHeaderCell = function (p, sortProperty, enableSort) {
        var _this = this;
        if (sortProperty === void 0) { sortProperty = ''; }
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            sortValue: this.getSortValue(sortProperty),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            title: (0, trans_1.trans)("product-sales-by-orders-report.list.header.".concat(p)),
            property: sortProperty
        };
    };
    ProductSalesReportByOrdersScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    ProductSalesReportByOrdersScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    ProductSalesReportByOrdersScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    return ProductSalesReportByOrdersScreen;
}(abstract_component_1.AbstractComponent));
exports.ProductSalesReportByOrdersScreen = ProductSalesReportByOrdersScreen;
