module.exports = {
    'complaint.list.title': 'Reklamációk',
    'complaint.table.header.orderId': 'Megrendelés azonosító',
    'complaint.table.header.customerEmail': 'Megrendelő',
    'complaint.table.header.status': 'Állapot',
    'complaint.table.header.updatedAt': 'Utolsó üzenet ideje',
    'complaint.table.header.createdAt': 'Létrehzás ideje',
    'complaint-details.screen.title': 'Reklamáció részletei',
    'complaint-details.screen.order-details.subtitle': 'Megrendelés adatai',
    'complaint-details.screen.messages.subtitle': 'Üzenetek',
    'complaint.title': 'Reklamáció',
    'complaint.customer.label': 'Vásárló',
    'complaint.admin.label': 'Admin',
    'complaint.response.form.message.label': 'Üzenet',
    'complaint.new.form.message.label': 'Üzenet',
    'complaint.new.form.category.label': 'Kategória',
    'complaint.response.label': 'Üzenet',
    'complaint.start.description': 'Amennyiben bármi problémád van a fenti megrendeléssel, úgy vedd fel a kapcsolatot egy kollégánkkal az alábbi gomb segítségével.',
    'complaint.send.button.label': 'Üzenet küldése',
    'complaint.start.button.label': 'Reklamációt indítok',
};
