"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attributeOptionIcons = void 0;
exports.attributeOptionIcons = [
    { key: 'almond-drink-attributes', value: 'almond-drink-attributes', name: 'almond' },
    { key: 'anise-drink-attributes', value: 'anise-drink-attributes', name: 'anise' },
    { key: 'apple-drink-attributes', value: 'apple-drink-attributes', name: 'apple' },
    { key: 'banana-drink-attributes', value: 'banana-drink-attributes', name: 'banana' },
    { key: 'beer-alcohol-percentage-drink-attributes', value: 'beer-alcohol-percentage-drink-attributes', name: 'beer-alcohol-percentage' },
    { key: 'beer-character-drink-attributes', value: 'beer-character-drink-attributes', name: 'beer-character' },
    { key: 'beer-liquid-measure-drink-attributes', value: 'beer-liquid-measure-drink-attributes', name: 'beer-liquid-measure' },
    { key: 'beer-temperature-drink-attributes', value: 'beer-temperature-drink-attributes', name: 'beer-temperature' },
    { key: 'beetroot-drink-attributes', value: 'beetroot-drink-attributes', name: 'beetroot' },
    { key: 'biscuit-drink-attributes', value: 'biscuit-drink-attributes', name: 'biscuit' },
    { key: 'blackberry-drink-attributes', value: 'blackberry-drink-attributes', name: 'blackberry' },
    { key: 'blackcurrant-drink-attributes', value: 'blackcurrant-drink-attributes', name: 'blackcurrant' },
    { key: 'blueberry-drink-attributes', value: 'blueberry-drink-attributes', name: 'blueberry' },
    { key: 'bourbon-drink-attributes', value: 'bourbon-drink-attributes', name: 'bourbon' },
    { key: 'caramel-drink-attributes', value: 'caramel-drink-attributes', name: 'caramel' },
    { key: 'cashew-drink-attributes', value: 'cashew-drink-attributes', name: 'cashew' },
    { key: 'cherry-sour-cherry-drink-attributes', value: 'cherry-sour-cherry-drink-attributes', name: 'cherry-sour-cherry' },
    { key: 'chocolate-drink-attributes', value: 'chocolate-drink-attributes', name: 'chocolate' },
    { key: 'cinnamon-drink-attributes', value: 'cinnamon-drink-attributes', name: 'cinnamon' },
    { key: 'clove-drink-attributes', value: 'clove-drink-attributes', name: 'clove' },
    { key: 'cocoa-drink-attributes', value: 'cocoa-drink-attributes', name: 'cocoa' },
    { key: 'coconut-drink-attributes', value: 'coconut-drink-attributes', name: 'coconut' },
    { key: 'coffee-drink-attributes', value: 'coffee-drink-attributes', name: 'coffee' },
    { key: 'coriander-drink-attributes', value: 'coriander-drink-attributes', name: 'coriander' },
    { key: 'default-drink-attributes', value: 'default-drink-attributes', name: 'default' },
    { key: 'egzotic-flower-drink-attributes', value: 'egzotic-flower-drink-attributes', name: 'egzotic-flower' },
    { key: 'elder-flower-drink-attributes', value: 'elder-flower-drink-attributes', name: 'elder-flower' },
    { key: 'fig-drink-attributes', value: 'fig-drink-attributes', name: 'fig' },
    { key: 'food-pairing-drink-attributes', value: 'food-pairing-drink-attributes', name: 'food-pairing' },
    { key: 'ginger-drink-attributes', value: 'ginger-drink-attributes', name: 'ginger' },
    { key: 'grass-drink-attributes', value: 'grass-drink-attributes', name: 'grass' },
    { key: 'hazelnut-drink-attributes', value: 'hazelnut-drink-attributes', name: 'hazelnut' },
    { key: 'honey-drink-attributes', value: 'honey-drink-attributes', name: 'honey' },
    { key: 'hop-drink-attributes', value: 'hop-drink-attributes', name: 'hop' },
    { key: 'hop-type-drink-attributes', value: 'hop-type-drink-attributes', name: 'hop-type' },
    { key: 'ibu-drink-attributes', value: 'ibu-drink-attributes', name: 'ibu' },
    { key: 'lemon-lime-drink-attributes', value: 'lemon-lime-drink-attributes', name: 'lemon-lime' },
    { key: 'lemonbalm-menth-drink-attributes', value: 'lemonbalm-menth-drink-attributes', name: 'lemonbalm-menth' },
    { key: 'lichi-drink-attributes', value: 'lichi-drink-attributes', name: 'lichi' },
    { key: 'malt-drink-attributes', value: 'malt-drink-attributes', name: 'malt' },
    { key: 'mango-drink-attributes', value: 'mango-drink-attributes', name: 'mango' },
    { key: 'mineral-drink-attributes', value: 'mineral-drink-attributes', name: 'mineral' },
    { key: 'mushroom-drink-attributes', value: 'mushroom-drink-attributes', name: 'mushroom' },
    { key: 'nut-drink-attributes', value: 'nut-drink-attributes', name: 'nut' },
    { key: 'orange-grapefruit-mandarin-drink-attributes', value: 'orange-grapefruit-mandarin-drink-attributes', name: 'orange-grapefruit-mandarin' },
    { key: 'paprika-drink-attributes', value: 'paprika-drink-attributes', name: 'paprika' },
    { key: 'peach-drink-attributes', value: 'peach-drink-attributes', name: 'peach' },
    { key: 'pear-drink-attributes', value: 'pear-drink-attributes', name: 'pear' },
    { key: 'pepper-drink-attributes', value: 'pepper-drink-attributes', name: 'pepper' },
    { key: 'pine-drink-attributes', value: 'pine-drink-attributes', name: 'pine' },
    { key: 'pineapple-drink-attributes', value: 'pineapple-drink-attributes', name: 'pineapple' },
    { key: 'plum-drink-attributes', value: 'plum-drink-attributes', name: 'plum' },
    { key: 'poppy-drink-attributes', value: 'poppy-drink-attributes', name: 'poppy' },
    { key: 'raisins-drink-attributes', value: 'raisins-drink-attributes', name: 'raisins' },
    { key: 'raspberry-drink-attributes', value: 'raspberry-drink-attributes', name: 'raspberry' },
    { key: 'rose-drink-attributes', value: 'rose-drink-attributes', name: 'rose' },
    { key: 'rum-drink-attributes', value: 'rum-drink-attributes', name: 'rum' },
    { key: 'seasoning-drink-attributes', value: 'seasoning-drink-attributes', name: 'seasoning' },
    { key: 'strawberry-drink-attributes', value: 'strawberry-drink-attributes', name: 'strawberry' },
    { key: 'tea-drink-attributes', value: 'tea-drink-attributes', name: 'tea' },
    { key: 'tobacco-leaf-drink-attributes', value: 'tobacco-leaf-drink-attributes', name: 'tobacco-leaf' },
    { key: 'tropicalFruit-drink-attributes', value: 'tropicalFruit-drink-attributes', name: 'tropicalFruit' },
    { key: 'vanilla-drink-attributes', value: 'vanilla-drink-attributes', name: 'vanilla' },
    { key: 'wheat-drink-attributes', value: 'wheat-drink-attributes', name: 'wheat' },
    { key: 'wine-alcohol-percentage-drink-attributes', value: 'wine-alcohol-percentage-drink-attributes', name: 'wine-alcohol-percentage' },
    { key: 'wine-character-drink-attributes', value: 'wine-character-drink-attributes', name: 'wine-character' },
    { key: 'wine-liquid-measure-drink-attributes', value: 'wine-liquid-measure-drink-attributes', name: 'wine-liquid-measure' },
    { key: 'wine-origin-drink-attributes', value: 'wine-origin-drink-attributes', name: 'wine-origin' },
    { key: 'wine-temperature-drink-attributes', value: 'wine-temperature-drink-attributes', name: 'wine-temperature' },
    { key: 'yeast-drink-attributes', value: 'yeast-drink-attributes', name: 'yeast' }
];
