"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearlySalesByPartner = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var select_input_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var moment = require("moment/moment");
var React = require("react");
var box_loader_1 = require("../../../common/components/repository-list/loader/box-loader");
var b2b_order_list_screen_1 = require("../../b2b-order/b2b-order-list.screen");
var sales_commitment_charts_1 = require("./libs/sales-commitment-charts");
var YearlySalesByPartner = (function (_super) {
    __extends(YearlySalesByPartner, _super);
    function YearlySalesByPartner() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerPartner: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'customerPartner',
                    label: 'Vevő partner',
                    validators: []
                }),
            }
        });
        _this.state = {
            loading: false,
            customerPartner: null,
            percentage: 0,
            total: 0,
            fact: []
        };
        return _this;
    }
    YearlySalesByPartner.prototype.componentDidMount = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var partner;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.form.fields.customerPartner.setOptions(this.props.customerPartners.map(function (i) { return ({ name: "".concat(i === null || i === void 0 ? void 0 : i.name, " (").concat(i === null || i === void 0 ? void 0 : i.name, ")"), key: i._id, value: i._id }); }));
                        partner = (_a = this.form.fields.customerPartner.getValue()) !== null && _a !== void 0 ? _a : (_c = (_b = this.props.customerPartners) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c._id;
                        return [4, this.fetch(partner)];
                    case 1:
                        _d.sent();
                        this.$subscriptions.push(this.form.fields.customerPartner.$value.subscribe(function (customerPartner) { return _this.fetch(customerPartner); }));
                        return [2];
                }
            });
        });
    };
    YearlySalesByPartner.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (prevProps.customerPartners !== this.props.customerPartners && ((_a = this.props.customerPartners) === null || _a === void 0 ? void 0 : _a.length)) {
            this.form.fields.customerPartner.setValue(this.props.customerPartners[0]._id);
            this.form.fields.customerPartner.setOptions(this.props.customerPartners.map(function (i) { return ({ name: "".concat(i === null || i === void 0 ? void 0 : i.name, " (").concat(i === null || i === void 0 ? void 0 : i.name, ")"), key: i._id, value: i._id }); }));
        }
    };
    YearlySalesByPartner.prototype.render = function () {
        return React.createElement("div", { style: { minHeight: '300px' } },
            this.renderPartnerFilter(),
            this.renderChart());
    };
    YearlySalesByPartner.prototype.renderChart = function () {
        var _a, _b;
        if (this.state.loading) {
            return React.createElement("div", { style: { minHeight: '300px' }, className: 'row py-9 ' },
                React.createElement(box_loader_1.BoxLoader, null));
        }
        return React.createElement(sales_commitment_charts_1.SalesCommitmentCharts, { total: this.state.total, data: this.state.fact, monthlyCommitment: (_b = (_a = this.state.customerPartner) === null || _a === void 0 ? void 0 : _a.commitment) !== null && _b !== void 0 ? _b : 0, percentage: this.state.percentage });
    };
    YearlySalesByPartner.prototype.renderPartnerFilter = function () {
        return React.createElement("div", { className: "w-100 display-flex align-items-center" },
            React.createElement("div", { className: "flex-fill" },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { unclearable: false, searchable: false, className: 'select-input-basic' }))));
    };
    YearlySalesByPartner.prototype.fetch = function (customerPartner) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var from, to, url, paidFact, determinedFact, fact, factTotal, percentage, total, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, 4, 5]);
                        if (!customerPartner) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        from = moment().startOf('year').toString();
                        to = moment().endOf('year').toString();
                        url = "/b2b/order?customerPartner=".concat(customerPartner, "&from=").concat(from, "&to=").concat(to);
                        return [4, this.repository.get("".concat(url, "&status=").concat(b2b_order_list_screen_1.b2bOrderStatus.paid.status))];
                    case 1:
                        paidFact = _b.sent();
                        return [4, this.repository.get("".concat(url, "&status=").concat(b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status))];
                    case 2:
                        determinedFact = _b.sent();
                        fact = __spreadArray(__spreadArray([], __read(paidFact.items), false), __read(determinedFact.items), false);
                        factTotal = fact.reduce(function (acc, item) { return acc + (item.currentTotalValue || 0); }, 0);
                        percentage = !!((_a = this.state.customerPartner) === null || _a === void 0 ? void 0 : _a.commitment)
                            ? (Math.floor(factTotal / this.state.customerPartner * 100) || 0)
                            : 0;
                        total = factTotal;
                        this.setState({ percentage: percentage, fact: fact, total: total });
                        return [3, 5];
                    case 3:
                        e_1 = _b.sent();
                        console.log({ YearlySalesByPartner: e_1 });
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    return YearlySalesByPartner;
}(abstract_component_1.AbstractComponent));
exports.YearlySalesByPartner = YearlySalesByPartner;
