"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onLogin = void 0;
var permissions_1 = require("../permissions/permissions");
var get_safe_redirect_url_1 = require("./get-safe-redirect-url");
var set_access_token_1 = require("./set-access-token");
var onLogin = function (response, targetUrl) {
    var _a, _b, _c, _d;
    (0, set_access_token_1.setAccessToken)(response.accessToken);
    var permissions = (_d = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.user) === null || _a === void 0 ? void 0 : _a.publicMeta) === null || _b === void 0 ? void 0 : _b.permissions) === null || _c === void 0 ? void 0 : _c.role) === null || _d === void 0 ? void 0 : _d.permissions;
    window.location.href = (0, get_safe_redirect_url_1.getSafeRedirectUrl)(permissions, permissions_1.PERMISSIONS.DASHBOARD.read, targetUrl);
};
exports.onLogin = onLogin;
