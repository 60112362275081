module.exports = {
    'manufacturer.list.title': 'Borászatok',
    'winery.list.button.create.title': 'Borászat hozzáadása',
    'manufacturer.table.header.name': 'Borászat neve',
    'manufacturer.table.header.code': 'Borászat kódja',
    'winery-update.save-box.last-modified.label': 'Módosítás ideje',
    'winery-create.screen.title': 'Borászat létrehozása',
    'winery-update.screen.title': 'Borászat szerkesztése',
    'winery-update.subtitle.basic-data': 'Alap adatok',
    'winery-create.subtitle.basic-data': 'Alap adatok',
    'winery.form.commissioner': 'Bizományos beszállító',
    'winery.form.show-on-b2c': 'Jelenjen meg a borászatok listában',
    'winery.form.name': 'Borászat neve',
    'winery.form.code': 'Borászat kódja',
    'winery.form.huTaxNumber': 'Adószám',
    'winery.form.euTaxNumber': 'EU Adószám',
    'winery.form.heaCode': 'HÉA azonosítószám',
    'winery.form.country': 'Ország',
    'winery.form.city': 'Város',
    'winery.form.zipCode': 'Irányító szám',
    'winery.form.address': 'Cím',
    'winery.form.region': 'Régió',
    'winery.form.description': 'Leírás',
    'winery.form.mozaikManufacturers': 'Gyártó cég',
    'winery.form.area': 'Terület',
    'winery.form.grape_variety': 'Szőlőfajták',
    'winery.form.vineyard': 'Dűlő',
    'winery.form.soil': 'Talaj',
    'winery.form.technology': 'Borkészítés',
    'winery-prize.form.field.description.label': 'Díj neve',
    'winery-create.success.toast': 'A borászat létrehozása sikeresen megtörtént',
    'winery-update.success.toast': 'A borászat módosítása sikeresen megtörtént',
};
