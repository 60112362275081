"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var permissions_1 = require("../../../common/libs/permissions/permissions");
var user_permission_1 = require("../../../common/libs/user-permission");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var badge_1 = require("../../../components/libs/badge/badge");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var optional_component_1 = require("../../../components/optional-component");
var user_list_screen_1 = require("../../user/user-list.screen");
var basic_data_form_1 = require("./product-attribute-editor/basic-data-form");
var bundle_product_form_1 = require("./product-attribute-editor/bundle-product.form");
var custom_sku_handler_1 = require("./product-attribute-editor/custom-sku/custom-sku-handler");
var excise_duty_data_form_1 = require("./product-attribute-editor/excise-duty-data.form");
var extended_extra_attributes_form_1 = require("./product-attribute-editor/extended-extra-attributes-form");
var prizes_form_1 = require("./product-attribute-editor/prizes-form");
var product_attributes_form_1 = require("./product-attribute-editor/product-attributes-form");
var seo_form_1 = require("./product-attribute-editor/seo-form");
var specialist_form_1 = require("./product-attribute-editor/specialist-form");
var supplier_partner_form_1 = require("./product-attribute-editor/supplier-partner-form");
var product_reviews_component_1 = require("./product-review/product-reviews.component");
require("../product-update.screen.scss");
var languageOptions = [
    { value: 'hu', name: 'HU', key: 'hu' },
    { value: 'en', name: 'EN', key: 'en' }
];
var ProductUpdateScreen = (function (_super) {
    __extends(ProductUpdateScreen, _super);
    function ProductUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            product: null,
            customSKUList: {},
            editorLanguage: 'hu',
            isBundle: null,
            isInBundle: false,
            loading: false,
            requiredErrors: [],
            supplierCanEdit: true,
            showHiper: false,
        };
        return _this;
    }
    ProductUpdateScreen.prototype.componentDidMount = function () {
        this.fetchProduct();
        this.handleB2BSupplierEditable();
    };
    ProductUpdateScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevState.product !== this.state.product) {
            this.handleB2BSupplierEditable();
        }
    };
    ProductUpdateScreen.prototype.handleB2BSupplierEditable = function () {
        var _a;
        var status = user_list_screen_1.ProductStatus.FilledByPartner;
        if (!(0, user_permission_1.hasRole)(user_permission_1.Role.b2bSupplier)) {
            return;
        }
        var currentIndex = Object.keys(user_list_screen_1.ProductStatus).indexOf((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status);
        var isFulfilledStatus = Object.keys(user_list_screen_1.ProductStatus).indexOf(status) <= currentIndex;
        return this.setState({ supplierCanEdit: isFulfilledStatus });
    };
    ProductUpdateScreen.prototype.fetchProduct = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId))];
                    case 1:
                        response = _c.sent();
                        this.setState({ product: response, isBundle: (response === null || response === void 0 ? void 0 : response.type) === 'bundle', isInBundle: response.isInBundle });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        return React.createElement("div", { className: 'ProductUpdateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-primary-link button-size-normal', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/products'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('product.update.title'))),
                !!this.state.requiredErrors.length && this.renderRequiredErrors(),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.images'))),
                        this.renderImageUploader()),
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.basic-data'))),
                        React.createElement(basic_data_form_1.BasicDataForm, { onChannelsChange: function (showHiper) { return _this.setState({ showHiper: showHiper }); }, ref: function (ref) { return _this.productBasicDataFormRef$ = ref; }, disabled: !this.enableEdit(), editorLanguage: this.state.editorLanguage, values: this.state.product }))),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-24" },
                                React.createElement("div", { className: 'mb-3' },
                                    React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.bundle-settings'))),
                                React.createElement(bundle_product_form_1.BundleProductForm, { disabled: !this.enableEdit() || this.state.isInBundle, ref: function (ref) { return _this.bundleProductFormRef$ = ref; }, type: (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.type, sku: (_b = this.state.product) === null || _b === void 0 ? void 0 : _b.sku, bundleChange: function (value) { return _this.setState({ isBundle: value }); }, bundleProducts: (_c = this.state.product) === null || _c === void 0 ? void 0 : _c.bundleProducts }))),
                        React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }))),
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-24" },
                                React.createElement("div", { className: 'mb-3' },
                                    React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.seo'))),
                                React.createElement("div", { className: 'row flex-column flex-md-row' },
                                    React.createElement("div", { className: 'col col-md-12' },
                                        React.createElement("p", null, (0, trans_1.trans)('product.seo.form.og-image')),
                                        React.createElement(image_uploader_1.ImageUploader, { disabled: !this.enableEdit(), ref: function (ref) { return _this.imageSeoFacebookUploaderRef$ = ref; }, value: (_e = (_d = this.state.product) === null || _d === void 0 ? void 0 : _d.seo) === null || _e === void 0 ? void 0 : _e.ogImage, width: 368, heigth: 368, namePrefix: function () { var _a; return (_a = _this.seoFormRef$) === null || _a === void 0 ? void 0 : _a.getFileNamePrefix('facebook'); }, targetVariant: "default" })),
                                    React.createElement("div", { className: 'col col-md-12' },
                                        React.createElement(seo_form_1.SeoForm, { ref: function (ref) { return _this.seoFormRef$ = ref; }, disabled: !this.enableEdit(), editorLanguage: this.state.editorLanguage, values: this.state.product }))))),
                        React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }))),
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-24" },
                                React.createElement("div", { className: 'mb-3' },
                                    React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.excise-duty'))),
                                React.createElement(excise_duty_data_form_1.ExciseDutyDataForm, { ref: function (ref) { return _this.exciseDutyDataFormRef$ = ref; }, disabled: !this.enableEdit(), values: this.state.product }))),
                        React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }))),
                (!this.state.isBundle) && React.createElement("div", null,
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-24" },
                            React.createElement("div", { className: 'mb-3' },
                                React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.attributes'))),
                            React.createElement(product_attributes_form_1.ProductAttributesForm, { disabled: !this.enableEdit(), ref: function (ref) { return _this.productAttributesFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, attributes: (0, lodash_1.compact)((_j = ((_h = (_g = (_f = this.state.product) === null || _f === void 0 ? void 0 : _f.b2cCategory) === null || _g === void 0 ? void 0 : _g.b2cAttributeValues) === null || _h === void 0 ? void 0 : _h.sort(function (a, b) { return (a.adminOrder - b.adminOrder); }))) === null || _j === void 0 ? void 0 : _j.map(function (av) { return !av.attribute.isAccordingToOurSpecialists ? av.attribute : null; })), values: (_k = this.state.product) === null || _k === void 0 ? void 0 : _k.b2cAttributeValues }))),
                    React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' })),
                React.createElement(optional_component_1.OptionalComponent, { condition: this.state.showHiper, ifTrue: React.createElement("div", { className: "row my-7" },
                        React.createElement("div", { className: "col-24" },
                            React.createElement("div", { className: 'mb-3' },
                                React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.custom-sku'))),
                            React.createElement(custom_sku_handler_1.CustomSkuHandler, { show: this.state.showHiper, customSKUList: (_l = this.state.product) === null || _l === void 0 ? void 0 : _l.customSKUList, onDataChange: function (customSKU) { return _this.setState({ customSKUList: __assign(__assign({}, _this.state.customSKUList), customSKU) }); } }))) }),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.extra-attributes'))),
                        React.createElement(extended_extra_attributes_form_1.ExtendedExtraAttributesForm, { ref: function (ref) { return _this.productExtraAttributesFormRef$ = ref; }, disabled: !this.enableEdit(), productCategory: (_p = (_o = (_m = this.state.product) === null || _m === void 0 ? void 0 : _m.b2cCategory) === null || _o === void 0 ? void 0 : _o.slug) === null || _p === void 0 ? void 0 : _p.en, productType: this.state.isBundle ? 'bundle' : 'single', editorLanguage: (_q = this.state) === null || _q === void 0 ? void 0 : _q.editorLanguage, attributes: (_s = (_r = this.state) === null || _r === void 0 ? void 0 : _r.product) === null || _s === void 0 ? void 0 : _s.extendedExtraAttributes }))),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", null,
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-24" },
                            React.createElement("div", { className: 'mb-3' },
                                React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.specialist'))),
                            React.createElement(specialist_form_1.SpecialistForm, { disabled: !this.enableEdit(), ref: function (ref) { return _this.productSpecialistFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, attributes: (0, lodash_1.compact)((_v = (_u = (_t = this.state.product) === null || _t === void 0 ? void 0 : _t.b2cCategory) === null || _u === void 0 ? void 0 : _u.b2cAttributeValues) === null || _v === void 0 ? void 0 : _v.map(function (av) { var _a; return !!((_a = av.attribute) === null || _a === void 0 ? void 0 : _a.isAccordingToOurSpecialists) ? av.attribute : null; })), values: (_w = this.state.product) === null || _w === void 0 ? void 0 : _w.b2cAttributeValues }))),
                    React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' })),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.prizes'))),
                        React.createElement(prizes_form_1.PrizesForm, { disabled: !this.enableEdit(), ref: function (ref) { return _this.productPrizesFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, values: this.state.product }))),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", null,
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-24" },
                            React.createElement(product_reviews_component_1.ProductReviewsComponent, { loading: this.state.loading, onChange: function () { return _this.fetchProduct(); }, productId: (_x = this.state.product) === null || _x === void 0 ? void 0 : _x._id, sumRating: (_y = this.state.product) === null || _y === void 0 ? void 0 : _y.sumRating, disabled: !this.enableEdit(), ratings: ((_z = this.state.product) === null || _z === void 0 ? void 0 : _z.ratings) || [] }))),
                    React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' })),
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-24" },
                                React.createElement("div", { className: 'mb-3' },
                                    React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.supplier-settings'))),
                                React.createElement(supplier_partner_form_1.SupplierPartnerForm, { ref: function (ref) { return _this.supplierFormRef$ = ref; }, disabled: !this.enableEdit(), supplier: (_0 = this.state.product) === null || _0 === void 0 ? void 0 : _0.supplier }))),
                        React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' })))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    ProductUpdateScreen.prototype.enableEdit = function () {
        var _a;
        if (((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status) !== user_list_screen_1.ProductStatus.SentToPartner) {
            return !!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update) && this.state.supplierCanEdit;
        }
        return true;
    };
    ProductUpdateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        var _a;
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(image_uploader_1.ImageUploader, { disabled: !this.enableEdit(), ref: function (ref) { return _this.imageSingleProductUploaderRef$ = ref; }, value: (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.imageSingleProduct, width: 368, heigth: 368, targetVariant: "default", namePrefix: function () { var _a; return (_a = _this.productBasicDataFormRef$) === null || _a === void 0 ? void 0 : _a.getFileNamePrefix('single'); } })));
    };
    ProductUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'SaveBox mb-4' },
                React.createElement("div", { className: 'display-flex flex-column' },
                    React.createElement("small", null, (0, trans_1.trans)('product.update.save-box.last-modified.label')),
                    React.createElement("strong", null, moment((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.updatedAt).format('YYYY.MM.DD. HH:mm'))),
                React.createElement("div", { className: "mb-4" },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', value: this.state.editorLanguage, onChange: function (value) { return _this.changeLanguage(value); }, unclearable: true, options: languageOptions })),
                !!this.enableEdit() && React.createElement("div", { className: 'w-100 display-flex justify-content-center mb-4' },
                    React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.submitForms(); }, title: this.state.loading ? React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.save'), className: 'button-primary-normal button-size-normal' })),
                React.createElement("div", { className: 'w-100 display-flex justify-content-center' }, this.renderStatusChangeButton()),
                React.createElement("div", { className: 'w-100 display-flex justify-content-center' }, this.renderDistributedButton()),
                React.createElement("div", { className: 'w-100 display-flex justify-content-center' }, this.renderPublishButton())),
            React.createElement("div", null, this.renderStatusBox()));
    };
    ProductUpdateScreen.prototype.renderStatusBox = function () {
        return React.createElement("div", { className: 'StatusBox' },
            React.createElement("div", { className: 'mb-2' }, this.renderPublishedBadge()),
            React.createElement("span", { className: 'mb-2' }, (0, trans_1.trans)('product-update.status-box.title')),
            React.createElement("div", { className: 'display-flex flex-column position-relative pl-4' },
                this.renderStatusIndicator(user_list_screen_1.ProductStatus.Draft),
                this.renderStatusIndicator(user_list_screen_1.ProductStatus.SentToPartner),
                this.renderStatusIndicator(user_list_screen_1.ProductStatus.FilledByPartner),
                this.renderStatusIndicator(user_list_screen_1.ProductStatus.CommercialApproved),
                this.renderStatusIndicator(user_list_screen_1.ProductStatus.MarketingApproved)),
            React.createElement("div", { className: 'w-100 display-flex justify-content-center' }, this.renderStatusChangeBackButton()));
    };
    ProductUpdateScreen.prototype.renderStatusIndicator = function (status) {
        var _a;
        var currentIndex = Object.keys(user_list_screen_1.ProductStatus).indexOf((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status);
        var isFulfilledStatus = Object.keys(user_list_screen_1.ProductStatus).indexOf(status) <= currentIndex;
        var pending = Object.keys(user_list_screen_1.ProductStatus).indexOf(status) === currentIndex + 1;
        return React.createElement("div", { className: "mb-2 ".concat(isFulfilledStatus ? 'fulfilled' : '', " ").concat(pending ? 'pending' : '') },
            React.createElement("small", { className: 'display-flex align-items-center' },
                React.createElement("div", { className: 'dot mr-2' }),
                (0, trans_1.trans)("product-update.status-box.".concat(status))));
    };
    ProductUpdateScreen.prototype.renderVamInformatikaBadge = function () {
        var _a;
        if ((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.isVamInformatikaReady) {
            return React.createElement(badge_1.Badge, { className: 'p-2', type: 'success', title: (0, trans_1.trans)('product-update.status-box.badge.VamInformatikaReady') });
        }
        return React.createElement(badge_1.Badge, { className: 'p-2', type: 'neutral', title: (0, trans_1.trans)('product-update.status-box.badge.NotVamInformatikaReady') });
    };
    ProductUpdateScreen.prototype.renderPublishedBadge = function () {
        var _a, _b, _c;
        if (((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status) !== user_list_screen_1.ProductStatus.MarketingApproved) {
            return;
        }
        if (((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.published) === null) {
            return React.createElement(badge_1.Badge, { className: 'p-2', type: 'neutral', title: (0, trans_1.trans)('product.status.waitingToPublish') });
        }
        if ((_c = this.state.product) === null || _c === void 0 ? void 0 : _c.published) {
            return React.createElement(badge_1.Badge, { className: 'p-2', type: 'success', title: (0, trans_1.trans)('product.status.published') });
        }
        return React.createElement(badge_1.Badge, { className: 'p-2', type: 'error', title: (0, trans_1.trans)('product.status.unpublished') });
    };
    ProductUpdateScreen.prototype.renderStatusChangeButton = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (user_list_screen_1.ProductStatus[(_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status] === user_list_screen_1.ProductStatus.Draft) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.approveAndSendToPartner(); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.sent-to-partner'), className: 'button-primary-outline button-size-normal-success' });
        }
        if (user_list_screen_1.ProductStatus[(_b = this.state.product) === null || _b === void 0 ? void 0 : _b.status] === user_list_screen_1.ProductStatus.SentToPartner) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.approveByPartner(); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.approve-by-partner'), className: 'button-primary-outline button-size-normal-success' });
        }
        if (user_list_screen_1.ProductStatus[(_c = this.state.product) === null || _c === void 0 ? void 0 : _c.status] === user_list_screen_1.ProductStatus.FilledByPartner) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.approveByCommercial(); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.approve-by-commercial'), className: 'button-primary-outline button-size-normal-success' });
        }
        if (user_list_screen_1.ProductStatus[(_d = this.state.product) === null || _d === void 0 ? void 0 : _d.status] === user_list_screen_1.ProductStatus.CommercialApproved) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.approveByMarketing(); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.approve-by-marketing'), className: 'button-primary-outline button-size-normal-success' });
        }
        return React.createElement("div", null);
    };
    ProductUpdateScreen.prototype.renderStatusChangeBackButton = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (user_list_screen_1.ProductStatus[(_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status] === user_list_screen_1.ProductStatus.SentToPartner) {
            if (!(0, user_permission_1.userHasPermission)(user_permission_1.Role.superadmin)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.resetToStatus(user_list_screen_1.ProductStatus.Draft); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : "".concat((0, trans_1.trans)('product.update.save-box.button.status-back'), " ").concat((0, trans_1.trans)("product-update.status-box.".concat(user_list_screen_1.ProductStatus.SentToPartner)), "ra"), className: 'font-size-small button-primary-outline button-size-normal-info' });
        }
        if (user_list_screen_1.ProductStatus[(_b = this.state.product) === null || _b === void 0 ? void 0 : _b.status] === user_list_screen_1.ProductStatus.FilledByPartner) {
            if (!(0, user_permission_1.userHasPermission)(user_permission_1.Role.superadmin)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.resetToStatus(user_list_screen_1.ProductStatus.SentToPartner); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : "".concat((0, trans_1.trans)('product.update.save-box.button.status-back'), " ").concat((0, trans_1.trans)("product-update.status-box.".concat(user_list_screen_1.ProductStatus.FilledByPartner)), "ra"), className: 'font-size-small button-primary-outline button-size-normal-info' });
        }
        if (user_list_screen_1.ProductStatus[(_c = this.state.product) === null || _c === void 0 ? void 0 : _c.status] === user_list_screen_1.ProductStatus.CommercialApproved) {
            if (!(0, user_permission_1.userHasPermission)(user_permission_1.Role.superadmin)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.resetToStatus(user_list_screen_1.ProductStatus.FilledByPartner); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : "".concat((0, trans_1.trans)('product.update.save-box.button.status-back'), " ").concat((0, trans_1.trans)("product-update.status-box.".concat(user_list_screen_1.ProductStatus.CommercialApproved)), "ra"), className: 'font-size-small button-primary-outline button-size-normal-info' });
        }
        if (user_list_screen_1.ProductStatus[(_d = this.state.product) === null || _d === void 0 ? void 0 : _d.status] === user_list_screen_1.ProductStatus.MarketingApproved) {
            if (!(0, user_permission_1.userHasPermission)(user_permission_1.Role.superadmin)) {
                return;
            }
            return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.resetToStatus(user_list_screen_1.ProductStatus.CommercialApproved); }, title: this.state.loading ?
                    React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : "".concat((0, trans_1.trans)('product.update.save-box.button.status-back'), " ").concat((0, trans_1.trans)("product-update.status-box.".concat(user_list_screen_1.ProductStatus.MarketingApproved)), "ra"), className: 'font-size-small button-primary-outline button-size-normal-info' });
        }
        return React.createElement("div", null);
    };
    ProductUpdateScreen.prototype.renderPublishButton = function () {
        var _this = this;
        var _a, _b, _c;
        if (user_list_screen_1.ProductStatus[(_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status] === user_list_screen_1.ProductStatus.MarketingApproved) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            if (!!((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.published)) {
                return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.unpublish(); }, title: this.state.loading ?
                        React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.unpublish'), className: 'button-primary-outline button-size-normal-error' });
            }
            if (!((_c = this.state.product) === null || _c === void 0 ? void 0 : _c.published)) {
                return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.publish(); }, title: this.state.loading ?
                        React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.publish'), className: 'button-primary-outline button-size-normal-success' });
            }
        }
        return React.createElement("div", null);
    };
    ProductUpdateScreen.prototype.renderDistributedButton = function () {
        var _this = this;
        var _a, _b, _c;
        if (user_list_screen_1.ProductStatus[(_a = this.state.product) === null || _a === void 0 ? void 0 : _a.status] === user_list_screen_1.ProductStatus.MarketingApproved) {
            if (!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update)) {
                return;
            }
            if (!!((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.distributed)) {
                return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.undistribute(); }, title: this.state.loading ?
                        React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.undistribute'), className: 'button-primary-outline button-size-normal-error mb-4' });
            }
            if (!((_c = this.state.product) === null || _c === void 0 ? void 0 : _c.distributed)) {
                return React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.distribute(); }, title: this.state.loading ?
                        React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.distribute'), className: 'button-primary-outline button-size-normal-success mb-4' });
            }
        }
        return React.createElement("div", null);
    };
    ProductUpdateScreen.prototype.resetToStatus = function (status) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/status"), { data: { status: status } })];
                    case 1:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.status-back.success.toast'));
                        return [3, 3];
                    case 2:
                        e_2 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_2, (0, trans_1.trans)(e_2.message.replace(/\s/g, '')));
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.approveAndSendToPartner = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/status"), { data: { status: user_list_screen_1.ProductStatus.SentToPartner } })];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.approve.success.toast'));
                        return [3, 4];
                    case 3:
                        e_3 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_3, (0, trans_1.trans)(e_3.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.approveByPartner = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_4;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/status"), { data: { status: user_list_screen_1.ProductStatus.FilledByPartner } })];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.approve.success.toast'));
                        return [3, 4];
                    case 3:
                        e_4 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_4, (0, trans_1.trans)(e_4.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.approveByCommercial = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_5;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/status"), { data: { status: user_list_screen_1.ProductStatus.CommercialApproved } })];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.approve.success.toast'));
                        return [3, 4];
                    case 3:
                        e_5 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_5, (0, trans_1.trans)(e_5.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.approveByMarketing = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_6;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/status"), { data: { status: user_list_screen_1.ProductStatus.MarketingApproved } })];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.approve.success.toast'));
                        return [3, 4];
                    case 3:
                        e_6 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_6, (0, trans_1.trans)(e_6.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.publish = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_7;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/publish"))];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.publish.success.toast'));
                        return [3, 4];
                    case 3:
                        e_7 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_7, (0, trans_1.trans)(e_7.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.unpublish = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_8;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/unpublish"))];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.unpublish.success.toast'));
                        return [3, 4];
                    case 3:
                        e_8 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_8, (0, trans_1.trans)(e_8.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.distribute = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_9;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/distribute"))];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.distribute.success.toast'));
                        return [3, 4];
                    case 3:
                        e_9 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_9, (0, trans_1.trans)(e_9.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.undistribute = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var submitSuccess, response, e_10;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, this.submitForms()];
                    case 1:
                        submitSuccess = _c.sent();
                        if (!submitSuccess) {
                            return [2];
                        }
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId, "/undistribute"))];
                    case 2:
                        response = _c.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.undistribute.success.toast'));
                        return [3, 4];
                    case 3:
                        e_10 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_10, (0, trans_1.trans)(e_10.message.replace(/\s/g, '')));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.submitForms = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return __awaiter(this, void 0, void 0, function () {
            var basicData, extraAttributes, attributes, specialist, prizeData, bundleSettings, exciseDutyData, seo, supplier, imageSingleProduct, imageSeoFacebook, requiredErrors, data, response, e_11;
            return __generator(this, function (_p) {
                switch (_p.label) {
                    case 0:
                        _p.trys.push([0, 13, , 14]);
                        this.setState({ loading: true, requiredErrors: [] });
                        return [4, ((_a = this.productBasicDataFormRef$) === null || _a === void 0 ? void 0 : _a.getData())];
                    case 1:
                        basicData = _p.sent();
                        return [4, ((_b = this.productExtraAttributesFormRef$) === null || _b === void 0 ? void 0 : _b.getData())];
                    case 2:
                        extraAttributes = _p.sent();
                        return [4, ((_c = this.productAttributesFormRef$) === null || _c === void 0 ? void 0 : _c.getData())];
                    case 3:
                        attributes = _p.sent();
                        return [4, ((_d = this.productSpecialistFormRef$) === null || _d === void 0 ? void 0 : _d.getData())];
                    case 4:
                        specialist = _p.sent();
                        return [4, ((_e = this.productPrizesFormRef$) === null || _e === void 0 ? void 0 : _e.getData())];
                    case 5:
                        prizeData = _p.sent();
                        return [4, ((_f = this.bundleProductFormRef$) === null || _f === void 0 ? void 0 : _f.getData())];
                    case 6:
                        bundleSettings = _p.sent();
                        return [4, ((_g = this.exciseDutyDataFormRef$) === null || _g === void 0 ? void 0 : _g.getData())];
                    case 7:
                        exciseDutyData = _p.sent();
                        return [4, ((_h = this.seoFormRef$) === null || _h === void 0 ? void 0 : _h.getData())];
                    case 8:
                        seo = _p.sent();
                        return [4, ((_j = this.supplierFormRef$) === null || _j === void 0 ? void 0 : _j.getData())];
                    case 9:
                        supplier = _p.sent();
                        return [4, ((_k = this.imageSingleProductUploaderRef$) === null || _k === void 0 ? void 0 : _k.getValue())];
                    case 10:
                        imageSingleProduct = _p.sent();
                        return [4, ((_l = this.imageSeoFacebookUploaderRef$) === null || _l === void 0 ? void 0 : _l.getValue())];
                    case 11:
                        imageSeoFacebook = _p.sent();
                        if ((supplier === null || supplier === void 0 ? void 0 : supplier.error) ||
                            (extraAttributes === null || extraAttributes === void 0 ? void 0 : extraAttributes.error) ||
                            (attributes === null || attributes === void 0 ? void 0 : attributes.error) ||
                            (basicData === null || basicData === void 0 ? void 0 : basicData.error) ||
                            (specialist === null || specialist === void 0 ? void 0 : specialist.error) ||
                            (prizeData === null || prizeData === void 0 ? void 0 : prizeData.error) ||
                            (exciseDutyData === null || exciseDutyData === void 0 ? void 0 : exciseDutyData.error) ||
                            (seo === null || seo === void 0 ? void 0 : seo.error)) {
                            requiredErrors = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((supplier === null || supplier === void 0 ? void 0 : supplier.keys) || []), false), __read((extraAttributes === null || extraAttributes === void 0 ? void 0 : extraAttributes.keys) || []), false), __read((attributes === null || attributes === void 0 ? void 0 : attributes.keys) || []), false), __read((exciseDutyData === null || exciseDutyData === void 0 ? void 0 : exciseDutyData.keys) || []), false);
                            this.setState({ loading: false, requiredErrors: requiredErrors });
                            throw new Error('form.missing.required');
                        }
                        data = __assign(__assign(__assign(__assign(__assign(__assign({}, basicData), exciseDutyData), { customSKUList: this.state.customSKUList, extraAttributes: extraAttributes, imageSingleProduct: imageSingleProduct, type: (bundleSettings === null || bundleSettings === void 0 ? void 0 : bundleSettings.isBundle) ? 'bundle' : 'single', bundleProducts: bundleSettings === null || bundleSettings === void 0 ? void 0 : bundleSettings.bundleProducts, b2cAttributeValues: __assign(__assign({}, attributes), specialist) }), prizeData), { seo: __assign(__assign({}, seo), { ogImage: imageSeoFacebook }) }), supplier);
                        return [4, this.repository.put("/admin/product/".concat((_o = (_m = this.props.route) === null || _m === void 0 ? void 0 : _m.params) === null || _o === void 0 ? void 0 : _o.productId), { data: data })];
                    case 12:
                        response = _p.sent();
                        this.setState({ loading: false, product: response });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.update.success.toast'));
                        return [2, true];
                    case 13:
                        e_11 = _p.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_11, (0, trans_1.trans)(e_11.message.replace(/\s/g, '')));
                        return [2, false];
                    case 14: return [2];
                }
            });
        });
    };
    ProductUpdateScreen.prototype.renderRequiredErrors = function () {
        var _a;
        return React.createElement("div", { className: 'color--error-6 border-2 border-color--error-6 background-color--error-2 p-4 mb-6' },
            React.createElement("p", { className: 'fw-700' }, "Az al\u00E1bbi k\u00F6telez\u0151 mez\u0151k m\u00E9g hi\u00E1nyoznak:"),
            React.createElement("ul", { className: 'm-0' }, (_a = this.state.requiredErrors) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
                return React.createElement("li", { key: index }, (0, trans_1.trans)("product.update.required.error.".concat(item)));
            })));
    };
    ProductUpdateScreen.prototype.changeLanguage = function (lang) {
        this.setState({ editorLanguage: lang });
    };
    ProductUpdateScreen.prototype.submit = function (value) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var data, e_12;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        data = {
                            name: value === null || value === void 0 ? void 0 : value.name,
                            subtitle: value === null || value === void 0 ? void 0 : value.subtitle,
                            description: value === null || value === void 0 ? void 0 : value.description
                        };
                        return [4, this.repository.put("/admin/product/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.productId), { data: data })];
                    case 1:
                        _c.sent();
                        return [4, this.fetchProduct()];
                    case 2: return [2, _c.sent()];
                    case 3:
                        e_12 = _c.sent();
                        this.setState({ error: e_12 });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ProductUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.ProductUpdateScreen = ProductUpdateScreen;
