"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateFilterComponent = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var operators_1 = require("rxjs/operators");
var b2b_order_list_screen_1 = require("../../../screens/b2b-order/b2b-order-list.screen");
var StateFilterComponent = (function (_super) {
    __extends(StateFilterComponent, _super);
    function StateFilterComponent() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                status: new field_1.Field({
                    name: 'status',
                    label: (0, trans_1.trans)('b2b-order.filter.form.label.status'),
                    value: ((_b = (_a = _this.props.control) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.status) || null,
                    options: Object.keys(b2b_order_list_screen_1.b2bOrderStatus).map(function (item) { return ({
                        name: (0, trans_1.trans)("b2b-order.status.".concat(b2b_order_list_screen_1.b2bOrderStatus[item].status)),
                        value: b2b_order_list_screen_1.b2bOrderStatus[item].status,
                        key: b2b_order_list_screen_1.b2bOrderStatus[item].status,
                    }); }),
                }),
            }
        });
        return _this;
    }
    StateFilterComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.form.fields.status.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (value) { return _this.handleChange(value); });
    };
    StateFilterComponent.prototype.render = function () {
        return React.createElement(form_control_1.FormControl, { field: this.form.fields.status, type: select_input_form_control_type_1.SelectInputFormControlType },
            React.createElement(select_input_1.SelectInput, { searchable: false, unclearable: false, multiple: false, tabIndex: 2, className: "select-input-basic pl-0 ".concat(this.props.className) }));
    };
    StateFilterComponent.prototype.handleChange = function (value) {
        this.props.control.set('status', value);
        this.props.control.page(0);
    };
    return StateFilterComponent;
}(abstract_component_1.AbstractComponent));
exports.StateFilterComponent = StateFilterComponent;
