"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicDataForm = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var extended_required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/extended-required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var form_1 = require("../../../../../components/form/form");
var warehouseTypes = [
    { key: 'tax', name: 'Adóraktár', value: 'tax' },
    { key: 'retail', name: 'Kisker', value: 'retail' },
    { key: 'wholesale', name: 'Nagyker', value: 'wholesale' }
];
var BasicDataForm = (function (_super) {
    __extends(BasicDataForm, _super);
    function BasicDataForm() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.title) || '',
                    name: 'title',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('warehouse.basic-data.form.field.title.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                identifier: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.identifier) || '',
                    name: 'identifier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('warehouse.basic-data.form.field.identifier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                licenceNumber: new field_1.Field({
                    placeholder: '',
                    value: ((_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.licenceNumber) || '',
                    name: 'licenceNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('warehouse.basic-data.form.field.licenceNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: ((_d = _this.props.values) === null || _d === void 0 ? void 0 : _d.description) || '',
                    name: 'description',
                    label: React.createElement("div", null, (0, trans_1.trans)('warehouse.basic-data.form.field.description.label')),
                    validators: []
                }),
                type: new field_1.Field({
                    name: 'type',
                    id: 'type',
                    value: ((_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.type) || null,
                    options: warehouseTypes,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('warehouse.basic-data.form.field.type.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                depot: new field_1.Field({
                    name: 'depot',
                    id: 'depot',
                    value: ((_f = _this.props.values) === null || _f === void 0 ? void 0 : _f.depot) || null,
                    optionsEndpoint: '/b2b/depot',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: "".concat(o.identifier, " - ").concat(o.title), value: o._id, key: o._id }); }); },
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('warehouse.basic-data.form.field.depot.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        return _this;
    }
    BasicDataForm.prototype.componentDidMount = function () {
        var _a, _b, _c, _d;
        this.form.updateFields(__assign(__assign({}, this.props.values), { paymentDueDate: (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.billingData) === null || _b === void 0 ? void 0 : _b.paymentDueDate, billingType: (_d = (_c = this.props.values) === null || _c === void 0 ? void 0 : _c.billingData) === null || _d === void 0 ? void 0 : _d.billingType }));
    };
    BasicDataForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.form.updateFields(__assign(__assign({}, this.props.values), { paymentDueDate: (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.billingData) === null || _b === void 0 ? void 0 : _b.paymentDueDate, billingType: (_d = (_c = this.props.values) === null || _c === void 0 ? void 0 : _c.billingData) === null || _d === void 0 ? void 0 : _d.billingType }));
        }
    };
    BasicDataForm.prototype.render = function () {
        return React.createElement("div", null, this.renderBaseDataForm());
    };
    BasicDataForm.prototype.renderBaseDataForm = function () {
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('warehouse.update.subtitle.basic-data.company-data'))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.identifier, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.licenceNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: 'text-input-basic' }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.type, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, readOnly: this.props.readOnly, className: 'select-input-basic' }))),
                    React.createElement("div", { className: "col-md-24 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { readOnly: this.props.readOnly, className: 'textarea-input-basic' }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.depot, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, readOnly: this.props.readOnly, className: 'select-input-basic' }))))));
    };
    BasicDataForm.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var formErrors, hasError, companyData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _b.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        formErrors = _b.sent();
                        hasError = false;
                        (0, lodash_1.map)(__assign({}, formErrors), function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        companyData = this.form.toJSON();
                        return [2, __assign({}, companyData)];
                }
            });
        });
    };
    return BasicDataForm;
}(abstract_component_1.AbstractComponent));
exports.BasicDataForm = BasicDataForm;
