"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeOptionUpdateComponent = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var attribute_option_icons_1 = require("./attribute-option-icons");
var AttributeOptionUpdateComponent = (function (_super) {
    __extends(AttributeOptionUpdateComponent, _super);
    function AttributeOptionUpdateComponent() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: ((_b = (_a = _this.props.option) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.hu) || '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: ((_d = (_c = _this.props.option) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.en) || '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                icon: new field_1.Field({
                    placeholder: '',
                    value: (_e = _this.props.option) === null || _e === void 0 ? void 0 : _e.icon,
                    name: 'icon',
                    options: attribute_option_icons_1.attributeOptionIcons,
                    label: ((0, trans_1.trans)('attribute-option.form.icon')),
                    validators: []
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: ((_g = (_f = _this.props.option) === null || _f === void 0 ? void 0 : _f.description) === null || _g === void 0 ? void 0 : _g.hu) || '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('attribute-option.form.description_hu'),
                    validators: []
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: ((_j = (_h = _this.props.option) === null || _h === void 0 ? void 0 : _h.description) === null || _j === void 0 ? void 0 : _j.en) || '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('attribute-option.form.description_en'),
                    validators: []
                })
            }
        });
        _this.state = {
            option: _this.props.option || null,
            error: null
        };
        return _this;
    }
    AttributeOptionUpdateComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.handleSubmit(); });
    };
    AttributeOptionUpdateComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        return React.createElement("div", { className: 'AttributeUpdateScreen display-flex flex-column align-items-center w-100' },
            React.createElement("div", { className: 'w-100 display-flex flex-row mb-6' },
                React.createElement("h2", { onClick: function () { return router_provider_1.RouterProvider.goTo('/attributes'); }, className: 'color--secondary-dark-3 cursor-pointer' },
                    (0, trans_1.trans)('attribute.list.title'),
                    " "),
                React.createElement("h2", { className: 'color--secondary-dark-3 mx-2' }, "/"),
                React.createElement("h2", { className: 'color--secondary-dark-3 cursor-pointer', onClick: function () { return _this.handleCancel(); } }, (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.attribute) === null || _b === void 0 ? void 0 : _b.title) === null || _c === void 0 ? void 0 : _c.hu),
                React.createElement("h2", { className: 'color--secondary-dark-3 mx-2' }, "/"),
                React.createElement("h2", null, (_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.option) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 :
                    _f.hu,
                    " ",
                    (0, trans_1.trans)('attribute-option.update.screen.title'))),
            React.createElement("div", { className: "mb-4" }, this.renderAttributeForm()));
    };
    AttributeOptionUpdateComponent.prototype.renderAttributeForm = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'AttributeUpdateForm' },
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.icon, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { searchable: true, renderOption: function (o) { return React.createElement("div", { className: 'p-2' },
                            React.createElement("img", { className: 'invert-image-filter', src: "/assets/drink-attributes/".concat(o.key, ".svg"), alt: "" }),
                            React.createElement("span", { className: 'mx-2' }, o.name)); }, renderSelectedOptions: function (o) { return React.createElement("div", { className: 'p-2' },
                            React.createElement("img", { className: 'invert-image-filter', src: "/assets/drink-attributes/".concat(o.key, ".svg"), alt: "" }),
                            React.createElement("span", { className: 'mx-2' }, o.name)); }, className: 'select-input-basic ' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic', rows: 4 })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic', rows: 4 }))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.handleCancel(); } }),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('attribute.update.button.title'), onClick: function () { return _this.form.submit(); } })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message })));
    };
    AttributeOptionUpdateComponent.prototype.handleCancel = function () {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };
    AttributeOptionUpdateComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, parsedData;
            return __generator(this, function (_a) {
                data = this.form.toJSON();
                parsedData = __assign(__assign(__assign({}, this.props.option), data), { value: { hu: data === null || data === void 0 ? void 0 : data.title_hu, en: data.title_en }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en } });
                if (this.props.onSave) {
                    this.props.onSave(parsedData);
                }
                return [2];
            });
        });
    };
    return AttributeOptionUpdateComponent;
}(abstract_component_1.AbstractComponent));
exports.AttributeOptionUpdateComponent = AttributeOptionUpdateComponent;
