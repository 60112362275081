"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TotalSoldChart = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var recharts_1 = require("recharts");
var chart_loader_1 = require("./components/chart-loader");
var time_selector_for_charts_1 = require("./components/time-selector-for-charts");
var TotalSoldChart = (function (_super) {
    __extends(TotalSoldChart, _super);
    function TotalSoldChart() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            from: moment().day(1).startOf('day').toDate(),
            to: moment().toDate(),
            data: [],
            loading: true,
            isLastYear: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                option: new field_1.Field({
                    placeholder: '',
                    value: 'ThisWeek',
                    name: 'option',
                    label: (0, trans_1.trans)('dashboard.charts.time-selector-label'),
                    options: [
                        { key: 0, name: 'Ez a hét', value: 'ThisWeek' },
                        { key: 1, name: 'Múlt hét', value: 'LastWeek' },
                        { key: 2, name: 'Múlt hónap', value: 'LastMonth' },
                        { key: 3, name: 'Múlt év', value: 'LastYear' },
                    ]
                }),
            }
        });
        return _this;
    }
    TotalSoldChart.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        this.form.fields.option.$value.subscribe(function (value) { return _this.handleIntervalChange(value); });
                        return [2];
                }
            });
        });
    };
    TotalSoldChart.prototype.render = function () {
        return React.createElement("div", { className: 'dashboard-chart mb-8', style: { width: '100%', height: 390 } },
            React.createElement("h4", { className: 'mb-6' }, (0, trans_1.trans)('dashboard.charts.total-sold.title')),
            React.createElement("div", { className: 'display-flex justify-content-center mb-6' },
                React.createElement(time_selector_for_charts_1.TimeSelectorForCharts, { form: this.form })),
            React.createElement("div", { className: 'chart-wrapper' }, this.state.loading
                ? React.createElement(chart_loader_1.ChartLoader, null)
                : React.createElement(recharts_1.ResponsiveContainer, null,
                    React.createElement(recharts_1.ComposedChart, { width: 800, height: 180, data: this.state.data, margin: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        } },
                        React.createElement(recharts_1.CartesianGrid, { strokeDasharray: "3 3" }),
                        React.createElement(recharts_1.XAxis, { dataKey: "date" }),
                        React.createElement(recharts_1.YAxis, { dataKey: "priceTotalHUF", name: "priceHUF", unit: " HUF", orientation: "left", stroke: "#8884d8", width: 120 }),
                        React.createElement(recharts_1.YAxis, { yAxisId: "EUR", dataKey: "priceTotalEUR", name: "price EUR", unit: " EUR", orientation: "right", stroke: "#3484d8", width: 80 }),
                        React.createElement(recharts_1.YAxis, { yAxisId: "DB", dataKey: "amountTotal", name: "total amount", unit: " DB", orientation: "right", stroke: "#ff7300", width: 80 }),
                        React.createElement(recharts_1.Tooltip, null),
                        React.createElement(recharts_1.Legend, { iconType: 'circle', layout: 'horizontal', align: 'center', verticalAlign: 'bottom' }),
                        React.createElement(recharts_1.Area, { name: 'HUF', type: "monotone", dataKey: "priceTotalHUF", stroke: "#8884d8", fill: "#8884d8" }),
                        React.createElement(recharts_1.Area, { name: 'EUR', yAxisId: "EUR", type: "monotone", dataKey: "priceTotalEUR", stroke: "#3484d8", fill: "#3484d8" }),
                        React.createElement(recharts_1.Line, { name: 'DB', yAxisId: "DB", type: "monotone", dataKey: "amountTotal", stroke: "#ff7300" })))));
    };
    TotalSoldChart.prototype.fetchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/dashboard/total-sold?from=".concat(this.state.from, "&to=").concat(this.state.to))];
                    case 1:
                        response = _a.sent();
                        data = response === null || response === void 0 ? void 0 : response.map(function (item) {
                            return __assign(__assign({}, item), { date: moment(item.date).format('MM.DD.') });
                        });
                        if (this.state.isLastYear) {
                            data = (0, lodash_1.map)((0, lodash_1.groupBy)(data.map(function (item) {
                                return __assign(__assign({}, item), { month: moment(item.date).get('month') });
                            }), 'month'), function (group) {
                                return {
                                    date: moment(group[0].date).locale('hu').format('MMMM'),
                                    priceTotalHUF: (0, lodash_1.sumBy)(group, 'priceTotalHUF'),
                                    priceTotalEUR: (0, lodash_1.sumBy)(group, 'priceTotalEUR'),
                                    amountTotal: (0, lodash_1.sumBy)(group, 'amountTotal'),
                                };
                            });
                        }
                        this.setState({
                            data: data,
                            loading: false
                        });
                        return [2];
                }
            });
        });
    };
    TotalSoldChart.prototype.handleIntervalChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (value === 'ThisWeek') {
                            this.setState({
                                from: moment().startOf('week').toDate(),
                                to: moment().toDate(),
                                isLastYear: false,
                            });
                        }
                        if (value === 'LastWeek') {
                            this.setState({
                                from: moment().day(-6).startOf('day').toDate(),
                                to: moment().day(0).endOf('day').toDate(),
                                isLastYear: false,
                            });
                        }
                        if (value === 'LastMonth') {
                            this.setState({
                                from: moment().subtract(1, 'months').startOf('month').toDate(),
                                to: moment().subtract(1, 'months').endOf('month').toDate(),
                                isLastYear: false,
                            });
                        }
                        if (value === 'LastYear') {
                            this.setState({
                                from: moment().subtract(1, 'years').startOf('year').toDate(),
                                to: moment().subtract(1, 'years').endOf('year').toDate(),
                                isLastYear: true,
                            });
                        }
                        return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    return TotalSoldChart;
}(abstract_component_1.AbstractComponent));
exports.TotalSoldChart = TotalSoldChart;
