"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadedComponent = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var moment_1 = require("moment");
var React = require("react");
var FileUploadedComponent = (function (_super) {
    __extends(FileUploadedComponent, _super);
    function FileUploadedComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FileUploadedComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: 'FileComponent' },
            React.createElement("div", null,
                React.createElement("div", { className: 'icon-wrapper file-type' },
                    React.createElement("i", { className: this.getTypeIcon(((_a = this.props.file) === null || _a === void 0 ? void 0 : _a.group) || 'default') }))),
            React.createElement("div", { className: 'file-info-wrapper mx-2' },
                React.createElement("p", { className: 'color--neutral-9 fw-700 file-title' }, (_b = this.props.file) === null || _b === void 0 ? void 0 : _b.name),
                React.createElement("p", { className: '' },
                    "Felt\u00F6ltve: ",
                    (0, moment_1.utc)((_c = this.props.file) === null || _c === void 0 ? void 0 : _c.createdAt).format('YYYY.MM.DD hh:mm') || '. . .')),
            React.createElement("div", { className: 'control-button-wrapper' },
                React.createElement(button_1.Button, { className: 'icon-wrapper background-color--info-1', onClick: function () { var _a; return window.open((_a = _this.props.file) === null || _a === void 0 ? void 0 : _a.url); } },
                    React.createElement("i", { className: 'far fa-download color--info-6' })),
                !this.props.disabled
                    && React.createElement(button_1.Button, { className: 'icon-wrapper background-color--error-1 ml-2', onClick: function (event) { var _a, _b; return (_b = (_a = _this.props).onDelete) === null || _b === void 0 ? void 0 : _b.call(_a, event); } },
                        React.createElement("i", { className: 'far fa-trash-alt color--error-6' }))));
    };
    FileUploadedComponent.prototype.getTypeIcon = function (group) {
        switch (group) {
            case 'application':
                return 'far fa-file-lines';
            case 'audio':
                return 'far fa-list-music';
            case 'font':
                return 'far fa-font-case';
            case 'image':
                return 'far fa-image';
            case 'text':
                return 'far fa-align-left';
            case 'video':
                return 'far fa-clapperboard-play';
            case 'model':
                return 'fas fa-cube';
            default:
                return 'far fa-file-lines';
        }
    };
    return FileUploadedComponent;
}(abstract_component_1.AbstractComponent));
exports.FileUploadedComponent = FileUploadedComponent;
