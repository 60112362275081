"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'disposal.list.main.title': 'Seletezések',
    'disposal.list.table.header.legalCode': 'Jogcímkód',
    'disposal.list.create.button': 'Új selejtezés',
    'disposal.list.table.header.identifier': 'azonosító',
    'disposal.list.table.header.reason': 'indok',
    'disposal.list.table.header.amount': 'mennyiség',
    'disposal.list.table.header.creator': 'létrehozó',
    'disposal.list.table.header.createdAt': 'létrehozva',
    'stock-disposal.create.title': 'Selejtezés',
    'stock-disposal.create.form.reason.label': 'Indoklás',
    'stock-disposal.create.form.warehouse.label': 'Raktár',
    'stock-disposal.create.form.comment.label': 'Megjegyzés',
    'stock-disposal.create.product-list.title': 'Termékek',
    'stock-disposal.create.product-list.add.button': 'Új termék hozzáadása',
    'stock-disposal.create.table.header.product': 'Termék',
    'stock-disposal.create.table.header.storage': 'tárhely',
    'stock-disposal.create.table.header.lot': 'lot/nébih',
    'stock-disposal.create.table.header.amount': 'db',
    'stock-disposal.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'stock-disposal.form.missing.required': 'Kötelező adatak hiányoznak!',
    'stock-disposal.form.missing.NoAvailableStockAmount': 'Nincs elérhető mennyiség',
    'stock-disposal.form.missing.Unauthorized': 'Nincs jogosultága a művelet végrehajtásához',
    'stock-disposal.form.missing.MissingReason': 'Hiányzó indoklás',
    'stock-disposal.form.missing.MissingPlan': 'Termék megadása kötelező',
    'stock-disposal.form.missing.MissingTypeOnPlanItem': 'Hiányzó termékek típus',
    'stock-disposal.form.missing.StockMustBeAvailable': 'Termék típusnak elérhetőnek kell lennie',
    'stock-disposal.form.missing.MissingProductOnPlanItem': 'Hiányzó termékek',
    'stock-disposal.form.missing.MissingLotOnPlanItem': 'Hiányzó LOT/NÉBIH',
    'stock-disposal.form.missing.MissingStorageOnPlanItem': 'Hiányzó tárhely',
    'stock-disposal.form.missing.MissingAmountOnPlanItem': 'Hiányzó mennyiség',
    'stock-disposal.form.missing.UnknownStorage': 'Ismeretlen tárhely',
    'stock-disposal.form.missing.UnknownProductToDispose': 'Ismeretlen termék',
    'stock-disposal.form.missing.UnableToGetStockProductFromItem': 'Termék nem elérhető',
    'stock-disposal.create.success.toast': 'A selejtezés sikersen megtörtént',
    'stock-disposal.add-product.modal.title': 'Termék választása',
    'stock-disposal.create.delete.product.success.toast': 'A terméket sikeresen eltávolítottuk',
    'stock-disposal.show.title': 'Selejtezés',
    'stock-disposal.view.data.reason': 'Indok',
    'stock-disposal.view.data.legalCode': 'Jogcímkód',
    'stock-disposal.view.data.comment': 'Megjegyzés',
    'stock-disposal.view.data.products': 'Termékek',
    'stock-disposal.view.table.header.product': 'termék',
    'stock-disposal.view.table.header.storage': 'tárhely',
    'stock-disposal.view.table.header.lot': 'lot/nébih',
    'stock-disposal.view.table.header.amount': 'Mennyiség',
    'stock-disposal.view.control.status.title': 'STÁTUSZ',
    'stock-disposal.view.control.status.disposed': 'SELEJTEZVE'
};
