"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPriceTableTabs = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var handle_toasts_1 = require("../../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../../components/modal/modal");
var supplier_partner_modifier_table_1 = require("../../../b2b-channel-pricing/components/supplier-partner-modifier-table");
var customer_partner_modifier_table_1 = require("../customer-partner-modifier-table");
var edit_modifier_modal_1 = require("../edit-modifier.modal");
var edit_price_modal_1 = require("../edit-price.modal");
var price_table_1 = require("../price-table");
var supplier_modifier_for_customer_partner_1 = require("./supplier-modifier-for-customer-partner");
var supplier_price_for_customer_partner_1 = require("./supplier-price-for-customer-partner");
var Tab;
(function (Tab) {
    Tab["price"] = "price";
    Tab["modifier"] = "modifier";
    Tab["supplierPrice"] = "supplier-price";
    Tab["supplierModifier"] = "supplier-modifier";
})(Tab || (Tab = {}));
var CustomerPriceTableTabs = (function (_super) {
    __extends(CustomerPriceTableTabs, _super);
    function CustomerPriceTableTabs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            activeTab: Tab.price
        };
        _this.repository = repository_1.Repository.use('default');
        return _this;
    }
    CustomerPriceTableTabs.prototype.render = function () {
        return React.createElement(React.Fragment, null,
            this.renderControllers(),
            React.createElement("div", { className: 'row flex-column border-1 py-2' }, this.renderTabs()),
            this.renderModals());
    };
    CustomerPriceTableTabs.prototype.renderControllers = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === Tab.price ? 'active' : 'inactive'), title: 'PARTNER ÁR', onClick: function () { return _this.setState({ activeTab: Tab.price }); } }),
            React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === Tab.modifier ? 'active' : 'inactive'), title: 'AKCIÓK', onClick: function () { return _this.setState({ activeTab: Tab.modifier }); } }),
            React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === Tab.supplierPrice ? 'active' : 'inactive'), title: 'BESZÁLLÍTÓI ÁR', onClick: function () { return _this.setState({ activeTab: Tab.supplierPrice }); } }),
            React.createElement(button_1.Button, { className: "button-neutral-normal button-size-normal tab-button ".concat(this.state.activeTab === Tab.supplierModifier ? 'active' : 'inactive'), title: 'BESZÁLLÍTÓI AKCIÓK', onClick: function () { return _this.setState({ activeTab: Tab.supplierModifier }); } }));
    };
    CustomerPriceTableTabs.prototype.renderTabs = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var product = this.props.product;
        switch (this.state.activeTab) {
            case 'price':
                return React.createElement(price_table_1.PriceTable, { product: product, items: (_b = (_a = product === null || product === void 0 ? void 0 : product.customerPartnerPrice) === null || _a === void 0 ? void 0 : _a.priceData) === null || _b === void 0 ? void 0 : _b.items, openEdit: function (item) { return _this.$priceEditingModal.open(item); }, deletePrice: function (supplierPriceId, priceItemId) { return _this.deletePrice(supplierPriceId, priceItemId); } });
            case 'modifier':
                return React.createElement(customer_partner_modifier_table_1.CustomerPartnerModifierTable, { onRefreshRequest: this.props.onRefreshRequest, product: product, items: (_d = (_c = product === null || product === void 0 ? void 0 : product.customerPartnerPriceModifier) === null || _c === void 0 ? void 0 : _c.valueData) === null || _d === void 0 ? void 0 : _d.items, openEdit: function (item) { return _this.$modifierEditingModal.open(item); }, url: '/b2b/customer-partner-pricing/modifier/cancel-price', deleteModifier: function (channelPriceModifierId, modifierItemId) { return _this.deleteModifier(channelPriceModifierId, modifierItemId); } });
            case 'supplier-price':
                return React.createElement(price_table_1.PriceTable, { product: product, items: (_f = (_e = product === null || product === void 0 ? void 0 : product.supplierPriceForCustomerPartner) === null || _e === void 0 ? void 0 : _e.priceData) === null || _f === void 0 ? void 0 : _f.items, openEdit: function (item) { return _this.$supplierPriceEditingModal.open(item); }, deletePrice: function (supplierPriceId, priceItemId) { return _this.deleteSupplierPrice(supplierPriceId, priceItemId); } });
            case 'supplier-modifier':
                return React.createElement(supplier_partner_modifier_table_1.SupplierPartnerModifierTable, { modifierId: (_g = product.supplierModifierForCustomerPartner) === null || _g === void 0 ? void 0 : _g._id, onRefreshRequest: this.props.onRefreshRequest, product: product, items: (_j = (_h = product === null || product === void 0 ? void 0 : product.supplierModifierForCustomerPartner) === null || _h === void 0 ? void 0 : _h.valueData) === null || _j === void 0 ? void 0 : _j.items, openEdit: function (item) { return _this.$supplierModifierEditingModal.open(item); }, deleteModifier: function (priceModifierId, modifierItemId) { return _this.deleteSupplierModifier(priceModifierId, modifierItemId); }, url: '/b2b/customer-partner-pricing/supplier-modifier/cancel-price' });
        }
    };
    CustomerPriceTableTabs.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$priceEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(edit_price_modal_1.EditPriceModal, { onClose: function () { return _this.$priceEditingModal.close(); }, product: _this.props.product, price: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); } }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$modifierEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(edit_modifier_modal_1.EditModifierModal, { onClose: function () { return _this.$modifierEditingModal.close(); }, product: _this.props.product, modifier: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); } }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$supplierPriceEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(supplier_price_for_customer_partner_1.SupplierPriceForCustomerPartner, { onClose: function () { return _this.$supplierPriceEditingModal.close(); }, product: _this.props.product, price: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); }, supplierPartner: _this.props.product.supplier, customerPartner: (_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.customerPartner) === null || _b === void 0 ? void 0 : _b._id });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$supplierModifierEditingModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-supplier-pricing.pricing.edit.modal.title')); }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(supplier_modifier_for_customer_partner_1.SupplierModifierForCustomerPartner, { onClose: function () { return _this.$supplierModifierEditingModal.close(); }, product: _this.props.product, modifier: ctx.state.args, onSuccess: function () { var _a, _b; return (_b = (_a = _this.props).onRefreshRequest) === null || _b === void 0 ? void 0 : _b.call(_a); }, supplierPartner: _this.props.product.supplier, customerPartner: (_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.customerPartner) === null || _b === void 0 ? void 0 : _b._id });
                } }));
    };
    CustomerPriceTableTabs.prototype.deletePrice = function (priceDocumentId, priceItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/customer-partner-pricing/price/".concat(priceDocumentId, "/").concat(priceItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. Az ár adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CustomerPriceTableTabs.prototype.deleteModifier = function (priceModifierDocumentId, modifierItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/customer-partner-pricing/modifier/".concat(priceModifierDocumentId, "/").concat(modifierItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. A módosító adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CustomerPriceTableTabs.prototype.deleteSupplierPrice = function (supplierPriceId, priceItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/supplier-pricing/price/".concat(supplierPriceId, "/").concat(priceItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. Az ár adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_3 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CustomerPriceTableTabs.prototype.deleteSupplierModifier = function (priceModifierDocumentId, modifierItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/supplier-pricing/modifier/cancel-price/".concat(priceModifierDocumentId, "/").concat(modifierItemId))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. A módosító adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 3];
                    case 2:
                        e_4 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return CustomerPriceTableTabs;
}(abstract_component_1.AbstractComponent));
exports.CustomerPriceTableTabs = CustomerPriceTableTabs;
