"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var user_permission_1 = require("../../../common/libs/user-permission");
var form_1 = require("../../../components/form/form");
var email_validator_1 = require("../../../components/form/validators/email.validator");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var modal_1 = require("../../../components/modal/modal");
require("./user-update.screen.scss");
var optional_component_1 = require("../../../components/optional-component");
var UserUpdateScreen = (function (_super) {
    __extends(UserUpdateScreen, _super);
    function UserUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                firstName: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'firstName',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('user.form.firstName'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                lastName: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'lastName',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('user.form.lastName'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                identifier: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'identifier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('user.form.email'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required')), new email_validator_1.EmailValidator((0, trans_1.trans)('form.validation.invalid-email'))]
                }),
                phoneNumber: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'phoneNumber',
                    label: 'Telefonszám',
                    validators: []
                }),
                monthlyTarget: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'monthlyTarget',
                    label: 'Havi tervszám',
                    validators: []
                }),
                role: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'role',
                    optionsEndpoint: '/admin/user/roles',
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.map(function (o) { return ({ name: (0, trans_1.trans)("user.form.role.".concat(o.key)), value: o.key, key: o.key }); })) === null || _a === void 0 ? void 0 : _a.sort(function (i, j) { return i.name.localeCompare(j.name, undefined, { numeric: true, sensitivity: 'base' }); });
                    },
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('user.form.role'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                ownership: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'ownership',
                    optionsEndpoint: '/admin/manufacturer?limit=1000&_limit=1000',
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) { return response.items.map(function (o) { return ({ name: o.name.hu, value: o._id, key: o._id }); }); },
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('user.form.manufacturer'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            user: null,
            error: null
        };
        return _this;
    }
    UserUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.fields.role.$value.subscribe(function (role) {
            _this.setState({ monthlyTargetVisible: role === user_permission_1.Role.sales });
        });
        this.form.$submitSuccess.subscribe(function () { return _this.updateUser(); });
        this.fetchUser();
    };
    UserUpdateScreen.prototype.fetchUser = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_q) {
                switch (_q.label) {
                    case 0:
                        _q.trys.push([0, 3, , 4]);
                        return [4, this.repository.get("/admin/user/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.userId, "?noCache=true"))];
                    case 1:
                        response = _q.sent();
                        return [4, this.setState({ user: response })];
                    case 2:
                        _q.sent();
                        this.form.fields.firstName.setValue((_d = (_c = this.state.user) === null || _c === void 0 ? void 0 : _c.publicMeta) === null || _d === void 0 ? void 0 : _d.firstName);
                        this.form.fields.lastName.setValue((_f = (_e = this.state.user) === null || _e === void 0 ? void 0 : _e.publicMeta) === null || _f === void 0 ? void 0 : _f.lastName);
                        this.form.fields.identifier.setValue(this.state.user.identifier);
                        this.form.fields.phoneNumber.setValue((_h = (_g = this.state.user) === null || _g === void 0 ? void 0 : _g.publicMeta) === null || _h === void 0 ? void 0 : _h.phoneNumber);
                        this.form.fields.ownership.setValue((_k = (_j = this.state.user) === null || _j === void 0 ? void 0 : _j.publicMeta) === null || _k === void 0 ? void 0 : _k.ownership);
                        this.form.fields.role.setValue((_m = (_l = this.state.user) === null || _l === void 0 ? void 0 : _l.publicMeta) === null || _m === void 0 ? void 0 : _m.role);
                        this.form.fields.monthlyTarget.setValue((_p = (_o = this.state.user) === null || _o === void 0 ? void 0 : _o.publicMeta) === null || _p === void 0 ? void 0 : _p.monthlyTarget);
                        return [3, 4];
                    case 3:
                        e_1 = _q.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'UserUpdateScreen display-flex flex-column align-items-center w-100' },
            React.createElement("div", { className: "mb-4" }, this.renderUserForm()),
            React.createElement("div", { className: 'mb-4' },
                this.renderDeactivateBox(),
                this.renderActivateBox()),
            React.createElement("div", { className: 'mb-4' }, this.renderInvited()));
    };
    UserUpdateScreen.prototype.renderUserForm = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'UserUpdateForm' },
            React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('user.update.screen.title')),
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.lastName, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.firstName, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.identifier, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.phoneNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.role, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic' })),
                React.createElement(optional_component_1.OptionalComponent, { condition: this.state.monthlyTargetVisible, ifTrue: React.createElement(form_control_1.FormControl, { field: this.form.fields.monthlyTarget, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })) }),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.ownership, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', searchable: true }))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelUpdate(); } }),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('user.update.button.title'), onClick: function () { return _this.form.submit(); } })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message })));
    };
    UserUpdateScreen.prototype.cancelUpdate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/user');
                        return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.updateUser = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isValid, data, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 5]);
                        return [4, this.form.validate()];
                    case 1:
                        isValid = _b.sent();
                        if (!isValid) {
                            return [2];
                        }
                        data = this.form.toJSON();
                        return [4, this.repository.put("/admin/user/".concat((_a = this.state.user) === null || _a === void 0 ? void 0 : _a._id), { data: data })];
                    case 2:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('user.update.success.toast'));
                        router_provider_1.RouterProvider.goTo('/user');
                        return [3, 5];
                    case 3:
                        e_2 = _b.sent();
                        return [4, this.setState({ error: e_2 })];
                    case 4:
                        _b.sent();
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.renderDeactivateBox = function () {
        var _a;
        if (!this.state.user || !((_a = this.state.user.publicMeta) === null || _a === void 0 ? void 0 : _a.active)) {
            return;
        }
        var message = React.createElement("div", { className: 'DeactivateBox display-flex' },
            React.createElement("div", { className: 'flex-fill' },
                React.createElement("h5", null, (0, trans_1.trans)('user.deactivation.box.title')),
                React.createElement("span", { className: 'text-wrap' }, (0, trans_1.trans)('user.deactivation.box.description'))),
            React.createElement("div", null, this.renderDeactivation()));
        return React.createElement(message_box_1.MessageBox, { type: 'success', message: message });
    };
    UserUpdateScreen.prototype.deactivateUser = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/user/".concat((_a = this.state.user) === null || _a === void 0 ? void 0 : _a._id, "/deactivate"))];
                    case 1:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('user.deactivation.success'));
                        this.fetchUser();
                        return [3, 3];
                    case 2:
                        e_3 = _b.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.renderActivateBox = function () {
        var _a;
        if (!this.state.user || !!((_a = this.state.user.publicMeta) === null || _a === void 0 ? void 0 : _a.active)) {
            return;
        }
        var message = React.createElement("div", { className: 'ActivateBox display-flex' },
            React.createElement("div", { className: 'flex-fill' },
                React.createElement("h5", null, (0, trans_1.trans)('user.activation.box.title')),
                React.createElement("span", { className: 'text-wrap' }, (0, trans_1.trans)('user.activation.box.description'))),
            React.createElement("div", null, this.renderActivation()));
        return React.createElement(message_box_1.MessageBox, { type: 'error', message: (message) });
    };
    UserUpdateScreen.prototype.activateUser = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/user/".concat((_a = this.state.user) === null || _a === void 0 ? void 0 : _a._id, "/activate"))];
                    case 1:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('user.activation.success'));
                        this.fetchUser();
                        this.activationModalRef.close();
                        return [3, 3];
                    case 2:
                        e_4 = _b.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.renderInvited = function () {
        var _this = this;
        var message = React.createElement("div", { className: 'InvitedBox display-flex' },
            React.createElement("div", { className: 'flex-fill' },
                React.createElement("h5", null, (0, trans_1.trans)('user.invitation.box.title')),
                React.createElement("span", { className: 'text-wrap' }, (0, trans_1.trans)('user.invitation.box.description'))),
            React.createElement("div", null,
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('user.invitation.button.resend'), onClick: function () { return _this.reInvite(); }, className: 'button-primary-outline button-size-normal-info' })));
        return React.createElement(message_box_1.MessageBox, { type: 'info', message: message });
    };
    UserUpdateScreen.prototype.reInvite = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/user/".concat((_a = this.state.user) === null || _a === void 0 ? void 0 : _a._id, "/invite"))];
                    case 1:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('user.create.invited.toast'));
                        this.fetchUser();
                        return [3, 3];
                    case 2:
                        e_5 = _b.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    UserUpdateScreen.prototype.renderActivation = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { ref: function (ref) { return _this.activationModalRef = ref; }, renderModalHeader: function () {
                return React.createElement("h3", null, (0, trans_1.trans)('user.activation.modal.title'));
            }, renderModalContent: function () {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' }, (0, trans_1.trans)('user.activation.modal.description')),
                    React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                        React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.activationModalRef.close(); } }),
                        React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal-success', title: (0, trans_1.trans)('user.activation.button.title'), onClick: function () { return _this.activateUser(); } })));
            }, renderTrigger: function () {
                return React.createElement(button_1.Button, { title: (0, trans_1.trans)('user.activation.button.title'), className: 'button-primary-outline button-size-normal-success' });
            } });
    };
    UserUpdateScreen.prototype.renderDeactivation = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { ref: function (ref) { return _this.deactivationModalRef = ref; }, renderModalHeader: function () {
                return React.createElement("h3", null, (0, trans_1.trans)('user.deactivation.modal.title'));
            }, renderModalContent: function () {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' }, (0, trans_1.trans)('user.deactivation.modal.description')),
                    React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                        React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.deactivationModalRef.close(); } }),
                        React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal-error', title: (0, trans_1.trans)('user.deactivation.button.title'), onClick: function () { return _this.deactivateUser(); } })));
            }, renderTrigger: function () {
                return React.createElement(button_1.Button, { title: (0, trans_1.trans)('user.deactivation.button.title'), className: 'button-primary-outline button-size-normal-error' });
            } });
    };
    return UserUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.UserUpdateScreen = UserUpdateScreen;
