"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TotalBox = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
require("./customer-partner-report.scss");
var box_loader_1 = require("../../common/components/repository-list/loader/box-loader");
var currency_parser_1 = require("../../common/libs/currency-parser");
var TotalBox = (function (_super) {
    __extends(TotalBox, _super);
    function TotalBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TotalBox.prototype.render = function () {
        return React.createElement("div", { className: 'total-box px-3 row align-items-center justify-content-between' },
            React.createElement("div", { className: 'position-center total-box-red col-5' }),
            this.renderContent());
    };
    TotalBox.prototype.renderContent = function () {
        var _a;
        if (this.props.loading) {
            return React.createElement(box_loader_1.BoxLoader, null);
        }
        var total = (0, currency_parser_1.hufFormat)((_a = this.props.data) === null || _a === void 0 ? void 0 : _a.value);
        return React.createElement("div", { className: 'col-19' },
            React.createElement("div", { className: '' },
                React.createElement("span", { className: 'text-lg' }, total)),
            React.createElement("div", { className: '' },
                React.createElement("span", { className: 'text-md' }, this.props.text)));
    };
    return TotalBox;
}(abstract_component_1.AbstractComponent));
exports.TotalBox = TotalBox;
