"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanCharts = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var recharts_1 = require("recharts");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var PlanCharts = (function (_super) {
    __extends(PlanCharts, _super);
    function PlanCharts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            data: [],
        };
        return _this;
    }
    PlanCharts.prototype.componentDidMount = function () {
        this.countData();
    };
    PlanCharts.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.data, prevProps.data)) {
            this.countData();
        }
    };
    PlanCharts.prototype.countData = function () {
        var e_1, _a;
        var _this = this;
        var _b;
        if (!((_b = this.props.data) === null || _b === void 0 ? void 0 : _b.length)) {
            return;
        }
        var map = this.props.data.map(function (d) { return (__assign(__assign({}, d), { month: moment(d.createdAt).month() })); });
        var sumPerMonth = {};
        try {
            for (var map_1 = __values(map), map_1_1 = map_1.next(); !map_1_1.done; map_1_1 = map_1.next()) {
                var cur = map_1_1.value;
                var value = cur.currentTotalValue;
                if (!value) {
                    continue;
                }
                sumPerMonth[cur.month] = !sumPerMonth[cur.month] ? value : sumPerMonth[cur.month] + value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (map_1_1 && !map_1_1.done && (_a = map_1.return)) _a.call(map_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var commitment = Array.from({ length: 12 }).map(function (_, index) { return index; });
        var data = commitment.map(function (item) {
            var _a, _b;
            var fact = sumPerMonth[item] || null;
            var monthlyCommitment = (_b = (_a = _this.props.targets) === null || _a === void 0 ? void 0 : _a[item + 1]) !== null && _b !== void 0 ? _b : 0;
            return {
                fact: fact,
                name: moment(item + 1, 'MM').locale('hu').format('MMMM'),
                commitment: monthlyCommitment,
                y: Math.max(monthlyCommitment, fact) * 1.5
            };
        });
        this.setState({ data: data });
    };
    PlanCharts.prototype.customLabel = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height, value = props.value;
        var v = typeof value === 'string'
            ? parseInt(value, 10)
            : value;
        var format = (0, currency_parser_1.moneyFormat)(v);
        return React.createElement("g", null,
            React.createElement("text", { x: x + width / 2, y: y, dy: -4, textAnchor: 'middle', className: 'fw-600', style: { fontSize: 10 } }, format));
    };
    PlanCharts.prototype.renderCustomTooltip = function (data) {
        var _a, _b, _c, _d, _e, _f;
        if (data.active && ((_a = data.payload) === null || _a === void 0 ? void 0 : _a.length)) {
            return (React.createElement("div", { className: "custom-tooltip" },
                React.createElement("p", { className: "label" }, "V\u00E1llal\u00E1s : ".concat((0, currency_parser_1.moneyFormat)((_c = (_b = data.payload) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value, data.unit))),
                ((_d = data.payload) === null || _d === void 0 ? void 0 : _d[1])
                    ? React.createElement("p", { className: "label" }, "T\u00E9ny : ".concat((0, currency_parser_1.moneyFormat)((_f = (_e = data.payload) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.value, data.unit)))
                    : React.createElement("p", { className: "label" }, 'Tény : 0 Ft')));
        }
        return null;
    };
    PlanCharts.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'chart-wrapper', style: { height: 300, padding: 8 } },
            React.createElement(recharts_1.ResponsiveContainer, null,
                React.createElement(recharts_1.BarChart, { width: 800, height: 260, data: this.state.data, barGap: 2 },
                    React.createElement(recharts_1.CartesianGrid, null),
                    React.createElement(recharts_1.Tooltip, { content: function (props) { return _this.renderCustomTooltip(props); } }),
                    React.createElement(recharts_1.Legend, { dy: 24, verticalAlign: "bottom", height: 36, iconType: 'circle', formatter: function (value, entry) {
                            return React.createElement("span", { style: { color: '#000' } }, (0, trans_1.trans)("chart.legend.".concat(value)));
                        } }),
                    React.createElement(recharts_1.XAxis, { dataKey: 'name', angle: -45, dy: 20, dx: -5, height: 60, interval: 0, tick: { fill: '#A9A9A9' }, tickLine: false, axisLine: { stroke: '#A9A9A9' } }),
                    React.createElement(recharts_1.YAxis, { dataKey: 'y', width: 100, interval: 0, tick: { fill: '#A9A9A9' }, tickLine: false, tickFormatter: function (value) { return (0, currency_parser_1.moneyFormat)(value); }, axisLine: { stroke: '#A9A9A9' } }),
                    React.createElement(recharts_1.Bar, { dataKey: 'commitment', fill: "#EDB0AB", radius: [2, 2, 2, 2] }),
                    React.createElement(recharts_1.Bar, { dataKey: 'fact', fill: "#D6493D", radius: [2, 2, 2, 2], label: function (props) { return _this.customLabel(props); } }))));
    };
    return PlanCharts;
}(abstract_component_1.AbstractComponent));
exports.PlanCharts = PlanCharts;
