"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bOrderProductTableRow = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var cleave_text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/cleave-text-input.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var cleave_text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/cleave-text-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var lodash_1 = require("lodash");
var React = require("react");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var only_positive_number_validator_1 = require("../../../components/form/validators/only-positive-number.validator");
var modal_1 = require("../../../components/modal/modal");
var b2b_order_list_screen_1 = require("../b2b-order-list.screen");
var popup_1 = require("./popup");
var popup_modal_1 = require("./popup.modal");
var product_locks_modal_1 = require("./product-locks.modal");
var getBestPrice = function (bestPrice) {
    if (!isNaN(bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPrice)) {
        return bestPrice.bestPrice;
    }
    if (!isNaN(bestPrice)) {
        return bestPrice;
    }
    return 0;
};
var B2bOrderProductTableRow = (function (_super) {
    __extends(B2bOrderProductTableRow, _super);
    function B2bOrderProductTableRow() {
        var _this = this;
        var _a, _b, _c, _d;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.$download = new rxjs_1.Subject();
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            product: _this.props.product || {},
            order: _this.props.order || {},
            priceArtifact: {},
            supplierPriceArtifact: {},
            locks: [],
            locksSum: 0,
            stillAvailable: {},
            values: {},
            units: ((_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.units) || null,
            bestPrice: {},
            bestPriceLoading: false,
            hasAvailableStock: true,
            availableAmount: 0,
            availabilityLoading: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                id: new field_1.Field({
                    name: 'id',
                    placeholder: '',
                    label: '',
                    value: (_c = (_b = _this.state.product) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c._id,
                }),
                bestPrice: new field_1.Field({
                    name: 'bestPrice',
                    placeholder: '',
                    label: '',
                    value: ((_d = _this.state.product) === null || _d === void 0 ? void 0 : _d.calculatedPrice) || '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                exactPrice: new field_1.Field({
                    name: 'exactPrice',
                    placeholder: '',
                    label: '',
                    value: _this.state.product.exactPrice || '',
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator((0, trans_1.trans)('form.validation.only.positive.nums'))]
                }),
                unit: new field_1.Field({
                    name: 'unit',
                    placeholder: '',
                    label: '',
                    value: 'pieces',
                    options: [
                        { name: 'darab', value: 'pieces', key: 0 },
                        { name: 'karton', value: 'cardboard', key: 1 },
                        { name: 'raklap', value: 'palette', key: 2 },
                    ],
                    validators: []
                }),
                plan: new field_1.Field({
                    name: 'plan',
                    placeholder: '',
                    label: '',
                    value: _this.state.product.amount || '',
                    validators: []
                }),
                locks: new field_1.Field({
                    name: 'locks',
                    placeholder: '',
                    label: '',
                    value: '',
                    validators: []
                }),
                fact: new field_1.Field({
                    name: 'fact',
                    placeholder: '',
                    label: '',
                    value: '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bOrderProductTableRow.prototype.componentDidMount = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return __awaiter(this, void 0, void 0, function () {
            var locks, locksSum, fact;
            var _this = this;
            return __generator(this, function (_w) {
                switch (_w.label) {
                    case 0:
                        if (((_b = (_a = this.props.product) === null || _a === void 0 ? void 0 : _a.priceArtifact) === null || _b === void 0 ? void 0 : _b.bestPriceType) === 'exactPrice') {
                            this.form.fields.exactPrice.setValue((_d = (_c = this.props.product) === null || _c === void 0 ? void 0 : _c.priceArtifact) === null || _d === void 0 ? void 0 : _d.bestPrice);
                        }
                        if (!isNaN(parseInt((_e = this.props.product) === null || _e === void 0 ? void 0 : _e.exactPrice, 10))) {
                            this.form.fields.exactPrice.setValue(this.props.product.exactPrice);
                        }
                        if ((_f = this.props.product) === null || _f === void 0 ? void 0 : _f.priceArtifact) {
                            this.form.fields.bestPrice.setValue(this.props.product.priceArtifact);
                        }
                        this.$subscriptions.push(this.$download.pipe((0, operators_1.debounceTime)(100)).subscribe(function () { return _this.download(); }), this.form.fields.unit.$value.subscribe(function (v) { return _this.onUnitChange(v); }), this.form.fields.plan.$value.subscribe(function (amount) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.onPlanChange(amount)];
                                    case 1:
                                        _a.sent();
                                        return [4, this.fetchBestPrice(+amount)];
                                    case 2:
                                        _a.sent();
                                        return [2];
                                }
                            });
                        }); }), this.form.fields.bestPrice.$value.subscribe(function (price) {
                            var _a, _b;
                            var amount = _this.form.fields.plan.getValue();
                            _this.props.onPlanChange(amount, getBestPrice(price) || 0, true, (_b = (_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.drs);
                        }), this.form.fields.fact.$value.pipe((0, operators_1.debounceTime)(400)).subscribe(function (fact) {
                            var locks = _this.form.fields.locks.getValue();
                            if (locks < fact) {
                                _this.form.fields.fact.setValue(locks);
                            }
                        }), this.form.fields.exactPrice.$value.subscribe(function (price) {
                            var _a;
                            var amount = _this.form.fields.plan.getValue();
                            _this.setState({
                                priceArtifact: __assign(__assign({}, _this.state.bestPrice), { exactPrice: +price, bestPriceType: price ? 'exactPrice' : _this.state.bestPrice.bestPriceType })
                            });
                            if (!price || isNaN(+price)) {
                                return;
                            }
                            _this.props.onPlanChange(amount, price, false, (_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.drs);
                        }));
                        return [4, this.getAvailability()];
                    case 1:
                        _w.sent();
                        return [4, this.fetchBestPrice((_g = this.form.fields.plan.getValue()) !== null && _g !== void 0 ? _g : 0)];
                    case 2:
                        _w.sent();
                        return [4, this.fetchSupplierPriceArtifact()];
                    case 3:
                        _w.sent();
                        return [4, this.form.fields.unit.setValue(this.state.product.unit || 'pieces')];
                    case 4:
                        _w.sent();
                        this.form.fields.exactPrice.setValue((_j = (_h = this.props.product) === null || _h === void 0 ? void 0 : _h.exactPrice) !== null && _j !== void 0 ? _j : '');
                        if (this.props.flow === 'create' || [b2b_order_list_screen_1.b2bOrderStatus.draft.status, b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status].includes((_k = this.state.order) === null || _k === void 0 ? void 0 : _k.status)) {
                            this.form.fields.plan.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        if ([b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status, b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status, b2b_order_list_screen_1.b2bOrderStatus.casePicked.status].includes((_l = this.state.order) === null || _l === void 0 ? void 0 : _l.status)) {
                            this.form.fields.locks.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            this.$download.next(null);
                            this.sumLocks();
                        }
                        if (((_m = this.state.order) === null || _m === void 0 ? void 0 : _m.status) === b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status) {
                            this.form.fields.fact.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        if ([b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status, b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status, b2b_order_list_screen_1.b2bOrderStatus.paid.status].includes((_o = this.state.order) === null || _o === void 0 ? void 0 : _o.status)) {
                            locks = (_s = (_r = (_q = (_p = this.state.order) === null || _p === void 0 ? void 0 : _p.lockArtifacts) === null || _q === void 0 ? void 0 : _q.at(-1)) === null || _r === void 0 ? void 0 : _r.items) === null || _s === void 0 ? void 0 : _s.filter(function (l) { return l.product === _this.props.product.productId; });
                            locksSum = locks === null || locks === void 0 ? void 0 : locks.reduce(function (acc, l) { return acc + l.amount; }, 0);
                            fact = ((_v = (_u = (_t = this.state.order) === null || _t === void 0 ? void 0 : _t.factArtifacts) === null || _u === void 0 ? void 0 : _u.find(function (i) { var _a, _b; return i.productId === ((_b = (_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b._id); })) === null || _v === void 0 ? void 0 : _v.amount) || 0;
                            this.form.fields.locks.setValue(locksSum);
                            this.setState({ locksSum: locksSum });
                            this.form.fields.fact.setValue(fact);
                        }
                        return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        return __awaiter(this, void 0, void 0, function () {
            var exactPrice, amount, locks, locksSum, fact;
            var _this = this;
            return __generator(this, function (_6) {
                switch (_6.label) {
                    case 0:
                        if (!(0, lodash_1.isEqual)(this.state.locks, prevState.locks)) {
                            this.sumLocks();
                        }
                        if (!(0, lodash_1.isEqual)(this.state.bestPrice, prevState.bestPrice)) {
                            exactPrice = this.form.fields.exactPrice.getValue();
                            amount = this.form.fields.plan.getValue();
                            this.props.onPlanChange(amount, exactPrice || getBestPrice(this.state.bestPrice), false, (_b = (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.drs);
                            this.sumLocks();
                        }
                        if (!(((_c = this.props.product) === null || _c === void 0 ? void 0 : _c.amount) && !(0, lodash_1.isEqual)((_d = this.props.product) === null || _d === void 0 ? void 0 : _d.amount, (_e = prevProps.product) === null || _e === void 0 ? void 0 : _e.amount))) return [3, 2];
                        this.form.fields.plan.setValue(this.props.product.amount);
                        return [4, this.onPlanChange(this.props.product.amount)];
                    case 1:
                        _6.sent();
                        _6.label = 2;
                    case 2:
                        if (((_g = (_f = this.props.product) === null || _f === void 0 ? void 0 : _f.priceArtifact) === null || _g === void 0 ? void 0 : _g.bestPriceType) && !(0, lodash_1.isEqual)((_j = (_h = this.props.product) === null || _h === void 0 ? void 0 : _h.priceArtifact) === null || _j === void 0 ? void 0 : _j.bestPriceType, (_l = (_k = prevProps.product) === null || _k === void 0 ? void 0 : _k.priceArtifact) === null || _l === void 0 ? void 0 : _l.bestPriceType)) {
                            if (((_o = (_m = this.props.product) === null || _m === void 0 ? void 0 : _m.priceArtifact) === null || _o === void 0 ? void 0 : _o.bestPriceType) === 'exactPrice') {
                                this.form.fields.exactPrice.setValue((_q = (_p = this.props.product) === null || _p === void 0 ? void 0 : _p.priceArtifact) === null || _q === void 0 ? void 0 : _q.bestPrice);
                            }
                        }
                        if (!!(0, lodash_1.isEqual)(this.props.order, prevProps.order)) return [3, 11];
                        return [4, this.form.fields.unit.setValue(this.props.product.unit || 'pieces')];
                    case 3:
                        _6.sent();
                        this.setState({
                            order: this.props.order,
                            product: this.props.product,
                            exactPrice: (_s = (_r = this.props.product) === null || _r === void 0 ? void 0 : _r.exactPrice) !== null && _s !== void 0 ? _s : '',
                            units: this.state.product.units,
                        });
                        this.form.fields.id.setValue(((_t = this.props.product) === null || _t === void 0 ? void 0 : _t.productId) || '');
                        this.form.fields.exactPrice.setValue((_v = (_u = this.props.product) === null || _u === void 0 ? void 0 : _u.exactPrice) !== null && _v !== void 0 ? _v : '');
                        return [4, this.form.fields.unit.setValue(((_w = this.props.product) === null || _w === void 0 ? void 0 : _w.unit) || 'pieces')];
                    case 4:
                        _6.sent();
                        return [4, this.form.fields.plan.setValue((_x = this.props.product) === null || _x === void 0 ? void 0 : _x.amount)];
                    case 5:
                        _6.sent();
                        return [4, this.fetchSupplierPriceArtifact()];
                    case 6:
                        _6.sent();
                        return [4, this.getAvailability()];
                    case 7:
                        _6.sent();
                        if (![b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status, b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status, b2b_order_list_screen_1.b2bOrderStatus.paid.status].includes((_y = this.props.order) === null || _y === void 0 ? void 0 : _y.status)) return [3, 10];
                        locks = (_2 = (_1 = (_0 = (_z = this.props.order) === null || _z === void 0 ? void 0 : _z.lockArtifacts) === null || _0 === void 0 ? void 0 : _0.at(-1)) === null || _1 === void 0 ? void 0 : _1.items) === null || _2 === void 0 ? void 0 : _2.filter(function (l) { return l.product === _this.props.product.productId; });
                        locksSum = locks === null || locks === void 0 ? void 0 : locks.reduce(function (acc, l) { return acc + l.amount; }, 0);
                        fact = ((_5 = (_4 = (_3 = this.props.order) === null || _3 === void 0 ? void 0 : _3.factArtifacts) === null || _4 === void 0 ? void 0 : _4.find(function (i) { var _a, _b; return i.productId === ((_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b._id); })) === null || _5 === void 0 ? void 0 : _5.amount) || 0;
                        return [4, this.form.fields.locks.setValue(locksSum)];
                    case 8:
                        _6.sent();
                        return [4, this.form.fields.fact.setValue(fact)];
                    case 9:
                        _6.sent();
                        return [3, 11];
                    case 10:
                        this.$download.next(null);
                        _6.label = 11;
                    case 11: return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.getIsProductDiscountable = function (amount) {
        var _a;
        var wineOrChampagne = ['wine', 'champagne', 'buborek', 'bor'];
        var product = (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.product;
        var isWineOrChampagne = wineOrChampagne.some(function (item) { var _a, _b; return ((_a = product === null || product === void 0 ? void 0 : product.category) === null || _a === void 0 ? void 0 : _a.includes(item)) || ((_b = product === null || product === void 0 ? void 0 : product.b2cCategorySlugs) === null || _b === void 0 ? void 0 : _b.includes(item)); });
        if (!isWineOrChampagne) {
            return true;
        }
        return amount >= 6;
    };
    B2bOrderProductTableRow.prototype.fetchBestPrice = function (amount) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return __awaiter(this, void 0, void 0, function () {
            var calculatedPrice, exactPrice, bestPriceType, cpId, createdAt, channel, product, discountApplicable, queryParams, bestPrice, exactPrice, e_1;
            return __generator(this, function (_r) {
                switch (_r.label) {
                    case 0:
                        if (this.props.readOnly) {
                            calculatedPrice = (_b = (_a = this.props.product) === null || _a === void 0 ? void 0 : _a.calculatedPrice) !== null && _b !== void 0 ? _b : 0;
                            exactPrice = (_d = (_c = this.props.product) === null || _c === void 0 ? void 0 : _c.exactPrice) !== null && _d !== void 0 ? _d : 0;
                            bestPriceType = (_g = (_f = (_e = this.props.product) === null || _e === void 0 ? void 0 : _e.priceArtifact) === null || _f === void 0 ? void 0 : _f.bestPriceType) !== null && _g !== void 0 ? _g : '';
                            if (!bestPriceType) {
                                bestPriceType = exactPrice ? 'exactPrice' : '';
                            }
                            return [2, this.setState({ bestPrice: { bestPrice: exactPrice || calculatedPrice, bestPriceType: bestPriceType } })];
                        }
                        _r.label = 1;
                    case 1:
                        _r.trys.push([1, 4, 5, 6]);
                        this.setState({ bestPriceLoading: true });
                        cpId = ((_h = this.props.customerPartner) === null || _h === void 0 ? void 0 : _h.id) || ((_l = (_k = (_j = this.props.order) === null || _j === void 0 ? void 0 : _j.extraData) === null || _k === void 0 ? void 0 : _k.customerPartner) === null || _l === void 0 ? void 0 : _l.id);
                        createdAt = ((_m = this.state.order) === null || _m === void 0 ? void 0 : _m.createdAt) || new Date();
                        channel = (_p = (_o = this.props.customerPartner) === null || _o === void 0 ? void 0 : _o.channels) === null || _p === void 0 ? void 0 : _p[0];
                        product = (_q = this.state.product.product) === null || _q === void 0 ? void 0 : _q._id;
                        discountApplicable = this.getIsProductDiscountable(amount);
                        queryParams = "customerPartner=".concat(cpId, "&product=").concat(product, "&channel=").concat(channel, "&date=").concat(createdAt, "&discountApplicable=").concat(discountApplicable);
                        return [4, this.repository.get("/b2b/pricing/best-price?".concat(queryParams))];
                    case 2:
                        bestPrice = _r.sent();
                        if (!!this.props.product.calculatedPrice) {
                            try {
                                return [2, this.handleCalculatedPrice(bestPrice)];
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }
                        return [4, this.form.fields.bestPrice.setValue(bestPrice.bestPrice)];
                    case 3:
                        _r.sent();
                        exactPrice = this.form.fields.exactPrice.getValue();
                        if (!isNaN(parseInt(exactPrice, 10))) {
                            bestPrice.bestPrice = exactPrice;
                            bestPrice.bestPriceType = 'exactPrice';
                        }
                        this.setState({ bestPrice: bestPrice, bestPriceLoading: false });
                        return [3, 6];
                    case 4:
                        e_1 = _r.sent();
                        console.log(e_1);
                        return [3, 6];
                    case 5:
                        this.setState({ bestPriceLoading: false });
                        return [7];
                    case 6: return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.handleCalculatedPrice = function (bestPrice) {
        var _a, _b, _c, _d, _e;
        var calculatedPrice = bestPrice.bestPrice;
        var bestPriceType = this.form.fields.exactPrice.getValue() ? 'exactPrice' : bestPrice.bestPriceType;
        if (typeof ((_a = this.props.product.calculatedPrice) === null || _a === void 0 ? void 0 : _a.bestPrice) === 'object' && !isNaN(+((_b = this.props.product.calculatedPrice) === null || _b === void 0 ? void 0 : _b.bestPrice.bestPrice))) {
            calculatedPrice = (_c = this.props.product.calculatedPrice) === null || _c === void 0 ? void 0 : _c.bestPrice.bestPrice;
        }
        if (!isNaN(+((_d = this.props.product.calculatedPrice) === null || _d === void 0 ? void 0 : _d.bestPrice))) {
            calculatedPrice = (_e = this.props.product.calculatedPrice) === null || _e === void 0 ? void 0 : _e.bestPrice;
        }
        if (!isNaN(+this.props.product.calculatedPrice)) {
            calculatedPrice = this.props.product.calculatedPrice;
        }
        var adjustedBestPrice = __assign(__assign({}, bestPrice), { bestPriceType: bestPriceType, bestPrice: calculatedPrice });
        this.setState({ bestPrice: adjustedBestPrice });
        return this.form.fields.bestPrice.setValue(adjustedBestPrice);
    };
    B2bOrderProductTableRow.prototype.fetchSupplierPriceArtifact = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var cpId, supplierPriceArtifact, e_2;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 2, , 3]);
                        cpId = ((_a = this.props.customerPartner) === null || _a === void 0 ? void 0 : _a.id) || ((_d = (_c = (_b = this.props.order) === null || _b === void 0 ? void 0 : _b.extraData) === null || _c === void 0 ? void 0 : _c.customerPartner) === null || _d === void 0 ? void 0 : _d.id);
                        return [4, this.repository.get("/b2b/pricing/supplier-prices?customerPartner=".concat(cpId, "&product=").concat((_e = this.state.product.product) === null || _e === void 0 ? void 0 : _e._id, "&channel=").concat((_g = (_f = this.props.customerPartner) === null || _f === void 0 ? void 0 : _f.channels) === null || _g === void 0 ? void 0 : _g[0], "&date=").concat(((_h = this.state.order) === null || _h === void 0 ? void 0 : _h.createdAt) || new Date()))];
                    case 1:
                        supplierPriceArtifact = _j.sent();
                        this.setState({ supplierPriceArtifact: supplierPriceArtifact });
                        return [3, 3];
                    case 2:
                        e_2 = _j.sent();
                        console.log(e_2);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.getAvailability = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var customerPartnerId, productId, response, availableAmount, e_3;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 2, 3, 4]);
                        this.setState({ availabilityLoading: true });
                        customerPartnerId = ((_a = this.props.customerPartner) === null || _a === void 0 ? void 0 : _a.id) || ((_d = (_c = (_b = this.props.order) === null || _b === void 0 ? void 0 : _b.extraData) === null || _c === void 0 ? void 0 : _c.customerPartner) === null || _d === void 0 ? void 0 : _d.id);
                        productId = (_e = this.state.product.product) === null || _e === void 0 ? void 0 : _e._id;
                        return [4, this.repository.get("/b2b/stock-miscellaneous/get-available-amount-for-cp/".concat(customerPartnerId, "/").concat(productId))];
                    case 1:
                        response = _f.sent();
                        availableAmount = response === null || response === void 0 ? void 0 : response.reduce(function (acc, stock) { var _a; return acc + ((_a = stock.details) === null || _a === void 0 ? void 0 : _a.totalAvailable); }, 0);
                        this.setState({ availableAmount: availableAmount, hasAvailableStock: !!response.length });
                        return [3, 4];
                    case 2:
                        e_3 = _f.sent();
                        console.log(e_3);
                        return [3, 4];
                    case 3:
                        this.setState({ availabilityLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.onPlanChange = function (value) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var unit, plan, cardboard, palette;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4, this.form.fields.unit.getValue()];
                    case 1:
                        unit = _e.sent();
                        plan = parseFloat(value);
                        if (!plan) {
                            return [2, this.setState({
                                    units: {
                                        pieces: 0,
                                        cardboard: 0,
                                        palette: 0,
                                    }
                                })];
                        }
                        cardboard = ((_b = (_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.extraAttributes) === null || _b === void 0 ? void 0 : _b.bottlePerCardboard) || 0;
                        palette = ((_d = (_c = this.state.product.product) === null || _c === void 0 ? void 0 : _c.extraAttributes) === null || _d === void 0 ? void 0 : _d.bottlePerPalette) || 0;
                        if (unit === 'pieces') {
                            return [2, this.setState({
                                    units: {
                                        pieces: plan,
                                        cardboard: !!cardboard ? (plan / cardboard) : 0,
                                        palette: !!palette ? (plan / palette) : 0,
                                    }
                                })];
                        }
                        if (unit === 'cardboard') {
                            return [2, this.setState({
                                    units: {
                                        pieces: plan * cardboard,
                                        cardboard: plan,
                                        palette: !!palette ? (plan * cardboard / palette) : 0,
                                    }
                                })];
                        }
                        if (unit === 'palette') {
                            return [2, this.setState({
                                    units: {
                                        pieces: plan * palette,
                                        cardboard: !!cardboard ? (plan * palette / cardboard) : 0,
                                        palette: plan,
                                    }
                                })];
                        }
                        return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.onUnitChange = function (unit) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.state.units) {
                            return [2];
                        }
                        return [4, this.form.fields.plan.setValue(((_a = this.state.units) === null || _a === void 0 ? void 0 : _a[unit]) || 0)];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.getListPrice = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.bestPrice) === null || _b === void 0 ? void 0 : _b.channelPriceItem) {
            return (0, currency_parser_1.moneyFormat)((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.bestPrice) === null || _d === void 0 ? void 0 : _d.channelPriceItem);
        }
        var planItem = (_g = (_f = (_e = this.props.order) === null || _e === void 0 ? void 0 : _e.plan) === null || _f === void 0 ? void 0 : _f.find) === null || _g === void 0 ? void 0 : _g.call(_f, function (plan) { var _a, _b, _c, _d, _e, _f; return ((_b = (_a = plan === null || plan === void 0 ? void 0 : plan.product) === null || _a === void 0 ? void 0 : _a._id) === null || _b === void 0 ? void 0 : _b.toString()) === ((_f = (_e = (_d = (_c = _this.state) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.product) === null || _e === void 0 ? void 0 : _e._id) === null || _f === void 0 ? void 0 : _f.toString()); });
        if ((_h = planItem === null || planItem === void 0 ? void 0 : planItem.priceArtifact) === null || _h === void 0 ? void 0 : _h.channelPriceItem) {
            return (0, currency_parser_1.moneyFormat)((_j = planItem === null || planItem === void 0 ? void 0 : planItem.priceArtifact) === null || _j === void 0 ? void 0 : _j.channelPriceItem);
        }
        return '-';
    };
    B2bOrderProductTableRow.prototype.renderMissingSupplierPriceInfo = function () {
        var _a;
        if (this.props.readOnly) {
            return null;
        }
        if (!((_a = Object.keys(this.state.supplierPriceArtifact)) === null || _a === void 0 ? void 0 : _a.length)) {
            return React.createElement("p", { style: { color: '#d23838' }, className: 'fs-10 ' }, "Ennek a term\u00E9knek m\u00E9g nincs besz\u00E1ll\u00EDt\u00F3 \u00E1ra megadva!");
        }
        return null;
    };
    B2bOrderProductTableRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15;
        var ean = ((_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = this.state.product.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = this.state.product.product) === null || _c === void 0 ? void 0 : _c.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu) || '-';
        var title = ((_f = (_e = this.state.product.product) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu) || '-';
        var vintage = ((_g = this.state.product.product) === null || _g === void 0 ? void 0 : _g.vintage) || '-';
        var capacity = ((_k = (_j = (_h = this.state.product.product) === null || _h === void 0 ? void 0 : _h.b2cAttributeSearchValues) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k[1]) || '-';
        var numberParse = function (value) { return isNaN(+value) ? 0 : value; };
        var cardboard = ((_m = (_l = this.state.product.product) === null || _l === void 0 ? void 0 : _l.extraAttributes) === null || _m === void 0 ? void 0 : _m.bottlePerCardboard) || 0;
        var palette = ((_p = (_o = this.state.product.product) === null || _o === void 0 ? void 0 : _o.extraAttributes) === null || _p === void 0 ? void 0 : _p.bottlePerPalette) || 0;
        var inputClasses = 'w-100 text-input-basic';
        var clickHandler = isNaN(parseInt(this.state.bestPrice.bestPrice, 10)) ? { onClick: function () { return _this.$popupModal.open(); } } : {};
        var isProductDiscountable = this.getIsProductDiscountable(this.form.fields.plan.getValue());
        var drs = ((_r = (_q = this.state.product) === null || _q === void 0 ? void 0 : _q.product) === null || _r === void 0 ? void 0 : _r.drs) ? '(DRS)' : '';
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'B2bOrderProductTableRow' },
            this.renderModals(),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 25 }, clickHandler),
                React.createElement("div", null,
                    !this.state.hasAvailableStock && React.createElement("p", { className: 'color--error-6 mb-2' }, "Jelenleg nincs k\u00E9szleten"),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title,
                        " ",
                        vintage,
                        " (",
                        capacity,
                        ") ",
                        drs),
                    React.createElement("p", null,
                        "Lista\u00E1r: ",
                        this.getListPrice()),
                    React.createElement("p", { className: 'typo-small' },
                        "Szabadk\u00E9szlet: ",
                        this.state.availabilityLoading
                            ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' })
                            : this.state.availableAmount),
                    React.createElement("small", null,
                        "Karton: ",
                        cardboard,
                        " / Raklap: ",
                        palette),
                    this.renderMissingSupplierPriceInfo())),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 10 }, clickHandler), this.renderBestPrice()),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 12 }, clickHandler),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.exactPrice, type: cleave_text_input_form_control_type_1.CleaveTextInputFormControlType },
                    React.createElement(cleave_text_input_1.CleaveTextInput, { readOnly: this.props.readOnly
                            || (this.props.flow !== 'create'
                                && ((_s = this.state.order) === null || _s === void 0 ? void 0 : _s.status) !== b2b_order_list_screen_1.b2bOrderStatus.draft.status
                                && !(((_t = this.state.order) === null || _t === void 0 ? void 0 : _t.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && ((_u = this.state.order) === null || _u === void 0 ? void 0 : _u.isPlanUpdateNeeded))), className: "".concat(inputClasses, "\n                            ").concat(this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status
                            && !((_x = (_w = (_v = this.state.order) === null || _v === void 0 ? void 0 : _v.extraData) === null || _w === void 0 ? void 0 : _w.partnerCreditInfo) === null || _x === void 0 ? void 0 : _x.hasCredit)
                            && 'approval-needed', "\n                        "), min: 0, iconRight: 'huf-icon' }))),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 10 }, clickHandler),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.unit, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic w-100', multiple: false, readOnly: this.props.readOnly
                            || (this.props.flow !== 'create'
                                && ((_y = this.state.order) === null || _y === void 0 ? void 0 : _y.status) !== b2b_order_list_screen_1.b2bOrderStatus.draft.status
                                && !(((_z = this.state.order) === null || _z === void 0 ? void 0 : _z.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && ((_0 = this.state.order) === null || _0 === void 0 ? void 0 : _0.isPlanUpdateNeeded))) }))),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 8 }, clickHandler),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.plan, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: isNaN(parseInt(this.state.bestPrice.bestPrice, 10)), readOnly: this.props.readOnly
                            || (this.props.flow !== 'create'
                                && ((_1 = this.state.order) === null || _1 === void 0 ? void 0 : _1.status) !== b2b_order_list_screen_1.b2bOrderStatus.draft.status
                                && !(((_2 = this.state.order) === null || _2 === void 0 ? void 0 : _2.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && ((_3 = this.state.order) === null || _3 === void 0 ? void 0 : _3.isPlanUpdateNeeded))), className: inputClasses, type: 'number', min: 0 })),
                React.createElement("div", { className: 'position-relative' },
                    React.createElement(popup_1.Popup, { popupClassName: 'amount-info-icon', show: !isProductDiscountable, iconClassName: 'far fa-exclamation-circle ml-3', content: React.createElement("p", { className: 'fs-12' }, (0, trans_1.trans)('b2b-order.product.is.not.divisible.by.6')) }))),
            React.createElement(table_body_cell_1.TableBodyCell, __assign({ width: 10 }, clickHandler),
                React.createElement("div", { className: 'w-100' },
                    React.createElement("p", { className: 'typo-small' },
                        "Darab: ", (_4 = this.state.units) === null || _4 === void 0 ? void 0 :
                        _4.pieces),
                    React.createElement("p", { className: 'typo-small' },
                        "Karton: ",
                        numberParse(parseFloat((_6 = (_5 = this.state.units) === null || _5 === void 0 ? void 0 : _5.cardboard) === null || _6 === void 0 ? void 0 : _6.toFixed(3)))),
                    React.createElement("p", { className: 'typo-small' },
                        "Raklap: ",
                        numberParse(parseFloat((_8 = (_7 = this.state.units) === null || _7 === void 0 ? void 0 : _7.palette) === null || _8 === void 0 ? void 0 : _8.toFixed(3)))))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.locks, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { readOnly: true, className: "".concat(inputClasses, " ").concat(!(this.props.readOnly
                            || (((_9 = this.state.order) === null || _9 === void 0 ? void 0 : _9.status) !== b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status
                                && ((_10 = this.state.order) === null || _10 === void 0 ? void 0 : _10.status) !== b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status
                                && ((_11 = this.state.order) === null || _11 === void 0 ? void 0 : _11.status) !== b2b_order_list_screen_1.b2bOrderStatus.casePicked.status))
                            && 'read-only'), type: 'number', min: 0, onFocus: function () {
                            var _a, _b, _c;
                            return (_this.props.readOnly
                                || (((_a = _this.state.order) === null || _a === void 0 ? void 0 : _a.status) !== b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status
                                    && ((_b = _this.state.order) === null || _b === void 0 ? void 0 : _b.status) !== b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status
                                    && ((_c = _this.state.order) === null || _c === void 0 ? void 0 : _c.status) !== b2b_order_list_screen_1.b2bOrderStatus.casePicked.status))
                                ? null
                                : _this.$showProductLocksModal.open();
                        } }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.fact, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { readOnly: this.props.readOnly
                            || ((_12 = this.state.order) === null || _12 === void 0 ? void 0 : _12.status) !== b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status, className: inputClasses, type: 'number', min: 0, max: this.state.locksSum }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 5 }, !this.props.readOnly
                && (this.props.flow === 'create'
                    || ((_13 = this.state.order) === null || _13 === void 0 ? void 0 : _13.status) === b2b_order_list_screen_1.b2bOrderStatus.draft.status
                    || (((_14 = this.state.order) === null || _14 === void 0 ? void 0 : _14.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && ((_15 = this.state.order) === null || _15 === void 0 ? void 0 : _15.isPlanUpdateNeeded)))
                && this.deleteButton(this.state.product.productId)));
    };
    B2bOrderProductTableRow.prototype.deleteButton = function (productId) {
        var _this = this;
        return React.createElement(button_1.Button, { disabled: this.props.readOnly, className: 'delete-button', onClick: function () { return _this.props.showDeleteProductModal(productId); }, iconLeft: 'fas fa-trash' });
    };
    B2bOrderProductTableRow.prototype.getData = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _f.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _f.sent();
                        console.log({ errors: errors });
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        return [2, __assign(__assign({}, this.form.toJSON()), { priceArtifact: this.state.priceArtifact, supplierPriceArtifact: this.state.supplierPriceArtifact, product: this.state.product.product._id, listPrice: (_b = this.state.bestPrice) === null || _b === void 0 ? void 0 : _b.channelPriceItem, locks: (_c = this.getFifoToRender()) === null || _c === void 0 ? void 0 : _c.filter(function (f) { return !!f.amount; }), plan: ((_d = this.state.units) === null || _d === void 0 ? void 0 : _d.pieces) || ((_e = this.props.product) === null || _e === void 0 ? void 0 : _e.amount) || 0, units: this.state.units })];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.renderBestPrice = function () {
        if (this.state.bestPriceLoading) {
            return React.createElement("i", { className: 'fas fa-spinner-third fa-spin' });
        }
        var bestPrice = this.state.bestPrice;
        var exactPrice = this.form.fields.exactPrice.getValue();
        if (exactPrice > 0 && exactPrice < bestPrice.bestPrice) {
            return React.createElement("div", { style: { top: '-8px' }, className: 'position-relative' },
                (bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPriceType) && React.createElement("span", { style: { color: '#d23838' }, className: 'fs-10 ' },
                    "(",
                    (0, trans_1.trans)('price.modifier.exactPrice'),
                    ")"),
                React.createElement("p", null,
                    (0, currency_parser_1.moneyFormat)(exactPrice, 'HUF', 'Nincs ár'),
                    " ",
                    React.createElement(popup_1.Popup, { show: !this.props.readOnly, iconClassName: 'far fa-exclamation-circle ml-3', content: this.renderPricePopOverContent(bestPrice) })));
        }
        return React.createElement("div", { style: { top: '-8px' }, className: 'position-relative' },
            (bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPriceType) && React.createElement("span", { style: { color: '#d23838' }, className: 'fs-10 ' },
                "(",
                (0, trans_1.trans)("price.modifier.".concat(bestPrice.bestPriceType)),
                ")"),
            React.createElement("p", null,
                (0, currency_parser_1.moneyFormat)(bestPrice.bestPrice, 'HUF', 'Nincs ár'),
                " ",
                React.createElement(popup_1.Popup, { show: !this.props.readOnly, iconClassName: 'far fa-exclamation-circle ml-3', content: this.renderPricePopOverContent(bestPrice) })));
    };
    B2bOrderProductTableRow.prototype.renderPricePopOverContent = function (bestPrice) {
        var priceRenderer = function (_a) {
            var title = _a.title, hasValue = _a.hasValue;
            if (!hasValue) {
                return React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-20' },
                        React.createElement("p", { className: 'fs-9' },
                            title,
                            ": ")),
                    React.createElement("div", { className: 'col-3' },
                        React.createElement("i", { className: 'fal fa-minus fs-8' })));
            }
            return React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-20' },
                    React.createElement("p", { className: 'fs-9' },
                        title,
                        ": ")),
                React.createElement("div", { className: 'col-3' },
                    React.createElement("i", { className: 'fal fa-plus fs-8' })));
        };
        var initial = !bestPrice.isProductPriceDiscountable ? React.createElement("p", { className: 'fs-12' }, (0, trans_1.trans)('b2b-order.discounts.not.applicable')) : '';
        var prices = Object.entries(bestPrice)
            .filter((function (_a) {
            var _b = __read(_a, 1), key = _b[0];
            return key !== 'date' && key !== 'bestPriceType' && key !== 'bestPrice' && key !== 'isProductPriceDiscountable';
        }))
            .map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return ({ title: (0, trans_1.trans)("price.modifier.".concat(key)), hasValue: !!value });
        });
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24' }, initial),
            prices.map(function (price) { return React.createElement("div", { key: price.title, className: 'col-24' }, priceRenderer(price)); }));
    };
    B2bOrderProductTableRow.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showProductLocksModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-order.locks.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(product_locks_modal_1.ProductLocksModal, { onClose: function () { return _this.$showProductLocksModal.close(); }, order: _this.state.order, product: _this.state.product, items: _this.getFifoToRender(), setValue: function (data, value) { return _this.setValue(data, value); }, onSave: function () { return _this.onSave(); } }); }, col: {
                    md: 20,
                    lg: 20,
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$popupModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-order.has.no.price.message')); }, renderModalContent: function (ctx) { return React.createElement(popup_modal_1.PopupModal, { onClose: function () { return _this.$popupModal.close(); }, content: React.createElement("div", { className: 'row pb-3' },
                        React.createElement("div", { className: 'col-24 position-center' },
                            React.createElement("span", null,
                                React.createElement("a", { target: '_blank', className: 'a-link', href: '/b2b-customer-partner-pricing' }, (0, trans_1.trans)('b2b-order.has.no.price.link')),
                                " ",
                                (0, trans_1.trans)('b2b-order.has.no.price.instructions')))) }); }, col: {
                    md: 20,
                    lg: 10,
                } }));
    };
    B2bOrderProductTableRow.prototype.sumLocks = function () {
        var _a;
        var count = (_a = this.state.locks) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, item) { return acc + item.amount; }, 0);
        this.form.fields.locks.setValue(count);
    };
    B2bOrderProductTableRow.prototype.download = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, fifoLocks, fifoLocks_1, fifoLocks_1_1, lock, e_4_1;
            var e_4, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.repository.get("/b2b/order/lock-plan/".concat(this.props.order._id, "/").concat(this.state.product.productId))];
                    case 1:
                        response = _d.sent();
                        this.setState({
                            stillAvailable: (response === null || response === void 0 ? void 0 : response.stillAvailable[0]) || {},
                            locks: (response === null || response === void 0 ? void 0 : response.locks) || [],
                        });
                        fifoLocks = (_b = (_a = this.getFifoToRender()).filter) === null || _b === void 0 ? void 0 : _b.call(_a, function (i) { return !!i.amount; });
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        fifoLocks_1 = __values(fifoLocks), fifoLocks_1_1 = fifoLocks_1.next();
                        _d.label = 3;
                    case 3:
                        if (!!fifoLocks_1_1.done) return [3, 6];
                        lock = fifoLocks_1_1.value;
                        return [4, this.setValue(lock, lock.amount)];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5:
                        fifoLocks_1_1 = fifoLocks_1.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_4_1 = _d.sent();
                        e_4 = { error: e_4_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (fifoLocks_1_1 && !fifoLocks_1_1.done && (_c = fifoLocks_1.return)) _c.call(fifoLocks_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7];
                    case 9: return [2];
                }
            });
        });
    };
    B2bOrderProductTableRow.prototype.getLocks = function () {
        var _a;
        return (((_a = this.state) === null || _a === void 0 ? void 0 : _a.locks) || [])
            .map(function (item) { return ({
            product: item.productId || item.product,
            lot: item.productLot || item.lot,
            storage: item.storage,
            type: item.type,
            typePayload: item.refId || item.typePayload || null,
            amount: item.amount || 0,
            __meta: item.__meta,
            __key: "".concat(item.product, "-").concat(item.lot, "-").concat(item.storage, "-").concat(item.type, "-").concat(item.typePayload || null),
            isLock: true,
        }); })
            .reduce(function (acc, item) {
            var accIndex = acc.findIndex(function (i) { return i.__key === item.__key; });
            if (accIndex !== -1) {
                acc[accIndex].amount += item.amount;
                return acc;
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }, []);
    };
    B2bOrderProductTableRow.prototype.getAvailable = function () {
        var _this = this;
        var _a, _b;
        return (((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.stillAvailable) === null || _b === void 0 ? void 0 : _b.items) || [])
            .map(function (item) {
            var _a, _b, _c, _d;
            return ({
                product: (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.productId,
                lot: item.productLot || item.lot,
                storage: item.storage,
                type: item.type,
                typePayload: item.refId || item.typePayload || null,
                amount: item.amount || 0,
                __meta: item.__meta,
                __key: "".concat((_d = (_c = _this.state) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.productId, "-").concat(item.lot, "-").concat(item.storage, "-").concat(item.type, "-").concat(item.typePayload || null)
            });
        })
            .reduce(function (acc, item) {
            var accIndex = acc.findIndex(function (i) { return i.__key === item.__key; });
            if (accIndex !== -1) {
                acc[accIndex].amount += item.amount;
                return acc;
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }, []);
    };
    B2bOrderProductTableRow.prototype.getFifoToRender = function () {
        var e_5, _a;
        var items = [];
        var locksItems = this.getLocks();
        var availableItems = this.getAvailable();
        var keys = __spreadArray(__spreadArray([], __read(locksItems), false), __read(availableItems), false).map(function (item) { return item.__key; })
            .reduce(function (acc, item) { return acc.includes(item) ? acc : __spreadArray(__spreadArray([], __read(acc), false), [item], false); }, [])
            .sort(function (a, b) { return a > b ? 1 : -1; });
        var _loop_1 = function (key) {
            var lockValue = locksItems.find(function (item) { return item.__key === key; });
            var availableValue = availableItems.find(function (item) { return item.__key === key; });
            var value = lockValue || availableValue;
            var amount = ((this_1.state.values[key] === undefined)
                ? ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0)
                : (this_1.state.values[key]) || 0);
            var totalAmount = availableValue
                ? ((availableValue === null || availableValue === void 0 ? void 0 : availableValue.amount) || 0) + ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0)
                : ((lockValue === null || lockValue === void 0 ? void 0 : lockValue.amount) || 0);
            items.push(__assign(__assign({}, value), { amount: amount, totalAmount: totalAmount }));
        };
        var this_1 = this;
        try {
            for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                var key = keys_1_1.value;
                _loop_1(key);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        return items;
    };
    B2bOrderProductTableRow.prototype.setValue = function (data, value) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var current;
            var _b;
            return __generator(this, function (_c) {
                current = __assign(__assign({}, (_a = this.state) === null || _a === void 0 ? void 0 : _a.values), (_b = {}, _b[data.__key] = value, _b));
                this.setState({ values: current });
                this.sumLocks();
                return [2];
            });
        });
    };
    B2bOrderProductTableRow.prototype.onSave = function () {
        var _a;
        var count = (_a = Object.values(this.state.values)) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, item) { return acc + item; }, 0);
        this.form.fields.locks.setValue(count);
        this.$showProductLocksModal.close();
    };
    return B2bOrderProductTableRow;
}(abstract_component_1.AbstractComponent));
exports.B2bOrderProductTableRow = B2bOrderProductTableRow;
