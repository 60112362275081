"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
require("./attribute-update.screen.scss");
var AttributeUpdateScreen = (function (_super) {
    __extends(AttributeUpdateScreen, _super);
    function AttributeUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('attribute.form.description_hu'),
                    validators: []
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('attribute.form.description_en'),
                    validators: []
                }),
                key: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'key',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.key'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                adminTitle: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'adminTitle',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('attribute.form.adminTitle'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                isAccordingToOurSpecialists: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'isAccordingToOurSpecialists',
                    label: (0, trans_1.trans)('attribute.form.isAccordingToOurSpecialists'),
                    validators: []
                }),
                productOwnerEnableEdit: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'productOwnerEnableEdit',
                    label: (0, trans_1.trans)('attribute.form.productOwnerEnableEdit'),
                    validators: []
                }),
                inputFieldType: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'inputFieldType',
                    options: [
                        { name: 'Szöveg', value: 'text', key: 'text' },
                        { name: 'Szám', value: 'number', key: 'number' }
                    ],
                    label: (0, trans_1.trans)('attribute.form.inputFieldType'),
                    validators: []
                }),
            }
        });
        _this.state = {
            attribute: null,
            error: null
        };
        return _this;
    }
    AttributeUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.updateAttribute(); });
        this.fetchAttribute();
    };
    AttributeUpdateScreen.prototype.fetchAttribute = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_r) {
                switch (_r.label) {
                    case 0:
                        _r.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/b2c/category-attribute/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.attributeId))];
                    case 1:
                        response = _r.sent();
                        this.setState({ attribute: response });
                        this.form.fields.title_hu.setValue((_d = (_c = this.state.attribute) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.hu);
                        this.form.fields.title_en.setValue((_f = (_e = this.state.attribute) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.en);
                        this.form.fields.description_hu.setValue((_h = (_g = this.state.attribute) === null || _g === void 0 ? void 0 : _g.description) === null || _h === void 0 ? void 0 : _h.hu);
                        this.form.fields.description_en.setValue((_k = (_j = this.state.attribute) === null || _j === void 0 ? void 0 : _j.description) === null || _k === void 0 ? void 0 : _k.en);
                        this.form.fields.adminTitle.setValue((_l = this.state.attribute) === null || _l === void 0 ? void 0 : _l.adminTitle);
                        this.form.fields.key.setValue((_m = this.state.attribute) === null || _m === void 0 ? void 0 : _m.key);
                        this.form.fields.isAccordingToOurSpecialists.setValue((_o = this.state.attribute) === null || _o === void 0 ? void 0 : _o.isAccordingToOurSpecialists);
                        this.form.fields.productOwnerEnableEdit.setValue(!!((_p = this.state.attribute) === null || _p === void 0 ? void 0 : _p.productOwnerEnableEdit));
                        this.form.fields.inputFieldType.setValue((_q = this.state.attribute) === null || _q === void 0 ? void 0 : _q.inputFieldType);
                        return [3, 3];
                    case 2:
                        e_1 = _r.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    AttributeUpdateScreen.prototype.render = function () {
        var _a, _b, _c, _d;
        return React.createElement("div", { className: 'AttributeUpdateScreen display-flex flex-column align-items-center w-100' },
            React.createElement("div", { className: 'w-100 display-flex flex-row mb-6' },
                React.createElement("h2", { className: 'color--secondary-dark-3' },
                    (0, trans_1.trans)('attribute.update.screen.title'),
                    " / "),
                React.createElement("h2", { className: 'color--secondary-dark-3' }, (_d = (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.attribute) === null || _b === void 0 ? void 0 : _b.title) === null || _c === void 0 ? void 0 : _c.hu) === null || _d === void 0 ? void 0 :
                    _d.toUpperCase(),
                    " / "),
                React.createElement("h2", null, (0, trans_1.trans)('attribute.update.screen.title'))),
            React.createElement("div", { className: "mb-4" }, this.renderAttributeForm()));
    };
    AttributeUpdateScreen.prototype.renderAttributeForm = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'AttributeUpdateForm' },
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.key, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.adminTitle, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.isAccordingToOurSpecialists, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.productOwnerEnableEdit, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.inputFieldType, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic' }))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelUpdate(); } }),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('attribute.update.button.title'), onClick: function () { return _this.form.submit(); } })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message })));
    };
    AttributeUpdateScreen.prototype.cancelUpdate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/attributes');
                        return [2];
                }
            });
        });
    };
    AttributeUpdateScreen.prototype.updateAttribute = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data, parsedData, response, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        data = this.form.toJSON();
                        parsedData = __assign(__assign({}, data), { title: { hu: data === null || data === void 0 ? void 0 : data.title_hu.trim(), en: data.title_en.trim() }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en } });
                        return [4, this.repository.put("/admin/b2c/category-attribute/".concat((_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a._id), { data: parsedData })];
                    case 1:
                        response = _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('attribute.update.success.toast'));
                        router_provider_1.RouterProvider.goTo("/attributes/".concat(response === null || response === void 0 ? void 0 : response._id, "/options"));
                        return [3, 3];
                    case 2:
                        e_2 = _b.sent();
                        this.setState({ error: e_2 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return AttributeUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.AttributeUpdateScreen = AttributeUpdateScreen;
