"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearlySales = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var lodash_1 = require("lodash");
var React = require("react");
var box_loader_1 = require("../../../common/components/repository-list/loader/box-loader");
var sales_target_charts_1 = require("./libs/sales-target-charts");
var YearlySales = (function (_super) {
    __extends(YearlySales, _super);
    function YearlySales() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            percentage: 0,
            targets: null,
            total: 0,
            orders: []
        };
        return _this;
    }
    YearlySales.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch(this.props.user)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    YearlySales.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(prevProps.user, this.props.user)) return [3, 2];
                        return [4, this.fetch(this.props.user)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    YearlySales.prototype.render = function () {
        if (this.state.loading) {
            return React.createElement("div", { style: { minHeight: '300px' }, className: 'row py-9 ' },
                React.createElement(box_loader_1.BoxLoader, null));
        }
        return React.createElement(sales_target_charts_1.SalesTargetCharts, { total: this.state.total, data: this.state.orders, targets: this.state.targets, percentage: this.state.percentage });
    };
    YearlySales.prototype.fetch = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var response, total, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(user === null || user === void 0 ? void 0 : user._id)) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/sales-dashboard/yearly-sales/".concat(user._id))];
                    case 2:
                        response = _a.sent();
                        total = response.orders.reduce(function (acc, item) { return acc + (item.currentTotalValue || 0); }, 0);
                        this.setState({
                            total: total,
                            orders: response.orders,
                            targets: response.salesManagerTarget
                        });
                        return [3, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.log({ YearlySales: e_1 });
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    return YearlySales;
}(abstract_component_1.AbstractComponent));
exports.YearlySales = YearlySales;
