"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerPricingTableRow = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var moment = require("moment");
var React = require("react");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var permissions_1 = require("../../../../common/libs/permissions/permissions");
var permission_wrapper_1 = require("../../../../components/permission-wrapper");
var CustomerPartnerPricingTableRow = (function (_super) {
    __extends(CustomerPartnerPricingTableRow, _super);
    function CustomerPartnerPricingTableRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            date: _this.props.date,
            item: _this.props.item,
            channelPrice: '',
            generalDiscountedPrice: '',
            customerPartnerPriceModifier: _this.props.customerPartnerPriceModifier || 0,
        };
        return _this;
    }
    CustomerPartnerPricingTableRow.prototype.componentDidMount = function () {
        this.handlePriceDisplays(this.props.date);
    };
    CustomerPartnerPricingTableRow.prototype.handlePriceDisplays = function (date) {
        var price = this.getCustomPriceByDate(this.props.date);
        var discountPrice = this.getDiscountPriceByDate(this.props.date);
        var channelPrice = this.getChannelPrice();
        var generalDiscountedPrice = this.getGeneralPartnerDiscountPriceByDate(+channelPrice);
        this.setState({
            generalDiscountedPrice: generalDiscountedPrice,
            discountPrice: discountPrice,
            channelPrice: (0, currency_parser_1.hufFormat)(channelPrice),
            price: (price === null || price === void 0 ? void 0 : price.value) ? React.createElement("span", { className: 'color--error-6' },
                (0, currency_parser_1.splitToMoney)(price === null || price === void 0 ? void 0 : price.value),
                " Ft") : 'nincs érvényes ár',
        });
    };
    CustomerPartnerPricingTableRow.prototype.getGeneralPartnerDiscountPriceByDate = function (channelPrice) {
        if (!channelPrice || isNaN(channelPrice)) {
            return 'nincs érvényes listaár';
        }
        if (this.state.customerPartnerPriceModifier === undefined || this.state.customerPartnerPriceModifier === null) {
            return 'nincs partner ár módosító';
        }
        var discountPrice = channelPrice * ((100 - this.state.customerPartnerPriceModifier) / 100);
        return "".concat((0, currency_parser_1.splitToMoney)(discountPrice), " Ft | -").concat(this.state.customerPartnerPriceModifier, "%");
    };
    CustomerPartnerPricingTableRow.prototype.getChannelPrice = function () {
        var _a, _b, _c;
        var defaultDisplayValue = 'nincs érvényes ár';
        var channelPriceObj = (_a = this.props.item.channelPrice) === null || _a === void 0 ? void 0 : _a.priceData.items.find(function (item) { return moment().isSameOrAfter(item.from) && (!item.to || moment().isSameOrBefore(item.to)); });
        if (!channelPriceObj || !channelPriceObj.value) {
            return defaultDisplayValue;
        }
        return (_b = channelPriceObj === null || channelPriceObj === void 0 ? void 0 : channelPriceObj.value) !== null && _b !== void 0 ? _b : (_c = channelPriceObj === null || channelPriceObj === void 0 ? void 0 : channelPriceObj.value) === null || _c === void 0 ? void 0 : _c.value;
    };
    CustomerPartnerPricingTableRow.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.props.date !== prevProps.date) {
                    this.setState({ date: this.props.date });
                    this.handlePriceDisplays(this.props.date);
                }
                if (this.props.item !== prevProps.item) {
                    this.setState({ item: this.props.item });
                }
                return [2];
            });
        });
    };
    CustomerPartnerPricingTableRow.prototype.getCustomPriceByDate = function (date) {
        var _a, _b, _c, _d;
        var price = (_d = (_c = (_b = (_a = this.props.item) === null || _a === void 0 ? void 0 : _a.customerPartnerPrice) === null || _b === void 0 ? void 0 : _b.priceData) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.find(function (priceItem) {
            if ((!!priceItem.from && !!priceItem.to) && (moment(date).isSameOrAfter(priceItem.from, 'day') && moment(date).isSameOrBefore(priceItem.to, 'day'))) {
                return priceItem;
            }
            if ((!!priceItem.from && !priceItem.to) && moment(date).isSameOrAfter(priceItem.from, 'day')) {
                return priceItem;
            }
        });
        return price;
    };
    CustomerPartnerPricingTableRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var ean = ((_a = this.props.item) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = this.props.item) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = this.props.item) === null || _c === void 0 ? void 0 : _c.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu) || '-';
        var title = ((_f = (_e = this.props.item) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu) || '-';
        var vintage = ((_g = this.props.item) === null || _g === void 0 ? void 0 : _g.vintage) || '-';
        var capacity = ((_k = (_j = (_h = this.props.item) === null || _h === void 0 ? void 0 : _h.b2cAttributeSearchValues) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k[1]) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, null,
            React.createElement(table_body_cell_1.TableBodyCell, { width: 3, className: 'align-items-start' },
                React.createElement("input", { type: "checkbox", className: 'table-checkbox', onChange: function () { return _this.props.handleCheckboxClick(); }, checked: this.props.checked })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'align-items-start' },
                React.createElement("p", null, (_m = (_l = this.props.item) === null || _l === void 0 ? void 0 : _l.b2cCategoryName) === null || _m === void 0 ? void 0 : _m.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2 ' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title),
                    React.createElement("p", null,
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 14 },
                React.createElement("span", null, this.state.channelPrice)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 14, className: 'justify-content-end align-items-start' },
                React.createElement("p", { className: 'text-align--right' }, this.state.generalDiscountedPrice)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 14, className: 'justify-content-end align-items-start' },
                React.createElement("p", { className: 'text-align--right' }, this.state.price)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 14, className: 'justify-content-end align-items-start' },
                React.createElement("p", { className: 'text-align--right' }, this.state.discountPrice)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 13, className: 'justify-content-end align-items-start' }),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'align-items-start' },
                React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.PRICING.customerPartnerPricing.update },
                    React.createElement(button_1.Button, { onClick: function () { return _this.props.openModal(_this.state.item._id); }, title: (0, trans_1.trans)('b2b-channel-pricing.modal.button.open'), iconLeft: 'fas fa-pencil', className: 'button-neutral-normal button-size-normal' }))));
    };
    CustomerPartnerPricingTableRow.prototype.getDiscountPriceByDate = function (date) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var price = (_a = this.props.item.channelPrice) === null || _a === void 0 ? void 0 : _a.currentPrice;
        if (!(price === null || price === void 0 ? void 0 : price.value)) {
            return 'nincs érvényes akció ';
        }
        var currentDate = moment(date).add(2, 'minute');
        var modifier = (_e = (_d = (_c = (_b = this.props.item) === null || _b === void 0 ? void 0 : _b.customerPartnerPriceModifier) === null || _c === void 0 ? void 0 : _c.valueData) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e.find(function (modifierItem) {
            if (!modifierItem.from) {
                return;
            }
            var modifierFrom = moment(modifierItem.from);
            var modifierTo = moment(modifierItem.to).add(-1, 'hour');
            if (!!modifierItem.to && (moment(currentDate).isSameOrAfter(modifierFrom, 'minute') && moment(currentDate).isBefore(modifierTo, 'minute'))) {
                return modifierItem;
            }
            if (!modifierItem.to && moment(currentDate).isSameOrAfter(modifierFrom, 'minute')) {
                return modifierItem;
            }
        });
        var getValue = function (value) { return (isNaN(+value) ? value === null || value === void 0 ? void 0 : value.value : +value); };
        if (((_f = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _f === void 0 ? void 0 : _f.type) === 'amount') {
            var value = getValue(price);
            var discountPrice = value - ((_g = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _g === void 0 ? void 0 : _g.value);
            return React.createElement("span", { className: 'color--error-6' },
                (0, currency_parser_1.hufFormat)(discountPrice),
                " | -",
                (0, currency_parser_1.hufFormat)((_h = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _h === void 0 ? void 0 : _h.value));
        }
        if (((_j = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _j === void 0 ? void 0 : _j.type) === 'percentage') {
            var value = getValue(price);
            var discountPrice = value * ((100 - ((_k = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _k === void 0 ? void 0 : _k.value)) / 100);
            return React.createElement("span", { className: 'color--error-6' },
                (0, currency_parser_1.hufFormat)(discountPrice),
                " | -", (_l = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _l === void 0 ? void 0 :
                _l.value,
                "%");
        }
        return 'nincs érvényes akció';
    };
    return CustomerPartnerPricingTableRow;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerPricingTableRow = CustomerPartnerPricingTableRow;
