"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WpOrdersManager = exports.StateInERP = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment-timezone");
var React = require("react");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var permissions_1 = require("../../common/libs/permissions/permissions");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var optional_component_1 = require("../../components/optional-component");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var StateInERP;
(function (StateInERP) {
    StateInERP["unprocessed"] = "unprocessed";
    StateInERP["error"] = "error";
    StateInERP["pending"] = "pending";
    StateInERP["readyToProcess"] = "readyToProcess";
    StateInERP["stockIssued"] = "stockIssued";
    StateInERP["failed"] = "failed";
    StateInERP["refunded"] = "refunded";
})(StateInERP = exports.StateInERP || (exports.StateInERP = {}));
var WpOrdersManager = (function (_super) {
    __extends(WpOrdersManager, _super);
    function WpOrdersManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            total: 0,
            statisticsLoading: false,
            statistics: {
                unsuccessful: '',
                paid: '',
                delivered: ''
            }
        };
        return _this;
    }
    WpOrdersManager.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: 'admin/wp-orders', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    WpOrdersManager.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    WpOrdersManager.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('identifier'))));
    };
    WpOrdersManager.prototype.setHeaderCell = function (title) {
        return {
            title: title,
            enableSort: false,
            property: title
        };
    };
    WpOrdersManager.prototype.renderItem = function (ctx, item) {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'hover-opacity', onClick: function () { return _this.handleOpenState(item._id); } },
            React.createElement(table_body_row_1.TableBodyRow, { className: 'bgc-light-grey' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.identifier)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.stateInERP)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.status)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, this.dateFormat(item.updatedAt))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, this.dateFormat(item.createdAt))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, (0, currency_parser_1.hufFormat)(item.total))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, this.renderProcessButton(item))),
            React.createElement(optional_component_1.OptionalComponent, { condition: (_a = this.state.openState) === null || _a === void 0 ? void 0 : _a[item._id], ifTrue: this.renderProducts(item.lineItems, item.processingError) }));
    };
    WpOrdersManager.prototype.renderProducts = function (items, errors) {
        return React.createElement("div", null,
            items.map(function (item) { return React.createElement(table_body_row_1.TableBodyRow, { key: item.sku },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.sku)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.name)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.price)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.amount)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("span", null, item.total))); }),
            React.createElement(table_body_row_1.TableBodyRow, null,
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 }, this.renderErrors(errors))));
    };
    WpOrdersManager.prototype.renderErrors = function (errors) {
        var _this = this;
        var _a;
        return React.createElement("div", null, (_a = (errors || [])) === null || _a === void 0 ? void 0 : _a.reverse().map(function (item, index) { return React.createElement("div", { className: 'bgc-red row my-4 w-100', key: index },
            React.createElement("div", { className: 'col-24' },
                React.createElement("span", null,
                    "Time of occurrence: ",
                    (item === null || item === void 0 ? void 0 : item.time) ? _this.dateFormat(item === null || item === void 0 ? void 0 : item.time) : '')),
            React.createElement("div", { className: 'col-24' },
                React.createElement("span", null, item.error))); }));
    };
    WpOrdersManager.prototype.renderProcessButton = function (order) {
        var _this = this;
        if (order.stateInERP === StateInERP.readyToProcess) {
            return React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.REPORTS.management.update },
                React.createElement(button_1.Button, { onClick: function () { return _this.process(order._id); }, disabled: this.state.loading, title: 'Retry', iconLeft: 'fal fa-plus', className: 'button-primary-normal button-size-normal' }));
        }
        return null;
    };
    WpOrdersManager.prototype.process = function (id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, repository_1.Repository.use('default').put("/admin/wp-orders/".concat(id))];
                    case 1: return [2, _b.sent()];
                    case 2:
                        e_1 = _b.sent();
                        handle_toasts_1.HandleToasts.error(e_1, (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.message) !== null && _a !== void 0 ? _a : 'Sikertelen művelet');
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    WpOrdersManager.prototype.handleOpenState = function (id) {
        var _a;
        var _b;
        var oldState = ((_b = this.state) === null || _b === void 0 ? void 0 : _b.openState) || {};
        var currentOne = !!(oldState === null || oldState === void 0 ? void 0 : oldState[id]);
        this.setState({
            openState: __assign(__assign({}, oldState), (_a = {}, _a[id] = !currentOne, _a))
        });
    };
    WpOrdersManager.prototype.dateFormat = function (date) {
        return moment(date).format('YYYY-MM-DD HH:mm');
    };
    return WpOrdersManager;
}(abstract_component_1.AbstractComponent));
exports.WpOrdersManager = WpOrdersManager;
