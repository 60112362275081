module.exports = {
    'pricing.list.title': 'Árazás',
    'pricing.table.header.sku': 'SKU',
    'pricing.table.header.title': 'Név',
    'pricing.table.header.manufacturer': 'Pincészet',
    'pricing.table.header.amount': 'Ajánlott fogyasztói ár',
    'pricing.table.header.isProductActive': 'Termék állapota',
    'pricing.product-status.exist': 'Kezelt termék',
    'pricing.product-status.deleted': 'Törölt termék',
    'pricing.table.header.updatedAt': 'Módosítva',
    'price-show-bases.table.header.gross': 'Bruttó ár',
    'price-show-bases.table.header.tax': 'Adó mértéke',
    'price-show-bases.table.header.validFrom': 'Érvényesség kezdete',
    'price-show-sales.table.header.discountType': 'Akció típusa',
    'price-show-sales.table.header.discountPayload': 'Akció mértéke',
    'price-show-sales.table.header.validFrom': 'Érvényesség kezdete',
    'price-show-sales.table.header.validTo': 'Érvényesség vége',
    'price-show-sales.discount-type.percentage': 'Százalékos',
    'price-show-sales.discount-type.amount': 'Fix ár',
    'price-show-sales.discount-type-unit.percentage': '%',
    'price-show-sales.discount-type-unit.amount': '',
    'pricing.list.modify-batch.title': 'Tömeges módosítás'
};
