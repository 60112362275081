"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRIES = void 0;
exports.COUNTRIES = [
    {
        name: 'Afganisztán',
        key: 'AF',
        value: 'AF',
    },
    {
        name: 'Åland',
        key: 'AX',
        value: 'AX',
    },
    {
        name: 'Albánia',
        key: 'AL',
        value: 'AL',
    },
    {
        name: 'Algéria',
        key: 'DZ',
        value: 'DZ',
    },
    {
        name: 'Amerikai Szamoa',
        key: 'AS',
        value: 'AS',
    },
    {
        name: 'Andorra',
        key: 'AD',
        value: 'AD',
    },
    {
        name: 'Angola',
        key: 'AO',
        value: 'AO',
    },
    {
        name: 'Anguilla',
        key: 'AI',
        value: 'AI',
    },
    {
        name: 'Antarktisz',
        key: 'AQ',
        value: 'AQ',
    },
    {
        name: 'Antigua és Barbuda',
        key: 'AG',
        value: 'AG',
    },
    {
        name: 'Argentína',
        key: 'AR',
        value: 'AR',
    },
    {
        name: 'Örményország',
        key: 'AM',
        value: 'AM',
    },
    {
        name: 'Aruba',
        key: 'AW',
        value: 'AW',
    },
    {
        name: 'Ausztrália',
        key: 'AU',
        value: 'AU',
    },
    {
        name: 'Ausztria',
        key: 'AT',
        value: 'AT',
    },
    {
        name: 'Azerbajdzsán',
        key: 'AZ',
        value: 'AZ',
    },
    {
        name: 'Bahama-szigetek',
        key: 'BS',
        value: 'BS',
    },
    {
        name: 'Bahrein',
        key: 'BH',
        value: 'BH',
    },
    {
        name: 'Banglades',
        key: 'BD',
        value: 'BD',
    },
    {
        name: 'Barbados',
        key: 'BB',
        value: 'BB',
    },
    {
        name: 'Belarusz',
        key: 'BY',
        value: 'BY',
    },
    {
        name: 'Belgium',
        key: 'BE',
        value: 'BE',
    },
    {
        name: 'Belau',
        key: 'PW',
        value: 'PW',
    },
    {
        name: 'Belize',
        key: 'BZ',
        value: 'BZ',
    },
    {
        name: 'Benin',
        key: 'BJ',
        value: 'BJ',
    },
    {
        name: 'Bermuda',
        key: 'BM',
        value: 'BM',
    },
    {
        name: 'Bhután',
        key: 'BT',
        value: 'BT',
    },
    {
        name: 'Bolívia',
        key: 'BO',
        value: 'BO',
    },
    {
        name: 'Bonaire, Saint Eustatius és Saba',
        key: 'BQ',
        value: 'BQ',
    },
    {
        name: 'Bosznia-Hercegovina',
        key: 'BA',
        value: 'BA',
    },
    {
        name: 'Botswana',
        key: 'BW',
        value: 'BW',
    },
    {
        name: 'Bouvet-sziget',
        key: 'BV',
        value: 'BV',
    },
    {
        name: 'Brazília',
        key: 'BR',
        value: 'BR',
    },
    {
        name: 'Brit Indiai-óceáni Terület',
        key: 'IO',
        value: 'IO',
    },
    {
        name: 'Brunei',
        key: 'BN',
        value: 'BN',
    },
    {
        name: 'Bulgária',
        key: 'BG',
        value: 'BG',
    },
    {
        name: 'Burkina Faso',
        key: 'BF',
        value: 'BF',
    },
    {
        name: 'Burundi',
        key: 'BI',
        value: 'BI',
    },
    {
        name: 'Kambodzsa',
        key: 'KH',
        value: 'KH',
    },
    {
        name: 'Kamerun',
        key: 'CM',
        value: 'CM',
    },
    {
        name: 'Kanada',
        key: 'CA',
        value: 'CA',
    },
    {
        name: 'Zöld-foki-szigetek',
        key: 'CV',
        value: 'CV',
    },
    {
        name: 'Kajmán-szigetek',
        key: 'KY',
        value: 'KY',
    },
    {
        name: 'Közép-afrikai Köztársaság',
        key: 'CF',
        value: 'CF',
    },
    {
        name: 'Csád',
        key: 'TD',
        value: 'TD',
    },
    {
        name: 'Chile',
        key: 'CL',
        value: 'CL',
    },
    {
        name: 'Kína',
        key: 'CN',
        value: 'CN',
    },
    {
        name: 'Karácsony-sziget',
        key: 'CX',
        value: 'CX',
    },
    {
        name: 'Kókusz-szigetek/Keeling-szigetek',
        key: 'CC',
        value: 'CC',
    },
    {
        name: 'Kolumbia',
        key: 'CO',
        value: 'CO',
    },
    {
        name: 'Comore-szigetek',
        key: 'KM',
        value: 'KM',
    },
    {
        name: 'Kongó',
        key: 'CG',
        value: 'CG',
    },
    {
        name: 'Kongói Demokratikus Köztársaság',
        key: 'CD',
        value: 'CD',
    },
    {
        name: 'Cook-szigetek',
        key: 'CK',
        value: 'CK',
    },
    {
        name: 'Costa Rica',
        key: 'CR',
        value: 'CR',
    },
    {
        name: 'Horvátország',
        key: 'HR',
        value: 'HR',
    },
    {
        name: 'Kuba',
        key: 'CU',
        value: 'CU',
    },
    {
        name: 'Curaçao',
        key: 'CW',
        value: 'CW',
    },
    {
        name: 'Ciprus',
        key: 'CY',
        value: 'CY',
    },
    {
        name: 'Csehország',
        key: 'CZ',
        value: 'CZ',
    },
    {
        name: 'Dánia',
        key: 'DK',
        value: 'DK',
    },
    {
        name: 'Dzsibuti',
        key: 'DJ',
        value: 'DJ',
    },
    {
        name: 'Dominika',
        key: 'DM',
        value: 'DM',
    },
    {
        name: 'Dominikai Köztársaság',
        key: 'DO',
        value: 'DO',
    },
    {
        name: 'Ecuador',
        key: 'EC',
        value: 'EC',
    },
    {
        name: 'Egyiptom',
        key: 'EG',
        value: 'EG',
    },
    {
        name: 'Salvador',
        key: 'SV',
        value: 'SV',
    },
    {
        name: 'Egyenlítői-Guinea',
        key: 'GQ',
        value: 'GQ',
    },
    {
        name: 'Eritrea',
        key: 'ER',
        value: 'ER',
    },
    {
        name: 'Észtország',
        key: 'EE',
        value: 'EE',
    },
    {
        name: 'Etiópia',
        key: 'ET',
        value: 'ET',
    },
    {
        name: 'Falkland-szigetek',
        key: 'FK',
        value: 'FK',
    },
    {
        name: 'Feröer-szigetek',
        key: 'FO',
        value: 'FO',
    },
    {
        name: 'Fidzsi-szigetek',
        key: 'FJ',
        value: 'FJ',
    },
    {
        name: 'Finnország',
        key: 'FI',
        value: 'FI',
    },
    {
        name: 'Franciaország',
        key: 'FR',
        value: 'FR',
    },
    {
        name: 'Francia Guyana',
        key: 'GF',
        value: 'GF',
    },
    {
        name: 'Francia Polinézia',
        key: 'PF',
        value: 'PF',
    },
    {
        name: 'Francia Déli Területek',
        key: 'TF',
        value: 'TF',
    },
    {
        name: 'Gabon',
        key: 'GA',
        value: 'GA',
    },
    {
        name: 'Gambia',
        key: 'GM',
        value: 'GM',
    },
    {
        name: 'Grúzia',
        key: 'GE',
        value: 'GE',
    },
    {
        name: 'Németország',
        key: 'DE',
        value: 'DE',
    },
    {
        name: 'Ghána',
        key: 'GH',
        value: 'GH',
    },
    {
        name: 'Gibraltár',
        key: 'GI',
        value: 'GI',
    },
    {
        name: 'Görögország',
        key: 'GR',
        value: 'GR',
    },
    {
        name: 'Grönland',
        key: 'GL',
        value: 'GL',
    },
    {
        name: 'Grenada',
        key: 'GD',
        value: 'GD',
    },
    {
        name: 'Guadeloupe',
        key: 'GP',
        value: 'GP',
    },
    {
        name: 'Guam',
        key: 'GU',
        value: 'GU',
    },
    {
        name: 'Guatemala',
        key: 'GT',
        value: 'GT',
    },
    {
        name: 'Guernsey',
        key: 'GG',
        value: 'GG',
    },
    {
        name: 'Guinea',
        key: 'GN',
        value: 'GN',
    },
    {
        name: 'Bissau-Guinea',
        key: 'GW',
        value: 'GW',
    },
    {
        name: 'Guyana',
        key: 'GY',
        value: 'GY',
    },
    {
        name: 'Haiti',
        key: 'HT',
        value: 'HT',
    },
    {
        name: 'Heard-sziget és McDonald-szigetek',
        key: 'HH',
        value: 'HH',
    },
    {
        name: 'Honduras',
        key: 'HN',
        value: 'HN',
    },
    {
        name: 'Hongkong',
        key: 'HK',
        value: 'HK',
    },
    {
        name: 'Magyarország',
        key: 'HU',
        value: 'HU',
    },
    {
        name: 'Izland',
        key: 'IS',
        value: 'IS',
    },
    {
        name: 'India',
        key: 'IN',
        value: 'IN',
    },
    {
        name: 'Indonézia',
        key: 'ID',
        value: 'ID',
    },
    {
        name: 'Irán',
        key: 'IR',
        value: 'IR',
    },
    {
        name: 'Irak',
        key: 'IQ',
        value: 'IQ',
    },
    {
        name: 'Írország',
        key: 'IE',
        value: 'IE',
    },
    {
        name: 'Man',
        key: 'IM',
        value: 'IM',
    },
    {
        name: 'Izrael',
        key: 'IL',
        value: 'IL',
    },
    {
        name: 'Olaszország',
        key: 'IT',
        value: 'IT',
    },
    {
        name: 'Elefántcsontpart',
        key: 'CI',
        value: 'CI',
    },
    {
        name: 'Jamaica',
        key: 'JM',
        value: 'JM',
    },
    {
        name: 'Japán',
        key: 'JP',
        value: 'JP',
    },
    {
        name: 'Jersey',
        key: 'JE',
        value: 'JE',
    },
    {
        name: 'Jordánia',
        key: 'JO',
        value: 'JO',
    },
    {
        name: 'Kazahsztán',
        key: 'KZ',
        value: 'KZ',
    },
    {
        name: 'Kenya',
        key: 'KE',
        value: 'KE',
    },
    {
        name: 'Kiribati',
        key: 'KI',
        value: 'KI',
    },
    {
        name: 'Kirgizisztán',
        key: 'KG',
        value: 'KG',
    },
    {
        name: 'Laosz',
        key: 'LA',
        value: 'LA',
    },
    {
        name: 'Lettország',
        key: 'LV',
        value: 'LV',
    },
    {
        name: 'Libanon',
        key: 'LB',
        value: 'LB',
    },
    {
        name: 'Lesotho',
        key: 'LS',
        value: 'LS',
    },
    {
        name: 'Libéria',
        key: 'LR',
        value: 'LR',
    },
    {
        name: 'Líbia',
        key: 'LY',
        value: 'LY',
    },
    {
        name: 'Liechtenstein',
        key: 'LI',
        value: 'LI',
    },
    {
        name: 'Litvánia',
        key: 'LT',
        value: 'LT',
    },
    {
        name: 'Luxemburg',
        key: 'LU',
        value: 'LU',
    },
    {
        name: 'Makaó',
        key: 'MO',
        value: 'MO',
    },
    {
        name: 'Észak-Macedónia',
        key: 'MK',
        value: 'MK',
    },
    {
        name: 'Madagaszkár',
        key: 'MG',
        value: 'MG',
    },
    {
        name: 'Malawi',
        key: 'MW',
        value: 'MW',
    },
    {
        name: 'Malajzia',
        key: 'MY',
        value: 'MY',
    },
    {
        name: 'Maldiv-szigetek',
        key: 'MV',
        value: 'MV',
    },
    {
        name: 'Mali',
        key: 'ML',
        value: 'ML',
    },
    {
        name: 'Málta',
        key: 'MT',
        value: 'MT',
    },
    {
        name: 'Marshall-szigetek',
        key: 'MH',
        value: 'MH',
    },
    {
        name: 'Martinique',
        key: 'MQ',
        value: 'MQ',
    },
    {
        name: 'Mauritánia',
        key: 'MR',
        value: 'MR',
    },
    {
        name: 'Mauritius',
        key: 'MU',
        value: 'MU',
    },
    {
        name: 'Mayotte',
        key: 'YT',
        value: 'YT',
    },
    {
        name: 'Mexikó',
        key: 'MX',
        value: 'MX',
    },
    {
        name: 'Mikronézia',
        key: 'FM',
        value: 'FM',
    },
    {
        name: 'Moldova',
        key: 'MD',
        value: 'MD',
    },
    {
        name: 'Monaco',
        key: 'MC',
        value: 'MC',
    },
    {
        name: 'Mongólia',
        key: 'MN',
        value: 'MN',
    },
    {
        name: 'Montenegró',
        key: 'ME',
        value: 'ME',
    },
    {
        name: 'Montserrat',
        key: 'MS',
        value: 'MS',
    },
    {
        name: 'Marokkó',
        key: 'MA',
        value: 'MA',
    },
    {
        name: 'Mozambzik',
        key: 'MZ',
        value: 'MZ',
    },
    {
        name: 'Mianmar',
        key: 'MM',
        value: 'MM',
    },
    {
        name: 'Namíbia',
        key: 'NA',
        value: 'NA',
    },
    {
        name: 'Nauru',
        key: 'NR',
        value: 'NR',
    },
    {
        name: 'Nepál',
        key: 'NP',
        value: 'NP',
    },
    {
        name: 'Hollandia',
        key: 'NL',
        value: 'NL',
    },
    {
        name: 'Új-Kaledónia',
        key: 'NC',
        value: 'NC',
    },
    {
        name: 'Új-Zéland',
        key: 'NZ',
        value: 'NZ',
    },
    {
        name: 'Nicaragua',
        key: 'NI',
        value: 'NI',
    },
    {
        name: 'Niger',
        key: 'NE',
        value: 'NE',
    },
    {
        name: 'Nigéria',
        key: 'NG',
        value: 'NG',
    },
    {
        name: 'Niue',
        key: 'NU',
        value: 'NU',
    },
    {
        name: 'Norfolk-sziget',
        key: 'NF',
        value: 'NF',
    },
    {
        name: 'Északi-Mariana-szigetek',
        key: 'MP',
        value: 'MP',
    },
    {
        name: 'Észak-Korea',
        key: 'KP',
        value: 'KP',
    },
    {
        name: 'Norvégia',
        key: 'NO',
        value: 'NO',
    },
    {
        name: 'Omán',
        key: 'OM',
        value: 'OM',
    },
    {
        name: 'Pakisztán',
        key: 'PK',
        value: 'PK',
    },
    {
        name: 'Palesztina',
        key: 'PS',
        value: 'PS',
    },
    {
        name: 'Panama',
        key: 'PA',
        value: 'PA',
    },
    {
        name: 'ápua Új-Guinea',
        key: 'PG',
        value: 'PG',
    },
    {
        name: 'Paraguay',
        key: 'PY',
        value: 'PY',
    },
    {
        name: 'Peru',
        key: 'PE',
        value: 'PE',
    },
    {
        name: 'Fülöp-szigetek',
        key: 'PH',
        value: 'PH',
    },
    {
        name: 'Pitcairn-szigetek',
        key: 'PN',
        value: 'PN',
    },
    {
        name: 'Lengyelország',
        key: 'PL',
        value: 'PL',
    },
    {
        name: 'Portugália',
        key: 'PT',
        value: 'PT',
    },
    {
        name: 'Puerto Rico',
        key: 'PR',
        value: 'PR',
    },
    {
        name: 'Katár',
        key: 'QA',
        value: 'QA',
    },
    {
        name: 'Réunion',
        key: 'RE',
        value: 'RE',
    },
    {
        name: 'Románia',
        key: 'RO',
        value: 'RO',
    },
    {
        name: 'Oroszország',
        key: 'RU',
        value: 'RU',
    },
    {
        name: 'Ruanda',
        key: 'RW',
        value: 'RW',
    },
    {
        name: 'Saint-Barthélemy',
        key: 'BL',
        value: 'BL',
    },
    {
        name: 'Szent Ilona',
        key: 'SH',
        value: 'SH',
    },
    {
        name: 'Saint Kitts és Nevis',
        key: 'KN',
        value: 'KN',
    },
    {
        name: 'Saint Lucia',
        key: 'LC',
        value: 'LC',
    },
    {
        name: 'Saint Martin (Francia rész',
        key: 'MF',
        value: 'MF',
    },
    {
        name: 'Saint Martin (Holland rész)',
        key: 'SX',
        value: 'SX',
    },
    {
        name: 'Saint-Pierre és Miquelon',
        key: 'PM',
        value: 'PM',
    },
    {
        name: 'Saint Vincent és Grenadine-szigetek',
        key: 'VC',
        value: 'VC',
    },
    {
        name: 'San Marino',
        key: 'SM',
        value: 'SM',
    },
    {
        name: 'São Tomé és Príncipe',
        key: 'ST',
        value: 'ST',
    },
    {
        name: 'Szaúd-Arábia',
        key: 'SA',
        value: 'SA',
    },
    {
        name: 'Szenegál',
        key: 'SN',
        value: 'SN',
    },
    {
        name: 'Szerbia',
        key: 'RS',
        value: 'RS',
    },
    {
        name: 'Seychelle-szigetek',
        key: 'SC',
        value: 'SC',
    },
    {
        name: 'Sierra Leone',
        key: 'SL',
        value: 'SL',
    },
    {
        name: 'Szingapúr',
        key: 'SG',
        value: 'SG',
    },
    {
        name: 'Szlovákia',
        key: 'SK',
        value: 'SK',
    },
    {
        name: 'Szlovénia',
        key: 'SI',
        value: 'SI',
    },
    {
        name: 'Salamon-szigetek',
        key: 'SB',
        value: 'SB',
    },
    {
        name: 'Szomália',
        key: 'SO',
        value: 'SO',
    },
    {
        name: 'Dél-Afrika',
        key: 'ZA',
        value: 'ZA',
    },
    {
        name: 'Dél-Georgia és Déli-Sandwish-szigetek',
        key: 'GS',
        value: 'GS',
    },
    {
        name: 'Dél-Korea',
        key: 'KR',
        value: 'KR',
    },
    {
        name: 'Dél-Szudán',
        key: 'SS',
        value: 'SS',
    },
    {
        name: 'Spanyolország',
        key: 'ES',
        value: 'ES',
    },
    {
        name: 'Srí Lanka',
        key: 'LK',
        value: 'LK',
    },
    {
        name: 'Szudán',
        key: 'SD',
        value: 'SD',
    },
    {
        name: 'Suriname',
        key: 'SR',
        value: 'SR',
    },
    {
        name: 'Svalbard- és Jan Mayen-szigetek',
        key: 'SJ',
        value: 'SJ',
    },
    {
        name: 'Szváziföld',
        key: 'SZ',
        value: 'SZ',
    },
    {
        name: 'Svédország',
        key: 'SE',
        value: 'SE',
    },
    {
        name: 'Svájc',
        key: 'CH',
        value: 'CH',
    },
    {
        name: 'Szíria',
        key: 'SY',
        value: 'SY',
    },
    {
        name: 'Tajvan',
        key: 'TW',
        value: 'TW',
    },
    {
        name: 'Tádzsikisztán',
        key: 'TJ',
        value: 'TJ',
    },
    {
        name: 'Tanzánia',
        key: 'TZ',
        value: 'TZ',
    },
    {
        name: 'Thaiföld',
        key: 'TH',
        value: 'TH',
    },
    {
        name: 'Kelet-Timor',
        key: 'TL',
        value: 'TL',
    },
    {
        name: 'Togo',
        key: 'TG',
        value: 'TG',
    },
    {
        name: 'Tokelau-szigetek',
        key: 'TK',
        value: 'TK',
    },
    {
        name: 'Tonga',
        key: 'TO',
        value: 'TO',
    },
    {
        name: 'Trinidad és Tobago',
        key: 'TT',
        value: 'TT',
    },
    {
        name: 'Tunézia',
        key: 'TN',
        value: 'TN',
    },
    {
        name: 'Törökország',
        key: 'TR',
        value: 'TR',
    },
    {
        name: 'Türkmenisztán',
        key: 'TM',
        value: 'TM',
    },
    {
        name: 'Turks- és Caicos-szigetek',
        key: 'TC',
        value: 'TC',
    },
    {
        name: 'Tuvalue',
        key: 'TV',
        value: 'TV',
    },
    {
        name: 'Uganda',
        key: 'UG',
        value: 'UG',
    },
    {
        name: 'Ukrajna',
        key: 'UA',
        value: 'UA',
    },
    {
        name: 'Egyesült Arab Emírségek',
        key: 'AE',
        value: 'AE',
    },
    {
        name: 'Egyesült Királyság',
        key: 'GB',
        value: 'GB',
    },
    {
        name: 'Amerikai Egyesült Államok',
        key: 'US',
        value: 'US',
    },
    {
        name: 'Az Amerikai Egyesült Államokhoz tartozó lakatlan szigetek',
        key: 'UM',
        value: 'UM',
    },
    {
        name: 'Uruguay',
        key: 'UY',
        value: 'UY',
    },
    {
        name: 'Üzbegisztán',
        key: 'UZ',
        value: 'UZ',
    },
    {
        name: 'Vanuatu',
        key: 'VU',
        value: 'VU',
    },
    {
        name: 'Vatikán',
        key: 'VA',
        value: 'VA',
    },
    {
        name: 'Venezuela',
        key: 'VE',
        value: 'VE',
    },
    {
        name: 'Vietnám',
        key: 'VN',
        value: 'VN',
    },
    {
        name: 'Brit Virgin-szigetek',
        key: 'VG',
        value: 'VG',
    },
    {
        name: 'Virgin-szigetek',
        key: 'VI',
        value: 'VI',
    },
    {
        name: 'Wallis és Futuna',
        key: 'WF',
        value: 'WF',
    },
    {
        name: 'Nyugat-Szahara',
        key: 'EH',
        value: 'EH',
    },
    {
        name: 'Szamoa',
        key: 'WS',
        value: 'WS',
    },
    {
        name: 'Jemen',
        key: 'YE',
        value: 'YE',
    },
    {
        name: 'Zambia',
        key: 'ZM',
        value: 'ZM',
    },
    {
        name: 'Zimbabwe',
        key: 'ZW',
        value: 'ZW',
    },
];
