"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminLayout = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var React = require("react");
var admin_menu_component_1 = require("../../common/components/admin-menu/admin-menu.component");
require("./admin.layout.scss");
var AdminLayout = (function (_super) {
    __extends(AdminLayout, _super);
    function AdminLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminLayout.prototype.render = function () {
        var _a;
        return React.createElement("div", { className: 'DefaultLayout display-flex flex-row' },
            React.createElement("div", { className: 'MenuContainer' },
                React.createElement("div", { className: "admin-menu-wrapper" },
                    React.createElement(admin_menu_component_1.AdminMenuComponent, __assign({}, this.props)))),
            React.createElement("div", { className: "container-fluid flex-fill MainContainer" },
                React.createElement("div", null, (_a = this.props) === null || _a === void 0 ? void 0 : _a.children)));
    };
    return AdminLayout;
}(abstract_component_1.AbstractComponent));
exports.AdminLayout = AdminLayout;
