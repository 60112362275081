module.exports = {
    'price.modifier.exactPrice': 'Egyedi ár',
    'price.modifier.channelPriceItem': 'Listaár',
    'price.modifier.customerPartnerPriceItem': 'Vevő partner ár',
    'price.modifier.channelPriceModifierItem': 'Akciós listaár',
    'price.modifier.customerPartnerPriceModifierItem': 'Akciós vevő partner ár',
    'price.modifier.customerPartnerGeneralModifier': 'Általános vevő partner kedvezmény',
    'form.validation.required': 'Kötelező mező',
    'form.validation.only.positive.nums': 'Negatív érték nem elfogadott',
    'form.validation.password-missmatch': 'A jelszavak nem egyeznek',
    'form.validation.ean': 'Érvénytelen EAN kód',
    'form.validation.invalid-email': 'Nem érvényes email cím',
    'form.missing.required': 'Kötelező adatok hiányosak!',
    'form.missing.ean': 'Hiányzó EAN kód(ok)!',
};
