module.exports = {
    'warehouse.list.title': 'Raktárak',
    'warehouse.title': 'Raktár',
    'warehouse.list.button-create.title': 'Új raktár létrehozása',
    'warehouse.update.save-box.button.enable-edit': 'Raktár szerkesztése',
    'warehouse.update.save-box.button.save': 'Mentés',
    'warehouse.table.header.identifier': 'Azonosító',
    'warehouse.table.header.title': 'Elnevezés',
    'warehouse.table.header.lastOrder': 'Utolsó rendelés',
    'warehouse.table.header.createdAt': 'Létrehozva',
    'warehouse.status.active': 'Aktív',
    'warehouse.status.inactive': 'Inaktív',
    'warehouse.create.save-box.button.save': 'Létrehozás',
    'warehouse.update.subtitle.basic-data.company-data': 'Adatok',
    'warehouse.update.subtitle.basic-data.address': 'Cím',
    'warehouse.update.subtitle.basic-data.other': 'Egyéb adatok',
    'warehouse.basic-data.form.field.title.label': 'Elnevezés',
    'warehouse.basic-data.form.field.description.label': 'Megjegyzés',
    'warehouse.basic-data.form.field.identifier.label': 'Azonosító',
    'warehouse.basic-data.form.field.type.label': 'Raktártípus',
    'warehouse.basic-data.form.field.licenceNumber.label': 'Engedélyszám',
    'warehouse.basic-data.form.field.depot.label': 'Telephely',
    'warehouse.basic-data.form.field.zipCode.label': 'Irányítószám',
    'warehouse.basic-data.form.field.country.label': 'Ország',
    'warehouse.basic-data.form.field.city.label': 'Város',
    'warehouse.basic-data.form.field.address.label': 'Cím',
    'warehouse.basic-data.form.field.comment.label': 'Megjegyzés',
    'warehouse.update.success.toast': 'Sikeresen módosítás',
    'warehouse.create.success.toast': 'Sikeresen létrehozás',
    'warehouse.status-update.success.toast': 'Sikeresen státusz módosítás',
};
