"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceTable = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var badge_1 = require("../../../../components/libs/badge/badge");
var get_status_1 = require("./libs/get-status");
var PriceTable = (function (_super) {
    __extends(PriceTable, _super);
    function PriceTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PriceTable.prototype.render = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'col' }, this.renderTable()),
            React.createElement("div", { className: 'col display-flex justify-content-center my-3' },
                React.createElement(button_1.Button, { className: 'button-error-inverze button-size-normal', title: 'Új ár megadása', iconLeft: 'fas fa-plus', onClick: function () { return _this.props.openEdit(); } })));
    };
    PriceTable.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic w-100', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItem(_this.props.items); } });
    };
    PriceTable.prototype.renderItem = function (items) {
        var _this = this;
        return items === null || items === void 0 ? void 0 : items.map(function (item, index) {
            var status = (0, get_status_1.getStatus)(item);
            return React.createElement(table_body_row_1.TableBodyRow, { key: index, className: "".concat(status === 'past'
                    ? 'color--neutral-4'
                    : status === 'current'
                        ? 'fw-700'
                        : '') },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, item.from),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, item.to || '-'),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement(badge_1.Badge, { title: status === 'past'
                            ? 'LEJÁRT'
                            : status === 'current'
                                ? 'AKTUÁLIS'
                                : 'JÖVŐBENI', type: status === 'past'
                            ? 'neutal'
                            : status === 'current'
                                ? 'success'
                                : 'info' })),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 45 },
                    (0, currency_parser_1.splitToMoney)(item.value),
                    " Ft"),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: 'justify-content-start' }, status === 'future' && React.createElement(button_1.Button, { title: React.createElement("i", { className: 'fas fa-pencil' }), className: 'button-neutral-link', onClick: function () { return _this.props.openEdit(item); } })),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: 'justify-content-start' }, status === 'future' && React.createElement(button_1.Button, { title: React.createElement("i", { className: 'fas fa-trash-can' }), className: 'button-neutral-link', onClick: function () { var _a, _b; return _this.props.deletePrice((_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.customerPartnerPrice) === null || _b === void 0 ? void 0 : _b._id, item.id); } })));
        });
    };
    PriceTable.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('start'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('end'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 45 }, this.setHeaderCell('price'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('edit'), { className: 'justify-content-center' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    PriceTable.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-channel-pricing.edit.modal.table.header.".concat(p)),
            property: p
        };
    };
    return PriceTable;
}(abstract_component_1.AbstractComponent));
exports.PriceTable = PriceTable;
