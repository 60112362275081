"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDistributedFilterComponent = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var select_input_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var ProductDistributedFilterComponent = (function (_super) {
    __extends(ProductDistributedFilterComponent, _super);
    function ProductDistributedFilterComponent() {
        var _this = this;
        var _a, _b, _c, _d;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            status: (_b = (_a = _this.props.control$) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.distributed,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                distributed: new field_1.Field({
                    placeholder: '',
                    value: ((_d = (_c = _this.props.control$) === null || _c === void 0 ? void 0 : _c.getQuery()) === null || _d === void 0 ? void 0 : _d.distributed) || true,
                    name: 'distributed',
                    options: [
                        { key: 'distrubted', name: 'Aktív', value: true },
                        { key: 'undistrubted', name: 'Inaktív', value: false },
                    ],
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.distributed.label'),
                    validators: []
                }),
            }
        });
        return _this;
    }
    ProductDistributedFilterComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.form.fields.distributed.$value.subscribe(function (v) { return _this.handleChange(v); });
    };
    ProductDistributedFilterComponent.prototype.render = function () {
        return React.createElement("div", { className: "w-100 display-flex align-items-center" },
            React.createElement("div", { className: "flex-fill" },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.distributed, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, unclearable: false, multiple: false, className: 'select-input-basic' }))));
    };
    ProductDistributedFilterComponent.prototype.handleChange = function (value) {
        this.props.control$.set('distributed', value);
        this.props.control$.page(0);
        var state = this.state;
        state.distributed = value;
        this.setState(state);
    };
    return ProductDistributedFilterComponent;
}(abstract_component_1.AbstractComponent));
exports.ProductDistributedFilterComponent = ProductDistributedFilterComponent;
