"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementProductsTableComponent = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var check_user_role_1 = require("../../../../common/libs/authentication/check-user-role");
var handle_toasts_1 = require("../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../components/modal/modal");
var add_product_modal_1 = require("./add-product.modal");
var delete_product_modal_1 = require("./delete-product.modal");
var procurement_products_table_row_component_1 = require("./procurement-products-table-row.component");
var ProcurementProductsTableComponent = (function (_super) {
    __extends(ProcurementProductsTableComponent, _super);
    function ProcurementProductsTableComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.planInputRefs = {};
        _this.factInputRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            plan: _this.props.plan || [],
            fact: _this.props.fact || [],
        };
        return _this;
    }
    ProcurementProductsTableComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b;
        if (!(0, lodash_1.isEqual)(this.props.plan, prevProps.plan)) {
            this.setState({ plan: this.props.plan });
        }
        if (!(0, lodash_1.isEqual)(this.props.fact, prevProps.fact)) {
            if (!((_a = this.props.fact) === null || _a === void 0 ? void 0 : _a.length)) {
                var factFromPlan = this.props.plan
                    ? (_b = this.props.plan) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                        return {
                            ean: item.ean,
                            productId: item.productId,
                        };
                    })
                    : [];
                this.setState({ fact: factFromPlan });
            }
            else {
                this.setState({ fact: this.props.fact });
            }
        }
        if (!(0, lodash_1.isEqual)(this.state.plan, prevState.plan)) {
            !!this.state.plan.length
                ? this.props.setSupplierSelectDisabled(true)
                : this.props.setSupplierSelectDisabled(false);
        }
    };
    ProcurementProductsTableComponent.prototype.handleProductPlanAmountChange = function (productId, amount) {
        var plan = (0, lodash_1.clone)(this.state.plan);
        plan.map(function (item) {
            if (item.productId === productId) {
                item.plan = amount;
            }
        });
    };
    ProcurementProductsTableComponent.prototype.handleProductFactAmountChange = function (productId, amount) {
        var fact = (0, lodash_1.clone)(this.state.fact);
        fact.map(function (item) {
            if (item.productId === productId) {
                item.fact = amount;
            }
        });
    };
    ProcurementProductsTableComponent.prototype.handleRemoveProduct = function (productId) {
        var _a, _b;
        var newPlan = (_a = this.state.plan) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
        var newFact = (_b = this.state.fact) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.productId !== productId; });
        this.setState({ plan: newPlan, fact: newFact });
        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('procurement.create.delete.product.success.toast'));
        this.$showDeleteProductModal.close();
    };
    ProcurementProductsTableComponent.prototype.getData = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var valid, _g, _h, product, _j, e_1_1, result, plans, _k, _l, plan, facts, _m, _o, fact;
            var e_1, _p, e_2, _q, e_3, _r;
            return __generator(this, function (_s) {
                switch (_s.label) {
                    case 0:
                        valid = true;
                        _s.label = 1;
                    case 1:
                        _s.trys.push([1, 7, 8, 9]);
                        _g = __values(this.state.plan), _h = _g.next();
                        _s.label = 2;
                    case 2:
                        if (!!_h.done) return [3, 6];
                        product = _h.value;
                        _j = valid;
                        if (!_j) return [3, 4];
                        return [4, this.planInputRefs[product.productId].validate()];
                    case 3:
                        _j = (_s.sent());
                        _s.label = 4;
                    case 4:
                        valid = _j;
                        _s.label = 5;
                    case 5:
                        _h = _g.next();
                        return [3, 2];
                    case 6: return [3, 9];
                    case 7:
                        e_1_1 = _s.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_h && !_h.done && (_p = _g.return)) _p.call(_g);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            return [2, { error: true }];
                        }
                        result = {};
                        plans = [];
                        try {
                            for (_k = __values(this.state.plan), _l = _k.next(); !_l.done; _l = _k.next()) {
                                plan = _l.value;
                                if (!this.planInputRefs[plan === null || plan === void 0 ? void 0 : plan.productId]) {
                                    continue;
                                }
                                plans.push({
                                    productId: ((_a = plan.product) === null || _a === void 0 ? void 0 : _a._id) || (plan === null || plan === void 0 ? void 0 : plan.productId),
                                    ean: parseInt((((_b = plan.product) === null || _b === void 0 ? void 0 : _b.eanCode) || (plan === null || plan === void 0 ? void 0 : plan.ean)), 10),
                                    amount: parseInt((_d = (_c = this.planInputRefs[plan === null || plan === void 0 ? void 0 : plan.productId]) === null || _c === void 0 ? void 0 : _c.getData()) === null || _d === void 0 ? void 0 : _d.amount, 10),
                                });
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_l && !_l.done && (_q = _k.return)) _q.call(_k);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        result.plan = plans;
                        facts = [];
                        try {
                            for (_m = __values(this.state.fact), _o = _m.next(); !_o.done; _o = _m.next()) {
                                fact = _o.value;
                                if (!this.factInputRefs[fact === null || fact === void 0 ? void 0 : fact.productId]) {
                                    continue;
                                }
                                facts.push({
                                    productId: fact.productId,
                                    ean: parseInt(fact.ean, 10),
                                    amount: parseInt((_f = (_e = this.factInputRefs[fact === null || fact === void 0 ? void 0 : fact.productId]) === null || _e === void 0 ? void 0 : _e.getData()) === null || _f === void 0 ? void 0 : _f.amount, 10),
                                });
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_o && !_o.done && (_r = _m.return)) _r.call(_m);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        result.fact = facts;
                        return [2, result];
                }
            });
        });
    };
    ProcurementProductsTableComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            this.renderModals(),
            React.createElement("div", { className: 'col mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('procurement.create.product-list.title')),
                (0, check_user_role_1.checkUserRole)('admin') && React.createElement(button_1.Button, { title: (0, trans_1.trans)('procurement.create.product-list.add.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.$showAddProductModal.open(); }, className: 'button-primary-inverze button-size-normal', disabled: this.props.isAddProductsDisabled })),
            React.createElement(table_1.Table, { className: 'table-basic ProcurementTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.plan); } }));
    };
    ProcurementProductsTableComponent.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('30-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('60-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('90-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('free-stock'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('under-procurement'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('plan'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('fact'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    ProcurementProductsTableComponent.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = true; }
        return {
            enableSort: false,
            title: (0, trans_1.trans)("procurement.create.table.header.".concat(p)),
            property: p
        };
    };
    ProcurementProductsTableComponent.prototype.renderItems = function (items) {
        var _this = this;
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return items.map(function (item, index) {
            var _a;
            var factAmount = (_a = _this.state.fact) === null || _a === void 0 ? void 0 : _a.find(function (i) { return i.productId === item.productId; }).amount;
            return React.createElement(procurement_products_table_row_component_1.ProcurementProductsTableRowComponent, { key: item.productId || item._id, item: item, factAmount: factAmount, planInputRefs: _this.planInputRefs, factInputRefs: _this.factInputRefs, showDeleteProductModal: function (productId) { return _this.$showDeleteProductModal.open({ product: productId }); }, procurementAction: _this.props.procurementAction, handleProductPlanAmountChange: function (amount) { return _this.handleProductPlanAmountChange(item.productId || item._id, amount); }, handleProductFactAmountChange: function (amount) { return _this.handleProductFactAmountChange(item.productId || item._id, amount); }, handleRemoveProduct: function () { return _this.handleRemoveProduct(item.productId || item._id); } });
        });
    };
    ProcurementProductsTableComponent.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAddProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('procurement.add-product.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$showAddProductModal.close(); }, onAddProduct: function (product) { return _this.setState({
                        plan: __spreadArray(__spreadArray([], __read(_this.state.plan), false), [product], false),
                        fact: __spreadArray(__spreadArray([], __read(_this.state.fact), false), [product], false),
                    }); }, supplier: _this.props.supplier, excludedProducts: (_this.state.plan || []).map(function (p) { return "excludedProducts[]=".concat(p.productId); }).join('&') }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$showDeleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.product });
                } }));
    };
    return ProcurementProductsTableComponent;
}(abstract_component_1.AbstractComponent));
exports.ProcurementProductsTableComponent = ProcurementProductsTableComponent;
