"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactsBox = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var table_empty_1 = require("../../../../../common/components/repository-list/empty/table-empty");
var handle_toasts_1 = require("../../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../../components/modal/modal");
var contact_form_1 = require("./contact-form");
var ContactsBox = (function (_super) {
    __extends(ContactsBox, _super);
    function ContactsBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            opened: true,
            contacts: _this.props.values || []
        };
        return _this;
    }
    ContactsBox.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.setState({ contacts: this.props.values });
        }
    };
    ContactsBox.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            this.renderContactsBox(),
            React.createElement(contact_form_1.ContactForm, { onSubmit: function (data) { return _this.addContact(data); }, ref: function (ref) { return _this.$createForm = ref; } }),
            React.createElement(contact_form_1.ContactForm, { onSubmit: function (data, index) { return _this.updateContact(data, index); }, ref: function (ref) { return _this.$updateForm = ref; }, isUpdate: true }));
    };
    ContactsBox.prototype.addContact = function (data) {
        var contacts = this.state.contacts;
        this.setState({ contacts: __spreadArray(__spreadArray([], __read(contacts), false), [data], false) });
    };
    ContactsBox.prototype.deleteContact = function (index) {
        var contacts = this.state.contacts;
        contacts[index] = null;
        this.setState({ contacts: (0, lodash_1.compact)(contacts) });
        handle_toasts_1.HandleToasts.success('Sikeres törlés');
    };
    ContactsBox.prototype.updateContact = function (data, index) {
        var contacts = this.state.contacts;
        contacts[index] = data;
        this.setState({ contacts: __spreadArray([], __read(contacts), false) });
    };
    ContactsBox.prototype.renderContactsBox = function () {
        var _this = this;
        return React.createElement("div", { className: 'border-2 p-4' },
            React.createElement("div", { className: 'display-flex' },
                React.createElement("div", { className: "flex-fill" },
                    React.createElement("h4", { className: 'mb-3' }, this.props.title)),
                this.renderToggle()),
            this.state.opened && React.createElement("div", null,
                React.createElement("div", { className: 'mb-4' }, this.renderTable()),
                !this.props.readOnly && React.createElement("div", { className: 'display-flex justify-content-center' },
                    React.createElement(button_1.Button, { disabled: this.props.readOnly, onClick: function () { var _a; return (_a = _this.$createForm) === null || _a === void 0 ? void 0 : _a.open(); }, className: 'button-size-small button-primary-inverze', iconLeft: 'far fa-plus', title: 'Új hozzáadása' }))));
    };
    ContactsBox.prototype.renderToggle = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("i", { onClick: function () { return _this.setState({ opened: !_this.state.opened }); }, className: "cursor-pointer ".concat(this.state.opened ? 'far fa-chevron-up' : 'far fa-chevron-down') }));
    };
    ContactsBox.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderTableItems(); } });
    };
    ContactsBox.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('name'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('email'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('phone'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('position'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('comment'))),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: '', enableSort: false, property: null }));
    };
    ContactsBox.prototype.renderTableItems = function () {
        var _this = this;
        var _a;
        if (!((_a = this.state.contacts) === null || _a === void 0 ? void 0 : _a.length)) {
            return React.createElement(table_empty_1.TableEmpty, null);
        }
        return this.state.contacts.map(function (a, index) { return _this.renderTableItem(a, index); });
    };
    ContactsBox.prototype.renderTableItem = function (item, index) {
        var _this = this;
        return React.createElement(table_body_row_1.TableBodyRow, { key: index },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.lastName,
                    " ", item === null || item === void 0 ? void 0 :
                    item.firstName)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.email)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.phone)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.position)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.comment)),
            React.createElement(table_body_cell_1.TableBodyCell, { className: 'display-flex justify-content-end', width: 20 }, !this.props.readOnly && React.createElement("div", null,
                React.createElement(button_1.Button, { onClick: function () { return _this.$updateForm.open(__assign(__assign({}, item), { index: index })); }, className: 'button-size-small button-info-link', iconLeft: 'fas fa-pencil' }),
                React.createElement(modal_1.Modal, { disableBackdropClick: true, renderTrigger: function () {
                        return React.createElement(button_1.Button, { className: 'button-size-small button-error-link', iconLeft: 'fas fa-trash' });
                    }, renderModalHeader: function () { return (React.createElement("div", null)); }, renderModalContent: function (modalContext) {
                        return React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                            React.createElement("div", { className: 'mb-2' },
                                React.createElement("span", { className: "fa-stack fa-3x" },
                                    React.createElement("i", { className: "fa-solid fa-circle fa-stack-2x color--error-6" }),
                                    React.createElement("i", { className: "fa-solid fa-trash-can fa-1x fa-stack-1x fa-inverse" }))),
                            React.createElement("h4", { className: 'mb-4' }, "Biztosan t\u00F6rl\u00F6d a kapcsolattart\u00F3t?"),
                            React.createElement("div", { className: "row w-100" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement(button_1.Button, { className: 'w-100 button-neutral-inverze button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } })),
                                React.createElement("div", { className: 'col-12' },
                                    React.createElement(button_1.Button, { className: 'w-100 button-error-normal button-size-normal', iconLeft: 'far fa-trash-can', title: (0, trans_1.trans)('general.button.title.sureDelete'), onClick: function () { return _this.deleteContact(index); } }))));
                    } }))));
    };
    ContactsBox.prototype.setHeaderCell = function (p) {
        return {
            enableSort: false,
            title: (0, trans_1.trans)("contact.table.header.".concat(p)),
            property: p
        };
    };
    ContactsBox.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.state.contacts];
            });
        });
    };
    return ContactsBox;
}(abstract_component_1.AbstractComponent));
exports.ContactsBox = ContactsBox;
