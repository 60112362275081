"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowZeroForLockModal = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var AllowZeroForLockModal = (function (_super) {
    __extends(AllowZeroForLockModal, _super);
    function AllowZeroForLockModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AllowZeroForLockModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'display-flex flex-column align-items-center' },
            React.createElement("p", null, "Az lefoglalt/kiszedett \u00E9rt\u00E9kek k\u00F6z\u00F6tt n\u00E9h\u00E1ny nulla."),
            React.createElement("h4", null, "Biztos, hogy j\u00F3v\u00E1gyod?"),
            React.createElement("div", { className: 'mt-6' },
                React.createElement("div", { className: 'display-flex justify-content-center' },
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.allow-zero.lock.modal.close.button'), onClick: function () { return _this.props.onClose(); }, className: 'button-neutral-inverze button-size-normal' }),
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.allow-zero.lock.modal.add-product.button'), onClick: function () { return _this.props.allow(); }, className: 'button-primary-inverze button-size-normal ml-4' }))));
    };
    return AllowZeroForLockModal;
}(abstract_component_1.AbstractComponent));
exports.AllowZeroForLockModal = AllowZeroForLockModal;
