"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleProductList = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var remove_product_modal_1 = require("./remove-product.modal");
var BundleProductList = (function (_super) {
    __extends(BundleProductList, _super);
    function BundleProductList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BundleProductList.prototype.render = function () {
        return React.createElement("div", null, this.renderProductSelector());
    };
    BundleProductList.prototype.renderProductSelector = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderProductTableHeader(); }, renderBody: function () { return _this.renderSelectedProducts(); } })));
    };
    BundleProductList.prototype.renderSelectedProducts = function () {
        var _this = this;
        var _a;
        return (_a = this.props.products) === null || _a === void 0 ? void 0 : _a.map(function (p) {
            return _this.renderItem(p);
        });
    };
    BundleProductList.prototype.renderProductTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'SKU', property: 'sku' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'CSPID', property: 'group' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Termék címe', property: 'name' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Termék alcíme', property: 'product' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: 'Kategória', property: 'category' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 15, title: '', property: '' }));
    };
    BundleProductList.prototype.renderItem = function (item) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item === null || item === void 0 ? void 0 : item.productId },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.group)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.title) === null || _a === void 0 ? void 0 : _a.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_b = item === null || item === void 0 ? void 0 : item.subTitle) === null || _b === void 0 ? void 0 : _b.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_c = item === null || item === void 0 ? void 0 : item.b2cCategoryName) === null || _c === void 0 ? void 0 : _c.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(remove_product_modal_1.RemoveProductModal, { onDeleteSuccess: function () { return (!!_this.props.onChange ? _this.props.onChange() : null); }, product: item, forYouId: this.props.forYouId })));
    };
    return BundleProductList;
}(abstract_component_1.AbstractComponent));
exports.BundleProductList = BundleProductList;
