"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageParentTreeSelectFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/sprinkles/libs/form/form-control/types/abstract.form-control-type");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var StorageParentTreeSelectFormControlType = (function (_super) {
    __extends(StorageParentTreeSelectFormControlType, _super);
    function StorageParentTreeSelectFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StorageParentTreeSelectFormControlType.prototype.getProps = function () {
        var _this = this;
        return {
            onChange: function (value) { return _this.formControl.props.field.setValue(value[0]); },
            value: this.getValue(),
            label: this.formControl.props.field.getLabel(),
            placeholder: this.formControl.props.field.getPlaceholder(),
            required: this.formControl.props.field.getRequired(),
            errors: this.errors,
            loading: this.formControl.props.field.loading,
            renderTrigger: function (ctx, readOnly) { return _this.renderTrigger(ctx, readOnly); }
        };
    };
    StorageParentTreeSelectFormControlType.prototype.getValue = function () {
        var _a;
        var value = this.formControl.props.field.getValue();
        if (!value) {
            return null;
        }
        if (!!((_a = value === null || value === void 0 ? void 0 : value.document) === null || _a === void 0 ? void 0 : _a._id)) {
            return [value];
        }
        return [{ document: { _id: value } }];
    };
    StorageParentTreeSelectFormControlType.prototype.renderTrigger = function (ctx, readOnly) {
        var _a;
        if (readOnly) {
            return React.createElement("div", null,
                React.createElement(button_1.Button, { className: 'button-size-medium button-neutral-link ', title: !!this.getValue() ? ctx.getPaths(this.getValue()) : 'Raktárterület választása' }));
        }
        return React.createElement("div", null,
            React.createElement(button_1.Button, { iconLeft: !!this.getValue() ? 'far fa-pencil mr-2' : 'far fa-plus mr-2', className: "button-size-medium ".concat(((_a = this.errors) === null || _a === void 0 ? void 0 : _a.length) ? 'button-error-outline' : 'button-neutral-outline', " "), title: !!this.getValue() ? ctx.getPaths(this.getValue()) : 'Raktárterület választása' }));
    };
    return StorageParentTreeSelectFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.StorageParentTreeSelectFormControlType = StorageParentTreeSelectFormControlType;
