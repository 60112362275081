"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploader = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var lodash_1 = require("lodash");
var React = require("react");
var file_uploaded_component_1 = require("./components/file-uploaded.component");
var file_uploading_component_1 = require("./components/file-uploading.component");
require("./file-uploader.scss");
var FileUploader = (function (_super) {
    __extends(FileUploader, _super);
    function FileUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputId = (0, random_id_1.randomId)();
        _this.state = {
            filesToUpload: [],
            current: (0, lodash_1.clone)(_this.props.value || [])
        };
        return _this;
    }
    FileUploader.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b;
        var currentP = (0, lodash_1.clone)(this.props.value || []);
        var prevP = (0, lodash_1.clone)(prevProps.value || []);
        if (!(0, lodash_1.isEqual)(currentP, prevP)) {
            this.setState({
                current: (0, lodash_1.clone)(this.props.value || [])
            });
        }
        var currentS = (0, lodash_1.clone)(this.state.current);
        var prevS = (0, lodash_1.clone)(prevState.current);
        if (!(0, lodash_1.isEqual)(currentS, prevS)) {
            (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.state.current);
        }
    };
    FileUploader.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement("div", { className: "file-uploader-wrapper ".concat(this.props.errors.length ? 'error' : '', " ").concat(this.props.disabled ? 'disabled' : '') },
            React.createElement("label", { htmlFor: this.inputId, className: 'label' }, this.props.label),
            React.createElement("div", { className: 'FileUploader' },
                (this.props.multiple || (!this.props.multiple && !((_a = this.state.filesToUpload) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.state.current) === null || _b === void 0 ? void 0 : _b.length)))
                    && React.createElement("div", { className: 'uploader' },
                        React.createElement("input", { className: 'uploader-input', type: 'file', disabled: this.props.disabled, accept: (_d = (_c = this.props.acceptedMimetypes) === null || _c === void 0 ? void 0 : _c.join) === null || _d === void 0 ? void 0 : _d.call(_c, ', '), ref: function (ref) { return _this.fileInputRef = ref; }, onChange: function (event) { return _this.createUploadList(event); }, multiple: this.props.multiple, id: this.inputId }),
                        React.createElement("span", null, this.props.title),
                        !!this.props.subtitle && React.createElement("small", null, this.props.subtitle)),
                !!((_e = this.state.current) === null || _e === void 0 ? void 0 : _e.length)
                    && React.createElement("div", { className: 'uploaded-files-wrapper' }, this.state.current.map(function (item, index) { return React.createElement(file_uploaded_component_1.FileUploadedComponent, { key: item.url + index, file: item, onDelete: function () { return _this.handleOnDelete(item); }, disabled: _this.props.disabled }); })),
                !!((_f = this.state.filesToUpload) === null || _f === void 0 ? void 0 : _f.length)
                    && React.createElement("div", { className: 'uploading-files-wrapper' }, (_g = this.state.filesToUpload) === null || _g === void 0 ? void 0 : _g.map(function (item, index) { return React.createElement(file_uploading_component_1.FileUploadingComponent, { key: item.token, file: item.file, onSuccess: function (value) { return _this.handleFileUploadSuccess(value, item.token); }, onDismissRequest: function () { return _this.handleFileUploadDismissRequest(item.token); }, endpoint: _this.props.endpoint, repository: _this.props.repository, errors: _this.props.errors }); }))),
            this.renderError());
    };
    FileUploader.prototype.createUploadList = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var files;
            var _this = this;
            return __generator(this, function (_a) {
                if (this.props.disabled) {
                    return [2];
                }
                try {
                    this.setState({ loading: true });
                    files = event.target.files;
                    if (!files.length) {
                        this.setState({ loading: false });
                        return [2];
                    }
                    Array.from(files).forEach(function (item) {
                        var _a, _b;
                        if (!!_this.props.acceptedMimetypes && !((_b = (_a = _this.props.acceptedMimetypes) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, item.type))) {
                            throw new Error('Unsupported file format');
                        }
                        if (!!_this.props.maxSize && (item.size > _this.props.maxSize)) {
                            throw new Error('Too large file size');
                        }
                        var token = (0, random_id_1.randomId)();
                        _this.setState({
                            filesToUpload: __spreadArray(__spreadArray([], __read(_this.state.filesToUpload), false), [
                                {
                                    token: token,
                                    file: item
                                }
                            ], false)
                        });
                    });
                }
                catch (e) {
                    this.setState({ loading: false });
                }
                return [2, null];
            });
        });
    };
    FileUploader.prototype.handleOnDelete = function (value) {
        var newCurrent = (0, lodash_1.clone)(this.state.current || [])
            .filter(function (item) { return (item.url !== value.url); });
        this.setState({
            current: newCurrent
        });
    };
    FileUploader.prototype.handleFileUploadSuccess = function (value, uiToken) {
        this.handleFileUploadDismissRequest(uiToken);
        var newCurrent = __spreadArray(__spreadArray([], __read((0, lodash_1.clone)(this.state.current || [])), false), [value], false);
        this.setState({
            current: newCurrent
        });
    };
    FileUploader.prototype.handleFileUploadDismissRequest = function (uiToken) {
        var newFilesToUpload = (0, lodash_1.clone)(this.state.filesToUpload || [])
            .filter(function (item) { return (item.token !== uiToken); });
        this.setState({
            filesToUpload: newFilesToUpload
        });
    };
    FileUploader.prototype.renderError = function () {
        var _a, _b, _c;
        if (!((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length)) {
            return React.createElement("div", { className: 'error-message' });
        }
        return React.createElement("div", { className: 'error-message' }, (_c = this.props) === null || _c === void 0 ? void 0 : _c.errors[0]);
    };
    return FileUploader;
}(abstract_component_1.AbstractComponent));
exports.FileUploader = FileUploader;
