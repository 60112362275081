"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedPartnerForm = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var lodash_1 = require("lodash");
var React = require("react");
var handle_toasts_1 = require("../../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../../components/modal/modal");
var ConnectedPartnerForm = (function (_super) {
    __extends(ConnectedPartnerForm, _super);
    function ConnectedPartnerForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.connectedPartnerForm = new form_builder_1.FormBuilder({
            fields: {
                partner: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'partner',
                    optionsEndpoint: '/b2b/supplier-partner/',
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) { return response.items.map(function (o) { return ({ name: o.name, value: o, key: o._id, disabled: _this.isOptionDisabled(o._id) }); }); },
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('connected-partner.form.field.name.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        return _this;
    }
    ConnectedPartnerForm.prototype.isOptionDisabled = function (optionId) {
        return this.props.currentPartners.find(function (p) { return ((p === null || p === void 0 ? void 0 : p._id) || p) === optionId; });
    };
    ConnectedPartnerForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.currentPartners, prevProps.currentPartners)) {
            this.connectedPartnerForm.fields.partner.loadOptions({}, '/b2b/supplier-partner/');
        }
    };
    ConnectedPartnerForm.prototype.render = function () {
        return React.createElement("div", null, this.renderPartnerModal());
    };
    ConnectedPartnerForm.prototype.renderPartnerModal = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { ref: function (ref) { return _this.$connectedPartnerModal = ref; }, renderModalHeader: function () {
                return React.createElement("h3", null, !_this.props.isUpdate ? 'Partner hozzáadása' : 'Partner szerkesztése');
            }, renderModalContent: function (modalContext) {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("div", { className: 'row w-100' },
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.connectedPartnerForm.fields.partner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, className: 'select-input-basic' }))))),
                    React.createElement("div", { className: 'display-flex flex-row' },
                        React.createElement("div", null,
                            React.createElement(button_1.Button, { className: 'button-neutral-inverze button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                            React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: _this.props.isUpdate ? 'Mentés' : 'Hozzáadás', onClick: function () { return _this.submitPartner(); } }))));
            }, renderTrigger: function () { return null; } });
    };
    ConnectedPartnerForm.prototype.open = function (data) {
        if (!!data) {
            this.connectedPartnerForm.updateFields(data);
            this.setState({ updateIndex: data.index });
        }
        this.$connectedPartnerModal.open();
    };
    ConnectedPartnerForm.prototype.submitPartner = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.connectedPartnerForm.validate()];
                    case 1:
                        _d.sent();
                        return [4, ((_a = this.connectedPartnerForm) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _d.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        if (this.props.onSubmit) {
                            this.props.onSubmit((_b = this.connectedPartnerForm.toJSON()) === null || _b === void 0 ? void 0 : _b.partner, (_c = this.state) === null || _c === void 0 ? void 0 : _c.updateIndex);
                        }
                        return [4, this.connectedPartnerForm.clear()];
                    case 3:
                        _d.sent();
                        this.connectedPartnerForm.updateFields({});
                        this.$connectedPartnerModal.close();
                        handle_toasts_1.HandleToasts.success(this.props.isUpdate ? 'Sikeres módosítás' : 'Sikeres hozzáadás');
                        return [2];
                }
            });
        });
    };
    return ConnectedPartnerForm;
}(abstract_component_1.AbstractComponent));
exports.ConnectedPartnerForm = ConnectedPartnerForm;
