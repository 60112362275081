module.exports = {
    'order.list.title': 'Megrendelések',
    'order.table.header.identifier': 'Azonosító',
    'order.table.header.customerEmail': 'Megrendelő email címe',
    'order.table.header.name': 'Megrendelő neve',
    'order.table.header.status': 'Státusz',
    'order.table.header.grandTotalGross': 'Összérték',
    'order.table.header.createdAt': 'Időpont',
    'order.table.header.invoiceId': 'Számla',
    'order.table.list.invoice.created': 'Kiállítva',
    'order.table.list.invoice.not-created': 'Sikertelen',
    'order-details.screen.title': 'Megrendelés részletei',
    'order-details.screen.products.subtitle': 'Megrendelt termékek',
    'order-details.screen.product.table.header.sku': 'Sku',
    'order-details.screen.product.table.header.supplierPrice': 'Van beszállító',
    'order-details.screen.product.table.header.manufacturer': 'Pince',
    'order-details.screen.product.table.header.vintage': 'Évjárat',
    'order-details.screen.product.table.header.title': 'Termék neve',
    'order-details.screen.product.table.header.amount': 'Mennyiség',
    'order-details.screen.product.table.header.originalPrize': 'Eredeti darabár',
    'order-details.screen.product.table.header.customerPrize': 'Darabár',
    'order-details.screen.product.table.header.prize': 'Darabár',
    'order-details.screen.product.table.header.sumPrize': 'Ár',
    'order-details.screen.order-sum-prize': 'Összesen',
    'order-details.screen.delivery-title': 'Házhozszállítás',
    'order-details.screen.discounts': 'Kedvezmények',
    'order-details.screen.customer-data.subtitle': 'Vásárló adatai',
    'order-details.screen.customer-data.name': 'Név',
    'order-details.screen.customer-data.phoneNumber': 'Telefonszám',
    'order-details.screen.customer-data.email': 'Email',
    'order-details.screen.billing-address.subtitle': 'Számlázási cím',
    'order-details.screen.billing-address.name': 'Név',
    'order-details.screen.billing-address.address': 'Cím',
    'order-details.screen.billing-address.phoneNumber': 'Telefonszám',
    'order-details.screen.billing-address.taxNumber': 'Adószám',
    'order-details.screen.billing-address.comment': 'Megjegyzés',
    'order-details.screen.shipping-address.subtitle': 'Szállítási cím',
    'order-details.screen.shipping-address.name': 'Név',
    'order-details.screen.shipping-address.address': 'Cím',
    'order-details.screen.shipping-address.phoneNumber': 'Telefonszám',
    'order-details.screen.shipping-address.taxNumber': 'Adószám',
    'order-details.screen.shipping-address.comment': 'Megjegyzés',
    'order.status.title': 'Rendelés állapota',
    'order.status.inProgress': 'Feldolgozás alatt',
    'order.status.approved': 'Fizetve',
    'order.status.failed': 'Sikertelen',
    'order.status.prepared': 'Összekészítve',
    'order.status.underDelivery': 'Futárnak átadva',
    'order.status.delivered': 'Kézbesítve',
};
