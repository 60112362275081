"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var SliderUpdateScreen = (function (_super) {
    __extends(SliderUpdateScreen, _super);
    function SliderUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                subTitle_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'subTitle_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.subTitle_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                subTitle_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'subTitle_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.subTitle_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('slider.form.description_hu'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('slider.form.description_en'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonTitle_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonTitle_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonTitle_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonTitle_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonTitle_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonTitle_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonLink_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonLink_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonLink_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                buttonLink_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'buttonLink_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.buttonLink_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                order: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'order',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.order'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                color: new field_1.Field({
                    placeholder: '',
                    value: 'black',
                    name: 'color',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('slider.form.color'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))],
                    options: [
                        {
                            value: 'black',
                            name: 'fekete',
                            key: 1,
                            disabled: false
                        },
                        {
                            value: 'white',
                            name: 'fehér',
                            key: 2,
                            disabled: false
                        },
                        {
                            value: 'coral',
                            name: 'piros',
                            key: 3,
                            disabled: false
                        },
                    ],
                })
            }
        });
        _this.state = {
            slide: null,
            desktopImage: null,
            mobileImage: null,
            error: null,
            modified: false
        };
        return _this;
    }
    SliderUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.updateSlide(); });
        this.form.$value.subscribe(function (value) { return _this.setState({ modified: true }); });
        this.fetchSlide();
    };
    SliderUpdateScreen.prototype.fetchSlide = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_0) {
                switch (_0.label) {
                    case 0:
                        _0.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/slider/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.slideId))];
                    case 1:
                        response = _0.sent();
                        this.setState({ slide: response });
                        this.setState({ desktopImage: response === null || response === void 0 ? void 0 : response.desktopImage, mobileImage: response === null || response === void 0 ? void 0 : response.mobileImage });
                        this.form.fields.title_hu.setValue((_d = (_c = this.state.slide) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.hu);
                        this.form.fields.title_en.setValue((_f = (_e = this.state.slide) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.en);
                        this.form.fields.subTitle_hu.setValue((_h = (_g = this.state.slide) === null || _g === void 0 ? void 0 : _g.subTitle) === null || _h === void 0 ? void 0 : _h.hu);
                        this.form.fields.subTitle_en.setValue((_k = (_j = this.state.slide) === null || _j === void 0 ? void 0 : _j.subTitle) === null || _k === void 0 ? void 0 : _k.en);
                        this.form.fields.description_hu.setValue((_m = (_l = this.state.slide) === null || _l === void 0 ? void 0 : _l.description) === null || _m === void 0 ? void 0 : _m.hu);
                        this.form.fields.description_en.setValue((_p = (_o = this.state.slide) === null || _o === void 0 ? void 0 : _o.description) === null || _p === void 0 ? void 0 : _p.en);
                        this.form.fields.buttonTitle_hu.setValue((_r = (_q = this.state.slide) === null || _q === void 0 ? void 0 : _q.buttonTitle) === null || _r === void 0 ? void 0 : _r.hu);
                        this.form.fields.buttonTitle_en.setValue((_t = (_s = this.state.slide) === null || _s === void 0 ? void 0 : _s.buttonTitle) === null || _t === void 0 ? void 0 : _t.en);
                        this.form.fields.buttonLink_hu.setValue((_v = (_u = this.state.slide) === null || _u === void 0 ? void 0 : _u.buttonLink) === null || _v === void 0 ? void 0 : _v.hu);
                        this.form.fields.buttonLink_en.setValue((_x = (_w = this.state.slide) === null || _w === void 0 ? void 0 : _w.buttonLink) === null || _x === void 0 ? void 0 : _x.en);
                        this.form.fields.order.setValue((_y = this.state.slide) === null || _y === void 0 ? void 0 : _y.order);
                        this.form.fields.color.setValue((_z = this.state.slide) === null || _z === void 0 ? void 0 : _z.color);
                        this.setState({ modified: false });
                        return [3, 3];
                    case 2:
                        e_1 = _0.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    SliderUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        return React.createElement("div", { className: 'SlideCreateScreen row w-100' },
            React.createElement("div", { className: "col-24" },
                React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('slider.update.screen.title'))),
            React.createElement("div", { className: "col-md-12 col-24" }, this.renderAttributeForm()),
            React.createElement("div", { className: "col-md-12 col-24" }, this.renderImageUploader()),
            React.createElement("div", { className: "col-md-12 col-24" }, !!this.state.slide && React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement("h4", null, "Slider elem megjelen\u00EDt\u00E9se"),
                    React.createElement("p", { className: 'mb-2' }, "Ahhoz, hogy ez az elem megjelenjen az oldalon, publik\u00E1lnod kell. Amennyiben a lenti gomobon azt l\u00E1tod hogy elrejt\u00E9s, \u00FAgy az elem publikus \u00E9s b\u00E1rki sz\u00E1m\u00E1ra l\u00E1that\u00F3. ")),
                React.createElement("div", null,
                    !((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.slide) === null || _b === void 0 ? void 0 : _b.published) &&
                        React.createElement(button_1.Button, { disabled: this.state.modified, className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('slider.publish.button.title'), onClick: function () { return _this.publishSlide(); } }),
                    !!((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.slide) === null || _d === void 0 ? void 0 : _d.published) &&
                        React.createElement(button_1.Button, { disabled: this.state.modified, className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('slider.unpublish.button.title'), onClick: function () { return _this.unpublishSlide(); } }),
                    this.state.modified && React.createElement("span", null, "A st\u00E1tuszv\u00E1ltoztat\u00E1shoz el\u0151bb mentened kell a v\u00E1ltoztat\u00E1saidat.")))),
            React.createElement("div", { className: "col-md-12 col-24" },
                React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelCreate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('slider.update.button.title'), onClick: function () { return _this.form.submit(); } })),
                React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                    React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("slider.update.".concat((0, trans_1.trans)((_e = this.state.error) === null || _e === void 0 ? void 0 : _e.message))) }))));
    };
    SliderUpdateScreen.prototype.renderAttributeForm = function () {
        return React.createElement("div", { className: 'SlideCreateForm' },
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.subTitle_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.subTitle_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { rows: 4, className: 'textarea-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTitle_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTitle_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonLink_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonLink_en, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.color, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { unclearable: true, tabIndex: 1, iconRight: 'winehub-icon arrow-small-down', className: 'select-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.order, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))));
    };
    SliderUpdateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 mb-4' },
                React.createElement("h4", null, "Desktop k\u00E9p"),
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.desktopImageUploaderRef$ = ref; }, value: this.state.desktopImage, onChange: function () { return _this.setState({ modified: true }); }, width: 1600, heigth: 333, targetVariant: "fullpage" })),
            React.createElement("div", { className: 'col-12 mb-4' },
                React.createElement("h4", null, "Mobil k\u00E9p"),
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.mobileImageUploaderRef$ = ref; }, value: this.state.mobileImage, onChange: function () { return _this.setState({ modified: true }); }, width: 375, heigth: 400, targetVariant: "fullpage" })));
    };
    SliderUpdateScreen.prototype.cancelCreate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/slider');
                        return [2];
                }
            });
        });
    };
    SliderUpdateScreen.prototype.publishSlide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/slider/".concat(this.state.slide._id, "/publish"))];
                    case 1:
                        _a.sent();
                        this.fetchSlide();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('slider.publish.success.toast'));
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        handle_toasts_1.HandleToasts.error(e_2, e_2.message);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    SliderUpdateScreen.prototype.unpublishSlide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/admin/slider/".concat(this.state.slide._id, "/unpublish"))];
                    case 1:
                        _a.sent();
                        this.fetchSlide();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('slider.unpublish.success.toast'));
                        return [3, 3];
                    case 2:
                        e_3 = _a.sent();
                        handle_toasts_1.HandleToasts.error(e_3, e_3.message);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    SliderUpdateScreen.prototype.updateSlide = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var data, desktopImage, mobileImage, parsedData, response, e_4;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        data = this.form.toJSON();
                        return [4, ((_a = this.desktopImageUploaderRef$) === null || _a === void 0 ? void 0 : _a.getValue())];
                    case 1:
                        desktopImage = _c.sent();
                        return [4, ((_b = this.mobileImageUploaderRef$) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 2:
                        mobileImage = _c.sent();
                        if (!desktopImage || !mobileImage) {
                            handle_toasts_1.HandleToasts.error(null, 'Képek feltöltése kötelező');
                            return [2];
                        }
                        parsedData = __assign(__assign({}, data), { options: [], title: { hu: data === null || data === void 0 ? void 0 : data.title_hu, en: data.title_en }, subTitle: { hu: data === null || data === void 0 ? void 0 : data.subTitle_hu, en: data.subTitle_en }, buttonTitle: { hu: data === null || data === void 0 ? void 0 : data.buttonTitle_hu, en: data.buttonTitle_en }, buttonLink: { hu: data === null || data === void 0 ? void 0 : data.buttonLink_hu, en: data.buttonLink_en }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en }, desktopImage: desktopImage, mobileImage: mobileImage, color: data.color });
                        return [4, this.repository.put("/admin/slider/".concat(this.state.slide._id), { data: parsedData })];
                    case 3:
                        response = _c.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('slider.update.success.toast'));
                        this.fetchSlide();
                        return [3, 5];
                    case 4:
                        e_4 = _c.sent();
                        this.setState({ error: e_4 });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    return SliderUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.SliderUpdateScreen = SliderUpdateScreen;
