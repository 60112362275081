"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrizesForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var lodash_1 = require("lodash");
var React = require("react");
var prize_input_1 = require("./prize-input");
var PrizesForm = (function (_super) {
    __extends(PrizesForm, _super);
    function PrizesForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prizeInputRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            prizes: []
        };
        return _this;
    }
    PrizesForm.prototype.componentDidMount = function () {
        this.initPrizes();
    };
    PrizesForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.initPrizes();
        }
    };
    PrizesForm.prototype.initPrizes = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var prizes;
            return __generator(this, function (_b) {
                prizes = (((_a = this.props.values) === null || _a === void 0 ? void 0 : _a.prizes) || []).map(function (p) { return ({ prize: p, key: (0, random_id_1.randomId)() }); });
                this.setState({ prizes: prizes });
                return [2];
            });
        });
    };
    PrizesForm.prototype.addPrize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var prizes;
            return __generator(this, function (_a) {
                prizes = this.state.prizes;
                prizes.push({ prize: { title: { hu: '', en: '' }, year: '' }, key: (0, random_id_1.randomId)() });
                this.setState({ prizes: prizes });
                return [2];
            });
        });
    };
    PrizesForm.prototype.render = function () {
        return React.createElement("div", null, this.renderPrizeForm());
    };
    PrizesForm.prototype.renderPrizeForm = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-12' },
                React.createElement("div", null, this.state.prizes.map(function (p) { return React.createElement(prize_input_1.PrizeInput, { ref: function (ref) { return _this.prizeInputRefs[p === null || p === void 0 ? void 0 : p.key] = ref; }, key: p === null || p === void 0 ? void 0 : p.key, onRemove: function () { return _this.removePrize(p === null || p === void 0 ? void 0 : p.key); }, editorLanguage: _this.props.editorLanguage, disabled: _this.props.disabled, value: p === null || p === void 0 ? void 0 : p.prize }); })),
                React.createElement("div", { className: "display-flex align-items-center-justify-content-center w-100" },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', disabled: this.props.disabled, title: (0, trans_1.trans)('product.update.prize.form.add-new.button.title'), onClick: function () { return _this.props.disabled || _this.addPrize(); } }))));
    };
    PrizesForm.prototype.removePrize = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var prizes;
            return __generator(this, function (_a) {
                prizes = this.state.prizes.filter(function (p) { return (p === null || p === void 0 ? void 0 : p.key) !== key; });
                this.setState({ prizes: prizes });
                return [2];
            });
        });
    };
    PrizesForm.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, _a, _b, p, _c, e_1_1, prizes, _d, _e, p;
            var e_1, _f, e_2, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        valid = true;
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 7, 8, 9]);
                        _a = __values(this.state.prizes), _b = _a.next();
                        _h.label = 2;
                    case 2:
                        if (!!_b.done) return [3, 6];
                        p = _b.value;
                        _c = valid;
                        if (!_c) return [3, 4];
                        return [4, this.prizeInputRefs[p === null || p === void 0 ? void 0 : p.key].validate()];
                    case 3:
                        _c = (_h.sent());
                        _h.label = 4;
                    case 4:
                        valid = _c;
                        _h.label = 5;
                    case 5:
                        _b = _a.next();
                        return [3, 2];
                    case 6: return [3, 9];
                    case 7:
                        e_1_1 = _h.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            return [2, { error: true }];
                        }
                        prizes = [];
                        try {
                            for (_d = __values(this.state.prizes), _e = _d.next(); !_e.done; _e = _d.next()) {
                                p = _e.value;
                                if (!this.prizeInputRefs[p === null || p === void 0 ? void 0 : p.key]) {
                                    continue;
                                }
                                prizes.push(this.prizeInputRefs[p === null || p === void 0 ? void 0 : p.key].getData());
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_g = _d.return)) _g.call(_d);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        return [2, {
                                prizes: prizes
                            }];
                }
            });
        });
    };
    return PrizesForm;
}(abstract_component_1.AbstractComponent));
exports.PrizesForm = PrizesForm;
