module.exports = {
    'bundle.list.title': 'Csomagok',
    'bundle.create.screen.title': 'Új létrehozása',
    'bundle.update.screen.title': 'Szerkesztés',
    'bundle.list.button.create.title': 'Új létrehozása',
    'bundle.update.button.title': 'Mentés',
    'bundle.create.button.title': 'Mentés',
    'bundle.table.header.title.hu': 'Cím (HU)',
    'bundle.table.header.title.en': 'Cím (EN)',
    'bundle.table.header.validTo': 'Lerárat',
    'bundle.table.header.categories': 'Kategóriák',
    'bundle.form.title_hu': 'Cím (HU)',
    'bundle.form.title_en': 'Cím (EN)',
    'bundle.form.description_hu': 'Leírás (HU)',
    'bundle.form.description_en': 'Leírás (EN)',
    'bundle.form.categories': 'Kategóriák',
    'bundle.form.validTo': 'Lejárat',
    'bundle-product.form.field.product.label': 'Termék',
};
