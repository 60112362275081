"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageBox = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
require("./message-box.scss");
var MessageBox = (function (_super) {
    __extends(MessageBox, _super);
    function MessageBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MessageBox.prototype.render = function () {
        return React.createElement("div", { className: "message-box ".concat(this.props.type) },
            React.createElement("div", { className: "message" }, this.props.message));
    };
    return MessageBox;
}(abstract_component_1.AbstractComponent));
exports.MessageBox = MessageBox;
