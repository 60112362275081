"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterGroup = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
var FilterGroup = (function (_super) {
    __extends(FilterGroup, _super);
    function FilterGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isFilterOpen: true,
        };
        return _this;
    }
    FilterGroup.prototype.componentDidMount = function () {
        var _a;
        if (!!((_a = this.props) === null || _a === void 0 ? void 0 : _a.defaultClose)) {
            this.setState({ isFilterOpen: false });
        }
    };
    FilterGroup.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'w-100' },
            React.createElement("div", { className: 'list-filter-collapsible-wrapper' },
                React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                    React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                    React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                        React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                this.state.isFilterOpen ? this.props.renderFilters : null));
    };
    FilterGroup.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    return FilterGroup;
}(abstract_component_1.AbstractComponent));
exports.FilterGroup = FilterGroup;
