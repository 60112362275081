module.exports = {
    UnknownUser: 'Ismeretlen felhasználó',
    UnknownProduct: 'Ismeretlen termék',
    NetworkError: 'Hálózati hiba',
    ValidFromMustBeInFuture: 'A kezdeti datumnak jővőbeninek kell lennie',
    AtThisDateABasePriceIsAlreadyExists: 'A megadott ídőszakra már van megadva ár',
    NoBasePriceForSku: 'A termékhez nincs megadott alap ár',
    MissingShippingData: 'Legalább egy telephely megadása kötelező',
    'login.BadCredentials': 'Nem megfelelő emailt vagy jelszót adtál meg',
    'forgot-password.BadCredentials': 'Nem megfelelő emailt',
    'user.create.AlreadyExists': 'Ezzel az e-mail címmel már létezik felhasználó',
    ProductMustBeUnpublished: 'Csak webshopban NEM elérhető termék inaktiválható',
    UnknownProductInPlan: 'Ismeretlen termék a listában',
    ProductIsNotDistributed: 'Inaktív termék a listában',
    'kn-attribute.error.MissingKnCode': 'Hiányzó KN kód',
    'kn-attribute.error.KnCodeAlreadyExists': 'A megadott KN kód már létezik',
    'kn-attribute.error.MissingExciseDutyList': 'Hiányzó jövedékifajtakód(ok)',
    'kn-attribute.error.MissingExciseDutyMeasurement': 'Hiányzó jövedéki mérkék egység',
    'kn-attribute.error.MissingExciseDutyFormula': 'Hiányzó képlet',
    'kn-attribute.error.UnknownDocument': 'Ismeretlen KN kód',
};
