"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomShippingChargeForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var only_positive_number_validator_1 = require("../../../components/form/validators/only-positive-number.validator");
var optional_component_1 = require("../../../components/optional-component");
var popup_1 = require("../../b2b-order/components/popup");
var CustomShippingChargeForm = (function (_super) {
    __extends(CustomShippingChargeForm, _super);
    function CustomShippingChargeForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {};
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                costHUF: new field_1.Field({
                    name: 'costHUF',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.costHUF.label')),
                    value: _this.props.data.costHUF,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
                costEUR: new field_1.Field({
                    name: 'costEUR',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.costEUR.label')),
                    value: _this.props.data.costEUR,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
                from: new field_1.Field({
                    name: 'from',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.from.label')),
                    value: _this.props.data.zipCodes.from,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.to.label')),
                    value: _this.props.data.zipCodes.to,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
                minimumThresholdHUF: new field_1.Field({
                    name: 'to',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.minimumThresholdHUF.label')),
                    value: _this.props.data.minimumThresholdHUF || 0,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')]
                }),
                minimumThresholdEUR: new field_1.Field({
                    name: 'to',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.minimumThresholdEUR.label')),
                    value: _this.props.data.minimumThresholdEUR || 0,
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided')]
                }),
            }
        });
        return _this;
    }
    CustomShippingChargeForm.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.costHUF, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.costEUR, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.minimumThresholdHUF, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.minimumThresholdEUR, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12 col-lg-4', disabled: this.props.readOnly })),
                React.createElement("div", { className: 'col-2 position-center' },
                    React.createElement(optional_component_1.OptionalComponent, { condition: !this.props.readOnly, ifTrue: React.createElement(popup_1.Popup, { popupClassName: 'for-free-shipping-info-icon', show: true, iconClassName: 'far fa-exclamation-circle ml-3', content: React.createElement("p", { className: 'fs-12' }, (0, trans_1.trans)('shipping-charge.info')) }) })),
                React.createElement("div", { className: 'col-1 position-center' },
                    React.createElement(optional_component_1.OptionalComponent, { condition: !this.props.readOnly, ifTrue: React.createElement(button_1.Button, { className: 'button-size-small button-neutral-link hover-opacity', onClick: function () { return _this.props.deleteItem(_this.props.data.key); }, iconLeft: 'fas fa-trash' }) }))));
    };
    CustomShippingChargeForm.prototype.getErrors = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, errors;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        isValid = _a.sent();
                        if (isValid) {
                            return [2, ''];
                        }
                        return [4, this.form.getErrors()];
                    case 2:
                        errors = _a.sent();
                        return [2, Object.values(errors !== null && errors !== void 0 ? errors : {}).join(' ')];
                }
            });
        });
    };
    CustomShippingChargeForm.prototype.getFormData = function () {
        var data = this.form.toJSON();
        return {
            costEUR: +data.costEUR,
            costHUF: +data.costHUF,
            minimumThresholdHUF: +data.minimumThresholdHUF,
            minimumThresholdEUR: +data.minimumThresholdEUR,
            zipCodes: {
                from: +data.from,
                to: +data.to,
            }
        };
    };
    return CustomShippingChargeForm;
}(abstract_component_1.AbstractComponent));
exports.CustomShippingChargeForm = CustomShippingChargeForm;
