"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMenuItemModal = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var modal_1 = require("../../components/modal/modal");
var CreateMenuItemModal = (function (_super) {
    __extends(CreateMenuItemModal, _super);
    function CreateMenuItemModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: (0, trans_1.trans)('menu-item.form.field.title_hu.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: (0, trans_1.trans)('menu-item.form.field.title_en.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                link_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'link_hu',
                    label: (0, trans_1.trans)('menu-item.form.field.link_hu.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                link_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'link_en',
                    label: (0, trans_1.trans)('menu-item.form.field.link_en.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                order: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'order',
                    label: (0, trans_1.trans)('menu-item.form.field.order.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            loading: false
        };
        return _this;
    }
    CreateMenuItemModal.prototype.render = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { renderModalHeader: function () {
                var _a, _b;
                return React.createElement("h3", null, (_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.hu);
            }, renderModalContent: function (modalContext) {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("div", null,
                            React.createElement(form_control_1.FormControl, { field: _this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { type: 'text', tabIndex: 1, className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: _this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { type: 'text', tabIndex: 1, className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: _this.form.fields.link_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { type: 'text', tabIndex: 1, className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: _this.form.fields.link_en, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { type: 'text', tabIndex: 1, className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: _this.form.fields.order, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { type: 'number', tabIndex: 1, className: 'text-input-basic' })))),
                    React.createElement("div", { className: 'display-flex flex-row' },
                        React.createElement("div", null,
                            React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                            React.createElement(button_1.Button, { disabled: _this.state.loading, className: 'button-primary-outline button-size-normal-success', title: 'Mentés', onClick: function () { return _this.submit(modalContext); } }))));
            }, renderTrigger: function () {
                return React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: 'Hozzáadás', iconRight: 'fas fa-plus' });
            } });
    };
    CreateMenuItemModal.prototype.submit = function (modalContext) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isValid, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        isValid = _b.sent();
                        if (!isValid) {
                            return [2];
                        }
                        data = this.form.toJSON();
                        modalContext.setLoading(true);
                        return [4, this.repository.post('/admin/main-menu', {
                                data: {
                                    title: { hu: data.title_hu, en: data.title_en },
                                    link: { hu: data.link_hu, en: data.link_en },
                                    order: data.order,
                                    category: (_a = this.props.category) === null || _a === void 0 ? void 0 : _a._id
                                }
                            })];
                    case 2:
                        _b.sent();
                        if (this.props.onSubmitSuccess) {
                            this.props.onSubmitSuccess();
                        }
                        return [4, this.form.updateFields({})];
                    case 3:
                        _b.sent();
                        return [4, this.form.clear()];
                    case 4:
                        _b.sent();
                        modalContext.close();
                        modalContext.setLoading(false);
                        return [2];
                }
            });
        });
    };
    return CreateMenuItemModal;
}(abstract_component_1.AbstractComponent));
exports.CreateMenuItemModal = CreateMenuItemModal;
