"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeSelectorForCharts = void 0;
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var form_1 = require("../../form/form");
require("./time-selector-for-charts.scss");
var TimeSelectorForCharts = (function (_super) {
    __extends(TimeSelectorForCharts, _super);
    function TimeSelectorForCharts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TimeSelectorForCharts.prototype.render = function () {
        var _a, _b;
        return React.createElement(form_1.Form, { className: 'time-selector' },
            React.createElement(form_control_1.FormControl, { field: (_b = (_a = this.props.form) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.option, type: select_input_form_control_type_1.SelectInputFormControlType },
                React.createElement(select_input_1.SelectInput, { tabIndex: 1, className: 'select-input-basic', unclearable: true })));
    };
    return TimeSelectorForCharts;
}(abstract_component_1.AbstractComponent));
exports.TimeSelectorForCharts = TimeSelectorForCharts;
