"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommitmentBox = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var cleave_text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/cleave-text-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var cleave_text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/cleave-text-input");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var react_circular_progressbar_1 = require("react-circular-progressbar");
var commitment_chart_1 = require("../../../../components/charts/commitment-chart");
var form_1 = require("../../../../components/form/form");
var extended_required_validator_1 = require("../../../../components/form/validators/extended-required.validator");
var b2b_order_list_screen_1 = require("../../../b2b-order/b2b-order-list.screen");
var CommitmentBox = (function (_super) {
    __extends(CommitmentBox, _super);
    function CommitmentBox() {
        var _this = this;
        var _a;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                commitment: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.commitment) || '',
                    name: 'commitment',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('customer-partner.commitment-box.form.field.commitment.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                fact: new field_1.Field({
                    placeholder: '',
                    value: 0,
                    name: 'fact',
                    label: React.createElement("div", null, (0, trans_1.trans)('customer-partner.commitment-box.form.field.fact.label')),
                    validators: []
                })
            }
        });
        _this.state = {
            fact: {},
            percentage: 0,
        };
        return _this;
    }
    CommitmentBox.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) return [3, 2];
                        this.form.fields.commitment.setValue(this.props.values.commitment);
                        return [4, this.fetchOrders()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    CommitmentBox.prototype.fetchOrders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, paidFact, determinedFact, fact, factTotal, percentage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/b2b/order?customerPartner=".concat(this.props.values._id, "&from=").concat(moment().startOf('year').toString(), "&to=").concat(moment().endOf('year').toString());
                        return [4, this.repository.get("".concat(url, "&status=").concat(b2b_order_list_screen_1.b2bOrderStatus.paid.status))];
                    case 1:
                        paidFact = _a.sent();
                        return [4, this.repository.get("".concat(url, "&status=").concat(b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status))];
                    case 2:
                        determinedFact = _a.sent();
                        fact = __spreadArray(__spreadArray([], __read(paidFact.items), false), __read(determinedFact.items), false);
                        factTotal = fact.reduce(function (acc, item) { return acc + (item.currentTotalValue || 0); }, 0);
                        return [4, this.form.fields.fact.setValue(factTotal)];
                    case 3:
                        _a.sent();
                        percentage = !!this.props.values.commitment
                            ? (Math.floor(factTotal / this.props.values.commitment * 100) || 0)
                            : 0;
                        this.setState({ percentage: percentage, fact: fact });
                        return [2];
                }
            });
        });
    };
    CommitmentBox.prototype.render = function () {
        return React.createElement("div", null, this.renderCommitmentBox());
    };
    CommitmentBox.prototype.renderCommitmentBox = function () {
        var _a;
        return React.createElement("div", { className: 'border-2 p-4' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: "col-12" },
                    React.createElement("h5", null,
                        "Forgalmi v\u00E1llal\u00E1s ",
                        moment().year())),
                React.createElement("div", { className: 'col-12' },
                    React.createElement(form_1.Form, { className: 'display-flex' },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.commitment, type: cleave_text_input_form_control_type_1.CleaveTextInputFormControlType },
                            React.createElement(cleave_text_input_1.CleaveTextInput, { readOnly: this.props.readOnly, iconRight: 'huf-icon', className: 'text-input-basic', cleaveOptions: { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: ' ' } })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.fact, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(cleave_text_input_1.CleaveTextInput, { readOnly: true, iconRight: 'huf-icon', className: 'text-input-basic', cleaveOptions: { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: ' ' } }))))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-19' },
                    React.createElement(commitment_chart_1.CommitmentChart, { data: this.state.fact, commitment: (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.commitment })),
                React.createElement("div", { className: 'col-5' },
                    React.createElement("div", { className: 'circular-progress-bar-wrapper' },
                        React.createElement(react_circular_progressbar_1.CircularProgressbar, { value: this.state.percentage, text: "".concat(this.state.percentage, "%"), strokeWidth: 12, styles: {
                                path: { stroke: '#D6493D' },
                                trail: { stroke: '#F0F0F0' },
                                text: { fill: '#121212' }
                            } })))));
    };
    CommitmentBox.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _b.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _b.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    return CommitmentBox;
}(abstract_component_1.AbstractComponent));
exports.CommitmentBox = CommitmentBox;
