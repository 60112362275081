"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierPriceType = exports.BestPriceType = void 0;
var BestPriceType;
(function (BestPriceType) {
    BestPriceType["exactPrice"] = "exactPrice";
    BestPriceType["channelPriceItem"] = "channelPriceItem";
    BestPriceType["customerPartnerPriceItem"] = "customerPartnerPriceItem";
    BestPriceType["channelPriceModifierItem"] = "channelPriceModifierItem";
    BestPriceType["customerPartnerPriceModifierItem"] = "customerPartnerPriceModifierItem";
    BestPriceType["customerPartnerGeneralModifier"] = "customerPartnerGeneralModifier";
})(BestPriceType = exports.BestPriceType || (exports.BestPriceType = {}));
var SupplierPriceType;
(function (SupplierPriceType) {
    SupplierPriceType["supplierPrice"] = "supplierPrice";
    SupplierPriceType["supplierPriceModifier"] = "supplierPriceModifier";
    SupplierPriceType["supplierPriceForCustomerPartner"] = "supplierPriceForCustomerPartner";
    SupplierPriceType["supplierModifierForCustomerPartner"] = "supplierModifierForCustomerPartner";
})(SupplierPriceType = exports.SupplierPriceType || (exports.SupplierPriceType = {}));
