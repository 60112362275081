module.exports = {
    'shipping-charge.screen.title': 'Szállítási költségek',
    'shipping-charge.general.error': 'Valami nem sikerült',
    'shipping-charge.success.toast': 'Sikeres művelet',
    'shipping-charge.costHUF.label': 'Szállítási díj (huf)',
    'shipping-charge.costEUR.label': 'Szállítási díj (eur)',
    'shipping-charge.info': 'Az ingyenes kiszállításhoz szükséges minimum rendelési érték, megadható euró vagy foritnban',
    'shipping-charge.from.label': 'Irányítószám(tól)',
    'shipping-charge.to.label': 'Irányítószám(ig)',
    'shipping-charge.minimumThresholdHUF.label': 'Ingyenes szállításhoz (HUF)',
    'shipping-charge.minimumThresholdEUR.label': 'Ingyenes szállításhoz (EUR)',
    'shipping-charge.default.shipping.charge.missing': 'Hiányzik az alapértelmezett szállítási költség',
    'shipping-charge.defaultShippingChargeHUF.label': 'Alapértelmezett szállítási díj (huf)',
    'shipping-charge.defaultShippingChargeEUR.label': 'Alapértelmezett szállítási díj (eur)',
    'shipping-charge.custom.shipping.charges.label': 'Egyedi szállítási díjak',
    'shipping-charge.add.custom.shipping.charge.label': 'Egyedi szállítási díj hozzáadása',
};
