module.exports = {
    'synonym-dictionary.screen.title': 'Keresések',
    'synonym-dictionary.search-words.tab.title': 'Keresett kulcsszavak',
    'synonym-dictionary.synonyms.tab.title': 'Szinoníma szótár',
    'synonym-dictionary.tab.result-number.title': 'Találatok száma',
    'search-string.table.header.keyword': 'Kulcsszó',
    'search-string.table.header.frequency': 'Keresés gyakorisága',
    'search-string.table.header.hasSynonym': 'Szótárba',
    'search-string.table.header.updatedAt': 'Utolsó frissítés',
    'search-string.table.has-synonym.label': 'Szerepel a szótárban',
    'search-string.add-to-dictionary.button.title': 'Hozzáadás szótárhoz',
    'search-string.frequency.unit.label': 'alkalommal',
    'add-synonym.button.title': 'Hozzáadás',
    'add-synonym.modal.title': 'kulcsszó hozzáadása a szótárhoz',
    'search-keyword-synonym.label': 'Szinoníma',
    'synonym-dictionary.table.header.synonym': 'Szinoníma',
    'synonym-dictionary.table.header.keywords': 'Kulcsszavak',
    'remove-keyword.modal.title': 'Kulcsszó törlése',
    'remove-keyword.button.title': 'Törlés',
    'create-synonym.modal.title': 'Szinoníma hozzáadása',
    'create-synonym-form.synonym.label': 'Szinoníma',
    'create-synonym-form.keywords.label': 'Kulcsszavak',
    'create-synonym.success.toast.message': 'A kulcsszót sikeresen hozzáadtad a szótárhoz',
    'add-synonym.success.toast.message': 'A kulcsszót sikeresen hozzáadtad a szótárhoz',
    'remove-keyword.success.toast.message': 'A kulcsszót sikeresen törölted'
};
