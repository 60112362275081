"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpdListScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var synced_1 = require("./components/synced");
var unsynced_1 = require("./components/unsynced");
var DpdListScreen = (function (_super) {
    __extends(DpdListScreen, _super);
    function DpdListScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {},
        });
        _this.state = {
            total: 0,
            currentTab: 'unsynced',
            tabs: ['unsynced', 'synced']
        };
        return _this;
    }
    DpdListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'display-flex align-items-center mb-6' },
                React.createElement("h2", { className: 'mr-4' }, "DPD szinkroniz\u00E1ci\u00F3")),
            React.createElement("div", null,
                React.createElement("div", null, this.state.tabs.map(function (item) { return (React.createElement(button_1.Button, { key: item, className: _this.state.currentTab === item ? 'button-primary-normal button-size-normal' : 'button-primary-outline button-size-normal', title: item, onClick: function () { return _this.setState({ currentTab: item }); } })); })),
                React.createElement("div", { className: 'border-2 p-4' },
                    this.state.currentTab === 'unsynced' && React.createElement(unsynced_1.Unsynced, null),
                    this.state.currentTab === 'synced' && React.createElement(synced_1.Synced, null))));
    };
    return DpdListScreen;
}(abstract_component_1.AbstractComponent));
exports.DpdListScreen = DpdListScreen;
