"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'b2b-channel-pricing.list.main.title': 'Listaárak',
    'b2b-channel-pricing.filter.form.label.channel': 'Csatorna',
    'b2b-channel-pricing.filter.form.label.date': 'Dátum',
    'b2b-channel-pricing.filter.form.label.category': 'Kategória',
    'b2b-channel-pricing.filter.form.label.manufacturer': 'Pince',
    'b2b-channel-pricing.modal.button.open.grouped': 'Csoportos kedvezmény megadás',
    'b2b-channel-pricing.table.header.checkbox': '',
    'b2b-channel-pricing.table.header.category': 'kategória',
    'b2b-channel-pricing.table.header.product': 'termék',
    'b2b-channel-pricing.table.header.price': 'listaár',
    'b2b-channel-pricing.table.header.discount': 'akciós ár',
    'b2b-channel-pricing.table.header.edit': '',
    'b2b-channel-pricing.modal.button.open': 'Szerkesztés',
    'b2b-channel-pricing.modal.button.discount.end': 'Akció vége',
    'b2b-channel-pricing.pricing.show.modal.title': 'Ár módosítása',
    'b2b-channel-pricing.pricing.show.modal.title.batch': 'Tömeges akció megadás',
    'b2b-channel-pricing.pricing.show.modal.channel': 'Beszállító partner',
    'b2b-channel-pricing.edit.modal.table.header.start': 'kezdés',
    'b2b-channel-pricing.edit.modal.table.header.end': 'vége',
    'b2b-channel-pricing.edit.modal.table.header.status': 'státusz',
    'b2b-channel-pricing.edit.modal.table.header.price': 'ár',
    'b2b-channel-pricing.edit.modal.table.header.modifier': 'ár módosító',
    'b2b-channel-pricing.edit.modal.table.header.edit': React.createElement("i", { className: 'fa-solid fa-pencil' }),
    'b2b-channel-pricing.edit.modal.table.header.delete': React.createElement("i", { className: 'fa-solid fa-trash-can' }),
    'b2b-channel-pricing.error.missing.required': 'Kötelző adatok hiányoznak',
    'b2b-channel-pricing.error.Unauthorized': 'Nincs jogosultságod a művelet végrehajtásához',
    'b2b-channel-pricing.error.MissingChannel': 'Hiányzó csatorna',
    'b2b-channel-pricing.error.MissingProductId': 'Hiányzó termék azonosító',
    'b2b-channel-pricing.error.MissingProducts': 'Hiányzó termék azonosítók',
    'b2b-channel-pricing.error.MissingFrom': 'Hiányzó kezdő dátum',
    'b2b-channel-pricing.error.MissingTo': 'Hiányzó vége dátum',
    'b2b-channel-pricing.error.MissingValue': 'Hiányzó ár',
    'b2b-channel-pricing.error.MissingValueInValue': 'Hiányzó ár/mennyiség',
    'b2b-channel-pricing.error.MissingTypeInValue': 'Hiányzó kedvezmény típus',
    'b2b-channel-pricing.error.WrongValueType': 'Hibás kedvezmény típus',
    'b2b-channel-pricing.error.UnknownSupplierPartner': 'Ismeretlen beszállító partner',
    'b2b-channel-pricing.error.UnknownProduct': 'Ismeretlen termék',
    'b2b-channel-pricing.error.MissingSupplierPartnerPriceDocument': 'Hiányzó ár adatok',
    'b2b-channel-pricing.error.FromMustBeInFuture': 'Kezdeti dátumnak a jövőben kell lennie',
    'b2b-channel-pricing.error.ToMustBeAfterFrom': 'A vége dátumnak a kezdeti dátum után kell lennie',
    'b2b-channel-pricing.error.MissingChannelPriceDocument': 'Hinyzó listaár dokumentum',
    'b2b-channel-pricing.error.MissingChannelPriceModifierDocument': 'Hinyzó listaár kedvezmény dokumentum',
};
