"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditBox = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var cleave_text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/cleave-text-input.form-control-type");
var cleave_text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/cleave-text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var moment = require("moment/moment");
var React = require("react");
var react_circular_progressbar_1 = require("react-circular-progressbar");
require("react-circular-progressbar/dist/styles.css");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var form_1 = require("../../../../components/form/form");
var extended_required_validator_1 = require("../../../../components/form/validators/extended-required.validator");
var b2b_order_list_screen_1 = require("../../../b2b-order/b2b-order-list.screen");
var CreditBox = (function (_super) {
    __extends(CreditBox, _super);
    function CreditBox() {
        var _this = this;
        var _a, _b, _c;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                credit: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.credit) || '',
                    name: 'credit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('customer-partner.credit-box.form.field.credit.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                debt: new field_1.Field({
                    placeholder: '',
                    value: ((_c = (_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.__creditInfo) === null || _c === void 0 ? void 0 : _c.unpaidTotal) || 0,
                    name: 'debt',
                    label: React.createElement("div", null, (0, trans_1.trans)('customer-partner.credit-box.form.field.debt.label')),
                    validators: []
                })
            }
        });
        _this.state = {
            percentage: 0,
            outstandings: [],
        };
        return _this;
    }
    CreditBox.prototype.componentDidMount = function () {
        var _a;
        this.countCredit((_a = this.props.values) === null || _a === void 0 ? void 0 : _a.__creditInfo);
    };
    CreditBox.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) return [3, 2];
                        this.form.fields.credit.setValue((_a = this.props.values) === null || _a === void 0 ? void 0 : _a.credit);
                        this.form.fields.debt.setValue(((_c = (_b = this.props.values) === null || _b === void 0 ? void 0 : _b.__creditInfo) === null || _c === void 0 ? void 0 : _c.unpaidTotal) || 0);
                        this.countCredit((_d = this.props.values) === null || _d === void 0 ? void 0 : _d.__creditInfo);
                        return [4, this.fetchFactDeliveryDeterminedOrders()];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    CreditBox.prototype.fetchFactDeliveryDeterminedOrders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var factDeliveryDeterminedOrders, orders, outstandings;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.repository.get("/b2b/order?customerPartner=".concat(this.props.values._id, "&status=").concat(b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status))];
                    case 1:
                        factDeliveryDeterminedOrders = _a.sent();
                        orders = factDeliveryDeterminedOrders.items.map(function (i) { return i.order; });
                        outstandings = orders.map(function (order) { return ({
                            identifier: order.identifier,
                            deliveryDate: moment(order.plannedDeliveryDate).format('YYYY.MM.DD'),
                            createdAt: moment(order.createdAt).format('YYYY.MM.DD hh:mm'),
                            paymentDueDate: moment(order.createdAt).format('YYYY.MM.DD hh:mm'),
                            total: _this.getTotal(order.plan)
                        }); });
                        this.setState({ outstandings: outstandings });
                        return [2];
                }
            });
        });
    };
    CreditBox.prototype.getTotal = function (plan) {
        var total = plan === null || plan === void 0 ? void 0 : plan.reduce(function (acc, item) {
            var _a;
            var price = (_a = item.exactPrice) !== null && _a !== void 0 ? _a : item.calculatedPrice;
            var value = item.amount * price;
            return acc + value;
        }, 0);
        return (0, currency_parser_1.hufFormat)(total !== null && total !== void 0 ? total : 0);
    };
    CreditBox.prototype.countCredit = function (creditInfo) {
        var difference = parseInt(creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.credit, 10) - (creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.unpaidTotal);
        if (difference < 1) {
            return this.setState({ percentage: 0 });
        }
        var percentage = (creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.credit)
            ? (Math.floor(difference / parseInt(creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.credit, 10) * 100))
            : 0;
        this.setState({ percentage: percentage });
    };
    CreditBox.prototype.render = function () {
        return React.createElement("div", null, this.renderCreditBox());
    };
    CreditBox.prototype.renderCreditBox = function () {
        var _this = this;
        return React.createElement("div", { className: 'border-2 p-4' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: "col-12 mb-6" },
                    React.createElement("h5", null, "Hitelkeret"))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'row' },
                        React.createElement(form_1.Form, { className: 'col-12' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.credit, type: cleave_text_input_form_control_type_1.CleaveTextInputFormControlType },
                                React.createElement(cleave_text_input_1.CleaveTextInput, { readOnly: this.props.readOnly, iconRight: 'huf-icon', className: 'text-input-basic', cleaveOptions: { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: ' ' } }))),
                        React.createElement("div", { className: 'col-12' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.debt, type: cleave_text_input_form_control_type_1.CleaveTextInputFormControlType },
                                React.createElement(cleave_text_input_1.CleaveTextInput, { readOnly: true, iconRight: 'huf-icon', className: 'text-input-basic', cleaveOptions: { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: ' ' } })))),
                    React.createElement("div", { className: 'row' },
                        React.createElement("h6", { className: 'col' }, "Fizetetlen sz\u00E1ml\u00E1k"),
                        React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderOutstanding(_this.state.outstandings); } }))),
                React.createElement("div", { className: 'px-6' },
                    React.createElement("h6", null, "Megl\u00E9v\u0151 hitelkeret sz\u00E1zal\u00E9kban"),
                    React.createElement("div", { className: 'circular-progress-bar-wrapper' },
                        React.createElement(react_circular_progressbar_1.CircularProgressbar, { value: this.state.percentage, text: "".concat(this.state.percentage, "%"), strokeWidth: 12, styles: {
                                path: { stroke: '#2E8DE5' },
                                trail: { stroke: '#F0F0F0' },
                                text: { fill: '#121212' }
                            } })))));
    };
    CreditBox.prototype.renderOutstanding = function (outstandings) {
        if (!(outstandings === null || outstandings === void 0 ? void 0 : outstandings.length)) {
            return React.createElement("div", { className: 'w-100 position-center pt-4' },
                React.createElement("span", null, "\u00DCres lista"));
        }
        return outstandings.map(function (_a) {
            var identifier = _a.identifier, total = _a.total, paymentDueDate = _a.paymentDueDate;
            return React.createElement(table_body_row_1.TableBodyRow, { key: identifier },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                    React.createElement("p", null,
                        React.createElement("span", null, identifier))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                    React.createElement("p", null,
                        React.createElement("span", null, total))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                    React.createElement("p", null,
                        React.createElement("span", null, paymentDueDate))));
        });
    };
    CreditBox.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('identifier'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('currentTotalValue'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('paymentDeadline'))));
    };
    CreditBox.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("customer-partner.credit-box.unpaid-orders.table.header.".concat(p)),
            property: p
        };
    };
    CreditBox.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _b.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _b.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    return CreditBox;
}(abstract_component_1.AbstractComponent));
exports.CreditBox = CreditBox;
