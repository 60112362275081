"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendedExtraAttributeField = void 0;
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var dropdown_1 = require("@codebuild/sprinkles/modules/codebuild/drpodown/react/dropdown");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var user_permission_1 = require("../../../../common/libs/user-permission");
var ExtendedExtraAttributeField = (function (_super) {
    __extends(ExtendedExtraAttributeField, _super);
    function ExtendedExtraAttributeField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            productCategory: _this.props.productCategory,
            productType: _this.props.productType,
            attribute: _this.props.attribute,
            editorLanguage: _this.props.editorLanguage || 'hu'
        };
        return _this;
    }
    ExtendedExtraAttributeField.prototype.render = function () {
        return React.createElement("div", { className: "w-100 display-flex align-items-center" },
            this.renderInput(),
            this.generateInfoBox());
    };
    ExtendedExtraAttributeField.prototype.generateInfoBox = function () {
        var _a, _b, _c, _d;
        var description = ((_b = (_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a.infoByCategory) === null || _b === void 0 ? void 0 : _b[this.state.productCategory]) || ((_d = (_c = this.state.attribute) === null || _c === void 0 ? void 0 : _c.infoByCategory) === null || _d === void 0 ? void 0 : _d.common);
        if (!description) {
            return null;
        }
        return React.createElement("div", { className: "px-2" },
            React.createElement(dropdown_1.Dropdown, { renderTrigger: function () { return React.createElement("i", { className: 'cursor-pointer fas fa-info-circle' }); }, triggerType: 'hover', renderContent: function () { return React.createElement("div", { className: 'p-2 drop-shadow AttributeInfoBox' },
                    React.createElement("small", null, description)); } }));
    };
    ExtendedExtraAttributeField.prototype.renderInput = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        switch ((_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a.inputType) {
            case 'text':
                return React.createElement(form_control_1.FormControl, { field: this.props.field, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'text', disabled: this.props.disabled || !!((_b = this.state.attribute) === null || _b === void 0 ? void 0 : _b.readonly) || !(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read((_c = this.state.attribute) === null || _c === void 0 ? void 0 : _c.enableEdit), false)), className: 'text-input-basic w-100' }));
            case 'number':
                return React.createElement(form_control_1.FormControl, { field: this.props.field, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', disabled: this.props.disabled || !!((_d = this.state.attribute) === null || _d === void 0 ? void 0 : _d.readonly) || !(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read((_e = this.state.attribute) === null || _e === void 0 ? void 0 : _e.enableEdit), false)), className: 'text-input-basic w-100' }));
            case 'select':
                return React.createElement(form_control_1.FormControl, { field: this.props.field, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: false, unclearable: false, disabled: this.props.disabled || !!((_f = this.state.attribute) === null || _f === void 0 ? void 0 : _f.readonly) || !(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read((_g = this.state.attribute) === null || _g === void 0 ? void 0 : _g.enableEdit), false)), className: 'select-input-basic w-100' }));
            case 'multiselect':
                return React.createElement(form_control_1.FormControl, { field: this.props.field, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, unclearable: false, disabled: this.props.disabled || !!((_h = this.state.attribute) === null || _h === void 0 ? void 0 : _h.readonly) || !(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read((_j = this.state.attribute) === null || _j === void 0 ? void 0 : _j.enableEdit), false)), className: 'select-input-basic w-100' }));
        }
    };
    return ExtendedExtraAttributeField;
}(abstract_component_1.AbstractComponent));
exports.ExtendedExtraAttributeField = ExtendedExtraAttributeField;
