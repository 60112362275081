"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDetailsScreen = exports.OrderStatus = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./order-details.screen.scss");
var permissions_1 = require("../../../common/libs/permissions/permissions");
var permission_wrapper_1 = require("../../../components/permission-wrapper");
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["inProgress"] = "inProgress";
    OrderStatus["approved"] = "approved";
    OrderStatus["prepared"] = "prepared";
    OrderStatus["underDelivery"] = "underDelivery";
    OrderStatus["delivered"] = "delivered";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var OrderDetailsScreen = (function (_super) {
    __extends(OrderDetailsScreen, _super);
    function OrderDetailsScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            order: null,
            error: null,
            loading: false
        };
        return _this;
    }
    OrderDetailsScreen.prototype.componentDidMount = function () {
        this.fetchOrder();
    };
    OrderDetailsScreen.prototype.fetchOrder = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var order, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 6]);
                        return [4, this.setState({ loading: true })];
                    case 1:
                        _c.sent();
                        return [4, this.repository.get("/admin/order/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.orderId))];
                    case 2:
                        order = _c.sent();
                        return [4, this.setState({ order: order, loading: false })];
                    case 3:
                        _c.sent();
                        return [3, 6];
                    case 4:
                        e_1 = _c.sent();
                        return [4, this.setState({ loading: false })];
                    case 5:
                        _c.sent();
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    OrderDetailsScreen.prototype.render = function () {
        var _a, _b;
        return React.createElement("div", { className: 'OrderDetailsScreen  justify-content-center w-100' },
            React.createElement("h2", { className: 'mb-6' }, (_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.identifier) !== null && _b !== void 0 ? _b : '-',
                " ",
                (0, trans_1.trans)('order-details.screen.title')),
            this.renderOrderDetails());
    };
    OrderDetailsScreen.prototype.renderOrderDetails = function () {
        return React.createElement("div", { className: 'OrderDetails row w-100' },
            React.createElement("div", { className: "col-18 mb-4" }, this.renderCustomerData()),
            React.createElement("div", { className: "col-6 mb-4" }, this.renderOrderStatusBox()),
            React.createElement("div", { className: "col-9 mb-4" }, this.renderShippingAddress()),
            React.createElement("div", { className: "col-9 mb-4" }, this.renderBillingAddress()),
            React.createElement("div", { className: "col-24 mb-4" }, this.renderProducts()));
    };
    OrderDetailsScreen.prototype.renderBillingAddress = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.billing-address.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.name'),
                        ":"),
                    React.createElement("span", null, ((_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.billingAddress) === null || _b === void 0 ? void 0 : _b.billingName) || "".concat(((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.customerFirstName) || '', " ").concat(((_d = this.state.order) === null || _d === void 0 ? void 0 : _d.customerLastName) || ''))),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.address'),
                        ":"),
                    React.createElement("span", null, this.parseAddress((_e = this.state.order) === null || _e === void 0 ? void 0 : _e.billingAddress))),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.taxNumber'),
                        ":"),
                    React.createElement("span", null, ((_g = (_f = this.state.order) === null || _f === void 0 ? void 0 : _f.billingAddress) === null || _g === void 0 ? void 0 : _g.taxNumber) || '-'))));
    };
    OrderDetailsScreen.prototype.renderShippingAddress = function () {
        var _a, _b, _c;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.shipping-address.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.shipping-address.address'),
                        ":"),
                    React.createElement("span", null, this.parseAddress((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.shippingAddress))),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.shipping-address.comment'),
                        ":"),
                    React.createElement("p", null, ((_c = (_b = this.state.order) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.comment) || '-'))));
    };
    OrderDetailsScreen.prototype.parseAddress = function (address) {
        return "".concat(address === null || address === void 0 ? void 0 : address.zipCode, " ").concat(address === null || address === void 0 ? void 0 : address.city, " ").concat(address === null || address === void 0 ? void 0 : address.address);
    };
    OrderDetailsScreen.prototype.renderOrderStatusBox = function () {
        return React.createElement("div", { className: 'StatusBox' },
            React.createElement("div", { className: 'mb-2' },
                React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.ORDERS.b2cOrders.update }, this.renderNextStatusButton())),
            React.createElement("span", { className: 'mb-2' }, (0, trans_1.trans)('order.status.title')),
            React.createElement("div", { className: 'display-flex flex-column position-relative pl-4' },
                this.renderStatusIndicator(OrderStatus.inProgress),
                this.renderStatusIndicator(OrderStatus.approved),
                this.renderStatusIndicator(OrderStatus.prepared),
                this.renderStatusIndicator(OrderStatus.underDelivery),
                this.renderStatusIndicator(OrderStatus.delivered)));
    };
    OrderDetailsScreen.prototype.renderStatusIndicator = function (status) {
        var _a;
        var currentIndex = Object.keys(OrderStatus).indexOf((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.status);
        var isFulfilledStatus = Object.keys(OrderStatus).indexOf(status) <= currentIndex;
        return React.createElement("div", { className: "mb-2 ".concat(isFulfilledStatus ? 'fulfilled' : '') },
            React.createElement("small", { className: 'display-flex align-items-center' },
                React.createElement("div", { className: 'dot mr-2' }),
                (0, trans_1.trans)("order.status.".concat(status))));
    };
    OrderDetailsScreen.prototype.renderNextStatusButton = function () {
        var _this = this;
        var _a;
        var currentIndex = Object.keys(OrderStatus).indexOf((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.status);
        var isLastStatus = currentIndex === Object.keys(OrderStatus).length - 1;
        var nextStatus = isLastStatus ? Object.values(OrderStatus)[currentIndex] : Object.values(OrderStatus)[currentIndex + 1];
        return React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal status-set-button', title: this.state.loading ? React.createElement("i", { className: "fas fa-spinner-third fa-spin" }) : (0, trans_1.trans)("order.status.".concat(nextStatus)), disabled: isLastStatus, onClick: function () { return _this.setNextStatus(nextStatus); } });
    };
    OrderDetailsScreen.prototype.setNextStatus = function (status) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var order, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 6]);
                        return [4, this.setState({ loading: true })];
                    case 1:
                        _c.sent();
                        return [4, this.repository.put("/admin/order/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.orderId), {
                                data: {
                                    reason: {
                                        message: status,
                                    }
                                }
                            })];
                    case 2:
                        order = _c.sent();
                        return [4, this.setState({ order: order, loading: false })];
                    case 3:
                        _c.sent();
                        return [3, 6];
                    case 4:
                        e_2 = _c.sent();
                        return [4, this.setState({ loading: false })];
                    case 5:
                        _c.sent();
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    OrderDetailsScreen.prototype.renderProducts = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.products.subtitle')),
            React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderProductsTableHeader(); }, renderBody: function () {
                    var _a, _b, _c, _d;
                    return [
                        (_b = (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a.productItems) === null || _b === void 0 ? void 0 : _b.map(function (item) { return _this.renderProductItem(item); }),
                        (_d = (_c = _this.state.order) === null || _c === void 0 ? void 0 : _c.giftItems) === null || _d === void 0 ? void 0 : _d.map(function (item) { return _this.renderGiftItem(item); })
                    ];
                } }),
            ((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.shippingType) === 'delivery' && React.createElement("div", { className: 'w-100 display-flex justify-content-start' },
                React.createElement("strong", { className: 'mr-4' },
                    (0, trans_1.trans)('order-details.screen.delivery-title'),
                    ":"),
                React.createElement("span", null, (((_b = this.state.order) === null || _b === void 0 ? void 0 : _b.shippingTotalGross) > 0) ? (0, currency_parser_1.moneyFormat)((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.shippingTotalGross, 'HUF') : 'Ingyenes')),
            React.createElement("div", null, this.renderDiscounts((_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.order) === null || _e === void 0 ? void 0 : _e.discountItems)),
            React.createElement("div", null, this.renderShippingItems((_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.order) === null || _g === void 0 ? void 0 : _g.shippingItems)),
            React.createElement("div", { className: 'w-100 display-flex justify-content-start' },
                React.createElement("strong", { className: 'mr-4' },
                    (0, trans_1.trans)('order-details.screen.order-sum-prize'),
                    ":"),
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.order) === null || _j === void 0 ? void 0 : _j.grandTotalGross, (_l = (_k = this.state) === null || _k === void 0 ? void 0 : _k.order) === null || _l === void 0 ? void 0 : _l.currency))));
    };
    OrderDetailsScreen.prototype.renderCustomerData = function () {
        var _a, _b, _c, _d;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.customer-data.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.name'),
                        ":"),
                    React.createElement("span", null, (_a = this.state.order) === null || _a === void 0 ? void 0 :
                        _a.customerLastName,
                        " ", (_b = this.state.order) === null || _b === void 0 ? void 0 :
                        _b.customerFirstName)),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.phoneNumber'),
                        ":"),
                    React.createElement("span", null, (_c = this.state.order) === null || _c === void 0 ? void 0 : _c.customerPhone)),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.email'),
                        ":"),
                    React.createElement("span", null, (_d = this.state.order) === null || _d === void 0 ? void 0 : _d.customerEmail))));
    };
    OrderDetailsScreen.prototype.renderDiscounts = function (items) {
        var _this = this;
        if (!items || !items.length) {
            return React.createElement("div", null);
        }
        return React.createElement("div", { className: 'w-100 display-flex flex-column justify-content-start' },
            React.createElement("strong", { className: 'mr-4' },
                (0, trans_1.trans)('order-details.screen.discounts'),
                ":"),
            items.map(function (i, index) {
                return React.createElement("div", { className: 'pl-4', key: index },
                    React.createElement("span", { className: 'mr-2' }, _this.getDiscountTitle(i === null || i === void 0 ? void 0 : i.title)),
                    React.createElement("span", null, (0, currency_parser_1.moneyFormat)(i === null || i === void 0 ? void 0 : i.gross, i === null || i === void 0 ? void 0 : i.currency)));
            }));
    };
    OrderDetailsScreen.prototype.renderShippingItems = function (items) {
        var _this = this;
        if (!items || !items.length) {
            return React.createElement("div", null);
        }
        return React.createElement("div", { className: 'w-100 display-flex flex-column justify-content-start' },
            React.createElement("strong", { className: 'mr-4' }, "Sz\u00E1ll\u00EDt\u00E1si inform\u00E1ci\u00F3k:"),
            items.map(function (i, index) {
                return React.createElement("div", { className: 'pl-4', key: index },
                    React.createElement("span", { className: 'mr-2' }, _this.getShippingItemTitle(i === null || i === void 0 ? void 0 : i.title)),
                    React.createElement("span", null, (0, currency_parser_1.moneyFormat)(i === null || i === void 0 ? void 0 : i.gross, i === null || i === void 0 ? void 0 : i.currency)));
            }));
    };
    OrderDetailsScreen.prototype.getShippingItemTitle = function (title) {
        switch (title) {
            case 'in-store':
                return 'Személyes átvétel';
            case 'delivery-fee':
                return 'Szállítási díj';
            case 'cod-fee':
                return 'Utánvétel díja';
            default:
                return title;
        }
    };
    OrderDetailsScreen.prototype.getDiscountTitle = function (title) {
        switch (title) {
            case 'customer-discount':
                return 'Vásárlói kedvezmény';
            default:
                return title;
        }
    };
    OrderDetailsScreen.prototype.setHeaderCell = function (p) {
        return {
            sortValue: null,
            enableSort: true,
            title: (0, trans_1.trans)("order-details.screen.product.table.header.".concat(p)),
            property: p
        };
    };
    OrderDetailsScreen.prototype.renderProductsTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('manufacturer'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('vintage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('originalPrize'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('customerPrize'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('sumPrize'))));
    };
    OrderDetailsScreen.prototype.renderProductItem = function (item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        var unitPrice = (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.grossOriginal) !== null && _c !== void 0 ? _c : 0;
        var originalPrice = (_d = unitPrice * (item === null || item === void 0 ? void 0 : item.amount)) !== null && _d !== void 0 ? _d : 0;
        var appliedDiscount = (_e = item === null || item === void 0 ? void 0 : item.appliedDiscountItemsOnlyForRenderingFrontend) === null || _e === void 0 ? void 0 : _e.reduce(function (acc, obj) {
            return acc + (obj === null || obj === void 0 ? void 0 : obj.gross);
        }, 0);
        var countedPrice = (_f = (originalPrice + appliedDiscount) / (item === null || item === void 0 ? void 0 : item.amount)) !== null && _f !== void 0 ? _f : 0;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_g = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _g === void 0 ? void 0 : _g.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_k = (_j = (_h = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _h === void 0 ? void 0 : _h.manufacturer) === null || _j === void 0 ? void 0 : _j.name) === null || _k === void 0 ? void 0 : _k.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_m = (_l = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _l === void 0 ? void 0 : _l.title) === null || _m === void 0 ? void 0 : _m.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_o = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _o === void 0 ? void 0 : _o.vintage)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.amount,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)(unitPrice, item === null || item === void 0 ? void 0 : item.currency))),
            ((_q = (_p = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _p === void 0 ? void 0 : _p.price) === null || _q === void 0 ? void 0 : _q.isSale)
                ? [
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20, key: item._id + 1 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)((_s = (_r = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _r === void 0 ? void 0 : _r.price) === null || _s === void 0 ? void 0 : _s.gross, item === null || item === void 0 ? void 0 : item.currency))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20, key: item._id + 2 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)((item === null || item === void 0 ? void 0 : item.amount) * ((_u = (_t = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _t === void 0 ? void 0 : _t.price) === null || _u === void 0 ? void 0 : _u.gross), item === null || item === void 0 ? void 0 : item.currency)))
                ]
                : [
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20, key: item._id + 1 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)(countedPrice, item === null || item === void 0 ? void 0 : item.currency))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20, key: item._id + 2 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)((item === null || item === void 0 ? void 0 : item.amount) * (countedPrice), item === null || item === void 0 ? void 0 : item.currency)))
                ]);
    };
    OrderDetailsScreen.prototype.renderGiftItem = function (item) {
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _a === void 0 ? void 0 : _a.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_d = (_c = (_b = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _b === void 0 ? void 0 : _b.manufacturer) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_f = (_e = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_g = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _g === void 0 ? void 0 : _g.vintage)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.amount,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((item === null || item === void 0 ? void 0 : item.gross), item === null || item === void 0 ? void 0 : item.currency))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((item === null || item === void 0 ? void 0 : item.net), item === null || item === void 0 ? void 0 : item.currency))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.moneyFormat)((item === null || item === void 0 ? void 0 : item.gross), item === null || item === void 0 ? void 0 : item.currency))));
    };
    OrderDetailsScreen.prototype.calcProductPrice = function (product) {
        var _a, _b;
        var discount = (0, lodash_1.sumBy)(product === null || product === void 0 ? void 0 : product.appliedDiscountItemsOnlyForRenderingFrontend, function (i) { return i.grossOriginalUnit; });
        return (((_b = (_a = product === null || product === void 0 ? void 0 : product.productArtifact) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.gross) + discount);
    };
    return OrderDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.OrderDetailsScreen = OrderDetailsScreen;
