"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = {
    'stock-history.main.title': 'Készlet történet',
    'b2b-stock-history.filters.title': 'SZŰRÉSEK',
    'b2b-stock-history.filter.form.label.product': 'Termékek',
    'b2b-stock-history.filter.form.label.lot': 'LOT/NÉBIH',
    'b2b-stock-history.filter.form.label.storage': 'Tárhely',
    'b2b-stock-history.filter.form.label.action': 'Esemény',
    'b2b-stock-history.filter.form.label.vtsz': 'KN kód/vtsz',
    'b2b-stock-history.filter.form.label.jovedekiFajta': 'Jövedéki fajta kód',
    'b2b-stock-history.filter.form.label.warehouse': 'Raktár',
    'b2b-stock-history.filter.form.label.country': 'Vevőcég székhely országa',
    'b2b-stock-history.filter.form.label.date': 'Dátum',
    'b2b-stock-history.filter.form.label.customerPartner': 'Vevő partner',
    'b2b-stock-history.filter.form.label.supplierPartner': 'Beszállító partner',
    'b2b-stock-history.table.header.ean': 'termék',
    'b2b-stock-history.table.header.lot': 'lot/nébih',
    'b2b-stock-history.table.header.storage': 'tárhely',
    'b2b-stock-history.table.header.action': 'esemény',
    'b2b-stock-history.table.header.start': 'nyitás',
    'b2b-stock-history.table.header.issuePartner': 'partner',
    'b2b-stock-history.table.header.difference': 'változás (DB)',
    'b2b-stock-history.table.header.differenceHlt': 'változás (jövedéki)',
    'b2b-stock-history.table.header.end': 'zárás',
    'b2b-stock-history.table.header.creator': 'létrehozó',
    'b2b-stock-history.table.header.createdAt': 'létrehozva',
    'b2b-stock-history.action.default': 'default',
    'b2b-stock-history.action.income': 'BETÁROLÁS',
    'b2b-stock-history.action.issuing': 'KITÁROLÁS',
    'b2b-stock-history.action.dispose': 'SELEJTEZÉS',
    'b2b-stock-history.action.transaction': 'MOZGATÁS',
};
