"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierPricingTableRow = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var moment = require("moment");
var React = require("react");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var permissions_1 = require("../../../../common/libs/permissions/permissions");
var permission_wrapper_1 = require("../../../../components/permission-wrapper");
var SupplierPricingTableRow = (function (_super) {
    __extends(SupplierPricingTableRow, _super);
    function SupplierPricingTableRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            date: _this.props.date,
            item: _this.props.item,
        };
        return _this;
    }
    SupplierPricingTableRow.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.props.date !== prevProps.date) {
            return this.setState({ date: this.props.date });
        }
        if (this.props.item !== prevProps.item) {
            return this.setState({ item: this.props.item });
        }
    };
    SupplierPricingTableRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var ean = ((_a = this.props.item) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = this.props.item) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = this.props.item) === null || _c === void 0 ? void 0 : _c.manufacturerName) === null || _d === void 0 ? void 0 : _d.hu) || '-';
        var title = ((_f = (_e = this.props.item) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.hu) || '-';
        var vintage = ((_g = this.props.item) === null || _g === void 0 ? void 0 : _g.vintage) || '-';
        var capacity = ((_k = (_j = (_h = this.props.item) === null || _h === void 0 ? void 0 : _h.b2cAttributeSearchValues) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k[1]) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, null,
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'align-items-start' },
                React.createElement("p", null, (_m = (_l = this.props.item) === null || _l === void 0 ? void 0 : _l.b2cCategoryName) === null || _m === void 0 ? void 0 : _m.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 50 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title),
                    React.createElement("p", null,
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: 'align-items-start justify-content-end' },
                React.createElement("p", null, this.getPriceByDate(this.state.date))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'align-items-start' },
                React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.PRICING.supplierPricing.update },
                    React.createElement(button_1.Button, { onClick: function () { return _this.props.openModal(_this.state.item._id); }, title: (0, trans_1.trans)('b2b-supplier-pricing.modal.button.open'), iconLeft: 'fas fa-pencil', className: 'button-neutral-normal button-size-normal' }))));
    };
    SupplierPricingTableRow.prototype.getPriceByDate = function (date) {
        var _a, _b, _c, _d;
        var price = (_d = (_c = (_b = (_a = this.props.item) === null || _a === void 0 ? void 0 : _a.supplierPrice) === null || _b === void 0 ? void 0 : _b.priceData) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.find(function (priceItem) {
            if ((!!priceItem.from && !!priceItem.to) && (moment(date).isSameOrAfter(priceItem.from, 'day') && moment(date).isSameOrBefore(priceItem.to, 'day'))) {
                return priceItem;
            }
            if ((!!priceItem.from && !priceItem.to) && moment(date).isSameOrAfter(priceItem.from, 'day')) {
                return priceItem;
            }
        });
        return (price === null || price === void 0 ? void 0 : price.value) ? "".concat((0, currency_parser_1.splitToMoney)(price === null || price === void 0 ? void 0 : price.value), " Ft") : 'nincs érvényes ár';
    };
    return SupplierPricingTableRow;
}(abstract_component_1.AbstractComponent));
exports.SupplierPricingTableRow = SupplierPricingTableRow;
