"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bOrderUpdateScreen = void 0;
var sleep_1 = require("@codebuild/glaze/libs/libs/sleep");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var currency_parser_1 = require("../../common/libs/currency-parser");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var permissions_1 = require("../../common/libs/permissions/permissions");
var user_permission_1 = require("../../common/libs/user-permission");
var form_1 = require("../../components/form/form");
var badge_1 = require("../../components/libs/badge/badge");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var progress_bar_1 = require("../../components/progress-bar/progress-bar");
var b2b_order_list_screen_1 = require("./b2b-order-list.screen");
require("./b2b-order.scss");
var add_product_modal_1 = require("./components/add-product.modal");
var allow_zero_for_fact_modal_1 = require("./components/allow-zero-for-fact.modal");
var allow_zero_for_lock_modal_1 = require("./components/allow-zero-for-lock.modal");
var b2b_order_product_table_row_1 = require("./components/b2b-order-product-table-row");
var cancel_order_modal_1 = require("./components/cancel-order.modal");
var delete_draft_modal_1 = require("./components/delete-draft.modal");
var delete_product_modal_1 = require("./components/delete-product.modal");
var modify_order_modal_1 = require("./components/modify-order.modal");
var total_amount_1 = require("./components/total-amount");
var B2bOrderUpdateScreen = (function (_super) {
    __extends(B2bOrderUpdateScreen, _super);
    function B2bOrderUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.productsRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            products: [],
            order: {},
            fifoPlan: {},
            loading: false,
            shippingChargeOptions: {},
            shippingCharge: 0,
            error: '',
            zipCode: '',
            user: null,
            readOnly: true,
            allowZeroForLock: false,
            isFreeShipping: false,
            allowZeroForFact: false,
            issueIsLoading: false,
            noticeIsLoading: false,
            deliveryNotes: [],
            shouldRevertStatus: false,
            hasPlanExactPrice: false,
            isTakeoutSheetLoading: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerPartner: new field_1.Field({
                    name: 'customerPartner',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.customer-partner')),
                    value: '',
                }),
                shippingData: new field_1.Field({
                    name: 'shippingData',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('b2b-order.form.label.shipping-data'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    options: [],
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
                customOrderNumber: new field_1.Field({
                    name: 'customOrderNumber',
                    id: 'customOrderNumber',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.customOrderNumber')),
                    validators: []
                }),
                goodsReceiptId: new field_1.Field({
                    name: 'goodsReceiptId',
                    id: 'goodsReceiptId',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.goodsReceiptId')),
                    validators: []
                }),
                comment: new field_1.Field({
                    name: 'comment',
                    id: 'comment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.comment')),
                    validators: []
                }),
                publicComment: new field_1.Field({
                    name: 'publicComment',
                    id: 'publicComment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.publicComment')),
                    validators: []
                }),
                issuingPurpose: new field_1.Field({
                    name: 'issuingPurpose',
                    id: 'issuingPurpose',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.issuingPurpose')),
                    validators: []
                }),
                legalCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.cp.form.legalCode.label')),
                    options: issuing_legal_codes_1.ISSUING_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                cancelReason: new field_1.Field({
                    name: 'cancelReason',
                    id: 'cancelReason',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.cancelReason')),
                    validators: []
                }),
                isPlanUpdateNeeded: new field_1.Field({
                    name: 'isPlanUpdateNeeded',
                    id: 'isPlanUpdateNeeded',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.isPlanUpdateNeeded')),
                    validators: []
                }),
                licensePlateNumber: new field_1.Field({
                    name: 'licensePlateNumber',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.licensePlateNumber')),
                    value: '',
                    validators: []
                }),
                plannedDeliveryDate: new field_1.Field({
                    name: 'plannedDeliveryDate',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.plannedDeliveryDate')),
                }),
                isForeign: new field_1.Field({
                    name: 'isForeign',
                    id: 'isForeign',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.isForeign')),
                    validators: []
                }),
                isFreeShipping: new field_1.Field({
                    name: 'isFreeShipping',
                    id: 'isFreeShipping',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.isFreeShipping')),
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bOrderUpdateScreen.prototype.componentDidMount = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return __awaiter(this, void 0, void 0, function () {
            var user;
            var _this = this;
            return __generator(this, function (_w) {
                switch (_w.label) {
                    case 0: return [4, this.fetchShippingCharges()];
                    case 1:
                        _w.sent();
                        return [4, local_store_1.LocalStore.getItem('User')];
                    case 2:
                        user = _w.sent();
                        this.setState({ user: user });
                        return [4, this.fetchOrder()];
                    case 3:
                        _w.sent();
                        void this.setUpCache(this.state.order);
                        this.setState({ isFreeShipping: (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.isFreeShipping });
                        return [4, this.setShippingDataOptions(this.state.order)];
                    case 4:
                        _w.sent();
                        this.form.fields.customerPartner.setValue("".concat((_d = (_c = (_b = this.state.order) === null || _b === void 0 ? void 0 : _b.extraData) === null || _c === void 0 ? void 0 : _c.customerPartner) === null || _d === void 0 ? void 0 : _d.name, " (").concat((_g = (_f = (_e = this.state.order) === null || _e === void 0 ? void 0 : _e.extraData) === null || _f === void 0 ? void 0 : _f.customerPartner) === null || _g === void 0 ? void 0 : _g.brandName, ")"));
                        this.form.fields.comment.setValue((_h = this.state.order) === null || _h === void 0 ? void 0 : _h.comment);
                        this.form.fields.customOrderNumber.setValue((_j = this.state.order) === null || _j === void 0 ? void 0 : _j.customOrderNumber);
                        this.form.fields.goodsReceiptId.setValue((_k = this.state.order) === null || _k === void 0 ? void 0 : _k.goodsReceiptId);
                        this.form.fields.publicComment.setValue((_l = this.state.order) === null || _l === void 0 ? void 0 : _l.publicComment);
                        this.form.fields.shippingData.setValue((_m = this.state.order) === null || _m === void 0 ? void 0 : _m.shippingData);
                        this.form.fields.isPlanUpdateNeeded.setValue((_o = this.state.order) === null || _o === void 0 ? void 0 : _o.isPlanUpdateNeeded);
                        this.form.fields.isFreeShipping.setValue((_p = this.state.order) === null || _p === void 0 ? void 0 : _p.isFreeShipping);
                        this.form.fields.cancelReason.setValue(((_q = this.state.order) === null || _q === void 0 ? void 0 : _q.reasonForCancel) || '');
                        this.form.fields.issuingPurpose.setValue(((_r = this.state.order) === null || _r === void 0 ? void 0 : _r.issuingPurpose) || '');
                        this.form.fields.legalCode.setValue(((_s = this.state.order) === null || _s === void 0 ? void 0 : _s.legalCode) || '');
                        this.form.fields.plannedDeliveryDate.setValue(((_t = this.state.order) === null || _t === void 0 ? void 0 : _t.plannedDeliveryDate) || '');
                        this.form.fields.licensePlateNumber.setValue(((_u = this.state.order) === null || _u === void 0 ? void 0 : _u.licensePlateNumber) || '');
                        this.form.fields.isForeign.setValue(((_v = this.state.order) === null || _v === void 0 ? void 0 : _v.isForeign) || '');
                        this.$subscriptions.push(this.form.fields.customerPartner.$value.subscribe(function () { return _this.setUpCache(_this.state.order); }), this.form.fields.isFreeShipping.$value.subscribe(function (isFreeShipping) { return _this.setState({ isFreeShipping: isFreeShipping }); }), this.form.fields.shippingData.$value.subscribe(function (shippingData) { return _this.setState({
                            zipCode: shippingData === null || shippingData === void 0 ? void 0 : shippingData.zipCode
                        }); }));
                        this.setState({
                            products: this.state.order.plan
                        });
                        return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_y) {
                switch (_y.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(this.state.order, prevState.order)) return [3, 3];
                        this.setState({ products: this.state.order.plan });
                        this.setHasPlanExactPrice();
                        return [4, this.setShippingDataOptions(this.state.order)];
                    case 1:
                        _y.sent();
                        void this.setUpCache(this.state.order);
                        this.setState({
                            zipCode: (_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.shippingData) === null || _b === void 0 ? void 0 : _b.zipCode,
                            isFreeShipping: (_c = this.state.order) === null || _c === void 0 ? void 0 : _c.isFreeShipping
                        });
                        this.form.fields.isFreeShipping.setValue((_d = this.state.order) === null || _d === void 0 ? void 0 : _d.isFreeShipping);
                        this.form.fields.customerPartner.setValue("".concat((_g = (_f = (_e = this.state.order) === null || _e === void 0 ? void 0 : _e.extraData) === null || _f === void 0 ? void 0 : _f.customerPartner) === null || _g === void 0 ? void 0 : _g.name, " (").concat((_k = (_j = (_h = this.state.order) === null || _h === void 0 ? void 0 : _h.extraData) === null || _j === void 0 ? void 0 : _j.customerPartner) === null || _k === void 0 ? void 0 : _k.brandName, ")"));
                        this.form.fields.comment.setValue((_l = this.state.order) === null || _l === void 0 ? void 0 : _l.comment);
                        this.form.fields.publicComment.setValue((_m = this.state.order) === null || _m === void 0 ? void 0 : _m.publicComment);
                        this.form.fields.goodsReceiptId.setValue((_o = this.state.order) === null || _o === void 0 ? void 0 : _o.goodsReceiptId);
                        this.form.fields.customOrderNumber.setValue((_p = this.state.order) === null || _p === void 0 ? void 0 : _p.customOrderNumber);
                        this.form.fields.isPlanUpdateNeeded.setValue((_q = this.state.order) === null || _q === void 0 ? void 0 : _q.isPlanUpdateNeeded);
                        this.form.fields.cancelReason.setValue(((_r = this.state.order) === null || _r === void 0 ? void 0 : _r.reasonForCancel) || '');
                        this.form.fields.issuingPurpose.setValue(((_s = this.state.order) === null || _s === void 0 ? void 0 : _s.issuingPurpose) || '');
                        this.form.fields.legalCode.setValue(((_t = this.state.order) === null || _t === void 0 ? void 0 : _t.legalCode) || '');
                        this.form.fields.plannedDeliveryDate.setValue(((_u = this.state.order) === null || _u === void 0 ? void 0 : _u.plannedDeliveryDate) || '');
                        this.form.fields.licensePlateNumber.setValue(((_v = this.state.order) === null || _v === void 0 ? void 0 : _v.licensePlateNumber) || '');
                        if (this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.casePicked.status) {
                            this.form.fields.issuingPurpose.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            this.form.fields.issuingPurpose.label = React.createElement("div", null,
                                (0, trans_1.trans)('b2b-order.form.label.issuingPurpose'),
                                " ",
                                React.createElement("i", { className: 'color--error-6' }, "*"));
                            this.form.fields.licensePlateNumber.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            this.form.fields.licensePlateNumber.label = React.createElement("div", null,
                                (0, trans_1.trans)('b2b-order.form.label.licensePlateNumber'),
                                " ",
                                React.createElement("i", { className: 'color--error-6' }, "*"));
                            if ((_x = (_w = this.state.order) === null || _w === void 0 ? void 0 : _w.extraData) === null || _x === void 0 ? void 0 : _x.hasTaxArea) {
                                this.form.fields.legalCode.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                                this.form.fields.legalCode.label = React.createElement("div", null,
                                    (0, trans_1.trans)('stock-issuing.create.cp.form.legalCode.label'),
                                    " ",
                                    React.createElement("i", { className: 'color--error-6' }, "*"));
                            }
                        }
                        if (!!!this.state.order.issue) return [3, 3];
                        return [4, this.fetchIssue()];
                    case 2:
                        _y.sent();
                        _y.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.setShippingDataOptions = function (order) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var list, shippingData, operatingPermitNumber;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        list = ((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.extraData) === null || _a === void 0 ? void 0 : _a.customerPartner) === null || _b === void 0 ? void 0 : _b.shippingDatas) === null || _c === void 0 ? void 0 : _c.map(function (item) {
                            var permitNumber = item.operatingPermitNumber ? "(".concat(item.operatingPermitNumber, ")") : '';
                            return {
                                name: "".concat(item.zipCode, " ").concat(item.city, ", ").concat(item.address, " ").concat(permitNumber),
                                value: item,
                                key: item.name
                            };
                        })) || [];
                        shippingData = order === null || order === void 0 ? void 0 : order.shippingData;
                        operatingPermitNumber = shippingData.operatingPermitNumber ? "(".concat(shippingData.operatingPermitNumber, ")") : '';
                        list = __spreadArray(__spreadArray([], __read(list), false), [
                            {
                                name: "".concat(shippingData.zipCode, " ").concat(shippingData.city, ", ").concat(shippingData.address, " ").concat(operatingPermitNumber),
                                value: shippingData,
                                key: shippingData.name
                            }
                        ], false);
                        return [4, this.form.fields.shippingData.setOptions(list)];
                    case 1:
                        _d.sent();
                        return [4, this.form.fields.shippingData.setValue(shippingData)];
                    case 2:
                        _d.sent();
                        return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.fetchOrder = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var order, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/b2b/order/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        order = _c.sent();
                        this.setState({ order: order });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        console.log(e_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.setHasPlanExactPrice = function () {
        var _a, _b;
        var has = !!((_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.plan) === null || _b === void 0 ? void 0 : _b.find(function (p) { return !!p.exactPrice; }));
        this.setState({ hasPlanExactPrice: has });
    };
    B2bOrderUpdateScreen.prototype.fetchIssue = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var issues, deliveryNotes, e_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.setState({ issueIsLoading: true });
                        return [4, Promise.all((_a = this.state.order.issue) === null || _a === void 0 ? void 0 : _a.map(function (issue) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    return [2, this.repository.get("/b2b/stock-issuing/".concat(issue))];
                                });
                            }); }))];
                    case 1:
                        issues = _b.sent();
                        deliveryNotes = issues.map(function (i) {
                            var _a, _b;
                            if (!(i === null || i === void 0 ? void 0 : i.deliveryNoteIssued)) {
                                return false;
                            }
                            return (_b = (_a = i === null || i === void 0 ? void 0 : i.deliveryNoteResponse) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.payload;
                        });
                        this.setState({ deliveryNotes: deliveryNotes });
                        return [3, 4];
                    case 2:
                        e_2 = _b.sent();
                        console.log(e_2);
                        return [3, 4];
                    case 3:
                        this.setState({ issueIsLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return React.createElement("div", { className: 'B2bOrderScreen B2bOrderUpdateScreen' },
            this.renderModals(),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-19' },
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/b2b-order'); } })),
                    React.createElement("div", { className: 'mb-4 display-flex' },
                        React.createElement("h3", { className: 'fw-600 mr-6' }, (_a = this.state.order) === null || _a === void 0 ? void 0 :
                            _a.identifier,
                            " ",
                            (0, trans_1.trans)('b2b-order.update.title')),
                        this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.canceled.status
                            && React.createElement(badge_1.Badge, { title: 'TÖRÖLVE', type: 'error', className: 'typo-h5' }),
                        this.state.hasPlanExactPrice
                            && React.createElement(badge_1.Badge, { title: 'EGYEDI ÁRAT TARTALMAZ', type: 'error', className: 'typo-h5' })),
                    this.state.order.status !== b2b_order_list_screen_1.b2bOrderStatus.canceled.status
                        && React.createElement(progress_bar_1.ProgressBar, { config: [
                                b2b_order_list_screen_1.b2bOrderStatus.draft.status,
                                b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status,
                                b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status,
                                b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status,
                                b2b_order_list_screen_1.b2bOrderStatus.casePicked.status,
                                b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status,
                                b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status,
                                b2b_order_list_screen_1.b2bOrderStatus.paid.status,
                            ], status: (_b = this.state.order) === null || _b === void 0 ? void 0 : _b.status, className: 'mb-4' }),
                    React.createElement(form_1.Form, null,
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.isForeign, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6', disabled: this.state.readOnly }))),
                        this.renderFreeShipping(),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartner, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12', readOnly: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.shippingData, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, className: 'select-input-basic col-12', readOnly: this.state.readOnly })),
                            this.state.order.status === 'financeApprovalNeeded'
                                && React.createElement(form_control_1.FormControl, { field: this.form.fields.isPlanUpdateNeeded, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: this.state.readOnly, className: 'checkbox-input-basic col-12' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.customOrderNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12', readOnly: this.state.readOnly })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.goodsReceiptId, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12', readOnly: this.state.readOnly }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.licensePlateNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { readOnly: this.state.readOnly, className: 'text-input-basic col-12' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.plannedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic col-12', readOnly: this.state.readOnly, settings: { enabledDateFrom: moment.utc().format('YYYY.MM.DD') }, type: 'date', locale: 'hu' }))),
                        ((_d = (_c = this.state.order) === null || _c === void 0 ? void 0 : _c.extraData) === null || _d === void 0 ? void 0 : _d.hasTaxArea) &&
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { readOnly: this.state.readOnly, unclearable: true, multiple: false, searchable: true, className: 'select-input-basic col-12' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.issuingPurpose, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: this.state.readOnly })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: this.state.readOnly })),
                            this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.canceled.status
                                && React.createElement(form_control_1.FormControl, { field: this.form.fields.cancelReason, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.publicComment, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: this.state.readOnly }))),
                        React.createElement("div", { className: 'row' }, this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.canceled.status
                            && React.createElement(form_control_1.FormControl, { field: this.form.fields.cancelReason, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: true })))),
                    this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && !((_g = (_f = (_e = this.state.order) === null || _e === void 0 ? void 0 : _e.extraData) === null || _f === void 0 ? void 0 : _f.partnerCreditInfo) === null || _g === void 0 ? void 0 : _g.hasCredit) && React.createElement("div", null,
                        React.createElement("h5", { className: 'mb-2' }, (0, trans_1.trans)('b2b-order.approval-needed.reason')),
                        React.createElement("p", null,
                            (0, trans_1.trans)('b2b-order.approval-needed.credit'),
                            ": ",
                            React.createElement("span", null, (0, currency_parser_1.moneyFormat)((_k = (_j = (_h = this.state.order) === null || _h === void 0 ? void 0 : _h.extraData) === null || _j === void 0 ? void 0 : _j.partnerCreditInfo) === null || _k === void 0 ? void 0 : _k.credit))),
                        React.createElement("p", { className: 'color--error-6' },
                            (0, trans_1.trans)('b2b-order.approval-needed.difference'),
                            ": ",
                            (0, currency_parser_1.moneyFormat)(Math.abs(((_o = (_m = (_l = this.state.order) === null || _l === void 0 ? void 0 : _l.extraData) === null || _m === void 0 ? void 0 : _m.partnerCreditInfo) === null || _o === void 0 ? void 0 : _o.credit) - (((_r = (_q = (_p = this.state.order) === null || _p === void 0 ? void 0 : _p.extraData) === null || _q === void 0 ? void 0 : _q.partnerCreditInfo) === null || _r === void 0 ? void 0 : _r.orderTotal) + ((_u = (_t = (_s = this.state.order) === null || _s === void 0 ? void 0 : _s.extraData) === null || _t === void 0 ? void 0 : _t.partnerCreditInfo) === null || _u === void 0 ? void 0 : _u.unpaidTotal))))))),
                React.createElement("div", { className: 'col-5 save-box-wrapper' }, this.renderSaveBox())),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' }, this.renderProductsTable())));
    };
    B2bOrderUpdateScreen.prototype.renderFreeShipping = function () {
        var _a, _b;
        var role = (_b = (_a = this.state.user) === null || _a === void 0 ? void 0 : _a.publicMeta) === null || _b === void 0 ? void 0 : _b.role;
        if (role !== user_permission_1.Role.storekeeper && role !== user_permission_1.Role.superadmin) {
            return null;
        }
        return React.createElement("div", { className: 'row' },
            React.createElement(form_control_1.FormControl, { field: this.form.fields.isFreeShipping, type: checkbox_form_control_type_1.CheckboxFormControlType },
                React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6', disabled: this.state.readOnly })));
    };
    B2bOrderUpdateScreen.prototype.renderProductsTable = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", null,
            React.createElement("div", { className: 'col mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('b2b-order.create.product-list.title')),
                (this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.draft.status || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status)
                    && React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.create.product-list.add.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.$showAddProductModal.open(); }, className: 'button-primary-inverze button-size-normal', disabled: this.state.readOnly
                            || (((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.status) !== b2b_order_list_screen_1.b2bOrderStatus.draft.status
                                && !(((_b = this.state.order) === null || _b === void 0 ? void 0 : _b.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && ((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.isPlanUpdateNeeded))) })),
            React.createElement(table_1.Table, { className: 'table-basic B2bOrderTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.products); } }),
            React.createElement(total_amount_1.TotalAmount, { onShippingCalculation: function (shippingCharge) { return _this.setState({ shippingCharge: shippingCharge }); }, shippingChargeOptions: this.state.shippingChargeOptions, prices: this.state.prices, zipCode: this.state.zipCode, isFreeShipping: this.state.isFreeShipping }));
    };
    B2bOrderUpdateScreen.prototype.handlePlanChange = function (productId, amount, price, isBestPrice, drs) {
        var _a, e_3, _b;
        var _c, _d, _e, _f;
        var productsLeft = this.state.products.map(function (i) { var _a; return (_a = i.productId) !== null && _a !== void 0 ? _a : i._id; });
        var prices = __assign(__assign({}, this.state.prices), (_a = {}, _a[productId] = {
            drs: drs,
            amount: +(amount !== null && amount !== void 0 ? amount : 0),
            price: +(price || ((_f = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.prices) === null || _d === void 0 ? void 0 : _d[productId]) === null || _e === void 0 ? void 0 : _e.bestPrice) !== null && _f !== void 0 ? _f : 0)),
            bestPrice: this.getBestPrice({ isBestPrice: isBestPrice, price: price, productId: productId })
        }, _a));
        var removedRedundantPrices = {};
        try {
            for (var productsLeft_1 = __values(productsLeft), productsLeft_1_1 = productsLeft_1.next(); !productsLeft_1_1.done; productsLeft_1_1 = productsLeft_1.next()) {
                var prodId = productsLeft_1_1.value;
                removedRedundantPrices[prodId] = prices[prodId];
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (productsLeft_1_1 && !productsLeft_1_1.done && (_b = productsLeft_1.return)) _b.call(productsLeft_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.setState({ prices: removedRedundantPrices });
    };
    B2bOrderUpdateScreen.prototype.renderItems = function (items) {
        var _this = this;
        if (!(items === null || items === void 0 ? void 0 : items.length)) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var _a, _b, _c, _d, _e, _f, _g;
            return React.createElement(b2b_order_product_table_row_1.B2bOrderProductTableRow, { onPlanChange: function (amount, price, isBestPrice, drs) { return _this.handlePlanChange(item.productId, amount, price, isBestPrice, drs); }, key: (item === null || item === void 0 ? void 0 : item.productId) || (item === null || item === void 0 ? void 0 : item._id), orderStatus: _this.state.order.status, ref: function (ref) { return _this.productsRefs[item.productId] = ref; }, showDeleteProductModal: function () { return _this.$showDeleteProductModal.open({ product: item.productId }); }, readOnly: _this.state.readOnly, product: item, customerPartnerChannels: (_e = (_d = (((_c = (_b = (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a.extraData) === null || _b === void 0 ? void 0 : _b.customerPartner) === null || _c === void 0 ? void 0 : _c.channels) || [])) === null || _d === void 0 ? void 0 : _d.map(function (ch) { return "salesChannels[]=".concat(ch); })) === null || _e === void 0 ? void 0 : _e.join('&'), excludedProductsQuery: (_this.state.products || []).map(function (p) { var _a; return "excludedProducts[]=".concat((_a = p.product) === null || _a === void 0 ? void 0 : _a._id); }).join('&'), customerPartner: (_g = (_f = _this.state.order) === null || _f === void 0 ? void 0 : _f.extraData) === null || _g === void 0 ? void 0 : _g.customerPartner, order: _this.state.order });
        });
    };
    B2bOrderUpdateScreen.prototype.renderOrderChange = function () {
        var _this = this;
        var _a, _b;
        var allowedStatuses = ['financeApprovalNeeded', 'financeApproved', 'stockLocked'];
        var orderIsInAllowedStatus = allowedStatuses.includes(this.state.order.status);
        var isStorekeeper = ((_b = (_a = this.state.user) === null || _a === void 0 ? void 0 : _a.publicMeta) === null || _b === void 0 ? void 0 : _b.role) === user_permission_1.Role.storekeeper;
        if (!(orderIsInAllowedStatus && isStorekeeper)) {
            return null;
        }
        return React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Rendelés felnyitása', className: 'button-neutral-inverze button-size-normal mt-6', onClick: function () { return _this.$showModifyModal.open(); } });
    };
    B2bOrderUpdateScreen.prototype.renderControlButtons = function () {
        var _this = this;
        return React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.ORDERS.b2bOrders.update }, this.state.readOnly
            ? React.createElement("div", { className: 'display-flex flex-column align-items-center' },
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Módosítás', iconLeft: this.state.loading ? '' : 'fas fa-pencil', className: 'button-info-normal button-size-normal mb-6', disabled: this.state.loading ||
                        this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.canceled.status
                        || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status
                        || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.paid.status, onClick: function () { return _this.setState({ readOnly: false }); } }),
                this.renderNextStatusButton(),
                this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.draft.status
                    && React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Vázlat törlése', iconLeft: this.state.loading ? '' : 'fas fa-trash-can', className: 'button-primary-normal button-size-normal mt-6', disabled: false, onClick: function () { return _this.$showDeleteDraftModal.open(); } }),
                this.renderOrderChange())
            : React.createElement("div", { className: 'display-flex flex-column justify-content-center' },
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Mentés', iconLeft: this.state.loading ? '' : 'fas fa-pencil', className: 'button-success-normal button-size-normal', disabled: false, onClick: function () { return _this.updateOrder(); } })));
    };
    B2bOrderUpdateScreen.prototype.renderNextStatusButton = function () {
        var _this = this;
        var _a, _b;
        var isSales = ((_b = (_a = this.state.user) === null || _a === void 0 ? void 0 : _a.publicMeta) === null || _b === void 0 ? void 0 : _b.role) === user_permission_1.Role.sales;
        if (isSales) {
            return null;
        }
        return React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Következő státusz', iconLeft: this.state.loading ? '' : 'fas fa-arrow-right', className: 'button-success-normal button-size-normal', disabled: this.state.loading ||
                this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.paid.status
                || (this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && this.state.order.isPlanUpdateNeeded)
                || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.canceled.status, onClick: function () { return _this.setStatus(_this.state.order.status); } });
    };
    B2bOrderUpdateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('bestPrice'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 12 }, this.setHeaderCell('exactPrice'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('unit'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 18 }, this.setHeaderCell('plan'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('casePicked'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('fact'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    B2bOrderUpdateScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-order.create.table.header.".concat(p)),
            property: p
        };
    };
    B2bOrderUpdateScreen.prototype.renderFinanceApproval = function () {
        var _a, _b, _c, _d;
        var artifact = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.financeApprovedByArtifact;
        var user = artifact === null || artifact === void 0 ? void 0 : artifact.user;
        if (!((_b = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _b === void 0 ? void 0 : _b.firstName) || !(artifact === null || artifact === void 0 ? void 0 : artifact.date)) {
            return null;
        }
        var firstName = (_c = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _c === void 0 ? void 0 : _c.firstName;
        var lastName = (_d = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _d === void 0 ? void 0 : _d.lastName;
        var approvedByName = "".concat(lastName, " ").concat(firstName);
        var date = (artifact === null || artifact === void 0 ? void 0 : artifact.date) ? moment(artifact.date).format('YYYY.MM.DD. HH:mm:ss') : '';
        return React.createElement("div", { className: 'mb-4' },
            React.createElement("p", { className: 'typo-small' }, "P\u00E9nz\u00FCgy j\u00F3v\u00E1hagyta"),
            React.createElement("p", { className: 'fw-700 ml-2' }, approvedByName),
            React.createElement("p", { className: 'fw-700 ml-2' }, date));
    };
    B2bOrderUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if ((0, user_permission_1.hasRole)(user_permission_1.Role.sales)) {
            return null;
        }
        var creator = (_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.createdByArtifact) === null || _b === void 0 ? void 0 : _b.publicMeta;
        var createdBy = "".concat((creator === null || creator === void 0 ? void 0 : creator.lastName) || '', " ").concat((creator === null || creator === void 0 ? void 0 : creator.firstName) || '');
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'save-box info-box mb-6', key: 1 },
                React.createElement("h5", { className: 'mb-3' }, "SZERKESZT\u00C9S"),
                React.createElement("div", { className: 'w-100' },
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("p", { className: 'typo-small' }, "L\u00E9trehozva"),
                        React.createElement("p", { className: 'fw-700 ml-2' }, moment.utc((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.createdAt).format('YYYY.MM.DD.'))),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("p", { className: 'typo-small' }, "L\u00E9trehoz\u00F3"),
                        React.createElement("p", { className: 'fw-700 ml-2' }, createdBy)),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("p", { className: 'typo-small' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                        React.createElement("p", { className: 'fw-700 ml-2' }, moment.utc((_d = this.state.order) === null || _d === void 0 ? void 0 : _d.updatedAt).format('YYYY.MM.DD.'))),
                    this.renderFinanceApproval()),
                this.renderControlButtons()),
            (this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status
                || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status
                || this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.casePicked.status)
                && React.createElement("div", { className: 'save-box mb-6 success-box' },
                    React.createElement("h5", { className: 'mb-4' }, "KIT\u00C1ROL\u00C1S \u00CDV"),
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: this.state.isTakeoutSheetLoading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Kitárolás ív', iconLeft: 'far fa-download', onClick: function () { return _this.downloadTakeoutSheet(); } })),
            ((((_e = this.state.order) === null || _e === void 0 ? void 0 : _e.status) === b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.status && this.state.deliveryNotes.length)
                || ((_f = this.state.order) === null || _f === void 0 ? void 0 : _f.status) === b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status
                || ((_g = this.state.order) === null || _g === void 0 ? void 0 : _g.status) === b2b_order_list_screen_1.b2bOrderStatus.paid.status)
                && React.createElement("div", { className: 'save-box mb-6 success-box' },
                    React.createElement("h5", { className: 'mb-4' }, "DOKUMENTUMOK"),
                    this.renderDeliveryNotices(),
                    this.renderInvoiceComponent()),
            (((_h = this.state.order) === null || _h === void 0 ? void 0 : _h.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status
                || ((_j = this.state.order) === null || _j === void 0 ? void 0 : _j.status) === b2b_order_list_screen_1.b2bOrderStatus.financeApproved.status
                || ((_k = this.state.order) === null || _k === void 0 ? void 0 : _k.status) === b2b_order_list_screen_1.b2bOrderStatus.stockLocked.status)
                && React.createElement("div", { key: 2, className: 'save-box error-box mb-6' },
                    React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Megrendelés törlése', iconLeft: this.state.loading ? '' : 'fas fa-trash-can', className: 'button-error-outline button-size-normal', disabled: this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.paid.status, onClick: function () { return _this.$showCancelOrderModal.open(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bOrderUpdateScreen.prototype.retryInvoiceCreation = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, 4, 5]);
                        this.setState({ issueIsLoading: true });
                        return [4, this.repository.get("/b2b/order/retry-invoice-creation/".concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id))];
                    case 1:
                        _c.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.update.success.toast'));
                        return [4, (0, sleep_1.sleep)(1300)];
                    case 2:
                        _c.sent();
                        window.location.reload();
                        return [3, 5];
                    case 3:
                        e_4 = _c.sent();
                        handle_toasts_1.HandleToasts.error(e_4, (_b = e_4 === null || e_4 === void 0 ? void 0 : e_4.message) !== null && _b !== void 0 ? _b : 'Nem sikerült újra generálni a számlát');
                        return [3, 5];
                    case 4:
                        this.setState({ issueIsLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.retryDeliveryNoticeCreation = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var e_5;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, 4, 5]);
                        this.setState({ noticeIsLoading: true });
                        return [4, this.repository.get("/b2b/order/retry-delivery-notice-creation/".concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id))];
                    case 1:
                        _c.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.update.success.toast'));
                        return [4, (0, sleep_1.sleep)(1300)];
                    case 2:
                        _c.sent();
                        window.location.reload();
                        return [3, 5];
                    case 3:
                        e_5 = _c.sent();
                        handle_toasts_1.HandleToasts.error(e_5, (_b = e_5 === null || e_5 === void 0 ? void 0 : e_5.message) !== null && _b !== void 0 ? _b : 'Nem sikerült újra generálni a szállító levelet');
                        return [3, 5];
                    case 4:
                        this.setState({ noticeIsLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.renderDeliveryNotices = function () {
        var _this = this;
        var _a;
        return (_a = this.state.deliveryNotes) === null || _a === void 0 ? void 0 : _a.map(function (deliveryNote, index) {
            if (!deliveryNote) {
                return React.createElement("div", { key: index },
                    React.createElement("div", { className: 'position-center pb-2' },
                        React.createElement("span", { className: 'color--error-5' }, "Sz\u00E1ll\u00EDt\u00F3 lev\u00E9l gener\u00E1l\u00E1s sikertelen volt")),
                    React.createElement("div", { className: 'position-center' },
                        React.createElement(button_1.Button, { title: _this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Szállító levél generálás', className: 'button-info-inverze button-size-normal', disabled: false, loading: _this.state.noticeIsLoading, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2, this.retryDeliveryNoticeCreation()];
                            }); }); } })));
            }
            return React.createElement(button_1.Button, { key: index, title: _this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : "Sz\u00E1ll\u00EDt\u00F3lev\u00E9l ".concat(index + 1), iconLeft: _this.state.loading ? '' : 'fal fa-download', className: 'button-info-inverze button-size-normal mb-4', disabled: false, loading: _this.state.noticeIsLoading, onClick: function () { return window.open(deliveryNote); } });
        });
    };
    B2bOrderUpdateScreen.prototype.renderInvoiceComponent = function () {
        var _this = this;
        var _a, _b, _c;
        if (((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.status) === b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.status || ((_b = this.state.order) === null || _b === void 0 ? void 0 : _b.status) === b2b_order_list_screen_1.b2bOrderStatus.paid.status) {
            if (!((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.isInvoiceIssued)) {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'position-center pb-2' },
                        React.createElement("span", { className: 'color--error-5' }, "Sz\u00E1mlaki\u00E1ll\u00EDt\u00E1s sikertelen volt")),
                    React.createElement("div", { className: 'position-center' },
                        React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Számla generálás', className: 'button-info-inverze button-size-normal', disabled: false, loading: this.state.issueIsLoading, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2, this.retryInvoiceCreation()];
                            }); }); } })));
            }
            return React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Számla', iconLeft: this.state.loading ? ' ' : 'fal fa-download', className: 'button-info-inverze button-size-normal', disabled: false, loading: this.state.issueIsLoading, onClick: function () { return window.open(_this.getInvoiceUrl()); } });
        }
        return null;
    };
    B2bOrderUpdateScreen.prototype.getInvoiceUrl = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        if ((_c = (_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.invoiceResponse) === null || _b === void 0 ? void 0 : _b.default) === null || _c === void 0 ? void 0 : _c.payload) {
            return (_f = (_e = (_d = this.state.order) === null || _d === void 0 ? void 0 : _d.invoiceResponse) === null || _e === void 0 ? void 0 : _e.default) === null || _f === void 0 ? void 0 : _f.payload;
        }
        var value = (_j = Object.values(((_h = (_g = this.state.order) === null || _g === void 0 ? void 0 : _g.invoiceResponses) === null || _h === void 0 ? void 0 : _h[0]) || {})) === null || _j === void 0 ? void 0 : _j[0];
        if ((_k = value === null || value === void 0 ? void 0 : value.default) === null || _k === void 0 ? void 0 : _k.payload) {
            return value.default.payload;
        }
        if ((_p = (_o = (_m = (_l = this.state.order) === null || _l === void 0 ? void 0 : _l.invoiceUploadedResponse) === null || _m === void 0 ? void 0 : _m.uploaded) === null || _o === void 0 ? void 0 : _o.default) === null || _p === void 0 ? void 0 : _p.payload) {
            return (_t = (_s = (_r = (_q = this.state.order) === null || _q === void 0 ? void 0 : _q.invoiceUploadedResponse) === null || _r === void 0 ? void 0 : _r.uploaded) === null || _s === void 0 ? void 0 : _s.default) === null || _t === void 0 ? void 0 : _t.payload;
        }
    };
    B2bOrderUpdateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAddProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-order.add-product.modal.title')); }, renderModalContent: function (ctx) {
                    var _a, _b, _c, _d, _e;
                    return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$showAddProductModal.close(); }, onAddProduct: function (product) { return _this.setState({ products: __spreadArray(__spreadArray([], __read(_this.state.products), false), [product], false), }); }, customerPartnerChannels: (_e = (_d = (((_c = (_b = (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a.extraData) === null || _b === void 0 ? void 0 : _b.customerPartner) === null || _c === void 0 ? void 0 : _c.channels) || [])) === null || _d === void 0 ? void 0 : _d.map(function (ch) { return "salesChannels[]=".concat(ch); })) === null || _e === void 0 ? void 0 : _e.join('&'), excludedProductsQuery: (_this.state.products || []).map(function (p) { var _a; return "excludedProducts[]=".concat((_a = p.product) === null || _a === void 0 ? void 0 : _a._id); }).join('&') });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$showDeleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.product });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showCancelOrderModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-order.cancel.modal.title')); }, renderModalContent: function (ctx) {
                    var _a;
                    return React.createElement(cancel_order_modal_1.CancelOrderModal, { onClose: function () { return _this.$showCancelOrderModal.close(); }, order: (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a._id });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteDraftModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a;
                    return React.createElement(delete_draft_modal_1.DeleteDraftModal, { onClose: function () { return _this.$showDeleteDraftModal.close(); }, order: (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a._id });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showModifyModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a;
                    return React.createElement(modify_order_modal_1.ModifyOrderModal, { onClose: function () { return _this.$showModifyModal.close(); }, order: (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a._id });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAllowZeroForFactModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(allow_zero_for_fact_modal_1.AllowZeroForFactModal, { onClose: function () {
                        _this.$showAllowZeroForFactModal.close();
                        _this.setState({ loading: false });
                    }, allow: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.setState({ allowZeroForFact: true });
                                    return [4, this.setStatus(this.state.order.status)];
                                case 1:
                                    _a.sent();
                                    this.$showAllowZeroForFactModal.close();
                                    return [2];
                            }
                        });
                    }); } }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAllowZeroForLockModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(allow_zero_for_lock_modal_1.AllowZeroForLockModal, { onClose: function () {
                        _this.$showAllowZeroForLockModal.close();
                        _this.setState({ loading: false });
                    }, allow: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.setState({ allowZeroForLock: true });
                                    return [4, this.setStatus(this.state.order.status)];
                                case 1:
                                    _a.sent();
                                    this.$showAllowZeroForLockModal.close();
                                    return [2];
                            }
                        });
                    }); } }); } }));
    };
    B2bOrderUpdateScreen.prototype.handleRemoveProduct = function (productId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var newProducts;
            return __generator(this, function (_b) {
                newProducts = (_a = this.state.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
                this.setState({ products: newProducts });
                handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.create.delete.product.success.toast'));
                this.$showDeleteProductModal.close();
                return [2];
            });
        });
    };
    B2bOrderUpdateScreen.prototype.setStatus = function (status) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var valid, factItems, _b, _c, product, item, e_6_1, nextStatusUrl, result, e_7;
            var e_6, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 11, , 12]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _e.sent();
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('b2b-order.create.form.missing.required'));
                        }
                        if (this.state.order.status === b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.status && this.state.order.isPlanUpdateNeeded) {
                            throw new Error('PlanUpdateNeeded');
                        }
                        factItems = [];
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 7, 8, 9]);
                        _b = __values(this.state.products), _c = _b.next();
                        _e.label = 3;
                    case 3:
                        if (!!_c.done) return [3, 6];
                        product = _c.value;
                        return [4, this.productsRefs[product.productId].getData()];
                    case 4:
                        item = _e.sent();
                        if (parseInt(item.fact, 10) === 0) {
                            factItems.push(item);
                        }
                        if (status === 'casePicked' && !this.state.allowZeroForLock && !item.locks.length) {
                            return [2, this.$showAllowZeroForLockModal.open()];
                        }
                        _e.label = 5;
                    case 5:
                        _c = _b.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_6_1 = _e.sent();
                        e_6 = { error: e_6_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_6) throw e_6.error; }
                        return [7];
                    case 9:
                        if (status === 'deliveryNoteIssued' && !!(factItems === null || factItems === void 0 ? void 0 : factItems.length) && !this.state.allowZeroForFact) {
                            return [2, this.$showAllowZeroForFactModal.open()];
                        }
                        this.setState({ allowZeroForFact: false, allowZeroForLock: false });
                        nextStatusUrl = void 0;
                        switch (status) {
                            case 'draft':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.financeApprovalNeeded.url;
                                break;
                            case 'financeApprovalNeeded':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.financeApproved.url;
                                break;
                            case 'financeApproved':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.stockLocked.url;
                                break;
                            case 'stockLocked':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.casePicked.url;
                                break;
                            case 'casePicked':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.deliveryNoteIssued.url;
                                break;
                            case 'deliveryNoteIssued':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.factDeliveryDetermined.url;
                                break;
                            case 'factDeliveryDetermined':
                                nextStatusUrl = b2b_order_list_screen_1.b2bOrderStatus.paid.url;
                                break;
                            default:
                                throw new Error('UnknownStatus');
                        }
                        return [4, this.repository.put("/b2b/order/".concat(nextStatusUrl, "/set/").concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id), { data: this.state.order })];
                    case 10:
                        result = _e.sent();
                        this.setState({ loading: false, order: result });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.set.status.success.toast'));
                        return [3, 12];
                    case 11:
                        e_7 = _e.sent();
                        console.log({ importantError: e_7 });
                        handle_toasts_1.HandleToasts.error(e_7, (0, trans_1.trans)('b2b-order.set.status.error.toast'));
                        this.setState({ loading: false });
                        return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.getBestPrice = function (_a) {
        var _b, _c, _d, _e;
        var isBestPrice = _a.isBestPrice, price = _a.price, productId = _a.productId;
        if (isBestPrice || "".concat(price) === '0' || +price > 0) {
            return +price;
        }
        return (_e = (_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[productId]) === null || _d === void 0 ? void 0 : _d.bestPrice) !== null && _e !== void 0 ? _e : 0;
    };
    B2bOrderUpdateScreen.prototype.updateOrder = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, valid, items, _b, _c, product, item, e_8_1, plan_1, locks_1, fact_1, getCalculatedPrice_1, formData, isFreeShipping, data, updateUrl, result, e_9, errorMessage;
            var e_8, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        errors = [];
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 15, , 16]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 2:
                        valid = _e.sent();
                        items = [];
                        _e.label = 3;
                    case 3:
                        _e.trys.push([3, 8, 9, 10]);
                        _b = __values(this.state.products), _c = _b.next();
                        _e.label = 4;
                    case 4:
                        if (!!_c.done) return [3, 7];
                        product = _c.value;
                        return [4, this.productsRefs[product.productId].getData()];
                    case 5:
                        item = _e.sent();
                        if (item === null || item === void 0 ? void 0 : item.error) {
                            errors.push(item);
                            valid = false;
                            return [3, 6];
                        }
                        items.push(item);
                        _e.label = 6;
                    case 6:
                        _c = _b.next();
                        return [3, 4];
                    case 7: return [3, 10];
                    case 8:
                        e_8_1 = _e.sent();
                        e_8 = { error: e_8_1 };
                        return [3, 10];
                    case 9:
                        try {
                            if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                        }
                        finally { if (e_8) throw e_8.error; }
                        return [7];
                    case 10:
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('b2b-order.create.form.missing.required'));
                        }
                        plan_1 = [];
                        locks_1 = [];
                        fact_1 = [];
                        getCalculatedPrice_1 = function (_a) {
                            var exactPrice = _a.exactPrice, bestPrice = _a.bestPrice;
                            if ("".concat(exactPrice) === '0' || +exactPrice > 0) {
                                return exactPrice;
                            }
                            if (typeof bestPrice === 'object' && !isNaN(+(bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPrice))) {
                                return bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPrice;
                            }
                            if (!isNaN(+bestPrice)) {
                                return bestPrice;
                            }
                            throw new Error('Something is not right with the price given');
                        };
                        items.map(function (i) {
                            var _a;
                            plan_1.push({
                                productId: i.id,
                                priceArtifact: i.priceArtifact,
                                supplierPriceArtifact: i.supplierPriceArtifact,
                                calculatedPrice: getCalculatedPrice_1(i),
                                exactPrice: (_a = parseInt(i.exactPrice, 10)) !== null && _a !== void 0 ? _a : null,
                                amount: parseInt(i.plan, 10),
                                listPrice: i.listPrice,
                                unit: i.unit,
                                units: i.units,
                            });
                            var iLocks = (i.locks || []).map(function (lock) { return ({
                                product: lock.product,
                                lot: lock.lot,
                                storage: lock === null || lock === void 0 ? void 0 : lock.storage,
                                amount: lock === null || lock === void 0 ? void 0 : lock.amount,
                                type: lock === null || lock === void 0 ? void 0 : lock.type,
                                typePayload: lock === null || lock === void 0 ? void 0 : lock.typePayload,
                            }); });
                            locks_1 = __spreadArray(__spreadArray([], __read(locks_1), false), __read(iLocks), false);
                            fact_1.push({
                                productId: i.id,
                                amount: parseInt(i.fact, 10),
                            });
                        });
                        formData = this.form.toJSON();
                        isFreeShipping = this.form.fields.isFreeShipping.getValue();
                        data = {
                            plan: plan_1,
                            locks: locks_1,
                            fact: fact_1,
                            isFreeShipping: isFreeShipping,
                            shippingCharge: this.getShippingCharge(),
                            goodsReceiptId: formData.goodsReceiptId,
                            publicComment: formData.publicComment,
                            customOrderNumber: formData.customOrderNumber,
                            customerPartner: this.state.order.customerPartner,
                            shippingData: formData.shippingData,
                            comment: formData.comment,
                            isForeign: formData.isForeign,
                            isPlanUpdateNeeded: formData.isPlanUpdateNeeded,
                            issuingPurpose: formData.issuingPurpose,
                            legalCode: formData.legalCode,
                            plannedDeliveryDate: formData.plannedDeliveryDate,
                            licensePlateNumber: formData.licensePlateNumber,
                        };
                        updateUrl = b2b_order_list_screen_1.b2bOrderStatus[this.state.order.status].url;
                        return [4, this.repository.put("/b2b/order/".concat(updateUrl, "/update/").concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id), { data: data })];
                    case 11:
                        result = _e.sent();
                        this.setState({ loading: false, readOnly: true, order: result });
                        if (!this.state.shouldRevertStatus) return [3, 13];
                        return [4, this.handleStatusRevert()];
                    case 12:
                        _e.sent();
                        _e.label = 13;
                    case 13:
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.update.success.toast'));
                        return [4, (0, sleep_1.sleep)(600)];
                    case 14:
                        _e.sent();
                        window.location.reload();
                        return [3, 16];
                    case 15:
                        e_9 = _e.sent();
                        errorMessage = 'b2b-order.update.error.toast';
                        switch (e_9.message) {
                            case 'MissingLegalCode':
                                errorMessage = 'b2b-order.update.error.toast.MissingLegalCode';
                                break;
                            case 'MissingSupplierPrice':
                                errorMessage = 'b2b-order.update.error.toast.MissingSupplierPrice';
                                break;
                        }
                        handle_toasts_1.HandleToasts.error(e_9, (0, trans_1.trans)(errorMessage));
                        this.setState({ loading: false });
                        console.log(e_9);
                        return [3, 16];
                    case 16: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.getShippingCharge = function () {
        if (this.state.isFreeShipping) {
            return 0;
        }
        return this.state.shippingCharge;
    };
    B2bOrderUpdateScreen.prototype.handleStatusRevert = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                try {
                    return [2, this.repository.put("/b2b/order/revert-status/".concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id), { data: null })];
                }
                catch (e) {
                    console.log({ handleStatusRevert: e });
                }
                return [2];
            });
        });
    };
    B2bOrderUpdateScreen.prototype.setUpCache = function (order) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var channelsArr, channels, endpoint, e_10;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 2, , 3]);
                        channelsArr = (((_b = (_a = order === null || order === void 0 ? void 0 : order.extraData) === null || _a === void 0 ? void 0 : _a.customerPartner) === null || _b === void 0 ? void 0 : _b.channels) || []);
                        if (!(channelsArr === null || channelsArr === void 0 ? void 0 : channelsArr.length)) {
                            return [2];
                        }
                        channels = (_f = (_e = (((_d = (_c = order === null || order === void 0 ? void 0 : order.extraData) === null || _c === void 0 ? void 0 : _c.customerPartner) === null || _d === void 0 ? void 0 : _d.channels) || [])) === null || _e === void 0 ? void 0 : _e.map(function (ch) { return "salesChannels[]=".concat(ch); })) === null || _f === void 0 ? void 0 : _f.join('&');
                        endpoint = "/b2b/stock/list-by-customer-partner-with-price-for-order?".concat(channels);
                        return [4, this.repository.get(endpoint)];
                    case 1:
                        _g.sent();
                        return [3, 3];
                    case 2:
                        e_10 = _g.sent();
                        console.log({ setUpCache: e_10 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.downloadTakeoutSheet = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var takeoutSheetLink, e_11;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.setState({ isTakeoutSheetLoading: true });
                        return [4, this.repository.get("/b2b/stock/stock-issue-takeout-sheet?order=".concat((_a = this.state.order) === null || _a === void 0 ? void 0 : _a._id))];
                    case 1:
                        takeoutSheetLink = _b.sent();
                        window.open(takeoutSheetLink, '_blank');
                        return [3, 4];
                    case 2:
                        e_11 = _b.sent();
                        console.log('inventory sheet error: ', e_11);
                        return [3, 4];
                    case 3:
                        this.setState({ isTakeoutSheetLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bOrderUpdateScreen.prototype.fetchShippingCharges = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.get('/b2b/shipping-charge')];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            this.setState({ shippingChargeOptions: response });
                        }
                        return [3, 3];
                    case 2:
                        e_12 = _a.sent();
                        console.log({ fetchShippingCharges: e_12 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return B2bOrderUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bOrderUpdateScreen = B2bOrderUpdateScreen;
