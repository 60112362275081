"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSafeRedirectUrl = void 0;
var permissions_1 = require("../permissions/permissions");
var getDefaultRedirectUrl = function (permissions) {
    var defaultUrl = '/';
    if (!(permissions === null || permissions === void 0 ? void 0 : permissions.length)) {
        return defaultUrl;
    }
    if (permissions.includes(permissions_1.PERMISSIONS.PRODUCTS.read)) {
        return '/products';
    }
    if (permissions.includes(permissions_1.PERMISSIONS.ORDERS.b2bOrders.read)) {
        return '/b2b-order';
    }
    if (permissions.includes(permissions_1.PERMISSIONS.ORDERS.b2cOrders.read)) {
        return '/order';
    }
    return defaultUrl;
};
var getSafeRedirectUrl = function (permissions, toCheck, targetUrl) {
    var _a;
    var hasPermission = (_a = permissions === null || permissions === void 0 ? void 0 : permissions.includes) === null || _a === void 0 ? void 0 : _a.call(permissions, toCheck);
    var defaultRedirect = getDefaultRedirectUrl(permissions);
    return hasPermission ? targetUrl : defaultRedirect;
};
exports.getSafeRedirectUrl = getSafeRedirectUrl;
