"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'b2b-customer-partner-pricing.list.main.title': 'Vevő partner árak',
    'b2b-customer-partner-pricing.filter.form.label.customer-partner': 'Vevő partner',
    'b2b-customer-partner-pricing.filter.form.label.date': 'Dátum',
    'b2b-customer-partner-pricing.filter.form.label.category': 'Kategória',
    'b2b-customer-partner-pricing.filter.form.label.manufacturer': 'Pince',
    'b2b-customer-partner-pricing.modal.button.open.grouped': 'Csoportos kedvezmény megadás',
    'b2b-customer-partner-pricing.table.header.checkbox': '',
    'b2b-customer-partner-pricing.table.header.channel-price': 'Listaár',
    'b2b-customer-partner-pricing.table.header.cancel-discount': '',
    'b2b-customer-partner-pricing.table.header.category': 'kategória',
    'b2b-customer-partner-pricing.table.header.product': 'termék',
    'b2b-customer-partner-pricing.table.header.price': 'egyedi partner ár',
    'b2b-customer-partner-pricing.table.header.fix-discount': 'kedvezményes partner ár',
    'b2b-customer-partner-pricing.table.header.discount': 'akciós ár',
    'b2b-customer-partner-pricing.table.header.edit': '',
    'b2b-customer-partner-pricing.modal.button.open': 'Szerkesztés',
    'b2b-customer-partner-pricing.discount.over': 'Akció leállítása',
    'b2b-customer-partner-pricing.are.you.sure': 'Biztosan leállítod az akciós árat?',
    'b2b-customer-partner-pricing.pricing.show.modal.title': 'Ár módosítása',
    'b2b-customer-partner-pricing.pricing.show.modal.title.batch': 'Tömeges akicó megadás',
    'b2b-customer-partner-pricing.pricing.show.modal.customer-partner': 'Beszállító partner',
    'b2b-customer-partner-pricing.edit.modal.table.header.start': 'kezdés',
    'b2b-customer-partner-pricing.edit.modal.table.header.end': 'vége',
    'b2b-customer-partner-pricing.edit.modal.table.header.status': 'státusz',
    'b2b-customer-partner-pricing.edit.modal.table.header.price': 'ár',
    'b2b-customer-partner-pricing.edit.modal.table.header.modifier': 'ár módosító',
    'b2b-customer-partner-pricing.edit.modal.table.header.edit': React.createElement("i", { className: 'fa-solid fa-pencil' }),
    'b2b-customer-partner-pricing.edit.modal.table.header.delete': React.createElement("i", { className: 'fa-solid fa-trash-can' }),
    'b2b-customer-partner-pricing.error.missing.required': 'Kötelző adatok hiányoznak',
    'b2b-customer-partner-pricing.error.Unauthorized': 'Nincs jogosultságod a művelet végrehajtásához',
    'b2b-customer-partner-pricing.error.MissingCustomerPartner': 'Hiányzó vevő partner',
    'b2b-customer-partner-pricing.error.MissingProductId': 'Hiányzó termék azonosító',
    'b2b-customer-partner-pricing.error.MissingProducts': 'Hiányzó termék azonosítók',
    'b2b-customer-partner-pricing.error.MissingFrom': 'Hiányzó kezdő dátum',
    'b2b-customer-partner-pricing.error.MissingTo': 'Hiányzó vége dátum',
    'b2b-customer-partner-pricing.error.MissingValue': 'Hiányzó ár',
    'b2b-customer-partner-pricing.error.MissingValueInValue': 'Hiányzó ár/mennyiség',
    'b2b-customer-partner-pricing.error.MissingTypeInValue': 'Hiányzó kedvezmény típus',
    'b2b-customer-partner-pricing.error.WrongValueType': 'Hibás kedvezmény típus',
    'b2b-customer-partner-pricing.error.UnknownSupplierPartner': 'Ismeretlen beszállító partner',
    'b2b-customer-partner-pricing.error.UnknownProduct': 'Ismeretlen termék',
    'b2b-customer-partner-pricing.error.MissingSupplierPartnerPriceDocument': 'Hiányzó ár adatok',
    'b2b-customer-partner-pricing.error.FromMustBeInFuture': 'Kezdeti dátumnak a jövőben kell lennie',
    'b2b-customer-partner-pricing.error.ToMustBeAfterFrom': 'A vége dátumnak a kezdeti dátum után kell lennie',
    'b2b-customer-partner-pricing.error.MissingCustomerPartnerPriceDocument': 'Hinyzó vevő partner dokumentum',
    'b2b-customer-partner-pricing.error.MissingCustomerPartnerPriceModifierDocument': 'Hinyzó vevő partner kedvezmény dokumentum',
};
