"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryAttributeRow = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var React = require("react");
var CategoryAttributeRow = (function (_super) {
    __extends(CategoryAttributeRow, _super);
    function CategoryAttributeRow() {
        var _this = this;
        var _a, _b, _c, _d, _e;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                showList: new field_1.Field({
                    placeholder: '',
                    value: (_a = _this.props.categoryAttribute) === null || _a === void 0 ? void 0 : _a.showList,
                    name: 'showList',
                    label: '',
                    validators: []
                }),
                showProduct: new field_1.Field({
                    placeholder: '',
                    value: (_b = _this.props.categoryAttribute) === null || _b === void 0 ? void 0 : _b.showProduct,
                    name: 'showProduct',
                    label: '',
                    validators: []
                }),
                listOrder: new field_1.Field({
                    placeholder: '',
                    value: (_c = _this.props.categoryAttribute) === null || _c === void 0 ? void 0 : _c.listOrder,
                    name: 'listOrder',
                    label: '',
                    validators: []
                }),
                adminOrder: new field_1.Field({
                    placeholder: '',
                    value: (_d = _this.props.categoryAttribute) === null || _d === void 0 ? void 0 : _d.adminOrder,
                    name: 'adminOrder',
                    label: '',
                    validators: []
                }),
                productOrder: new field_1.Field({
                    placeholder: '',
                    value: (_e = _this.props.categoryAttribute) === null || _e === void 0 ? void 0 : _e.productOrder,
                    name: 'productOrder',
                    label: '',
                    validators: []
                })
            }
        });
        _this.state = {
            error: null
        };
        return _this;
    }
    CategoryAttributeRow.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$value.subscribe(function () { return _this.handleChange(); }));
    };
    CategoryAttributeRow.prototype.render = function () {
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: "col-24" }, this.renderAttributeForm()));
    };
    CategoryAttributeRow.prototype.renderAttributeForm = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        return React.createElement(table_body_row_1.TableBodyRow, { key: (_b = (_a = this.props.categoryAttribute) === null || _a === void 0 ? void 0 : _a.attribute) === null || _b === void 0 ? void 0 : _b._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_e = (_d = (_c = this.props.categoryAttribute) === null || _c === void 0 ? void 0 : _c.attribute) === null || _d === void 0 ? void 0 : _d.title) === null || _e === void 0 ? void 0 : _e.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, (_g = (_f = this.props.categoryAttribute) === null || _f === void 0 ? void 0 : _f.attribute) === null || _g === void 0 ? void 0 : _g.adminTitle)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.showList, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.showProduct, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.listOrder, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.productOrder, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.adminOrder, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(button_1.Button, { iconLeft: 'fal fa-trash', className: 'button-primary-link button-size-normal', onClick: function () { return _this.removeAttribute(); } })));
    };
    CategoryAttributeRow.prototype.removeAttribute = function () {
        var _a, _b;
        if (this.props.onRemoveAttribute) {
            this.props.onRemoveAttribute((_b = (_a = this.props.categoryAttribute) === null || _a === void 0 ? void 0 : _a.attribute) === null || _b === void 0 ? void 0 : _b._id);
        }
    };
    CategoryAttributeRow.prototype.handleChange = function () {
        var _a, _b, _c;
        if (this.props.onChange) {
            this.props.onChange((_b = (_a = this.props.categoryAttribute) === null || _a === void 0 ? void 0 : _a.attribute) === null || _b === void 0 ? void 0 : _b._id, __assign({ attribute: (_c = this.props.categoryAttribute) === null || _c === void 0 ? void 0 : _c.attribute }, this.form.toJSON()));
        }
    };
    return CategoryAttributeRow;
}(abstract_component_1.AbstractComponent));
exports.CategoryAttributeRow = CategoryAttributeRow;
