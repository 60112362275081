"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var moment = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var user_permission_1 = require("../../../common/libs/user-permission");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
require("../product-update.screen.scss");
var basic_data_form_1 = require("./product-attribute-editor/basic-data-form");
var languageOptions = [
    { value: 'hu', name: 'HU', key: 'hu' },
    { value: 'en', name: 'EN', key: 'en' }
];
var ProductCreateScreen = (function (_super) {
    __extends(ProductCreateScreen, _super);
    function ProductCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            product: null,
            editorLanguage: 'hu',
            isBundle: null,
            loading: false
        };
        return _this;
    }
    ProductCreateScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ProductUpdateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-primary-link button-size-normal', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/products'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('product.create.title'))),
                React.createElement("div", { className: 'row' },
                    !(0, user_permission_1.isExcludedPermission)(['productOwner']) &&
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: 'mb-3' },
                                React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.images'))),
                            this.renderImageUploader()),
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.basic-data'))),
                        React.createElement(basic_data_form_1.BasicDataForm, { ref: function (ref) { return _this.productBasicDataFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, isCreate: true, values: this.state.product })))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    ProductCreateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        var _a;
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.imageSingleProductUploaderRef$ = ref; }, value: (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.imageSingleProduct, width: 368, heigth: 368, namePrefix: function () { var _a; return (_a = _this.productBasicDataFormRef$) === null || _a === void 0 ? void 0 : _a.getFileNamePrefix('single'); }, targetVariant: "default" })));
    };
    ProductCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'SaveBox' },
                React.createElement("div", { className: 'display-flex flex-column' },
                    React.createElement("small", null, (0, trans_1.trans)('product.update.save-box.last-modified.label')),
                    React.createElement("strong", null, moment((_a = this.state.product) === null || _a === void 0 ? void 0 : _a.updatedAt).format('YYYY.MM.DD. HH:mm'))),
                React.createElement("div", { className: "mb-4" },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', value: this.state.editorLanguage, onChange: function (value) { return _this.changeLanguage(value); }, unclearable: true, options: languageOptions })),
                React.createElement("div", { className: 'w-100 display-flex justify-content-center' },
                    React.createElement(button_1.Button, { disabled: this.state.loading, onClick: function () { return _this.submitForms(); }, title: this.state.loading ?
                            React.createElement("i", { className: 'fas fa-circle-notch fa-spin' }) : (0, trans_1.trans)('product.update.save-box.button.save'), className: 'button-primary-normal button-size-normal' }))));
    };
    ProductCreateScreen.prototype.submitForms = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var basicData, imageSingleProduct, data, response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        this.setState({ loading: true });
                        return [4, ((_a = this.productBasicDataFormRef$) === null || _a === void 0 ? void 0 : _a.getData())];
                    case 1:
                        basicData = _c.sent();
                        return [4, ((_b = this.imageSingleProductUploaderRef$) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 2:
                        imageSingleProduct = _c.sent();
                        if (!!basicData.error) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        data = __assign(__assign({}, basicData), { imageSingleProduct: imageSingleProduct, prices: [] });
                        return [4, this.repository.post('/admin/product', { data: data })];
                    case 3:
                        response = _c.sent();
                        this.setState({ loading: false });
                        router_provider_1.RouterProvider.goTo("/products/".concat(response._id));
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('product.create.success.toast'));
                        return [3, 5];
                    case 4:
                        e_1 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_1, (0, trans_1.trans)(e_1.message.replace(/\s/g, '')));
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    ProductCreateScreen.prototype.changeLanguage = function (lang) {
        this.setState({ editorLanguage: lang });
    };
    ProductCreateScreen.prototype.submit = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var data, response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        data = {
                            name: value === null || value === void 0 ? void 0 : value.name,
                            subtitle: value === null || value === void 0 ? void 0 : value.subtitle,
                            description: value === null || value === void 0 ? void 0 : value.description
                        };
                        return [4, this.repository.post('/admin/product', { data: data })];
                    case 1:
                        response = _a.sent();
                        return [2, router_provider_1.RouterProvider.goTo("/products/".concat(response.payload._id))];
                    case 2:
                        e_2 = _a.sent();
                        this.setState({ error: e_2 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return ProductCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.ProductCreateScreen = ProductCreateScreen;
