"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoryListControl = void 0;
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var RepositoryListControl = (function () {
    function RepositoryListControl(options) {
        var _this = this;
        var _a, _b;
        this.data = {};
        this.response = {};
        this.response$ = new rxjs_1.Subject();
        this.query$ = new rxjs_1.Subject();
        this.queue$ = new rxjs_1.Subject();
        this.subscriptions$ = [];
        this.qm = (_a = options === null || options === void 0 ? void 0 : options.queryMapper) !== null && _a !== void 0 ? _a : (function (v) { return v; });
        this.rm = (_b = options === null || options === void 0 ? void 0 : options.responseMapper) !== null && _b !== void 0 ? _b : (function (v) { return v; });
        this.handleSet = options === null || options === void 0 ? void 0 : options.handleSet;
        this.handleGet = options === null || options === void 0 ? void 0 : options.handleGet;
        this.data = this.qm(this.handleGet
            ? this.handleGet(((options === null || options === void 0 ? void 0 : options.defaultData) || {}))
            : ((options === null || options === void 0 ? void 0 : options.defaultData) || {}));
        this.subscriptions$.push(this.queue$.subscribe(function (value) { return _this.query$.next(_this.qm(_this.handleGet
            ? _this.handleGet()
            : value)); }));
    }
    RepositoryListControl.prototype.set = function (key, value) {
        if ((0, lodash_1.isEqual)(this.data[key], value)) {
            return;
        }
        (0, lodash_1.set)(this.data, key, value);
        if (this.handleSet) {
            this.handleSet(this.data);
        }
        this.queue$.next(this.data);
    };
    RepositoryListControl.prototype.delete = function (key) {
        if (this.data[key] === undefined) {
            return;
        }
        delete this.data[key];
        this.queue$.next(this.data);
    };
    RepositoryListControl.prototype.reset = function () {
        this.data = {};
        this.queue$.next(this.data);
    };
    RepositoryListControl.prototype.setResponse = function (response) {
        this.response = this.rm(response);
        this.response$.next(this.response);
    };
    RepositoryListControl.prototype.reload = function () {
        this.queue$.next(this.data);
    };
    RepositoryListControl.prototype.getQuery = function () {
        return this.qm(this.handleGet
            ? this.handleGet()
            : this.data);
    };
    return RepositoryListControl;
}());
exports.RepositoryListControl = RepositoryListControl;
