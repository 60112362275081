"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminMenuHeaderComponent = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var React = require("react");
require("./admin-menu.component.scss");
var AdminMenuHeaderComponent = (function (_super) {
    __extends(AdminMenuHeaderComponent, _super);
    function AdminMenuHeaderComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminMenuHeaderComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'admin-menu-header', onClick: function () { return _this.toggle(); } },
            React.createElement("div", { className: "row w-100" },
                React.createElement("div", { className: 'col-8 display-flex display-md-none' },
                    React.createElement("div", { className: 'menu-button' },
                        React.createElement("i", { className: 'fal fa-bars mr-2' }),
                        React.createElement("span", null, "Menu"))),
                React.createElement("div", { className: "logo col-md-24 col-8" },
                    React.createElement("img", { src: "/assets/images/WineHub-Logo_wine.svg", alt: "winehub-logo" })),
                React.createElement("div", { className: 'col-8 display-md-none' })));
    };
    AdminMenuHeaderComponent.prototype.toggle = function () {
        if (this.props.onToggle) {
            this.props.onToggle();
        }
    };
    return AdminMenuHeaderComponent;
}(abstract_component_1.AbstractComponent));
exports.AdminMenuHeaderComponent = AdminMenuHeaderComponent;
