"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockInventoryUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var form_1 = require("../../components/form/form");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
require("./b2b-stock-inventory.screen.scss");
var delete_draft_modal_1 = require("./components/delete-draft.modal");
var stock_inventory_table_stock_row_1 = require("./components/stock-inventory-table-stock-row");
var B2bStockInventoryUpdateScreen = (function (_super) {
    __extends(B2bStockInventoryUpdateScreen, _super);
    function B2bStockInventoryUpdateScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.stockRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.state = {
            readOnly: true,
            loading: false,
            inventory: {},
            storages: [],
            plan: [],
            error: '',
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-inventory.form.label.warehouse'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    optionsEndpoint: '/b2b/warehouse?limit=5000',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.title,
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                })
            }
        });
        return _this;
    }
    B2bStockInventoryUpdateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchInventoryData()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockInventoryUpdateScreen.prototype.fetchInventoryData = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var response, data, result, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, 4, 5]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/stock-inventory/".concat((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id))];
                    case 1:
                        response = _d.sent();
                        data = {
                            warehouse: response.warehouse,
                            plan: response.plan,
                        };
                        return [4, this.repository.post('/b2b/stock-inventory/plan', { data: data })];
                    case 2:
                        result = _d.sent();
                        this.setState({
                            inventory: response,
                            storages: result.items,
                            plan: response.plan
                        });
                        this.form.fields.warehouse.setValue(response.warehouse);
                        return [3, 5];
                    case 3:
                        e_1 = _d.sent();
                        this.setState({ error: (0, trans_1.trans)("stock-inventory.error.".concat(e_1.message)) });
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    B2bStockInventoryUpdateScreen.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f;
        return React.createElement("div", { className: 'StockInventoryScreen CreateScreen' },
            this.renderModals(),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-product-inventory'); } })),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("h2", null, (_a = this.state.inventory) === null || _a === void 0 ? void 0 :
                            _a.identifier,
                            " ",
                            (0, trans_1.trans)('stock-inventory.update.title'))),
                    React.createElement(form_1.Form, null,
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { disabled: true, multiple: false, className: 'select-input-basic col-12' }))))),
                React.createElement("div", { className: 'col save-box-wrapper' }, this.renderSaveBox())),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' }, this.renderTable())),
            (!!((_c = (_b = this.state.inventory) === null || _b === void 0 ? void 0 : _b.__meta) === null || _c === void 0 ? void 0 : _c.difference) && ((_d = this.state.inventory) === null || _d === void 0 ? void 0 : _d.status) !== 'executed') && React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' }, this.renderDifferences((_f = (_e = this.state.inventory) === null || _e === void 0 ? void 0 : _e.__meta) === null || _f === void 0 ? void 0 : _f.difference))));
    };
    B2bStockInventoryUpdateScreen.prototype.renderDifferences = function (data) {
        var values = Object.values(data);
        if (!values.length) {
            return null;
        }
        return React.createElement("div", { className: 'difference-container p-4' },
            React.createElement("h5", { className: 'mb-2' }, "Lelt\u00E1r k\u00FCl\u00F6nb\u00F6zet"),
            React.createElement("div", null, values === null || values === void 0 ? void 0 : values.map(function (value, index) { return React.createElement("div", { key: index, className: 'row border-bottom-1 py-2' },
                React.createElement("div", { className: 'display-flex col-8 mr-6' },
                    React.createElement("p", { className: 'mr-4' },
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, value.ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "LOT"),
                        React.createElement("span", null, value.lot))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "K\u00FCl\u00F6nb\u00F6zet: "),
                        React.createElement("span", null, !!value.difference
                            ? value.difference > 0
                                ? React.createElement("span", { className: 'color--success-5' },
                                    "+",
                                    value.difference)
                                : React.createElement("span", { className: 'color--error-5' }, value.difference)
                            : React.createElement("span", null, value.difference))))); })));
    };
    B2bStockInventoryUpdateScreen.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic B2bInventoryTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.storages); } });
    };
    B2bStockInventoryUpdateScreen.prototype.renderItems = function (items) {
        var _this = this;
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nem tal\u00E1lhat\u00F3 term\u00E9k"));
        }
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var _a, _b;
            return React.createElement(table_body_row_1.TableBodyRow, { key: item === null || item === void 0 ? void 0 : item._id, className: 'flex-column wrapper main-wrapper border-0' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement(table_body_row_1.TableBodyRow, null,
                        React.createElement(table_body_cell_1.TableBodyCell, null, (_a = item === null || item === void 0 ? void 0 : item.path) === null || _a === void 0 ? void 0 : _a.map(function (p) { return p.title; }).join(' / ')))), (_b = item === null || item === void 0 ? void 0 : item.stockProducts) === null || _b === void 0 ? void 0 :
                _b.map(function (stock) {
                    var _a;
                    return React.createElement(stock_inventory_table_stock_row_1.StockInventoryTableStockRow, { key: stock._id, ref: function (ref) { return _this.stockRefs[stock._id] = ref; }, stock: stock, plan: (_a = _this.state.plan) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.stockProduct === stock._id; }), readOnly: _this.state.readOnly });
                }));
        });
    };
    B2bStockInventoryUpdateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, null,
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 100 }, this.setHeaderCell('storage'))));
    };
    B2bStockInventoryUpdateScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("inventory.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockInventoryUpdateScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bStockInventoryUpdateScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bStockInventoryUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: 'w-100' },
            React.createElement("div", { className: 'save-box info-box mb-4' },
                React.createElement("h5", { className: 'mb-6' }, "ST\u00C1TUSZ"),
                React.createElement("div", { className: 'w-100 mb-4' },
                    React.createElement("div", { className: 'display-flex flex-column mb-2' },
                        React.createElement("small", { className: 'mb-2' }, "L\u00E9trehozva"),
                        React.createElement("span", { className: 'fw-700 ml-4' }, moment((_a = this.state.inventory) === null || _a === void 0 ? void 0 : _a.createdAt).format('YYYY.MM.DD.'))),
                    React.createElement("div", { className: 'display-flex flex-column' },
                        React.createElement("small", { className: 'mb-2' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                        React.createElement("span", { className: 'fw-700 ml-4' }, moment((_b = this.state.inventory) === null || _b === void 0 ? void 0 : _b.updatedAt).format('YYYY.MM.DD.')))),
                (this.state.readOnly && ((_c = this.state.inventory) === null || _c === void 0 ? void 0 : _c.status) !== 'executed')
                    && [
                        React.createElement(button_1.Button, { key: 0, title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Szerkesztés', iconLeft: this.state.loading ? '' : 'fal fa-pen', className: 'button-info-normal button-size-normal', disabled: false, onClick: function () { return _this.setState({ readOnly: false }); } }),
                        React.createElement(button_1.Button, { key: 1, title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Törlés', iconLeft: this.state.loading ? '' : 'fas fa-trash', className: 'button-error-normal button-size-normal mt-6', disabled: false, onClick: function () { return _this.$showDeleteDraftModal.open(); } }),
                    ],
                !this.state.readOnly
                    && [
                        React.createElement(button_1.Button, { key: 0, title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Mentés', iconLeft: this.state.loading ? '' : 'fal fa-save', className: 'button-info-normal button-size-normal', disabled: false, onClick: function () { return _this.handleSubmit(); } }),
                        React.createElement(button_1.Button, { key: 1, title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Véglegesítés', iconLeft: this.state.loading ? '' : 'fal fa-check', className: 'button-success-normal button-size-normal mt-6', disabled: false, onClick: function () { return _this.handleExecute(); } })
                    ]),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockInventoryUpdateScreen.prototype.handleSubmit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var valid, plan, stockProducts, stockProducts_1, stockProducts_1_1, stock, item, e_2_1, formData, data, e_3;
            var e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 11, 12, 13]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        plan = [];
                        stockProducts = (_a = this.state.storages) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, storage) { return __spreadArray(__spreadArray([], __read(acc), false), __read(storage.stockProducts), false); }, []);
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        stockProducts_1 = __values(stockProducts), stockProducts_1_1 = stockProducts_1.next();
                        _d.label = 3;
                    case 3:
                        if (!!stockProducts_1_1.done) return [3, 6];
                        stock = stockProducts_1_1.value;
                        return [4, this.stockRefs[stock._id].getData(false)];
                    case 4:
                        item = _d.sent();
                        if (!(item === null || item === void 0 ? void 0 : item.amount) && (item === null || item === void 0 ? void 0 : item.amount) !== 0) {
                            return [3, 5];
                        }
                        plan.push(item);
                        _d.label = 5;
                    case 5:
                        stockProducts_1_1 = stockProducts_1.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (stockProducts_1_1 && !stockProducts_1_1.done && (_c = stockProducts_1.return)) _c.call(stockProducts_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            throw new Error((0, trans_1.trans)('stock-inventory.form.missing.required'));
                        }
                        formData = this.form.toJSON();
                        data = {
                            warehouse: formData === null || formData === void 0 ? void 0 : formData.warehouse,
                            plan: plan === null || plan === void 0 ? void 0 : plan.map(function (p) { return ({
                                stockProduct: p.product,
                                amount: parseInt(p.amount, 10),
                            }); }),
                        };
                        return [4, this.repository.put("/b2b/stock-inventory/".concat((_b = this.state.inventory) === null || _b === void 0 ? void 0 : _b._id), { data: data })];
                    case 10:
                        _d.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-inventory.update.success.toast'));
                        window.location.reload();
                        return [3, 13];
                    case 11:
                        e_3 = _d.sent();
                        this.setState({ loading: false, error: e_3.message });
                        return [3, 13];
                    case 12:
                        this.setState({ loading: false });
                        return [7];
                    case 13: return [2];
                }
            });
        });
    };
    B2bStockInventoryUpdateScreen.prototype.handleExecute = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var valid, stockProducts, stockProducts_2, stockProducts_2_1, stock, item, e_4_1, e_5;
            var e_4, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 11, 12, 13]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        stockProducts = (_a = this.state.storages) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, storage) { return __spreadArray(__spreadArray([], __read(acc), false), __read(storage.stockProducts), false); }, []);
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        stockProducts_2 = __values(stockProducts), stockProducts_2_1 = stockProducts_2.next();
                        _d.label = 3;
                    case 3:
                        if (!!stockProducts_2_1.done) return [3, 6];
                        stock = stockProducts_2_1.value;
                        return [4, this.stockRefs[stock._id].getData(true)];
                    case 4:
                        item = _d.sent();
                        if (item === null || item === void 0 ? void 0 : item.error) {
                            valid = false;
                        }
                        _d.label = 5;
                    case 5:
                        stockProducts_2_1 = stockProducts_2.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_4_1 = _d.sent();
                        e_4 = { error: e_4_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (stockProducts_2_1 && !stockProducts_2_1.done && (_c = stockProducts_2.return)) _c.call(stockProducts_2);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            throw new Error((0, trans_1.trans)('stock-inventory.form.missing.required'));
                        }
                        return [4, this.repository.put("/b2b/stock-inventory/".concat((_b = this.state.inventory) === null || _b === void 0 ? void 0 : _b._id, "/execute"))];
                    case 10:
                        _d.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-inventory.execute.success.toast'));
                        window.location.reload();
                        return [3, 13];
                    case 11:
                        e_5 = _d.sent();
                        this.setState({ loading: false, error: e_5.message });
                        return [3, 13];
                    case 12:
                        this.setState({ loading: false });
                        return [7];
                    case 13: return [2];
                }
            });
        });
    };
    B2bStockInventoryUpdateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteDraftModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(delete_draft_modal_1.DeleteDraftModal, { onClose: function () { return _this.$showDeleteDraftModal.close(); }, inventory: _this.state.inventory._id }); } }));
    };
    return B2bStockInventoryUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockInventoryUpdateScreen = B2bStockInventoryUpdateScreen;
