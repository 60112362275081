"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KnAttributeUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var form_1 = require("../../components/form/form");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var KnAttributeUpdateScreen = (function (_super) {
    __extends(KnAttributeUpdateScreen, _super);
    function KnAttributeUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            isExciseDuty: false,
            attribute: {},
            loading: false,
            error: null
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                knCode: new field_1.Field({
                    placeholder: '',
                    value: _this.state.attribute.knCode || '',
                    name: 'knCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('kn-attribute.form.knCode'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                isExciseDuty: new field_1.Field({
                    placeholder: '',
                    value: _this.state.attribute.isExciseDuty || false,
                    name: 'isExciseDuty',
                    label: React.createElement("div", null, (0, trans_1.trans)('kn-attribute.form.isExciseDuty')),
                    validators: []
                }),
                exciseDutyList: new field_1.Field({
                    placeholder: '',
                    value: _this.state.attribute.exciseDutyList || [],
                    name: 'exciseDutyList',
                    optionsEndpoint: '/admin/kn-attribute/excise-duty-options',
                    optionsMap: function (response) { return response.map(function (i) { return ({
                        name: "".concat(i.code, " - ").concat(i.description),
                        value: i.code,
                        key: i.code
                    }); }); },
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null, (0, trans_1.trans)('kn-attribute.form.exciseDutyList')),
                    validators: []
                }),
            }
        });
        return _this;
    }
    KnAttributeUpdateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$subscriptions.push(this.form.fields.isExciseDuty.$value.subscribe(function (v) { return _this.onIsExciseDutyChange(v); }), this.form.$submitSuccess.subscribe(function () { return _this.update(); }));
                        return [4, this.fetchAttribute()];
                    case 1:
                        _a.sent();
                        this.form.fields.knCode.setValue(this.state.attribute.knCode);
                        this.form.fields.isExciseDuty.setValue(this.state.attribute.isExciseDuty);
                        this.form.fields.exciseDutyList.setValue(this.state.attribute.exciseDutyList);
                        return [2];
                }
            });
        });
    };
    KnAttributeUpdateScreen.prototype.fetchAttribute = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/kn-attribute/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        response = _c.sent();
                        this.setState({ attribute: response });
                        return [3, 4];
                    case 2:
                        e_1 = _c.sent();
                        console.log(e_1);
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    KnAttributeUpdateScreen.prototype.onIsExciseDutyChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (value) {
                    this.setState({ isExciseDuty: true });
                    return [2, this.form.fields.exciseDutyList.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))])];
                }
                this.setState({ isExciseDuty: false });
                return [2, this.form.fields.exciseDutyList.removeValidators()];
            });
        });
    };
    KnAttributeUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'KNAttributeCreateScreen display-flex flex-column justify-content-center w-100' },
            React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('kn-attribute.update.screen.title')),
            React.createElement(form_1.Form, { className: 'KnAttributeCreateForm' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.knCode, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.isExciseDuty, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic color--neutral-6 fw-500' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.exciseDutyList, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { disabled: !this.state.isExciseDuty, className: 'select-input-basic', multiple: true, unclearable: false, searchable: true }))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelUpdate(); } }),
                React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('attribute.update.button.title'), onClick: function () { return _this.form.submit(); } })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("kn-attribute.error.".concat((0, trans_1.trans)((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message))) })));
    };
    KnAttributeUpdateScreen.prototype.cancelUpdate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                router_provider_1.RouterProvider.goTo('/kn-attributes');
                return [2];
            });
        });
    };
    KnAttributeUpdateScreen.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rawData, data, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        rawData = this.form.toJSON();
                        data = {
                            knCode: rawData.knCode,
                            isExciseDuty: rawData.isExciseDuty,
                            exciseDutyList: rawData.exciseDutyList.length ? rawData.exciseDutyList : [],
                        };
                        return [4, this.repository.put("/admin/kn-attribute/".concat(this.state.attribute._id), { data: data })];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('kn-attribute.update.success.toast'));
                        router_provider_1.RouterProvider.goTo('/kn-attributes');
                        return [3, 4];
                    case 2:
                        e_2 = _a.sent();
                        this.setState({ error: e_2 });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: true });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return KnAttributeUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.KnAttributeUpdateScreen = KnAttributeUpdateScreen;
