"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractForm = exports.typeOptions = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var lodash_1 = require("lodash");
var React = require("react");
var file_uploader_1 = require("../../../../../components/file-uplader/file-uploader");
var email_validator_1 = require("../../../../../components/form/validators/email.validator");
var extended_required_validator_1 = require("../../../../../components/form/validators/extended-required.validator");
var handle_toasts_1 = require("../../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../../components/modal/modal");
exports.typeOptions = [
    { value: 'basic', name: 'Alap szerződés', key: 'basic' }
];
var ContractForm = (function (_super) {
    __extends(ContractForm, _super);
    function ContractForm() {
        var _this = this;
        var _a, _b, _c, _d, _e;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.contractForm = new form_builder_1.FormBuilder({
            fields: {
                identifier: new field_1.Field({
                    placeholder: '',
                    value: (_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.identifier,
                    name: 'identifier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contract.form.field.identifier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                type: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.type) || [],
                    name: 'type',
                    options: exports.typeOptions,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contract.form.field.type.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                validFrom: new field_1.Field({
                    placeholder: '',
                    value: ((_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.validFrom) || new Date(),
                    name: 'validFrom',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contract.form.field.validFrom.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                validTo: new field_1.Field({
                    placeholder: '',
                    value: ((_d = _this.props.values) === null || _d === void 0 ? void 0 : _d.validTo) || new Date(),
                    name: 'validTo',
                    label: '',
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description',
                    label: React.createElement("div", null, (0, trans_1.trans)('contract.form.field.description.label')),
                    validators: []
                }),
                files: new field_1.Field({
                    placeholder: '',
                    value: ((_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.files) || [],
                    name: 'files',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contract.form.field.files.label'),
                        " "),
                    validators: []
                }),
            }
        });
        _this.contactForm = new form_builder_1.FormBuilder({
            fields: {
                lastName: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'lastName',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contact.form.field.lastName.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                firstName: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'firstName',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contact.form.field.firstName.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                email: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'email',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contact.form.field.email.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required')), new email_validator_1.EmailValidator((0, trans_1.trans)('form.validation.invalid-email'))]
                }),
                phone: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'phone',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contact.form.field.phone.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                position: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'position',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('contact.form.field.position.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'comment',
                    label: React.createElement("div", null, (0, trans_1.trans)('contact.form.field.comment.label')),
                    validators: []
                })
            }
        });
        return _this;
    }
    ContractForm.prototype.render = function () {
        return React.createElement("div", null, this.renderContactModal());
    };
    ContractForm.prototype.renderContactModal = function () {
        var _this = this;
        return React.createElement(modal_1.Modal, { ref: function (ref) { return _this.$contractModal = ref; }, renderModalHeader: function () {
                return React.createElement("h3", null, !_this.props.isUpdate ? 'Szerződés hozzáadása' : 'Szerződés szerkesztése');
            }, renderModalContent: function (modalContext) {
                return React.createElement("div", null,
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("div", { className: 'row w-100 mb-4' },
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.identifier, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.validFrom, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                    React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.validTo, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                    React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', readOnly: false, disabled: false, type: 'date', locale: 'hu' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.type, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { readOnly: _this.props.readOnly, unclearable: false, multiple: true, className: 'select-input-basic' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: 'input-label' }, "Szerz\u0151d\u00E9s"),
                                React.createElement(form_control_1.FormControl, { field: _this.contractForm.fields.files, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(file_uploader_1.FileUploader, { disabled: _this.props.readOnly, title: React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                                            React.createElement("div", null,
                                                React.createElement("span", { className: 'color--primary-6 fw-700 cursor-pointer' }, "V\u00E1lassz f\u00E1jlt"),
                                                React.createElement("span", { className: 'fw-700' }, " vagy csak dobd ide")),
                                            React.createElement("div", null,
                                                React.createElement("span", { className: 'color--neutral-5' }, "PNG, JPG, JPEG - max 5MB"))), endpoint: '/b2b/upload' })))),
                        React.createElement("h4", null, "Kapcsolattart\u00F3"),
                        React.createElement("div", { className: 'row w-100' },
                            React.createElement("div", { className: "col-md-12 col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.lastName, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-md-12 col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.firstName, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.email, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.phone, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.position, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                            React.createElement("div", { className: "col-24" },
                                React.createElement(form_control_1.FormControl, { field: _this.contactForm.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))))),
                    React.createElement("div", { className: 'display-flex flex-row' },
                        React.createElement("div", null,
                            React.createElement(button_1.Button, { className: 'button-neutral-inverze button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                            React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: _this.props.isUpdate ? 'Mentés' : 'Hozzáadás', onClick: function () { return _this.submitContact(); } }))));
            }, renderTrigger: function () { return null; } });
    };
    ContractForm.prototype.open = function (data) {
        if (!!data) {
            this.contractForm.updateFields(data);
            this.contactForm.updateFields(data === null || data === void 0 ? void 0 : data.contact);
            this.setState({ updateIndex: data.index });
        }
        this.$contractModal.open();
    };
    ContractForm.prototype.submitContact = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var errors, contactErrors, hasError, data;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.contractForm.validate()];
                    case 1:
                        _d.sent();
                        return [4, ((_a = this.contractForm) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _d.sent();
                        return [4, ((_b = this.contactForm) === null || _b === void 0 ? void 0 : _b.getErrors())];
                    case 3:
                        contactErrors = _d.sent();
                        hasError = false;
                        (0, lodash_1.map)(__assign(__assign({}, errors), contactErrors), function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        data = __assign(__assign({}, this.contractForm.toJSON()), { contact: this.contactForm.toJSON() });
                        if (this.props.onSubmit) {
                            this.props.onSubmit(data, (_c = this.state) === null || _c === void 0 ? void 0 : _c.updateIndex);
                        }
                        return [4, this.contractForm.clear()];
                    case 4:
                        _d.sent();
                        return [4, this.contactForm.clear()];
                    case 5:
                        _d.sent();
                        this.contractForm.updateFields({});
                        this.contactForm.updateFields({});
                        this.$contractModal.close();
                        handle_toasts_1.HandleToasts.success(this.props.isUpdate ? 'Sikeres módosítás' : 'Sikeres hozzáadás');
                        return [2];
                }
            });
        });
    };
    return ContractForm;
}(abstract_component_1.AbstractComponent));
exports.ContractForm = ContractForm;
