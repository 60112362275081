"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TotalAmount = exports.DRS_CHARGE = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var lodash_1 = require("lodash");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
exports.DRS_CHARGE = 50;
var TotalAmount = (function (_super) {
    __extends(TotalAmount, _super);
    function TotalAmount() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            total: 0,
            drsTotal: 0,
            shippingCharge: 0,
            aggregatedTotal: 0
        };
        return _this;
    }
    TotalAmount.prototype.componentDidMount = function () {
        var _a = this.calculateTotals(this.props), total = _a.total, drsTotal = _a.drsTotal, shippingCharge = _a.shippingCharge, aggregatedTotal = _a.aggregatedTotal;
        this.setState({ total: total, drsTotal: drsTotal, shippingCharge: shippingCharge, aggregatedTotal: aggregatedTotal });
    };
    TotalAmount.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(prevProps.prices, this.props.prices)) {
            var _a = this.calculateTotals(this.props), total = _a.total, drsTotal = _a.drsTotal, shippingCharge = _a.shippingCharge, aggregatedTotal = _a.aggregatedTotal;
            this.setState({ total: total, drsTotal: drsTotal, shippingCharge: shippingCharge, aggregatedTotal: aggregatedTotal });
        }
        if (!(0, lodash_1.isEqual)(prevProps.isFreeShipping, this.props.isFreeShipping)) {
            var _b = this.calculateTotals(this.props), total = _b.total, drsTotal = _b.drsTotal, shippingCharge = _b.shippingCharge, aggregatedTotal = _b.aggregatedTotal;
            this.setState({ total: total, drsTotal: drsTotal, shippingCharge: shippingCharge, aggregatedTotal: aggregatedTotal });
        }
        if (!(0, lodash_1.isEqual)(prevProps.zipCode, this.props.zipCode)) {
            var _c = this.calculateTotals(this.props), total = _c.total, drsTotal = _c.drsTotal, shippingCharge = _c.shippingCharge, aggregatedTotal = _c.aggregatedTotal;
            this.setState({ total: total, drsTotal: drsTotal, shippingCharge: shippingCharge, aggregatedTotal: aggregatedTotal });
        }
        if (!(0, lodash_1.isEqual)(prevState.shippingCharge, this.state.shippingCharge)) {
            this.props.onShippingCalculation(this.state.shippingCharge);
        }
    };
    TotalAmount.prototype.render = function () {
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24' },
                React.createElement("h5", null,
                    "Term\u00E9k tot\u00E1l: ",
                    (0, currency_parser_1.moneyFormat)(this.state.total))),
            React.createElement("div", { className: 'col-24' },
                React.createElement("h5", null,
                    "DRS tot\u00E1l: ",
                    (0, currency_parser_1.moneyFormat)(this.state.drsTotal))),
            React.createElement("div", { className: 'col-24' },
                React.createElement("h5", null,
                    "Kisz\u00E1ll\u00EDt\u00E1si \u00E1r: ",
                    (0, currency_parser_1.moneyFormat)(this.state.shippingCharge))),
            React.createElement("div", { className: 'col-24' },
                React.createElement("h4", null,
                    "V\u00E9g\u00F6sszeg: ",
                    (0, currency_parser_1.moneyFormat)(this.state.aggregatedTotal))));
    };
    TotalAmount.prototype.calculateTotals = function (_a) {
        var e_1, _b;
        var prices = _a.prices, shippingChargeOptions = _a.shippingChargeOptions, zipCode = _a.zipCode, isFreeShipping = _a.isFreeShipping;
        var total = 0;
        var drsTotal = 0;
        try {
            for (var _c = __values(Object.entries(prices !== null && prices !== void 0 ? prices : {})), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), _1 = _e[0], data = _e[1];
                var price = data === null || data === void 0 ? void 0 : data.price;
                var amount = data === null || data === void 0 ? void 0 : data.amount;
                if (isNaN(price) || isNaN(amount)) {
                    continue;
                }
                total += price * amount;
                if (data.drs) {
                    drsTotal += exports.DRS_CHARGE * amount;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var shippingCharge = this.handleShippingCharge(zipCode, total, shippingChargeOptions, isFreeShipping);
        var aggregatedTotal = total + drsTotal + shippingCharge;
        return { total: total, drsTotal: drsTotal, shippingCharge: shippingCharge, aggregatedTotal: aggregatedTotal };
    };
    TotalAmount.prototype.handleShippingCharge = function (zipCode, total, shippingChargeOptions, isFreeShipping) {
        var _a;
        if (!shippingChargeOptions || !shippingChargeOptions.defaultShippingChargeHUF || isFreeShipping) {
            return 0;
        }
        if (!zipCode || !(shippingChargeOptions === null || shippingChargeOptions === void 0 ? void 0 : shippingChargeOptions.customCharges)) {
            return +shippingChargeOptions.defaultShippingChargeHUF;
        }
        var zipCodeAsNumber = +zipCode;
        var customShippingCharge = (_a = shippingChargeOptions.customCharges) === null || _a === void 0 ? void 0 : _a.find(function (customCharge) {
            var from = customCharge.zipCodes.from;
            var to = customCharge.zipCodes.to;
            return zipCodeAsNumber < to && zipCodeAsNumber > from;
        });
        if (!customShippingCharge) {
            return +shippingChargeOptions.defaultShippingChargeHUF;
        }
        if (!total || total < customShippingCharge.minimumThresholdHUF) {
            return +customShippingCharge.costHUF;
        }
        return 0;
    };
    return TotalAmount;
}(abstract_component_1.AbstractComponent));
exports.TotalAmount = TotalAmount;
