"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductReviewsComponent = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
var box_loader_1 = require("../../../../common/components/repository-list/loader/box-loader");
require("./product-review.scss");
var review_input_1 = require("./review-input");
var ProductReviewsComponent = (function (_super) {
    __extends(ProductReviewsComponent, _super);
    function ProductReviewsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false
        };
        return _this;
    }
    ProductReviewsComponent.prototype.render = function () {
        var _this = this;
        var _a, _b;
        return React.createElement("div", { className: 'ProductReviewComponent position-relative' },
            React.createElement("div", { className: 'mb-3' },
                React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.reviews')),
                React.createElement("span", null,
                    "\u00C1tlag: ", (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.sumRating) === null || _b === void 0 ? void 0 :
                    _b.toPrecision(2))),
            (!!this.state.loading || !!this.props.loading) && React.createElement(box_loader_1.BoxLoader, null),
            React.createElement("div", { className: 'mb-2' },
                React.createElement("div", null, this.renderReviews())),
            React.createElement(review_input_1.ReviewInput, { onLoad: function (v) { return _this.setState({ loading: v }); }, disabled: this.props.disabled, onChange: function () { return !!_this.props.onChange ? _this.props.onChange() : null; }, productId: this.props.productId }));
    };
    ProductReviewsComponent.prototype.renderReviews = function () {
        var _this = this;
        return React.createElement("div", null, this.props.ratings.map(function (r, k) {
            return React.createElement("div", { key: k },
                React.createElement("div", null,
                    _this.renderStars(r.value),
                    " ",
                    React.createElement("span", null, r === null || r === void 0 ? void 0 :
                        r.count,
                        " db")));
        }));
    };
    ProductReviewsComponent.prototype.renderStars = function (score) {
        return React.createElement("div", { className: 'display-flex align-items-center' },
            React.createElement("div", { className: 'mr-4' }, Array.from(Array(5).keys()).map(function (i) { return (React.createElement("i", { className: "".concat(score <= i ? 'fal' : 'fas', " fa-star"), key: i })); })));
    };
    return ProductReviewsComponent;
}(abstract_component_1.AbstractComponent));
exports.ProductReviewsComponent = ProductReviewsComponent;
