"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockDisposalViewScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
require("./b2b-stock-disposal.scss");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var B2bStockDisposalViewScreen = (function (_super) {
    __extends(B2bStockDisposalViewScreen, _super);
    function B2bStockDisposalViewScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            disposal: null,
            loading: false,
            error: '',
        };
        return _this;
    }
    B2bStockDisposalViewScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockDisposalViewScreen.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/stock-disposal/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        response = _c.sent();
                        this.setState({ disposal: response, loading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ error: e_1.message, loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bStockDisposalViewScreen.prototype.getLegalCode = function () {
        var _a;
        var legalCode = (_a = this.state.disposal) === null || _a === void 0 ? void 0 : _a.legalCode;
        if (!legalCode && legalCode !== 0) {
            return '';
        }
        var legalCodeData = issuing_legal_codes_1.DEFECT_LEGAL_CODES.find(function (item) { return item.code === legalCode; });
        if (!legalCodeData) {
            return '';
        }
        var code = legalCodeData.code, title = legalCodeData.title;
        return "".concat(code, " - ").concat(title);
    };
    B2bStockDisposalViewScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var legalCode = this.getLegalCode();
        return React.createElement("div", { className: 'B2bStockDisposalViewScreen row w-100' },
            React.createElement("div", { className: 'col-20 disposal-show-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-disposal'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (_a = this.state.disposal) === null || _a === void 0 ? void 0 :
                        _a.identifier,
                        " ",
                        (0, trans_1.trans)('stock-disposal.show.title'))),
                React.createElement("div", null,
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-8 mb-4' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('stock-disposal.view.data.reason')),
                            React.createElement("p", { className: 'fw-700 ml-2' }, (_b = this.state.disposal) === null || _b === void 0 ? void 0 : _b.reason))),
                    legalCode && React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-8 mb-4' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('stock-disposal.view.data.legalCode')),
                            React.createElement("p", { className: 'fw-700 ml-2' }, legalCode))),
                    React.createElement("div", { className: 'row mb-7' },
                        React.createElement("div", { className: 'col-14' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('stock-disposal.view.data.comment')),
                            React.createElement("p", { className: 'fw-700 ml-2' }, ((_c = this.state.reservation) === null || _c === void 0 ? void 0 : _c.comment) || '-')))),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('stock-disposal.view.data.products')),
                    React.createElement(table_1.Table, { className: 'table-basic DisposalTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { var _a; return _this.renderItems((_a = _this.state.disposal) === null || _a === void 0 ? void 0 : _a.plan); } }))),
            React.createElement("div", { className: 'col-4 stock-disposal-status-box-wrapper' }, this.renderStatusBox()));
    };
    B2bStockDisposalViewScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 45 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('amount'), { className: 'justify-content-center' })));
    };
    B2bStockDisposalViewScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-disposal.view.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockDisposalViewScreen.prototype.renderItems = function (items) {
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var ean = ((_a = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _a === void 0 ? void 0 : _a.ean) || '-';
            var sku = ((_b = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
            var manufacturer = ((_c = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _c === void 0 ? void 0 : _c.manufacturer) || '-';
            var title = ((_d = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _d === void 0 ? void 0 : _d.title) || '-';
            var vintage = ((_e = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _e === void 0 ? void 0 : _e.vintage) || '-';
            var capacity = ((_f = item === null || item === void 0 ? void 0 : item.__meta.product) === null || _f === void 0 ? void 0 : _f.capacity) || '-';
            return React.createElement(table_body_row_1.TableBodyRow, { key: item.ean },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                    React.createElement("div", null,
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                            React.createElement("span", null, ean)),
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                            React.createElement("span", null, sku)),
                        React.createElement("p", null,
                            manufacturer,
                            " ",
                            title,
                            " ",
                            vintage,
                            " (",
                            capacity,
                            ")"))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 45 },
                    React.createElement("p", null, (_j = (_h = (_g = item === null || item === void 0 ? void 0 : item.__meta) === null || _g === void 0 ? void 0 : _g.storage) === null || _h === void 0 ? void 0 : _h.path) === null || _j === void 0 ? void 0 : _j.map(function (s) { return s.title; }).join('/'))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement("p", null, item === null || item === void 0 ? void 0 : item.productLot)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'justify-content-center' },
                    React.createElement("p", null, item === null || item === void 0 ? void 0 :
                        item.amount,
                        " db")));
        });
    };
    B2bStockDisposalViewScreen.prototype.renderStatusBox = function () {
        return React.createElement("div", { className: 'stock-disposal-control-box mb-4' },
            React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('stock-disposal.view.control.status.title')),
            React.createElement("div", { className: 'stock-disposal-status-badge' },
                React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)('stock-disposal.view.control.status.disposed'))));
    };
    return B2bStockDisposalViewScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockDisposalViewScreen = B2bStockDisposalViewScreen;
