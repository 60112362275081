"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CouponUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var moment = require("moment");
var Papa = require("papaparse");
var React = require("react");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var cspids_1 = require("./cspids");
var discount_type_options_1 = require("./discount-type-options");
var CouponUpdateScreen = (function (_super) {
    __extends(CouponUpdateScreen, _super);
    function CouponUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                code: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'code',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.code'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('coupon.form.description_hu'),
                    validators: []
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('coupon.form.description_en'),
                    validators: []
                }),
                hasDateLimit: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'hasDateLimit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.userRedemptionLimit'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                hasRedemptionLimit: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'hasRedemptionLimit',
                    label: (0, trans_1.trans)('coupon.form.hasRedemptionLimit'),
                    validators: []
                }),
                hasUserRedemptionLimit: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'hasUserRedemptionLimit',
                    label: (0, trans_1.trans)('coupon.form.hasUserRedemptionLimit'),
                    validators: []
                }),
                discountType: new field_1.Field({
                    placeholder: '',
                    value: 'amount',
                    name: 'discountType',
                    options: discount_type_options_1.discountTypeOptions,
                    label: (0, trans_1.trans)('coupon.form.discountType'),
                    validators: []
                }),
                amountDiscountHuf: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'amountDiscountHuf',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.amountDiscountHuf'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                amountDiscountEur: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'amountDiscountEur',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.amountDiscountEur'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                minimumValueHuf: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'minimumValueHuf',
                    label: (0, trans_1.trans)('coupon.form.minimumValueHuf'),
                    validators: []
                }),
                minimumValueEur: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'minimumValueEur',
                    label: (0, trans_1.trans)('coupon.form.minimumValueEur'),
                    validators: []
                }),
                percentDiscount: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'percentDiscount',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.percentDiscount'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                gift: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'gift',
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.title) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.gift'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                validFrom: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'validFrom',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.validFrom'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                validTo: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'validTo',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.validTo'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                redemptionLimit: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'redemptionLimit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.redemptionLimit'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                userRedemptionLimit: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'userRedemptionLimit',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('coupon.form.userRedemptionLimit'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: []
                }),
                allowedCategories: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'allowedCategories',
                    optionsEndpoint: '/admin/b2c/category',
                    loadOptionsAfterMount: true,
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    label: (0, trans_1.trans)('coupon.form.allowedCategories'),
                    validators: []
                }),
                allowedProducts: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'allowedProducts',
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.title) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('coupon.form.allowedProducts'),
                    validators: []
                }),
                allowedGroups: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'allowedGroups',
                    options: (cspids_1.CSPIDS.map(function (i) { return ({ name: i, key: i, value: i }); })),
                    loadOptionsAfterMount: false,
                    label: (0, trans_1.trans)('coupon.form.allowedGroups'),
                    validators: []
                }),
                forbiddenCategories: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'forbiddenCategories',
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/admin/b2c/category',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('coupon.form.forbiddenCategories'),
                    validators: []
                }),
                hasEmailList: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'hasEmailList',
                    label: (0, trans_1.trans)('coupon.form.hasEmailList'),
                    validators: []
                }),
                forbiddenProducts: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'forbiddenProducts',
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.title) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/admin/product',
                    loadOptionsAfterMount: true,
                    label: (0, trans_1.trans)('coupon.form.forbiddenProducts'),
                    validators: []
                }),
                forbiddenGroups: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'forbiddenGroups',
                    options: (cspids_1.CSPIDS.map(function (i) { return ({ name: i, key: i, value: i }); })),
                    loadOptionsAfterMount: false,
                    label: (0, trans_1.trans)('coupon.form.forbiddenGroups'),
                    validators: []
                })
            }
        });
        _this.state = {
            error: null,
            coupon: null,
            hasDateLimit: false,
            hasRedemptionLimit: false,
            hasUserRedemptionLimit: false,
            emailList: [],
            hasEmailList: false,
            discountType: discount_type_options_1.discountTypeOptions[0].value
        };
        return _this;
    }
    CouponUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.updateCoupon(); });
        this.form.fields.hasDateLimit.$value.subscribe(function (value) { return _this.toggleDateLimit(value); });
        this.form.fields.hasRedemptionLimit.$value.subscribe(function (value) { return _this.toggleRedemptionLimit(value); });
        this.form.fields.hasUserRedemptionLimit.$value.subscribe(function (value) { return _this.toggleUserRedemptionLimit(value); });
        this.form.fields.discountType.$value.subscribe(function (value) { return _this.toggleDiscountType(value); });
        this.form.fields.hasEmailList.$value.subscribe(function (value) { return _this.toggleEmailList(value); });
        this.fetchCoupon();
    };
    CouponUpdateScreen.prototype.toggleEmailList = function (value) {
        this.setState({ hasEmailList: value });
    };
    CouponUpdateScreen.prototype.fetchCoupon = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return __awaiter(this, void 0, void 0, function () {
            var response, _r, _s, key, _t, _u, key, e_1;
            var e_2, _v, e_3, _w;
            return __generator(this, function (_x) {
                switch (_x.label) {
                    case 0:
                        _x.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/coupon/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.couponId))];
                    case 1:
                        response = _x.sent();
                        this.setState({ coupon: response });
                        try {
                            for (_r = __values(Object.keys(this.form.fields)), _s = _r.next(); !_s.done; _s = _r.next()) {
                                key = _s.value;
                                if (key === 'title_hu') {
                                    this.form.fields.title_hu.setValue(this.state.coupon.title.hu);
                                    continue;
                                }
                                if (key === 'title_en') {
                                    this.form.fields.title_en.setValue(this.state.coupon.title.en);
                                    continue;
                                }
                                if (key === 'description_hu') {
                                    this.form.fields.description_hu.setValue(this.state.coupon.description.hu);
                                    continue;
                                }
                                if (key === 'description_en') {
                                    this.form.fields.description_en.setValue(this.state.coupon.description.en);
                                    continue;
                                }
                                (_d = (_c = this.form.fields) === null || _c === void 0 ? void 0 : _c[key]) === null || _d === void 0 ? void 0 : _d.setValue((_e = this.state.coupon) === null || _e === void 0 ? void 0 : _e[key]);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_s && !_s.done && (_v = _r.return)) _v.call(_r);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        try {
                            for (_t = __values(Object.keys(this.state.coupon.limitation)), _u = _t.next(); !_u.done; _u = _t.next()) {
                                key = _u.value;
                                (_f = this.form.fields[key]) === null || _f === void 0 ? void 0 : _f.setValue((_g = this.state.coupon) === null || _g === void 0 ? void 0 : _g.limitation[key]);
                                if (key === 'validFrom') {
                                    (_h = this.form.fields[key]) === null || _h === void 0 ? void 0 : _h.setValue(moment((_j = this.state.coupon) === null || _j === void 0 ? void 0 : _j.limitation[key]).format('YYYY-MM-DD'));
                                }
                                if (key === 'validTo') {
                                    (_k = this.form.fields[key]) === null || _k === void 0 ? void 0 : _k.setValue(moment((_l = this.state.coupon) === null || _l === void 0 ? void 0 : _l.limitation[key]).format('YYYY-MM-DD'));
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_u && !_u.done && (_w = _t.return)) _w.call(_t);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        if ((_o = (_m = this.state.coupon) === null || _m === void 0 ? void 0 : _m.limitation) === null || _o === void 0 ? void 0 : _o.hasEmailList) {
                            this.setState({ hasEmailList: true, emailList: ((_q = (_p = this.state.coupon) === null || _p === void 0 ? void 0 : _p.limitation) === null || _q === void 0 ? void 0 : _q.emailList) || [] });
                        }
                        return [3, 3];
                    case 2:
                        e_1 = _x.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CouponUpdateScreen.prototype.toggleDateLimit = function (value) {
        if (value) {
            this.form.fields.validFrom.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            this.form.fields.validTo.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
        }
        else {
            this.form.fields.validFrom.setValidators([]);
            this.form.fields.validTo.setValidators([]);
        }
        this.setState({ hasDateLimit: value });
    };
    CouponUpdateScreen.prototype.toggleRedemptionLimit = function (value) {
        if (value) {
            this.form.fields.redemptionLimit.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
        }
        else {
            this.form.fields.redemptionLimit.setValidators([]);
        }
        this.setState({ hasRedemptionLimit: value });
    };
    CouponUpdateScreen.prototype.toggleDiscountType = function (value) {
        this.setState({ discountType: value });
        if (value === 'amount') {
            this.form.fields.percentDiscount.removeValidators();
            this.form.fields.gift.removeValidators();
            this.form.fields.amountDiscountHuf.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            this.form.fields.amountDiscountEur.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            return;
        }
        if (value === 'percent') {
            this.form.fields.amountDiscountHuf.removeValidators();
            this.form.fields.amountDiscountEur.removeValidators();
            this.form.fields.gift.removeValidators();
            this.form.fields.percentDiscount.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            return;
        }
        if (value === 'gift') {
            this.form.fields.amountDiscountHuf.removeValidators();
            this.form.fields.amountDiscountEur.removeValidators();
            this.form.fields.percentDiscount.removeValidators();
            this.form.fields.gift.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            return;
        }
    };
    CouponUpdateScreen.prototype.toggleUserRedemptionLimit = function (value) {
        this.setState({ hasUserRedemptionLimit: value });
        if (value) {
            this.form.fields.userRedemptionLimit.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
        }
        else {
            this.form.fields.userRedemptionLimit.setValidators([]);
        }
    };
    CouponUpdateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'CouponCreateScreen  justify-content-center w-100' },
            React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('coupon.update.screen.title')),
            this.renderCouponForm());
    };
    CouponUpdateScreen.prototype.renderCouponForm = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'CouponCreateForm row w-100' },
            React.createElement("div", { className: "col-24" },
                React.createElement(form_1.Form, null,
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-8 col-md-12 col-24" },
                            React.createElement("h4", { className: 'mb-1' }, (0, trans_1.trans)('coupon.update.screen.basic.subtitle')),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.code, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic' })),
                            React.createElement("div", null,
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.discountType, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic' })),
                                this.state.discountType === 'amount' &&
                                    React.createElement("div", null,
                                        React.createElement(form_control_1.FormControl, { field: this.form.fields.amountDiscountHuf, type: text_input_form_control_type_1.TextInputFormControlType },
                                            React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                                        React.createElement(form_control_1.FormControl, { field: this.form.fields.amountDiscountEur, type: text_input_form_control_type_1.TextInputFormControlType },
                                            React.createElement(text_input_1.TextInput, { className: 'text-input-basic' }))),
                                this.state.discountType === 'percent' &&
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.percentDiscount, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                                this.state.discountType === 'gift' &&
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.gift, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { searchable: true, multiple: false, className: 'select-input-basic' })))),
                        React.createElement("div", { className: "col-lg-8 col-md-12 col-24" },
                            React.createElement("h4", { className: 'mb-1' }, (0, trans_1.trans)('coupon.update.screen.restrictions.subtitle')),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.minimumValueHuf, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic', type: 'number' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.minimumValueEur, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic', type: 'number' })),
                            React.createElement("div", null,
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.hasDateLimit, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: false, tabIndex: 1, className: 'checkbox-input-basic' }))),
                            this.state.hasDateLimit &&
                                React.createElement("div", null,
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.validFrom, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                        React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' })),
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.validTo, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                        React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' }))),
                            React.createElement("div", null,
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.hasRedemptionLimit, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: false, tabIndex: 1, className: 'checkbox-input-basic' }))),
                            this.state.hasRedemptionLimit &&
                                React.createElement("div", null,
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.redemptionLimit, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' }))),
                            React.createElement("div", null,
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.hasUserRedemptionLimit, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: false, tabIndex: 1, className: 'checkbox-input-basic' }))),
                            this.state.hasUserRedemptionLimit &&
                                React.createElement("div", null,
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.userRedemptionLimit, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { type: 'number', className: 'text-input-basic' })))),
                        React.createElement("div", { className: "col-lg-8 col-md-12 col-24" },
                            React.createElement("h4", { className: 'mb-1' }, (0, trans_1.trans)('coupon.update.screen.productRestrictions.subtitle')),
                            React.createElement("div", null,
                                React.createElement("h5", null, (0, trans_1.trans)('coupon.update.screen.subtitle.allowedProducts')),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.allowedCategories, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.allowedProducts, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.allowedGroups, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' }))),
                            React.createElement("div", null,
                                React.createElement("h5", null, (0, trans_1.trans)('coupon.update.screen.subtitle.forbiddenProducts')),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.forbiddenCategories, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.forbiddenProducts, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.forbiddenGroups, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })))),
                        React.createElement("div", { className: "col-lg-8 col-md-12 col-24" },
                            React.createElement("div", null,
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.hasEmailList, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: false, tabIndex: 1, className: 'checkbox-input-basic' }))),
                            this.state.hasEmailList && React.createElement("div", null,
                                this.renderEmailListInput(),
                                React.createElement("div", null,
                                    React.createElement("h4", null, "Email c\u00EDmek"),
                                    React.createElement("div", { className: 'display-flex' }, (this.state.emailList || []).map(function (e) { return (React.createElement("span", { key: e, className: 'mr-2' }, e)); })))))))),
            React.createElement("div", { className: "col-24" },
                React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelCreate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('coupon.update.button.title'), onClick: function () { return _this.form.submit(); } })),
                React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                    React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("coupon.update.".concat((0, trans_1.trans)((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message))) }))));
    };
    CouponUpdateScreen.prototype.cancelCreate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/coupons');
                        return [2];
                }
            });
        });
    };
    CouponUpdateScreen.prototype.updateCoupon = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, parsedData, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        data = this.form.toJSON();
                        parsedData = __assign(__assign({}, data), { title: { hu: data === null || data === void 0 ? void 0 : data.title_hu, en: data.title_en }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en }, limitation: {
                                hasRedemptionLimit: data.hasRedemptionLimit,
                                hasDateLimit: data.hasDateLimit,
                                hasUserRedemptionLimit: data.hasUserRedemptionLimit,
                                validFrom: data.validFrom,
                                validTo: data.validTo,
                                hasEmailList: data.hasEmailList,
                                emailList: this.state.emailList,
                                userRedemptionLimit: data.userRedemptionLimit,
                                redemptionLimit: data.redemptionLimit,
                                minimumValueHuf: data.minimumValueHuf,
                                minimumValueEur: data.minimumValueEur,
                                allowedGroups: data.allowedGroups,
                                allowedCategories: data.allowedCategories,
                                allowedProducts: data.allowedProducts,
                                forbiddenCategories: data.forbiddenCategories,
                                forbiddenGroups: data.forbiddenGroups,
                                forbiddenProducts: data.forbiddenProducts
                            } });
                        return [4, this.repository.put("/admin/coupon/".concat(this.state.coupon._id), { data: parsedData })];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('coupon.update.success.toast'));
                        router_provider_1.RouterProvider.goTo('/coupons');
                        return [3, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.setState({ error: e_4 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CouponUpdateScreen.prototype.renderEmailListInput = function () {
        var _this = this;
        return React.createElement("div", { className: 'CsvUploadButton button-primary-normal button-size-normal' },
            React.createElement("span", { className: 'button-title' }, "Feltoltes"),
            React.createElement("input", { ref: function (ref) { return _this.emailListInputRef = ref; }, onChange: (function (event) { return _this.readEmailListFile(event); }), type: "file", className: 'button-primary-normal button-size-normal' }));
    };
    CouponUpdateScreen.prototype.readEmailListFile = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var files, file, text, parsedEmailListFile, emailList, _a, _b, item;
            var e_5, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        files = event.target.files;
                        file = files.item(0);
                        if (!file) {
                            return [2];
                        }
                        return [4, file.text()];
                    case 1:
                        text = _d.sent();
                        parsedEmailListFile = Papa.parse(text.trimEnd(), {
                            header: true,
                            dynamicTyping: true,
                        });
                        emailList = [];
                        try {
                            for (_a = __values(parsedEmailListFile.data), _b = _a.next(); !_b.done; _b = _a.next()) {
                                item = _b.value;
                                emailList.push(item.email || '');
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                        this.setState({ emailFile: parsedEmailListFile, emailList: emailList });
                        return [2];
                }
            });
        });
    };
    return CouponUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.CouponUpdateScreen = CouponUpdateScreen;
