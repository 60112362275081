"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockProductCustomerPartnerGroupTable = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var pagination_list_control_1 = require("../../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../../../common/components/repository-list/repository-list");
var StockProductCustomerPartnerGroupTable = (function (_super) {
    __extends(StockProductCustomerPartnerGroupTable, _super);
    function StockProductCustomerPartnerGroupTable() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_k = (_g = (_f = (_e = (_d = _this.props.searchControl$) === null || _d === void 0 ? void 0 : _d.getQuery) === null || _e === void 0 ? void 0 : _e.call(_d)) === null || _f === void 0 ? void 0 : _f.query) !== null && _g !== void 0 ? _g : (_j = (_h = _this.props.route) === null || _h === void 0 ? void 0 : _h.query) === null || _j === void 0 ? void 0 : _j.query) !== null && _k !== void 0 ? _k : '',
                sort: (_m = (_l = _this.props.route) === null || _l === void 0 ? void 0 : _l.sort) !== null && _m !== void 0 ? _m : { createdAt: -1 }
            },
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    StockProductCustomerPartnerGroupTable.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c;
        (_c = (_b = (_a = this.props.searchControl$) === null || _a === void 0 ? void 0 : _a.query$) === null || _b === void 0 ? void 0 : _b.subscribe) === null || _c === void 0 ? void 0 : _c.call(_b, function (v) { var _a; return _this.control.set('query', ((_a = _this.props.searchControl$.getQuery()) === null || _a === void 0 ? void 0 : _a.query) || ''); });
    };
    StockProductCustomerPartnerGroupTable.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'StockProductCustomerPartnerGroupTable' },
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/stock-reservation/stock-product-customer-partner-reservation', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    StockProductCustomerPartnerGroupTable.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    StockProductCustomerPartnerGroupTable.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 35 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('owners'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('release'), { className: 'justify-content-center' })));
    };
    StockProductCustomerPartnerGroupTable.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("reservation.cp-partner-reservation.table.header.".concat(p)),
            property: p
        };
    };
    StockProductCustomerPartnerGroupTable.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    StockProductCustomerPartnerGroupTable.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    StockProductCustomerPartnerGroupTable.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var ean = (item === null || item === void 0 ? void 0 : item.ean) || '-';
        var sku = ((_b = (_a = item === null || item === void 0 ? void 0 : item.__meta) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_d = (_c = item === null || item === void 0 ? void 0 : item.__meta) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.manufacturer) || '-';
        var title = ((_f = (_e = item === null || item === void 0 ? void 0 : item.__meta) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.title) || '-';
        var vintage = ((_h = (_g = item === null || item === void 0 ? void 0 : item.__meta) === null || _g === void 0 ? void 0 : _g.product) === null || _h === void 0 ? void 0 : _h.vintage) || '-';
        var capacity = ((_k = (_j = item === null || item === void 0 ? void 0 : item.__meta) === null || _j === void 0 ? void 0 : _j.product) === null || _k === void 0 ? void 0 : _k.capacity) || '-';
        var storagePath = (_m = (_l = item === null || item === void 0 ? void 0 : item.__meta) === null || _l === void 0 ? void 0 : _l.storagePath) === null || _m === void 0 ? void 0 : _m.map(function (segment) { return segment.title; }).join('/');
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'cursor-pointer' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title,
                        " ",
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 40 },
                React.createElement("span", null, storagePath)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, item.lot)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: 'flex-wrap' },
                React.createElement("p", null, (_p = (_o = item === null || item === void 0 ? void 0 : item.__meta) === null || _o === void 0 ? void 0 : _o.owners) === null || _p === void 0 ? void 0 : _p.join(' | '))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null,
                    item.amount,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10, className: 'justify-content-center' },
                React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo("/customer-partner-stock-reservation/".concat(item._id, "/release")); }, title: (0, trans_1.trans)('reservation.list.release.cp.button'), className: 'button-neutral-normal button-size-small mr-4' })));
    };
    StockProductCustomerPartnerGroupTable.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return StockProductCustomerPartnerGroupTable;
}(abstract_component_1.AbstractComponent));
exports.StockProductCustomerPartnerGroupTable = StockProductCustomerPartnerGroupTable;
