module.exports = {
    'product.list.title': 'Termékek',
    'product.list.button.create.title': 'Új termék hozzáadása',
    'product.table.header.sku': 'Cikkszám',
    'product.table.header.vintage': 'Évjárat',
    'product.table.header.manufacturer': 'Pince',
    'product.table.header.title': 'Cím',
    'product.table.header.subTitle': 'Alcím',
    'product.table.header.name': 'Terméknév',
    'product.table.header.b2cCategory': 'Kategória',
    'product.table.header.status': 'Státusz',
    'product.table.header.supplierPrice': 'Beszállító ár',
    'product.table.header.createdAt': 'Létrehozva',
    'product.table.header.priceList': 'Árlista',
    'product.table.header.salesChannels': 'Csatorna',
    'product.status.active': 'aktív',
    'product.status.inactive': 'inaktív',
    'product.status.blocked': 'blokkolt',
    'product.update.title': 'Termék szerkesztése',
    'product.create.title': 'Termék létrehozás',
    'product.update.subtitle.images': 'Képek',
    'product.update.subtitle.basic-data': 'Alap adatok',
    'product.update.subtitle.vam-informatika': 'Vám informatika adatok',
    'product.update.subtitle.excise-duty': 'Jövedéki adatok',
    'product.update.subtitle.seo': 'SEO',
    'product.update.subtitle.attributes': 'Jellemzők',
    'product.update.subtitle.bundle-settings': 'Csomag beállítás',
    'product.update.subtitle.supplier-settings': 'Beszállító beállítás',
    'product.update.subtitle.specialist': 'Szakértőnk szerint',
    'product.update.subtitle.prizes': 'Díjak',
    'product.update.subtitle.reviews': 'Értékelések',
    'product.update.basic-data.form.manufacturer.name.label': 'Pince',
    'product.update.basic-data.form.b2cCategory.name.label': 'Kategória',
    'product.update.save-box.button.status-back': 'Státusz visszaállítása:',
    'product.update.save-box.button.sent-to-partner': 'Jóváhagyás és továbbítás',
    'product.update.save-box.button.approve-by-partner': 'Jóváhagyás és továbbítás',
    'product.update.save-box.button.approve-by-commercial': 'Jóváhagyás (commercial)',
    'product.update.save-box.button.approve-by-marketing': 'Jóváhagyás (marketing)',
    'product.publish': 'Közzététel az oldalon',
    'product.unpublish': 'Elrejtés az oldalról',
    'product.distribute': 'Aktiválás',
    'product.undistribute': 'Inaktiválás',
    'image-uploader.input.title': 'Kép feltöltése',
    'image-uploader.empty.line-1': 'Húzd ide a dokmentumot',
    'image-uploader.empty.line-2': 'vagy',
    'image-uploader.empty.line-3': 'Tallózz a gépeden',
    'image-uploader.enabled-format.title': 'Megengedett formátumok: png, jpg, jpeg',
    'image-uploader.enabled-size.title': 'Megengedett maximum fájméret: 24 MB',
    'product.update.bundle.form.field.isBundle.label': 'Csomagajánlat',
    'product.update.bundle.form.field.bundleProduct.label': 'Termék',
    'product.update.basic-data.form.field.custom.sku.label': 'Hiper cikkszám',
    'product.update.basic-data.form.field.sku.label': 'Cikkszám',
    'product.update.basic-data.form.field.varietyCode.label': 'Fajtakód',
    'product.update.basic-data.form.field.group.label': 'CSPID',
    'product.update.basic-data.form.field.price-gross.label': 'B2C Bruttó Ár (HUF)',
    'product.update.basic-data.form.field.name.label': 'Termék neve',
    'product.update.basic-data.form.field.title.label': 'Termék címe',
    'product.update.basic-data.form.field.subTitle.label': 'Termék alcíme',
    'product.update.basic-data.form.field.drs.label': 'DRS',
    'product.update.basic-data.form.description.name.label': 'Termék leírása',
    'product.update.basic-data.form.field.productLabel.label': 'Termék címke',
    'product.update.basic-data.form.field.vintage.label': 'Évjárat',
    'product.update.basic-data.form.field.b2cCategory.label': 'Kategória',
    'product.update.basic-data.form.field.salesChannels.label': 'Csatornák',
    'product.update.basic-data.form.field.status.label': 'Státusz',
    'product.update.basic-data.form.field.distributed.label': 'Aktív',
    'product.update.basic-data.form.field.hasNoSupplierPrice.label': 'Beszállító ár',
    'product.update.vam-informatika.form.field.country-of-origin.label': 'Származási ország',
    'product.update.vam-informatika.form.field.kn-code.label': 'KN kód',
    'product.update.vam-informatika.form.field.varietyCode.label': 'Fajtakód',
    'product.update.vam-informatika.form.field.net-weight.label': 'Nettó tömeg (kg)',
    'product.update.vam-informatika.form.field.gross-weight.label': 'Bruttó tömeg (kg)',
    'product.update.vam-informatika.form.field.alcohol-content.label': 'Alkohofok (%)',
    'product.update.vam-informatika.form.field.excise-duty-measurement.label': 'Jövedéki adó mértékegysége',
    'product.update.vam-informatika.form.field.ean-code.label': 'Palack EAN/13',
    'product.update.excise-duty.form.field.kn-code.label': 'KN kód',
    'product.update.excise-duty.form.field.excise-duty-type.label': 'Jövedéki fajta kód',
    'product.update.excise-duty.form.field.bottle-volume.label': 'Űrtartalom (L)',
    'product.update.excise-duty.form.field.alcohol-content.label': 'Alkoholfok (%)',
    'product.update.excise-duty.form.field.bottle-net-weight.label': 'Palack nettó súlya (kg)',
    'product.update.excise-duty.form.field.bottle-gross-weight.label': 'Palack bruttó súlya (kg)',
    'product.update.subtitle.custom-sku': 'Hiper cikkszámok',
    'product.update.subtitle.extra-attributes': 'Kiegészítő adatok',
    'product-extra-attributes.name-cardboardPerRow': 'Karton/sor “db”',
    'product-extra-attributes.name-cardboardNetWeight': 'Karton nettó súly “kg”',
    'product-extra-attributes.name-cardboardRowPerPalette': 'Karton sor/raklap “db”',
    'product-extra-attributes.name-bottleEAN': 'Palack EAN/13',
    'product-extra-attributes.name-bottleGrossWeight': 'Palack bruttó súlya “kg”',
    'product-extra-attributes.name-cardboardHeight': 'Karton magassága “mm”',
    'product-extra-attributes.name-bottlePerCardboard': 'Palack / Karton “db”',
    'product-extra-attributes.name-cardboardEAN': 'Karton EAN/13',
    'product-extra-attributes.name-palletEAN': 'Raklap EAN',
    'product-extra-attributes.name-vtsz': 'KN kód/VTSZ szám',
    'product-extra-attributes.name-jovedekiFajta': 'Jövedéki fajtakód',
    'product-extra-attributes.name-bottleHeight': 'Palack magasság “mm"',
    'product-extra-attributes.name-packagingType': 'Csomagolás típusa',
    'product-extra-attributes.name-packagingKind': 'Csomagolás fajtája',
    'product-extra-attributes.name-bottleWidth': 'Palack szélesség “mm"',
    'product-extra-attributes.name-bottleDepth': 'Palack mélység “mm"',
    'product-extra-attributes.name-bottleNetWeight': 'Palack nettó súly “kg”',
    'product-extra-attributes.name-bottleVolume': 'Palack űrtartalma “L”',
    'product-extra-attributes.name-cardboardWidth': 'Karton szélesség “mm”',
    'product-extra-attributes.name-cardboardDepth': 'Karton mélység “mm”',
    'product-extra-attributes.name-paletteWeightGrossWeight': 'Raklap súly “kg”',
    'product-extra-attributes.name-cardboardGrossWeight': 'Karton bruttó súly “kg”',
    'product-extra-attributes.name-cardboardPerPalette': 'Karton/raklap “db”',
    'product-extra-attributes.name-bottlePerPalette': 'Palack/raklap “db”',
    'product-extra-attributes.name-paletteHeight': 'Raklap magasság “mm”',
    'product-extra-attributes.name-servingTemperatureFrom': 'Minimális fogyasztási hőmérséklet',
    'product-extra-attributes.name-servingTemperatureTo': 'Maximális fogyasztási hőmérséklet',
    'product-extra-attributes.name-servingTemperature': 'Fogyasztási hőmérséklet',
    'product-extra-attributes.name-paletteWeightNetWeight': 'Raklap nettó súly "kg"',
    'product-extra-attributes.name-technology': 'Technológia',
    'product-extra-attributes.name-ingredients': 'Összetevők',
    'product-extra-attributes.name-allergens': 'Allergének',
    'product.update.prize.form.add-new.button.title': 'Új díj hozzáadása',
    'product.update.prize.form.field.description.label': 'Leírás',
    'product.update.prize.form.field.prize.label': 'Díj neve',
    'product.update.prize.form.field.year.label': 'Díj éve',
    'product.update.save-box.button.save': 'Mentés',
    'product.update.save-box.last-modified.label': 'Utoljára módosította',
    'product.update.success.toast': 'A termék módosítása sikeresen megtörtént',
    'product.approve.success.toast': 'A termék státusz módosítása megtörtént',
    'product.publish.success.toast': 'A termék közzététele megtörtént',
    'product.unpublish.success.toast': 'A termék elrejtése megtörtént',
    'product.distribute.success.toast': 'Sikeres akiválás',
    'product.undistribute.success.toast': 'Sikeres inaktiválás',
    'product.create.success.toast': 'A termék létrehozása sikeresen megtörtént',
    'product.status-back.success.toast': 'A státusz visszaállítása sikeresen megtörtént',
    'product-update.status-box.title': 'Termék státusz',
    'product-update.status-box.Draft': 'Vázlat',
    'product-update.status-box.SentToPartner': 'Borász jóváhagyás',
    'product-update.status-box.FilledByPartner': 'Partner adatfeltöltés',
    'product-update.status-box.CommercialApproved': 'Kereskedelmi jóváhagyás',
    'product-update.status-box.MarketingApproved': 'Marketing jóváhagyás',
    'product-update.status-box.badge.VamInformatikaReady': 'Vám Informatikának küldhető',
    'product-update.status-box.badge.NotVamInformatikaReady': 'Vám Informatikai adatok hiányosak',
    'product.status.Draft': 'Vázlat',
    'product.status.SentToPartner': 'Borász kitöltésre vár',
    'product.status.FilledByPartner': 'Kereskedelmi jóváhagyásra vár',
    'product.status.CommercialApproved': 'Marketing jóváhagyásra vár',
    'product.status.MarketingApproved': 'Marketing jóváhagyta',
    'product.status.waitingToPublish': 'Publikálásra vár',
    'product.status.published': 'Elérhető az oldalon',
    'product.status.unpublished': 'Nem elérhető az oldalon',
    'product.seo.form.title': 'Google meta - cím',
    'product.seo.form.description': 'Google meta - leírás',
    'product.seo.form.keywords': 'Google meta - kulcs szavak',
    'product.seo.form.image': 'Google meta - kép',
    'product.seo.form.og-title': 'Facebook meta - cím',
    'product.seo.form.og-description': 'Facebook meta - leírás',
    'product.seo.form.og-image': 'Facebook meta - kép',
    'product.seo.form.og-image-alt': 'Facebook meta - kép leírás',
    'product.supplier.form.supplier': 'Beszállító',
    'product.update.required.error.alcohol': 'Alkohol',
    'product.update.required.error.capacity': 'Űrtartalom (jellemzők)',
    'product.update.required.error.supplier': 'Beszállító',
    'product.update.required.error.bottleEAN': 'Palack EAN/13',
    'product.update.required.error.cardboardEAN': 'Karton EAN/13',
    'product.update.required.error.vtsz': 'KN kód/VTSZ szám',
    'product.update.required.error.jovedekiFajta': 'Jövedéki fajtakód',
    'product.update.required.error.bottleNetWeight': 'Palack nettó súly',
    'product.update.required.error.bottleVolume': 'Palack űrtartalma',
    'product.update.required.error.bottleGrossWeight': 'Palack bruttó súly',
    'product.update.required.error.knCode': 'KN kód',
    'product.update.required.error.exciseDutyType': 'Jövedéki fajta kód',
};
