module.exports = {
    'region.list.title': 'Régiók',
    'region.list.button.create.title': 'Új régió hozzáadása',
    'region.table.header.title.hu': 'Régió neve (HU)',
    'region.table.header.title.en': 'Régió neve (EN)',
    'region.table.header.code': 'Kód',
    'region.table.header.country': 'Ország',
    'region-create.screen.title': 'Régió létrehozása',
    'region.form.title': 'Régió neve',
    'region.form.country': 'Ország',
    'region.form.description': 'Leírás',
    'region.form.code': 'Kód',
    'region.form.fertileArea': 'Termő terület',
    'region.form.soil': 'Talaj',
    'region.form.area': 'Teljes terület',
    'region.form.climate': 'Klíma',
};
