"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploaderFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/sprinkles/libs/form/form-control/types/abstract.form-control-type");
var FileUploaderFormControlType = (function (_super) {
    __extends(FileUploaderFormControlType, _super);
    function FileUploaderFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FileUploaderFormControlType.prototype.getProps = function () {
        var _this = this;
        return {
            onChange: function (event) { return _this.formControl.props.field.setValue(event); },
            value: this.formControl.props.field.getValue(),
            label: this.formControl.props.field.getLabel(),
            placeholder: this.formControl.props.field.getPlaceholder(),
            required: this.formControl.props.field.getRequired(),
            errors: this.errors
        };
    };
    return FileUploaderFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.FileUploaderFormControlType = FileUploaderFormControlType;
