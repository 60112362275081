"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminMenuBodyComponent = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var React = require("react");
var user_permission_1 = require("../../libs/user-permission");
var admin_menu_1 = require("./admin-menu");
var admin_menu_footer_component_1 = require("./admin-menu-footer.component");
require("./admin-menu.component.scss");
var AdminMenuBodyComponent = (function (_super) {
    __extends(AdminMenuBodyComponent, _super);
    function AdminMenuBodyComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpen: _this.props.isOpen,
            openedSubmenu: []
        };
        return _this;
    }
    AdminMenuBodyComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
        }
    };
    AdminMenuBodyComponent.prototype.render = function () {
        return React.createElement("div", { className: "admin-menu-body ".concat(this.state.isOpen ? 'open' : '') },
            React.createElement("div", { className: 'menu-items-wrapper' }, this.renderMenuItems(admin_menu_1.adminMenu)),
            React.createElement("div", null,
                React.createElement(admin_menu_footer_component_1.AdminMenuFooterComponent, null)));
    };
    AdminMenuBodyComponent.prototype.renderMenuItems = function (items) {
        var _this = this;
        return items.map(function (item, index) {
            return _this.renderMenuItem(item);
        });
    };
    AdminMenuBodyComponent.prototype.renderMenuItem = function (item) {
        var _this = this;
        if (!(0, user_permission_1.checkPermission)(item.permissions)) {
            return;
        }
        if (item.items.length) {
            item.items.map(function (i) {
                if (_this.isActiveMenu(i.activeRegexp) && !_this.isOpenedSubmenu(item)) {
                    _this.toggleSubmenu(item);
                }
            });
            return React.createElement("div", null,
                React.createElement("div", { key: "menu-item-".concat(item.name), className: 'menu-item', onClick: function () { return _this.toggleSubmenu(item); } },
                    React.createElement("div", { className: "flex-fill" },
                        React.createElement("i", { className: item.icon }),
                        React.createElement("span", null, (0, trans_1.trans)("menu.item.".concat(item.name)))),
                    React.createElement("div", null, this.renderSubmenuChevron(item))),
                this.isOpenedSubmenu(item) && React.createElement("div", { className: 'submenu-items mb-4' }, this.renderMenuItems(item.items)));
        }
        return React.createElement("div", { key: "menu-item-".concat(item.name), className: "menu-item ".concat(this.isActiveMenu(item.activeRegexp) ? 'active' : ''), onClick: function () { return router_provider_1.RouterProvider.goTo(item.path); } },
            React.createElement("i", { className: item.icon }),
            React.createElement("span", null, (0, trans_1.trans)("menu.item.".concat(item.name))));
    };
    AdminMenuBodyComponent.prototype.toggleSubmenu = function (menu) {
        var currentOpened = this.state.openedSubmenu || [];
        if (this.state.openedSubmenu.includes(menu.name) || !menu.items.length) {
            return this.setState({ openedSubmenu: currentOpened.filter(function (m) { return m !== menu.name; }) });
        }
        return this.setState({ openedSubmenu: __spreadArray(__spreadArray([], __read(currentOpened), false), [menu.name], false) });
    };
    AdminMenuBodyComponent.prototype.isOpenedSubmenu = function (menu) {
        return this.state.openedSubmenu.includes(menu.name);
    };
    AdminMenuBodyComponent.prototype.renderSubmenuChevron = function (menu) {
        if (!menu.items.length) {
            return;
        }
        return this.isOpenedSubmenu(menu) ? React.createElement("i", { className: 'fas fa-chevron-up' }) :
            React.createElement("i", { className: 'fas fa-chevron-down' });
    };
    AdminMenuBodyComponent.prototype.isActiveMenu = function (regexp) {
        var _a;
        return regexp.test((_a = this.props.route) === null || _a === void 0 ? void 0 : _a.path);
    };
    return AdminMenuBodyComponent;
}(abstract_component_1.AbstractComponent));
exports.AdminMenuBodyComponent = AdminMenuBodyComponent;
