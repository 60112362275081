"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'reservation.list.main.title.cp': 'Partner foglalások',
    'reservation.list.main.title.ch': 'Csatorna foglalások',
    'reservation.list.create.cp.button': 'Új foglalás',
    'reservation.list.create.ch.button': 'Új foglalás',
    'reservation.list.tab.button.cp.group': 'PARTNER CSOPORTOK',
    'reservation.list.tab.button.cp.list': 'FOGLALÁSOK TÉTELENKÉNT',
    'reservation.list.tab.button.cp.release': 'FELOLDÁSOK',
    'reservation.list.tab.button.ch.group': 'CSATORNA CSOPORTOK',
    'reservation.list.tab.button.ch.list': 'FOGLALÁSOK TÉTELENKÉNT',
    'reservation.list.tab.button.ch.release': 'FELOLDÁSOK',
    'reservation.cp-partner-group.table.header.identifier': 'azonosító',
    'reservation.cp-partner-group.table.header.owners': 'Vevő cégek',
    'reservation.cp-partner-group.table.header.amount': 'mennyiség',
    'reservation.cp-partner-group.table.header.creator': 'létrehozó',
    'reservation.cp-partner-group.table.header.createdAt': 'létrehozva',
    'reservation.cp-partner-group-release.table.header.identifier': 'azonosító',
    'reservation.cp-partner-group-release.table.header.owners': 'Vevő cégek',
    'reservation.cp-partner-group-release.table.header.amount': 'mennyiség',
    'reservation.cp-partner-group-release.table.header.creator': 'létrehozó',
    'reservation.cp-partner-group-release.table.header.createdAt': 'létrehozva',
    'reservation.cp-partner-reservation.table.header.product': 'termék',
    'reservation.cp-partner-reservation.table.header.storage': 'tárhely',
    'reservation.cp-partner-reservation.table.header.lot': 'lot/nébih',
    'reservation.cp-partner-reservation.table.header.owners': 'vevőcégek',
    'reservation.cp-partner-reservation.table.header.amount': 'mennyiség',
    'reservation.cp-partner-reservation.table.header.release': 'feloldás',
    'reservation.ch-group.table.header.identifier': 'azonosító',
    'reservation.ch-group.table.header.channels': 'csatornák',
    'reservation.ch-group.table.header.amount': 'mennyiség',
    'reservation.ch-group.table.header.creator': 'létrehozó',
    'reservation.ch-group.table.header.createdAt': 'létrehozva',
    'reservation.ch-group-release.table.header.identifier': 'azonosító',
    'reservation.ch-group-release.table.header.channels': 'csatornák',
    'reservation.ch-group-release.table.header.amount': 'mennyiség',
    'reservation.ch-group-release.table.header.creator': 'létrehozó',
    'reservation.ch-group-release.table.header.createdAt': 'létrehozva',
    'reservation.ch-reservation.table.header.product': 'termék',
    'reservation.ch-reservation.table.header.storage': 'tárhely',
    'reservation.ch-reservation.table.header.lot': 'lot/nébih',
    'reservation.ch-reservation.table.header.channels': 'csatornák',
    'reservation.ch-reservation.table.header.amount': 'mennyiség',
    'reservation.ch-reservation.table.header.release': 'feloldás',
    'stock-reservation.create.cp.title': 'Partner foglalás',
    'stock-reservation.create.cp.form.supplier.label': 'Vevő partner',
    'stock-reservation.create.cp.form.comment.label': 'Megjegyzés',
    'stock-reservation.create.ch.title': 'Csatorna foglalás',
    'stock-reservation.create.ch.form.supplier.label': 'Csatornák',
    'stock-reservation.create.ch.form.comment.label': 'Megjegyzés',
    'stock-reservation.create.product-list.title': 'Termékek',
    'stock-reservation.create.product-list.add.button': 'Új termék hozzáadása',
    'stock-reservation.create.table.header.product': 'termék',
    'stock-reservation.create.table.header.storage': 'tárhely',
    'stock-reservation.create.table.header.lot': 'lot/nébih',
    'stock-reservation.create.table.header.amount': 'db',
    'stock-reservation.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'stock-reservation.view.table.header.product': 'termék',
    'stock-reservation.view.table.header.storage': 'tárhely',
    'stock-reservation.view.table.header.lot': 'lot/nébih',
    'stock-reservation.view.table.header.amount': 'db',
    'stock-reservation.create.success.toast': 'A foglalást sikeresen létrehoztuk',
    'stock-reservation.select-storage.modal.title': 'Tárhely választása',
    'stock-reservation.add-product.modal.title': 'Termék választása',
    'stock-reservation.show.cp.title': 'Partner foglalás',
    'stock-reservation.view.cp.data.owners': 'Vevő cégek',
    'stock-reservation.view.cp.data.comment': 'Megjegyzés',
    'stock-reservation.view.cp.data.products': 'Termékek',
    'stock-reservation.show.ch.title': 'Csatorna foglalás',
    'stock-reservation.view.ch.data.owners': 'Csatornák',
    'stock-reservation.view.ch.data.comment': 'Megjegyzés',
    'stock-reservation.view.ch.data.products': 'Termékek',
    'stock-reservation.view.control.status.title': 'STÁTUSZ',
    'stock-reservation.view.control.status.reserved': 'FOGLALVA',
    'stock-reservation.release.ch.title': 'Csatorna foglalás feloldása',
    'stock-reservation.release.ch.data.channels': 'Csatornák',
    'stock-reservation.release.ch.form.comment.label': 'Megjegyzés',
    'stock-reservation.release.ch.form.amount.label': 'Mennyiség',
    'stock-reservation.release.ch.data.products': 'Termék',
    'stock-reservation.release.table.header.product': 'Termék',
    'stock-reservation.release.table.header.storage': 'Tárhely',
    'stock-reservation.release.table.header.lot': 'lot/nébih',
    'stock-reservation.release.table.header.current-amount': 'jelenleg foglalt',
    'stock-reservation.release.table.header.amount-to-release': 'feloldandó mennyiség',
    'stock-reservation.release.success.toast': 'A feloldás sikeresen megtörtént',
    'reservation.list.release.ch.button': 'Feloldás',
    'stock-release.form.missing.required': 'Hiányzó kötelező adatok',
    'stock-release.form.wrong.amount': 'Hibás mennyiség! A mennyiség legyen nagyobb mint nulla és nem lehet nagyobb, mint a foglalt mennyiség.',
    'stock-reservation.release.cp.title': 'Partner foglalás feloldása',
    'stock-reservation.release.cp.data.owners': 'Vevő partnerek',
    'stock-reservation.release.cp.form.comment.label': 'Megjegyzés',
    'stock-reservation.release.cp.form.amount.label': 'Mennyiség',
    'stock-reservation.release.cp.data.products': 'Termék',
    'reservation.list.release.cp.button': 'Feloldás',
};
