"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkOwnership = exports.isSuperAdmin = exports.checkUserRole = void 0;
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var checkUserRole = function (role) {
    var _a, _b, _c;
    var user = local_store_1.LocalStore.getItem('User');
    var roles = (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.role) === null || _c === void 0 ? void 0 : _c.permissions;
    if (roles.includes('superadmin')) {
        return true;
    }
    return roles.includes(role);
};
exports.checkUserRole = checkUserRole;
var isSuperAdmin = function () {
    var _a, _b, _c;
    var user = local_store_1.LocalStore.getItem('User');
    var roles = (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.role) === null || _c === void 0 ? void 0 : _c.permissions;
    roles.includes('superadmin');
};
exports.isSuperAdmin = isSuperAdmin;
var checkOwnership = function (ownership) {
    var _a;
    var user = local_store_1.LocalStore.getItem('User');
    return ((_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.ownership) === ownership;
};
exports.checkOwnership = checkOwnership;
