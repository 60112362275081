"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'reservation.list.main.title.cp': 'Partner foglalások',
    'reservation.list.main.title.ch': 'Csatorna foglalások',
    'reservation.list.create.cp.button': 'Új foglalás',
    'reservation.list.release.cp.button': 'Új feloldás',
    'reservation.list.create.ch.button': 'Új foglalás',
    'reservation.list.release.ch.button': 'Új feloldás',
    'reservation.list.tab.button.cp.group': 'PARTNER CSOPORTOK',
    'reservation.list.tab.button.cp.list': 'FOGLALÁSOK TÉTELENKÉNT',
    'reservation.list.tab.button.cp.release': 'FELOLDÁSOK',
    'reservation.list.tab.button.ch.group': 'CSATORNA CSOPORTOK',
    'reservation.list.tab.button.ch.list': 'FOGLALÁSOK TÉTELENKÉNT',
    'reservation.list.tab.button.ch.release': 'FELOLDÁSOK',
    'reservation.cp-partner-group.table.header.identifier': 'azonosító',
    'reservation.cp-partner-group.table.header.owners': 'Vevő cégek',
    'reservation.cp-partner-group.table.header.amount': 'mennyiség',
    'reservation.cp-partner-group.table.header.creator': 'létrehozó',
    'reservation.cp-partner-group.table.header.createdAt': 'létrehozva',
    'reservation.cp-partner-group-release.table.header.identifier': 'azonosító',
    'reservation.cp-partner-group-release.table.header.owners': 'Vevő cégek',
    'reservation.cp-partner-group-release.table.header.amount': 'mennyiség',
    'reservation.cp-partner-group-release.table.header.creator': 'létrehozó',
    'reservation.cp-partner-group-release.table.header.createdAt': 'létrehozva',
    'reservation.cp-partner-reservation.table.header.product': 'termék',
    'reservation.cp-partner-reservation.table.header.storage': 'tárhely',
    'reservation.cp-partner-reservation.table.header.lot': 'lot/nébih',
    'reservation.cp-partner-reservation.table.header.owners': 'vevőcégek',
    'reservation.cp-partner-reservation.table.header.amount': 'mennyiség',
    'reservation.ch-group.table.header.identifier': 'azonosító',
    'reservation.ch-group.table.header.channels': 'csatornák',
    'reservation.ch-group.table.header.amount': 'mennyiség',
    'reservation.ch-group.table.header.creator': 'létrehozó',
    'reservation.ch-group.table.header.createdAt': 'létrehozva',
    'reservation.ch-group-release.table.header.identifier': 'azonosító',
    'reservation.ch-group-release.table.header.channels': 'csatornák',
    'reservation.ch-group-release.table.header.amount': 'mennyiség',
    'reservation.ch-group-release.table.header.creator': 'létrehozó',
    'reservation.ch-group-release.table.header.createdAt': 'létrehozva',
    'reservation.ch-reservation.table.header.product': 'termék',
    'reservation.ch-reservation.table.header.storage': 'tárhely',
    'reservation.ch-reservation.table.header.lot': 'lot/nébih',
    'reservation.ch-reservation.table.header.channels': 'csatornák',
    'reservation.ch-reservation.table.header.amount': 'mennyiség',
    'stock-reservation.create.cp.title': 'Partner foglalás',
    'stock-reservation.create.cp.form.supplier.label': 'Vevő partner',
    'stock-reservation.create.cp.form.comment.label': 'Megjegyzés',
    'stock-reservation.create.ch.title': 'Csatorna foglalás',
    'stock-reservation.create.ch.form.supplier.label': 'Csatornák',
    'stock-reservation.create.ch.form.comment.label': 'Megjegyzés',
    'stock-reservation.create.product-list.title': 'Termékek',
    'stock-reservation.create.product-list.add.button': 'Új termék hozzáadása',
    'stock-reservation.create.table.header.product': 'termék',
    'stock-reservation.create.table.header.storage': 'tárhely',
    'stock-reservation.create.table.header.lot': 'lot/nébih',
    'stock-reservation.create.table.header.amount': 'db',
    'stock-reservation.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'stock-reservation.create.success.toast': 'A foglalást sikeresen létrehoztuk',
    'stock-reservation.select-storage.modal.title': 'Tárhely választása',
    'stock-reservation.add-product.modal.title': 'Termék választása',
    'stock-reservation.form.missing.required': 'Kötelező mezők hiányoznak!'
};
