"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoldPerCategoryAmountChart = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var moment = require("moment");
var React = require("react");
var recharts_1 = require("recharts");
var chart_loader_1 = require("./components/chart-loader");
var pie_chart_custom_tooltip_1 = require("./components/pie-chart-custom-tooltip");
var time_selector_for_charts_1 = require("./components/time-selector-for-charts");
require("./dashboard-chart.scss");
var SoldPerCategoryAmountChart = (function (_super) {
    __extends(SoldPerCategoryAmountChart, _super);
    function SoldPerCategoryAmountChart() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.colors = ['#DF7067', '#DE4646', '#FACF7', '#E37F6A', '#4695DE', '#ffff00'];
        _this.state = {
            from: moment().day(1).startOf('day').toDate(),
            to: moment().toDate(),
            amount: [],
            loading: true,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                option: new field_1.Field({
                    placeholder: '',
                    value: 'ThisWeek',
                    name: 'option',
                    label: (0, trans_1.trans)('dashboard.charts.time-selector-label'),
                    options: [
                        { key: 0, name: 'Ez a hét', value: 'ThisWeek' },
                        { key: 1, name: 'Múlt hét', value: 'LastWeek' },
                        { key: 2, name: 'Múlt hónap', value: 'LastMonth' },
                        { key: 3, name: 'Múlt év', value: 'LastYear' },
                    ]
                }),
            }
        });
        return _this;
    }
    SoldPerCategoryAmountChart.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        this.form.fields.option.$value.subscribe(function (value) { return _this.handleIntervalChange(value); });
                        return [2];
                }
            });
        });
    };
    SoldPerCategoryAmountChart.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'dashboard-chart mb-8', style: { width: '100%', height: 390 } },
            React.createElement("h4", { className: 'mb-6' }, (0, trans_1.trans)('dashboard.charts.by-category.amount.title')),
            React.createElement("div", { className: 'display-flex justify-content-center mb-6' },
                React.createElement(time_selector_for_charts_1.TimeSelectorForCharts, { form: this.form })),
            React.createElement("div", { className: 'chart-wrapper' }, this.state.loading
                ? React.createElement(chart_loader_1.ChartLoader, null)
                : React.createElement(recharts_1.ResponsiveContainer, null,
                    React.createElement(recharts_1.PieChart, { width: 800, height: 180 },
                        React.createElement(recharts_1.Tooltip, { content: function (props) { return React.createElement(pie_chart_custom_tooltip_1.PieChartCustomTooltip, __assign({}, props, { unit: 'DB' })); } }),
                        React.createElement(recharts_1.Legend, { iconType: 'circle', layout: 'horizontal', align: 'center', verticalAlign: 'bottom' }),
                        React.createElement(recharts_1.Pie, { dataKey: 'value', data: this.state.amount, cx: "50%", cy: 90, innerRadius: 40, outerRadius: 80, label: true },
                            React.createElement(recharts_1.Label, { value: 'DB', offset: 0, position: 'center' }),
                            this.state.amount.map(function (entry, index) { return (React.createElement(recharts_1.Cell, { key: "cell-".concat(index), fill: _this.colors[index] })); }))))));
    };
    SoldPerCategoryAmountChart.prototype.fetchData = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var response, amount, _f, _g, key;
            var e_1, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/dashboard/sold-per-category?from=".concat(this.state.from, "&to=").concat(this.state.to))];
                    case 1:
                        response = _j.sent();
                        amount = [];
                        try {
                            for (_f = __values(Object.keys(response)), _g = _f.next(); !_g.done; _g = _f.next()) {
                                key = _g.value;
                                if (!!((_a = response === null || response === void 0 ? void 0 : response[key]) === null || _a === void 0 ? void 0 : _a.sumAmount)) {
                                    amount = __spreadArray(__spreadArray([], __read(amount), false), [
                                        {
                                            name: (_d = (_c = (_b = response === null || response === void 0 ? void 0 : response[key]) === null || _b === void 0 ? void 0 : _b.category) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.hu,
                                            value: (_e = response === null || response === void 0 ? void 0 : response[key]) === null || _e === void 0 ? void 0 : _e.sumAmount,
                                        }
                                    ], false);
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_g && !_g.done && (_h = _f.return)) _h.call(_f);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        this.setState({
                            amount: amount,
                            loading: false
                        });
                        return [2];
                }
            });
        });
    };
    SoldPerCategoryAmountChart.prototype.handleIntervalChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (value === 'ThisWeek') {
                            this.setState({
                                from: moment().startOf('week').toDate(),
                                to: moment().toDate(),
                            });
                        }
                        if (value === 'LastWeek') {
                            this.setState({
                                from: moment().day(-6).startOf('day').toDate(),
                                to: moment().day(0).endOf('day').toDate(),
                            });
                        }
                        if (value === 'LastMonth') {
                            this.setState({
                                from: moment().subtract(1, 'months').startOf('month').toDate(),
                                to: moment().subtract(1, 'months').endOf('month').toDate(),
                            });
                        }
                        if (value === 'LastYear') {
                            this.setState({
                                from: moment().subtract(1, 'years').startOf('year').toDate(),
                                to: moment().subtract(1, 'years').endOf('year').toDate(),
                            });
                        }
                        return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    return SoldPerCategoryAmountChart;
}(abstract_component_1.AbstractComponent));
exports.SoldPerCategoryAmountChart = SoldPerCategoryAmountChart;
