"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = {
    'inventory.list.main.title': 'Leltár',
    'inventory.list.create.button': 'Új leltár létrehozása',
    'stock-inventory.create.title': 'Leltár létrehozás',
    'stock-inventory.update.title': 'Leltár',
    'stock-inventory.form.label.warehouse': 'Raktár épület',
    'inventory.list.table.header.identifier': 'azonosító',
    'inventory.list.table.header.warehouse': 'raktár épület',
    'inventory.list.table.header.status': 'státusz',
    'inventory.list.table.header.creator': 'létrehozó',
    'inventory.list.table.header.createdAt': 'létrehozva',
    'inventory.table.header.storage': 'raktár terület',
    'stock-inventory.form.missing.required': 'Kötelező adatok hiányosak!',
    'stock-inventory.create.success.toast': 'Leltár sikeresen létrehozva',
    'stock-inventory.update.success.toast': 'Leltár sikeresen frissítve',
    'stock-inventory.execute.success.toast': 'Leltár végelegesítve',
    'stock-inventory.delete.success.toast': 'Sikeres törlés',
    'stock-inventory.delete.error.toast': 'A documentumot nem sikerült törölni',
    'b2b-inventory.delete-draft.modal.title': 'Törlés',
    'b2b-inventory.delete-draft.modal.text': 'Biztos, hogy törlöd a dokumentumot?',
    'b2b-inventory.delete-draft.modal.close.button': 'Mégse',
    'b2b-inventory.delete-draft.modal.delete.button': 'Törlés',
};
