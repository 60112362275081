"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelOrderModal = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var CancelOrderModal = (function (_super) {
    __extends(CancelOrderModal, _super);
    function CancelOrderModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                reason: new field_1.Field({
                    name: 'reason',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('b2b-order.form.label.cancel-reason'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                })
            }
        });
        return _this;
    }
    CancelOrderModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'row flex-column' },
            React.createElement(form_1.Form, { className: 'col' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.reason, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic' }))),
            React.createElement("div", { className: 'col display-flex justify-content-center' },
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.cancel.modal.close.button'), onClick: function () { return _this.props.onClose(); }, className: 'button-neutral-inverze button-size-normal' }),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.cancel.modal.cancel.button'), onClick: function () { return _this.cancelOrder(); }, className: 'button-primary-inverze button-size-normal ml-4' })));
    };
    CancelOrderModal.prototype.cancelOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, formData, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error((0, trans_1.trans)('b2b-order.create.form.missing.required'));
                        }
                        formData = this.form.toJSON();
                        return [4, this.repository.put("/b2b/order/cancel/".concat(this.props.order), { data: { reasonForCancel: formData.reason } })];
                    case 2:
                        _a.sent();
                        this.props.onClose();
                        window.location.reload();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.cancel.success.toast'));
                        this.setState({ loading: false });
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ loading: false });
                        console.log(e_1);
                        handle_toasts_1.HandleToasts.error((0, trans_1.trans)('b2b-order.cancel.error.toast'));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return CancelOrderModal;
}(abstract_component_1.AbstractComponent));
exports.CancelOrderModal = CancelOrderModal;
