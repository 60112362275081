module.exports = {
    'stock-issuing.list.main.title': 'Kitárolás',
    'stock-issuing.list.create.button': 'Új kitárolás',
    'b2b-stock-issuing.table.header.identifier': 'Azonosító',
    'b2b-stock-issuing.table.header.creator': 'Létrehozó',
    'b2b-stock-issuing.table.header.createdAt': 'Létrehozva',
    'b2b-stock-issuing.table.header.customerPartner': 'vevőcég',
    'b2b-stock-issuing.table.header.delivery-address': 'Szállítási cím',
    'b2b-stock-issuing.table.header.amount': 'mennyiség',
    'b2b-stock-issuing.table.header.status': 'státusz',
    'stock-issuing.create.title': 'Kitárolás',
    'stock-issuing.create.b2cOrder': 'Webshop rendelés azonosító',
    'stock-issuing.create.cp.form.partner.label': 'Vevő',
    'stock-issuing.create.cp.form.supplier.label': 'Beszállító',
    'stock-issuing.create.cp.form.legalCode.label': 'Jogcímkód',
    'stock-issuing.create.form.comment.label': 'Megjegyzés',
    'stock-issuing.create.form.isB2c.label': 'B2C megrendelő',
    'stock-issuing.create.form.isReturn.label': 'Visszáru',
    'stock-issuing.create.form.isTransaction.label': 'Winehub tárol ki',
    'stock-issuing.create.form.isThirdParty.label': 'nem Winehub értékesíti',
    'stock-issuing.create.form.shipping-data.label': 'Szállítási cím',
    'stock-issuing.create.form.purpose.label': 'Kitárolás célja',
    'stock-issuing.create.form.full-name.label': 'Teljes név',
    'stock-issuing.create.form.email.label': 'Email',
    'stock-issuing.create.form.phone.label': 'Telefonszám',
    'stock-issuing.create.form.city.label': 'Város',
    'stock-issuing.create.form.zipCode.label': 'Irányító szám',
    'stock-issuing.create.form.address.label': 'Cím',
    'stock-issuing.create.form.taxNumber.label': 'Adószám',
    'stock-issuing.create.form.operatingPermitNumber.label': 'Engedélyszám',
    'stock-issuing.create.form.heaCode.label': 'Jövedéki engedély szám',
    'stock-issuing.create.form.licensePlateNumber.label': 'Kiszállító rendszáma',
    'stock-issuing.toast.success.create-update': 'Kitárolás dokumentum sikeresen létrehozva/frissítve',
    'stock-issuing.toast.success.execute': 'Sikeres kitárolás',
    'stock-issuing.toast.success.delete': 'Sikeres törlés',
    'stock-issuing.toast.error.update': 'Hiba a kitárolás során',
    'stock-issuing.toast.error.UnknownCustomerPartner': 'Ismeretlen vevő partner',
    'stock-issuing.toast.error.UnknownSupplierPartner': 'Ismeretlen beszállító partner',
    'stock-issuing.toast.error.CustomerPartnerMustBeWinehubCustomer': 'A partner nem Winehub Vevő Partner',
    'stock-issuing.toast.success.UnableToDownloadIssuing': 'Nem sikerült letölteni a kitárolás adatokat',
    'b2b-stock-issuing.status.badge.draft': 'VÁZLAT',
    'b2b-stock-issuing.status.badge.executed': 'KITÁROLT',
    'stock-issuing.create.form.isForeign.label': 'EMCS'
};
