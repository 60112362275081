"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardTotalBoxes = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var trans_1 = require("@codebuild/glaze/libs/translation/trans");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var total_box_1 = require("../../../components/customer-partner-list/total-box");
var DashboardTotalBoxes = (function (_super) {
    __extends(DashboardTotalBoxes, _super);
    function DashboardTotalBoxes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            procurement: 0,
            profit: 0,
            orders: 0,
        };
        _this.baseTransText = 'sales.dashboard';
        return _this;
    }
    DashboardTotalBoxes.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    DashboardTotalBoxes.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.user !== this.props.user)) return [3, 2];
                        return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    DashboardTotalBoxes.prototype.render = function () {
        return React.createElement("div", { className: "justify-content-between align-items-center row pt-5 w-100" },
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 pr-4" },
                React.createElement(total_box_1.TotalBox, { text: (0, trans_1.trans)("".concat(this.baseTransText, ".total.procurement")), data: { value: this.state.procurement }, loading: this.state.loading })),
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 px-2" },
                React.createElement(total_box_1.TotalBox, { text: (0, trans_1.trans)("".concat(this.baseTransText, ".total.profit")), data: { value: this.state.profit }, loading: this.state.loading })),
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3 pl-4" },
                React.createElement(total_box_1.TotalBox, { text: (0, trans_1.trans)("".concat(this.baseTransText, ".total.orders")), data: { value: this.state.orders }, loading: this.state.loading })));
    };
    DashboardTotalBoxes.prototype.fetch = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((_a = this.props.user) === null || _a === void 0 ? void 0 : _a._id)) {
                            return [2];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/sales-dashboard/totals/".concat(this.props.user._id))];
                    case 2:
                        response = _b.sent();
                        this.setState({
                            procurement: response.procurement,
                            profit: response.profit,
                            orders: response.orders,
                        });
                        return [3, 5];
                    case 3:
                        e_1 = _b.sent();
                        console.log({ DashboardTotalBoxes: e_1 });
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    return DashboardTotalBoxes;
}(abstract_component_1.AbstractComponent));
exports.DashboardTotalBoxes = DashboardTotalBoxes;
