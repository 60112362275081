"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleStorageTreeSelectFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/sprinkles/libs/form/form-control/types/abstract.form-control-type");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var SingleStorageTreeSelectFormControlType = (function (_super) {
    __extends(SingleStorageTreeSelectFormControlType, _super);
    function SingleStorageTreeSelectFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SingleStorageTreeSelectFormControlType.prototype.getProps = function () {
        var _this = this;
        return {
            onChange: function (value) { var _a, _b; return _this.formControl.props.field.setValue((_b = (_a = value[0]) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b._id); },
            value: this.getValue(),
            label: this.formControl.props.field.getLabel(),
            placeholder: this.formControl.props.field.getPlaceholder(),
            required: this.formControl.props.field.getRequired(),
            errors: this.errors,
            loading: this.formControl.props.field.loading,
            renderTrigger: function (ctx) { return _this.renderTrigger(ctx); }
        };
    };
    SingleStorageTreeSelectFormControlType.prototype.getValue = function () {
        if (!this.formControl.props.field.getValue()) {
            return [];
        }
        return [{ document: { _id: this.formControl.props.field.getValue() } }];
    };
    SingleStorageTreeSelectFormControlType.prototype.renderTrigger = function (ctx) {
        var _a, _b, _c, _d;
        return React.createElement("div", null,
            React.createElement(button_1.Button, { iconLeft: !!((_a = this.getValue()) === null || _a === void 0 ? void 0 : _a.length) ? 'far fa-pencil mr-2' : 'far fa-plus mr-2', className: "button-size-medium ".concat(((_b = this.errors) === null || _b === void 0 ? void 0 : _b.length) ? 'button-error-outline' : 'button-neutral-outline', " "), title: !!((_c = this.getValue()) === null || _c === void 0 ? void 0 : _c.length) ? (_d = ctx.getPaths(this.getValue())) === null || _d === void 0 ? void 0 : _d[0] : 'Tárhely választása' }));
    };
    return SingleStorageTreeSelectFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.SingleStorageTreeSelectFormControlType = SingleStorageTreeSelectFormControlType;
