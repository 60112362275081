"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockTransactionShowScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
require("./b2b-stock-transaction.scss");
var stock_transaction_show_table_body_1 = require("./components/stock-transaction-show-table-body");
var B2bStockTransactionShowScreen = (function (_super) {
    __extends(B2bStockTransactionShowScreen, _super);
    function B2bStockTransactionShowScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            transactionDocument: {},
        };
        return _this;
    }
    B2bStockTransactionShowScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchIncomeDocument()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockTransactionShowScreen.prototype.fetchIncomeDocument = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/b2b/stock-transaction/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        result = _c.sent();
                        this.setState({ transactionDocument: result });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bStockTransactionShowScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'StockTransactionShowScreen w-100' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-transaction'); } })),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("h3", null,
                            this.state.transactionDocument.identifier,
                            " - Bels\u0151 mozgat\u00E1s")),
                    React.createElement("div", null,
                        React.createElement("div", { className: 'row mb-7' },
                            React.createElement("div", { className: 'col' },
                                React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('stock-transaction.view.data.comment')),
                                React.createElement("p", { className: 'fw-700 ml-2' }, ((_a = this.state.transactionDocument) === null || _a === void 0 ? void 0 : _a.comment) || '-'))))),
                React.createElement("div", { className: 'stock-transaction-status-box-wrapper' }, this.renderStatusBox())),
            React.createElement("div", { className: 'row mb-4' },
                React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('stock-transaction.view.data.products')),
                React.createElement(table_1.Table, { className: 'table-basic StockTransactionTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(); } })));
    };
    B2bStockTransactionShowScreen.prototype.renderItems = function () {
        var _a;
        return React.createElement(stock_transaction_show_table_body_1.StockTransactionShowTableBody, { items: (_a = this.state.transactionDocument) === null || _a === void 0 ? void 0 : _a.artifacts });
    };
    B2bStockTransactionShowScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 35 }, this.setHeaderCell('fromStorage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 35 }, this.setHeaderCell('toStorage'))));
    };
    B2bStockTransactionShowScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-transaction.view.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockTransactionShowScreen.prototype.renderStatusBox = function () {
        return React.createElement("div", { className: 'stock-transaction-control-box mb-4' },
            React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('stock-transaction.view.control.status.title')),
            React.createElement("div", { className: 'stock-transaction-status-badge stocked' },
                React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)('stock-transaction.status.badge.title.done'))));
    };
    return B2bStockTransactionShowScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockTransactionShowScreen = B2bStockTransactionShowScreen;
