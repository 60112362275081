"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementViewScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var check_user_role_1 = require("../../common/libs/authentication/check-user-role");
var permissions_1 = require("../../common/libs/permissions/permissions");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var procurement_status_badge_1 = require("./procurement-create-update/components/procurement-status-badge");
var procurement_view_table_row_component_1 = require("./procurement-create-update/components/procurement-view-table-row.component");
var set_failed_status_modal_1 = require("./procurement-create-update/components/set-failed-status.modal");
var ProcurementViewScreen = (function (_super) {
    __extends(ProcurementViewScreen, _super);
    function ProcurementViewScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                reason: new field_1.Field({
                    name: 'reason',
                    value: '',
                    placeholder: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('procurement.view.failed.modal.form.reason.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))],
                })
            }
        });
        _this.state = {
            procurement: {},
            loading: false,
            error: '',
            statusChangeError: false,
            isFactMissing: false,
            missingConfirmedDeliveryDate: false,
        };
        return _this;
    }
    ProcurementViewScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ProcurementViewScreen.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/procurement/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        response = _c.sent();
                        this.setState({ procurement: response, loading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ error: e_1.message, loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProcurementViewScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return React.createElement("div", { className: 'ProcurementViewScreen row px-4 w-100' },
            this.renderModals(),
            React.createElement("div", { className: 'col procurement-content-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/procurement'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h3", { className: 'fw-600' }, (_a = this.state.procurement) === null || _a === void 0 ? void 0 :
                        _a.identifier,
                        " ",
                        (0, trans_1.trans)('procurement.view.title'))),
                React.createElement("div", null,
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-8 mb-4' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.supplier')),
                            React.createElement("p", { className: 'fw-700 ml-2' }, (_c = (_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.supplierArtifact) === null || _c === void 0 ? void 0 : _c.name)),
                        React.createElement("div", { className: 'mb-4 col-8' },
                            React.createElement("p", { className: 'typo-small' }, "Besz\u00E1ll\u00EDt\u00F3 partner megjegyz\u00E9s"),
                            React.createElement("p", { className: 'fw-700 ml-2' }, (_g = (_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.procurement) === null || _e === void 0 ? void 0 : _e.supplier) === null || _f === void 0 ? void 0 : _f.comment) !== null && _g !== void 0 ? _g : '-'))),
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-8 mb-4' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.planned-delivery-date')),
                            React.createElement("div", { className: 'display-flex justify-content-between align-items-center' },
                                React.createElement("p", { className: 'fw-700 ml-2' }, ((_h = this.state.procurement) === null || _h === void 0 ? void 0 : _h.plannedDeliveryDate) || '-'),
                                React.createElement("i", { className: 'far fa-calendar' }))),
                        React.createElement("div", { className: 'col-8 mb-4' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.confirmed-delivery-date')),
                            React.createElement("div", { className: 'display-flex justify-content-between align-items-center' },
                                React.createElement("p", { className: 'fw-700 ml-2' }, ((_j = this.state.procurement) === null || _j === void 0 ? void 0 : _j.confirmedDeliveryDate) || '-'),
                                React.createElement("i", { className: 'far fa-calendar' })))),
                    React.createElement("div", { className: 'row mb-7' },
                        React.createElement("div", { className: 'col-14' },
                            React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.comment')),
                            React.createElement("p", { className: 'fw-700 ml-2' }, ((_k = this.state.procurement) === null || _k === void 0 ? void 0 : _k.comment) || '-')),
                        ((_l = this.state.procurement) === null || _l === void 0 ? void 0 : _l.failedReason) &&
                            React.createElement("div", { className: 'col-10' },
                                React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.failed-reason')),
                                React.createElement("p", { className: 'fw-700 ml-2' }, (_m = this.state.procurement) === null || _m === void 0 ? void 0 : _m.failedReason))),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('procurement.view.data.products')),
                        React.createElement(table_1.Table, { className: 'table-basic ProcurementTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(); } })))),
            React.createElement("div", { className: 'col procurement-save-box-wrapper' }, this.renderControl()));
    };
    ProcurementViewScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('7-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('14-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('30-days'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('free-stock'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('under-procurement'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('plan'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('fact'))));
    };
    ProcurementViewScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = true; }
        return {
            enableSort: false,
            title: (0, trans_1.trans)("procurement.view.table.header.".concat(p)),
            property: p
        };
    };
    ProcurementViewScreen.prototype.renderItems = function () {
        var _this = this;
        var _a, _b;
        var plan = ((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.plan) || [];
        var fact = ((_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.fact) || [];
        return plan === null || plan === void 0 ? void 0 : plan.map(function (item) {
            var _a, _b;
            var factItem = fact === null || fact === void 0 ? void 0 : fact.find(function (f) { return f.productId === item.productId; });
            return React.createElement(procurement_view_table_row_component_1.ProcurementProductsTableRowComponent, { procurementId: (_a = _this.state.procurement) === null || _a === void 0 ? void 0 : _a._id, status: (_b = _this.state.procurement) === null || _b === void 0 ? void 0 : _b.status, key: item.productId, item: item, factItem: factItem });
        });
    };
    ProcurementViewScreen.prototype.renderControl = function () {
        var _a, _b;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            (0, check_user_role_1.checkUserRole)('admin') && (((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.status) === 'Draft' || ((_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.status) === 'Placed') && this.renderEditBox(),
            this.renderStatusBox(),
            this.state.statusChangeError && React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)('procurement.view.status.change.server-error') }),
            this.state.isFactMissing && React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)('procurement.view.status.change.missing-fact') }),
            this.state.missingConfirmedDeliveryDate && React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)('procurement.view.status.change.confirmed-deliverydate-missing') }));
    };
    ProcurementViewScreen.prototype.renderEditBox = function () {
        var _this = this;
        return React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.STORAGE.procurement.update },
            React.createElement("div", { className: 'procurement-control-box mb-4' },
                React.createElement(button_1.Button, { className: 'button-info-normal button-size-normal', title: (0, trans_1.trans)('procurement.view.edit.box.button'), iconLeft: 'fas fa-pencil', onClick: function () { var _a; return router_provider_1.RouterProvider.goTo("/procurement/".concat((_a = _this.state.procurement) === null || _a === void 0 ? void 0 : _a._id, "/update")); } })));
    };
    ProcurementViewScreen.prototype.renderStatusBox = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        return React.createElement("div", { className: 'procurement-control-box mb-4' },
            React.createElement("h5", { className: 'fw-700 mb-4' }, (0, trans_1.trans)('procurement.view.control.status.title')),
            React.createElement(procurement_status_badge_1.ProcurementStatusBadge, { status: (_b = (_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.toLowerCase() }),
            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.STORAGE.procurement.update },
                ((_c = this.state.procurement) === null || _c === void 0 ? void 0 : _c.status) === 'Draft'
                    && React.createElement(button_1.Button, { disabled: ((_d = this.state.procurement) === null || _d === void 0 ? void 0 : _d.status) !== 'Draft', className: 'button-info-outline button-size-normal mt-7 mb-5 text-align--left', title: (0, trans_1.trans)('procurement.view.edit.placed-status.button'), iconRight: 'fas fa-check', onClick: function () { return _this.setProcurementStatus('Placed'); } }),
                ((_e = this.state.procurement) === null || _e === void 0 ? void 0 : _e.status) === 'Placed'
                    && React.createElement(React.Fragment, null,
                        React.createElement(button_1.Button, { disabled: ((_f = this.state.procurement) === null || _f === void 0 ? void 0 : _f.status) !== 'Placed', className: 'button-success-outline button-size-normal mt-7 mb-5 text-align--left', title: (0, trans_1.trans)('procurement.view.edit.fulfilled-status.button'), iconRight: 'fas fa-check', onClick: function () { return _this.setProcurementStatus('Fulfilled'); } }),
                        React.createElement(button_1.Button, { className: 'button-error-outline button-size-normal text-align--left', title: (0, trans_1.trans)('procurement.view.edit.failed-status.button'), iconRight: 'fas fa-x', onClick: function () { return _this.$showSetFailedModal.open(); } }))));
    };
    ProcurementViewScreen.prototype.setProcurementStatus = function (status) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var reason, isFactValid_1, valid, e_2;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 5, , 6]);
                        this.setState({ statusChangeError: false, loading: true });
                        reason = '';
                        if (status === 'Placed' && !this.state.procurement.confirmedDeliveryDate) {
                            return [2, this.setState({ missingConfirmedDeliveryDate: true, loading: false })];
                        }
                        if (status === 'Fulfilled' || status === 'Placed') {
                            isFactValid_1 = true;
                            (_b = (_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.fact) === null || _b === void 0 ? void 0 : _b.forEach(function (item) {
                                isFactValid_1 = isFactValid_1 && !!item.amount;
                            });
                            if (!isFactValid_1 || !((_d = (_c = this.state.procurement) === null || _c === void 0 ? void 0 : _c.fact) === null || _d === void 0 ? void 0 : _d.length)) {
                                return [2, this.setState({ isFactMissing: true, loading: false })];
                            }
                        }
                        if (!(status === 'Failed')) return [3, 2];
                        return [4, this.form.validate()];
                    case 1:
                        valid = _f.sent();
                        if (!valid) {
                            return [2];
                        }
                        reason = this.form.fields.reason.getValue();
                        _f.label = 2;
                    case 2: return [4, this.repository.put("/b2b/procurement/".concat((_e = this.state.procurement) === null || _e === void 0 ? void 0 : _e._id, "/status"), {
                            data: {
                                status: status,
                                reason: reason,
                            }
                        })];
                    case 3:
                        _f.sent();
                        this.setState({ loading: false });
                        this.$showSetFailedModal.close();
                        return [4, this.fetch()];
                    case 4:
                        _f.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('procurement.view.status.change.success'));
                        return [3, 6];
                    case 5:
                        e_2 = _f.sent();
                        this.setState({ statusChangeError: true, loading: false });
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    ProcurementViewScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showSetFailedModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(set_failed_status_modal_1.SetFailedStatusModal, { form: _this.form, onClose: function () { return _this.$showSetFailedModal.close(); }, onSetStatusFailed: function () { return _this.setProcurementStatus('Failed'); } }); } }));
    };
    return ProcurementViewScreen;
}(abstract_component_1.AbstractComponent));
exports.ProcurementViewScreen = ProcurementViewScreen;
