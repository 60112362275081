"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPagination = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var button_1 = require("@codebuild/glaze/modules/codebuild/button/react/button");
var React = require("react");
require("./pagination.scss");
var AdminPagination = (function (_super) {
    __extends(AdminPagination, _super);
    function AdminPagination() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminPagination.prototype.render = function () {
        if (this.getTotalPage() <= 1) {
            return null;
        }
        return React.createElement("div", { className: "pagination" },
            React.createElement("div", { className: "button-group-horizontal-neutral-outline" },
                this.renderFirst(),
                this.renderPrev(),
                this.renderPageNumbers(),
                this.renderNext(),
                this.renderLast()));
    };
    AdminPagination.prototype.renderPageNumbers = function () {
        var _this = this;
        var totalPage = this.getTotalPage();
        if (totalPage > 5) {
            return Array.from(Array(totalPage).keys()).map(function (p) {
                var isActive = p === _this.props.page;
                if ((p < 5 && _this.props.page < 4) || (p > totalPage - 6 && _this.props.page > totalPage - 5)) {
                    return React.createElement(button_1.Button, { title: (p + 1).toString(), onClick: function (e) { return _this.handleChange(e, p); }, className: "button-neutral-outline button-size-normal pagination-button ".concat(isActive ? 'active' : ''), key: p + 1 });
                }
                if (p === 0 || [_this.props.page - 1, _this.props.page, _this.props.page + 1].includes(p) || p === totalPage - 1) {
                    return React.createElement(button_1.Button, { title: (p + 1).toString(), onClick: function (e) { return _this.handleChange(e, p); }, className: "button-neutral-outline button-size-normal pagination-button ".concat(isActive ? 'active' : ''), key: p + 1 });
                }
                if ((p === 1 && _this.props.page >= 3) || (p === totalPage - 2 && _this.props.page <= totalPage - 4)) {
                    return React.createElement("div", { className: 'pagination-elipsis border-color--secondary-default-1 border-right-2' }, "...");
                }
            });
        }
        return Array.from(Array(totalPage).keys()).map(function (p) {
            var isActive = p === _this.props.page;
            return React.createElement(button_1.Button, { title: (p + 1).toString(), onClick: function (e) { return _this.handleChange(e, p); }, className: "button-neutral-outline button-size-normal pagination-button ".concat(isActive ? 'active' : ''), key: p + 1 });
        });
    };
    AdminPagination.prototype.renderPrev = function () {
        var _this = this;
        var hasPrev = this.props.page > 0;
        return React.createElement(button_1.Button, { disabled: !hasPrev, onClick: function (e) { return _this.handleChange(e, (_this.props.page - 1)); }, className: "button-neutral-outline button-size-normal pagination-button ".concat(!hasPrev ? 'disabled' : ''), title: React.createElement("i", { className: 'fas fa-chevron-left' }) });
    };
    AdminPagination.prototype.renderFirst = function () {
        var _this = this;
        var isFirst = this.props.page <= 0;
        return React.createElement(button_1.Button, { disabled: isFirst, onClick: function (e) { return _this.handleChange(e, 0); }, className: "button-neutral-outline button-size-normal pagination-button ".concat(isFirst ? 'disabled' : ''), title: React.createElement("i", { className: 'fas fa-chevron-double-left' }) });
    };
    AdminPagination.prototype.renderNext = function () {
        var _this = this;
        var hasNext = this.props.page < this.getTotalPage() - 1;
        return React.createElement(button_1.Button, { disabled: !hasNext, onClick: function (e) { return _this.handleChange(e, (_this.props.page + 1)); }, className: 'button-neutral-outline button-size-normal pagination-button', title: React.createElement("i", { className: 'fas fa-chevron-right' }) });
    };
    AdminPagination.prototype.renderLast = function () {
        var _this = this;
        var isLast = this.props.page >= this.getTotalPage() - 1;
        return React.createElement(button_1.Button, { disabled: isLast, onClick: function (e) { return _this.handleChange(e, _this.getTotalPage() - 1); }, className: 'button-neutral-outline button-size-normal pagination-button', title: React.createElement("i", { className: 'fas fa-chevron-double-right' }) });
    };
    AdminPagination.prototype.handleChange = function (e, page) {
        if (this.props.onChange) {
            this.props.onChange(e, page);
        }
    };
    AdminPagination.prototype.getTotalPage = function () {
        return Math.ceil(this.props.total / this.props.limit) > 0 ? Math.ceil(this.props.total / this.props.limit) : 1;
    };
    return AdminPagination;
}(abstract_component_1.AbstractComponent));
exports.AdminPagination = AdminPagination;
