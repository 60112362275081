"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.round = void 0;
var round = function (value, decimal) {
    if (decimal === void 0) { decimal = 1; }
    if (!value) {
        return 0;
    }
    var decimalValue = getDecimal(decimal);
    return Math.round(value * decimalValue) / decimalValue;
};
exports.round = round;
var getDecimal = function (decimal) {
    switch (decimal) {
        case 1:
            return 10;
        case 2:
            return 100;
        case 3:
            return 1000;
        case 4:
            return 10000;
        default:
            return 1;
    }
};
