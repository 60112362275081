"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplaintDetailsScreen = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var rxjs_1 = require("rxjs");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./complain-details.screen.scss");
var ComplaintDetailsScreen = (function (_super) {
    __extends(ComplaintDetailsScreen, _super);
    function ComplaintDetailsScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            complaint: null,
            messages: [],
            error: null,
            loading: false
        };
        _this.responseForm = new form_builder_1.FormBuilder({
            fields: {
                message: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'message',
                    label: (0, trans_1.trans)('complaint.response.form.message.label'),
                    validators: [new required_validator_1.RequiredValidator('error.input-validation.required')]
                }),
            }
        });
        return _this;
    }
    ComplaintDetailsScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.fetchComplaint();
                this.$subscriptions.push((0, rxjs_1.timer)(10000, 10000).subscribe(function () { return _this.reFetchConversation(); }));
                return [2];
            });
        });
    };
    ComplaintDetailsScreen.prototype.componentWillUnmount = function () {
        this.$subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    ComplaintDetailsScreen.prototype.fetchComplaint = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var complaint, order, _c, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, , 6]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/complaint/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.complaintId))];
                    case 1:
                        complaint = _d.sent();
                        if (!(complaint === null || complaint === void 0 ? void 0 : complaint.orderId)) return [3, 3];
                        return [4, this.repository.get("/admin/order/".concat(complaint === null || complaint === void 0 ? void 0 : complaint.orderId))];
                    case 2:
                        _c = _d.sent();
                        return [3, 4];
                    case 3:
                        _c = null;
                        _d.label = 4;
                    case 4:
                        order = _c;
                        this.setState({ complaint: complaint, messages: complaint.messages, order: order, loading: false });
                        return [3, 6];
                    case 5:
                        e_1 = _d.sent();
                        this.setState({ loading: false });
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    ComplaintDetailsScreen.prototype.reFetchConversation = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var complaint, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/complaint/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.complaintId))];
                    case 1:
                        complaint = _c.sent();
                        this.setState({ messages: complaint.messages });
                        return [3, 3];
                    case 2:
                        e_2 = _c.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ComplaintDetailsScreen.prototype.render = function () {
        return React.createElement("div", { className: 'ComplaintDetailsScreen  justify-content-center w-100' },
            React.createElement("h2", { className: 'mb-6' }, (0, trans_1.trans)('complaint-details.screen.title')),
            React.createElement("div", null,
                React.createElement("h3", { className: 'mb-6' }, (0, trans_1.trans)('complaint-details.screen.order-details.subtitle')),
                this.renderOrderDetails()),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-12 col-24 complaint" }, this.renderConversation())));
    };
    ComplaintDetailsScreen.prototype.renderConversation = function () {
        var _this = this;
        var _a, _b;
        var complaint = (_a = this.state) === null || _a === void 0 ? void 0 : _a.complaint;
        var messages = (_b = this.state) === null || _b === void 0 ? void 0 : _b.messages;
        return React.createElement("div", null,
            React.createElement("div", { className: "display-flex align-items-center mb-4" },
                React.createElement("h4", { className: 'mb-6 flex-fill' }, (0, trans_1.trans)('complaint-details.screen.messages.subtitle')),
                React.createElement("span", null, complaint === null || complaint === void 0 ? void 0 : complaint.category)),
            React.createElement("div", { className: 'mb-4' }, messages.map(function (m, index) { return _this.renderMessage(m, index); })),
            React.createElement("div", null, this.renderResponse()));
    };
    ComplaintDetailsScreen.prototype.renderMessage = function (message, index) {
        if (message.isCustomer) {
            return React.createElement("div", { key: "message-".concat(index), className: 'display-flex flex-column align-items-start mb-4' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement("strong", { className: 'mr-4' }, (0, trans_1.trans)('complaint.customer.label')),
                    React.createElement("span", { className: 'color--secondary-dark-3' }, moment(message.sentAt).format('YYYY.MM.DD. HH:mm'))),
                React.createElement("div", { className: 'message-bubble customer' }, message.message));
        }
        return React.createElement("div", { key: "message-".concat(index), className: 'display-flex flex-column align-items-end mb-4' },
            React.createElement("div", { className: 'mb-2' },
                React.createElement("strong", { className: 'mr-4' }, (0, trans_1.trans)('complaint.admin.label')),
                React.createElement("span", { className: 'color--secondary-dark-3' }, moment(message.sentAt).format('YYYY.MM.DD. HH:mm'))),
            React.createElement("div", { className: 'message-bubble admin' }, message.message));
    };
    ComplaintDetailsScreen.prototype.renderOrderDetails = function () {
        return React.createElement("div", { className: 'OrderDetails row w-100' },
            React.createElement("div", { className: "col-24 mb-4" }, this.renderCustomerData()),
            React.createElement("div", { className: "col-12 mb-4" }, this.renderShippingAddress()),
            React.createElement("div", { className: "col-12 mb-4" }, this.renderBillingAddress()),
            React.createElement("div", { className: "col-24 mb-4" }, this.renderProducts()));
    };
    ComplaintDetailsScreen.prototype.renderCustomerData = function () {
        var _a, _b, _c, _d;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.customer-data.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.name'),
                        ":"),
                    React.createElement("span", null, (_a = this.state.order) === null || _a === void 0 ? void 0 :
                        _a.customerLastName,
                        " ", (_b = this.state.order) === null || _b === void 0 ? void 0 :
                        _b.customerFirstName)),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.phoneNumber'),
                        ":"),
                    React.createElement("span", null, (_c = this.state.order) === null || _c === void 0 ? void 0 : _c.customerPhone)),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.customer-data.email'),
                        ":"),
                    React.createElement("span", null, (_d = this.state.order) === null || _d === void 0 ? void 0 : _d.customerEmail))));
    };
    ComplaintDetailsScreen.prototype.renderBillingAddress = function () {
        var _a, _b, _c, _d, _e;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.billing-address.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.name'),
                        ":"),
                    React.createElement("span", null, (_b = (_a = this.state.order) === null || _a === void 0 ? void 0 : _a.billingAddress) === null || _b === void 0 ? void 0 : _b.billingName)),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.address'),
                        ":"),
                    React.createElement("span", null, this.parseAddress((_c = this.state.order) === null || _c === void 0 ? void 0 : _c.billingAddress))),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.billing-address.taxNumber'),
                        ":"),
                    React.createElement("span", null, (_e = (_d = this.state.order) === null || _d === void 0 ? void 0 : _d.billingAddress) === null || _e === void 0 ? void 0 : _e.taxNumber))));
    };
    ComplaintDetailsScreen.prototype.renderShippingAddress = function () {
        var _a, _b, _c;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.shipping-address.subtitle')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.shipping-address.address'),
                        ":"),
                    React.createElement("span", null, this.parseAddress((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.shippingAddress))),
                React.createElement("div", { className: 'col-24' },
                    React.createElement("strong", { className: 'fw-bold mr-2' },
                        (0, trans_1.trans)('order-details.screen.shipping-address.comment'),
                        ":"),
                    React.createElement("p", null, (_c = (_b = this.state.order) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.comment))));
    };
    ComplaintDetailsScreen.prototype.parseAddress = function (address) {
        return "".concat(address === null || address === void 0 ? void 0 : address.zipCode, " ").concat(address === null || address === void 0 ? void 0 : address.city, " ").concat(address === null || address === void 0 ? void 0 : address.address);
    };
    ComplaintDetailsScreen.prototype.renderProducts = function () {
        var _this = this;
        var _a;
        return React.createElement("div", null,
            React.createElement("h4", null, (0, trans_1.trans)('order-details.screen.products.subtitle')),
            React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderProductsTableHeader(); }, renderBody: function () { var _a, _b; return (_b = (_a = _this.state.order) === null || _a === void 0 ? void 0 : _a.productItems) === null || _b === void 0 ? void 0 : _b.map(function (item) { return _this.renderProductItem(item); }); } }),
            React.createElement("div", { className: 'w-100 display-flex justify-content-start' },
                React.createElement("strong", { className: 'mr-4' },
                    (0, trans_1.trans)('order-details.screen.order-sum-prize'),
                    ":"),
                React.createElement("span", null, (0, currency_parser_1.hufFormat)((_a = this.state.order) === null || _a === void 0 ? void 0 : _a.productTotalGross))));
    };
    ComplaintDetailsScreen.prototype.setHeaderCell = function (p) {
        return {
            sortValue: null,
            enableSort: true,
            title: (0, trans_1.trans)("order-details.screen.product.table.header.".concat(p)),
            property: p
        };
    };
    ComplaintDetailsScreen.prototype.renderProductsTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('prize'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('sumPrize'))));
    };
    ComplaintDetailsScreen.prototype.renderProductItem = function (item) {
        var _a, _b, _c;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _a === void 0 ? void 0 : _a.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_c = (_b = item === null || item === void 0 ? void 0 : item.productArtifact) === null || _b === void 0 ? void 0 : _b.title) === null || _c === void 0 ? void 0 : _c.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.amount,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)((item === null || item === void 0 ? void 0 : item.gross)))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(((item === null || item === void 0 ? void 0 : item.amount) * (item === null || item === void 0 ? void 0 : item.gross))))));
    };
    ComplaintDetailsScreen.prototype.renderResponse = function () {
        var _this = this;
        return React.createElement("div", { className: 'position-relative' },
            React.createElement("h5", null, (0, trans_1.trans)('complaint.response.label')),
            React.createElement(form_control_1.FormControl, { field: this.responseForm.fields.message, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(textarea_input_1.TextareaInput, { rows: 8, disabled: this.state.loading, className: 'textarea-input-basic' })),
            React.createElement(button_1.Button, { disabled: this.state.loading, className: 'button-primary-normal button-size-normal', title: (0, trans_1.trans)('complaint.send.button.label'), onClick: function () { return _this.response(); } }));
    };
    ComplaintDetailsScreen.prototype.response = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isValid, formData, complaint, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4, this.responseForm.validate()];
                    case 1:
                        isValid = _b.sent();
                        if (!isValid) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        formData = this.responseForm.toJSON();
                        return [4, this.repository.put("/admin/complaint/".concat((_a = this.state) === null || _a === void 0 ? void 0 : _a.complaint._id, "/response"), {
                                data: {
                                    message: formData === null || formData === void 0 ? void 0 : formData.message
                                }
                            })];
                    case 2:
                        complaint = _b.sent();
                        this.responseForm.fields.message.setValue('');
                        this.setState({ messages: complaint === null || complaint === void 0 ? void 0 : complaint.messages, loading: false });
                        return [3, 4];
                    case 3:
                        e_3 = _b.sent();
                        this.setState({ loading: false });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ComplaintDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.ComplaintDetailsScreen = ComplaintDetailsScreen;
