"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = {
    'stock-rotation-report.list.main.title': 'Készlet forgás riport',
    'stock-rotation-report.list.table.header.product': 'termék',
    'stock-rotation-report.list.table.header.available': 'Szabadkészlet',
    'stock-rotation-report.list.table.header.procurement': 'Beszerzés alatt',
    'stock-rotation-report.list.table.header.rotations.7days.sum': 'elmúlt 7 nap',
    'stock-rotation-report.list.table.header.rotations.14days.sum': 'elmúlt 14 nap',
    'stock-rotation-report.list.table.header.rotations.30days.sum': 'elmúlt 30 nap',
    'stock-rotation-report.list.table.header.rotations.60days.sum': 'elmúlt 60 nap',
    'stock-rotation-report.list.table.header.rotations.90days.sum': 'elmúlt 90 nap',
    'stock-rotation-report.list.table.header.rotations.120days.sum': 'elmúlt 120 nap',
    'stock-rotation-report.list.table.header.rotations.365days.sum': 'elmúlt 1 év',
};
