"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesTargetsUpdateScreen = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var select_input_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
var moment = require("moment");
require("./sales-targets-update.screen.scss");
var form_1 = require("../../../components/form/form");
var optional_component_1 = require("../../../components/optional-component");
var yearly_targets_form_1 = require("./libs/yearly-targets.form");
var SalesTargetsUpdateScreen = (function (_super) {
    __extends(SalesTargetsUpdateScreen, _super);
    function SalesTargetsUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            userName: null,
            year: moment().year(),
            loading: null
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                year: new field_1.Field({
                    placeholder: '',
                    value: moment().year(),
                    name: 'year',
                    options: _this.getYearsRangeOptions(),
                    label: (0, trans_1.trans)('sales-targets.update.year'),
                    validators: []
                }),
            }
        });
        return _this;
    }
    SalesTargetsUpdateScreen.prototype.getYearsRangeOptions = function () {
        var mapper = function (year) { return ({ value: year, name: year, key: year }); };
        var startYear = moment().subtract(5, 'years').year();
        var endYear = moment().add(10, 'years').year();
        var years = [];
        for (var year = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return years.map(mapper);
    };
    SalesTargetsUpdateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchUser()];
                    case 1:
                        _a.sent();
                        this.$subscriptions.push(this.form.fields.year.$value.subscribe(function (year) { return _this.setState({ year: year }); }));
                        return [2];
                }
            });
        });
    };
    SalesTargetsUpdateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'display-flex flex-column align-items-center w-100' },
            React.createElement("div", { className: "mb-4" },
                React.createElement(optional_component_1.OptionalComponent, { condition: this.state.userName, ifTrue: React.createElement("h2", { className: 'text-center mb-6' }, this.state.userName) })),
            React.createElement("div", { className: "mb-4 w-100 position-center" },
                React.createElement(form_1.Form, { className: 'w-100 position-center row' },
                    React.createElement("div", { className: "col-lg-8 col-md-12 col-24 w-100" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.year, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { unclearable: true, className: 'select-input-basic' }))))),
            React.createElement("div", { className: "mb-4" },
                React.createElement(yearly_targets_form_1.YearlyTargetsForm, { year: this.state.year, user: this.state.user })));
    };
    SalesTargetsUpdateScreen.prototype.fetchUser = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var response, firstName, lastName, e_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 3, 4, 5]);
                        if (!((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.userId)) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/user/".concat((_d = (_c = this.props.route) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.userId, "?noCache=true"))];
                    case 1:
                        response = _j.sent();
                        firstName = (_f = (_e = response === null || response === void 0 ? void 0 : response.publicMeta) === null || _e === void 0 ? void 0 : _e.firstName) !== null && _f !== void 0 ? _f : '';
                        lastName = (_h = (_g = response === null || response === void 0 ? void 0 : response.publicMeta) === null || _g === void 0 ? void 0 : _g.lastName) !== null && _h !== void 0 ? _h : '';
                        return [4, this.setState({ user: response, userName: "".concat(lastName, " ").concat(firstName) })];
                    case 2:
                        _j.sent();
                        return [3, 5];
                    case 3:
                        e_1 = _j.sent();
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    return SalesTargetsUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.SalesTargetsUpdateScreen = SalesTargetsUpdateScreen;
