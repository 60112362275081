"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = {
    'customer-partner-report.list.main.title': 'Vevő cég riport',
    'customer-partner-report.list.table.header.company': 'Cégnév',
    'customer-partner-report.total-cost': 'Összes beszerzés',
    'customer-partner-report.total-profit': 'Összes nyereség',
    'customer-partner-report.total-revenue': 'Összes rendelés',
    'customer-partner-comparison': 'előző évhez képest',
    'customer-partner-report.list.table.header.seller': 'Értékesítő',
    'customer-partner-report.list.table.header.past-3-month': 'Elmúlt 3 hónap',
    'customer-partner-report.list.table.header.name': 'Név',
    'customer-partner-report.list.table.header.phone': 'Telefonszám',
    'customer-partner-report.list.table.header.email': 'E-mail',
    'customer-partner-report.list.table.header.cost': 'Beszerzés',
    'customer-partner-report.list.table.header.profit': 'Nyereség',
    'customer-partner-report.list.table.header.revenue': 'Össz. Rendelés',
    'customer-partner-report.sub.list.sku': 'SKU',
    'customer-partner-report.sub.list.title': 'név',
    'customer-partner-report.sub.list.amount': 'mennyiség',
    'customer-partner-report.sub.list.price': 'eladás totál',
};
