"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockDisposalCreateScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var form_1 = require("../../components/form/form");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
var delete_product_modal_1 = require("../b2b-stock-reservation/components/create/delete-product.modal");
require("./b2b-stock-disposal.scss");
var add_product_modal_1 = require("./components/add-product.modal");
var stock_disposal_create_table_row_1 = require("./components/stock-disposal-create-table-row");
var B2bStockDisposalCreateScreen = (function (_super) {
    __extends(B2bStockDisposalCreateScreen, _super);
    function B2bStockDisposalCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.productsRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            loading: false,
            error: '',
            products: [],
            warehouse: null,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                reason: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'reason',
                    label: (0, trans_1.trans)('stock-disposal.create.form.reason.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-disposal.create.form.warehouse.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    optionsEndpoint: '/b2b/warehouse?limit=5000',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.title,
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
                legalCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.legalCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: issuing_legal_codes_1.DEFECT_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'comment',
                    label: (0, trans_1.trans)('stock-disposal.create.form.comment.label'),
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bStockDisposalCreateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.$subscriptions.push(this.form.fields.warehouse.$value.subscribe(function (warehouse) {
                    var _a, _b;
                    var selectedOption = _this.form.fields.warehouse.options.find(function (item) { return item.value === warehouse; });
                    _this.setState({ warehouse: warehouse, showTaxDependentFields: !!((_b = (_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, 'Adóraktár')) });
                }));
                return [2];
            });
        });
    };
    B2bStockDisposalCreateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'StockDisposalCreateScreen row w-100' },
            this.renderModals(),
            React.createElement("div", { className: 'col disposal-create-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-disposal'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('stock-disposal.create.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement(form_1.Form, { className: 'w-100' },
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.reason, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { disabled: this.props.disabled, className: 'text-input-basic col-12' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-12' }))),
                        React.createElement("div", { className: 'row w-100' },
                            React.createElement("div", { className: 'col-12' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled, rows: 6, className: 'textarea-input-basic' }))),
                            React.createElement("div", { className: 'col-12' }, this.state.showTaxDependentFields && React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.loading, unclearable: true, multiple: false, searchable: true, className: 'select-input-basic' })))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col' }, this.renderStockDisposalTable()))))),
            React.createElement("div", { className: 'col stock-disposal-save-box-wrapper' }, this.renderSaveBox()));
    };
    B2bStockDisposalCreateScreen.prototype.renderStockDisposalTable = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'col mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('stock-disposal.create.product-list.title')),
                React.createElement(button_1.Button, { disabled: !this.state.warehouse, title: (0, trans_1.trans)('stock-disposal.create.product-list.add.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.$showAddProductModal.open(); }, className: 'button-primary-inverze button-size-normal' })),
            React.createElement(table_1.Table, { className: 'table-basic StockReservationTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(_this.state.products); } }));
    };
    B2bStockDisposalCreateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 35 }, this.setHeaderCell('storage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('lot'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('amount'), { className: 'justify-content-center' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    B2bStockDisposalCreateScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-disposal.create.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockDisposalCreateScreen.prototype.renderItems = function (items) {
        var _this = this;
        if (!items.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return items === null || items === void 0 ? void 0 : items.map(function (item) { return React.createElement(stock_disposal_create_table_row_1.StockDisposalCreateTableRow, { key: item._id, ref: function (ref) { return _this.productsRefs[item.productId] = ref; }, product: item, warehouse: _this.state.warehouse, showDeleteProductModal: function () { return _this.$showDeleteProductModal.open({ product: item.productId, warehouse: _this.state.warehouse }); } }); });
    };
    B2bStockDisposalCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'stock-disposal-save-box mb-6' },
                React.createElement("h5", { className: 'mb-6' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Indítás', iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-primary-normal button-size-normal', disabled: false, onClick: function () { return _this.submitDisposal(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockDisposalCreateScreen.prototype.submitDisposal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, items, _a, _b, product, item, e_1_1, formData, data, e_2;
            var e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 11, , 12]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        items = [];
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        _a = __values(this.state.products), _b = _a.next();
                        _d.label = 3;
                    case 3:
                        if (!!_b.done) return [3, 6];
                        product = _b.value;
                        return [4, this.productsRefs[product.productId].getData()];
                    case 4:
                        item = _d.sent();
                        if (item === null || item === void 0 ? void 0 : item.error) {
                            valid = false;
                            return [3, 5];
                        }
                        items.push(item);
                        _d.label = 5;
                    case 5:
                        _b = _a.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error('required');
                        }
                        formData = this.form.toJSON();
                        data = {
                            warehouse: formData.warehouse,
                            comment: formData.comment,
                            reason: formData.reason,
                            legalCode: formData === null || formData === void 0 ? void 0 : formData.legalCode,
                            plan: items === null || items === void 0 ? void 0 : items.map(function (item) { return ({
                                product: item.product,
                                lot: item.lot,
                                storage: item.storage,
                                amount: parseInt(item.amount, 10),
                                type: 'available',
                            }); }),
                        };
                        return [4, this.repository.post('/b2b/stock-disposal', { data: data })];
                    case 10:
                        _d.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-disposal.create.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo('/stock-disposal')];
                    case 11:
                        e_2 = _d.sent();
                        this.setState({ loading: false, error: (0, trans_1.trans)("stock-disposal.form.missing.".concat(e_2.message)) });
                        return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    B2bStockDisposalCreateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAddProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('stock-disposal.add-product.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$showAddProductModal.close(); }, onAddProduct: function (product) { return _this.setState({
                        products: __spreadArray(__spreadArray([], __read(_this.state.products), false), [product], false),
                    }); }, warehouse: _this.state.warehouse }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$showDeleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.product });
                } }));
    };
    B2bStockDisposalCreateScreen.prototype.handleRemoveProduct = function (productId) {
        var _a;
        var newProducts = (_a = this.state.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
        this.setState({ products: newProducts });
        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-disposal.create.delete.product.success.toast'));
        this.$showDeleteProductModal.close();
    };
    return B2bStockDisposalCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockDisposalCreateScreen = B2bStockDisposalCreateScreen;
