"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesOptionsListScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var React = require("react");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var attribute_option_create_component_1 = require("./attribute-option.create.component");
var attribute_option_update_component_1 = require("./attribute-option.update.component");
var AttributesOptionsListScreen = (function (_super) {
    __extends(AttributesOptionsListScreen, _super);
    function AttributesOptionsListScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            optionOnCreate: false,
            optionsOnUpdate: null,
            attribute: null
        };
        return _this;
    }
    AttributesOptionsListScreen.prototype.componentDidMount = function () {
        this.fetchAttribute();
    };
    AttributesOptionsListScreen.prototype.fetchAttribute = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/admin/b2c/category-attribute/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.attributeId))];
                    case 1:
                        response = _c.sent();
                        this.setState({ attribute: response, loading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    AttributesOptionsListScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (!!this.state.optionOnUpdate) {
            return React.createElement("div", null,
                React.createElement(attribute_option_update_component_1.AttributeOptionUpdateComponent, { onCancel: function () { return _this.handleUpdateCancel(); }, onSave: function (value) { return _this.handleUpdateSave(value); }, attribute: this.state.attribute, option: this.state.optionOnUpdate }));
        }
        if (!!this.state.optionOnCreate) {
            return React.createElement("div", null,
                React.createElement(attribute_option_create_component_1.AttributeOptionCreateComponent, { onCancel: function () { return _this.handleCreateCancel(); }, attribute: this.state.attribute, onSave: function (value) { return _this.handleCreateSave(value); } }));
        }
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-6' },
                        React.createElement("div", { className: 'display-flex flex-row mr-6' },
                            React.createElement("h2", { onClick: function () { return router_provider_1.RouterProvider.goTo('/attributes'); }, className: 'color--secondary-dark-3 cursor-pointer' }, (0, trans_1.trans)('attribute.list.title')),
                            React.createElement("h2", { className: 'color--secondary-dark-3 mx-2' }, "/"),
                            React.createElement("h2", { className: '' },
                                " ", (_b = (_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 :
                                _b.hu)),
                        React.createElement("span", null, (_d = (_c = this.state.attribute) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 :
                            _d.length,
                            " db"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(button_1.Button, { onClick: function () { return _this.handleCreateOption(); }, title: (0, trans_1.trans)('attribute-options.list.button.create.title'), iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' })),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo("/attributes/update/".concat(_this.state.attribute._id)); }, title: (0, trans_1.trans)('attribute.update.button.title'), iconLeft: 'fal fa-edit', className: 'button-primary-link button-size-normal' }))))),
            React.createElement("div", null, this.renderTable()));
    };
    AttributesOptionsListScreen.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { var _a, _b; return (_b = (_a = _this.state.attribute) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.map(function (o, index) { return _this.renderItem(_this, o, index); }); } });
    };
    AttributesOptionsListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title_hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title_en'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('icon'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('description_hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('description_en'))));
    };
    AttributesOptionsListScreen.prototype.setHeaderCell = function (p) {
        return {
            sortValue: null,
            enableSort: false,
            title: (0, trans_1.trans)("attribute-options.table.header.".concat(p)),
            property: p
        };
    };
    AttributesOptionsListScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'cursor-pointer', key: item._id, onClick: function () { return _this.handleUpdateOption(item); } },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.value) === null || _a === void 0 ? void 0 : _a.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, (_b = item === null || item === void 0 ? void 0 : item.value) === null || _b === void 0 ? void 0 : _b.en)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("div", { className: 'p-2' },
                    React.createElement("img", { className: 'invert-image-filter', src: "/assets/drink-attributes/".concat(item.icon, ".svg"), alt: "" }))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, ((_c = item === null || item === void 0 ? void 0 : item.description) === null || _c === void 0 ? void 0 : _c.hu) || '-')),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, ((_d = item === null || item === void 0 ? void 0 : item.description) === null || _d === void 0 ? void 0 : _d.en) || '-')));
    };
    AttributesOptionsListScreen.prototype.handleUpdateOption = function (item) {
        this.setState({ optionOnUpdate: item });
    };
    AttributesOptionsListScreen.prototype.handleUpdateCancel = function () {
        this.setState({ optionOnUpdate: null });
    };
    AttributesOptionsListScreen.prototype.handleUpdateSave = function (value) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var attribute, options, attributeResponse, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        attribute = this.state.attribute;
                        options = this.state.attribute.options.map(function (o) {
                            if (value.selector === o.selector) {
                                return value;
                            }
                            return o;
                        });
                        attribute.options = options;
                        return [4, this.repository.put("/admin/b2c/category-attribute/".concat((_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a._id), { data: attribute })];
                    case 1:
                        attributeResponse = _b.sent();
                        this.setState({ optionOnUpdate: null, attribute: attributeResponse });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('attribute-option.update.success.toast'));
                        return [3, 3];
                    case 2:
                        e_2 = _b.sent();
                        this.setState({ error: e_2 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    AttributesOptionsListScreen.prototype.handleCreateOption = function () {
        this.setState({ optionOnCreate: true });
    };
    AttributesOptionsListScreen.prototype.handleCreateCancel = function () {
        this.setState({ optionOnCreate: null });
    };
    AttributesOptionsListScreen.prototype.handleCreateSave = function (value) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var attribute, options, attributeResponse, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        attribute = this.state.attribute;
                        value.selector = (0, random_id_1.randomId)();
                        options = __spreadArray(__spreadArray([], __read(this.state.attribute.options), false), [value], false);
                        attribute.options = options;
                        return [4, this.repository.put("/admin/b2c/category-attribute/".concat((_a = this.state.attribute) === null || _a === void 0 ? void 0 : _a._id), { data: attribute })];
                    case 1:
                        attributeResponse = _b.sent();
                        this.setState({ optionOnCreate: null, attribute: attributeResponse });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('attribute-option.create.success.toast'));
                        return [3, 3];
                    case 2:
                        e_3 = _b.sent();
                        this.setState({ error: e_3 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return AttributesOptionsListScreen;
}(abstract_component_1.AbstractComponent));
exports.AttributesOptionsListScreen = AttributesOptionsListScreen;
