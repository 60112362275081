"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerSelectComponent = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var select_input_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var CustomerPartnerSelectComponent = (function (_super) {
    __extends(CustomerPartnerSelectComponent, _super);
    function CustomerPartnerSelectComponent() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            customerPartners: (_b = (_a = _this.props.control$) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b[(_c = _this.props) === null || _c === void 0 ? void 0 : _c.property],
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerPartners: new field_1.Field({
                    placeholder: '',
                    value: ((_e = (_d = _this.props.control$) === null || _d === void 0 ? void 0 : _d.getQuery()) === null || _e === void 0 ? void 0 : _e[(_f = _this.props) === null || _f === void 0 ? void 0 : _f.property]) || null,
                    name: 'customerPartners',
                    optionsMap: (function (response) { return response.items.map(function (i) { return ({ name: "".concat(i === null || i === void 0 ? void 0 : i.name, " (").concat(i === null || i === void 0 ? void 0 : i.brandName, ")"), key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/b2b/customer-partner/options?_limit=1000000',
                    loadOptionsAfterMount: true,
                    label: _this.props.label,
                    validators: []
                }),
            }
        });
        return _this;
    }
    CustomerPartnerSelectComponent.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c, _d;
        this.form.fields.customerPartners.setValue((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.defaultValue) !== null && _b !== void 0 ? _b : '');
        this.handleChange((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.defaultValue) !== null && _d !== void 0 ? _d : '');
        this.$subscriptions.push(this.form.fields.customerPartners.$value.subscribe(function (v) {
            var _a, _b;
            (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, v);
            _this.handleChange(v);
        }), this.form.fields.customerPartners.$options.subscribe(function (options) {
            if (!(options === null || options === void 0 ? void 0 : options.length) || !_this.props.extraOption || options.find(function (i) { return i.value === _this.props.extraOption.value; })) {
                return;
            }
            _this.form.fields.customerPartners.setOptions(__spreadArray([_this.props.extraOption], __read(options), false));
        }));
    };
    CustomerPartnerSelectComponent.prototype.render = function () {
        return React.createElement("div", { className: "w-100 display-flex align-items-center" },
            React.createElement("div", { className: "flex-fill" },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartners, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, multiple: false, searchable: true, className: 'select-input-basic' }))));
    };
    CustomerPartnerSelectComponent.prototype.handleChange = function (value) {
        var _a;
        this.props.control$.set((((_a = this.props) === null || _a === void 0 ? void 0 : _a.property) || 'customerPartners'), value);
        this.props.control$.page(0);
        var state = this.state;
        state.customerPartners = value;
        this.setState(state);
    };
    return CustomerPartnerSelectComponent;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerSelectComponent = CustomerPartnerSelectComponent;
