"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExciseDutyDataForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var lodash_1 = require("lodash");
var React = require("react");
var form_1 = require("../../../../components/form/form");
var ExciseDutyDataForm = (function (_super) {
    __extends(ExciseDutyDataForm, _super);
    function ExciseDutyDataForm() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            isExciseDutyDisabled: true,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                knCode: new field_1.Field({
                    name: 'knCode',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.update.excise-duty.form.field.kn-code.label'),
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.knCode) || '',
                    loadOptionsAfterMount: true,
                    optionsEndpoint: '/admin/kn-attribute?limit=100000',
                    optionsMap: function (response) { return response.items.map(function (i) { return ({ key: i._id, value: i._id, name: i.knCode, raw: i }); }); },
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                exciseDutyType: new field_1.Field({
                    name: 'exciseDutyType',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.update.excise-duty.form.field.excise-duty-type.label'),
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.exciseDutyType) || '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    ExciseDutyDataForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.$subscriptions.push(this.form.fields.knCode.$value.subscribe(function (v) { return _this.onKnCodeChange(v); }));
                return [2];
            });
        });
    };
    ExciseDutyDataForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.form.fields.knCode.setValue((_a = this.props.values) === null || _a === void 0 ? void 0 : _a.knCode);
            this.form.fields.exciseDutyType.setValue((_b = this.props.values) === null || _b === void 0 ? void 0 : _b.exciseDutyType);
        }
    };
    ExciseDutyDataForm.prototype.getOptions = function (attribute) {
        var _a, _b, _c, _d, _e, _f;
        var alcoholAttribute = (_c = (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.b2cCategory) === null || _b === void 0 ? void 0 : _b.b2cAttributeValues) === null || _c === void 0 ? void 0 : _c.find(function (item) { var _a; return ((_a = item.attribute) === null || _a === void 0 ? void 0 : _a.key) === attribute; });
        return (_f = (_e = (_d = alcoholAttribute === null || alcoholAttribute === void 0 ? void 0 : alcoholAttribute.attribute) === null || _d === void 0 ? void 0 : _d.options) === null || _e === void 0 ? void 0 : _e.map(function (item) { var _a; return ({ key: item.selector, name: (_a = item.value) === null || _a === void 0 ? void 0 : _a.hu, value: item.value.hu }); })) === null || _f === void 0 ? void 0 : _f.sort(function (i, j) { return i.name.localeCompare(j.name, undefined, { numeric: true, sensitivity: 'base' }); });
    };
    ExciseDutyDataForm.prototype.onKnCodeChange = function (value) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var knAttribute, exciseDutyList, options;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.form.fields.exciseDutyType.setValue('');
                        if (!value) {
                            (_a = this.form.fields.exciseDutyType) === null || _a === void 0 ? void 0 : _a.setOptions([]);
                            this.setState({ isExciseDutyDisabled: true });
                            this.form.fields.exciseDutyType.removeValidators();
                            return [2];
                        }
                        knAttribute = (_c = (_b = this.form.fields.knCode.getOptions()) === null || _b === void 0 ? void 0 : _b.find(function (i) { return i.value === value; })) === null || _c === void 0 ? void 0 : _c.raw;
                        if (!(knAttribute === null || knAttribute === void 0 ? void 0 : knAttribute.isExciseDuty)) {
                            this.setState({ isExciseDutyDisabled: true });
                            this.form.fields.exciseDutyType.removeValidators();
                        }
                        else {
                            this.setState({ isExciseDutyDisabled: false });
                            this.form.fields.exciseDutyType.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        return [4, this.repository.get('/admin/kn-attribute/excise-duty-options')];
                    case 1:
                        exciseDutyList = _e.sent();
                        options = exciseDutyList
                            .filter(function (i) { return knAttribute.exciseDutyList.includes(i.code); })
                            .map(function (o) { return ({
                            name: "".concat(o.code, " - ").concat(o.description),
                            value: o.code,
                            key: o.code,
                        }); });
                        (_d = this.form.fields.exciseDutyType) === null || _d === void 0 ? void 0 : _d.setOptions(options);
                        return [2];
                }
            });
        });
    };
    ExciseDutyDataForm.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement("div", { className: 'row' },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.knCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { className: 'select-input-basic col-12', searchable: true })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.exciseDutyType, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { className: 'select-input-basic col-12', disabled: this.state.isExciseDutyDisabled })))));
    };
    ExciseDutyDataForm.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError, keys, _b, _c, _d, key, value;
            var e_1, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _f.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _f.sent();
                        hasError = false;
                        keys = [];
                        try {
                            for (_b = __values(Object.entries(errors)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                                if (!!value.length) {
                                    keys.push(key);
                                }
                                hasError = (hasError || !!value.length);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        if (hasError) {
                            return [2, { error: true, keys: keys }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    return ExciseDutyDataForm;
}(abstract_component_1.AbstractComponent));
exports.ExciseDutyDataForm = ExciseDutyDataForm;
