"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBar = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
require("./progress-bar.scss");
var ProgressBar = (function (_super) {
    __extends(ProgressBar, _super);
    function ProgressBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            config: _this.props.config || [],
            activeStep: 0,
            height: 0,
            width: 0,
        };
        return _this;
    }
    ProgressBar.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.init()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ProgressBar.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.props.status !== prevProps.status)) return [3, 2];
                        return [4, this.init()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ProgressBar.prototype.init = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var activeStep, textWidths, height, width;
            return __generator(this, function (_b) {
                activeStep = this.state.config.indexOf(this.props.status);
                textWidths = this.state.config.map(function (i) {
                    var canvas = document.createElement('canvas');
                    var context = canvas.getContext('2d');
                    context.font = getComputedStyle(document.body).font;
                    return context.measureText(i.toUpperCase()).width;
                });
                height = Math.max.apply(Math, __spreadArray([], __read(textWidths), false));
                width = "".concat(100 / ((_a = this.state.config) === null || _a === void 0 ? void 0 : _a.length), "%");
                this.setState({ activeStep: activeStep, width: width, height: height });
                return [2];
            });
        });
    };
    ProgressBar.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "progress-bar-wrapper ".concat(this.props.className) }, (_a = this.state.config) === null || _a === void 0 ? void 0 :
            _a.map(function (label, step) {
                var _a;
                var labelStatus = step < _this.state.activeStep || _this.state.activeStep === (((_a = _this.state.config) === null || _a === void 0 ? void 0 : _a.length) - 1)
                    ? 'accomplished'
                    : step === _this.state.activeStep
                        ? 'current'
                        : '';
                var width = step === 0 ? 16 : _this.state.width;
                return React.createElement("div", { key: step, className: "step ".concat(labelStatus), style: { width: width } },
                    React.createElement("div", { className: 'label', style: { height: _this.state.height } },
                        React.createElement("span", null, (0, trans_1.trans)("b2b-order.status.".concat(label)).toUpperCase())),
                    React.createElement("div", { className: 'step-wrapper' },
                        (step !== 0) && React.createElement("div", { className: 'step-bar' }),
                        React.createElement("div", { className: 'step-indicator' }, (step < _this.state.activeStep)
                            ? React.createElement("i", { className: 'fas fa-check' })
                            : null)));
            }),
            React.createElement("div", { className: 'spacer', style: { width: this.state.width } }));
    };
    return ProgressBar;
}(abstract_component_1.AbstractComponent));
exports.ProgressBar = ProgressBar;
