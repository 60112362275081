module.exports = {
    'depot.list.title': 'Telephelyek',
    'depot.title': 'Telephely',
    'depot.list.button-create.title': 'Új telephely létrehozása',
    'depot.update.save-box.button.enable-edit': 'Telephely szerkesztése',
    'depot.update.save-box.button.save': 'Mentés',
    'depot.table.header.identifier': 'Azonosító',
    'depot.table.header.title': 'Elnevezés',
    'depot.table.header.lastOrder': 'Utolsó rendelés',
    'depot.table.header.createdAt': 'Létrehozva',
    'depot.status.active': 'Aktív',
    'depot.status.inactive': 'Inaktív',
    'depot.create.save-box.button.save': 'Létrehozás',
    'depot.update.subtitle.basic-data.company-data': 'Adatok',
    'depot.update.subtitle.basic-data.address': 'Cím',
    'depot.update.subtitle.basic-data.other': 'Egyéb adatok',
    'depot.basic-data.form.field.title.label': 'Elnevezés',
    'depot.basic-data.form.field.description.label': 'Megjegyzés',
    'depot.basic-data.form.field.identifier.label': 'Azonosító',
    'depot.basic-data.form.field.companyName.label': 'Cégnév',
    'depot.basic-data.form.field.taxNumber.label': 'Adószám',
    'depot.basic-data.form.field.felir.label': 'FELIR',
    'depot.basic-data.form.field.zipCode.label': 'Irányítószám',
    'depot.basic-data.form.field.country.label': 'Ország',
    'depot.basic-data.form.field.city.label': 'Város',
    'depot.basic-data.form.field.address.label': 'Cím',
    'depot.basic-data.form.field.number.label': 'Házszám',
    'depot.basic-data.form.field.comment.label': 'Megjegyzés',
    'depot.update.success.toast': 'Sikeresen módosítás',
    'depot.create.success.toast': 'Sikeresen létrehozás',
    'depot.status-update.success.toast': 'Sikeresen státusz módosítás',
};
