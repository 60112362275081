"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendedExtraAttributesForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var lodash_1 = require("lodash");
var React = require("react");
var user_permission_1 = require("../../../../common/libs/user-permission");
var form_1 = require("../../../../components/form/form");
var extended_extra_attribute_field_1 = require("./extended-extra-attribute-field");
var ExtendedExtraAttributesForm = (function (_super) {
    __extends(ExtendedExtraAttributesForm, _super);
    function ExtendedExtraAttributesForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {}
        });
        _this.state = {
            show: false,
            attributes: _this.props.attributes || [],
            productCategory: _this.props.productCategory,
            productType: _this.props.productType,
            editorLanguage: _this.props.editorLanguage || 'hu'
        };
        return _this;
    }
    ExtendedExtraAttributesForm.prototype.componentDidMount = function () {
        this.initForm();
    };
    ExtendedExtraAttributesForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(prevProps.attributes, this.props.attributes)) return [3, 2];
                        return [4, this.setState({ attributes: this.props.attributes })];
                    case 1:
                        _a.sent();
                        this.initForm();
                        _a.label = 2;
                    case 2:
                        if (!!(0, lodash_1.isEqual)(prevProps.productCategory, this.props.productCategory)) return [3, 4];
                        return [4, this.setState({ productCategory: this.props.productCategory })];
                    case 3:
                        _a.sent();
                        this.initForm();
                        _a.label = 4;
                    case 4:
                        if (!!(0, lodash_1.isEqual)(prevProps.productType, this.props.productType)) return [3, 6];
                        return [4, this.setState({ productType: this.props.productType })];
                    case 5:
                        _a.sent();
                        this.initForm();
                        _a.label = 6;
                    case 6: return [2];
                }
            });
        });
    };
    ExtendedExtraAttributesForm.prototype.initForm = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var _e, _f, attribute, e_1_1;
            var e_1, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0: return [4, this.setState({ show: false })];
                    case 1:
                        _h.sent();
                        if (!this.state.attributes.length || !this.state.productCategory || !this.state.productType) {
                            return [2];
                        }
                        _h.label = 2;
                    case 2:
                        _h.trys.push([2, 15, 16, 17]);
                        _e = __values(this.state.attributes), _f = _e.next();
                        _h.label = 3;
                    case 3:
                        if (!!_f.done) return [3, 14];
                        attribute = _f.value;
                        if (!(!!attribute.hidden || (!(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read(attribute.enableView), false)) && !(0, user_permission_1.checkPermission)(__spreadArray(['admin'], __read(attribute.enableView), false))))) return [3, 5];
                        return [4, this.form.deleteField(attribute === null || attribute === void 0 ? void 0 : attribute.key)];
                    case 4:
                        _h.sent();
                        return [3, 13];
                    case 5:
                        if (!(this.state.productType === 'bundle' && !(attribute === null || attribute === void 0 ? void 0 : attribute.isBundle))) return [3, 7];
                        return [4, this.form.deleteField(attribute === null || attribute === void 0 ? void 0 : attribute.key)];
                    case 6:
                        _h.sent();
                        return [3, 13];
                    case 7:
                        if (!(this.state.productType === 'single' && !(attribute === null || attribute === void 0 ? void 0 : attribute.isSingle))) return [3, 9];
                        return [4, this.form.deleteField(attribute === null || attribute === void 0 ? void 0 : attribute.key)];
                    case 8:
                        _h.sent();
                        return [3, 13];
                    case 9:
                        if (!(!(attribute === null || attribute === void 0 ? void 0 : attribute.categories.includes('common')) && !attribute.categories.includes(this.state.productCategory))) return [3, 11];
                        return [4, this.form.deleteField(attribute === null || attribute === void 0 ? void 0 : attribute.key)];
                    case 10:
                        _h.sent();
                        return [3, 13];
                    case 11: return [4, this.form.field(attribute === null || attribute === void 0 ? void 0 : attribute.key, new field_1.Field({
                            id: attribute === null || attribute === void 0 ? void 0 : attribute.key,
                            placeholder: '',
                            value: attribute === null || attribute === void 0 ? void 0 : attribute.value,
                            name: attribute === null || attribute === void 0 ? void 0 : attribute.key,
                            options: (0, lodash_1.uniqBy)(((attribute === null || attribute === void 0 ? void 0 : attribute.inputOptions) || []).map(function (o) { return ({ key: o, name: o.toString(), value: o }); }), function (k) { return k.key; }),
                            label: this.hasRequired(attribute.validators) ? React.createElement("div", null,
                                (((_a = attribute === null || attribute === void 0 ? void 0 : attribute.labelByCategory) === null || _a === void 0 ? void 0 : _a[this.state.productCategory]) || ((_b = attribute === null || attribute === void 0 ? void 0 : attribute.labelByCategory) === null || _b === void 0 ? void 0 : _b.common) || (attribute === null || attribute === void 0 ? void 0 : attribute.key)),
                                " ",
                                React.createElement("i", { className: 'color--error-6' }, "*")) : (((_c = attribute === null || attribute === void 0 ? void 0 : attribute.labelByCategory) === null || _c === void 0 ? void 0 : _c[this.state.productCategory]) || ((_d = attribute === null || attribute === void 0 ? void 0 : attribute.labelByCategory) === null || _d === void 0 ? void 0 : _d.common) || (attribute === null || attribute === void 0 ? void 0 : attribute.key)),
                            validators: this.getAttributeValidators(attribute.validators)
                        }))];
                    case 12:
                        _h.sent();
                        _h.label = 13;
                    case 13:
                        _f = _e.next();
                        return [3, 3];
                    case 14: return [3, 17];
                    case 15:
                        e_1_1 = _h.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 17];
                    case 16:
                        try {
                            if (_f && !_f.done && (_g = _e.return)) _g.call(_e);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 17: return [4, this.setState({ show: true })];
                    case 18:
                        _h.sent();
                        return [2];
                }
            });
        });
    };
    ExtendedExtraAttributesForm.prototype.render = function () {
        if (!this.state.show) {
            return null;
        }
        return React.createElement("div", null, this.renderAttributesForm());
    };
    ExtendedExtraAttributesForm.prototype.renderAttributesForm = function () {
        var _this = this;
        var regularInputFields = this.state.attributes.filter(function (a) { return !a.isCalculated; }).sort(function (a, b) { return a.sort - b.sort; });
        var calculatedFields = this.state.attributes.filter(function (a) { return !!a.isCalculated; }).sort(function (a, b) { return a.sort - b.sort; });
        return React.createElement(form_1.Form, null,
            React.createElement("div", { className: "row mb-4" }, (regularInputFields || []).map(function (attribute) {
                var _a, _b;
                if (!_this.form.fields[attribute === null || attribute === void 0 ? void 0 : attribute.key]) {
                    return null;
                }
                return React.createElement("div", { className: 'col-12', key: attribute === null || attribute === void 0 ? void 0 : attribute.key },
                    React.createElement(extended_extra_attribute_field_1.ExtendedExtraAttributeField, { field: _this.form.fields[attribute === null || attribute === void 0 ? void 0 : attribute.key], attribute: attribute, disabled: _this.props.disabled, productCategory: (_a = _this.state) === null || _a === void 0 ? void 0 : _a.productCategory, productType: (_b = _this.state) === null || _b === void 0 ? void 0 : _b.productType }));
            })),
            React.createElement("div", { className: 'w-100 border-bottom-1 border-color--secondary-dark-3' },
                React.createElement("h5", null, "Sz\u00E1m\u00EDtott mez\u0151k")),
            React.createElement("div", { className: "row" }, (calculatedFields || []).map(function (attribute) {
                var _a, _b;
                if (!_this.form.fields[attribute === null || attribute === void 0 ? void 0 : attribute.key]) {
                    return null;
                }
                return React.createElement("div", { className: 'col-12', key: attribute === null || attribute === void 0 ? void 0 : attribute.key },
                    React.createElement(extended_extra_attribute_field_1.ExtendedExtraAttributeField, { field: _this.form.fields[attribute === null || attribute === void 0 ? void 0 : attribute.key], attribute: attribute, productCategory: (_a = _this.state) === null || _a === void 0 ? void 0 : _a.productCategory, disabled: _this.props.disabled, productType: (_b = _this.state) === null || _b === void 0 ? void 0 : _b.productType }));
            })));
    };
    ExtendedExtraAttributesForm.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError, keys, _b, _c, _d, key, value;
            var e_2, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _f.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _f.sent();
                        hasError = false;
                        keys = [];
                        try {
                            for (_b = __values(Object.entries(errors)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                                if (!!value.length) {
                                    keys.push(key);
                                }
                                hasError = (hasError || !!value.length);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (hasError) {
                            return [2, { error: true, keys: keys }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    ExtendedExtraAttributesForm.prototype.getAttributeValidators = function (validatorKeys) {
        var e_3, _a;
        var validators = [];
        try {
            for (var validatorKeys_1 = __values(validatorKeys), validatorKeys_1_1 = validatorKeys_1.next(); !validatorKeys_1_1.done; validatorKeys_1_1 = validatorKeys_1.next()) {
                var key = validatorKeys_1_1.value;
                switch (key) {
                    case 'required':
                        validators.push(new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required')));
                        break;
                    case 'ean':
                        validators.push(new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required')));
                        break;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (validatorKeys_1_1 && !validatorKeys_1_1.done && (_a = validatorKeys_1.return)) _a.call(validatorKeys_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return validators;
    };
    ExtendedExtraAttributesForm.prototype.hasRequired = function (validatorKeys) {
        return !!validatorKeys.find(function (k) { return (k === 'required' || k === 'ean'); });
    };
    return ExtendedExtraAttributesForm;
}(abstract_component_1.AbstractComponent));
exports.ExtendedExtraAttributesForm = ExtendedExtraAttributesForm;
