"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetFailedStatusModal = void 0;
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var form_1 = require("../../../../components/form/form");
var SetFailedStatusModal = (function (_super) {
    __extends(SetFailedStatusModal, _super);
    function SetFailedStatusModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SetFailedStatusModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'display-flex flex-column' },
            React.createElement("div", { className: 'delete-big-icon mb-2 align-self-center' },
                React.createElement("i", { className: 'fas fa-trash color--contrast-light' })),
            React.createElement("h4", { className: 'align-self-center mb-2' }, (0, trans_1.trans)('procurement.view.failed.modal.title')),
            React.createElement("p", { className: 'align-self-center mb-2' }, (0, trans_1.trans)('procurement.view.failed.modal.text')),
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.props.form.fields.reason, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled, rows: 3, className: 'textarea-input-basic' }))),
            React.createElement("div", { className: 'display-flex justify-content-center' },
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('procurement.view.failed.modal.cancel.button'), onClick: function () { return _this.props.onClose(); }, className: 'button-neutral-inverze button-size-normal' }),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('procurement.view.failed.modal.submit.button'), onClick: function () { return _this.props.onSetStatusFailed(); }, className: 'button-primary-inverze button-size-normal ml-4' })));
    };
    return SetFailedStatusModal;
}(abstract_component_1.AbstractComponent));
exports.SetFailedStatusModal = SetFailedStatusModal;
