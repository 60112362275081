"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieChartCustomTooltip = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./pie-chart-custom-tooltip.scss");
var PieChartCustomTooltip = (function (_super) {
    __extends(PieChartCustomTooltip, _super);
    function PieChartCustomTooltip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PieChartCustomTooltip.prototype.render = function () {
        var _a, _b, _c, _d, _e;
        if (this.props.active && ((_a = this.props.payload) === null || _a === void 0 ? void 0 : _a.length)) {
            return (React.createElement("div", { className: "custom-tooltip" },
                React.createElement("p", { className: "label" }, "".concat((_c = (_b = this.props.payload) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.name, " : ").concat((0, currency_parser_1.moneyFormat)((_e = (_d = this.props.payload) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.value, this.props.unit)))));
        }
        return null;
    };
    return PieChartCustomTooltip;
}(abstract_component_1.AbstractComponent));
exports.PieChartCustomTooltip = PieChartCustomTooltip;
