"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockIssuingCreateUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var issuing_legal_codes_1 = require("../../common/libs/issuing-legal-codes");
var form_1 = require("../../components/form/form");
var badge_1 = require("../../components/libs/badge/badge");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
require("./b2b-stock-issuing.scss");
var fifo_component_1 = require("./components/fifo.component");
var B2bStockIssuingCreateUpdateScreen = (function (_super) {
    __extends(B2bStockIssuingCreateUpdateScreen, _super);
    function B2bStockIssuingCreateUpdateScreen() {
        var _this = this;
        var _a, _b, _c;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                isB2c: new field_1.Field({
                    name: 'isB2c',
                    id: 'isB2c',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.isB2c.label')),
                    validators: []
                }),
                isReturn: new field_1.Field({
                    name: 'isReturn',
                    id: 'isReturn',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.isReturn.label')),
                    validators: []
                }),
                isThirdParty: new field_1.Field({
                    name: 'isThirdParty',
                    id: 'isThirdParty',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.isThirdParty.label')),
                    validators: []
                }),
                isTransaction: new field_1.Field({
                    name: 'isTransaction',
                    id: 'isTransaction',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.isTransaction.label')),
                    validators: []
                }),
                isForeign: new field_1.Field({
                    name: 'isForeign',
                    id: 'isForeign',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.isForeign.label')),
                    validators: []
                }),
                comment: new field_1.Field({
                    name: 'comment',
                    id: 'comment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.comment.label')),
                    validators: []
                }),
                legalCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'legalCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.legalCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: issuing_legal_codes_1.ISSUING_LEGAL_CODES.map(function (c) { return ({ key: c.code, value: c.code, name: "".concat(c.code, " - ").concat(c.title) }); }),
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                customerPartner: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'customerPartner',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.partner.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/customer-partner?_limit=1000000&isActive=true',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                            name: o.name,
                            value: o._id,
                            key: o._id,
                            raw: o
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                supplierPartner: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'supplierPartner',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.cp.form.supplier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/supplier-partner',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                            name: o.name,
                            value: o._id,
                            key: o._id,
                            raw: o
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                shippingData: new field_1.Field({
                    name: 'shippingData',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.shipping-data.label')),
                    value: '',
                    options: [],
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                plan: new field_1.Field({
                    name: 'value',
                    value: []
                }),
                purpose: new field_1.Field({
                    name: 'purpose',
                    id: 'purpose',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.purpose.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                fullName: new field_1.Field({
                    name: 'fullName',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.full-name.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: []
                }),
                email: new field_1.Field({
                    name: 'email',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.email.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: []
                }),
                phone: new field_1.Field({
                    name: 'phone',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.phone.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: []
                }),
                city: new field_1.Field({
                    name: 'city',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.city.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                zipCode: new field_1.Field({
                    name: 'zipCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.zipCode.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                address: new field_1.Field({
                    name: 'address',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.address.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                taxNumber: new field_1.Field({
                    name: 'taxNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.taxNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: []
                }),
                operatingPermitNumber: new field_1.Field({
                    name: 'operatingPermitNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.operatingPermitNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: []
                }),
                heaCode: new field_1.Field({
                    name: 'heaCode',
                    label: React.createElement("div", null, (0, trans_1.trans)('stock-issuing.create.form.heaCode.label')),
                    value: '',
                    validators: []
                }),
                licensePlateNumber: new field_1.Field({
                    name: 'licensePlateNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-issuing.create.form.licensePlateNumber.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                depot: new field_1.Field({
                    name: 'depot',
                    id: 'depot',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.depot.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/depot?hasStorage=true',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: o.title, value: o._id, key: o._id }); }); },
                    loadOptionsAfterMount: true,
                    validators: []
                }),
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    id: 'warehouse',
                    value: '',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.warehouse.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    options: [],
                    loadOptionsAfterMount: true,
                    validators: []
                }),
            }
        });
        _this.state = {
            loading: !!((_c = (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id),
            error: '',
            selectedCustomerPartnerId: null,
            selectedSupplierPartnerId: null,
            issue: null,
            isB2c: false,
            isReturn: false,
            isThirdParty: false,
            isTransaction: false,
            isWarehouseSelected: false,
            isTakeoutSheetLoading: false,
            warehouse: {},
            defaultValues: {},
            taxDepot: false,
        };
        return _this;
    }
    B2bStockIssuingCreateUpdateScreen.prototype.componentDidMount = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        this.$subscriptions.push(this.form.fields.isB2c.$value.subscribe(function (value) { return _this.onIsB2cChange(value); }), this.form.fields.isReturn.$value.subscribe(function (value) { return _this.onReturnChange(value); }), this.form.fields.isThirdParty.$value.subscribe(function (value) { return _this.onThirdPartyChange(value); }), this.form.fields.isTransaction.$value.subscribe(function (value) { return _this.onTransactionChange(value); }), this.form.fields.customerPartner.$value.subscribe(function (value) { return _this.onCustomerPartnerChange(value); }), this.form.fields.supplierPartner.$value.subscribe(function (value) { return _this.onSupplierPartnerChange(value); }), this.form.fields.shippingData.$value.subscribe(function (value) { return _this.onShippingDataChange(value); }), this.form.fields.warehouse.$value.subscribe(function (value) { return _this.onWarehouseChange(value); }));
                        if (!((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id)) return [3, 3];
                        return [4, this.fetchWarehouses()];
                    case 1:
                        _j.sent();
                        return [4, this.download((_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.id)];
                    case 2:
                        _j.sent();
                        _j.label = 3;
                    case 3: return [4, this.fetchDefaultWarehouseAndDepot()];
                    case 4:
                        _j.sent();
                        if (!(((_g = this.state.issue) === null || _g === void 0 ? void 0 : _g.businessType) === 'return')) return [3, 6];
                        return [4, this.onReturnChange(true)];
                    case 5:
                        _j.sent();
                        return [3, 8];
                    case 6:
                        if (!(((_h = this.state.issue) === null || _h === void 0 ? void 0 : _h.businessType) === 'third-party')) return [3, 8];
                        return [4, this.onThirdPartyChange(true)];
                    case 7:
                        _j.sent();
                        _j.label = 8;
                    case 8:
                        this.$subscriptions.push(this.form.fields.depot.$value.subscribe(function (value) { return _this.onDepotChange(value); }));
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
        return __awaiter(this, void 0, void 0, function () {
            var defaultValues;
            return __generator(this, function (_7) {
                switch (_7.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(this.state.isB2c, prevState.isB2c)) return [3, 2];
                        return [4, this.setDefaultOptions(this.state.defaultValues)];
                    case 1:
                        _7.sent();
                        _7.label = 2;
                    case 2:
                        if (!(0, lodash_1.isEqual)(this.state.taxDepot, prevState.taxDepot)) {
                            if (this.state.taxDepot) {
                                this.form.fields.legalCode.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            }
                            else {
                                this.form.fields.legalCode.removeValidators();
                            }
                        }
                        if (!!(0, lodash_1.isEqual)(this.state.issue, prevState.issue)) return [3, 10];
                        if (!(((_a = this.state.issue) === null || _a === void 0 ? void 0 : _a.businessType) === 'b2c')) return [3, 5];
                        this.form.fields.isB2c.setValue(true);
                        return [4, this.fetchDefaultWarehouseAndDepot()];
                    case 3:
                        defaultValues = _7.sent();
                        return [4, this.setDefaultOptions(defaultValues)];
                    case 4:
                        _7.sent();
                        return [3, 7];
                    case 5:
                        this.form.fields.depot.setValue(((_b = this.state.issue) === null || _b === void 0 ? void 0 : _b.depot) || '');
                        this.form.fields.warehouse.setValue(((_c = this.state.issue) === null || _c === void 0 ? void 0 : _c.warehouse) || '');
                        return [4, this.fetchWarehouses()];
                    case 6:
                        _7.sent();
                        _7.label = 7;
                    case 7:
                        if (((_d = this.state.issue) === null || _d === void 0 ? void 0 : _d.businessType) === 'return') {
                            this.form.fields.isReturn.setValue(true);
                        }
                        if (((_e = this.state.issue) === null || _e === void 0 ? void 0 : _e.businessType) === 'third-party') {
                            this.form.fields.isThirdParty.setValue(true);
                        }
                        if (((_f = this.state.issue) === null || _f === void 0 ? void 0 : _f.businessType) === 'transaction') {
                            this.form.fields.isTransaction.setValue(true);
                        }
                        this.form.fields.customerPartner.setValue(((_g = this.state.issue) === null || _g === void 0 ? void 0 : _g.customerPartner) || '');
                        this.form.fields.supplierPartner.setValue(((_h = this.state.issue) === null || _h === void 0 ? void 0 : _h.supplierPartner) || '');
                        this.form.fields.comment.setValue(((_j = this.state.issue) === null || _j === void 0 ? void 0 : _j.comment) || '');
                        this.form.fields.isForeign.setValue(((_k = this.state.issue) === null || _k === void 0 ? void 0 : _k.isForeign) || '');
                        this.form.fields.legalCode.setValue(((_l = this.state.issue) === null || _l === void 0 ? void 0 : _l.legalCode) || '');
                        this.form.fields.fullName.setValue(((_o = (_m = this.state.issue) === null || _m === void 0 ? void 0 : _m.deliveryData) === null || _o === void 0 ? void 0 : _o.fullName) || '');
                        this.form.fields.email.setValue(((_q = (_p = this.state.issue) === null || _p === void 0 ? void 0 : _p.deliveryData) === null || _q === void 0 ? void 0 : _q.email) || '');
                        this.form.fields.phone.setValue(((_s = (_r = this.state.issue) === null || _r === void 0 ? void 0 : _r.deliveryData) === null || _s === void 0 ? void 0 : _s.phone) || '');
                        this.form.fields.taxNumber.setValue(((_u = (_t = this.state.issue) === null || _t === void 0 ? void 0 : _t.deliveryData) === null || _u === void 0 ? void 0 : _u.taxNumber) || '');
                        this.form.fields.heaCode.setValue(((_w = (_v = this.state.issue) === null || _v === void 0 ? void 0 : _v.deliveryData) === null || _w === void 0 ? void 0 : _w.heaCode) || '');
                        this.form.fields.city.setValue(((_y = (_x = this.state.issue) === null || _x === void 0 ? void 0 : _x.deliveryData) === null || _y === void 0 ? void 0 : _y.city) || '');
                        this.form.fields.address.setValue(((_0 = (_z = this.state.issue) === null || _z === void 0 ? void 0 : _z.deliveryData) === null || _0 === void 0 ? void 0 : _0.address) || '');
                        this.form.fields.zipCode.setValue(((_2 = (_1 = this.state.issue) === null || _1 === void 0 ? void 0 : _1.deliveryData) === null || _2 === void 0 ? void 0 : _2.zipCode) || '');
                        this.form.fields.operatingPermitNumber.setValue(((_4 = (_3 = this.state.issue) === null || _3 === void 0 ? void 0 : _3.deliveryData) === null || _4 === void 0 ? void 0 : _4.operatingPermitNumber) || '');
                        this.form.fields.purpose.setValue(((_5 = this.state.issue) === null || _5 === void 0 ? void 0 : _5.purpose) || '');
                        this.form.fields.licensePlateNumber.setValue(((_6 = this.state.issue) === null || _6 === void 0 ? void 0 : _6.licensePlateNumber) || '');
                        return [4, this.setState({ selectedCustomerPartnerId: this.state.issue.customerPartner })];
                    case 8:
                        _7.sent();
                        return [4, this.setState({ selectedSupplierPartnerId: this.state.issue.supplierPartner })];
                    case 9:
                        _7.sent();
                        _7.label = 10;
                    case 10: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onIsB2cChange = function (value) {
        this.setState({ isB2c: value });
        if (value) {
            this.form.fields.fullName.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            this.form.fields.email.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            this.form.fields.phone.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
            this.form.fields.taxNumber.setValidators([]);
            this.form.fields.operatingPermitNumber.setValidators([]);
            this.form.fields.customerPartner.removeValidators();
            this.form.fields.supplierPartner.removeValidators();
            this.form.fields.customerPartner.setValue('');
            return this.form.fields.supplierPartner.setValue('');
        }
        return this.form.fields.customerPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onReturnChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({ isReturn: value })];
                    case 1:
                        _a.sent();
                        if (value) {
                            this.form.fields.supplierPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            this.form.fields.customerPartner.removeValidators();
                            this.form.fields.customerPartner.setValue('');
                        }
                        else {
                            this.form.fields.supplierPartner.removeValidators();
                            this.form.fields.supplierPartner.setValue('');
                            this.form.fields.customerPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onThirdPartyChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({ isThirdParty: value })];
                    case 1:
                        _a.sent();
                        if (value) {
                            this.form.fields.supplierPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                            this.form.fields.customerPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        else {
                            this.form.fields.supplierPartner.removeValidators();
                            this.form.fields.supplierPartner.setValue('');
                            this.form.fields.customerPartner.removeValidators();
                            this.form.fields.customerPartner.setValue('');
                        }
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onTransactionChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({ isTransaction: value })];
                    case 1:
                        _a.sent();
                        this.form.fields.supplierPartner.removeValidators();
                        this.form.fields.customerPartner.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onCustomerPartnerChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.fields.shippingData.setValue(null)];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.taxNumber.setValue('')];
                    case 2:
                        _a.sent();
                        return [4, this.form.fields.fullName.setValue('')];
                    case 3:
                        _a.sent();
                        return [4, this.setState({ selectedCustomerPartnerId: value })];
                    case 4:
                        _a.sent();
                        return [4, this.fetchCustomerPartner(value)];
                    case 5:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onSupplierPartnerChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.fields.shippingData.setValue(null)];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.taxNumber.setValue('')];
                    case 2:
                        _a.sent();
                        return [4, this.form.fields.fullName.setValue('')];
                    case 3:
                        _a.sent();
                        return [4, this.setState({ selectedSupplierPartnerId: value })];
                    case 4:
                        _a.sent();
                        if (!this.state.isReturn) return [3, 6];
                        return [4, this.fetchSupplierPartner(value)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onDepotChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.state.isB2c) return [3, 4];
                        return [4, this.fetchWarehouses()];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.warehouse.setValue(null)];
                    case 2:
                        _a.sent();
                        return [4, this.setState({ isDepotSelected: !!value, warehouse: {} })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.fetchWarehouses = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var depotId_1, response, list, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        return [4, this.form.fields.depot.getValue()];
                    case 1:
                        depotId_1 = _c.sent();
                        if (!depotId_1) {
                            return [2, this.form.fields.warehouse.setOptions([])];
                        }
                        return [4, this.repository.get('/b2b/warehouse?hasStorage=true')];
                    case 2:
                        response = _c.sent();
                        list = ((_b = (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.depot === depotId_1; })) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
                            name: item.title,
                            value: item,
                            key: item._id,
                        }); })) || [];
                        return [4, this.form.fields.warehouse.setOptions(list)];
                    case 3:
                        _c.sent();
                        return [3, 5];
                    case 4:
                        e_1 = _c.sent();
                        console.log(e_1);
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.fetchDefaultWarehouseAndDepot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, defaultValues, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.repository.get('/b2b/stock-issuing/b2c-default-depot-and-warehouse')];
                    case 1:
                        response = _a.sent();
                        defaultValues = {
                            defaultWarehouse: (response === null || response === void 0 ? void 0 : response.warehouse) ? [response.warehouse] : null,
                            defaultDepot: (response === null || response === void 0 ? void 0 : response.depot) ? [response.depot] : null,
                        };
                        return [4, this.setState({ defaultValues: defaultValues })];
                    case 2:
                        _a.sent();
                        return [2, defaultValues];
                    case 3:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.setDefaultOptions = function (defaultValues) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var warehouseOptions, depotOptions;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        warehouseOptions = ((_a = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.defaultWarehouse) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: item.title,
                            value: item,
                            key: item._id,
                        }); })) || [];
                        return [4, this.form.fields.warehouse.setOptions(warehouseOptions)];
                    case 1:
                        _e.sent();
                        return [4, this.form.fields.warehouse.setValue((_b = warehouseOptions === null || warehouseOptions === void 0 ? void 0 : warehouseOptions[0]) === null || _b === void 0 ? void 0 : _b.value)];
                    case 2:
                        _e.sent();
                        depotOptions = ((_c = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.defaultDepot) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({
                            name: item.title,
                            value: item,
                            key: item._id,
                        }); })) || [];
                        return [4, this.form.fields.depot.setOptions(depotOptions)];
                    case 3:
                        _e.sent();
                        return [4, this.form.fields.depot.setValue((_d = depotOptions === null || depotOptions === void 0 ? void 0 : depotOptions[0]) === null || _d === void 0 ? void 0 : _d.value)];
                    case 4:
                        _e.sent();
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onWarehouseChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!value) {
                            return [2];
                        }
                        return [4, this.setState({ isWarehouseSelected: !!value, warehouse: value, taxDepot: (value === null || value === void 0 ? void 0 : value.type) === 'tax' })];
                    case 1:
                        _a.sent();
                        if ((value === null || value === void 0 ? void 0 : value.type) === 'tax') {
                            this.form.fields.legalCode.setValidators([new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]);
                        }
                        else {
                            this.form.fields.legalCode.removeValidators();
                        }
                        return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.fetchCustomerPartner = function (customerPartnerId) {
        return __awaiter(this, void 0, void 0, function () {
            var response, list, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!customerPartnerId) {
                            this.setState({ partner: null });
                            return [2, this.form.fields.supplierPartner.setOptions([])];
                        }
                        return [4, this.repository.get("/b2b/customer-partner/".concat(customerPartnerId))];
                    case 1:
                        response = _a.sent();
                        list = this.formatPartnerList(response === null || response === void 0 ? void 0 : response.shippingDatas);
                        return [4, this.form.fields.shippingData.setOptions(list)];
                    case 2:
                        _a.sent();
                        return [4, this.form.fields.taxNumber.setValue(response.taxNumber || '')];
                    case 3:
                        _a.sent();
                        return [4, this.form.fields.fullName.setValue(response.name || '')];
                    case 4:
                        _a.sent();
                        return [3, 6];
                    case 5:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.formatPartnerList = function (shippingData) {
        if (!(shippingData === null || shippingData === void 0 ? void 0 : shippingData.length)) {
            return [];
        }
        return shippingData.map(function (item) { return ({
            name: "".concat(item.zipCode, " ").concat(item.city, ", ").concat(item.address, " (").concat(item.name, ")"),
            value: item,
            key: item.name
        }); });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.fetchSupplierPartner = function (shippingId) {
        return __awaiter(this, void 0, void 0, function () {
            var response, list, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!shippingId) {
                            this.setState({ partner: null });
                            return [2, this.form.fields.supplierPartner.setOptions([])];
                        }
                        return [4, this.repository.get("/b2b/supplier-partner/".concat(shippingId))];
                    case 1:
                        response = _a.sent();
                        list = this.formatPartnerList(response === null || response === void 0 ? void 0 : response.shippingDatas);
                        return [4, this.form.fields.shippingData.setOptions(list)];
                    case 2:
                        _a.sent();
                        return [4, this.form.fields.taxNumber.setValue(response.taxNumber || '')];
                    case 3:
                        _a.sent();
                        return [4, this.form.fields.fullName.setValue(response.name || '')];
                    case 4:
                        _a.sent();
                        return [3, 6];
                    case 5:
                        e_4 = _a.sent();
                        console.log(e_4.message);
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.onShippingDataChange = function (value) {
        if ((0, lodash_1.isEmpty)(value)) {
            this.form.fields.city.setValue('');
            this.form.fields.zipCode.setValue('');
            this.form.fields.operatingPermitNumber.setValue('');
            this.form.fields.address.setValue('');
            return;
        }
        this.form.fields.city.setValue(value.city);
        this.form.fields.zipCode.setValue(value.zipCode);
        this.form.fields.operatingPermitNumber.setValue(value.operatingPermitNumber);
        this.form.fields.address.setValue(value.address);
        return;
    };
    B2bStockIssuingCreateUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        var readOnlyAndDisabled = ((_a = this.state.issue) === null || _a === void 0 ? void 0 : _a.status) === 'executed';
        return React.createElement("div", { className: 'B2bStockIssuingCreateScreen' },
            React.createElement("div", { className: 'col' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col' },
                        React.createElement("div", { className: 'mb-2' },
                            React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-issuing'); } })),
                        React.createElement("div", { className: 'mb-4' },
                            React.createElement("h2", { className: 'fw-600' },
                                ((_d = (_c = (_b = this.props) === null || _b === void 0 ? void 0 : _b.route) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.id) ? "".concat(((_e = this.state.issue) === null || _e === void 0 ? void 0 : _e.identifier) || '', " ") : '',
                                (0, trans_1.trans)('stock-issuing.create.title')),
                            ((_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.issue) === null || _g === void 0 ? void 0 : _g.b2cIdentifier) ? React.createElement("h4", { className: 'fw-600' },
                                (0, trans_1.trans)('stock-issuing.create.b2cOrder'),
                                ": ",
                                this.state.issue.b2cIdentifier) : null),
                        React.createElement(form_1.Form, null,
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isB2c, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: readOnlyAndDisabled || ((_k = (_j = (_h = this.props) === null || _h === void 0 ? void 0 : _h.route) === null || _j === void 0 ? void 0 : _j.params) === null || _k === void 0 ? void 0 : _k.id) || this.state.isReturn || this.state.isThirdParty || this.state.isTransaction, className: 'checkbox-input-basic cursor-pointer col-5' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isReturn, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: readOnlyAndDisabled || ((_o = (_m = (_l = this.props) === null || _l === void 0 ? void 0 : _l.route) === null || _m === void 0 ? void 0 : _m.params) === null || _o === void 0 ? void 0 : _o.id) || this.state.isB2c || this.state.isThirdParty || this.state.isTransaction, className: 'checkbox-input-basic cursor-pointer col-4' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isThirdParty, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: readOnlyAndDisabled || ((_r = (_q = (_p = this.props) === null || _p === void 0 ? void 0 : _p.route) === null || _q === void 0 ? void 0 : _q.params) === null || _r === void 0 ? void 0 : _r.id) || this.state.isB2c || this.state.isReturn || this.state.isTransaction, className: 'checkbox-input-basic cursor-pointer col-6' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isTransaction, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { disabled: readOnlyAndDisabled || ((_u = (_t = (_s = this.props) === null || _s === void 0 ? void 0 : _s.route) === null || _t === void 0 ? void 0 : _t.params) === null || _u === void 0 ? void 0 : _u.id) || this.state.isB2c || this.state.isReturn || this.state.isThirdParty, className: 'checkbox-input-basic cursor-pointer col-5' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.isForeign, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-4' }))),
                            (this.state.isReturn || this.state.isThirdParty) && React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.supplierPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled || ((_x = (_w = (_v = this.props) === null || _v === void 0 ? void 0 : _v.route) === null || _w === void 0 ? void 0 : _w.params) === null || _x === void 0 ? void 0 : _x.id), unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-12' }))),
                            !(this.state.isB2c || this.state.isReturn) && React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled || ((_0 = (_z = (_y = this.props) === null || _y === void 0 ? void 0 : _y.route) === null || _z === void 0 ? void 0 : _z.params) === null || _0 === void 0 ? void 0 : _0.id), unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-12' }))),
                            this.state.isB2c && React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.fullName, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.email, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.phone, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' }))),
                            !this.state.isB2c && React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.taxNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                (this.state.isB2c || this.state.taxDepot) && React.createElement("div", { className: "col-8" },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.legalCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                                        React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, readOnly: readOnlyAndDisabled, unclearable: true, multiple: false, searchable: true, className: 'select-input-basic' }))),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.heaCode, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' }))),
                            !this.state.isB2c && React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.shippingData, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled
                                            || (this.state.isReturn && !this.state.selectedSupplierPartnerId)
                                            || (this.state.isThirdParty && !this.state.selectedCustomerPartnerId)
                                            || ((!this.state.isThirdParty && !this.state.isReturn) && !this.state.selectedCustomerPartnerId), unclearable: false, multiple: false, searchable: false, className: 'select-input-basic col-12' }))),
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.zipCode, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.city, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.address, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' })),
                                !this.state.isB2c && React.createElement(form_control_1.FormControl, { field: this.form.fields.operatingPermitNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' }))),
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.purpose, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(textarea_input_1.TextareaInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, rows: 6, className: 'textarea-input-basic col-12' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(textarea_input_1.TextareaInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, rows: 6, className: 'textarea-input-basic col-12' }))),
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.licensePlateNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                    React.createElement(text_input_1.TextInput, { readOnly: readOnlyAndDisabled, disabled: readOnlyAndDisabled, className: 'text-input-basic col-8' }))),
                            React.createElement("div", { className: 'row' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.depot, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: !!this.state.issue, unclearable: false, multiple: false, className: 'select-input-basic col-12' })),
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: !!this.state.issue, unclearable: false, multiple: false, className: 'select-input-basic col-12' }))))),
                    React.createElement("div", { className: 'col save-box-wrapper' }, this.renderSaveBox()))),
            React.createElement("div", { className: 'col' },
                React.createElement(fifo_component_1.FifoComponent, { issue: this.state.issue, isWarehouseSelected: this.state.isWarehouseSelected, customerPartner: this.state.selectedCustomerPartnerId, supplierPartner: this.state.selectedSupplierPartnerId, warehouse: this.state.warehouse, isB2c: this.state.isB2c, isReturn: this.state.isReturn, isThirdParty: this.state.isThirdParty, isTransaction: this.state.isTransaction, onChange: function (value) { return _this.form.fields.plan.setValue(value); } })));
    };
    B2bStockIssuingCreateUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
        return React.createElement("div", { className: 'w-100 mt-6' },
            React.createElement("div", { className: "save-box mb-6 ".concat(!!((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id) ? 'info-box' : '', " ") },
                React.createElement("h5", { className: 'mb-4' }, !!((_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.id) ? 'STÁTUSZ' : 'LÉTREHOZÁS'),
                !!((_j = (_h = (_g = this.props) === null || _g === void 0 ? void 0 : _g.route) === null || _h === void 0 ? void 0 : _h.params) === null || _j === void 0 ? void 0 : _j.id) && [
                    React.createElement(badge_1.Badge, { key: 0, title: (0, trans_1.trans)("b2b-stock-issuing.status.badge.".concat(((_k = this.state.issue) === null || _k === void 0 ? void 0 : _k.status) || 'draft')), type: ((_l = this.state.issue) === null || _l === void 0 ? void 0 : _l.status) === 'executed' ? 'success' : 'info', className: 'mb-6' }),
                    React.createElement("div", { className: 'w-100', key: 1 },
                        React.createElement("div", { className: 'mb-4' },
                            React.createElement("p", { className: 'typo-small' }, "L\u00E9trehozva"),
                            React.createElement("p", { className: 'fw-700 ml-2' }, moment((_m = this.state.issue) === null || _m === void 0 ? void 0 : _m.createdAt).format('YYYY.MM.DD.'))),
                        React.createElement("div", { className: 'mb-4' },
                            React.createElement("p", { className: 'typo-small' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                            React.createElement("p", { className: 'fw-700 ml-2' }, moment((_o = this.state.issue) === null || _o === void 0 ? void 0 : _o.updatedAt).format('YYYY.MM.DD.'))))
                ],
                ((_p = this.state.issue) === null || _p === void 0 ? void 0 : _p.status) !== 'executed' && React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : (((_s = (_r = (_q = this.props) === null || _q === void 0 ? void 0 : _q.route) === null || _r === void 0 ? void 0 : _r.params) === null || _s === void 0 ? void 0 : _s.id) ? 'Mentés' : 'Rögzítés'), iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-info-normal button-size-normal', disabled: false, onClick: function () { return _this.submit(); } }),
                (((_v = (_u = (_t = this.props) === null || _t === void 0 ? void 0 : _t.route) === null || _u === void 0 ? void 0 : _u.params) === null || _v === void 0 ? void 0 : _v.id) && ((_w = this.state.issue) === null || _w === void 0 ? void 0 : _w.status) !== 'executed') && React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : (((_z = (_y = (_x = this.props) === null || _x === void 0 ? void 0 : _x.route) === null || _y === void 0 ? void 0 : _y.params) === null || _z === void 0 ? void 0 : _z.id) ? 'Kitárolás' : ''), iconLeft: this.state.loading ? '' : 'fal fa-save', className: 'button-primary-normal button-size-normal mt-6', disabled: false, onClick: function () { return _this.execute(); } })),
            (!!((_2 = (_1 = (_0 = this.props) === null || _0 === void 0 ? void 0 : _0.route) === null || _1 === void 0 ? void 0 : _1.params) === null || _2 === void 0 ? void 0 : _2.id) && ((_3 = this.state.issue) === null || _3 === void 0 ? void 0 : _3.status) === 'draft')
                && React.createElement("div", { className: 'save-box mb-6 success-box' },
                    React.createElement("h5", { className: 'mb-4' }, "KIT\u00C1ROL\u00C1S \u00CDV"),
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: this.state.isTakeoutSheetLoading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Kitárolás ív', iconLeft: 'far fa-download', onClick: function () { return _this.downloadTakeoutSheet(); } })),
            (!!((_6 = (_5 = (_4 = this.props) === null || _4 === void 0 ? void 0 : _4.route) === null || _5 === void 0 ? void 0 : _5.params) === null || _6 === void 0 ? void 0 : _6.id) && ((_7 = this.state.issue) === null || _7 === void 0 ? void 0 : _7.status) === 'executed')
                && React.createElement("div", { className: 'save-box mb-6 success-box' },
                    React.createElement("h5", { className: 'mb-4' }, "SZ\u00C1LL\u00CDT\u00D3 LEV\u00C9L"),
                    React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Letöltés', iconLeft: this.state.loading ? '' : 'fal fa-download', className: 'button-info-inverze button-size-normal', disabled: false, onClick: function () { var _a, _b, _c; return window.open((_c = (_b = (_a = _this.state.issue) === null || _a === void 0 ? void 0 : _a.deliveryNoteResponse) === null || _b === void 0 ? void 0 : _b.default) === null || _c === void 0 ? void 0 : _c.payload); } })),
            (!!((_10 = (_9 = (_8 = this.props) === null || _8 === void 0 ? void 0 : _8.route) === null || _9 === void 0 ? void 0 : _9.params) === null || _10 === void 0 ? void 0 : _10.id) && ((_11 = this.state.issue) === null || _11 === void 0 ? void 0 : _11.status) !== 'executed')
                && React.createElement("div", { className: 'save-box mb-6 error-box' },
                    React.createElement("h5", { className: 'mb-4' }, "T\u00D6RL\u00C9S"),
                    React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Kitárolás törlése', iconLeft: this.state.loading ? '' : 'fal fa-trash-can', className: 'button-error-normal button-size-normal', disabled: false, onClick: function () { return _this.deleteIssue(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bStockIssuingCreateUpdateScreen.prototype.downloadTakeoutSheet = function () {
        return __awaiter(this, void 0, void 0, function () {
            var takeoutSheetLink, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ isTakeoutSheetLoading: true });
                        return [4, this.repository.get("/b2b/stock/stock-issue-takeout-sheet?issue=".concat(this.state.issue._id))];
                    case 1:
                        takeoutSheetLink = _a.sent();
                        window.open(takeoutSheetLink);
                        return [3, 4];
                    case 2:
                        e_5 = _a.sent();
                        return [3, 4];
                    case 3:
                        this.setState({ isTakeoutSheetLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.submit = function (shouldThrow) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (shouldThrow === void 0) { shouldThrow = false; }
        return __awaiter(this, void 0, void 0, function () {
            var valid, form, businessType, data, issue, _h, e_6;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 6, 7, 8]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _j.sent();
                        if (!valid) {
                            throw new Error((0, trans_1.trans)('stock-reservation.form.missing.required'));
                        }
                        form = this.form.toJSON();
                        businessType = 'b2b';
                        if (form.isB2c)
                            businessType = 'b2c';
                        if (form.isReturn)
                            businessType = 'return';
                        if (form.isThirdParty)
                            businessType = 'third-party';
                        if (form.isTransaction)
                            businessType = 'transaction';
                        data = {
                            businessType: businessType,
                            plan: form.plan,
                            customerPartner: form.customerPartner,
                            supplierPartner: form.supplierPartner,
                            deliveryData: {
                                fullName: form.fullName,
                                email: form.email,
                                phone: form.phone,
                                taxNumber: form.taxNumber,
                                heaCode: form.heaCode,
                                city: form.city,
                                zipCode: form.zipCode,
                                address: form.address,
                                operatingPermitNumber: form === null || form === void 0 ? void 0 : form.operatingPermitNumber
                            },
                            purpose: form.purpose,
                            comment: form.comment,
                            isForeign: form.isForeign,
                            legalCode: form.legalCode,
                            licensePlateNumber: form.licensePlateNumber,
                            depot: form.depot,
                            warehouse: form.warehouse._id,
                        };
                        if (!!!((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id)) return [3, 3];
                        return [4, repository_1.Repository.use('default').put("/b2b/stock-issuing/".concat((_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.id), { data: data })];
                    case 2:
                        _h = _j.sent();
                        return [3, 5];
                    case 3: return [4, repository_1.Repository.use('default').post('/b2b/stock-issuing', { data: data })];
                    case 4:
                        _h = _j.sent();
                        _j.label = 5;
                    case 5:
                        issue = _h;
                        if (!shouldThrow) {
                            router_provider_1.RouterProvider.goTo("/stock-issuing/".concat(issue._id));
                        }
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-issuing.toast.success.create-update'));
                        return [3, 8];
                    case 6:
                        e_6 = _j.sent();
                        handle_toasts_1.HandleToasts.error(e_6, (_g = e_6 === null || e_6 === void 0 ? void 0 : e_6.message) !== null && _g !== void 0 ? _g : 'Sikertelen művelet');
                        if (shouldThrow) {
                            throw e_6;
                        }
                        return [3, 8];
                    case 7:
                        this.setState({ loading: false });
                        return [7];
                    case 8: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.execute = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var e_7, e_8;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        return [4, this.submit(true)];
                    case 1:
                        _d.sent();
                        return [3, 3];
                    case 2:
                        e_7 = _d.sent();
                        return [2];
                    case 3:
                        _d.trys.push([3, 5, 6, 7]);
                        this.setState({ loading: true });
                        return [4, repository_1.Repository.use('default').put("/b2b/stock-issuing/".concat((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id, "/execute"))];
                    case 4:
                        _d.sent();
                        router_provider_1.RouterProvider.goTo('/stock-issuing');
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-issuing.toast.success.execute'));
                        return [3, 7];
                    case 5:
                        e_8 = _d.sent();
                        handle_toasts_1.HandleToasts.error(e_8, e_8 === null || e_8 === void 0 ? void 0 : e_8.message);
                        return [3, 7];
                    case 6:
                        this.setState({ loading: false });
                        return [7];
                    case 7: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.download = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var issue, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4, repository_1.Repository.use('default')
                                .get("/b2b/stock-issuing/".concat(id))];
                    case 1:
                        issue = _a.sent();
                        this.setState({
                            issue: issue,
                            isB2c: ((issue === null || issue === void 0 ? void 0 : issue.businessType) === 'b2c'),
                            isReturn: ((issue === null || issue === void 0 ? void 0 : issue.businessType) === 'return'),
                            warehouse: issue.warehouse,
                        });
                        return [3, 4];
                    case 2:
                        e_9 = _a.sent();
                        handle_toasts_1.HandleToasts.error(e_9, (0, trans_1.trans)('stock-issuing.toast.success.UnableToDownloadIssuing'));
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockIssuingCreateUpdateScreen.prototype.deleteIssue = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var e_10;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, 3, 4]);
                        return [4, repository_1.Repository.use('default').delete("/b2b/stock-issuing/".concat((_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.id))];
                    case 1:
                        _d.sent();
                        router_provider_1.RouterProvider.goTo('/stock-issuing');
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('stock-issuing.toast.success.delete'));
                        return [3, 4];
                    case 2:
                        e_10 = _d.sent();
                        handle_toasts_1.HandleToasts.error(e_10, e_10 === null || e_10 === void 0 ? void 0 : e_10.message);
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    return B2bStockIssuingCreateUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockIssuingCreateUpdateScreen = B2bStockIssuingCreateUpdateScreen;
