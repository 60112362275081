"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@codebuild/glaze/libs/environment");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var React = require("react");
var ReactDOM = require("react-dom");
var react_toastify_1 = require("react-toastify");
var get_access_token_1 = require("./common/libs/authentication/get-access-token");
require("./styles/index.scss");
var root$ = document.getElementById('root');
repository_1.Repository.register('default', {
    baseURL: environment_1.Environment.get('api'),
    headers: { Authorization: (0, get_access_token_1.getAccessToken)() }
});
repository_1.Repository.register('attachment', {
    baseURL: environment_1.Environment.get('attachment')
});
var router = React.createElement("div", null,
    React.createElement(router_provider_1.RouterProvider, { routes: require('./routes/routes') }),
    React.createElement(react_toastify_1.ToastContainer, { key: 'toastcontainer', autoClose: 5000, bodyClassName: 'flex-fill', position: 'bottom-right' }));
ReactDOM.render(router, root$);
