"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderBadge = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var React = require("react");
require("./order-badge.scss");
var OrderBadge = (function (_super) {
    __extends(OrderBadge, _super);
    function OrderBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrderBadge.prototype.render = function () {
        var status = this.props.status;
        if (!status) {
            return null;
        }
        switch (status) {
            case 'draft':
                return this.renderBadge(status, 'neutral');
            case 'financeApprovalNeeded':
                return this.renderBadge(status, 'purple-light');
            case 'financeApproved':
                return this.renderBadge(status, 'brown');
            case 'stockLocked':
                return this.renderBadge(status, 'peach');
            case 'casePicked':
                return this.renderBadge(status, 'purple-dark');
            case 'deliveryNoteIssued':
                return this.renderBadge(status, 'blue');
            case 'factDeliveryDetermined':
                return this.renderBadge(status, 'blue-dark');
            case 'paid':
                return this.renderBadge(status, 'green');
            case 'canceled':
                return this.renderBadge(status, 'red');
            default:
                return this.renderBadge(status, 'neutral');
        }
    };
    OrderBadge.prototype.renderBadge = function (status, colour) {
        return React.createElement("div", { className: "badge ".concat(colour) }, (0, trans_1.trans)("b2b-order.status.".concat(status)));
    };
    return OrderBadge;
}(abstract_component_1.AbstractComponent));
exports.OrderBadge = OrderBadge;
