module.exports = {
    'stocks.list.title': 'Jelenlegi készlet',
    'stocks.list.income.title': 'Bevételezés',
    'stocks.list.outcome.title': 'Kiadás',
    'stocks.table.header.sku': 'SKU',
    'stocks.table.header.vintage': 'Évjárat',
    'stocks.table.header.title': 'Név',
    'stocks.table.header.manufacturer': 'Pincészet',
    'stocks.table.header.amount': 'Aktuális mennyiség',
    'stocks.table.header.isProductActive': 'Aktiv termék',
    'stocks.table.header.updatedAt': 'Módositva',
    'stocks.table.header.createdAt': 'Létrehozva',
    'stocks-show.table.header.type': 'Típus',
    'stocks-show.table.header.amount': 'Mennyiség',
    'stocks-show.table.header.reason': 'Megjegyzés',
    'stocks-show.table.header.creator': 'Létrehozó',
    'stocks-show.table.header.createdAt': 'Létrehozva',
    'stock-sites.list.title': 'Telephelyek',
    'stock-sites.table.header.title': 'Telephely neve',
    'stock-sites.list.income.title': 'Új létrehozása',
    'stock-storages.list.title': 'Raktár',
    'stock-storages.table.header.title': 'Raktár neve',
    'stock-storages.list.income.title': 'Új létrehozása',
    'stocks-history.list.title': 'Készletmozgás',
    'stocks-history.table.header.type': 'Irány',
    'stocks-history.table.header.name': 'Termék neve',
    'stocks-history.table.header.vintage': 'Évjárat',
    'stocks-history.table.header.creator': 'Létrehozó',
    'stocks-history.table.header.createdAt': 'Létrehozva',
    'stocks-history.table.header.amount': 'Változás',
    'stocks-history.table.header.reason': 'Megjegyzés',
    'stocks-history.table.header.sku': 'SKU'
};
