"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrizesForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var lodash_1 = require("lodash");
var React = require("react");
var multi_language_input_1 = require("../../../common/components/multi-language-input");
var form_1 = require("../../../components/form/form");
var PrizesForm = (function (_super) {
    __extends(PrizesForm, _super);
    function PrizesForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.prizesForm = new form_builder_1.FormBuilder({
            fields: {}
        });
        return _this;
    }
    PrizesForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.initPrizeFields();
        }
    };
    PrizesForm.prototype.initPrizeFields = function () {
        return __awaiter(this, void 0, void 0, function () {
            var prizes, prizes_1, prizes_1_1, p, key, e_1_1;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        prizes = this.props.values || [];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        prizes_1 = __values(prizes), prizes_1_1 = prizes_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!prizes_1_1.done) return [3, 5];
                        p = prizes_1_1.value;
                        key = (0, random_id_1.randomId)();
                        return [4, this.prizesForm.field(key, new field_1.Field({
                                id: key,
                                placeholder: '',
                                value: p,
                                name: key,
                                label: (0, trans_1.trans)('winery-prize.form.field.description.label'),
                                validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                            }))];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        prizes_1_1 = prizes_1.next();
                        return [3, 2];
                    case 5: return [3, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 8];
                    case 7:
                        try {
                            if (prizes_1_1 && !prizes_1_1.done && (_a = prizes_1.return)) _a.call(prizes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 8:
                        this.forceUpdate();
                        return [2];
                }
            });
        });
    };
    PrizesForm.prototype.addPrizeField = function () {
        return __awaiter(this, void 0, void 0, function () {
            var key;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = (0, random_id_1.randomId)();
                        return [4, this.prizesForm.field(key, new field_1.Field({
                                id: key,
                                placeholder: '',
                                value: '',
                                name: key,
                                label: (0, trans_1.trans)('winery-prize.form.field.description.label'),
                                validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                            }))];
                    case 1:
                        _a.sent();
                        this.forceUpdate();
                        return [2];
                }
            });
        });
    };
    PrizesForm.prototype.render = function () {
        return React.createElement("div", null, this.renderPrizeForm());
    };
    PrizesForm.prototype.renderPrizeForm = function () {
        var _this = this;
        return React.createElement(form_1.Form, { className: 'row' },
            React.createElement("div", { className: 'col-12' },
                React.createElement("div", null, (0, lodash_1.map)(this.prizesForm.fields, function (f, k) {
                    return React.createElement("div", { className: 'display-flex flex-row', key: k },
                        React.createElement("div", { className: 'flex-fill' },
                            React.createElement(form_control_1.FormControl, { field: f, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: _this.props.editorLanguage, className: 'text-input-basic' }))),
                        React.createElement("div", null,
                            React.createElement(button_1.Button, { className: 'button-primary-link button-size-normal', iconLeft: 'fal fa-trash', onClick: function () { return _this.removePrize(f, k); } })));
                })),
                React.createElement("div", { className: "display-flex align-items-center-justify-content-center w-100" },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: (0, trans_1.trans)('product.update.prize.form.add-new.button.title'), onClick: function () { return _this.addPrizeField(); } }))));
    };
    PrizesForm.prototype.removePrize = function (field, key) {
        return __awaiter(this, void 0, void 0, function () {
            var fields, fields_1, fields_1_1, f, e_2_1;
            var e_2, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        fields = (0, lodash_1.filter)(this.prizesForm.fields, function (f, k) { return k !== key; });
                        this.prizesForm.fields = {};
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        fields_1 = __values(fields), fields_1_1 = fields_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!fields_1_1.done) return [3, 5];
                        f = fields_1_1.value;
                        return [4, this.prizesForm.field(f.getId(), f)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        fields_1_1 = fields_1.next();
                        return [3, 2];
                    case 5: return [3, 8];
                    case 6:
                        e_2_1 = _b.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 8];
                    case 7:
                        try {
                            if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 8:
                        this.forceUpdate();
                        return [2];
                }
            });
        });
    };
    PrizesForm.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var prizesErrors, hasError, prizes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.prizesForm.validate()];
                    case 1:
                        _a.sent();
                        return [4, this.prizesForm.getErrors()];
                    case 2:
                        prizesErrors = _a.sent();
                        hasError = false;
                        (0, lodash_1.map)(__assign({}, prizesErrors), function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        prizes = (0, lodash_1.map)(this.prizesForm.toJSON(), function (p) { return (p); });
                        return [2, {
                                values: prizes
                            }];
                }
            });
        });
    };
    return PrizesForm;
}(abstract_component_1.AbstractComponent));
exports.PrizesForm = PrizesForm;
