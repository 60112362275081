"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockTransactionShowTableBody = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
require("../b2b-stock-transaction.scss");
var StockTransactionShowTableBody = (function (_super) {
    __extends(StockTransactionShowTableBody, _super);
    function StockTransactionShowTableBody() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            rows: {},
            openedRows: [],
        };
        return _this;
    }
    StockTransactionShowTableBody.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var rows;
            return __generator(this, function (_a) {
                if (!(0, lodash_1.isEqual)(this.props.items, prevProps.items)) {
                    rows = this.getRows(this.props.items);
                    this.setState({ rows: rows, });
                    this.setState({
                        openedRows: __spreadArray([], __read(this.state.openedRows), false)
                    });
                }
                return [2];
            });
        });
    };
    StockTransactionShowTableBody.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        if (!((_a = this.state.rows.data) === null || _a === void 0 ? void 0 : _a.length)) {
            return React.createElement("div", null);
        }
        return (_c = (_b = this.state.rows) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map(function (item, index) {
            return _this.renderRow(item, index);
        });
    };
    StockTransactionShowTableBody.prototype.renderRow = function (item, key) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var ean = ((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.eanCode) || '-';
        var sku = ((_b = item === null || item === void 0 ? void 0 : item.product) === null || _b === void 0 ? void 0 : _b.sku) || '-';
        var manufacturer = ((_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.manufacturer) || '-';
        var title = ((_d = item === null || item === void 0 ? void 0 : item.product) === null || _d === void 0 ? void 0 : _d.title) || '-';
        var vintage = ((_e = item === null || item === void 0 ? void 0 : item.product) === null || _e === void 0 ? void 0 : _e.vintage) || '-';
        var capacity = ((_f = item === null || item === void 0 ? void 0 : item.product) === null || _f === void 0 ? void 0 : _f.capacity) || '-';
        var fromStorage = ((_g = item === null || item === void 0 ? void 0 : item.fromStorage) === null || _g === void 0 ? void 0 : _g.map(function (s) { return s.title; }).join('/')) || '-';
        var toStorage = ((_h = item === null || item === void 0 ? void 0 : item.toStorage) === null || _h === void 0 ? void 0 : _h.map(function (s) { return s.title; }).join('/')) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper main-wrapper', key: key },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                React.createElement(table_body_row_1.TableBodyRow, { key: item.product.productId, className: 'cursor-pointer align-items-center content', onClick: function () { return _this.handleRowClick(item.product.productId); } },
                    React.createElement("div", { className: 'chevron-container' },
                        React.createElement("i", { className: "fal ".concat(!((_j = this.state.openedRows) === null || _j === void 0 ? void 0 : _j.includes(item.product.productId)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 27 },
                        React.createElement("div", null,
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                React.createElement("span", null, ean)),
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                React.createElement("span", null, sku)),
                            React.createElement("p", null,
                                manufacturer,
                                " ",
                                title,
                                " ",
                                vintage,
                                " (",
                                capacity,
                                ")"))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 35 },
                        React.createElement("p", null, fromStorage)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 35 },
                        React.createElement("p", null, toStorage)))),
            !!((_k = item.rowItems) === null || _k === void 0 ? void 0 : _k.length)
                && React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: "".concat(!((_l = this.state.openedRows) === null || _l === void 0 ? void 0 : _l.includes(item.product.productId)) ? 'opened' : 'closed') },
                    React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'flex-column wrapper' },
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                            React.createElement("div", { style: { width: 32 } }),
                            React.createElement(table_header_row_1.TableHeaderRow, { className: 'inner-table-header' },
                                React.createElement("div", { style: { width: 32 } }),
                                React.createElement(table_header_cell_1.TableHeaderCell, { width: 80, title: 'LOT/NÉBIH', property: 'lot', className: 'pr-3' }),
                                React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, title: 'MENNYISÉG', property: 'amount', className: 'justify-content-end' }))), (_m = item.rowItems) === null || _m === void 0 ? void 0 :
                        _m.map(function (lot) {
                            var _a, _b;
                            return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: lot.lot },
                                React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper' },
                                    React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                                        React.createElement("div", { style: { width: 32 } }),
                                        React.createElement(table_body_row_1.TableBodyRow, { className: 'align-items-center content', onClick: function () { return _this.handleRowClick("".concat(item.product.productId, " - ").concat(lot.lot)); } },
                                            React.createElement("div", { className: 'chevron-container' },
                                                React.createElement("i", { className: "fal ".concat(!((_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat(item.product.productId, " - ").concat(lot.lot))) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                                            React.createElement(table_body_cell_1.TableBodyCell, { width: 80, className: 'pr-3' },
                                                React.createElement("p", null, lot.lot)),
                                            React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: 'justify-content-end' },
                                                React.createElement("p", null,
                                                    "\u2211 ",
                                                    lot.sum,
                                                    " db")))), (_b = lot.lotItems) === null || _b === void 0 ? void 0 :
                                    _b.map(function (type, index) {
                                        var _a, _b, _c;
                                        return (React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: type.type + index, className: "".concat(!((_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat(item.product.productId, " - ").concat(lot.lot))) ? 'opened' : 'closed') },
                                            React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'flex-column wrapper' },
                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                                                    React.createElement("div", { style: { width: 64 } }),
                                                    React.createElement(table_body_row_1.TableBodyRow, { className: "".concat(type.type, " align-items-center content"), onClick: function () { return _this.handleRowClick("".concat(item.product.productId, " - ").concat(lot.lot, " - ").concat(type.type)); } },
                                                        type.type !== 'move-available'
                                                            ? React.createElement("div", { className: 'chevron-container' },
                                                                React.createElement("i", { className: "fal ".concat(!((_b = _this.state.openedRows) === null || _b === void 0 ? void 0 : _b.includes("".concat(item.product.productId, " - ").concat(lot.lot, " - ").concat(type.type))) ? 'fa-chevron-up' : 'fa-chevron-down') }))
                                                            : React.createElement("div", { style: { width: 32 } }),
                                                        React.createElement(table_body_cell_1.TableBodyCell, { width: 80, className: 'pr-3' },
                                                            React.createElement("p", null, _this.getTypeTitle(type === null || type === void 0 ? void 0 : type.type))),
                                                        React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: 'justify-content-end' }, type.type !== 'move-available'
                                                            ? React.createElement("p", null,
                                                                "\u2211 ", type === null || type === void 0 ? void 0 :
                                                                type.sum,
                                                                " db")
                                                            : React.createElement("p", null, type === null || type === void 0 ? void 0 :
                                                                type.sum,
                                                                " db")))),
                                                type.type !== 'move-available'
                                                    && ((_c = type.typeItems) === null || _c === void 0 ? void 0 : _c.map(function (t, i) {
                                                        var _a;
                                                        return React.createElement(table_body_cell_1.TableBodyCell, { key: i, width: 100, className: "".concat(!((_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat(item.product.productId, " - ").concat(lot.lot, " - ").concat(type.type))) ? 'opened' : 'closed') },
                                                            React.createElement("div", { style: { width: 96 } }),
                                                            React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'content' },
                                                                React.createElement("div", { style: { width: 32 } }),
                                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 80, className: 'pr-3' },
                                                                    React.createElement("p", null, _this.getRef(t.action, t.__meta))),
                                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 20, className: 'justify-content-end' },
                                                                    React.createElement("p", null,
                                                                        t.__meta.amount || '-',
                                                                        " db"))));
                                                    })))));
                                    })));
                        }))));
    };
    StockTransactionShowTableBody.prototype.getRows = function (raw) {
        var _a;
        var data = (0, lodash_1.cloneDeep)(raw);
        var sum = 0;
        data = (_a = data === null || data === void 0 ? void 0 : data.sort(function (a, b) { return a.type - b.type; })) === null || _a === void 0 ? void 0 : _a.reduce(function (result, i) {
            var _a, _b, _c, _d, _e, _f;
            var rowIndex = result.indexOf(result.find(function (r) {
                return r.product.productId === i.__meta.product.productId
                    && (0, lodash_1.isEqual)(r.fromStorage, i.__meta.fromStoragePath)
                    && (0, lodash_1.isEqual)(r.toStorage, i.__meta.toStoragePath);
            }));
            var rowPosition = rowIndex < 0 ? result.length : rowIndex;
            var row = result[rowPosition] = result[rowPosition]
                || {
                    product: i.__meta.product,
                    fromStorage: i.__meta.fromStoragePath,
                    toStorage: i.__meta.toStoragePath,
                    rowItems: [],
                    sum: 0
                };
            var lotIndex = (_a = row.rowItems) === null || _a === void 0 ? void 0 : _a.indexOf((_b = row.rowItems) === null || _b === void 0 ? void 0 : _b.find(function (l) { return l.lot === i.__meta.lot; }));
            var lotPosition = lotIndex < 0 ? row.rowItems.length : lotIndex;
            var lot = row.rowItems[lotPosition] = row.rowItems[lotPosition] || { lot: i.__meta.lot, lotItems: [], sum: 0 };
            var typeIndex = (_c = lot.lotItems) === null || _c === void 0 ? void 0 : _c.indexOf((_d = lot.lotItems) === null || _d === void 0 ? void 0 : _d.find(function (t) { return t.type === i.action; }));
            var typePosition = typeIndex < 0 ? (_e = lot.lotItems) === null || _e === void 0 ? void 0 : _e.length : typeIndex;
            var type = lot.lotItems[typePosition] = lot.lotItems[typePosition] || { type: i.action, typeItems: [], sum: 0 };
            (_f = type.typeItems) === null || _f === void 0 ? void 0 : _f.push(i);
            type.sum += i.__meta.amount;
            lot.sum += i.__meta.amount;
            row.sum += i.__meta.amount;
            sum += i.__meta.amount;
            return result;
        }, []);
        return { data: data, sum: sum };
    };
    StockTransactionShowTableBody.prototype.handleRowClick = function (rowId) {
        if (this.state.openedRows.includes(rowId)) {
            var newOpened = (0, lodash_1.cloneDeep)(this.state.openedRows).filter(function (row) { return row !== rowId; });
            return this.setState({ openedRows: newOpened });
        }
        this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [rowId], false) });
    };
    StockTransactionShowTableBody.prototype.getRef = function (type, meta) {
        var _a, _b, _c, _d;
        switch (type) {
            case 'move-cp-reservation-combined':
                return (_a = meta.owners) === null || _a === void 0 ? void 0 : _a.join(', ');
            case 'move-ch-reservation-combined':
                return ((_b = meta.channels) === null || _b === void 0 ? void 0 : _b.join(', ')) || '---';
            case 'move-order-lock-combined':
                return (_c = meta.order) === null || _c === void 0 ? void 0 : _c.id;
            case 'move-issue-lock-combined':
                return (_d = meta.issue) === null || _d === void 0 ? void 0 : _d.identifier;
            default:
                return 'Szabad készlet';
        }
    };
    StockTransactionShowTableBody.prototype.getTypeTitle = function (type) {
        switch (type) {
            case 'move-cp-reservation-combined':
                return 'Vevő cég foglalás';
            case 'move-ch-reservation-combined':
                return 'Csatorna foglalás';
            case 'move-order-lock-combined':
                return 'Megrendelés zárolás';
            case 'move-issue-lock-combined':
                return 'Kitárolás zárolás';
            default:
                return 'Szabad készlet';
        }
    };
    return StockTransactionShowTableBody;
}(abstract_component_1.AbstractComponent));
exports.StockTransactionShowTableBody = StockTransactionShowTableBody;
