module.exports = {
    'brewery.list.title': 'Főzdék',
    'brewery.list.button.create.title': 'Főzde hozzáadása',
    'brewery-update.save-box.last-modified.label': 'Módosítás ideje',
    'brewery-create.screen.title': 'Főzde létrehozása',
    'brewery-update.screen.title': 'Főzde szerkesztése',
    'brewery-update.subtitle.basic-data': 'Alap adatok',
    'brewery-create.subtitle.basic-data': 'Alap adatok',
    'brewery.form.commissioner': 'Bizományos beszállító',
    'brewery.form.name': 'Főzde neve',
    'brewery.form.huTaxNumber': 'Adószám',
    'brewery.form.euTaxNumber': 'EU Adószám',
    'brewery.form.heaCode': 'HÉA azonosítószám',
    'brewery.form.country': 'Ország',
    'brewery.form.region': 'Régió',
    'brewery.form.location': 'Lokáció',
    'brewery.form.description': 'Leírás',
    'brewery.form.mozaikManufacturers': 'Gyártó cég',
    'brewery.form.sortiment': 'Kínálat',
    'brewery.form.brewer': 'Sörfőző mester',
    'brewery.form.vineyard': 'Dűlő',
    'brewery.form.soil': 'Talaj',
    'brewery.form.technology': 'Borkészítés',
    'brewery-prize.form.field.description.label': 'Díj neve',
    'brewery-create.success.toast': 'A főzde létrehozása sikeresen megtörtént',
    'brewery-update.success.toast': 'A főzde módosítása sikeresen megtörtént',
};
