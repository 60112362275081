"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierStockReportNewScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var supplier_select_component_1 = require("../../common/components/list-search/supplier-select.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var report_download_1 = require("../../components/report-download");
var SupplierStockReportNewScreen = (function (_super) {
    __extends(SupplierStockReportNewScreen, _super);
    function SupplierStockReportNewScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 10,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : { from: moment().add(-1, 'month').toDate(), to: moment().toDate() }
            },
        });
        _this.state = {
            total: 0,
            csvLoading: false,
            isFilterOpen: true,
            openedRows: []
        };
        return _this;
    }
    SupplierStockReportNewScreen.prototype.componentDidMount = function () {
        this.control.set('to', (this.control.getQuery().to || moment().toDate()));
        this.control.set('from', (this.control.getQuery().from || moment().add(-1, 'month').toDate()));
    };
    SupplierStockReportNewScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('supplier-stock-report.list.title')))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/supplier-stock-report-new/download', control: this.control })))),
            React.createElement("div", { className: 'row mt-4 mb-7' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'list-filter-collapsible-wrapper' },
                        React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                            React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                            React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                                React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                        this.state.isFilterOpen && React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-8" },
                                React.createElement(supplier_select_component_1.SupplierSelectComponent, { label: (0, trans_1.trans)('order-sales-report.filter.suppliers'), property: 'supplier', "control$": this.control })),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').toDate(), "control$": this.control })))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: 'admin/supplier-stock-report-new', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    SupplierStockReportNewScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return React.createElement("div", null,
                    ctx.renderItems(ctx),
                    _this.renderSummaryRow(ctx)); } }));
    };
    SupplierStockReportNewScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('title.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('allStock'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('allBillableStock'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('billable'))));
    };
    SupplierStockReportNewScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: false,
            title: (0, trans_1.trans)("supplier-stock-report.list.table.header.".concat(p)),
            property: p
        };
    };
    SupplierStockReportNewScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    SupplierStockReportNewScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    SupplierStockReportNewScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    SupplierStockReportNewScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: 'cursor-pointer', onClick: function () { return _this.toggleRow(item._id); } },
                React.createElement("div", { className: 'chevron-container' },
                    React.createElement("i", { className: "fal ".concat(this.state.openedRows.includes("".concat(item._id)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'border-bottom-0' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                        React.createElement("div", null,
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.eanCode)),
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
                            React.createElement("p", null, (_b = (_a = item === null || item === void 0 ? void 0 : item.manufacturer) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 :
                                _b.hu,
                                " ", (_c = item === null || item === void 0 ? void 0 : item.title) === null || _c === void 0 ? void 0 :
                                _c.hu,
                                " ",
                                (item === null || item === void 0 ? void 0 : item.vintage) || '-',
                                " (",
                                (item === null || item === void 0 ? void 0 : item.capacity) || '-',
                                ")"))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyStock'),
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyBillableStock'),
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, currency_parser_1.hufFormat)(((0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyBillableStock') * 10)),
                            " ")))),
            this.isOpened(item._id) && React.createElement(table_body_cell_1.TableBodyCell, { width: 100 }, this.renderStockDays(item)));
    };
    SupplierStockReportNewScreen.prototype.renderStockDays = function (item) {
        var month = (0, lodash_1.groupBy)(item.stockDays, (function (d) { return moment(d.day).format('YYYY-MM'); }));
        return React.createElement("div", null, (Object.keys(month)).map(function (k) { return (React.createElement("div", { key: k },
            React.createElement("strong", null, k),
            React.createElement("div", { className: 'display-flex flex-wrap' }, month[k].map(function (d) { return (React.createElement("div", { className: 'p-4', key: d.day },
                React.createElement("div", null,
                    React.createElement("strong", null,
                        moment(d.day).format('DD'),
                        ".")),
                React.createElement("div", null,
                    "\u03A3: ",
                    d.dailyStock,
                    " db"),
                React.createElement("div", { className: 'color--success-6' },
                    "%: ",
                    d.dailyBillableStock,
                    " db"))); })))); }));
    };
    SupplierStockReportNewScreen.prototype.renderSummaryRow = function (ctx) {
        var e_1, _a, e_2, _b;
        var _this = this;
        var _c;
        var id = 'stock-summary-row';
        var items = (_c = ctx === null || ctx === void 0 ? void 0 : ctx.state) === null || _c === void 0 ? void 0 : _c.items;
        var item = { stockDays: [] };
        var stockDays = [];
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var i = items_1_1.value;
                stockDays.push.apply(stockDays, __spreadArray([], __read(i.stockDays), false));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var tempGroup = (0, lodash_1.groupBy)(stockDays, function (s) { return s.day; });
        try {
            for (var _d = __values(Object.keys(tempGroup)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var d = _e.value;
                item.stockDays.push({
                    day: d,
                    dailyStock: (0, lodash_1.sumBy)(tempGroup[d], function (s) { return s.dailyStock; }),
                    dailyBillableStock: (0, lodash_1.sumBy)(tempGroup[d], function (s) { return s.dailyBillableStock; }),
                });
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper w-100' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: 'cursor-pointer w-100', onClick: function () { return _this.toggleRow(id); } },
                React.createElement("div", { className: 'chevron-container' },
                    React.createElement("i", { className: "fal ".concat(this.state.openedRows.includes("".concat(id)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                React.createElement(table_body_row_1.TableBodyRow, { key: id, className: 'border-bottom-0' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                        React.createElement("strong", null, "\u00D6sszesen: ")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyStock'),
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyBillableStock'),
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null,
                            (0, currency_parser_1.hufFormat)(((0, lodash_1.sumBy)(item === null || item === void 0 ? void 0 : item.stockDays, 'dailyBillableStock') * 10)),
                            " ")))),
            this.isOpened(id) && React.createElement(table_body_cell_1.TableBodyCell, { width: 100 }, this.renderStockDays(item)));
    };
    SupplierStockReportNewScreen.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    SupplierStockReportNewScreen.prototype.isOpened = function (id) {
        return this.state.openedRows.includes(id);
    };
    SupplierStockReportNewScreen.prototype.toggleRow = function (id) {
        if (this.state.openedRows.includes(id)) {
            return this.setState({ openedRows: this.state.openedRows.filter(function (r) { return r !== id; }) });
        }
        return this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [id], false) });
    };
    return SupplierStockReportNewScreen;
}(abstract_component_1.AbstractComponent));
exports.SupplierStockReportNewScreen = SupplierStockReportNewScreen;
