module.exports = {
    'inventory.list.title': 'Raktár területek',
    'inventory.title': 'Raktár terület',
    'inventory.list.button-create.title': 'Új raktár terület létrehozása',
    'inventory.update.save-box.button.enable-edit': 'Raktár terület szerkesztése',
    'inventory.update.save-box.button.save': 'Mentés',
    'inventory.table.header.identifier': 'Azonosító',
    'inventory.table.header.title': 'Elnevezés',
    'inventory.table.header.path': 'Útvonal',
    'inventory.table.header.lastOrder': 'Utolsó rendelés',
    'inventory.table.header.createdAt': 'Létrehozva',
    'inventory.status.active': 'Aktív',
    'inventory.status.inactive': 'Inaktív',
    'inventory.create.save-box.button.save': 'Létrehozás',
    'inventory.update.subtitle.basic-data.company-data': 'Adatok',
    'inventory.update.subtitle.basic-data.address': 'Cím',
    'inventory.update.subtitle.basic-data.other': 'Egyéb adatok',
    'inventory.basic-data.form.field.title.label': 'Elnevezés',
    'inventory.basic-data.form.field.description.label': 'Megjegyzés',
    'inventory.basic-data.form.field.parentEntity.label': 'Útvonal',
    'inventory.basic-data.form.field.identifier.label': 'Azonosító',
    'inventory.basic-data.form.field.depot.label': 'Telephely',
    'inventory.basic-data.form.field.zipCode.label': 'Irányítószám',
    'inventory.basic-data.form.field.country.label': 'Ország',
    'inventory.basic-data.form.field.city.label': 'Város',
    'inventory.basic-data.form.field.address.label': 'Cím',
    'inventory.basic-data.form.field.comment.label': 'Megjegyzés',
    'inventory.update.success.toast': 'Sikeresen módosítás',
    'inventory.create.success.toast': 'Sikeresen létrehozás',
    'inventory.status-update.success.toast': 'Sikeresen státusz módosítás',
};
