"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceShowModal = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var list_loader_1 = require("../../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var permissions_1 = require("../../../common/libs/permissions/permissions");
var user_permission_1 = require("../../../common/libs/user-permission");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var modal_1 = require("../../../components/modal/modal");
var user_list_screen_1 = require("../../user/user-list.screen");
var add_base_price_1 = require("./add-base-price");
var add_sale_price_1 = require("./add-sale-price");
var PriceShowModal = (function (_super) {
    __extends(PriceShowModal, _super);
    function PriceShowModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            item: null,
            history: [],
            loading: false
        };
        _this.basesControl = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 1000,
                sort: { validFrom: 1 }
            }
        });
        _this.salesControl = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 1000,
                sort: { validFrom: 1 }
            }
        });
        return _this;
    }
    PriceShowModal.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.fetch();
                return [2];
            });
        });
    };
    PriceShowModal.prototype.fetch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        _a = this.setState;
                        _b = {};
                        return [4, this.repository.get("/admin/price/".concat(this.props.sku, "/by-sku"))];
                    case 1:
                        _a.apply(this, [(_b.item = _c.sent(),
                                _b)]);
                        this.setState({ loading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error(e_1, 'Sikertelen muvelet');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    PriceShowModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "w-100" },
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$addBasePrice = ref); }, renderModalHeader: function () {
                    return React.createElement("h3", null, "\u00C1r l\u00E9trehoz\u00E1sa");
                }, renderModalContent: function (ctx) { return React.createElement(add_base_price_1.AddBasePrice, { sku: ctx.state.args, onCreateSuccess: function () {
                        _this.$listBase.reload();
                        _this.$addBasePrice.close();
                    } }); } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$addSalePrice = ref); }, renderModalHeader: function () {
                    return React.createElement("h3", null, "Akci\u00F3 l\u00E9trehoz\u00E1sa");
                }, renderModalContent: function (ctx) { return React.createElement(add_sale_price_1.AddSalePrice, { sku: ctx.state.args, onCreateSuccess: function () {
                        _this.$listSale.reload();
                        _this.$addSalePrice.close();
                    } }); } }),
            React.createElement("h3", null, "Aj\u00E1nlott fogyaszt\u00F3i \u00E1rak"),
            this.enableEdit() && React.createElement(button_1.Button, { onClick: function () { return _this.$addBasePrice.open(_this.props.sku); }, title: "\u00DAj hozz\u00E1ad\u00E1sa", iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' }),
            React.createElement("br", null),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return (_this.$listBase = ref); }, control: this.basesControl, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/admin/price/".concat(this.props.sku, "/list-bases"), renderItemsWrapper: function (ctx) { return _this.renderBasesTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderBasesItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement("h3", null, "-")); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement("br", null),
            React.createElement("h3", null, "Aj\u00E1nlott akci\u00F3s fogyaszt\u00F3i \u00E1rak"),
            this.enableEdit() && React.createElement(button_1.Button, { onClick: function () { return _this.$addSalePrice.open(_this.props.sku); }, title: "\u00DAj hozz\u00E1ad\u00E1sa", iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' }),
            React.createElement("br", null),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return (_this.$listSale = ref); }, control: this.salesControl, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/admin/price/".concat(this.props.sku, "/list-sales"), renderItemsWrapper: function (ctx) { return _this.renderSalesTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderSalesItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement("h3", null, "-")); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    PriceShowModal.prototype.renderBasesTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderBasesTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    PriceShowModal.prototype.renderSalesTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderSalesTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    PriceShowModal.prototype.setBasesHeaderCell = function (p) {
        var _this = this;
        var _a;
        return {
            sortValue: ((_a = this.basesControl.data.sort) === null || _a === void 0 ? void 0 : _a[p]) || 0,
            onSort: function (property, value) { return _this.basesControl.sort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("price-show-bases.table.header.".concat(p)),
            property: p
        };
    };
    PriceShowModal.prototype.setSalesHeaderCell = function (p) {
        var _this = this;
        var _a;
        return {
            sortValue: ((_a = this.basesControl.data.sort) === null || _a === void 0 ? void 0 : _a[p]) || 0,
            onSort: function (property, value) { return _this.basesControl.sort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("price-show-sales.table.header.".concat(p)),
            property: p
        };
    };
    PriceShowModal.prototype.renderBasesTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setBasesHeaderCell('gross'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 30 }, this.setBasesHeaderCell('tax'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 35 }, this.setBasesHeaderCell('validFrom'))),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 5, title: "", property: "" }));
    };
    PriceShowModal.prototype.renderSalesTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 23.75 }, this.setSalesHeaderCell('discountType'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 23.75 }, this.setSalesHeaderCell('discountPayload'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 23.75 }, this.setSalesHeaderCell('validFrom'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 23.75 }, this.setSalesHeaderCell('validTo'))),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 5, title: "", property: "" }));
    };
    PriceShowModal.prototype.renderBasesItem = function (ctx, item, index) {
        var _this = this;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30, className: "cursor-pointer" },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.gross))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 30 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.tax,
                    "%")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 35 },
                React.createElement("span", null, moment.utc(item === null || item === void 0 ? void 0 : item.validFrom).format('YYYY.MM.DD.'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 5 }, this.enableEdit() && React.createElement(button_1.Button, { onClick: function () { return _this.deleteBasePrice(item === null || item === void 0 ? void 0 : item._id); }, iconLeft: 'fal fa-minus', className: 'button-primary-link button-size-normal' })));
    };
    PriceShowModal.prototype.renderSalesItem = function (ctx, item, index) {
        var _this = this;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 23.75, className: "cursor-pointer" },
                React.createElement("span", null, (0, trans_1.trans)("price-show-sales.discount-type.".concat(item === null || item === void 0 ? void 0 : item.discountType)))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 23.75 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.discountPayload,
                    (0, trans_1.trans)("price-show-sales.discount-type-unit.".concat(item === null || item === void 0 ? void 0 : item.discountType)))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 23.75 },
                React.createElement("span", null, moment.utc(item === null || item === void 0 ? void 0 : item.validFrom).format('YYYY.MM.DD.'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 23.75 },
                React.createElement("span", null, moment.utc(item === null || item === void 0 ? void 0 : item.validTo).format('YYYY.MM.DD.'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 5 }, this.enableEdit() && React.createElement(button_1.Button, { onClick: function () { return _this.deleteSalePrice(item === null || item === void 0 ? void 0 : item._id); }, iconLeft: 'fal fa-minus', className: 'button-primary-link button-size-normal' })));
    };
    PriceShowModal.prototype.deleteBasePrice = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4, this.repository.delete("/admin/price/".concat(this.props.sku, "/base-price/").concat(id))];
                    case 1:
                        _a.sent();
                        return [4, this.$listBase.reload()];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        e_2 = _a.sent();
                        return [4, this.$listBase.reload()];
                    case 4:
                        _a.sent();
                        handle_toasts_1.HandleToasts.error(e_2, 'Sikertelen művelet');
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    PriceShowModal.prototype.deleteSalePrice = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4, this.repository.delete("/admin/price/".concat(this.props.sku, "/sale-price/").concat(id))];
                    case 1:
                        _a.sent();
                        return [4, this.$listSale.reload()];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        e_3 = _a.sent();
                        return [4, this.$listSale.reload()];
                    case 4:
                        _a.sent();
                        handle_toasts_1.HandleToasts.error(e_3, 'Sikertelen művelet');
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    PriceShowModal.prototype.enableEdit = function () {
        var _a, _b, _c, _d, _e, _f;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c.status)) {
            return false;
        }
        if (((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.item) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.status) !== user_list_screen_1.ProductStatus.SentToPartner) {
            return !!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update) && !(0, user_permission_1.isProductOwner)(this.props.product);
        }
        return !!(0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.PRODUCTS.update) && (0, user_permission_1.isProductOwner)(this.props.product);
    };
    return PriceShowModal;
}(abstract_component_1.AbstractComponent));
exports.PriceShowModal = PriceShowModal;
