module.exports = {
    'storage.list.title': 'Tárhelyek',
    'storage.title': 'Tárhely',
    'storage.list.button-create.title': 'Új tárhely létrehozása',
    'storage.update.save-box.button.enable-edit': 'Tárhely szerkesztése',
    'storage.update.save-box.button.save': 'Mentés',
    'storage.table.header.identifier': 'Azonosító',
    'storage.table.header.title': 'Elnevezés',
    'storage.table.header.path': 'Útvonal',
    'storage.table.header.lastOrder': 'Utolsó rendelés',
    'storage.table.header.createdAt': 'Létrehozva',
    'storage.status.active': 'Aktív',
    'storage.status.inactive': 'Inaktív',
    'storage.create.save-box.button.save': 'Létrehozás',
    'storage.update.subtitle.basic-data.storage-data': 'Adatok',
    'storage.update.subtitle.basic-data.capacity-data': 'Kapacitás',
    'storage.update.subtitle.basic-data.address': 'Cím',
    'storage.update.subtitle.basic-data.other': 'Egyéb adatok',
    'storage.basic-data.form.field.title.label': 'Elnevezés',
    'storage.basic-data.form.field.description.label': 'Megjegyzés',
    'storage.basic-data.form.field.inventory.label': 'Raktár terület',
    'storage.basic-data.form.field.identifier.label': 'Azonosító',
    'storage.basic-data.form.field.width.label': 'Szélesség',
    'storage.basic-data.form.field.height.label': 'Magasság',
    'storage.basic-data.form.field.depth.label': 'Mélység',
    'storage.basic-data.form.field.sizeUnit.label': 'Méret egység',
    'storage.basic-data.form.field.capacity.label': 'Kapacitás',
    'storage.basic-data.form.field.capacityUnit.label': 'Kapacitás egység',
    'storage.update.success.toast': 'Sikeresen módosítás',
    'storage.create.success.toast': 'Sikeresen létrehozás',
    'storage.status-update.success.toast': 'Sikeresen státusz módosítás',
};
