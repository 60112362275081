"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchKeywordListComponent = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var permissions_1 = require("../../common/libs/permissions/permissions");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var modal_1 = require("../../components/modal/modal");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var SearchKeywordListComponent = (function (_super) {
    __extends(SearchKeywordListComponent, _super);
    function SearchKeywordListComponent() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : ''
            },
        });
        _this.state = {
            total: 0
        };
        _this.synonymForm = new form_builder_1.FormBuilder({
            fields: {
                synonym: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'synonym',
                    label: (0, trans_1.trans)('search-keyword-synonym.label'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                })
            }
        });
        return _this;
    }
    SearchKeywordListComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row  mb-6" },
                React.createElement("div", { className: "col-15 display-flex align-items-center" },
                    React.createElement("div", { className: 'display-flex align-items-center' },
                        React.createElement("h3", { className: 'mr-4' }, (0, trans_1.trans)('synonym-dictionary.tab.result-number.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return _this.listRef$ = ref; }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: 'admin/search-string', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    SearchKeywordListComponent.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    SearchKeywordListComponent.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('keyword'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('frequency'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('hasSynonym'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('updatedAt'))));
    };
    SearchKeywordListComponent.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("search-string.table.header.".concat(p)),
            property: p
        };
    };
    SearchKeywordListComponent.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    SearchKeywordListComponent.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    SearchKeywordListComponent.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.keyword)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 :
                    item.frequency,
                    " ",
                    (0, trans_1.trans)('search-string.frequency.unit.label'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                item.hasSynonym && React.createElement("div", null,
                    React.createElement("strong", { className: 'color--status-success-1' },
                        React.createElement("i", { className: 'fas fa-check-circle' }),
                        " ",
                        (0, trans_1.trans)('search-string.table.has-synonym.label'))),
                !item.hasSynonym && React.createElement(modal_1.Modal, { renderModalHeader: function () {
                        return React.createElement("h3", null, item === null || item === void 0 ? void 0 :
                            item.keyword,
                            " ",
                            (0, trans_1.trans)('add-synonym.modal.title'),
                            " ");
                    }, disabled: item.hasSynonym, renderModalContent: function (modalContext) {
                        return React.createElement("div", null,
                            React.createElement("div", { className: 'mb-4' },
                                React.createElement("div", null,
                                    React.createElement(form_control_1.FormControl, { field: _this.synonymForm.fields.synonym, type: text_input_form_control_type_1.TextInputFormControlType },
                                        React.createElement(text_input_1.TextInput, { tabIndex: 1, className: 'text-input-basic' })))),
                            React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-4', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return modalContext.close(); } }),
                                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal-success', title: (0, trans_1.trans)('add-synonym.button.title'), onClick: function () { return _this.addToDictionary(item, modalContext); } })));
                    }, renderTrigger: function () {
                        return React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.CRM.searchKeywords.create },
                            React.createElement(button_1.Button, { disabled: item.hasSynonym, className: 'button-primary-outline button-size-normal', title: (0, trans_1.trans)('search-string.add-to-dictionary.button.title') }));
                    } })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.updatedAt).format('YYYY-MM-DD HH:mm:ss'))));
    };
    SearchKeywordListComponent.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    SearchKeywordListComponent.prototype.addToDictionary = function (item, ctx) {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, synonym;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.synonymForm.validate()];
                    case 1:
                        isValid = _a.sent();
                        if (!isValid) {
                            return [2];
                        }
                        synonym = this.synonymForm.fields.synonym.getValue();
                        return [4, this.repository.post('/admin/synonym-dictionary/addToDictionary', {
                                data: {
                                    synonym: synonym,
                                    keywords: [item.keyword]
                                }
                            })];
                    case 2:
                        _a.sent();
                        this.synonymForm.updateFields({});
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('add-synonym.success.toast.message'));
                        this.listRef$.reload();
                        ctx.close();
                        return [2];
                }
            });
        });
    };
    return SearchKeywordListComponent;
}(abstract_component_1.AbstractComponent));
exports.SearchKeywordListComponent = SearchKeywordListComponent;
