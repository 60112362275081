"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var React = require("react");
var multi_language_input_1 = require("../../../common/components/multi-language-input");
var sticky_container_1 = require("../../../common/components/sticky-container");
var countries_1 = require("../../../common/libs/countries");
var form_1 = require("../../../components/form/form");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
require("./region-update.screen.scss");
var languageOptions = [
    { value: 'hu', name: 'HU', key: 'hu' },
    { value: 'en', name: 'EN', key: 'en' }
];
var RegionUpdateScreen = (function (_super) {
    __extends(RegionUpdateScreen, _super);
    function RegionUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('region.form.title'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description',
                    label: (0, trans_1.trans)('region.form.description'),
                    validators: []
                }),
                country: new field_1.Field({
                    placeholder: '',
                    value: null,
                    name: 'country',
                    options: countries_1.COUNTRIES,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('region.form.country'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                code: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'code',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('region.form.code'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                soil: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'soil',
                    label: ((0, trans_1.trans)('region.form.soil')),
                    validators: []
                }),
                climate: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'climate',
                    label: ((0, trans_1.trans)('region.form.climate')),
                    validators: []
                }),
                area: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'area',
                    label: ((0, trans_1.trans)('region.form.area')),
                    validators: []
                }),
                fertileArea: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'fertileArea',
                    label: ((0, trans_1.trans)('region.form.fertileArea')),
                    validators: []
                }),
                showRegion: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'showRegion',
                    label: ((0, trans_1.trans)('region.form.showRegion')),
                    validators: []
                })
            }
        });
        _this.state = {
            editorLanguage: 'hu',
            error: null,
            region: null,
        };
        return _this;
    }
    RegionUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function () { return _this.updateRegion(); });
        this.form.$value.subscribe(function (value) { return _this.setState({ modified: true }); });
        this.fetchRegion();
    };
    RegionUpdateScreen.prototype.fetchRegion = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        _m.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/region/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.regionId))];
                    case 1:
                        response = _m.sent();
                        this.setState({ region: response });
                        this.form.fields.title.setValue(((_c = this.state.region) === null || _c === void 0 ? void 0 : _c.title) || {});
                        this.form.fields.description.setValue(((_d = this.state.region) === null || _d === void 0 ? void 0 : _d.description) || {});
                        this.form.fields.country.setValue((_e = this.state.region) === null || _e === void 0 ? void 0 : _e.country);
                        this.form.fields.code.setValue((_f = this.state.region) === null || _f === void 0 ? void 0 : _f.code);
                        this.form.fields.soil.setValue((_g = this.state.region) === null || _g === void 0 ? void 0 : _g.soil);
                        this.form.fields.climate.setValue((_h = this.state.region) === null || _h === void 0 ? void 0 : _h.climate);
                        this.form.fields.fertileArea.setValue((_j = this.state.region) === null || _j === void 0 ? void 0 : _j.fertileArea);
                        this.form.fields.area.setValue((_k = this.state.region) === null || _k === void 0 ? void 0 : _k.area);
                        this.form.fields.showRegion.setValue((_l = this.state.region) === null || _l === void 0 ? void 0 : _l.showRegion);
                        this.setState({ modified: false });
                        return [3, 3];
                    case 2:
                        e_1 = _m.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    RegionUpdateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'RegionUpdateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('region-create.screen.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: "col-24" }, this.renderBasicData()))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    RegionUpdateScreen.prototype.renderBasicData = function () {
        var _this = this;
        var _a, _b;
        return React.createElement("div", { className: 'RegionCreateForm' },
            React.createElement("div", null,
                React.createElement(form_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.state.editorLanguage, className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.country, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { multiple: false, unclearable: true, className: 'select-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, currentLanguage: this.state.editorLanguage, rows: 12, className: 'textarea-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.code, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.showRegion, type: checkbox_form_control_type_1.CheckboxFormControlType },
                        React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.soil, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.climate, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.area, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.fertileArea, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })))),
            React.createElement("div", { className: 'mb-4' },
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.imageUploaderRef$ = ref; }, value: (_a = this.state.region) === null || _a === void 0 ? void 0 : _a.image, targetVariant: "fullpage", width: 368, heigth: 368 })),
            React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("user.create.".concat((0, trans_1.trans)((_b = this.state.error) === null || _b === void 0 ? void 0 : _b.message))) })));
    };
    RegionUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'SaveBox' },
                React.createElement("div", { className: "mb-4" },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', value: this.state.editorLanguage, onChange: function (value) { return _this.changeLanguage(value); }, unclearable: true, options: languageOptions })),
                React.createElement("div", { className: 'w-100 display-flex flex-column justify-content-center' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mb-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelCreate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: (0, trans_1.trans)('general.button.title.save'), onClick: function () { return _this.updateRegion(); } }))));
    };
    RegionUpdateScreen.prototype.cancelCreate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/region');
                        return [2];
                }
            });
        });
    };
    RegionUpdateScreen.prototype.updateRegion = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var isValid, image, data, parsedData, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 4, , 5]);
                        return [4, this.form.validate()];
                    case 1:
                        isValid = _d.sent();
                        return [4, ((_a = this.imageUploaderRef$) === null || _a === void 0 ? void 0 : _a.getValue())];
                    case 2:
                        image = _d.sent();
                        if (!isValid) {
                            return [2];
                        }
                        data = this.form.toJSON();
                        parsedData = __assign(__assign({}, data), { image: image });
                        return [4, this.repository.put("/admin/region/".concat((_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c._id), { data: parsedData })];
                    case 3:
                        _d.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('region.shipping-charge-form.success.toast'));
                        router_provider_1.RouterProvider.goTo('/region');
                        return [3, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.setState({ error: e_2 });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    RegionUpdateScreen.prototype.changeLanguage = function (lang) {
        this.setState({ editorLanguage: lang });
    };
    return RegionUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.RegionUpdateScreen = RegionUpdateScreen;
