"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmModal = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var ConfirmModal = (function (_super) {
    __extends(ConfirmModal, _super);
    function ConfirmModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConfirmModal.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 position-center pb-4 pt-1' },
                React.createElement("span", null, this.props.message)),
            React.createElement("div", { className: 'row col-24 position-center' },
                React.createElement("div", { className: 'col-md-12 col-24' },
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.close'), onClick: function () { return _this.props.onClose(); }, className: 'col button-neutral-inverze button-size-normal' })),
                React.createElement("div", { className: 'col-md-12 col-24' },
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.confirm'), onClick: function () { return _this.props.onConfirm(); }, className: 'col button-neutral-inverze button-size-normal' }))));
    };
    return ConfirmModal;
}(abstract_component_1.AbstractComponent));
exports.ConfirmModal = ConfirmModal;
