"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var classe_helper_1 = require("@codebuild/sprinkles/libs/libs/classe-helper");
var random_id_1 = require("@codebuild/sprinkles/libs/libs/random-id");
var React = require("react");
var ReactDOM = require("react-dom");
require("./modal.scss");
var Modal = (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.modalId = (0, random_id_1.randomId)();
        _this.state = {
            open: false,
            loading: false,
            args: {}
        };
        return _this;
    }
    Modal.prototype.render = function () {
        return React.createElement("div", { "modal-id": this.modalId, className: 'modal' },
            this.renderTrigger(),
            this.renderContent());
    };
    Modal.prototype.renderTrigger = function () {
        var _this = this;
        if (!this.props.renderTrigger) {
            return;
        }
        return React.createElement("div", { "modal-trigger-id": this.modalId, className: 'modal-trigger', onClick: function (e) { return _this.toggleModal(e); } }, this.props.renderTrigger());
    };
    Modal.prototype.renderContent = function () {
        var _this = this;
        var _a, _b, _c;
        if (!this.state.open) {
            return;
        }
        return ReactDOM.createPortal(React.createElement("div", { "modal-content-id": this.modalId, onClick: function () { return _this.onBackdropClick(); }, className: (0, classe_helper_1.arrayToClass)([this.props.positionX, this.props.positionY, 'modal-content-wrapper row']) },
            React.createElement("div", { className: "modal-content p-4 col-md-".concat(((_a = this.props.col) === null || _a === void 0 ? void 0 : _a.md) || 12, " col-lg-").concat(((_b = this.props.col) === null || _b === void 0 ? void 0 : _b.lg) || 10, " col-").concat(((_c = this.props.col) === null || _c === void 0 ? void 0 : _c.xs) || 24), onClick: function (e) { return e.stopPropagation(); } },
                this.state.loading && this.renderLoading(),
                this.props.renderModalHeader && React.createElement("div", { className: "display-flex flex-row modal-header" },
                    React.createElement("div", { className: "flex-fill" }, this.props.renderModalHeader()),
                    this.renderClose()),
                this.props.renderModalContent && React.createElement("div", { className: "modal-content-body" }, this.props.renderModalContent(this)))), document.body);
    };
    Modal.prototype.renderLoading = function () {
        return React.createElement("div", { className: 'modal-loading' },
            React.createElement("i", { className: 'fas fa-spinner-third fa-spin fa-2x' }));
    };
    Modal.prototype.renderClose = function () {
        var _this = this;
        if (!!this.props.hideClose) {
            return;
        }
        return React.createElement("div", null,
            React.createElement("i", { onClick: function () { return _this.close(); }, className: 'fal fa-times cursor-pointer' }));
    };
    Modal.prototype.onBackdropClick = function () {
        if (!!this.props.disableBackdropClick) {
            return;
        }
        this.close();
    };
    Modal.prototype.close = function () {
        this.setState({ open: false, loading: false, args: {} });
        this.handleOnClose();
    };
    Modal.prototype.open = function (args) {
        if (args === void 0) { args = {}; }
        this.setState({ open: true, args: args });
        this.handleOnOpen();
    };
    Modal.prototype.toggleModal = function (e) {
        e.preventDefault();
        if (!!this.props.disabled) {
            return;
        }
        var isOpen = this.state.open;
        !isOpen ? this.handleOnOpen() : this.handleOnClose();
        this.setState({ open: !isOpen });
    };
    Modal.prototype.setLoading = function (loading) {
        this.setState({ loading: loading });
    };
    Modal.prototype.handleOnClose = function () {
        if (!!this.props.onClose) {
            this.props.onClose();
        }
    };
    Modal.prototype.handleOnOpen = function () {
        if (!!this.props.onOpen) {
            this.props.onOpen();
        }
    };
    return Modal;
}(abstract_component_1.AbstractComponent));
exports.Modal = Modal;
