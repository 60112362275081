"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSPIDS = void 0;
exports.CSPIDS = [
    'SB08',
    'SB14',
    'SBP8',
    'VB00',
    'VBK0',
    'VBU1',
    'VC00',
    'VCPE',
    'VE00',
    'VEA0',
    'VEK0',
    'VH00',
    'VP00',
    'VPSZ',
    'VSK0',
    'VSKP',
    'VSNA',
    'VSNP',
    'VUA0',
];
