module.exports = {
    'product-sales-report.list.title': 'Eladások termékenként',
    'product-sales-report.list.table.header.sku': 'Cikkszám',
    'product-sales-report.list.table.header.title.hu': 'Termék neve',
    'product-sales-report.list.table.header.sellingSumPriceHUF': 'Totál(HUF)',
    'product-sales-report.list.table.header.sellingSumPriceEUR': 'Totál(EUR)',
    'product-sales-report.list.table.header.sellingUnitAmount': 'Tény eladások(db)',
    'product-sales-report.list.table.header.sellingUnitPlanAmount': 'Terv eladások(db)',
    'product-sales-report.list.table.header.lastSaleAt': 'Utolsó eladás',
    'product-sales-report.list.table.header.supplier-price': 'Beszállító ár',
    'product-sales-report.list.table.header.calculated-price': 'Kiszámolt ár',
};
