module.exports = {
    'pagination.limit.label': 'Egy oldalon',
    'general.button.title.cancel': 'Mégsem',
    'general.button.title.save': 'Mentés',
    'general.button.title.sureDelete': 'Igen',
    'general.not-found.title': '404 - Az oldal nem található',
    'general.not-found.description': 'Egy olyan oldalt próblász megnyitni ami sajnos nem létezik. Válassz a baloldali menüből egy oldalt!',
    'general.status.active': 'AKTÍV',
    'general.status.inactive': 'INAKTÍV',
};
