"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPECIAL_PERMISSIONS = void 0;
exports.SPECIAL_PERMISSIONS = {
    canSeeAllProducts: 'see-all-product-on-admin',
    admin: 'admin',
    productOwner: 'productOwner',
    adminLogin: 'admin-login',
    b2bSupplier: 'b2b-supplier',
    superadmin: 'superadmin',
    telesales: 'telesales',
    vamInformatics: 'vam-informatika-view',
    dpd: 'dpd-view',
    storekeeper: 'storekeeper',
};
