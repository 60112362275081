"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementUpdateScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var moment_1 = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var permissions_1 = require("../../../common/libs/permissions/permissions");
var user_permission_1 = require("../../../common/libs/user-permission");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var modal_1 = require("../../../components/modal/modal");
require("../procurement-screen.scss");
var permission_wrapper_1 = require("../../../components/permission-wrapper");
var delete_procurement_modal_1 = require("./components/delete-procurement.modal");
var procurement_products_table_component_1 = require("./components/procurement-products-table.component");
var moment = require("moment");
var ProcurementUpdateScreen = (function (_super) {
    __extends(ProcurementUpdateScreen, _super);
    function ProcurementUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                supplier: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'supplier',
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'comment',
                    label: (0, trans_1.trans)('procurement.update.form.comment.label')
                }),
                plannedDeliveryDate: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'plannedDeliveryDate',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('procurement.create.form.comment.planned-delivery-date'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                confirmedDeliveryDate: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'confirmedDeliveryDate',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('procurement.create.form.comment.confirmed-delivery-date'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                update: new field_1.Field({
                    value: false,
                    name: 'update',
                })
            }
        });
        _this.state = {
            procurement: {},
            loading: false,
            validationError: '',
            saveError: '',
            statusChangeError: '',
        };
        return _this;
    }
    ProcurementUpdateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetch()];
                    case 1:
                        _a.sent();
                        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function (value) { return _this.onFormSubmit(value); }));
                        return [2];
                }
            });
        });
    };
    ProcurementUpdateScreen.prototype.fetch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.get("/b2b/procurement/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id))];
                    case 1:
                        response = _c.sent();
                        this.setState({ procurement: response });
                        return [3, 4];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ error: e_1.message });
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    ProcurementUpdateScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d;
        if (!(0, lodash_1.isEqual)(prevState.procurment, this.state.procurement)) {
            this.form.fields.supplier.setValue((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.supplier);
            this.form.fields.plannedDeliveryDate.setValue(((_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.plannedDeliveryDate) || '');
            this.form.fields.confirmedDeliveryDate.setValue(((_c = this.state.procurement) === null || _c === void 0 ? void 0 : _c.confirmedDeliveryDate) || '');
            this.form.fields.comment.setValue(((_d = this.state.procurement) === null || _d === void 0 ? void 0 : _d.comment) || '');
        }
    };
    ProcurementUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return React.createElement("div", { className: 'ProcurementEditScreen row w-100 px-4' },
            this.renderModals(),
            React.createElement("div", { className: 'col procurement-content-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal px-0 mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/procurement'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('procurement.update.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement(form_1.Form, { className: 'w-100' },
                        React.createElement("div", { className: 'display-flex' },
                            React.createElement("div", { className: 'mb-4 col-8' },
                                React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.identifier')),
                                React.createElement("p", { className: 'fw-700 ml-2' }, (_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.identifier)),
                            React.createElement("div", { className: 'mb-4 col-8' },
                                React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.view.data.supplier')),
                                React.createElement("p", { className: 'fw-700 ml-2' }, (_c = (_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.supplierArtifact) === null || _c === void 0 ? void 0 : _c.name)),
                            React.createElement("div", { className: 'mb-4 col-8' },
                                React.createElement("p", { className: 'typo-small' }, "Besz\u00E1ll\u00EDt\u00F3 partner megjegyz\u00E9s"),
                                React.createElement("p", { className: 'fw-700 ml-2' }, (_g = (_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.procurement) === null || _e === void 0 ? void 0 : _e.supplier) === null || _f === void 0 ? void 0 : _f.comment) !== null && _g !== void 0 ? _g : '-'))),
                        React.createElement("div", { className: 'display-flex' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.plannedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { readOnly: false, type: 'date', className: 'datepicker-basic col-8', locale: 'hu', settings: { enabledDateFrom: (0, moment_1.utc)().subtract(1, 'days').toDate().toString() } })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.confirmedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { readOnly: false, type: 'date', className: 'datepicker-basic col-8', locale: 'hu', settings: { enabledDateFrom: (0, moment_1.utc)().subtract(1, 'days').toDate().toString() } }))),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled, rows: 6, className: 'textarea-input-basic col-14' })),
                        React.createElement("div", { className: 'col display-flex flex-column align-items-center' },
                            React.createElement(procurement_products_table_component_1.ProcurementProductsTableComponent, { ref: function (ref) { return _this.updateInputFormRef$ = ref; }, procurementAction: 'update', plan: (_h = this.state.procurement) === null || _h === void 0 ? void 0 : _h.plan, fact: (_j = this.state.procurement) === null || _j === void 0 ? void 0 : _j.fact, isAddProductsDisabled: this.state.isAddProductsDisabled, setSupplierSelectDisabled: function (bool) { return _this.setState({ isSupplierSelectDisabled: bool }); }, supplier: this.state.procurement.supplier }),
                            this.state.validationError && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex align-items-center' },
                                    React.createElement("i", { className: 'far fa-exclamation-circle mr-2' }),
                                    React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)(this.state.validationError))) }))))),
            React.createElement("div", { className: 'col-6 procurement-save-box-wrapper' }, this.renderSaveBox()));
    };
    ProcurementUpdateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'procurement-update-box mb-6' },
                React.createElement("h5", { className: 'mb-6 ' }, "SZERKESZT\u00C9S"),
                React.createElement("div", { className: 'mb-4 w-100' },
                    React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.update.data.created-at')),
                    React.createElement("p", { className: 'fw-700 ml-2' }, moment((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a.createdAt).locale('hu').format('L'))),
                React.createElement("div", { className: 'mb-4 w-100' },
                    React.createElement("p", { className: 'typo-small' }, (0, trans_1.trans)('procurement.update.data.updated-at')),
                    React.createElement("p", { className: 'fw-700 ml-2' }, moment((_b = this.state.procurement) === null || _b === void 0 ? void 0 : _b.updatedAt).locale('hu').format('L'))),
                (0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.STORAGE.procurement.update)
                    && React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Mentés', iconLeft: this.state.loading ? '' : 'fas fa-floppy-disk', className: 'button-info-normal button-size-normal', disabled: false, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.form.fields.update.setValue(false)];
                                    case 1:
                                        _a.sent();
                                        return [4, this.form.fields.confirmedDeliveryDate.removeValidators()];
                                    case 2:
                                        _a.sent();
                                        return [4, this.form.submit()];
                                    case 3:
                                        _a.sent();
                                        return [2];
                                }
                            });
                        }); } }),
                ((_c = this.state.procurement) === null || _c === void 0 ? void 0 : _c.status) === 'Draft'
                    && React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.STORAGE.procurement.update },
                        React.createElement(button_1.Button, { disabled: false, className: 'button-info-inverze button-size-normal mt-7 text-align--left', title: (0, trans_1.trans)('procurement.update.edit.under-delivery-status.button'), iconRight: 'fas fa-arrow-right', onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, this.form.fields.update.setValue(true)];
                                        case 1:
                                            _a.sent();
                                            return [4, this.form.submit()];
                                        case 2:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); } }))),
            this.state.saveError && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, (0, trans_1.trans)('procurement.update.save.error')) }),
            this.state.statusChangeError && React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)('procurement.view.status.change.server-error') }),
            (0, user_permission_1.userHasPermission)(permissions_1.PERMISSIONS.STORAGE.procurement.update)
                && React.createElement("div", { className: 'procurement-delete-box mt-6' },
                    React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Beszerzés törlése', iconLeft: this.state.loading ? '' : 'fas fa-trash', className: 'button-error-outline button-size-normal', disabled: false, onClick: function () { return _this.$showDeleteProcurementModal.open(); } })));
    };
    ProcurementUpdateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProcurementModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) { return React.createElement(delete_procurement_modal_1.DeleteProcurementModal, { onClose: function () { return _this.$showDeleteProcurementModal.close(); }, onDeleteProcurement: function () { return _this.deleteProcurement(); } }); } }));
    };
    ProcurementUpdateScreen.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            return [2, { error: true }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    ProcurementUpdateScreen.prototype.onFormSubmit = function (value) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var products, data, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, 7, 8]);
                        this.setState({ loading: true });
                        return [4, this.updateInputFormRef$.getData()];
                    case 1:
                        products = _b.sent();
                        if (!products.plan.length) {
                            return [2, this.setState({ loading: false, validationError: 'procurement.error.form.missing.products', updateStatus: false })];
                        }
                        if (!!products.error) {
                            return [2, this.setState({ loading: false, validationError: 'procurement.error.form.not.valid', updateStatus: false })];
                        }
                        data = {
                            supplier: value === null || value === void 0 ? void 0 : value.supplier,
                            comment: value === null || value === void 0 ? void 0 : value.comment,
                            plan: products.plan,
                            fact: products.fact,
                            plannedDeliveryDate: value === null || value === void 0 ? void 0 : value.plannedDeliveryDate,
                            confirmedDeliveryDate: value === null || value === void 0 ? void 0 : value.confirmedDeliveryDate,
                        };
                        return [4, this.repository.put("/b2b/procurement/".concat(this.state.procurement._id), { data: data })];
                    case 2:
                        _b.sent();
                        if (!value.update) return [3, 4];
                        return [4, this.setStatusToUnderDelivery()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [4, this.fetch()];
                    case 5:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('procurement.update.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo("/procurement/".concat((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a._id))];
                    case 6:
                        e_2 = _b.sent();
                        this.setState({ saveError: true });
                        return [3, 8];
                    case 7:
                        this.setState({ loading: false, updateStatus: false });
                        return [7];
                    case 8: return [2];
                }
            });
        });
    };
    ProcurementUpdateScreen.prototype.setStatusToUnderDelivery = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.put("/b2b/procurement/".concat((_a = this.state.procurement) === null || _a === void 0 ? void 0 : _a._id, "/status"), {
                                data: {
                                    status: 'Placed',
                                }
                            })];
                    case 1:
                        _b.sent();
                        return [3, 3];
                    case 2:
                        e_3 = _b.sent();
                        throw e_3;
                    case 3: return [2];
                }
            });
        });
    };
    ProcurementUpdateScreen.prototype.deleteProcurement = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.delete("/b2b/procurement/".concat(this.state.procurement._id))];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('procurement.delete.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo('/procurement')];
                    case 2:
                        e_4 = _a.sent();
                        handle_toasts_1.HandleToasts.error((0, trans_1.trans)('procurement.delete.failed.toast'));
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return ProcurementUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.ProcurementUpdateScreen = ProcurementUpdateScreen;
