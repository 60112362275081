"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleToasts = void 0;
var React = require("react");
var react_toastify_1 = require("react-toastify");
var HandleToasts = (function () {
    function HandleToasts() {
    }
    HandleToasts.error = function (error, msg) {
        if (msg) {
            return react_toastify_1.toast.error(msg, { icon: function () { return React.createElement('i', { className: 'far fa-exclamation-circle color--error-6' }); } });
        }
        return react_toastify_1.toast.error(error.message, { icon: function () { return React.createElement('i', { className: 'far fa-exclamation-circle color--error-6' }); } });
    };
    HandleToasts.success = function (msg) {
        return react_toastify_1.toast.success(msg, { icon: function () { return React.createElement('i', { className: 'far fa-check-circle color--success-6' }); } });
    };
    return HandleToasts;
}());
exports.HandleToasts = HandleToasts;
