"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordComponent = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var React = require("react");
var form_1 = require("../form/form");
var email_validator_1 = require("../form/validators/email.validator");
var handle_toasts_1 = require("../libs/handle.toasts");
var message_box_1 = require("../libs/message-box/message-box");
require("./forgot-password.component.scss");
var ForgotPasswordComponent = (function (_super) {
    __extends(ForgotPasswordComponent, _super);
    function ForgotPasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            error: null,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                email: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'email',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('login.field.email'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required')), new email_validator_1.EmailValidator((0, trans_1.trans)('form.validation.invalid-email'))]
                })
            }
        });
        return _this;
    }
    ForgotPasswordComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$submitSuccess.subscribe(function (value) { return _this.submitRequest(value); });
    };
    ForgotPasswordComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'login-component' },
            React.createElement("div", { className: 'winehub-logo mb-9' },
                React.createElement("img", { src: "/assets/images/WineHub-Logo_wine.svg", alt: "winehub-logo" })),
            React.createElement("h2", { className: 'text-center mb-9' }, (0, trans_1.trans)('forgot-password.screen.title')),
            React.createElement("div", { className: 'w-100' },
                React.createElement(form_1.Form, { onSubmit: function () { return _this.form.submit(); } },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.email, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { tabIndex: 1, className: 'text-input-basic' })),
                    React.createElement("div", { className: 'w-100 display-flex align-items-center justify-content-center py-6' },
                        React.createElement(button_1.Button, { disabled: this.state.loading, title: !this.state.loading ? (0, trans_1.trans)('forgot-password.button.title') :
                                React.createElement("i", { className: 'fas fa-spinner-third fa-spin' }), className: 'button-primary-normal button-size-normal' })),
                    React.createElement("div", { className: "display-flex justify-content-center" },
                        React.createElement(button_1.Button, { className: 'button-primary-link button-size-normal', onClick: function () { return router_provider_1.RouterProvider.goTo('/login'); }, title: React.createElement("small", null, (0, trans_1.trans)('login.back-to-login.button.title')) }))),
                !!this.state.error &&
                    React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("forgot-password.".concat((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message)) })));
    };
    ForgotPasswordComponent.prototype.submitRequest = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        data = {
                            email: value.email,
                        };
                        return [4, this.repository.post('/auth/request-forgot-password', { data: data })];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.email.setValue('')];
                    case 2:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('forgot-password.request.success.message'));
                        this.setState({ loading: false });
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ error: e_1, loading: false });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ForgotPasswordComponent;
}(abstract_component_1.AbstractComponent));
exports.ForgotPasswordComponent = ForgotPasswordComponent;
