"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicDataForm = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var extended_required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/extended-required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var storage_parent_tree_select_form_control_type_1 = require("../../../../../common/components/tree-select/storage-parent-tree-select.form-control-type");
var tree_select_1 = require("../../../../../common/components/tree-select/tree-select");
var form_1 = require("../../../../../components/form/form");
var BasicDataForm = (function (_super) {
    __extends(BasicDataForm, _super);
    function BasicDataForm() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.title) || '',
                    name: 'title',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.title.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                identifier: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.identifier) || '',
                    name: 'identifier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.identifier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: ((_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.description) || '',
                    name: 'description',
                    label: React.createElement("div", null, (0, trans_1.trans)('storage.basic-data.form.field.description.label')),
                    validators: []
                }),
                inventory: new field_1.Field({
                    placeholder: '',
                    value: (_d = _this.props.values) === null || _d === void 0 ? void 0 : _d.inventory,
                    name: 'inventory',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.inventory.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                })
            }
        });
        _this.capacityForm = new form_builder_1.FormBuilder({
            fields: {
                width: new field_1.Field({
                    placeholder: '',
                    value: ((_f = (_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.capacity) === null || _f === void 0 ? void 0 : _f.width) || '',
                    name: 'width',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.width.label'),
                        " "),
                    validators: []
                }),
                height: new field_1.Field({
                    placeholder: '',
                    value: ((_h = (_g = _this.props.values) === null || _g === void 0 ? void 0 : _g.capacity) === null || _h === void 0 ? void 0 : _h.height) || '',
                    name: 'height',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.height.label'),
                        " "),
                    validators: []
                }),
                depth: new field_1.Field({
                    placeholder: '',
                    value: ((_k = (_j = _this.props.values) === null || _j === void 0 ? void 0 : _j.capacity) === null || _k === void 0 ? void 0 : _k.depth) || '',
                    name: 'depth',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('storage.basic-data.form.field.depth.label'),
                        " "),
                    validators: []
                }),
                sizeUnit: new field_1.Field({
                    placeholder: '',
                    value: ((_m = (_l = _this.props.values) === null || _l === void 0 ? void 0 : _l.capacity) === null || _m === void 0 ? void 0 : _m.sizeUnit) || '',
                    name: 'sizeUnit',
                    label: React.createElement("div", null, (0, trans_1.trans)('storage.basic-data.form.field.sizeUnit.label')),
                    validators: []
                }),
                capacity: new field_1.Field({
                    placeholder: '',
                    value: ((_p = (_o = _this.props.values) === null || _o === void 0 ? void 0 : _o.capacity) === null || _p === void 0 ? void 0 : _p.capacity) || '',
                    name: 'capacity',
                    label: React.createElement("div", null, (0, trans_1.trans)('storage.basic-data.form.field.capacity.label')),
                    validators: []
                }),
                capacityUnit: new field_1.Field({
                    placeholder: '',
                    value: ((_r = (_q = _this.props.values) === null || _q === void 0 ? void 0 : _q.capacity) === null || _r === void 0 ? void 0 : _r.capacityUnit) || '',
                    name: 'capacityUnit',
                    label: React.createElement("div", null, (0, trans_1.trans)('storage.basic-data.form.field.capacityUnit.label')),
                    validators: []
                })
            }
        });
        return _this;
    }
    BasicDataForm.prototype.getParentValue = function () {
        var _a;
        return ((_a = this.props.values) === null || _a === void 0 ? void 0 : _a.inventory) || null;
    };
    BasicDataForm.prototype.componentDidMount = function () {
        var _a;
        this.form.updateFields(__assign(__assign({}, this.props.values), { inventory: this.getParentValue() }));
        this.capacityForm.updateFields(__assign({}, (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.capacity));
    };
    BasicDataForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.form.updateFields(__assign(__assign({}, this.props.values), { inventory: this.getParentValue() }));
            this.capacityForm.updateFields(__assign({}, (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.capacity));
        }
    };
    BasicDataForm.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: "mb-4" }, this.renderBaseDataForm()),
            React.createElement("div", null, this.renderCapacityForm()));
    };
    BasicDataForm.prototype.renderBaseDataForm = function () {
        var _a;
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('storage.update.subtitle.basic-data.storage-data'))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.identifier, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-24 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { readOnly: this.props.readOnly, className: 'textarea-input-basic' }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.inventory, type: storage_parent_tree_select_form_control_type_1.StorageParentTreeSelectFormControlType },
                            React.createElement(tree_select_1.TreeSelect, { multi: false, enabledTypes: ['inventory'], disabledOptions: [(_a = this.props.values) === null || _a === void 0 ? void 0 : _a._id], modalTitle: 'Elhelyezés', readOnly: this.props.readOnly, searchable: true, className: 'text-input-basic' }))))));
    };
    BasicDataForm.prototype.renderCapacityForm = function () {
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement("div", { className: 'mb-3' },
                    React.createElement("h4", null, (0, trans_1.trans)('storage.update.subtitle.basic-data.capacity-data'))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-6 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.width, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'number', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-6 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.height, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'number', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-6 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.depth, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'number', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-6 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.sizeUnit, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'text', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.capacity, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'number', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))),
                    React.createElement("div", { className: "col-md-12 col-24" },
                        React.createElement(form_control_1.FormControl, { field: this.capacityForm.fields.capacityUnit, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(text_input_1.TextInput, { type: 'text', autoFocus: true, tabIndex: 0, disabled: this.props.readOnly, className: "text-input-basic ".concat(this.props.readOnly ? 'readOnly' : '') }))))));
    };
    BasicDataForm.prototype.getData = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var formErrors, hasError, storageData, capacity;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _d.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        formErrors = _d.sent();
                        hasError = false;
                        (0, lodash_1.map)(__assign({}, formErrors), function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        storageData = this.form.toJSON();
                        capacity = this.capacityForm.toJSON();
                        storageData.inventory = (_c = (_b = storageData.inventory) === null || _b === void 0 ? void 0 : _b.document) === null || _c === void 0 ? void 0 : _c._id;
                        storageData.capacity = capacity;
                        return [2, __assign({}, storageData)];
                }
            });
        });
    };
    return BasicDataForm;
}(abstract_component_1.AbstractComponent));
exports.BasicDataForm = BasicDataForm;
