"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentDueDateOptions = void 0;
exports.paymentDueDateOptions = [
    { key: '8', value: '8', name: '8 nap' },
    { key: '10', value: '10', name: '10 nap' },
    { key: '14', value: '14', name: '14 nap' },
    { key: '15', value: '15', name: '15 nap' },
    { key: '30', value: '30', name: '30 nap' }
];
