module.exports = {
    'slider.list.title': 'Főoldali carousel',
    'slider.list.button.create.title': 'Új slide létrehozása',
    'slider.table.header.title.hu': 'Cím (HU)',
    'slider.table.header.title.en': 'Cím (EN)',
    'slider.table.header.subTitle.hu': 'Alcím (HU)',
    'slider.table.header.subTitle.en': 'Alcím (EN)',
    'slider.table.header.published': 'Státusz',
    'slider.table.header.order': 'Sorrend',
    'slider.status.published': 'Publikus',
    'slider.status.unpublished': 'Nem publikus',
    'slider.create.screen.title': 'Slider elem létrehozása',
    'slider.update.screen.title': 'Slider elem módosítása',
    'slider.create.success.toast': 'A slider elem sikeresen létre lett hozva',
    'slider.update.success.toast': 'A slider elem sikeresen módosítva lett',
    'slider.publish.success.toast': 'A slider elem sikeresen publikávla lett',
    'slider.unpublish.success.toast': 'A slider elem sikeresen el lett rejtve',
    'slider.form.title_hu': 'Cím (HU)',
    'slider.form.title_en': 'Cím (EN)',
    'slider.form.subTitle_hu': 'Alcím (HU)',
    'slider.form.subTitle_en': 'Alcím (EN)',
    'slider.form.description_hu': 'Leírás (HU)',
    'slider.form.description_en': 'Leírás (EN)',
    'slider.form.buttonTitle_hu': 'Gomb cím (HU)',
    'slider.form.buttonTitle_en': 'Gomb cím (EN)',
    'slider.form.buttonLink_hu': 'Gomb link (HU)',
    'slider.form.buttonLink_en': 'Gomb link (EN)',
    'slider.form.order': 'Sorrend',
    'slider.form.color': 'Szín',
    'slider.desktopImage.title': 'Desktop kép',
    'slider.mobileImage.title': 'Mobil kép',
    'slider.create.button.title': 'Mentés',
    'slider.update.button.title': 'Mentés',
    'slider.publish.button.title': 'Publikálás',
    'slider.unpublish.button.title': 'Elrejtés',
};
