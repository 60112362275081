"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderSalesReportScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var creator_filter_component_1 = require("../../common/components/list-search/creator-filter.component");
var customer_partner_select_component_1 = require("../../common/components/list-search/customer-partner-select.component");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var sales_channel_filter_component_1 = require("../../common/components/list-search/sales-channel-filter.component");
var state_filter_component_1 = require("../../common/components/list-search/state-filter.component");
var supplier_filter_component_1 = require("../../common/components/list-search/supplier-filter.component");
var user_filter_component_1 = require("../../common/components/list-search/user-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var total_box_1 = require("../../components/customer-partner-list/total-box");
var report_download_1 = require("../../components/report-download");
var warehouse_filter_1 = require("./libs/warehouse-filter");
var OrderSalesReportScreen = (function (_super) {
    __extends(OrderSalesReportScreen, _super);
    function OrderSalesReportScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 50,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : ''
            },
        });
        _this.state = {
            total: 0,
            csvLoading: false,
            showWarehouse: false,
            isFilterOpen: true,
            totalLoading: false,
            totalData: { value: 0 },
            openedRows: []
        };
        return _this;
    }
    OrderSalesReportScreen.prototype.render = function () {
        var _this = this;
        var _a, _b;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('order-sales-report.list.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/order-sales-report/download', control: this.control })))),
            React.createElement("div", { className: "justify-content-between align-items-center row pt-5" },
                React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.totalLoading, text: (0, trans_1.trans)('order-sales-report.list.total-box'), data: this.state.totalData }))),
            React.createElement("div", { className: 'row mt-4 mb-7' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'list-filter-collapsible-wrapper' },
                        React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                            React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                            React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                                React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                        this.state.isFilterOpen && React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-8" },
                                React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control })),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(sales_channel_filter_component_1.SalesChannelFilterComponent, { "control$": this.control })),
                            React.createElement(state_filter_component_1.StateFilterComponent, { className: 'col-8', control: this.control }),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(customer_partner_select_component_1.CustomerPartnerSelectComponent, { onChange: function (customerPartner) { return _this.setState({ customerPartner: customerPartner, showWarehouse: !!customerPartner }); }, label: (0, trans_1.trans)('order-sales-report.filter.customer-partner'), property: 'customerPartner', "control$": this.control, defaultValue: (_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.customerPartner })),
                            this.renderWarehouseFilter(),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(user_filter_component_1.UsersFilterComponent, { onlyActive: true, label: (0, trans_1.trans)('order-sales-report.filter.salesManager'), property: 'salesManager', "control$": this.control })),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(supplier_filter_component_1.SupplierFilterComponent, { multiple: true, searchable: true, label: (0, trans_1.trans)('order-sales-report.filter.suppliers'), property: 'suppliers', "control$": this.control })),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(creator_filter_component_1.CreatorFilterComponent, { searchable: true, label: (0, trans_1.trans)('order-sales-report.filter.creator'), property: 'creator', "control$": this.control })),
                            React.createElement("div", { className: "col-8" },
                                React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').toDate(), "control$": this.control })))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: 'admin/order-sales-report', onResponseChange: function (items) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.setTotal(items)];
                }); }); }, renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    OrderSalesReportScreen.prototype.renderWarehouseFilter = function () {
        var _a, _b;
        if (!this.state.showWarehouse) {
            return null;
        }
        return React.createElement("div", { className: "col-8" },
            React.createElement(warehouse_filter_1.WarehouseFilter, { customerPartner: this.state.customerPartner, label: (0, trans_1.trans)('order-sales-report.filter.warehouse'), control: this.control, defaultValue: (_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.warehouse }));
    };
    OrderSalesReportScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    OrderSalesReportScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("order-sales-report.list.table.header.".concat(p)),
            property: p
        };
    };
    OrderSalesReportScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    OrderSalesReportScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    OrderSalesReportScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('orderId'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 17 }, this.setHeaderCell('customer-partner'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('factTotal'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('planTotal'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 8 }, this.setHeaderCell('channel'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('salesManager'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('createdBy'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 13 }, this.setHeaderCell('createdAt'))));
    };
    OrderSalesReportScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var customerPartnerName = (_b = (_a = item === null || item === void 0 ? void 0 : item.customerPartner) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '-';
        var warehouseName = (item === null || item === void 0 ? void 0 : item.warehouseName) ? "(".concat(item.warehouseName, ")") : '';
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper', key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: 'cursor-pointer', onClick: function () { return _this.toggleRow(item._id); } },
                React.createElement("div", { className: 'chevron-container' },
                    React.createElement("i", { className: "fal ".concat(this.state.openedRows.includes("".concat(item._id)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'border-bottom-0' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                        React.createElement("span", null, item === null || item === void 0 ? void 0 : item.identifier)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 17 },
                        React.createElement("span", null,
                            customerPartnerName,
                            React.createElement("span", { className: 'color--neutral-6 ml-2' }, warehouseName))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                        React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.factPrice))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                        React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.planPrice))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 8 },
                        React.createElement("span", null, (_d = (_c = item === null || item === void 0 ? void 0 : item.channels) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.toUpperCase())),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                        React.createElement("span", null, (_f = (_e = item === null || item === void 0 ? void 0 : item.salesManager) === null || _e === void 0 ? void 0 : _e.publicMeta) === null || _f === void 0 ? void 0 :
                            _f.lastName,
                            " ", (_h = (_g = item === null || item === void 0 ? void 0 : item.salesManager) === null || _g === void 0 ? void 0 : _g.publicMeta) === null || _h === void 0 ? void 0 :
                            _h.firstName)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                        React.createElement("span", null, (_k = (_j = item === null || item === void 0 ? void 0 : item.createdBy) === null || _j === void 0 ? void 0 : _j.publicMeta) === null || _k === void 0 ? void 0 :
                            _k.lastName,
                            " ", (_m = (_l = item === null || item === void 0 ? void 0 : item.createdBy) === null || _l === void 0 ? void 0 : _l.publicMeta) === null || _m === void 0 ? void 0 :
                            _m.firstName)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 13 },
                        React.createElement("span", null, (item === null || item === void 0 ? void 0 : item.sellingDate) ? moment(item === null || item === void 0 ? void 0 : item.sellingDate).format('YYYY.MM.DD. HH:mm') : '-')))),
            this.isOpened(item._id) && React.createElement(table_body_cell_1.TableBodyCell, { width: 100 }, this.renderProductsTable(item === null || item === void 0 ? void 0 : item.productsDisplay)));
    };
    OrderSalesReportScreen.prototype.toggleRow = function (id) {
        if (this.state.openedRows.includes(id)) {
            return this.setState({ openedRows: this.state.openedRows.filter(function (r) { return r !== id; }) });
        }
        return this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [id], false) });
    };
    OrderSalesReportScreen.prototype.isOpened = function (id) {
        return this.state.openedRows.includes(id);
    };
    OrderSalesReportScreen.prototype.renderProductsTable = function (products) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic pl-5', renderHeader: function () { return _this.renderProductsTableHeader(); }, renderBody: function () { return (React.createElement("div", { className: 'table-body' }, products.map(function (p) { return _this.renderProductsTableRow(p); }))); } });
    };
    OrderSalesReportScreen.prototype.renderProductsTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 20, enableSort: false, title: (0, trans_1.trans)('order-sales-report.list.order-product.table.header.id'), property: '_id' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 10, enableSort: false, title: (0, trans_1.trans)('order-sales-report.list.order-product.table.header.factAmount'), property: 'factAmount' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 10, enableSort: false, title: (0, trans_1.trans)('order-sales-report.list.order-product.table.header.sumFactTruePrice'), property: 'sumFactTruePrice' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 10, enableSort: false, title: (0, trans_1.trans)('order-sales-report.list.order-product.table.header.planAmount'), property: 'planAmount' }),
            React.createElement(table_header_cell_1.TableHeaderCell, { width: 10, enableSort: false, title: (0, trans_1.trans)('order-sales-report.list.order-product.table.header.sumPlanTruePrice'), property: 'sumPlanTruePrice' }));
    };
    OrderSalesReportScreen.prototype.renderProductsTableRow = function (product) {
        var _a, _b, _c, _d;
        var eanCode = (product === null || product === void 0 ? void 0 : product.ean) || '-';
        var sku = (product === null || product === void 0 ? void 0 : product.sku) || '-';
        var title = (product === null || product === void 0 ? void 0 : product.title) || '-';
        return React.createElement(table_body_row_1.TableBodyRow, { key: product.ean },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, eanCode)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null, title))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_a = product === null || product === void 0 ? void 0 : product.fact) === null || _a === void 0 ? void 0 : _a.amount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)((_b = product === null || product === void 0 ? void 0 : product.fact) === null || _b === void 0 ? void 0 : _b.price))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_c = product === null || product === void 0 ? void 0 : product.plan) === null || _c === void 0 ? void 0 : _c.amount)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (0, currency_parser_1.hufFormat)((_d = product === null || product === void 0 ? void 0 : product.plan) === null || _d === void 0 ? void 0 : _d.price))));
    };
    OrderSalesReportScreen.prototype.setTotal = function (items) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!(items === null || items === void 0 ? void 0 : items.length)) {
                    return [2];
                }
                this.setState({
                    total: items.length,
                    totalData: { value: items.reduce(function (acc, item) { var _a; return (_a = acc + (item === null || item === void 0 ? void 0 : item.factPrice)) !== null && _a !== void 0 ? _a : 0; }, 0) },
                });
                if (this.props.totalChange) {
                    this.props.totalChange(items.length);
                }
                return [2];
            });
        });
    };
    OrderSalesReportScreen.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    return OrderSalesReportScreen;
}(abstract_component_1.AbstractComponent));
exports.OrderSalesReportScreen = OrderSalesReportScreen;
