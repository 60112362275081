"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeoForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var multi_language_input_1 = require("../../../../common/components/multi-language-input");
var form_1 = require("../../../../components/form/form");
var SeoForm = (function (_super) {
    __extends(SeoForm, _super);
    function SeoForm() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    name: 'title',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.title'),
                    value: ((_b = (_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.seo) === null || _b === void 0 ? void 0 : _b.title) || '',
                    validators: []
                }),
                description: new field_1.Field({
                    name: 'description',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.description'),
                    value: ((_d = (_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.seo) === null || _d === void 0 ? void 0 : _d.description) || '',
                    validators: []
                }),
                keywords: new field_1.Field({
                    name: 'keywords',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.keywords'),
                    value: ((_f = (_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.seo) === null || _f === void 0 ? void 0 : _f.keywords) || '',
                    validators: []
                }),
                ogTitle: new field_1.Field({
                    name: 'ogTitle',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.og-title'),
                    value: ((_h = (_g = _this.props.values) === null || _g === void 0 ? void 0 : _g.seo) === null || _h === void 0 ? void 0 : _h.ogTitle) || '',
                    validators: []
                }),
                ogDescription: new field_1.Field({
                    name: 'ogDescription',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.og-description'),
                    value: ((_k = (_j = _this.props.values) === null || _j === void 0 ? void 0 : _j.seo) === null || _k === void 0 ? void 0 : _k.ogDescription) || '',
                    validators: []
                }),
                ogImageAlt: new field_1.Field({
                    name: 'ogImageAlt',
                    placeholder: '',
                    label: (0, trans_1.trans)('product.seo.form.og-image-alt'),
                    value: ((_m = (_l = _this.props.values) === null || _l === void 0 ? void 0 : _l.seo) === null || _m === void 0 ? void 0 : _m.ogImageAlt) || '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    SeoForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            var title = ({
                hu: [(_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.manufacturerName) === null || _b === void 0 ? void 0 : _b.hu, (_d = (_c = this.props.values) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.hu, (_e = this.props.values) === null || _e === void 0 ? void 0 : _e.vintage].filter(function (item) { return !!item; }).join(' '),
                en: [(_g = (_f = this.props.values) === null || _f === void 0 ? void 0 : _f.manufacturerName) === null || _g === void 0 ? void 0 : _g.en, (_j = (_h = this.props.values) === null || _h === void 0 ? void 0 : _h.title) === null || _j === void 0 ? void 0 : _j.en, (_k = this.props.values) === null || _k === void 0 ? void 0 : _k.vintage].filter(function (item) { return !!item; }).join(' '),
            });
            this.form.fields.title.setValue(((_m = (_l = this.props.values) === null || _l === void 0 ? void 0 : _l.seo) === null || _m === void 0 ? void 0 : _m.title) || title);
            this.form.fields.description.setValue(((_p = (_o = this.props.values) === null || _o === void 0 ? void 0 : _o.seo) === null || _p === void 0 ? void 0 : _p.description) || ((_q = this.props.values) === null || _q === void 0 ? void 0 : _q.description));
            this.form.fields.keywords.setValue((_s = (_r = this.props.values) === null || _r === void 0 ? void 0 : _r.seo) === null || _s === void 0 ? void 0 : _s.keywords);
            this.form.fields.ogTitle.setValue(((_u = (_t = this.props.values) === null || _t === void 0 ? void 0 : _t.seo) === null || _u === void 0 ? void 0 : _u.ogTitle) || title);
            this.form.fields.ogDescription.setValue(((_w = (_v = this.props.values) === null || _v === void 0 ? void 0 : _v.seo) === null || _w === void 0 ? void 0 : _w.ogDescription) || ((_x = this.props.values) === null || _x === void 0 ? void 0 : _x.description));
        }
    };
    SeoForm.prototype.render = function () {
        return React.createElement(form_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.ogTitle, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.props.editorLanguage, className: 'text-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.ogDescription, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, rows: 4, currentLanguage: this.props.editorLanguage, className: 'textarea-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.ogImageAlt, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, rows: 4, currentLanguage: this.props.editorLanguage, className: 'textarea-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.props.editorLanguage, className: 'text-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, rows: 4, currentLanguage: this.props.editorLanguage, className: 'textarea-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.keywords, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.props.editorLanguage, className: 'textarea-input-basic' })));
    };
    SeoForm.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _b.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _b.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    SeoForm.prototype.getFileNamePrefix = function (line) {
        var _a, _b;
        var data = this.form.toJSON();
        var arr = [
            ((_a = data.title) === null || _a === void 0 ? void 0 : _a.hu) || ((_b = data.title) === null || _b === void 0 ? void 0 : _b.en) || data.title,
        ].filter(function (item) { return !!item; });
        return arr.length
            ? (__spreadArray(__spreadArray([], __read(arr), false), [line], false).join('-'))
                .toLowerCase()
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')
                .replace(/\./gu, '')
                .replace(/ /gu, '')
            : null;
    };
    return SeoForm;
}(abstract_component_1.AbstractComponent));
exports.SeoForm = SeoForm;
