module.exports = {
    'category.list.title': 'Kategóriák',
    'category.table.header.name.hu': 'Kategória neve(HU)',
    'category.table.header.name.en': 'Kategória neve(EN)',
    'category-attribute.update.screen.title ': 'Kategoria jellemzők',
    'category-attribute.table.header.attribute.title.hu': 'Jellemző neve',
    'category-attribute.table.header.attribute.adminTitle': 'Admin név',
    'category-attribute.table.header.showList': 'Mutatás listában',
    'category-attribute.table.header.showProduct': 'Mutatás terméken',
    'category-attribute.table.header.listOrder': 'Lista sorrend',
    'category-attribute.table.header.productOrder': 'Termék sorrend',
    'category-attribute.table.header.adminOrder': 'Admin sorrend',
    'category-attribute.update.screen.title': 'Kategória jellemzők szerkesztése',
    'category-attribute.update-success': 'Sikeres mentés',
    'category-attribute.update-error': 'Hiba történt'
};
