"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionWrapper = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var user_permission_1 = require("../common/libs/user-permission");
var PermissionWrapper = (function (_super) {
    __extends(PermissionWrapper, _super);
    function PermissionWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PermissionWrapper.prototype.render = function () {
        if (!this.hasPermissionToSee()) {
            return null;
        }
        return this.props.children;
    };
    PermissionWrapper.prototype.hasPermissionToSee = function () {
        var e_1, _a;
        if (typeof this.props.permissions === 'string') {
            return (0, user_permission_1.userHasPermission)(this.props.permissions);
        }
        try {
            for (var _b = __values(this.props.permissions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var permission = _c.value;
                if ((0, user_permission_1.userHasPermission)(permission)) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    return PermissionWrapper;
}(abstract_component_1.AbstractComponent));
exports.PermissionWrapper = PermissionWrapper;
