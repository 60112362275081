"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShippingChargeScreen = void 0;
var sleep_1 = require("@codebuild/glaze/libs/libs/sleep");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var lodash_1 = require("lodash");
var moment_1 = require("moment/moment");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var permissions_1 = require("../../common/libs/permissions/permissions");
var only_positive_number_validator_1 = require("../../components/form/validators/only-positive-number.validator");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var custom_shipping_charge_manager_1 = require("./shipping-charge-form/custom-shipping-charge.manager");
var ShippingChargeScreen = (function (_super) {
    __extends(ShippingChargeScreen, _super);
    function ShippingChargeScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            total: 0,
            loading: false,
            shippingCharge: null,
            readOnly: true,
            disableEdit: true,
            error: '',
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                defaultShippingChargeHUF: new field_1.Field({
                    name: 'defaultShippingChargeHUF',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.defaultShippingChargeHUF.label')),
                    value: '',
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
                defaultShippingChargeEUR: new field_1.Field({
                    name: 'defaultShippingChargeEUR',
                    label: React.createElement("div", null, (0, trans_1.trans)('shipping-charge.defaultShippingChargeEUR.label')),
                    value: '',
                    validators: [new only_positive_number_validator_1.OnlyPositiveNumberValidator('Invalid number value provided'), new required_validator_1.RequiredValidator('Required field')]
                }),
            }
        });
        return _this;
    }
    ShippingChargeScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ShippingChargeScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b;
        if (this.state.shippingCharge && !(0, lodash_1.isEqual)(prevState.shippingCharge, this.state.shippingCharge)) {
            this.form.fields.defaultShippingChargeEUR.setValue((_a = this.state.shippingCharge) === null || _a === void 0 ? void 0 : _a.defaultShippingChargeEUR);
            this.form.fields.defaultShippingChargeHUF.setValue((_b = this.state.shippingCharge) === null || _b === void 0 ? void 0 : _b.defaultShippingChargeHUF);
        }
    };
    ShippingChargeScreen.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-20" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('shipping-charge.screen.title'))),
                    this.renderContent()),
                React.createElement("div", { className: 'col-4 save-box-wrapper' }, this.renderEditBox())));
    };
    ShippingChargeScreen.prototype.renderContent = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'row' },
            React.createElement(form_control_1.FormControl, { field: this.form.fields.defaultShippingChargeHUF, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-24 col-md-12 col-lg-8', disabled: this.state.readOnly })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.defaultShippingChargeEUR, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-24 col-md-12 col-lg-8', disabled: this.state.readOnly })),
            React.createElement("div", { className: 'col-24' },
                React.createElement(custom_shipping_charge_manager_1.CustomShippingChargeManager, { ref: function (ref) { return _this.customShippingChargeManager = ref; }, readOnly: this.state.readOnly, customCharges: (_a = this.state.shippingCharge) === null || _a === void 0 ? void 0 : _a.customCharges })));
    };
    ShippingChargeScreen.prototype.renderEditBox = function () {
        var _this = this;
        var _a;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'save-box info-box mb-6', key: 1 },
                React.createElement("h5", { className: 'mb-3' }, "SZERKESZT\u00C9S"),
                React.createElement("div", { className: 'w-100' },
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("p", { className: 'typo-small' }, "Utolj\u00E1ra m\u00F3dos\u00EDtva"),
                        React.createElement("p", { className: 'fw-700 ml-2' }, this.parseDate((_a = this.state.shippingCharge) === null || _a === void 0 ? void 0 : _a.updatedAt)))),
                React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.ORDERS.b2bOrders.update }, this.state.readOnly
                    ? React.createElement("div", { className: 'display-flex flex-column align-items-center' },
                        React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Módosítás', iconLeft: this.state.loading ? '' : 'fas fa-pencil', className: 'button-info-normal button-size-normal mb-6', onClick: function () { return _this.setState({ readOnly: false }); } }))
                    : React.createElement("div", { className: 'display-flex flex-column justify-content-center' },
                        React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Mentés', iconLeft: this.state.loading ? '' : 'fas fa-pencil', className: 'button-success-normal button-size-normal', disabled: false, onClick: function () { return _this.update(); } })))),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    ShippingChargeScreen.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, defaultShippingChargeEUR, defaultShippingChargeHUF, customCharges, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, 6, 7]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            throw new Error((0, trans_1.trans)('shipping-charge.default.shipping.charge.missing'));
                        }
                        defaultShippingChargeEUR = this.form.fields.defaultShippingChargeEUR.getValue();
                        defaultShippingChargeHUF = this.form.fields.defaultShippingChargeHUF.getValue();
                        return [4, this.validateAndGetCustomCharges()];
                    case 2:
                        customCharges = _a.sent();
                        return [4, this.request({ defaultShippingChargeEUR: defaultShippingChargeEUR, defaultShippingChargeHUF: defaultShippingChargeHUF, customCharges: customCharges })];
                    case 3:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.update.success.toast'));
                        return [4, (0, sleep_1.sleep)(600)];
                    case 4:
                        _a.sent();
                        window.location.reload();
                        return [3, 7];
                    case 5:
                        e_1 = _a.sent();
                        console.log(e_1);
                        handle_toasts_1.HandleToasts.error(e_1, (0, trans_1.trans)('shipping-charge.general.error'));
                        return [3, 7];
                    case 6:
                        this.setState({ loading: false });
                        return [7];
                    case 7: return [2];
                }
            });
        });
    };
    ShippingChargeScreen.prototype.validateAndGetCustomCharges = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, errors, payload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.customShippingChargeManager.getFormData()];
                    case 1:
                        _a = _b.sent(), errors = _a.errors, payload = _a.payload;
                        if (errors === null || errors === void 0 ? void 0 : errors.length) {
                            throw new Error(errors.join(', '));
                        }
                        return [2, payload];
                }
            });
        });
    };
    ShippingChargeScreen.prototype.fetchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.setState({ disableEdit: true });
                        return [4, this.repository.get('/b2b/shipping-charge')];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            this.setState({ shippingCharge: response });
                        }
                        return [3, 4];
                    case 2:
                        e_2 = _a.sent();
                        console.log({ fetchData: e_2 });
                        return [3, 4];
                    case 3:
                        this.setState({ disableEdit: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    ShippingChargeScreen.prototype.request = function (data) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shippingChargeId;
            return __generator(this, function (_b) {
                shippingChargeId = (_a = this.state.shippingCharge) === null || _a === void 0 ? void 0 : _a._id;
                return [2, shippingChargeId
                        ? this.repository.put("/b2b/shipping-charge/".concat(shippingChargeId), { data: data })
                        : this.repository.post('/b2b/shipping-charge', { data: data })];
            });
        });
    };
    ShippingChargeScreen.prototype.parseDate = function (date) {
        if (!date) {
            return '-';
        }
        return (0, moment_1.utc)(date).format('YYYY.MM.DD.');
    };
    return ShippingChargeScreen;
}(abstract_component_1.AbstractComponent));
exports.ShippingChargeScreen = ShippingChargeScreen;
